<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.external_user_name"
          clearable
          placeholder="客户"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.staff_user_name"
          clearable
          placeholder="提报人"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.draw_company"
          clearable
          placeholder="经销商公司名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="time"
          end-placeholder="提报结束日期"
          range-separator="至"
          start-placeholder="提报开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="timelist"
          end-placeholder="审核结束日期"
          range-separator="至"
          start-placeholder="审核开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option
            v-for="item in shopSelect"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        :render-header="item.tooltip ? renderHeaderWithTooltip : null"
      >
        <template #default="{ row }">
          <div v-if="item.label == '合作凭证'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.prop == 'is_match_view'">
            {{ row[item.prop] ? '是' : '否' }}
          </div>
          <div v-else-if="item.prop == 'is_invite'">
            {{ row[item.prop] ? '是' : '否' }}
          </div>
          <div v-else-if="item.prop == 'is_call'">
            {{ row[item.prop] ? '是' : '否' }}
          </div>
          <div v-else-if="item.prop == 'mall_order'">
            {{ row[item.prop] ? '是' : '否' }}
          </div>
          <div v-else-if="item.prop == 'is_suggest'">
            {{ row[item.prop] ? '是' : '否' }}
          </div>
          <div v-else-if="item.label == '状态'">
            {{
              row[item.prop] == 1
                ? '已通过'
                : row[item.prop] == 3
                ? '已拒绝'
                : '待审核'
            }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="160">
        <template #default="{ row }">
          <div v-if="row.status == 2">
            <el-button size="small" type="text" @click="handlerpass(row, 1)">
              通过
            </el-button>
            <el-button size="small" type="text" @click="handlerpass(row, 3)">
              拒绝
            </el-button>
          </div>
          <el-button size="small" type="text" @click="handlerrefuse(row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog
      v-if="dialogVisible"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-descriptions border :column="1">
        <el-descriptions-item label="提报人">
          {{ detail.staff_user_name }}
        </el-descriptions-item>
        <el-descriptions-item label="客户">
          {{ detail.external_user_name }}
        </el-descriptions-item>
        <el-descriptions-item label="店铺名称">
          {{ detail.mall_name }}
        </el-descriptions-item>
        <el-descriptions-item label="提报时间">
          {{ detail.created }}
        </el-descriptions-item>
        <el-descriptions-item label="合作凭证">
          <span v-for="(list, index) in detail.img" :key="index">
            <el-image
              :preview-src-list="[list]"
              :src="list"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          {{ detail.remark }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import dayjs from 'dayjs'

  export default {
    watch: {
      timelist(v) {
        if (v) {
          this.form.audit_start_time = v[0]
          this.form.audit_end_time = v[1]
        } else {
          this.form.audit_start_time = ''
          this.form.audit_end_time = ''
        }
      },
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    data() {
      return {
        detail: {},
        dialogTitle: '详情',
        dialogVisible: false,
        form: {
          /**
           * 审核结束时间
           */
          audit_end_time: '',
          /**
           * 审核开始时间
           */
          audit_start_time: '',
          /**
           * 提报结束日期
           */
          end_time: dayjs(new Date()).format('YYYY-MM-DD'),
          /**
           * 客户
           */
          external_user_name: '',
          page: 1,
          limit: 10,
          /**
           * 店铺名
           */
          mall_name: '',
          /**
           * 提报人
           */
          staff_user_name: '',
          /**
           * 提报开始日期
           */
          start_time: dayjs(
            new Date().getTime() - 1000 * 60 * 60 * 24 * 30
          ).format('YYYY-MM-DD'),
          /**
           * 状态，1已通过，2待审核，3已拒绝
           */
          status: '',
        },
        time: [
          dayjs(new Date().getTime() - 1000 * 60 * 60 * 24 * 30).format(
            'YYYY-MM-DD'
          ),
          dayjs(new Date()).format('YYYY-MM-DD'),
        ],
        timelist: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '客户',
            prop: 'external_user_name',
            width: '',
          },
          {
            label: '经销商公司名称',
            prop: 'draw_company',
            width: '',
            tooltip: '显示企微用户画像中的公司名称',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '220px',
          },
          {
            label: '提报时间',
            prop: 'created',
            width: '220px',
          },
          {
            label: '合作凭证',
            prop: 'img',
            width: '220px',
          },
          {
            label: '备注',
            prop: 'remark',
            width: '220px',
          },
          {
            label: '提报人',
            prop: 'staff_user_name',
            width: '220px',
          },
          {
            label: '审核人',
            prop: 'audit_name',
            width: '',
          },
          {
            label: '审核时间',
            prop: 'audit_time',
            width: '',
          },
          {
            label: '是否匹配且被查看',
            prop: 'is_match_view',
            width: '',
          },
          {
            label: '是否被邀请',
            prop: 'is_invite',
            width: '',
          },
          {
            label: '是否电话咨询',
            prop: 'is_call',
            width: '',
          },
          {
            label: '是否样品申请',
            prop: 'mall_order',
            width: '',
          },
          {
            label: '是否留言过',
            prop: 'is_suggest',
            width: '',
          },
          {
            label: '状态',
            prop: 'status',
            width: '',
          },
          {
            label: '拒绝原因',
            prop: 'reason',
          },
        ],
        shopSelect: [
          {
            id: 1,
            name: '已通过',
          },
          {
            id: 2,
            name: '待审核',
          },
          {
            id: 3,
            name: '已拒绝',
          },
        ],
        total: 0,
        spaceSelect: [],
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlerExport() {
        postAction('/audit/biz-clue/export-cooplists', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      //经销商身份通过
      renderHeaderWithTooltip(h, { column, $index }) {
        console.log('提示内容，', column, $index)
        return h('span', [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content: '显示企微用户画像中的公司名称', // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ])
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      // async handlerSelect() {
      //   await getAction('/audit/advert/position-config').then((res) => {
      //     this.shopSelect = res.data
      //   })
      // },
      handlerpass(row, type) {
        let messagedata
        type == 1
          ? (messagedata = '确认审核通过')
          : (messagedata = '确认审核拒绝')
        this.$confirm(messagedata, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            if (type == 1) {
              postAction('/audit/biz-clue/coop-audit', {
                id: row.id,
                status: type,
              }).then((res) => {
                this.handlerInquire()
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
              })
            } else {
              this.$prompt('请输入理由', '理由', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              })
                .then(({ value }) => {
                  postAction('/audit/biz-clue/coop-audit', {
                    id: row.id,
                    status: type,
                    reason: value,
                  }).then((res) => {
                    this.handlerInquire()
                    this.$message({
                      type: 'success',
                      message: res.msg,
                    })
                  })
                })
                .catch(() => {})
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      handlerrefuse(row) {
        this.detail = row
        this.dialogVisible = true
      },

      handlerlist() {
        getAction('/audit/biz-clue/coop-lists', this.form).then((res) => {
          this.tabledata = res.data
          this.tabledata.map((item) => {
            item.img = item.img.split(',')
          })
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
