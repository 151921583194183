var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderTest-container" }, [
    _c(
      "div",
      [
        _c(
          "el-upload",
          {
            ref: "upload",
            staticClass: "avatar-uploader el-upload--text",
            attrs: {
              action: _vm.url + "/api/index/upload-video",
              headers: _vm.headers,
              accept: ".mp3,.aac,.acc,.m4a",
              "show-file-list": false,
              "on-success": _vm.handleVideoSuccess,
              "before-upload": _vm.beforeUploadVideo,
              "on-progress": _vm.uploadVideoProcess,
              disabled: _vm.disab,
            },
          },
          [
            _c("el-button", { attrs: { type: "primary" } }, [
              _vm._v("选择文件"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm.showInfo
      ? _c(
          "div",
          {
            staticStyle: {
              "line-height": "1.2",
              "margin-top": "10px",
              color: "red",
              "font-size": "12px",
            },
          },
          [_vm._v(" " + _vm._s(_vm.infoText) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }