<script>
import { getAction } from "@/api/request";
import tasklog from "@/views/pages/datastatis/Information/components/tasklog.vue";

export default {
  name: "guanggao",
  components: {
    tasklog
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      status: '',
    }
  },
  methods: {
   async handlerlist(id) {
     console.log(id)
     this.status = id.status
     await getAction("/mall/setmeal/advert-exposure", {
        id: id.id
      }).then(res => {
        this.tableData = res.data
        this.dialogVisible = true
      })
    },
    handlertask(row) {
      this.$refs.taskLog.showData({
        task_type: 3,
        data_id: row.position_mall_id,
        title: row.position_name,
        virtual_num: row.advert_exposure_virtual_num,
        views_num: row.advert_exposure_num + row.advert_exposure_virtual_num,
      });
    }
  }
};
</script>

<template>
<div>
  <el-dialog
    title="广告详情"
    :visible.sync="dialogVisible"
    width="40%">
    <span>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          prop="position_name"
          label="广告位"
          align="center">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          align="center">
        </el-table-column>
         <el-table-column
          prop="advert_exposure_num"
          label="已完成（真实）"
          align="center">
        </el-table-column>
        <el-table-column
          prop="advert_exposure_virtual_num"
          label="已完成（虚拟）"
          align="center">
        </el-table-column>
        <el-table-column
          prop="advert_exposure_num"
          label="已完成（总计）"
          align="center">
          <template slot-scope="scope">
            {{scope.row.advert_exposure_num + scope.row.advert_exposure_virtual_num}}
          </template>
        </el-table-column>
        <el-table-column
          prop="advert_max_exposure_num"
          label="总量"
          align="center">
        </el-table-column>
        <el-table-column
          prop="type"
          label="操作"
          align="center">
          <template slot-scope="scope">
            <el-button v-if="status==1&&scope.row.status!='已完成'" type="text" @click="handlertask(scope.row)">增加虚拟曝光量</el-button>
          </template>
        </el-table-column>
      </el-table>
    </span>
  </el-dialog>
  <tasklog ref="taskLog"/>
</div>
</template>

<style scoped>

</style>
