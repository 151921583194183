<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">


      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in callselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '店铺名称'">
            {{ row[item.prop] }}
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row[item.prop]==1?'已处理':'未处理' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
            <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">

          <el-button
            @click.native.prevent="handlertong(row)"
            type="text"
            style="margin-left: 10px"
            size="small">
            {{ row.status==1?'不处理':'处理' }}
          </el-button>
          <el-button
            @click.native.prevent="handleEdit(row)"
            type="text"
            style="margin-left: 10px"
            size="small">
            变更情况
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <changeslist ref="changes"/>
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  import Changeslist from "./components/changeslist";

  export default {
    name: 'Index',
    components: { Changeslist },
    data() {
      return {
        form: {
          status: '',
          name: '',
          type: '2',
          is_only:'1',
          page: 1,
          limit: 10,
        },
        callselect: [

          {
            id: 0,
            name: '未处理',
          },
          {
            id: 1,
            name: '已处理',
          },
        ],
        goodsselect: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '店铺ID',
            prop: 'data_id',
            width: '',
          },

          {
            label: '店铺名称',
            prop: 'name',
            width: '',
          },
          {
            label: '修改时间',
            prop: 'created',
            width: '',
          },
          {
            label: '审核时间',
            prop: 'status_time',
            width: '',
          },
          {
            label: '状态',
            prop: 'status',
            width: '',
          },

        ],
        total: 0,
      }
    },
    mounted() {
      this.handlerInquire()
      // this.handlerSelect()
    },
    methods: {
      handlertong(row){
        let statusopen
        if(row.status==1){
          statusopen=0
        }else{
          statusopen=1
        }

        getAction('/mall/shop/update-log-status',{id:row.id,status:statusopen}).then(res=>{
          this.handlerInquire()
          this.$message({
            type: 'success',
            message: res.msg,
          })
        })
      },
      handleEdit(row){
        this.$refs.changes.dialogVisible=true
        this.$refs.changes.form.data_id=row.data_id
        this.$refs.changes.handlerInquire()
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      /* async  handlerSelect(){
     const {data} = await getAction("/mall/product/goods")
      this.goodsselect=data
    },*/
      handlerlist() {
        getAction('/mall/shop/update-log-list', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
