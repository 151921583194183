var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.addshow, width: "50%" },
      on: {
        "update:visible": function ($event) {
          _vm.addshow = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "180px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "标题" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景色" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "背景色" },
                    model: {
                      value: _vm.form.bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bg_color", $$v)
                      },
                      expression: "form.bg_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "查看更多按钮颜色" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "查看更多按钮颜色" },
                    model: {
                      value: _vm.form.more_button,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "more_button", $$v)
                      },
                      expression: "form.more_button",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "悬浮图标" } },
                [
                  _c("upload-img", {
                    ref: "float_img",
                    attrs: { "info-text": "建议尺寸114*114", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "float_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.form.float_img != ""
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "悬浮按钮关联中转页",
                        prop: "transfer_id",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择关联中转页" },
                          model: {
                            value: _vm.form.transfer_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "transfer_id", $$v)
                            },
                            expression: "form.transfer_id",
                          },
                        },
                        _vm._l(_vm.jump_type, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "栏目名称选中的填充色",
                    prop: "menu_select_color",
                  },
                },
                [
                  _c("el-color-picker", {
                    model: {
                      value: _vm.form.menu_select_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "menu_select_color", $$v)
                      },
                      expression: "form.menu_select_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "栏目名称未选中的填充色",
                    prop: "menu_no_select",
                  },
                },
                [
                  _c("el-color-picker", {
                    model: {
                      value: _vm.form.menu_no_select,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "menu_no_select", $$v)
                      },
                      expression: "form.menu_no_select",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "栏目名称未选中的文字色",
                    prop: "menu_no_select_color",
                  },
                },
                [
                  _c("el-color-picker", {
                    model: {
                      value: _vm.form.menu_no_select_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "menu_no_select_color", $$v)
                      },
                      expression: "form.menu_no_select_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小程序分享图" } },
                [
                  _c("upload-img", {
                    ref: "share_img",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "share_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "H5分享图" } },
                [
                  _c("upload-img", {
                    ref: "h5_share_img",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "h5_share_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享标题" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "分享标题" },
                    model: {
                      value: _vm.form.share_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_title", $$v)
                      },
                      expression: "form.share_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享描述" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "分享描述" },
                    model: {
                      value: _vm.form.share_desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_desc", $$v)
                      },
                      expression: "form.share_desc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "底部logo" } },
                [
                  _c("upload-img", {
                    ref: "foot_logo",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "foot_logo")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签到开始时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.form.integral_stime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "integral_stime", $$v)
                      },
                      expression: "form.integral_stime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签到结束时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.form.integral_etime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "integral_etime", $$v)
                      },
                      expression: "form.integral_etime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }