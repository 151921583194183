var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "80px", inline: "" },
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.$refs.tongji.showliu = true
                      _vm.$refs.tongji.onSearch()
                    },
                  },
                },
                [_vm._v("统计")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.$refs.liu.showliu = true
                      _vm.$refs.liu.getList()
                    },
                  },
                },
                [_vm._v("留言统计")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "选择月榜单", clearable: "" },
                  model: {
                    value: _vm.form.month,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "month", $$v)
                    },
                    expression: "form.month",
                  },
                },
                _vm._l(_vm.monthlist, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "选择品类", clearable: "" },
                  on: { change: _vm.handlerlist },
                  model: {
                    value: _vm.form.category_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "category_id", $$v)
                    },
                    expression: "form.category_id",
                  },
                },
                _vm._l(_vm.catelist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "榜单产品名称", clearable: "" },
                model: {
                  value: _vm.form.goods_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "goods_name", $$v)
                  },
                  expression: "form.goods_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "状态", clearable: "" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "已发布", value: "1" } }),
                  _c("el-option", { attrs: { label: "待发布", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleradd } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerload } },
                [_vm._v("确认发布")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tabledata, border: "" },
        },
        _vm._l(_vm.columns, function (item, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              prop: item.prop,
              align: "center",
              label: item.label,
              width: item.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row, $index }) {
                    return [
                      item.label == "操作"
                        ? [
                            _vm.form.category_id != ""
                              ? _c("span", [
                                  $index != 0
                                    ? _c("i", {
                                        staticClass: "el-icon-top",
                                        staticStyle: {
                                          padding: "0px 10px",
                                          fontSize: "20px",
                                          color: "#00a0e9",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlertop($index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: $index < _vm.total - 1,
                                        expression: "$index < total - 1",
                                      },
                                    ],
                                    staticClass: "el-icon-bottom",
                                    staticStyle: {
                                      padding: "0px 10px",
                                      fontSize: "20px",
                                      color: "#00a0e9",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerbottom($index)
                                      },
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleredit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            row.is_link_goods == "否"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerStatus(row, "bind")
                                      },
                                    },
                                  },
                                  [_vm._v("关联云交会产品")]
                                )
                              : _vm._e(),
                            row.is_link_goods == "是"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerundo(row)
                                      },
                                    },
                                  },
                                  [_vm._v("取消关联")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerdelete(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        : [_vm._v(" " + _vm._s(row[item.prop]) + " ")],
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.page,
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.form.limit,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("bindgoods", {
        ref: "bind",
        attrs: { categoryList: _vm.catelist },
        on: { getlist: _vm.handlerlist },
      }),
      _c("addgoods", {
        ref: "add",
        attrs: { catelist: _vm.catelist },
        on: { refresh: _vm.handlerlist },
      }),
      _c("liulist", { ref: "liu", attrs: { catelist: _vm.catelist } }),
      _c("tongji", {
        ref: "tongji",
        attrs: { catelist: _vm.catelist, monthlist: _vm.monthlist },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }