<!-- 华糖会员统计-用户分析 -->
<template>
  <div class="datawrap">
    <div class="flexwrap">
      <h3>核心数据</h3>
      <p class="flexdate">
        <el-select
          v-model="form.is_staff"
          clearable
          placeholder="是否员工"
          style="width: 120px"
          @change="handlerStaff"
        >
          <el-option
            v-for="list in staffList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
        时间：
        <span
          :class="dateindex == 1 ? 'datecurrent' : ''"
          @click="dateindex = 1"
        >
          7天
        </span>
        <span
          :class="dateindex == 2 ? 'datecurrent' : ''"
          @click="dateindex = 2"
        >
          30天
        </span>
        <span
          :class="dateindex == 3 ? 'datecurrent' : ''"
          @click="dateindex = 3"
        >
          自定义
        </span>
      </p>
    </div>
    <div v-if="dateindex == 3" style="text-align: right">
      <el-date-picker
        v-model="time"
        type="daterange"
        range-separator="-"
        value-format="yyyy-MM-dd"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
    </div>
    <div class="numbox">
      <div>
        <p>数字刊</p>
        <p>浏览量：{{ zkNum.pv }}</p>
        <p>访问人数:{{ zkNum.uv }}</p>
      </div>
      <div>
        <p>实战课</p>
        <p>浏览量：{{ xktNum.pv }}</p>
        <p>访问人数:{{ xktNum.uv }}</p>
      </div>
      <div>
        <p>线下研修</p>
        <p>浏览量：{{ yxNum.pv }}</p>
        <p>访问人数:{{ yxNum.uv }}</p>
      </div>
      <div>
        <p>行业报告</p>
        <p>浏览量：{{ bgNum.pv }}</p>
        <p>访问人数:{{ bgNum.uv }}</p>
      </div>
    </div>
    <div class="flexwrap">
      <h3>访问数据</h3>
      <div style="display: flex">
        <p class="flextag">
          <span
            :class="trendindex == 1 ? 'current' : ''"
            @click="trendindex = 1"
          >
            趋势图
          </span>
          <span
            :class="trendindex == 2 ? 'current' : ''"
            @click="trendindex = 2"
          >
            数据列表
          </span>
        </p>
        <a
          href="javascript:;"
          style="display: inline-block; line-height: 30px; padding: 12px 10px"
          @click="handleExport"
        >
          下载Excel
        </a>
      </div>
    </div>
    <div v-if="trendindex == 1" style="max-width: 1500px">
      <div id="Echarts" style="width: 100%; height: 400px"></div>
    </div>
    <div v-if="trendindex == 2">
      <el-form ref="form" inline :model="form">
        <el-form-item label="">
          <el-select
            v-model="form.club_vip_id"
            clearable
            placeholder="套餐类型"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in viptype"
              :key="idx"
              :label="i.title"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <!-- <el-input v-model.trim="form.invite_name" placeholder="邀请人" /> -->
          <el-select
            v-model="form.invite_name"
            clearable
            filterable
            placeholder="邀请人"
          >
            <el-option
              v-for="(i, idx) in staffSelect"
              :key="idx"
              :label="i.name"
              :value="i.htyun_name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="form.vip_status"
            clearable
            placeholder="状态"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in vipstatus"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="">
          <el-select
            v-model="form.is_staff"
            clearable
            placeholder="是否员工"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in staffstatus"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="">
          <el-select
            v-model="form.data_type"
            clearable
            placeholder="全部来源"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in datatypelist"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="listLoading"
        border
        :data="tabledata"
        style="width: 100%"
        :default-sort="{ prop: 'created', order: 'descending' }"
      >
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '是否员工'">
              {{ row.is_staff == '1' ? '是' : '否' }}
            </div>
            <div v-else-if="item.label == '状态'">
              <span v-if="row.vip_status == '0'">暂无订单</span>
              <span v-else-if="row.vip_status == '1'">已开通</span>
              <span v-else-if="row.vip_status == '2'">已过期</span>
              <span v-else-if="row.vip_status == '4'">未支付</span>
            </div>
            <div v-else-if="item.label == '来源'">
              {{ row.source }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import dateFormat from '@/utils/Timeformat'
  import echarts from 'echarts'
  export default {
    name: 'htmembertjuser',
    data() {
      return {
        listLoading: false,
        time: [],
        total: 0,
        form: {
          date_type: '1', //1.7天 2.30天 3.自定义时间
          start_time: '', //自定义起始时间
          end_time: '', //自定义截止时间
          club_vip_id: '', //套餐类型
          vip_status: '', //套餐状态 1.待支付 2.已开通
          is_staff: '', //是否员工 0 否 1是
          invite_name: '', //邀请人
          page: 1,
          limit: 10,
        },
        staffList: [
          {
            id: '',
            name: '全部',
          },
          {
            id: '0',
            name: '非员工',
          },
          {
            id: '1',
            name: '员工',
          },
        ],
        viptype: [],
        staffstatus: [
          {
            id: '0',
            name: '否',
          },
          {
            id: '1',
            name: '是',
          },
        ],
        vipstatus: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 1,
            name: '已开通',
          },
          {
            id: 2,
            name: '已过期',
          },
          // {
          //   id: 3,
          //   name: '未激活',
          // },
          {
            id: 4,
            name: '未支付',
          },
        ],
        datatypelist: [
          {
            id: 1,
            name: '华糖会员',
          },
          {
            id: 2,
            name: '线下研修',
          },
          {
            id: 3,
            name: '实战课',
          },
          {
            id: 4,
            name: '数字刊',
          },
          {
            id: 5,
            name: '趋势报告',
          },
        ],
        trendindex: 1,
        dateindex: 1,
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
          },
          {
            label: 'userid',
            prop: 'user_id',
            width: '',
          },
          {
            label: '姓名',
            prop: 'nickname',
            width: '',
          },
          {
            label: '手机号码',
            prop: 'phone',
            width: '',
          },
          {
            label: '公司',
            prop: 'company',
            width: '',
          },
          {
            label: '是否员工',
            prop: 'is_staff',
            width: '',
          },
          {
            label: '套餐类型',
            prop: 'vip_title',
            width: '',
          },
          {
            label: '状态',
            prop: 'vip_status',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'invite_name',
            width: '',
          },
          {
            label: '来源',
            prop: 'source',
            width: '',
          },
          {
            label: '来源详情',
            prop: 'info_title',
            width: '',
          },
          {
            label: '时间',
            prop: 'created',
            width: '',
          },
        ],
        sourceMap: {
          1: '小程序(云交会我的)',
          2: '华糖云公众号',
          3: '商业家公众号',
          4: '短信',
          5: '海报',
          6: '微信好友',
          7: '访问时间',
        },
        datelist: [], //echarts日期数组
        ydatalist: [], //echarts数据列表
        ydatalist2: [],
        ydatalist3: [],
        ydatalist4: [],
        start_time: '',
        end_time: '',
        zkNum: {}, //数字刊
        xktNum: {}, //小课堂
        yxNum: {}, //线下研修
        bgNum: {}, //行业报告
        staffSelect: [],
      }
    },
    watch: {
      dateindex(n, o) {
        if (n == 3) {
        } else {
          this.datelist = []
          this.ydatalist = []
          this.handlerInquire()
          this.gettrenddata()
        }
      },

      time(val) {
        if (val) {
          this.start_time = val[0]
          this.end_time = val[1]
        } else {
          this.start_time = ''
          this.end_time = ''
        }
        this.datelist = []
        this.ydatalist = []
        this.handlerInquire()
        this.gettrenddata()
      },
      trendindex(n, o) {
        this.handlerInquire()
        setTimeout(() => {
          this.gettrenddata()
        }, 300)
      },
    },
    mounted() {
      this.handlerInquire()
      this.getVipinfo()
      this.getStaff()
      this.gettrenddata()
    },
    methods: {
      _onreset() {
        this.datelist = []
        this.ydatalist = []
        this.ydatalist2 = []
        this.ydatalist3 = []
        this.ydatalist4 = []
      },
      // 是否员工筛选
      handlerStaff(e) {
        console.log('----------e', e)
        this.handlerInquire()
        this.gettrenddata()
      },
      async getStaff() {
        const staff = await getAction('/form/form/staff')
        this.staffSelect = staff.data
      },
      gettrenddata() {
        console.log('获取趋势------')
        let params = {
          date_type: this.form.date_type,
          start_time: this.start_time,
          end_time: this.end_time,
          is_staff: this.form.is_staff,
        }

        getAction('/user/business-club/users-analyze-data', params).then(
          (res) => {
            this.zkNum = res.data.zkData[0]
            this.xktNum = res.data.xktData[0]
            this.yxNum = res.data.yxData[0]
            this.bgNum = res.data.bgData[0]
            console.log('日期列表-', this.yxNum, this.zkNum)
            if (this.trendindex == 1) {
              const myChart = echarts.init(document.getElementById('Echarts'))
              res.data.trend.map((list) => {
                this.datelist.push(Object.values(list)[0])
                this.ydatalist.push(Object.values(list)[1]) //数字刊
                this.ydatalist2.push(Object.values(list)[2]) //小课堂
                this.ydatalist3.push(Object.values(list)[3]) //线下研修
                this.ydatalist4.push(Object.values(list)[4]) //行业报告
              })

              let option = {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                    crossStyle: {
                      color: '#fff',
                    },
                  },
                },
                legend: {
                  data: ['数字刊', '小课堂', '线下研修', '行业报告'],
                },
                xAxis: {
                  type: 'category',
                  data: this.datelist,
                },
                yAxis: {
                  type: 'value',
                },
                dataZoom: [
                  {
                    id: 'dataZoomX',
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter',
                  },
                ],
                series: [
                  {
                    name: '数字刊',
                    data: this.ydatalist,
                    type: 'line',
                    // itemStyle: {
                    //   normal: {
                    //     color: '#5087ec', //改变折线点的颜色
                    //     lineStyle: {
                    //       color: '#5087ec', //改变折线颜色
                    //     },
                    //   },
                    // },
                  },
                  {
                    name: '小课堂',
                    data: this.ydatalist2,
                    type: 'line',
                    // itemStyle: {
                    //   normal: {
                    //     color: '#f00', //改变折线点的颜色
                    //     lineStyle: {
                    //       color: '#f00', //改变折线颜色
                    //     },
                    //   },
                    // },
                  },
                  {
                    name: '线下研修',
                    data: this.ydatalist3,
                    type: 'line',
                    itemStyle: {
                      normal: {
                        color: '#0f0', //改变折线点的颜色
                        lineStyle: {
                          color: '#0f0', //改变折线颜色
                        },
                      },
                    },
                  },
                  {
                    name: '行业报告',
                    data: this.ydatalist4,
                    type: 'line',
                    itemStyle: {
                      normal: {
                        color: '#00f', //改变折线点的颜色
                        lineStyle: {
                          color: '#00f', //改变折线颜色
                        },
                      },
                    },
                  },
                ],
              }
              myChart.setOption(option)
            }
          }
        )
      },
      handlerInquire() {
        this._onreset()
        this.form.page = 1
        this.handlerlist()
      },
      handleExport(row) {
        this.$message.success(
          '正在导出，因数据量大，导出时间可能会比较久，请耐心等待'
        )
        this.form.start_time = this.start_time
        this.form.end_time = this.end_time
        postAction('/user/business-club/users-access-export', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.data,
              filename: res.data.name,
            })
          }
        )
      },
      handlerlist() {
        this.listLoading = true
        this.form.date_type = this.dateindex
        this.form.start_time = this.start_time
        this.form.end_time = this.end_time
        getAction('/user/business-club/users-access-data', this.form).then(
          (res) => {
            if (res.code == 0) {
              this.tabledata = res.data
              this.total = res.totalCount
            }
            this.listLoading = false
            this.total = res.totalCount
          }
        )
      },
      //会员类型
      getVipinfo() {
        getAction('/user/business-club/vip-info').then((res) => {
          this.viptype = res.data
        })
      },
      // 处理每页显示条数变化
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
      // 处理页码变化
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .numbox {
    display: flex;
    font-size: 18px;
    width: 100%;
    font-weight: bold;

    div {
      flex: 1;
      // border: 1px solid #eee;
      border-radius: 4px;
      margin: 10px;
      color: #fff;
      &:nth-child(1) {
        background: url('../../../../assets/tj_images/1.png') center;
        background-size: cover;
      }
      &:nth-child(2) {
        background: url('../../../../assets/tj_images/2.png') center;
        background-size: cover;
      }
      &:nth-child(3) {
        background: url('../../../../assets/tj_images/3.png') center;
        background-size: cover;
      }
      &:nth-child(4) {
        background: url('../../../../assets/tj_images/4.png') center;
        background-size: cover;
      }
      p {
        text-align: center;
      }
      p:nth-child(2),
      p:nth-child(3) {
        font-size: 15px;
      }
    }
  }
  .datawrap {
    padding: 0 10px;
    border: 1px solid #f1f1f1;
  }
  .flexwrap {
    display: flex;
    justify-content: space-between;
    h3 {
      padding-left: 20px;
    }
    p {
      span {
        cursor: default;
        margin: 0 6px;
      }
    }
    .flexdate {
      margin-left: 20px;
      line-height: 26px;
      .datecurrent {
        color: #f00;
      }
    }
    .flextag {
      padding: 5px 2px;
      border: 1px solid #ccc;
      span {
        &:first-child {
          margin-right: 0;
          padding-right: 10px;
          border-right: 1px solid #ccc;
        }
        &.current {
          color: #f00;
        }
      }
    }
  }
</style>
