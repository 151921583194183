<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    width="800px"
    @closed="handleClose"
  >
    <el-form ref="form" label-width="160px" :model="form" :rules="rules">
      <div class="form-section">
        <h3>收件人信息</h3>
        <el-form-item label="收件人姓名">
          <el-input
            v-model="form.recipient_name"
            placeholder="请输入收件人姓名"
          />
        </el-form-item>
        <el-form-item label="收件人公司">
          <el-input
            v-model="form.recipient_company"
            placeholder="请输入收件人公司"
          />
        </el-form-item>
        <el-form-item label="收件人手机号">
          <el-input
            v-model="form.recipient_phone"
            placeholder="手机号和电话必填一个"
          />
        </el-form-item>
        <el-form-item label="收件人电话">
          <el-input
            v-model="form.recipient_mobile"
            placeholder="手机号和电话必填一个"
          />
        </el-form-item>
        <el-form-item label="收件人地址" prop="recipient_address">
          <el-input
            v-model="form.recipient_address"
            placeholder="请输入收件人地址"
          />
        </el-form-item>
      </div>

      <div class="form-section">
        <h3>发件人信息</h3>
        <el-form-item label="发件人姓名">
          <el-input v-model="form.sender_name" placeholder="请输入发件人姓名" />
        </el-form-item>
        <el-form-item label="发件人公司">
          <el-input
            v-model="form.sender_company"
            placeholder="请输入发件人公司"
          />
        </el-form-item>
        <el-form-item label="发件人手机号">
          <el-input
            v-model="form.sender_phone"
            placeholder="请输入发件人手机号"
          />
        </el-form-item>
        <el-form-item label="发件人电话">
          <el-input
            v-model="form.sender_mobile"
            placeholder="请输入发件人电话"
          />
        </el-form-item>
        <el-form-item label="发件人地址" prop="sender_address">
          <el-input
            v-model="form.sender_address"
            placeholder="请输入发件人地址"
          />
        </el-form-item>
      </div>

      <div class="form-section">
        <h3>商品信息</h3>
        <el-form-item label="商品名称">
          <el-input v-model="form.goods_name" placeholder="请输入商品名称" />
        </el-form-item>
        <el-form-item label="内件详情">
          <el-input v-model="form.goods_detail" placeholder="请输入内件详情" />
        </el-form-item>
        <el-form-item label="商品数量">
          <el-input-number v-model="form.goods_num" :min="1" />
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group v-model="form.goods_type_id">
            <el-radio
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="月份" prop="month">
          <el-date-picker
            v-model="form.month"
            placeholder="请选择月份"
            type="month"
            value-format="yyyy年MM月"
          />
        </el-form-item>
        <el-form-item label="重量(kg)">
          <el-input-number
            v-model="form.goods_weight"
            :min="0"
            :precision="2"
          />
        </el-form-item>
        <el-form-item label="尺寸">
          <div style="display: flex; gap: 10px">
            <el-input
              v-model="form.goods_length"
              placeholder="长"
              style="width: 120px"
            >
              <template slot="suffix">cm</template>
            </el-input>
            <el-input
              v-model="form.goods_width"
              placeholder="宽"
              style="width: 120px"
            >
              <template slot="suffix">cm</template>
            </el-input>
            <el-input
              v-model="form.goods_height"
              placeholder="高"
              style="width: 120px"
            >
              <template slot="suffix">cm</template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.note"
            placeholder="请输入备注"
            :rows="3"
            type="textarea"
          />
        </el-form-item>
      </div>
      <div class="form-section">
        <h3>物流信息</h3>
        <el-form-item label="客户订单号">
          <el-input
            v-model="form.logistics_order"
            placeholder="请输入客户订单号"
          />
        </el-form-item>
        <el-form-item label="物流公司">
          <el-select
            v-model="form.logistics_company_id"
            clearable
            filterable
            placeholder="请选择物流公司"
          >
            <el-option
              v-for="(item, index) in wuliuList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" prop="logistics_number">
          <el-input
            v-model="form.logistics_number"
            placeholder="请输入物流单号"
          />
        </el-form-item>
        <el-form-item label="寄递产品">
          <el-input
            v-model="form.logistics_goods"
            placeholder="请输入寄递产品"
          />
        </el-form-item>
        <el-form-item label="付款方式" prop="pay_type">
          <el-radio-group v-model="form.pay_type">
            <el-radio label="1">寄付</el-radio>
            <el-radio label="2">到付</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="寄速费">
          <el-input
            v-model="form.send_price"
            placeholder="请输入寄速费"
            style="width: 200px"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="到付费">
          <el-input
            v-model="form.cod_price"
            placeholder="请输入到付费"
            style="width: 200px"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="保价金额">
          <el-input
            v-model="form.insurance_price"
            placeholder="请输入保价金额"
            style="width: 200px"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="代收货款">
          <el-input
            v-model="form.collect_price"
            placeholder="请输入代收货款"
            style="width: 200px"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="密码投递 (收取服务费1元)"
          style="white-space: nowrap; margin-right: 20px"
        >
          <el-radio-group v-model="form.is_password">
            <el-radio :label="'1'">是</el-radio>
            <el-radio :label="'0'">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="返单服务">
          <el-radio-group v-model="form.return_service">
            <el-radio label="1">电子返单</el-radio>
            <el-radio label="2">实物返单</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'AddDialog',
    props: {
      staffList: {
        type: Array,
        default: () => [],
      },
      typeList: {
        type: Array,
        default: () => [],
      },
      wuliuList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        dialogVisible: false,
        isEdit: false,
        form: {
          recipient_name: '',
          recipient_company: '',
          recipient_phone: '',
          recipient_mobile: '',
          recipient_address: '',
          sender_name: '',
          sender_company: '',
          sender_phone: '',
          sender_mobile: '',
          sender_address: '',
          goods_name: '',
          goods_detail: '',
          goods_num: 1,
          goods_type_id: '',
          month: '',
          goods_weight: '',
          goods_length: '',
          goods_width: '',
          goods_height: '',
          logistics_number: '',
          logistics_goods: '',
          logistics_company_id: '',
          pay_type: '1',
          send_price: '',
          cod_price: '',
          insurance_price: '',
          collect_price: '',
          logistics_order: '',
          is_password: '0',
          return_service: '1',
          note: '',
        },
        rules: {
          recipient_address: [
            { required: true, message: '请输入收件人地址', trigger: 'blur' },
          ],
          sender_address: [
            { required: true, message: '请输入发件人地址', trigger: 'blur' },
          ],
          month: [{ required: true, message: '请选择月份', trigger: 'blur' }],
          logistics_number: [
            { required: true, message: '请输入物流单号', trigger: 'blur' },
          ],
          pay_type: [
            { required: true, message: '请选择付款方式', trigger: 'blur' },
          ],
        },
      }
    },
    computed: {
      dialogTitle() {
        return this.isEdit ? '编辑物流订单' : '新增物流订单'
      },
    },
    watch: {
      typeList: {
        immediate: true,
        handler(val) {
          if (val && val.length > 0) {
            this.form.goods_type_id = val[0].id
          }
        },
      },
    },
    methods: {
      show(row) {
        this.dialogVisible = true
        this.isEdit = !!row
        if (row) {
          this.form = { ...row }
          this.form.goods_type_id = parseInt(row.goods_type_id)
          for (let index = 0; index < this.wuliuList.length; index++) {
            const element = this.wuliuList[index]
            if (element.name == row.company_name) {
              this.form.logistics_company_id = element.id
              break
            }
          }
        } else {
          // 先重置 form 对象到初始状态
          this.form = {
            recipient_name: '',
            recipient_company: '',
            recipient_phone: '',
            recipient_mobile: '',
            recipient_address: '',
            sender_name: '',
            sender_company: '',
            sender_phone: '',
            sender_mobile: '',
            sender_address: '',
            goods_name: '',
            goods_detail: '',
            goods_num: 1,
            goods_type_id: '',
            month: '',
            goods_weight: '',
            goods_length: '',
            goods_width: '',
            goods_height: '',
            logistics_number: '',
            logistics_goods: '',
            logistics_company_id: '',
            pay_type: '1',
            send_price: '',
            cod_price: '',
            insurance_price: '',
            collect_price: '',
            logistics_order: '',
            is_password: '0',
            return_service: '1',
            note: '',
          }
          this.$refs.form && this.$refs.form.resetFields()
          console.log('form:', this.form)
          if (this.typeList && this.typeList.length > 0) {
            this.form.goods_type_id = this.typeList[0].id
          }
        }
      },
      handleClose() {
        this.form = this.$options.data().form
        this.isEdit = false
      },
      handleSubmit() {
        if (
          this.form.recipient_phone.length == 0 &&
          this.form.recipient_mobile.length == 0
        ) {
          this.$message.error('收件人手机号和电话必填一个')
          return
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$emit('confirm', { ...this.form })
            this.dialogVisible = false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .form-section {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;

    h3 {
      margin: 0 0 20px;
      padding-left: 10px;
      border-left: 4px solid #409eff;
      font-size: 16px;
      line-height: 1;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
</style>
