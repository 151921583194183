<template>
  <div class="permission-set">
    <div>
      <el-form
        :model="formData"
        :rules="rules"
        ref="formRef"
        label-width="140px"
      >
        <p class="title">设置最大产品数量</p>
        <el-form-item label="最大产品数量" prop="free_mall_goods_num">
          <el-input
            v-model="formData.free_mall_goods_num"
            placeholder="请输入"
            type="number"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <p class="title">设置电话相关</p>
        <el-form-item label="电话" prop="free_mall_is_phone">
          <el-radio-group v-model="formData.free_mall_is_phone">
            <el-radio :label="'1'">可以接到</el-radio>
            <el-radio :label="'2'">
              不能接到
              <el-tooltip
                content="可以接到指的是可以接到经销商电话，不能接到经销商会打到400电话"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <p class="title">设置留言相关</p>

        <el-form-item label="查看加密的旧留言" prop="free_mall_is_old_suggest">
          <el-radio-group v-model="formData.free_mall_is_old_suggest">
            <el-radio :label="'1'">扣除意向线索次数</el-radio>
            <el-radio :label="'2'">
              不扣除任何次数
              <el-tooltip
                content="旧留言指的是购买最新套餐之前的留言"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <p class="title">设置样品申请相关</p>
        <el-form-item label="样品申请" prop="free_mall_is_sample">
          <el-radio-group v-model="formData.free_mall_is_sample">
            <el-radio :label="'1'">可以接到</el-radio>
            <el-radio :label="'2'">不能接到</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span style="margin-left: 45%">
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </div>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'PermissionSet',
    data() {
      return {
        tableData: [],
        formData: {},
        rules: {
          free_mall_goods_num: [
            { required: true, message: '请输入最大产品数量', trigger: 'blur' },
          ],
        },
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      fetchData() {
        getAction('/api/system/conf/free-mall-conf', this.form).then((res) => {
          console.log('res', res)
          this.formData = res.data
          console.log('free_mall_goods_num---', this.free_mall_goods_num)
        })
      },
      handlersave() {
        this.$refs['formRef'].validate((valid) => {
          if (valid) {
            postAction('/api/system/conf/set-free-mall', this.formData).then(
              (res) => {
                console.log('res', res)
                if (res.code == 0) {
                  this.$message.success('保存成功')
                } else {
                  this.$message.error('保存失败')
                }
              }
            )
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .permission-set {
    padding: 10px;
    .title {
      // text-indent: 2em;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      border-top: 1px solid #e6e6e6;
      padding-top: 30px;
      &:first-child {
        border-top: none;
        padding-top: 10px;
      }
    }
  }
</style>
