var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showedit, width: "80%" },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handlerExport } },
        [_vm._v("导出")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableloading,
              expression: "tableloading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "customer_name", label: "客户姓名", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "staff_name", label: "标签添加人", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createtime", label: "标签添加时间", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "is_perfect", label: "标签是否已完善", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "83", label: "行业", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "59", label: "类别", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "219", label: "身份", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "191", label: "渠道", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "mobile", label: "区域", width: "" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.page,
          layout: _vm.layout,
          "page-size": _vm.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showedit = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }