<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="50%">
      <div>
        <el-form :model="form" label-width="120px" :rules="rules" ref="form">
          <el-form-item label="企业名称" prop="mall_name">
            <el-input
              placeholder="请输入企业名称"
              v-model="form.mall_name"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="主营产品" prop="main_product">
            <el-input
              placeholder="请输入主营产品"
              v-model="form.main_product"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="企业分类" prop="cat_id">
            <el-select
              v-model="form.cat_id"
              clearable
              style="width: 160px"
              placeholder="请选择"
              @change="handlerjuptype"
            >
              <el-option
                v-for="list in cateSelect"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="头像" prop="logo">
            <upload-img
              ref="uploadImage"
              infoText="建议尺寸500*500,大小不超过2MB"
              :limit="1"
              @uploadImg="uploadImg"
              :maxSize="100"
              :booleancorpper="true"
              :enlarge="2"
              :auto-crop-width="250"
              :auto-crop-height="250"
              :mode="'500px,500px'"
            />
          </el-form-item>
          <el-form-item label="公司介绍" prop="title">
            <el-input
              placeholder="请输入公司介绍"
              v-model="form.introduce"
              style="width: 300px"
              type="textarea"
            />
          </el-form-item>
          <el-form-item label="招商代理区域" prop="title">
            <el-input
              placeholder="请输入招商代理区域"
              v-model="form.province"
              style="width: 300px"
              type="textarea"
            />
          </el-form-item>
          <el-form-item label="展馆名称" prop="">
            <el-input
              placeholder="请输入展馆名称"
              v-model="form.number_house"
              style="width: 220px"
              type="text"
            />
          </el-form-item>
          <el-form-item label="展位号" prop="">
            <el-input
              placeholder="请输入展位号"
              v-model="form.number"
              style="width: 220px"
              type="text"
            />
          </el-form-item>
          <el-form-item label="专属报名链接" prop="afbc_url">
            <el-input
              placeholder="https://"
              v-model="form.afbc_url"
              style="width: 220px"
              type="text"
            />
          </el-form-item>
          <el-form-item label="排序" prop="title">
            <el-input
              placeholder="数字越大靠前排"
              v-model="form.sort_order"
              style="width: 220px"
              type="number"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { uploadImg },
    data() {
      return {
        title: '',
        isShow: false,
        form: {},
        cateSelect: [],
        rules: {
          mall_name: [
            { required: true, message: '请输入企业名称', trigger: 'blur' },
          ],
          main_product: [
            { required: true, message: '请输入主营产品', trigger: 'blur' },
          ],
          cat_id: [
            { required: true, message: '请选择企业分类', trigger: 'blur' },
          ],
          logo: [
            { required: true, message: '请上传企业头像', trigger: 'blur' },
          ],
        },
      }
    },

    watch: {
      isShow(n, o) {
        if (!n) {
          this._onreset()
        } else {
          this.getcate()
        }
      },
    },
    mounted() {},
    methods: {
      handlerinfo(row) {
        getAction('/api/afbc/act-company/info', { id: row.id }).then((res) => {
          if (res.code == 0) {
            this.form = res.data
            this.setImg(res.data.logo, 'uploadImage')
          }
        })
      },
      handlerjuptype(e) {},
      // 企业分类、品类
      getcate() {
        getAction('/user/index/cate').then((res) => {
          if (res.code == 0) {
            this.cateSelect = res.data
          }
        })
      },
      handlersave() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            postAction('/api/afbc/act-company/edit', this.form).then((res) => {
              if (res.code == 0) {
                this.isShow = false
                this.$message.success(res.msg)
                this.$emit('getlist')
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.logo = ''
        } else {
          this.form.logo = val[0]
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      _onreset() {
        this.closeimg('uploadImage')
        this.form = {
          mall_name: '',
          logo: '',
          main_product: '',
          cat_id: '',
          introduce: '',
          sort_order: 0,
          // status: 1,
          province: '',
        }
        this.$refs.form.clearValidate()
      },
    },
  }
</script>
