var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px 10px" } },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleredit()
                    },
                  },
                },
                [_vm._v(" 新增 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: { data: _vm.tabledata, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: "模板类型",
              align: "center",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(row.type == "1" ? "企业" : "产品") + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: "海报名称",
              align: "center",
              width: "90",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "list_img", label: "海报", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return _vm._l(row.list_img, function (list, index) {
                    return _c(
                      "span",
                      { key: index },
                      [
                        _c(
                          "el-image",
                          {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { "preview-src-list": [list], src: list },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "placeholder" },
                                slot: "placeholder",
                              },
                              [
                                _vm._v(" 加载中 "),
                                _c("span", { staticClass: "dot" }, [
                                  _vm._v("..."),
                                ]),
                              ]
                            ),
                            _c("div", {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "状态",
              align: "center",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(row.status == "1" ? "启用" : "禁用") + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "created",
              label: "创建时间",
              align: "center",
              width: "90",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleredit(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确认删除吗？" },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteRow(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "text",
                            },
                            slot: "reference",
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { getlist: _vm.handlerlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }