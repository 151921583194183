<!-- 物流管理页面组件 -->
<template>
  <!-- 页面容器 -->
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="收件人姓名">
        <el-input
          v-model="form.recipient_name"
          clearable
          placeholder="请输入收件人姓名"
        />
      </el-form-item>
      <el-form-item label="收件人手机号">
        <el-input
          v-model="form.recipient_phone"
          clearable
          placeholder="请输入收件人手机号"
        />
      </el-form-item>
      <el-form-item label="发件人姓名">
        <el-input
          v-model="form.sender_name"
          clearable
          placeholder="请输入发件人姓名"
        />
      </el-form-item>
      <!-- <el-form-item label="邀请人">
        <el-select v-model="form.inviter" placeholder="请选择邀请人" clearable filterable>
          <el-option
            v-for="(i, idx) in staffSelect"
            :key="idx"
            :label="i.name"
            :value="i.id"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item label="商品名称">
        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="请输入商品名称"
        />
      </el-form-item>
      <el-form-item label="商品类型">
        <el-select
          v-model="form.goods_type"
          clearable
          placeholder="请选择商品类型"
        >
          <!-- 添加选项 -->
          <el-option
            v-for="item in goodsTypeArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="月份">
        <el-date-picker
          v-model="form.month"
          clearable
          placeholder="请选择月份"
          type="month"
          value-format="yyyy年MM月"
        />
      </el-form-item>
      <el-form-item label="物流公司">
        <el-select
          v-model="form.logistics_company_id"
          clearable
          placeholder="请选择物流公司"
        >
          <el-option
            v-for="item in wuliuArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="物流单号">
        <el-input
          v-model="form.logistics_number"
          clearable
          placeholder="请输入物流单号"
        />
      </el-form-item>
      <el-form-item label="付款方式">
        <el-select
          v-model="form.pay_type"
          clearable
          placeholder="请选择付款方式"
        >
          <el-option label="寄付" value="1" />
          <el-option label="到付" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleImport">导入</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleExport">导出</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleColumnSetting">
          列表字段设置
        </el-button>
      </el-form-item>
    </el-form>

    <!-- 隐藏的el-upload组件 -->
    <el-upload
      ref="upload"
      action=""
      :before-upload="beforeUpload"
      :show-file-list="false"
    />

    <el-table v-loading="loading" border :data="tableData" style="width: 100%">
      <el-table-column
        align="center"
        fixed="left"
        label="收件人姓名"
        prop="recipient_name"
        width="120"
      />
      <el-table-column
        v-for="(item, index) in visibleColumns.slice(1)"
        :key="index"
        align="center"
        :label="item.label"
        min-width="180"
        :prop="item.prop"
      />
      <el-table-column align="center" fixed="right" label="操作" width="150">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="showDeleteConfirm(row)">
            删除
          </el-button>
          <el-button type="text" @click="handleCheck(row)">查看物流</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页组件 -->
    <el-pagination
      v-if="total > 10"
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 20px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />

    <!-- 列表字段设置对话框 -->
    <el-dialog title="列表字段设置" :visible.sync="dialogVisible" width="30%">
      <el-checkbox-group v-model="selectedColumns">
        <el-checkbox
          v-for="column in allColumns.slice(1)"
          :key="column.key"
          :label="column.key"
        >
          {{ column.name }}
        </el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmColumnSetting">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <add-dialog
      ref="addDialog"
      :staff-list="staffSelect"
      :type-list="goodsTypeArr"
      :wuliu-list="wuliuArr"
      @confirm="handleAddConfirm"
    />
    <import-dialog
      ref="importDialog"
      :type-list="goodsTypeArr"
      :wuliu-list="wuliuArr"
      @import-confirm="handleImportConfirm"
    />

    <!-- 删除确认弹窗 -->
    <el-dialog title="确认删除" :visible.sync="deleteDialogVisible" width="30%">
      <span>确定要删除这条记录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="danger" @click="confirmDelete">确认删除</el-button>
      </span>
    </el-dialog>

    <!-- 物流查看弹窗 -->
    <el-dialog
      class="logistics-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      title="物流信息"
      top="5vh"
      :visible.sync="logisticsDialogVisible"
      width="375px"
    >
      <iframe
        frameborder="0"
        :src="logisticsUrl"
        style="border-radius: 4px; overflow: hidden; height: 80vh"
        width="100%"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
  // 导入Excel处理库
  import * as XLSX from 'xlsx'
  import * as excel from '@/utils/excel'
  // 导入子组件
  import AddDialog from './components/AddDialog.vue'
  import ImportDialog from './components/ImportDialog.vue'
  // 导入API请求方法
  import { getAction, postAction } from '@/api/request'

  /**
   * 物流管理页面组件
   * @description 用于管理物流信息，包括查询、新增、编辑、删除、导入导出等功能
   */
  export default {
    name: 'WuliuIndex',
    components: {
      AddDialog,
      ImportDialog,
    },
    data() {
      // 组件数据
      return {
        // 查询表单数据
        form: {
          page: 1,
          limit: 10,
        },
        // 删除确认弹窗显示状态
        deleteDialogVisible: false,
        // 当前要删除的行数据
        currentDeleteRow: null,
        // 物流查看弹窗显示状态
        logisticsDialogVisible: false,
        // 物流查看页面URL
        logisticsUrl: '',
        // 表格数据
        tableData: [],
        // 列设置弹窗显示状态
        dialogVisible: false,
        // 已选择显示的列
        selectedColumns: [],
        // 分页相关数据
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        // 所有可配置的表格列
        allColumns: [
          // { prop: 'recipient_company', label: '收件人公司' },
          // { prop: 'recipient_phone', label: '收件人手机号' },
          // { prop: 'recipient_mobile', label: '收件人电话' },
          // { prop: 'recipient_address', label: '收件人地址' },
          // { prop: 'sender_name', label: '发件人姓名' },
          // { prop: 'sender_company', label: '发件人公司' },
          // { prop: 'sender_phone', label: '发件人手机号' },
          // { prop: 'sender_mobile', label: '发件人电话' },
          // { prop: 'sender_address', label: '发件人地址' },
          // { prop: 'goods_name', label: '商品名称' },
          // { prop: 'goods_detail', label: '内件详情' },
          // { prop: 'goods_num', label: '商品数量' },
          // { prop: 'goods_type', label: '商品类型' },
          // { prop: 'month', label: '月份' },
          // { prop: 'goods_weight', label: '重量kg' },
          // { prop: 'goods_length', label: '长（cm）' },
          // { prop: 'goods_width', label: '宽（cm）' },
          // { prop: 'goods_height', label: '高（cm）' },
          // { prop: 'note', label: '备注' },
          // { prop: 'logistics_order', label: '客户订单' },
          // { prop: 'logistics_number', label: '物流单号' },
          // { prop: 'logistics_goods', label: '寄递产品' },
          // { prop: 'pay_type_str', label: '付款方式' },
          // { prop: 'send_price', label: '寄递费' },
          // { prop: 'cod_price', label: '到付费' },
          // { prop: 'insurance_price', label: '保价金额' },
          // { prop: 'collect_price', label: '代收货款' },
          // { prop: 'is_password_str', label: '密码投递（收取服务费1元）' },
          // { prop: 'return_service_str', label: '返单服务' },
          // { prop: 'admin_name', label: '操作人' },
          // { prop: 'modified', label: '操作时间' },
        ],
        // 当前显示的表格列
        visibleColumns: [],
        // 员工列表数据
        staffSelect: [],
        //物流列表数据
        wuliuArr: [],
        //商品类型数据
        goodsTypeArr: [],
        loading: false,
      }
    },
    // 组件创建时的生命周期钩子
    created() {
      // selectedColumns的初始化移至getHeaderList中处理
    },
    // 组件挂载完成时的生命周期钩子
    mounted() {
      console.log('页面加载完成')
      this.getStaff()
      this.getHeaderList()
    },
    // 组件方法
    methods: {
      getStaff() {
        //获取物流信息
        getAction('user/logistics/company').then((res) => {
          console.log('获取物流结果《', res)
          if (res.request_code == 200) {
            this.wuliuArr = res.data
          }
        })
        //获取商品类型
        getAction('user/logistics/type').then((res) => {
          if (res.request_code == 200) {
            this.goodsTypeArr = res.data
          }
        })
      },
      //获取表头
      getHeaderList() {
        getAction('/user/logistics/admin-key').then((res) => {
          if (res.request_code == 200) {
            // 转换接口数据为组件所需格式
            let resData = res.data.map((item) => ({
              prop: item.key,
              label: item.name,
              key: item.key,
              name: item.name,
              is_show: item.is_show,
              id: item.id,
            }))
            resData.forEach((item) => {
              if (item.key === 'status') {
                item.prop = 'status_str'
                item.key = 'status_str'
              } else if (item.key === 'pay_type') {
                item.prop = 'pay_type_str'
                item.key = 'pay_type_str'
              } else if (item.key === 'is_password') {
                item.prop = 'is_password_str'
                item.key = 'is_password_str'
              } else if (item.key === 'return_service') {
                item.prop = 'return_service_str'
                item.key = 'return_service_str'
              }
            })
            this.allColumns = resData
            // 初始化selectedColumns，只包含is_show为1的列
            this.selectedColumns = this.allColumns
              .filter((col) => col.is_show === '1')
              .map((col) => col.key)
            // 更新可见列
            this.updateVisibleColumns()
            this.getList()
          }
        })
      },
      //获取列表数据
      getList() {
        let param = {}
        Object.keys(this.form).forEach((key) => {
          if (
            this.form[key] !== '' &&
            this.form[key] !== null &&
            this.form[key] !== undefined &&
            this.form[key] !== ''
          ) {
            param[key] = this.form[key]
          }
        })
        console.log('获取列表参数《', param)
        postAction('user/logistics/list-logistics', param).then((res) => {
          console.log('获取列表结果《', res)
          if (res.request_code == 200) {
            let data = res.data
            for (let i = 0; i < data.length; i++) {
              let item = data[i]
              if (item.status && item.status != '') {
                item.status_str =
                  item.status === '1'
                    ? '待发货'
                    : item.status === '2'
                    ? '已发货'
                    : item.status === '3'
                    ? '待收货'
                    : '已签收'
              }
              if (item.pay_type && item.pay_type != '') {
                item.pay_type_str = item.pay_type === '1' ? '寄付' : '到付'
              }
              if (item.is_password && item.is_password != '') {
                item.is_password_str = item.is_password == '1' ? '是' : '否'
              }
              if (item.return_service && item.return_service != '') {
                item.return_service_str =
                  item.return_service == '1' ? '电子返单' : '实物返单'
              }
            }
            this.tableData = data
            this.total = res.totalCount
          }
        })
      },
      formatDate(timestamp) {
        if (!timestamp) return ''
        const date = new Date(timestamp * 1000)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      },
      /**
       * 处理每页显示条数改变
       * @param {number} val 新的每页条数
       */
      handleSizeChange(val) {
        this.form.limit = val
        this.getList()
      },

      /**
       * 处理页码改变
       * @param {number} val 新的页码
       */
      handleCurrentChange(val) {
        this.form.page = val
        this.getList()
      },
      /**
       * 处理查询操作
       * @description 根据表单条件查询数据
       */
      handleQuery() {
        console.log('查询', this.form)
        this.getList()
      },
      /**
       * 更新显示的表格列
       * @description 根据选择的列更新表格显示
       */
      updateVisibleColumns() {
        this.allColumns.forEach((col) => {
          col.is_show = this.selectedColumns.includes(col.key) ? '1' : '0'
        })
        this.visibleColumns = this.allColumns.filter((col) =>
          this.selectedColumns.includes(col.key)
        )
      },
      /**
       * 处理列设置
       * @description 打开列设置弹窗
       */
      handleColumnSetting() {
        this.dialogVisible = true
      },
      /**
       * 确认列设置
       * @description 保存列设置并更新表格显示
       */
      handleConfirmColumnSetting() {
        this.updateVisibleColumns()
        this.dialogVisible = false
        const simplifiedColumns = this.allColumns.map((col) => ({
          id: col.id,
          is_show: col.is_show,
        }))
        console.log('简化后的列设置', simplifiedColumns)
        postAction('/user/logistics/save-admin-key', {
          keys: simplifiedColumns,
        }).then((res) => {
          if (res.request_code == 200) {
            this.$message.success('保存成功')
          }
        })
      },
      /**
       * 处理编辑操作
       * @param {Object} row 当前行数据
       */
      handleEdit(row) {
        this.$refs.addDialog.show(row)
      },
      /**
       * 显示删除确认弹窗
       * @param {Object} row 要删除的行数据
       */
      showDeleteConfirm(row) {
        this.currentDeleteRow = row
        this.deleteDialogVisible = true
      },
      /**
       * 确认删除操作
       * @description 从表格中删除选中的数据
       */
      confirmDelete() {
        if (this.currentDeleteRow) {
          postAction('/user/logistics/delete-logistics', {
            id: this.currentDeleteRow.id,
          }).then((res) => {
            console.log('删除结果《', res)
            if (res.request_code == 200) {
              this.$message.success('删除成功')
              this.getList()
            }
          })
        }
        this.deleteDialogVisible = false
        this.currentDeleteRow = null
      },
      /**
       * 处理查看物流操作
       * @param {Object} row 当前行数据
       */
      handleCheck(row) {
        this.logisticsDialogVisible = true
        console.log('行数据:', row)
        this.logisticsUrl = row.delivery
      },
      /**
       * 处理新增操作
       * @description 打开新增弹窗
       */
      handleAdd() {
        this.$refs.addDialog.show()
      },
      /**
       * 确认新增或编辑操作
       * @param {Object} data 新增的数据
       */
      handleAddConfirm(data) {
        let param = { ...data }
        param.goods_type = param.goods_type_id
        delete param.goods_type_id
        console.log('新增:', param)
        postAction('user/logistics/add-logistics', param).then((res) => {
          console.log('新增结果《', res)
          if (res.request_code == 200) {
            if (data.id) {
              this.$message.success('编辑成功')
            } else {
              this.$message.success('新增成功')
            }
            this.getList()
          }
        })
      },
      /**
       * 处理导入操作
       * @description 打开导入弹窗
       */
      handleImport() {
        this.$refs.importDialog.show()
      },
      /**
       * 确认导入操作
       * @param {Array} data 导入的数据列表
       */
      handleImportConfirm() {
        // 处理导入的数据
        this.form.page = 1
        this.getList()
      },
      /**
       * 处理导出操作
       * @description 将表格数据导出为Excel文件
       */
      handleExport() {
        // const ws = XLSX.utils.json_to_sheet(this.tableData)
        // const wb = XLSX.utils.book_new()
        // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        // XLSX.writeFile(wb, '导出数据.xlsx')
        this.loading = true
        postAction('/user/logistics/export-logistics', this.form).then(
          (res) => {
            if (res.request_code == 200) {
              excel.export_json_to_excel({
                header: res.data.header,
                data: res.data.data,
                filename: res.name,
              })
            }
            this.loading = false
          }
        )
      },
      /**
       * 上传文件前的处理
       * @param {File} file 上传的文件对象
       * @returns {boolean} 返回false阻止默认上传
       */
      beforeUpload(file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result)
          const workbook = XLSX.read(data, { type: 'array' })
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
          const jsonData = XLSX.utils.sheet_to_json(firstSheet)
          this.tableData = jsonData
        }
        reader.readAsArrayBuffer(file)
        return false // 阻止默认上传行为
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    padding: 20px;
  }

  .logistics-dialog {
    ::v-deep .el-dialog {
      border-radius: 8px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      .el-dialog__header {
        padding: 12px 16px;
        border-bottom: 1px solid #eee;
        margin: 0;

        .el-dialog__title {
          font-size: 16px;
          font-weight: 500;
        }

        .el-dialog__headerbtn {
          top: 14px;
        }
      }

      .el-dialog__body {
        padding: 0;
      }
    }
  }
</style>
