import { render, staticRenderFns } from "./importSer.vue?vue&type=template&id=47732c22&scoped=true&"
import script from "./importSer.vue?vue&type=script&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&lang=js&"
export * from "./importSer.vue?vue&type=script&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47732c22",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\shangjiaGit\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47732c22')) {
      api.createRecord('47732c22', component.options)
    } else {
      api.reload('47732c22', component.options)
    }
    module.hot.accept("./importSer.vue?vue&type=template&id=47732c22&scoped=true&", function () {
      api.rerender('47732c22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/datastatis/allocation/components/importSer.vue"
export default component.exports