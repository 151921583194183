<template>
  <el-dialog
    :title="title"
    :visible.sync="showadd"
    width="80%"
    :before-close="onClose"
  >
    <div>
      <el-form ref="form" :model="form" label-width="130px" :rules="rules">
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="标题"
            clearable
            style="width: 260px"
          />
        </el-form-item>
        <!-- <el-form-item label="来源" prop="source_id">
          <el-select
            v-model="form.source_id"
            clearable
            placeholder="来源"
            style="width: 160px"
          >
            <el-option label="云交会" value="1" />
            <el-option label="头条拉取" value="2" />
            <el-option label="企业文章" value="3" />
            <el-option label="公众号拉取" value="4" />
            <el-option label="经销商之家" value="5" />
          </el-select>
        </el-form-item> -->
        <el-form-item label="作者" prop="author" v-if="isgzh">
          <el-select
            v-model="form.author"
            clearable
            filterable
            placeholder="请选择"
            style="width: 160px"
          >
            <el-option
              v-for="(list, index) in authorlist"
              :key="index"
              :label="list"
              :value="list"
            />
          </el-select>
          <el-checkbox style="margin-left: 15px" v-model="form.ischeckauther">
            不显示作者
          </el-checkbox>
        </el-form-item>
        <el-form-item label="作者" prop="author" v-else>
          <el-input
            v-model="form.author"
            placeholder="作者"
            style="width: 160px"
          />
          <el-checkbox style="margin-left: 15px" v-model="form.ischeckauther">
            不显示作者
          </el-checkbox>
        </el-form-item>
        <el-form-item label="头像" v-if="!isgzh" prop="author_img">
          <upload-img
            ref="author_img"
            :infoText="''"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'author_img')"
            :maxSize="100"
          />
        </el-form-item>
        <el-form-item label="封面图" prop="cover_img">
          <upload-img
            ref="cover_img"
            :infoText="''"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'cover_img')"
            :maxSize="100"
          />
        </el-form-item>

        <el-form-item v-if="isgzh" label="文章链接地址" prop="content">
          <el-input placeholder="http://" v-model="form.content" />
        </el-form-item>
        <el-form-item v-else label="内容" prop="content">
          <vue-ueditor-wrap
            v-model="form.content"
            @before-init="addCustomDialog"
          />
        </el-form-item>

        <el-form-item label="虚拟阅读数(虚+真)">
          <el-input-number v-model="totalview" clearable style="width: 220px" />
          <span style="font-size: 12px">
            当前真实浏览量{{ form.view_count || 0 }}
          </span>
        </el-form-item>
        <el-form-item label="关联品类">
          <el-checkbox v-model="isSelectAll" @change="selectAll">
            全选
          </el-checkbox>
          <el-tree
            ref="tree"
            class="industrytree"
            :data="tagsTree"
            :empty-text="emptyText"
            :props="defaultProps"
            show-checkbox
            default-expand-all
            node-key="id"
            :default-checked-keys="tagstr"
            @check-change="industrychange"
          />
          <!-- <el-select
            v-model="form.cat_id"
            clearable
            placeholder="设置关联品类"
            style="width: 160px"
          >
            <el-option
              v-for="list in shopSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select> -->
        </el-form-item>

        <el-form-item label="定时发布">
          <el-date-picker
            v-model="form.push_time"
            placeholder="选择日期时间"
            style="width: 220px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="置顶">
          <el-switch
            v-model="form.is_top"
            :active-value="'1'"
            :inactive-value="'0'"
            active-color="#13ce66"
            inactive-color="#ccc"
          ></el-switch>
        </el-form-item>
        <!-- <el-form-item label="本周热文">
          <el-switch
            v-model="form.is_hot"
            :active-value="'1'"
            :inactive-value="'0'"
            active-color="#13ce66"
            inactive-color="#ccc"
          ></el-switch>
        </el-form-item> -->
        <el-form-item label="热文排序" v-if="form.is_hot == 1">
          <el-input
            v-model="form.hot_rank"
            placeholder="数字越大越靠前"
          ></el-input>
        </el-form-item>

        <el-form-item label="推荐产品" v-if="!isgzh">
          <el-switch
            v-model="form.is_comment_goods"
            :active-value="'1'"
            :inactive-value="'0'"
            active-color="#13ce66"
            inactive-color="#ccc"
            active-text="显示"
            inactive-text="隐藏"
          ></el-switch>
        </el-form-item>
        <div v-if="form.is_comment_goods == 1">
          <el-form-item label="">
            <el-radio v-model="form.comment_type" :label="'1'">
              标签推荐
            </el-radio>
            <el-radio v-model="form.comment_type" :label="'2'">
              指定产品
            </el-radio>
          </el-form-item>
          <el-form-item label="" v-if="form.comment_type == 2">
            <div
              v-for="(list, index) in goodslist"
              :key="index"
              style="margin-bottom: 10px"
            >
              产品{{ index + 1 }}
              <el-autocomplete
                v-model="list.goods_name"
                :fetch-suggestions="handlergoods"
                placeholder="请输入标题搜索"
                value-key="goods_name"
                style="width: 330px"
                @select="handleSelectgoods($event, index)"
              >
                <template slot-scope="{ item }">
                  <div :class="item.is_pay == 1 ? 'payname' : ''">
                    {{ item.goods_name }}
                  </div>
                </template>
              </el-autocomplete>
              <i
                class="el-icon-circle-plus-outline"
                style="margin: -20px 10px 0 60px; font-size: 24px"
                @click="handelradd"
              ></i>
              <i
                v-show="goodslist.length > 1"
                class="el-icon-remove-outline"
                style="margin-top: -20px; font-size: 24px"
                @click="handlerdelet(index)"
              ></i>
            </div>
          </el-form-item>
        </div>
        <el-form-item label="引导图" v-if="!isgzh">
          <el-switch
            v-model="form.is_gif"
            :active-value="'1'"
            :inactive-value="'0'"
            active-color="#13ce66"
            inactive-color="#ccc"
          ></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import uploadImg from '@/components/uploadImg'
  import WangEditor from '@/components/WangEditor'
  import { nextTick } from 'vue'

  export default {
    name: 'AddEdit',
    components: {
      uploadImg,
      WangEditor,
    },
    data() {
      return {
        showadd: false,
        title: '',
        shopSelect: [],
        isgzh: false,
        form: {
          id: null,
          title: '',
          source_id: '',
          cover_img: '',
          content: '',
          author: '',
          author_img: '',
          push_time: '',
          virtual_view_count: '',
          view_count: '0',
          cat_id: '0',
          is_check: '0',
          is_top: '0',
          is_hot: '0',
          is_push_time: '0',
          hot_rank: '',
          is_comment_goods: '0',
          type: 2, //1逛逛 2热文
          comment_type: '1',
          is_gif: '1',
          ischeckauther: false,
        },
        goodslist: [{}],
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          author: [{ required: true, message: '请输入作者', trigger: 'blur' }],
          author_img: [
            { required: true, message: '请上传作者头像', trigger: 'blur' },
          ],
          cover_img: [
            { required: true, message: '请上传封面图', trigger: 'blur' },
          ],
          content: [
            // {required:true,message:'请输入内容',validator:this.handelriskong,trigger:'blur'}
            { required: true, message: '请输入内容', trigger: 'blur' },
          ],
        },
        myConfig: {
          zIndex: 1000,
          toolbars: [
            [
              'undo',
              'redo',
              '|',
              'bold',
              'italic',
              'underline',
              'fontborder',
              'strikethrough',
              'superscript',
              'subscript',
              'removeformat',
              'formatmatch',
              'autotypeset',
              'blockquote',
              'pasteplain',
              '|',
              'forecolor',
              'backcolor',
              'insertorderedlist',
              'insertunorderedlist',
              'selectall',
              'cleardoc',
              '|',
              'rowspacingtop',
              'rowspacingbottom',
              'lineheight',
              '|',
              'customstyle',
              'paragraph',
              'fontfamily',
              'fontsize',
              '|',
              'directionalityltr',
              'directionalityrtl',
              'indent',
              '|',
              'justifyleft',
              'justifycenter',
              'justifyright',
              'justifyjustify',
              '|',
              'touppercase',
              'tolowercase',
              '|',
              'imagenone',
              'imageleft',
              'imageright',
              'imagecenter',
              '|',
              'simpleupload',
              // 'insertcode',
              'attachment',
              'pagebreak',
              '|',
              'horizontal',
              'date',
              'time',
              '|',
              'inserttable',
              'deletetable',
              'insertparagraphbeforetable',
              'insertrow',
              'deleterow',
              'insertcol',
              'deletecol',
              'mergecells',
              'mergeright',
              'mergedown',
              'splittocells',
              'splittorows',
              'splittocols',
              '|',
              'drafts',
            ],
          ],
        },
        isSelectAll: false,
        tagsTree: [],
        tagstr: [],
        emptyText: '',
        defaultProps: {
          children: 'children',
          label: 'name',
        },
        totalview: '',
        isadd: false, //是否是新建
        authorlist: [], //作者列表
      }
    },
    watch: {
      // totalview(val) {
      //   if (val <= parseInt(this.form.view_count)) {
      //     this.$message.error('您设置的虚拟阅读数不能低于真实浏览量数据')
      //   }
      // },
      isadd(v) {
        console.log('编辑，，，，，，，，，，，', v)
        if (v) {
          nextTick(() => {
            this.setImg('https://res.httoutiao.com/yjhlogo.jpg', 'author_img')
          })
        } else {
          this.closeimg('author_img')
        }
      },
      showadd(val) {
        if (!val) {
          this.form = {
            id: null,
            title: '',
            source_id: '',
            cover_img: '',
            content: '',
            author: '',
            author_img: '',
            push_time: '',
            virtual_view_count: '',
            view_count: '0',
            cat_id: '0',
            is_check: '0',
            is_top: '0',
            is_hot: '0',
            hot_rank: '',
            is_push_time: '0',
            is_comment_goods: '0',
            type: 2, //1逛逛 2热文
            comment_type: '1',
            is_gif: '1',
            ischeckauther: false,
          }
          this.isadd = false
          this.goodslist = [{}]
          this.tagstr = []
          this.closeimg('author_img')
          this.closeimg('cover_img')
          this.isgzh = false
          this.$refs.form.resetFields()
        }
      },
    },
    mounted() {
      this.handlerSelect()
      this.getTags()
      this.getauthorlist()
    },

    methods: {
      getauthorlist() {
        getAction('/api/stroll/article/wechat-select').then((res) => {
          if (res.code == 0) {
            this.authorlist = res.data
          }
        })
      },
      addCustomDialog(editorId) {
        window.UE.registerUI(
          'related-products',
          function (editor, uiName) {
            // 创建 dialog
            const dialog = new window.UE.ui.Dialog({
              // 指定弹出层中页面的路径，这里只能支持页面，路径参考常见问题 2
              iframeUrl: `/static/UEditor/dialogs/relatedproducts/index.html`,
              // 需要指定当前的编辑器实例
              editor: editor,
              // 指定 dialog 的名字
              name: uiName,
              // dialog 的标题
              title: '关联产品',
              // 指定 dialog 的外围样式420*230
              cssRules: 'width:420px;height:160px;',
              // 如果给出了 buttons 就代表 dialog 有确定和取消
              buttons: [
                // {
                //   className: 'edui-okbutton',
                //   label: '确定',
                //   onclick: function () {
                //     dialog.close(true);
                //   }
                // },
                // {
                //   className: 'edui-cancelbutton',
                //   label: '取消',
                //   onclick: function () {
                //     dialog.close(false);
                //   }
                // }
              ],
            })

            // 参考上面的自定义按钮
            const btn = new window.UE.ui.Button({
              name: 'related-products',
              title: '关联产品',
              cssRules:
                "background-image: url('/UEditor/dialogs/relatedproducts/related.png') !important;background-size: cover;",
              onclick: function () {
                // 渲染dialog
                dialog.render()
                dialog.open()
              },
            })

            return btn
          },
          100 /* 指定添加到工具栏上的那个位置，默认时追加到最后 */,
          editorId /* 指定这个UI是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
        )
        window.UE.registerUI(
          'hot-article',
          function (editor, uiName) {
            // 创建 dialog
            const dialog = new window.UE.ui.Dialog({
              // 指定弹出层中页面的路径，这里只能支持页面，路径参考常见问题 2
              iframeUrl: `/static/UEditor/dialogs/hotarticle/index.html`,
              // 需要指定当前的编辑器实例
              editor: editor,
              // 指定 dialog 的名字
              name: uiName,
              // dialog 的标题
              title: '本周行业热点',
              // 指定 dialog 的外围样式420*230
              cssRules: 'width:620px;height:160px;',
              // 如果给出了 buttons 就代表 dialog 有确定和取消
              buttons: [
                // {
                //   className: 'edui-okbutton',
                //   label: '确定',
                //   onclick: function () {
                //     dialog.close(true)
                //   },
                // },
                // {
                //   className: 'edui-cancelbutton',
                //   label: '取消',
                //   onclick: function () {
                //     dialog.close(false)
                //   },
                // },
              ],
            })

            // 参考上面的自定义按钮
            const btn = new window.UE.ui.Button({
              name: 'hot-article',
              title: '本周行业热点',
              cssRules:
                "background-image: url('/UEditor/dialogs/hotarticle/related.png') !important;background-size: cover;",
              onclick: function () {
                // 渲染dialog
                dialog.render()
                dialog.open()
              },
            })

            return btn
          },
          110 /* 指定添加到工具栏上的那个位置，默认时追加到最后 */,
          editorId /* 指定这个UI是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
        )
        window.UE.registerUI(
          'hot-product',
          function (editor, uiName) {
            // 创建 dialog
            const dialog = new window.UE.ui.Dialog({
              // 指定弹出层中页面的路径，这里只能支持页面，路径参考常见问题 2
              iframeUrl: `/static/UEditor/dialogs/hotproduct/index.html`,
              // 需要指定当前的编辑器实例
              editor: editor,
              // 指定 dialog 的名字
              name: uiName,
              // dialog 的标题
              title: '本周热产品',
              // 指定 dialog 的外围样式420*230
              cssRules: 'width:620px;height:160px;',
              // 如果给出了 buttons 就代表 dialog 有确定和取消
              buttons: [
                // {
                //   className: 'edui-okbutton',
                //   label: '确定',
                //   onclick: function () {
                //     dialog.close(true)
                //   },
                // },
                // {
                //   className: 'edui-cancelbutton',
                //   label: '取消',
                //   onclick: function () {
                //     dialog.close(false)
                //   },
                // },
              ],
            })

            // 参考上面的自定义按钮
            const btn = new window.UE.ui.Button({
              name: 'hot-article',
              title: '本周热产品',
              cssRules:
                "background-image: url('/UEditor/dialogs/hotproduct/related.png') !important;background-size: cover;",
              onclick: function () {
                // 渲染dialog
                dialog.render()
                dialog.open()
              },
            })

            return btn
          },
          120 /* 指定添加到工具栏上的那个位置，默认时追加到最后 */,
          editorId /* 指定这个UI是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
        )
      },
      handelradd() {
        this.goodslist.push({
          id: '',
          goods_name: '',
        })
        this.form.goods = this.goodslist[0].goods_name
      },
      handlerdelet(index) {
        this.goodslist.splice(index, 1)
      },
      // 关联产品远程搜索
      handlergoods(queryString, cb) {
        if (queryString == '') {
          cb([])
          // this.form.yjh_id = ''
        } else {
          getAction('/mall/product/goods', {
            goods_name: queryString,
            order: 1,
          }).then((res) => {
            cb(res.data)
          })
        }
      },
      // 关联产品选择
      handleSelectgoods(list, index) {
        console.log('关联产品----', list, index)
        this.goodslist[index].id = list.id
        this.goodslist[index].goods_name = list.goods_name
      },
      onClose() {
        //清空树结构数据
        this.$refs.tree.setCheckedNodes([])
        this.showadd = false
      },
      handlerinfo(row) {
        this.form = Object.assign(this.form, row)
        this.form.ischeckauther =
          this.form.is_display_author == 1 ? true : false
        this.totalview =
          parseInt(this.form.virtual_view_count) +
          parseInt(this.form.view_count)
        console.log(
          '---------编辑浏览量，',
          this.totalview,
          parseInt(this.form.virtual_view_count),
          parseInt(this.form.view_count)
        )
        this.tagstr = this.form.cat_id.split(',')

        this.goodslist = this.form.goods.length > 0 ? this.form.goods : [{}]

        console.log('标签集合-----------', this.tagstr)
        nextTick(() => {
          this.setImg(row.author_img, 'author_img')
          this.setImg(row.cover_img, 'cover_img')
          /*setTimeout(()=>{*/
          // const html = document.createElement('div')
          // html.innerHTML = row.content
          // const allDivs = html.getElementsByTagName('div')
          // for (let i = 0; i < allDivs.length; i++) {
          //   allDivs[i].style.fontSize = '18px'; // 设置字号
          //   allDivs[i].style.lineHeight = '32px';
          //   allDivs[i].style.marginBottom = '16px';
          //   allDivs[i].style.marginTop = '16px';
          //   const newP = document.createElement('p')
          //   newP.innerHTML = allDivs[i].innerHTML
          //   allDivs[i].parentNode.replaceChild(newP, allDivs[i]);
          // }
          // const allImages = html.getElementsByTagName('img')
          // // 遍历所有img标签并添加 alt 和 data-href 属性
          // for (let i = 0; i < allImages.length; i++) {
          //   if (!allImages[i].getAttribute('alt')) {
          //     allImages[i].setAttribute('alt', '');
          //   }
          //   if (!allImages[i].getAttribute('data-href')) {
          //     allImages[i].setAttribute('data-href', '');
          //   }
          // }
          /*   },100)*/
        })
      },
      industrychange(e, a, s) {
        console.log('获取----------------', e)
        let that = this
        if (e.children == undefined) {
          if (a) {
            this.tagstr.push(e.id)
          } else {
            this.tagstr.forEach((item, i) => {
              if (item == e.id) {
                this.tagstr.splice(i, 1)
              }
            })
          }
        }
      },
      selectAll() {
        if (this.isSelectAll) {
          // 	设置目前勾选的节点，使用此方法必须设置 node-key 属性
          this.$refs.tree.setCheckedNodes(this.tagsTree)
        } else {
          // 全部不选中
          this.$refs.tree.setCheckedNodes([])
        }
      },
      getTags() {
        getAction('/user/index/cate-tree').then((res) => {
          if (res.code == 0) {
            this.tagsTree = res.data
          }
        })
      },
      handlersave() {
        delete this.form.created
        if (this.form.ischeckauther) {
          this.form.is_display_author = 1
        } else {
          this.form.is_display_author = 0
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            // 创建一个临时div元素
            const tempDiv = document.createElement('div')
            tempDiv.innerHTML = this.form.content

            // 获取除了img标签外的所有子元素
            const elements = tempDiv.querySelectorAll(':not(img)')

            // 遍历每个子元素并添加行内样式
            elements.forEach((element) => {
              if (
                element.tagName == 'P' ||
                element.tagName == 'SPAN' ||
                element.tagName == 'DIV' ||
                element.tagName == 'SECTION'
              ) {
                element.style.fontSize = '17px' // 设置字号
                element.style.lineHeight = '34px'
                element.style.marginBottom = '16px'
                element.style.marginTop = '16px'
              } // 设置行间距
            })
            // 获取处理后的富文本字符串
            const processedRichTextString = tempDiv.innerHTML
            this.form.content = processedRichTextString
            // this.form.content=this.$refs.content.getHtmlM()
            console.log(this.form.content)
            this.form.cat_id = this.tagstr.join(',')
            let ids = []
            this.goodslist.forEach((item, index) => {
              ids.push(item.id)
            })
            this.form.goods_ids = ids.join(',')

            this.form.virtual_view_count =
              this.totalview - parseInt(this.form.view_count)
            console.log(
              '1111----------------------,virtual_view_count',
              this.totalview,
              this.form.view_count
            )
            if (this.totalview < parseInt(this.form.view_count)) {
              this.$message.error('您设置的虚拟阅读数不能低于真实浏览量数据')

              return false
            }
            if (this.isgzh) {
              this.form.source_id = 5
            }
            postAction('/api/stroll/article/edit', this.form).then((res) => {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.$refs.form.resetFields()
              this.$emit('getlist')
              this.showadd = false
            })
          } else {
            return false
          }
        })
      },

      async handlerSelect() {
        await getAction('/user/index/cate', { pid: 0 }).then((res) => {
          this.shopSelect = res.data
          this.shopSelect.push({
            id: '0',
            name: '关联品类',
          })
        })
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
    },
  }
</script>

<style scoped>
  .industrytree /deep/.el-tree-node__children {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .payname {
    color: #f00;
  }
</style>
