<template>
  <router-view />
</template>

<script>
  export default {
    name: 'htmemberset',
  }
</script>

<style scoped></style>

<!-- <template>
  <div class="index-container">
    <el-table
      :data="tabledata"
      border
      ref="table"
      v-loading="loading"
      row-key="user_id"
    >
      <el-table-column
        align="center"
        prop="id"
        width="150"
        label="顶部背景图"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="item"
        width="150"
        label="底部背景图1"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="item"
        width="150"
        label="底部背景图2"
      ></el-table-column>
      <el-table-column align="center" width="" label="操作">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerBanner(row)"
          >
            轮播图设置
          </el-button>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerMenu(row)"
          >
            金刚区设置
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <edit ref="edit"></edit>
    <banner ref="banner"></banner>
    <menulist ref="menulist"></menulist>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import edit from './components/edit'
  import banner from './components/banner'
  import menulist from './components/menu'
  export default {
    name: 'htmemberset',
    components: { edit, banner, menulist },
    data() {
      return {
        loading: false,
        tabledata: [{ id: 1, name: 1 }],
      }
    },
    mounted() {},
    methods: {
      getlist() {},
      handlerEdit() {
        this.$refs.edit.showDialog = true
      },
      handlerBanner() {
        this.$refs.banner.showDialog = true
      },
      handlerMenu() {
        1
        this.$refs.menulist.showDialog = true
        this.$refs.menulist.getData()
      },
    },
  }
</script> -->
