<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-date-picker
          v-model="time"
          :default-time="['12:00:00']"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          style="width: 340px"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      show-summary
      style="width: 100%"
      :summary-method="getSummaries"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '沟通图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '会议室截图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'

  export default {
    data() {
      return {
        form: {
          page: 1,
          limit: 10,
          start_time: '',
          end_time: '',
        },
        time: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '时间',
            prop: 'date',
            width: '',
          },
          {
            label: '创建方案数量',
            prop: 'sel_num',
            width: '',
          },
          {
            label: '已认证经销商数',
            prop: 'dealer_num',
            width: '',
          },
          {
            label: '浏览数',
            prop: 'view_num',
            width: '',
          },

          {
            label: '"请厂家联系我"数',
            prop: 'like_num',
            width: '',
          },
          {
            label: '"不感兴趣"数',
            prop: 'introduction_num',
            width: '',
          },
        ],
        total: 0,
        sumtotal: 0,
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '时间') {
            sums[index] = '合计'
          }
          if (column.label == '创建方案数量') {
            sums[index] = this.sumtotal.sel_num
          }
          if (column.label == '发送人数') {
            sums[index] = this.sumtotal.send_num
          }
          if (column.label == '浏览数') {
            sums[index] = this.sumtotal.view_num
          }
          if (column.label == '喜欢数') {
            sums[index] = this.sumtotal.like_num
          }
          if (column.label == '引荐数') {
            sums[index] = this.sumtotal.introduction_num
          }
          if (column.label == '已认证经销商数') {
            sums[index] = this.sumtotal.dealer_num
          }
          console.log('合计数', this.sumtotal)
        })
        return sums
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerExport() {
        postAction('/api/work/sel-plan/productcount-export', {
          start_time: this.form.start_time,
          end_time: this.form.end_time,
        }).then((res) => {
          console.log('导出数据11,', res)
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },

      async handlerlist() {
        await postAction('/api/work/sel-plan/product-count', this.form).then(
          (res) => {
            this.tabledata = res.data.list
            this.total = Number(res.totalCount)
          }
        )
        await postAction('/api/work/sel-plan/all-productcount', this.form).then(
          (res) => {
            this.sumtotal = res.data
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
