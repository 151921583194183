var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "分配结束日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "分配开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "跟进结束日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "跟进开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.follow_time,
                  callback: function ($$v) {
                    _vm.follow_time = $$v
                  },
                  expression: "follow_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择来源" },
                  model: {
                    value: _vm.form.source,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "source", $$v)
                    },
                    expression: "form.source",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "分配", value: "1" } }),
                  _c("el-option", { attrs: { label: "抢单", value: "2" } }),
                  _c("el-option", { attrs: { label: "网格化", value: "3" } }),
                  _c("el-option", { attrs: { label: "星级提升", value: "4" } }),
                  _c("el-option", {
                    attrs: { label: "万商重点企业", value: "5" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择跟进结果", clearable: "" },
                  model: {
                    value: _vm.form.follow_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "follow_status", $$v)
                    },
                    expression: "form.follow_status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "提需求", value: "1" } }),
                  _c("el-option", { attrs: { label: "未联系上", value: "2" } }),
                  _c("el-option", {
                    attrs: { label: "非行业人士", value: "3" },
                  }),
                  _c("el-option", { attrs: { label: "无效数据", value: "4" } }),
                  _c("el-option", {
                    attrs: { label: "暂无找品需求", value: "5" },
                  }),
                  _c("el-option", { attrs: { label: "有已对接", value: "6" } }),
                  _c("el-option", {
                    attrs: { label: "有找品需求", value: "7" },
                  }),
                  _c("el-option", {
                    attrs: { label: "云交会已合作", value: "8" },
                  }),
                  _c("el-option", { attrs: { label: "有效沟通", value: "9" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择客服",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.form.staff_user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staff_user_id", $$v)
                    },
                    expression: "form.staff_user_id",
                  },
                },
                _vm._l(_vm.staffList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择好友关系" },
                  model: {
                    value: _vm.form.friend_flag,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "friend_flag", $$v)
                    },
                    expression: "form.friend_flag",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "非企微非微信好友", value: "1" },
                  }),
                  _c("el-option", {
                    attrs: { label: "微信好友（企微非好友）", value: "2" },
                  }),
                  _c("el-option", {
                    attrs: { label: "企微好友（微信非好友）", value: "3" },
                  }),
                  _c("el-option", {
                    attrs: { label: "企微好友且微信好友", value: "4" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择平台厂家" },
                  model: {
                    value: _vm.form.platform_flag,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "platform_flag", $$v)
                    },
                    expression: "form.platform_flag",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "是", value: "1" } }),
                  _c("el-option", { attrs: { label: "否", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "请输入云交会昵称" },
                model: {
                  value: _vm.form.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "请输入企业名称" },
                model: {
                  value: _vm.form.platform,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "platform", $$v)
                  },
                  expression: "form.platform",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", width: "55", align: "center" },
          }),
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: item.align,
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "广告图"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      "preview-src-list": [row[item.prop]],
                                      src: row[item.prop],
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "placeholder" },
                                        slot: "placeholder",
                                      },
                                      [
                                        _vm._v(" 加载中 "),
                                        _c("span", { staticClass: "dot" }, [
                                          _vm._v("..."),
                                        ]),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }