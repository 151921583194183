var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "permission-set" }, [
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "formRef",
            attrs: {
              model: _vm.formData,
              rules: _vm.rules,
              "label-width": "140px",
            },
          },
          [
            _c("p", { staticClass: "title" }, [_vm._v("设置最大产品数量")]),
            _c(
              "el-form-item",
              { attrs: { label: "最大产品数量", prop: "free_mall_goods_num" } },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "请输入", type: "number" },
                  model: {
                    value: _vm.formData.free_mall_goods_num,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "free_mall_goods_num", $$v)
                    },
                    expression: "formData.free_mall_goods_num",
                  },
                }),
              ],
              1
            ),
            _c("p", { staticClass: "title" }, [_vm._v("设置电话相关")]),
            _c(
              "el-form-item",
              { attrs: { label: "电话", prop: "free_mall_is_phone" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.formData.free_mall_is_phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "free_mall_is_phone", $$v)
                      },
                      expression: "formData.free_mall_is_phone",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "1" } }, [
                      _vm._v("可以接到"),
                    ]),
                    _c(
                      "el-radio",
                      { attrs: { label: "2" } },
                      [
                        _vm._v(" 不能接到 "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content:
                                "可以接到指的是可以接到经销商电话，不能接到经销商会打到400电话",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question" })]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("p", { staticClass: "title" }, [_vm._v("设置留言相关")]),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "查看加密的旧留言",
                  prop: "free_mall_is_old_suggest",
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.formData.free_mall_is_old_suggest,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "free_mall_is_old_suggest", $$v)
                      },
                      expression: "formData.free_mall_is_old_suggest",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "1" } }, [
                      _vm._v("扣除意向线索次数"),
                    ]),
                    _c(
                      "el-radio",
                      { attrs: { label: "2" } },
                      [
                        _vm._v(" 不扣除任何次数 "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "旧留言指的是购买最新套餐之前的留言",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question" })]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("p", { staticClass: "title" }, [_vm._v("设置样品申请相关")]),
            _c(
              "el-form-item",
              { attrs: { label: "样品申请", prop: "free_mall_is_sample" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.formData.free_mall_is_sample,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "free_mall_is_sample", $$v)
                      },
                      expression: "formData.free_mall_is_sample",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "1" } }, [
                      _vm._v("可以接到"),
                    ]),
                    _c("el-radio", { attrs: { label: "2" } }, [
                      _vm._v("不能接到"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "span",
          { staticStyle: { "margin-left": "45%" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
              [_vm._v("确 定")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }