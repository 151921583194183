var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "80%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  attrs: {
                    span: 24,
                    type: "flex",
                    justify: "center",
                    align: "center",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        inline: true,
                        model: _vm.form_data,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.form_data.htyun_name } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form_data.start_time) +
                              " " +
                              _vm._s(_vm.form_data.end_time) +
                              " "
                          ),
                        ]
                      ),
                      _c("br"),
                      _c("el-form-item", { attrs: { label: "分配数量" } }, [
                        _vm._v(" " + _vm._s(_vm.form_data.num) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "跟进" } }, [
                        _vm._v(_vm._s(_vm.form_data.follow_num)),
                      ]),
                      _c("el-form-item", { attrs: { label: "有效沟通" } }, [
                        _vm._v(_vm._s(_vm.form_data.status_1_num)),
                      ]),
                      _c("el-form-item", { attrs: { label: "未跟进" } }, [
                        _vm._v(_vm._s(_vm.form_data.no_follow_num)),
                      ]),
                      _c("el-form-item", { attrs: { label: "跟进率" } }, [
                        _vm._v(_vm._s(_vm.form_data.follow_rate)),
                      ]),
                      _c("el-form-item", { attrs: { label: "有效沟通率" } }, [
                        _vm._v(_vm._s(_vm.form_data.effect_rate)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tabledata },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "55", align: "center" },
              }),
              _vm._l(_vm.columns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    align: item.align,
                    label: item.label,
                    prop: item.prop,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            item.label == "广告图"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                        },
                                        attrs: {
                                          "preview-src-list": [row[item.prop]],
                                          src: row[item.prop],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中 "),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }