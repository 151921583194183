var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: _vm.title,
            visible: _vm.isShow,
            width: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入产品名称", clearable: "" },
                        model: {
                          value: _vm.form.goods_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goods_name", $$v)
                          },
                          expression: "form.goods_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handlerlist()
                            },
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                  _vm.yjh_mall_id == 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleredit()
                                },
                              },
                            },
                            [_vm._v(" 新增产品 ")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handlerrelated()
                                },
                              },
                            },
                            [_vm._v(" 关联产品 ")]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  attrs: { data: _vm.tabledata, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      label: "ID",
                      align: "center",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_name",
                      label: "产品名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sort_order",
                      label: "排序",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_img",
                      label: "产品图片",
                      align: "center",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-image",
                              {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: {
                                  "preview-src-list": [row.goods_img],
                                  src: row.goods_img,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "placeholder" },
                                    slot: "placeholder",
                                  },
                                  [
                                    _vm._v(" 加载中 "),
                                    _c("span", { staticClass: "dot" }, [
                                      _vm._v("..."),
                                    ]),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "10px" },
                                attrs: { size: "small", type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleredit(row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑产品 ")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteRow(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "text",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                staticStyle: { "text-align": "center", "margin-top": "10px" },
                attrs: {
                  "current-page": _vm.form.page,
                  layout: _vm.layout,
                  "page-size": _vm.form.limit,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
              _c("goodsedit", {
                ref: "goodsedit",
                on: { getlist: _vm.handlerlist },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }