var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-word-rank" },
    [
      _c("el-input", {
        staticStyle: { width: "210px", "margin-right": "10px" },
        attrs: { placeholder: "请输入搜索词/手机号/用户昵称", clearable: "" },
        model: {
          value: _vm.form.title,
          callback: function ($$v) {
            _vm.$set(_vm.form, "title", $$v)
          },
          expression: "form.title",
        },
      }),
      _c(
        "el-select",
        {
          staticStyle: { width: "160px" },
          attrs: { clearable: "", filterable: "", placeholder: "搜索产品结果" },
          on: { change: _vm.handlerbumen },
          model: {
            value: _vm.form.goods_count,
            callback: function ($$v) {
              _vm.$set(_vm.form, "goods_count", $$v)
            },
            expression: "form.goods_count",
          },
        },
        _vm._l(_vm.searchlist, function (list, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: list.name, value: list.id },
          })
        }),
        1
      ),
      _c("el-date-picker", {
        staticStyle: { "margin-right": "10px" },
        attrs: {
          type: "daterange",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "value-format": "yyyy-MM-dd",
        },
        on: { change: _vm.handleDateChange },
        model: {
          value: _vm.dateRange,
          callback: function ($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange",
        },
      }),
      _c(
        "el-select",
        {
          staticStyle: { width: "160px" },
          attrs: { clearable: "", filterable: "", placeholder: "是否员工" },
          on: { change: _vm.handlerbumen },
          model: {
            value: _vm.form.is_staff,
            callback: function ($$v) {
              _vm.$set(_vm.form, "is_staff", $$v)
            },
            expression: "form.is_staff",
          },
        },
        _vm._l(_vm.staffList, function (list, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: list.name, value: list.id },
          })
        }),
        1
      ),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleSearch } },
        [_vm._v("查询")]
      ),
      _c(
        "el-button",
        { attrs: { type: "success" }, on: { click: _vm.handleExport } },
        [_vm._v("导出")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: { data: _vm.tableData },
        },
        [
          _c("el-table-column", { attrs: { prop: "title", label: "搜索词" } }),
          _c("el-table-column", {
            attrs: { prop: "nickname", label: "用户昵称" },
          }),
          _c("el-table-column", { attrs: { prop: "phone", label: "手机号" } }),
          _c("el-table-column", {
            attrs: { prop: "apply_name", label: "姓名" },
          }),
          _c("el-table-column", {
            attrs: { prop: "company_name", label: "公司名称" },
          }),
          _c("el-table-column", {
            attrs: { prop: "inviter_name", label: "邀请人" },
          }),
          _c("el-table-column", {
            attrs: { prop: "is_staff", label: "是否员工" },
          }),
          _c("el-table-column", {
            attrs: { prop: "goods_count", label: "搜索产品结果" },
          }),
          _c("el-table-column", {
            attrs: { prop: "is_enter", label: "是否登记" },
          }),
          _c("el-table-column", {
            attrs: { prop: "goods_click", label: "点击产品" },
          }),
          _c("el-table-column", {
            attrs: { prop: "article_click", label: "点击资讯" },
          }),
          _c("el-table-column", {
            attrs: { prop: "mall_click", label: "点击店铺" },
          }),
          _c("el-table-column", {
            attrs: { prop: "created", label: "搜索时间" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.currentPage,
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }