<script>
import uploadImg from "@/components/uploadImg.vue";
import Bindgoods from "@/views/pages/systemSet/hotmap/components/bindgoods.vue";
import { postAction } from "@/api/request";

export default {
  // name: "addgoods",
  components: {  uploadImg,Bindgoods },
  props: {
    catelist: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showadd: false,
      form: {
        goods_name: "",
        sort: "",
        attr: [
          {
            title: "",
            content: "",
            sort_order:1,
          },
        ],
        brand_name: '',
        category_id: '',
        title:'',
        cover_img:'',
        sell_point:'',
        ingredient_list:'',
        goods_id:0,
        id:''
      },
      rules: {
        category_id: [{ required: true, message: "请选择分类", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        cover_img: [{ required: true, message: "请上传商品图片", trigger: "blur" }],
        ingredient_list: [{ required: true, message: "请上传参考信息", trigger: "blur" }],
        attr: [{ required: true, message: "请填写参考信息", trigger: "blur" }],
        brand_name: [{ required: true, message: "请输入品牌名称", trigger: "blur" }],
        sell_point: [{ required: true, message: "请输入卖点", trigger: "blur" }],
      },
    };
  },
  watch: {
    showadd(val) {
      if (!val) {
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
        this.closeimg('cover_img')
        this.closeimg('ingredient_list')
      }
    },
  },
  methods:{
    handlersave(){
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          postAction('/api/other/cate-rank/edit',this.form).then(res=>{
            if(res.code==0){
              this.$message({
                type: 'success',
                message: '保存成功!'
              });
              this.$emit('refresh')
              this.showadd = false
            }else{
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
        }
      })
    },
    handlerinfo(row){
      this.form = Object.assign(this.form, row)
      this.$nextTick(() => {
        this.setImg(row.cover_img, 'cover_img')
        this.setImg(row.ingredient_list, 'ingredient_list')
      })
      console.log(this.form,"xiaoxi")
      if(row.attr==undefined||row.attr.length==0){
        this.form.attr = [{
          title: "",
          content: "",
          sort_order:1,
        }]
      }
    },
    handlerguanlian(){
        this.$refs.bindgoods.form = {
          // title: this.form.title,
          goods_id: this.form.goods_id,
          // category_id: this.form.category_id,
          id: this.form.id,
          // sort:this.form.sort,
          // cover_img:this.form.cover_img,
        }
        this.$refs.bindgoods.type='binding'
      this.$refs.bindgoods.showbind = true
    },
    handlertop(i){
      let temp = this.form.attr[i]
      this.$set(
        this.form.attr,
        i,
        this.form.attr[i - 1]
      )
      this.$set(this.form.attr, i - 1, temp)
    },
    handlerbottom(i){
      let temp = this.form.attr[i]
      this.$set(
        this.form.attr,
        i,
        this.form.attr[i + 1]
      )
      this.$set(this.form.attr, i + 1, temp)
    },
    setform(row){
      if(row.goods_id==0){
        console.log(row,'关联的产品')
        this.form = Object.assign(this.form, row)
        this.setImg(row.cover_img, 'cover_img')
        this.setImg(row.ingredient_list, 'ingredient_list')
      }else{
        this.$emit('refresh')
        this.showadd=false

      }

    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ''
      }
    },
    getGoods_imgImgs(v, prop) {
      if (v[0]) {
        this.form[prop] = v.join(',')
      } else {
        this.form[prop] = ''
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    setImgArray(v, prop) {
      if (v[0] != '') this.$refs[prop].img = v
    },
    closeimg(prop) {
      this.$refs[prop].img = []
    },
  }
};
</script>

<template>
<div>
  <el-dialog
    :visible.sync="showadd"
    width="60%">
    <span>

      <el-form :model="form" label-width="120px" :rules="rules" ref="form" label-suffix="：">
        <el-form-item label="商品名称" prop="title">
          <el-input v-model="form.title"></el-input>
          <el-button type="text" v-if="form.goods_id == 0" @click="handlerguanlian">关联云交会</el-button>
        </el-form-item>
        <el-form-item label="卖点" prop="sell_point">
          <el-input v-model="form.sell_point"></el-input>
        </el-form-item>
        <el-form-item label="产品图" prop="cover_img">
                      <upload-img
                        ref="cover_img"
                        :infoText="'图片尺寸750*750，仅支持jpg和png格式图片'"
                        :limit="5"
                        @getImgs="getGoods_imgImgs($event, 'cover_img')"
                        :maxSize="100"
                      />
        </el-form-item>
        <el-form-item label="参考信息" prop="attr">
          <el-row :gutter="10" type="flex" v-for="(list,index) in form.attr" :key="index">
            <el-col :span="6">
              <el-form-item label="分类">
                <el-input v-model="list.title" placeholder="分类"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="内容">
                <el-input v-model="list.content" placeholder="内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <i class="el-icon-delete-solid" v-if="form.attr.length > 1" @click="form.attr.splice(index,1)"></i>
              <i class="el-icon-bottom" v-show="index < form.attr.length - 1" @click="handlerbottom(index)"></i>
              <i class="el-icon-top" v-show="index > 0" @click="handlertop(index)"></i>
            </el-col>
          </el-row>
          <i class="el-icon-circle-plus-outline" style="font-size: 24px" @click="form.attr.push({title:'',content:'',sort_order:form.attr.length+1})"></i>
        </el-form-item>
        <el-form-item label="营养成分表">
                      <upload-img
                        ref="ingredient_list"
                        :infoText="''"
                        :limit="5"
                        @getImgs="getGoods_imgImgs($event, 'ingredient_list')"
                        :maxSize="100"
                      />
        </el-form-item>
        <el-form-item label="品牌" prop="brand_name">
                <el-input v-model="form.brand_name" placeholder="分类"></el-input>
        </el-form-item>
        <el-form-item label="所属分类" prop="category_id">
        <el-select v-model="form.category_id" placeholder="所属分类" >
          <el-option
            v-for="item in catelist"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="排名" prop="sort">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
      </el-form>
      <bindgoods :categoryList="catelist" @setform="setform" ref="bindgoods"/>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showadd = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<style scoped>

</style>
