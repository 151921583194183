var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showedit, width: "80%" },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handlerExport } },
        [_vm._v("导出")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableloading,
              expression: "tableloading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "nickname", label: "用户昵称", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "需求客户", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "cat_pname", label: "一级品类", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "cat_name", label: "二级品类", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "detail", label: "需求详情", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "telephone", label: "客户电话", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "staff_user_name", label: "提报人", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "view_time", label: "查看时间", width: "" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.page,
          layout: _vm.layout,
          "page-size": _vm.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showedit = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }