var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showadd,
        width: "60%",
        "before-close": _vm.onClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "标题", clearable: "" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "jump_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "类型" },
                      model: {
                        value: _vm.form.jump_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "jump_type", $$v)
                        },
                        expression: "form.jump_type",
                      },
                    },
                    _vm._l(_vm.jumplist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.jump_type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "跳转店铺", prop: "author" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          "fetch-suggestions": _vm.handlerarticle,
                          "value-key": "mall_name",
                          placeholder: "关联店铺",
                        },
                        on: { select: _vm.handleSelectarticle },
                        model: {
                          value: _vm.form.mall_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mall_name", $$v)
                          },
                          expression: "form.mall_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.jump_type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "跳转产品", prop: "author" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          "fetch-suggestions": _vm.handlerhuose,
                          placeholder: "搜索产品名称",
                          "value-key": "goods_name",
                        },
                        on: { select: _vm.handleSelecthuose },
                        model: {
                          value: _vm.form.goods_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goods_name", $$v)
                          },
                          expression: "form.goods_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.jump_type == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "H5链接", prop: "author" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: { placeholder: "请输入H5链接" },
                        model: {
                          value: _vm.form.jump_object,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "jump_object", $$v)
                          },
                          expression: "form.jump_object",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.jump_type == 5
                ? _c(
                    "el-form-item",
                    { attrs: { label: "热点文章", prop: "author" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: { placeholder: "请选择热点文章" },
                        model: {
                          value: _vm.form.jump_object_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "jump_object_name", $$v)
                          },
                          expression: "form.jump_object_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "背景图", prop: "pic" } },
                [
                  _c("upload-img", {
                    ref: "pic",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "起始时间" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "340px" },
                    attrs: {
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "数字越大越靠前", type: "number" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": "1",
                      "inactive-value": "0",
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onClose } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }