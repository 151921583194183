<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="showRefuseReason"
    width="30%"
  >
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="">
        <el-checkbox-group v-model="remark" @change="handleCheckChange">
          <!-- <el-checkbox label="复选框 A" />
          <el-checkbox label="复选框 B" />
          <el-checkbox label="复选框 C" /> -->
          <el-checkbox
            v-for="(item, idx) in reasonlist"
            :key="idx"
            :label="item"
          >
            {{ item }}
            <span v-if="item == '其他'" style="line-height: 60px">
              <el-input v-model="other" :min="0" type="textarea" />
            </span>
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <!-- <el-form-item label="其他">
        <el-input v-model="form.sort" label="原因" :min="0" type="textarea" />
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showRefuseReason = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'AddEdit',
    props: {
      rowid: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showRefuseReason: false,
        title: '拒绝理由',
        reasonlist: [],
        remark: [],
        other: '',
        form: {
          id: '',
          product_id: '',
          goods_name: '',
          sort: 0,
          status: '1',
        },
      }
    },
    watch: {
      showRefuseReason(val) {
        if (!val) {
          this.remark = []
          this.other = ''
        }
      },
    },
    mounted() {
      this.remark = []
      this.other = ''
      this.getReasonList()
    },

    methods: {
      handleCheckChange() {},
      getReasonList() {
        getAction('/audit/biz-clue/nopass-reason').then((res) => {
          if (res.request_code == 200) {
            this.reasonlist = res.data
            this.reasonlist.push('其他')
          }
        })
      },
      handlersave() {
        console.log('确定', this.remark, this.other)
        postAction('/audit/biz-clue/audit', {
          id: this.rowid,
          status: 3,
          remark: this.remark,
          other: this.other,
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.showRefuseReason = false
          // this.handlerInquire()
          this.$emit('getlist')
        })
      },
    },
  }
</script>

<style scoped>
  .vab-theme-blue-black .el-checkbox {
    display: block !important;
  }
</style>
