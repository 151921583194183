var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showadd,
        width: "80%",
        "before-close": _vm.onClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { placeholder: "标题", clearable: "" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "来源", prop: "source_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "来源" },
                      model: {
                        value: _vm.form.source_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "source_id", $$v)
                        },
                        expression: "form.source_id",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "云交会", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "头条拉取", value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: "企业文章", value: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "作者", prop: "author" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { placeholder: "标题" },
                    model: {
                      value: _vm.form.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "author", $$v)
                      },
                      expression: "form.author",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "头像", prop: "author_img" } },
                [
                  _c("upload-img", {
                    ref: "author_img",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "author_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "封面图", prop: "cover_img" } },
                [
                  _c("upload-img", {
                    ref: "cover_img",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "cover_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内容", prop: "content" } },
                [
                  _c("vue-ueditor-wrap", {
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "虚拟浏览量" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "220px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.virtual_view_count,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "virtual_view_count", $$v)
                      },
                      expression: "form.virtual_view_count",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联品类" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.selectAll },
                      model: {
                        value: _vm.isSelectAll,
                        callback: function ($$v) {
                          _vm.isSelectAll = $$v
                        },
                        expression: "isSelectAll",
                      },
                    },
                    [_vm._v(" 全选 ")]
                  ),
                  _c("el-tree", {
                    ref: "tree",
                    staticClass: "industrytree",
                    attrs: {
                      data: _vm.tagsTree,
                      "empty-text": _vm.emptyText,
                      props: _vm.defaultProps,
                      "show-checkbox": "",
                      "default-expand-all": "",
                      "node-key": "id",
                      "default-checked-keys": _vm.tagstr,
                    },
                    on: { "check-change": _vm.industrychange },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "置顶" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": "1",
                      "inactive-value": "0",
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.form.is_top,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_top", $$v)
                      },
                      expression: "form.is_top",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "本周热文" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": "1",
                      "inactive-value": "0",
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.form.is_hot,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_hot", $$v)
                      },
                      expression: "form.is_hot",
                    },
                  }),
                ],
                1
              ),
              _vm.form.is_hot == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "热文排序" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "数字越大越靠前" },
                        model: {
                          value: _vm.form.hot_rank,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "hot_rank", $$v)
                          },
                          expression: "form.hot_rank",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "推荐产品" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": "1",
                      "inactive-value": "0",
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.form.is_comment_goods,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_comment_goods", $$v)
                      },
                      expression: "form.is_comment_goods",
                    },
                  }),
                ],
                1
              ),
              _vm.form.is_comment_goods == 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.form.comment_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "comment_type", $$v)
                                },
                                expression: "form.comment_type",
                              },
                            },
                            [_vm._v(" 标签推荐 ")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              model: {
                                value: _vm.form.comment_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "comment_type", $$v)
                                },
                                expression: "form.comment_type",
                              },
                            },
                            [_vm._v(" 指定产品 ")]
                          ),
                        ],
                        1
                      ),
                      _vm.form.comment_type == 2
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            _vm._l(_vm.goodslist, function (list, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "10px" },
                                },
                                [
                                  _vm._v(" 产品" + _vm._s(index + 1) + " "),
                                  _c("el-autocomplete", {
                                    attrs: {
                                      "fetch-suggestions": _vm.handlergoods,
                                      placeholder: "请输入标题搜索",
                                      "value-key": "goods_name",
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.handleSelectgoods(
                                          $event,
                                          index
                                        )
                                      },
                                    },
                                    model: {
                                      value: list.goods_name,
                                      callback: function ($$v) {
                                        _vm.$set(list, "goods_name", $$v)
                                      },
                                      expression: "list.goods_name",
                                    },
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus-outline",
                                    staticStyle: {
                                      margin: "-20px 10px 0 60px",
                                      "font-size": "24px",
                                    },
                                    on: { click: _vm.handelradd },
                                  }),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.goodslist.length > 1,
                                        expression: "goodslist.length > 1",
                                      },
                                    ],
                                    staticClass: "el-icon-remove-outline",
                                    staticStyle: {
                                      "margin-top": "-20px",
                                      "font-size": "24px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerdelet(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onClose } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }