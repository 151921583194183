<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    top="5vh"
    :visible.sync="showEditDialog"
    width="40%"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="120px"
      :model="form"
    >
      <el-form-item label="标题">
        <el-input
          v-model="form.title"
          placeholder="请输入图片标题"
          style="width: 80%"
        />
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <upload-img
          ref="pic"
          :info-text="''"
          :limit="1"
          :max-size="100"
          @getImgs="getGoods_imgImg($event, 'img')"
        />
      </el-form-item>
      <el-form-item label="二维码类型">
        <el-select
          v-model.trim="form.jump_type"
          clearable
          placeholder="请选择图片类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="跳转ID" v-if="form.jump_type >= 7">
        <el-input v-model="form.jump_id" placeholder="请输入id值" />
      </el-form-item>

      <el-form-item label="二维码横坐标" prop="poster_x">
        <el-input
          v-model="form.poster_config.poster_x"
          onkeyup="value=value.replace(/[^\d.]/g,'') "
          placeholder="请输入二维码横坐标"
          style="width: 40%"
        />
      </el-form-item>
      <el-form-item label="二维码纵坐标" prop="poster_y">
        <el-input
          v-model="form.poster_config.poster_y"
          onkeyup="value=value.replace(/[^\d.]/g,'') "
          placeholder="请输入二维码纵坐标"
          style="width: 40%"
        />
      </el-form-item>
      <el-form-item label="二维码宽度" prop="poster_qrcode_width">
        <el-input
          v-model="form.poster_config.poster_qrcode_width"
          onkeyup="value=value.replace(/[^\d.]/g,'') "
          placeholder="请输入二维码宽度"
          style="width: 40%"
        />
      </el-form-item>
      <el-form-item label="二维码高度" prop="poster_qrcode_height">
        <el-input
          v-model="form.poster_config.poster_qrcode_height"
          onkeyup="value=value.replace(/[^\d.]/g,'') "
          placeholder="请输入二维码高度"
          style="width: 40%"
        />
      </el-form-item>
      <el-form-item label="二维码圆角" prop="poster_qrcode_angle">
        <el-input
          v-model="form.poster_config.poster_qrcode_angle"
          onkeyup="value=value.replace(/[^\d.]/g,'') "
          placeholder="请输入二维码圆角"
          style="width: 40%"
        />
      </el-form-item>
      <el-form-item label="效果预览">
        <el-button type="primary" @click="preview">预览</el-button>
      </el-form-item>
      <el-form-item label="文案">
        <el-input v-model="form.content" type="textarea"></el-input>
      </el-form-item>

      <el-form-item label="状态">
        <el-radio v-model="form.status" label="1">启用</el-radio>
        <el-radio v-model="form.status" label="0">禁用</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showEditDialog = false">取消</el-button>
      <el-button type="primary" @click="handlerSave">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg.vue'
  import { Base64 } from 'js-base64'
  export default {
    name: 'BanLog',
    components: { UploadImg },
    data() {
      return {
        wechart:
          'htcard/test/up/202409/09/20240909112210241785646622.1725852130-1840137713.png',
        showEditDialog: false,
        loading: false,
        user_id: '',
        total: 0,
        title: '新增海报',
        form: {
          type: '1',
          status: '1',
          jump_type: '0',
          sort_order: 0,
          title: '',
          poster_config: {
            poster_x: '',
            poster_y: '',
            poster_qrcode_width: '',
            poster_qrcode_height: '',
            poster_qrcode_angle: 0,
          },
        },
        list: [],
        jumpOptions: [
          {
            value: '0',
            label: '无跳转',
          },
          {
            value: '1',
            label: 'H5链接',
          },
          {
            value: '2',
            label: '小程序通用链接',
          },
          {
            value: '3',
            label: '糖烟酒周刊小程序',
          },
        ],
        typeOptions: [
          // {
          //   value: 0,
          //   label: '全部图片',
          // },
          {
            value: '1',
            label: '企微二维码',
          },
          {
            value: '2',
            label: '华糖会员首页',
          },
          {
            value: '3',
            label: '开通会员页',
          },
          {
            value: '4',
            label: '小课堂实践课',
          },
          {
            value: '5',
            label: '线下研修课',
          },
          {
            value: '6',
            label: '数字刊-首页',
          },
          {
            value: '7',
            label: '数字刊-期刊详情',
          },
          {
            value: '8',
            label: '数字刊-文章详情',
          },
          {
            value: '9',
            label: '数字刊-视频详情',
          },
        ],
      }
    },
    computed: {},
    watch: {
      showEditDialog(v) {
        if (v) {
          this.fetchData()
        } else {
          this.closeimg('pic')
          this.onreset()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async preview() {
        if (this.form.img) {
          if (
            this.form.poster_config.poster_x.length == 0 ||
            this.form.poster_config.poster_y.length == 0
          ) {
            this.$message.error('请填写二维码坐标')
            return false
          }
          if (this.form.poster_config.poster_qrcode_angle > 100) {
            this.form.poster_config.poster_qrcode_angle = 100
          }

          var imgs =
            this.wechart +
            `?x-oss-process=image/resize,w_${this.form.poster_config.poster_qrcode_width},h_${this.form.poster_config.poster_qrcode_height},limit_0`
          if (this.form.poster_config.poster_qrcode_angle > 0) {
            imgs += `/rounded-corners,r_${this.form.poster_config.poster_qrcode_angle}`
          }
          const encode = Base64.encode(imgs)
          const imgurl2 = this.base64urlEncode(encode)
          let a =
            this.form.img +
            `?x-oss-process=image/watermark,image_${imgurl2},g_nw,x_${this.form.poster_config.poster_x},y_${this.form.poster_config.poster_y}`
          console.log(a)
          this.$alert(
            `<img src="${a}" style="width: 380px;height: auto">`,
            '预览图片',
            {
              dangerouslyUseHTMLString: true,
            }
          )
        } else {
          this.$message.error('请先上传图片')
        }
      },
      async urlToBase64(url) {
        /*  try {
        // 从 URL 获取文件内容并转化为 Blob 对象
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();

        // 创建一个新的 File 对象
        const file = new File([blob], Date.now(), { type: 'png' });

        return file;
      } catch (error) {
        console.error('Error:', error);
        return null;
      }*/
        return new Promise((resolve, reject) => {
          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')
          let img = new Image()
          img.src = url
          img.crossOrigin = 'Anonymous'
          img.onload = () => {
            canvas.width = this.form.poster_config.poster_qrcode_width
            canvas.height = this.form.poster_config.poster_qrcode_height
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            const dataURL = canvas.toDataURL('image/png')
            console.log(dataURL)
            resolve(dataURL)
          }
          img.onerror = function (e) {
            reject(e)
          }
        })
      },
      base64urlEncode(str) {
        // 将Base64编码中的 '+' 替换为 '-'，'/' 替换为 '_'，并移除结果中尾部的所有 '='
        return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
      },
      onreset() {
        this.form = {
          type: '1',
          status: '1',
          jump_type: '0',
          jump_url: '',
          title: '',
          img: '',
          sort_order: 0,
          poster_config: {
            poster_x: '',
            poster_y: '',
            poster_qrcode_width: '',
            poster_qrcode_height: '',
            poster_qrcode_angle: 0,
          },
        }
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v.join(',')
        } else {
          this.form[prop] = ''
        }
      },
      getGoods_imgImgs(v, prop) {
        if (v[0]) {
          this.form[prop] = v
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      setImgArray(v, prop) {
        if (v[0] != '') this.$refs[prop].img = v
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      handlerinfo(row) {
        console.log('--------------', row)
        if (row) {
          this.form = Object.assign({}, row)
          this.form.poster_config = this.form.poster_config
            ? JSON.parse(this.form.poster_config)
            : {}
          console.log('--------------', this.form)
          this.$nextTick(() => {
            // if (this.form.type == 6) {
            //   this.setImgArray(row.img_arr, 'picarr')
            // } else {
            //   this.setImg(row.img, 'pic')
            // }
            this.setImg(row.img, 'pic')
          })
        }
      },
      async fetchData(row) {
        // this.loading = true
        // let data = await postAction('/Users/Lock_log', {
        //   user_id: this.user_id,
        // })
        // console.log('data', data)
        // this.total = data.result[0].total
        // this.list = data.result[0].list
        // this.loading = false
      },
      // 保存
      handlerSave() {
        if (this.form.title.length == '') {
          this.$message.error('请输入图片标题')
          return false
        }

        if (this.form.type == 5) {
          if (this.form.poster_config.poster_x == '') {
            this.$message.error('请输入二维码横坐标')
            return false
          }

          if (this.form.poster_config.poster_y == '') {
            this.$message.error('请输入二维码纵坐标')
            return false
          }

          if (this.form.poster_config.poster_qrcode_width == '') {
            this.$message.error('请输入二维码宽度')
            return false
          }

          if (this.form.poster_config.poster_qrcode_height == '') {
            this.$message.error('请输入二维码高度')
            return false
          }
          this.form.poster_config.poster_qrcode_angle = this.form.poster_config
            .poster_qrcode_angle
            ? this.form.poster_config.poster_qrcode_angle
            : 0
        }

        postAction('/user/business-club/edit-poster', this.form).then((res) => {
          if (res.request_code == 200) {
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
          this.showEditDialog = false
          this.$emit('getlist')
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
