<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item >
        <el-input placeholder="店铺名称" clearable v-model="form.mall_name" style="width: 210px;"/>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="公司名称" clearable v-model="form.company_name" style="width: 210px;"/>
      </el-form-item>

      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>


    </el-form>
    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.prop=='pp'">
<!--            已完成/总量（匹配）-->
            {{(Number(row.match_end_num))+'/'+row.match_total_num}}
          </div>
          <div v-else-if="item.prop=='ppy'">
            {{(Number(row.match_overdue_end_num))+'/'+row.match_overdue_total_num}}
          </div>
          <div v-else-if="item.prop=='yhy'">
            {{(Number(row.referrals_end_num))+'/'+row.referrals_total_num}}
          </div>
          <div v-else-if="item.prop=='yhyy'">
            {{(Number(row.referrals_overdue_end_num))+'/'+row.referrals_overdue_total_num}}
          </div>
          <div v-else-if="item.prop=='ds'">
            {{(Number(row.through_train_end_num))+'/'+row.through_train_total_num}}
          </div>
          <div v-else-if="item.prop=='dsy'">
            {{(Number(row.through_overdue_train_end_num))+'/'+row.through_overdue_train_total_num}}
          </div>
          <div v-else-if="item.prop=='yx'">
            {{(Number(row.intent_total_num)-Number(row.intent_last_num))+'/'+row.intent_total_num}}
          </div>
          <div v-else-if="item.prop=='yxy'">
            {{(Number(row.intent_overdue_total_num)-Number(row.intent_overdue_last_num))+'/'+row.intent_overdue_total_num}}
          </div>
          <div v-else-if="item.prop=='yq'">
            {{(Number(row.invite_total_num)-Number(row.invite_last_num))+'/'+row.invite_total_num}}
          </div>
          <div v-else-if="item.prop=='yqy'">
            {{(Number(row.invite_overdue_total_num)-Number(row.invite_overdue_last_num))+'/'+row.invite_overdue_total_num}}
          </div>
          <div v-else-if="item.label=='线上匹配'">
            <div v-if="row.match_last_num>0">
              <span>未完成</span>
            </div>
            <div v-else>
              {{Number(row.match_total_num)!=0?'已完成':'未获得'}}
            </div>
          </div>
          <div v-else-if="item.label=='云会议'">
            <div v-if="row.referrals_last_num>0">
              <span>未完成</span>
            </div>
            <div v-else>
              {{Number(row.referrals_total_num)!=0?'已完成':'未获得'}}
            </div>
          </div>
          <div v-else-if="item.label=='大商直通车'">
            <div v-if="row.through_train_last_num>0">
              <span>未完成</span>
            </div>
            <div v-else>
              {{Number(row.through_train_total_num)!=0?'已完成':'未获得'}}
            </div>
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       align="center">
        <template #default="{row}">
          <el-button type="text" @click="handlerinfo(row)">赠送明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <el-dialog :visible.sync="showinfo" width="50%" :close-on-click-modal="false">
      <el-table
        border
        :data="infodata"
        style="width: 100%">
        <el-table-column label="数量" align="center" prop="total_num"></el-table-column>
        <el-table-column label="内容" align="center" prop="give_type">
          <template #default="{row}">
            {{row.give_type}}
          </template>
        </el-table-column>
        <el-table-column label="变动方式" align="center" prop="operation_type">
        </el-table-column>
        <el-table-column label="渠道" align="center" prop="type_txt">
        </el-table-column>
        <el-table-column label="时间" align="center" prop="created">
          <template #default="{row}">
            {{row.created}}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
export default {
  data(){
    return {
      showinfo:false,
      form: {
        company_name:'',
        mall_name:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      infodata:[],
      columns: [
        {
          label: "店铺名称",
          prop: "mall_name",
          width: "",
        },

        {
          label: "公司名称",
          prop: "company_name",
          width: "",
        },
        {
          label: "线上匹配",
          prop: "match_total_num",
          width: "",
        },
        {
          label: "已完成/总量（未过期）",
          prop: "pp",
          width: "",
        },
        {
          label: "已完成/总量（已过期）",
          prop: "ppy",
          width: "",
        },
        {
          label: "云会议",
          prop: "referrals_total_num",
          width: "",
        },
        {
          label: "已完成/总量（未过期）",
          prop: "yhy",
          width: "",
        },
        {
          label: "已完成/总量（已过期）",
          prop: "yhyy",
          width: "",
        },
        {
          label: "大商直通车",
          prop: "through_train_total_num",
          width: "",
        },
        {
          label: "已完成/总量（未过期）",
          prop: "ds",
          width: "",
        },
        {
          label: "已完成/总量（已过期）",
          prop: "dsy",
          width: "",
        },
        {
          label: "意向线索",
          prop: "intent_total_num",
          width: "",
        },
        {
          label: "已完成/总量（未过期）",
          prop: "yx",
          width: "",
        },
        {
          label: "已完成/总量（已过期）",
          prop: "yxy",
          width: "",
        },
        {
          label: "邀请统计",
          prop: "invite_total_num",
          width: "",
        },
        {
          label: "已完成/总量（未过期）",
          prop: "yq",
          width: "",
        },
        {
          label: "已完成/总量（已过期）",
          prop: "yqy",
          width: "",
        },
      ],
      total: 0,
      typeSelect:[],
      toSelect:[],
      click_num:0,
      form_num:0,
      sign_num:0
    }
  },
  async mounted() {
    await this.handlerInquire()
  },
  methods:{

    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerinfo(row){
      console.log(row)
      getAction("/mall/setmeal/give-statistics-record",{mall_id:row.id}).then(res=>{
        this.infodata=res.data
        this.showinfo=true
      })
    },
    handlerlist(){
      getAction("/mall/setmeal/give-statistics",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
