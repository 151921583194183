import { render, staticRenderFns } from "./addEdit.vue?vue&type=template&id=615253aa&scoped=true&"
import script from "./addEdit.vue?vue&type=script&lang=js&"
export * from "./addEdit.vue?vue&type=script&lang=js&"
import style0 from "./addEdit.vue?vue&type=style&index=0&id=615253aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615253aa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\shangjiaGit\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('615253aa')) {
      api.createRecord('615253aa', component.options)
    } else {
      api.reload('615253aa', component.options)
    }
    module.hot.accept("./addEdit.vue?vue&type=template&id=615253aa&scoped=true&", function () {
      api.rerender('615253aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/Cloudmall/shoppingmall/components/addEdit.vue"
export default component.exports