var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "10px" } },
    [
      _c(
        "el-select",
        {
          staticStyle: { "margin-right": "10px" },
          attrs: { placeholder: "请选择任务状态", clearable: "" },
          on: { change: _vm.handleFilterChange },
          model: {
            value: _vm.filterStatus,
            callback: function ($$v) {
              _vm.filterStatus = $$v
            },
            expression: "filterStatus",
          },
        },
        [
          _c("el-option", { attrs: { label: "待开始", value: "1" } }),
          _c("el-option", { attrs: { label: "正在执行", value: "2" } }),
          _c("el-option", { attrs: { label: "执行完毕", value: "3" } }),
          _c("el-option", { attrs: { label: "已停止", value: "4" } }),
        ],
        1
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.search()
            },
          },
        },
        [_vm._v("查询")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.onCreat()
            },
          },
        },
        [_vm._v("创建任务")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: { data: _vm.tasks, border: "" },
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "任务ID" } }),
          _c("el-table-column", {
            attrs: { prop: "company_name", label: "轮播企业" },
          }),
          _c("el-table-column", {
            attrs: { prop: "goods", label: "轮播产品" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.goods, function (item) {
                    return _c(
                      "span",
                      { key: item.id, staticStyle: { "margin-right": "10px" } },
                      [_vm._v(_vm._s(item.goods_name) + "，")]
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "task_status", label: "任务状态" },
          }),
          _c("el-table-column", {
            attrs: { prop: "start_time", label: "开始时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "end_time", label: "结束时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "created", label: "创建时间" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.task_status_id != 4
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _vm._e(),
                    scope.row.task_status_id == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleStop(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v(" 停止任务 ")]
                        )
                      : _vm._e(),
                    scope.row.task_status_id == 4
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#333", cursor: "default" },
                            attrs: { size: "mini", type: "text" },
                          },
                          [_vm._v(" 已停止 ")]
                        )
                      : _vm._e(),
                    scope.row.status == 4
                      ? _c(
                          "el-button",
                          { attrs: { type: "text", size: "mini" } },
                          [_vm._v(" 已停止 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.$index, scope.row)
                          },
                        },
                      },
                      [_vm._v(" 删除任务 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创建任务",
            visible: _vm.showCreateTaskDialog,
            width: "40%",
            "before-close": _vm.onClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCreateTaskDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "newTaskForm",
              attrs: {
                model: _vm.newTask,
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "轮播企业" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "请选择" },
                      on: { change: _vm.getGoodsList },
                      model: {
                        value: _vm.newTask.company_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.newTask, "company_id", $$v)
                        },
                        expression: "newTask.company_id",
                      },
                    },
                    _vm._l(_vm.companyList, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { value: list.id, label: list.mall_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "轮播产品", prop: "goods_ids" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        placeholder: "请选择轮播产品",
                        clearable: "",
                        "collapse-tags": "",
                      },
                      model: {
                        value: _vm.newTask.goods_ids,
                        callback: function ($$v) {
                          _vm.$set(_vm.newTask, "goods_ids", $$v)
                        },
                        expression: "newTask.goods_ids",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.goods_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开始结束时间" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "340px" },
                    attrs: {
                      "default-time": ["00:00:00"],
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "start-placeholder": "开始日期",
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.timelist,
                      callback: function ($$v) {
                        _vm.timelist = $$v
                      },
                      expression: "timelist",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.createTask } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }