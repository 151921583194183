var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add1" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.showAddDialog, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.showAddDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.newItem,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newItem.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "title", $$v)
                      },
                      expression: "newItem.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片" } },
                [
                  _c("upload-img", {
                    ref: "icon",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "icon")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newItem.title_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.newItem, "title_type", $$v)
                        },
                        expression: "newItem.title_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("文本"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("图片"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台产品数量" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.newItem.platform_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "platform_num", $$v)
                      },
                      expression: "newItem.platform_num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "非平台产品数量" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.newItem.no_platform_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "no_platform_num", $$v)
                      },
                      expression: "newItem.no_platform_num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "效果" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newItem.display_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.newItem, "display_type", $$v)
                        },
                        expression: "newItem.display_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("横向展示"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("树列展示"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.newItem.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "sort_order", $$v)
                      },
                      expression: "newItem.sort_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newItem.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.newItem, "status", $$v)
                        },
                        expression: "newItem.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onclose } }, [_vm._v("取消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAddConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }