var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "姓名" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "手机号" },
                model: {
                  value: _vm.form.mobile,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "mobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { clearable: "", placeholder: "会员状态" },
                  model: {
                    value: _vm.form.vip_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vip_status", $$v)
                    },
                    expression: "form.vip_status",
                  },
                },
                _vm._l(_vm.vipstatus, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: i.name, value: i.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { clearable: "", placeholder: "来源" },
                  model: {
                    value: _vm.form.open_source,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "open_source", $$v)
                    },
                    expression: "form.open_source",
                  },
                },
                _vm._l(_vm.sourceselect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: i.name, value: i.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "价格" },
                  model: {
                    value: _vm.form.club_vip_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "club_vip_id", $$v)
                    },
                    expression: "form.club_vip_id",
                  },
                },
                _vm._l(_vm.clubvipSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: i.title, value: i.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "邀请人",
                  },
                  model: {
                    value: _vm.form.invite_user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "invite_user_id", $$v)
                    },
                    expression: "form.invite_user_id",
                  },
                },
                _vm._l(_vm.staffSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: i.name, value: i.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "是否体验会员",
                  },
                  model: {
                    value: _vm.form.is_experience,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_experience", $$v)
                    },
                    expression: "form.is_experience",
                  },
                },
                _vm._l(_vm.experienceSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: i.name, value: i.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "开通结束时间",
                  "range-separator": "至",
                  "start-placeholder": "开通开始时间",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.payTime,
                  callback: function ($$v) {
                    _vm.payTime = $$v
                  },
                  expression: "payTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "到期结束时间",
                  "range-separator": "至",
                  "start-placeholder": "到期开始时间",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.expireTime,
                  callback: function ($$v) {
                    _vm.expireTime = $$v
                  },
                  expression: "expireTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onEdit()
                    },
                  },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onExport()
                    },
                  },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerImport()
                    },
                  },
                },
                [_vm._v("导入")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: { border: "", data: _vm.list, "row-key": "user_id" },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", prop: "id", width: "90" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "姓名", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "手机号码", prop: "mobile" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "公司名称",
              prop: "company",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "套餐类型", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "价格",
              prop: "total_price",
              width: "90",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "vip_status",
              label: "会员状态",
              align: "center",
              width: "110px",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { attrs: { slot: "header" }, slot: "header" },
                      [
                        _vm._v(" 会员状态 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", placement: "top" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(" 已开通：有效期内可享受华糖会员权益 "),
                                _c("br"),
                                _vm._v(" 已过期：华糖会员权益已到期 "),
                                _c("br"),
                                _vm._v(" 未支付：会员订单未支付 "),
                                _c("br"),
                                _vm._v(" 未激活：线下打款但不是云交会用户 "),
                              ]
                            ),
                            _c("i", { staticClass: "el-icon-warning" }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.vip_status == 1
                      ? _c("span", [_vm._v("已开通")])
                      : row.vip_status == 2
                      ? _c("span", [_vm._v("已过期")])
                      : row.vip_status == 3
                      ? _c("span", [_vm._v("未激活")])
                      : row.vip_status == 4
                      ? _c("span", [_vm._v("未支付")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "开通时间", prop: "open_time" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "到期时间", prop: "expire_time" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "微信支付订单号", prop: "pay_no" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "邀请人",
              prop: "invite_user",
              "show-overflow-tooltip": "",
              width: "90",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源",
              prop: "status",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.platform == 5 ? "后台添加" : "前台支付"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "添加人",
              prop: "operating_user",
              "show-overflow-tooltip": "",
              width: "90",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", prop: "", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.goDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.goEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", padding: "20px 0" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: _vm.order_title,
            visible: _vm.editShow,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "editBox" },
            [
              _c(
                "el-form",
                {
                  ref: "editform",
                  attrs: {
                    "label-width": "130px",
                    model: _vm.editform,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择用户", prop: "mobile" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: {
                          disabled:
                            _vm.editform.id &&
                            (_vm.editform.vip_status == 2 ||
                              _vm.editform.vip_status == 4),
                          placeholder: "请输入手机号",
                        },
                        model: {
                          value: _vm.editform.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "mobile", $$v)
                          },
                          expression: "editform.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.editform.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "name", $$v)
                          },
                          expression: "editform.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司名称", prop: "company" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: { placeholder: "请输入公司名称" },
                        model: {
                          value: _vm.editform.company,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "company", $$v)
                          },
                          expression: "editform.company",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "主营产品", prop: "main_product" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: { placeholder: "请输入主营产品" },
                        model: {
                          value: _vm.editform.main_product,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "main_product", $$v)
                          },
                          expression: "editform.main_product",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在地区", prop: "province" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { clearable: "", placeholder: "省份" },
                          on: { change: _vm.handlerprovince },
                          model: {
                            value: _vm.editform.province,
                            callback: function ($$v) {
                              _vm.$set(_vm.editform, "province", $$v)
                            },
                            expression: "editform.province",
                          },
                        },
                        _vm._l(_vm.provinceSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "city" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { clearable: "", placeholder: "城市" },
                          on: { change: _vm.handlercity },
                          model: {
                            value: _vm.editform.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.editform, "city", $$v)
                            },
                            expression: "editform.city",
                          },
                        },
                        _vm._l(_vm.citySelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "area" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { clearable: "", placeholder: "地区" },
                          model: {
                            value: _vm.editform.area,
                            callback: function ($$v) {
                              _vm.$set(_vm.editform, "area", $$v)
                            },
                            expression: "editform.area",
                          },
                        },
                        _vm._l(_vm.districtSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址", prop: "address" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: { placeholder: "详细地址" },
                        model: {
                          value: _vm.editform.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "address", $$v)
                          },
                          expression: "editform.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "获赠行业资讯",
                        prop: "get_industry_information",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled: _vm.editform.vip_status == 4,
                            label: "1",
                          },
                          model: {
                            value: _vm.editform.get_industry_information,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.editform,
                                "get_industry_information",
                                $$v
                              )
                            },
                            expression: "editform.get_industry_information",
                          },
                        },
                        [_vm._v(" 酒类 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled: _vm.editform.vip_status == 4,
                            label: "2",
                          },
                          model: {
                            value: _vm.editform.get_industry_information,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.editform,
                                "get_industry_information",
                                $$v
                              )
                            },
                            expression: "editform.get_industry_information",
                          },
                        },
                        [_vm._v(" 食品类 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员套餐", prop: "club_vip_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            clearable: "",
                            disabled: _vm.editform.vip_status == 4,
                            placeholder: "选择会员",
                          },
                          model: {
                            value: _vm.editform.club_vip_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.editform, "club_vip_id", $$v)
                            },
                            expression: "editform.club_vip_id",
                          },
                        },
                        _vm._l(_vm.clubvipSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.title, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员类型", prop: "is_experience" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled: _vm.experiencetype == 2,
                            label: "1",
                          },
                          model: {
                            value: _vm.editform.is_experience,
                            callback: function ($$v) {
                              _vm.$set(_vm.editform, "is_experience", $$v)
                            },
                            expression: "editform.is_experience",
                          },
                        },
                        [_vm._v(" 正常 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: "2",
                            disabled: _vm.experiencetype == 1,
                          },
                          model: {
                            value: _vm.editform.is_experience,
                            callback: function ($$v) {
                              _vm.$set(_vm.editform, "is_experience", $$v)
                            },
                            expression: "editform.is_experience",
                          },
                        },
                        [_vm._v(" 体验 ")]
                      ),
                    ],
                    1
                  ),
                  _vm.editform.is_experience == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "体验版块",
                            prop: "experience_section",
                            required: "true",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              on: { change: _vm.handlechange },
                              model: {
                                value: _vm.editform.experience_section,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editform,
                                    "experience_section",
                                    $$v
                                  )
                                },
                                expression: "editform.experience_section",
                              },
                            },
                            [
                              _vm._l(_vm.blockList, function (list, index) {
                                return _c(
                                  "el-checkbox",
                                  { key: index, attrs: { label: list.id } },
                                  [_vm._v(" " + _vm._s(list.name) + " ")]
                                )
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    disabled: !_vm.isHavetyj,
                                    label: "4",
                                  },
                                },
                                [_vm._v(" 选品趋势报告 ")]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editform.vip_status == 1 ||
                  _vm.editform.vip_status == 2 ||
                  _vm.editform.vip_status == 4 ||
                  (_vm.editform.vip_status == 3 &&
                    _vm.editform.is_experience == 2) ||
                  _vm.editform.is_experience == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "到期时间", required: "" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              disabled:
                                _vm.editform.vip_status == 4 ||
                                (_vm.editform.vip_status == 2 &&
                                  _vm.editform.is_experience == 2),
                              format: "yyyy-MM-dd HH:mm:ss",
                              placeholder: "选择日期时间",
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.editform.expire_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.editform, "expire_time", $$v)
                              },
                              expression: "editform.expire_time",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "微信支付订单号", prop: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: { placeholder: "请输入微信支付订单号" },
                        model: {
                          value: _vm.editform.pay_no,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "pay_no", $$v)
                          },
                          expression: "editform.pay_no",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.editform.id
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "订单备注" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: { rows: "4", type: "textarea" },
                            model: {
                              value: _vm.editform.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.editform, "remark", $$v)
                              },
                              expression: "editform.remark",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邀 请 人", prop: "invite_user_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "选择邀请人",
                          },
                          model: {
                            value: _vm.editform.invite_user_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.editform, "invite_user_id", $$v)
                            },
                            expression: "editform.invite_user_id",
                          },
                        },
                        _vm._l(_vm.staffSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editSbmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("info", { ref: "info" }),
      _c("import-ser", { ref: "importSer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }