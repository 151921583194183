var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.isShow, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "120px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称", prop: "goods_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入产品名称" },
                        model: {
                          value: _vm.form.goods_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goods_name", $$v)
                          },
                          expression: "form.goods_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品卖点", prop: "point" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入产品卖点" },
                        model: {
                          value: _vm.form.point,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "point", $$v)
                          },
                          expression: "form.point",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品图片", prop: "list_img" } },
                    [
                      _c("upload-img", {
                        ref: "list_img",
                        attrs: {
                          infoText: "图片尺寸750*750，大小不超过2M",
                          limit: 5,
                          maxSize: 100,
                        },
                        on: {
                          uploadImg: _vm.uploadImg,
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImgs($event, "list_img")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品信息", prop: "title" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "品类", prop: "title" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "例如：饮品-果蔬饮品" },
                            model: {
                              value: _vm.form.attr_category,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "attr_category", $$v)
                              },
                              expression: "form.attr_category",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "包装规格", prop: "title" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "例如：10瓶/箱" },
                            model: {
                              value: _vm.form.attr_spec,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "attr_spec", $$v)
                              },
                              expression: "form.attr_spec",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "保质期", prop: "title" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "例如：12个月" },
                            model: {
                              value: _vm.form.attr_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "attr_date", $$v)
                              },
                              expression: "form.attr_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "市场零售价", prop: "title" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "例如：6元" },
                            model: {
                              value: _vm.form.attr_market_price,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "attr_market_price", $$v)
                              },
                              expression: "form.attr_market_price",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "招商区域", prop: "title" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "例如：山东、河北" },
                            model: {
                              value: _vm.form.attr_area,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "attr_area", $$v)
                              },
                              expression: "form.attr_area",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          placeholder: "数字越大靠前显示",
                          type: "number",
                        },
                        model: {
                          value: _vm.form.sort_order,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort_order", $$v)
                          },
                          expression: "form.sort_order",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": "1",
                          "inactive-value": "0",
                          "active-text": "上架",
                          "inactive-text": "下架",
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                        },
                        model: {
                          value: _vm.form.is_on_sale,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "is_on_sale", $$v)
                          },
                          expression: "form.is_on_sale",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }