<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="回复状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in statusselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_call"
          clearable
          placeholder="拨打状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in callselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.contact"
          clearable
          placeholder="联系方式"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="请选择商品"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_pid"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.realname"
          clearable
          placeholder="姓名"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item prop="province_id">
        <el-select
          v-model="form.province_id"
          clearable
          placeholder="省"
          style="width: 160px"
          @change="handleProChange"
        >
          <el-option
            v-for="list in provinceSelect"
            :key="list.id"
            :label="list.name"
            :value="Number(list.id)"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="city_id">
        <el-select
          v-model="form.city_id"
          clearable
          placeholder="市"
          style="width: 160px"
        >
          <el-option
            v-for="list in citylist"
            :key="list.id"
            :label="list.name"
            :value="Number(list.id)"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '店铺名称'">
            {{ row.mall.mall_name }}
          </div>
          <div v-else-if="item.label == '留言产品'">
            {{ row.goods[item.prop] }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <!--      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">

          <el-button
            @click.native.prevent="handlerinfo(row)"
            type="text"
            style="margin-left: 10px"
            size="small">
            详情
          </el-button>
          <el-button
            @click.native.prevent="handlerbusiness(row)"
            type="text"
            size="small">
            工商信息
          </el-button>
        </template>
      </el-table-column>-->
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction } from '@/api/request'

  export default {
    name: 'Index',
    data() {
      return {
        form: {
          status: 0,
          goods_id: '',
          goods_name: '',
          realname: '',
          province_id: '',
          city_id: '',
          contact: '',
          is_call: '',
          page: 1,
          limit: 10,
        },
        time: [],
        catselect: [],
        cateselect: [],
        statusselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未回复',
          },
          {
            id: 1,
            name: '已回复',
          },
        ],
        callselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未拨打',
          },
          {
            id: 1,
            name: '已拨打',
          },
        ],
        goodsselect: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '留言类型',
            prop: 'type_txt',
            width: '110',
          },
          {
            label: '留言产品',
            prop: 'goods_name',
            width: '220px',
          },
          {
            label: '店铺名称',
            prop: 'business_url',
            width: '120',
          },
          {
            label: '一级品类',
            prop: 'cat_pname',
            width: '120',
          },
          {
            label: '二级品类',
              prop:'cat_name',
              width:'120',
          },
        {
            label: "付费/免费",
            prop: "pay_txt",
            width: "",
            align: "center",
          },
          {
            label: '业务',
            prop: 'yewu',
            width: '',
          },
          {
            label: '经销商名称',
            prop: 'dealer_name',
            width: '',
          },
          {
            label: '留言内容',
            prop: 'content',
            width: '',
          },
          {
            label: '联系方式',
            prop: 'contact',
            width: '110',
          },
          {
            label: '姓名',
            prop: 'realname',
            width: '110',
          },
          {
            label: '地区',
            prop: 'province_name',
            width: '110',
          },
          {
            label: '用户渠道',
            prop: 'user_channel',
            width: '',
          },
          {
            label: '关注品类',
            prop: 'user_trade',
            width: '160',
          },
          {
            label: '留言时间',
            prop: 'add_time_txt',
            width: '120',
          },
          {
            label: '回复状态',
            prop: 'status_txt',
            width: '',
          },
          {
            label: '拨打状态',
            prop: 'call_txt',
            width: '',
          },
          {
            label: '分配的客服',
            prop: 'cust_service',
            width: '',
          },
          {
            label: '邀请人昵称',
            prop: 'invite_nickname',
            width: '',
          },
          {
            label: '邀请人手机号',
            prop: 'invite_phone',
            width: '',
          },
          {
            label: '邀请人是否员工',
            prop: 'invite_is_staff',
            width: '',
          },
          {
            label: '员工姓名',
            prop: 'invite_htyun_name',
            width: '',
          },
        ],
        total: 0,
        provinceSelect: [],
        citylist: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.getProvince()
      this.handlerSelect()
    },
    methods: {
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerExport() {
        getAction('/mall/product/suggest-lists-export', {
          ...this.form,
          yjhasync: 1,
        }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: '已添加导出任务',
            })
          }
        })
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      /* async  handlerSelect(){
     const {data} = await getAction("/mall/product/goods")
      this.goodsselect=data
    },*/
      // 获取省份
      async getProvince() {
        const provin = await getAction('/user/index/area', { pid: 0 })
        this.provinceSelect = provin.data
        console.log('省份')
      },
      // 获取市
      async getCityList(e) {
        const citydata = await getAction('/user/index/area', { pid: e })
        this.citylist = citydata.data
      },
      handleProChange(e) {
        this.form.city_id = ''
        if (e != '') {
          this.getCityList(e)
        }
      },
      handlerlist() {
        getAction('/mall/product/suggest-lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
