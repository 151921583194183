<template>
  <!-- NAME[epic=组件] 上传多图 -->
  <div class="orderTest-container">
    <div>
      <el-upload
        ref="upload"
        class="avatar-uploader el-upload--text"
        :action="url + '/api/index/upload-video'"
        :headers="headers"
        accept=".mp3,.aac,.acc,.m4a"
        :show-file-list="false"
        :on-success="handleVideoSuccess"
        :before-upload="beforeUploadVideo"
        :on-progress="uploadVideoProcess"
        :disabled="disab"
      >
        <!-- <div v-if="Video.length > 0">
          {{ Video }}
        </div> -->
        <el-button type="primary">选择文件</el-button>
        <!-- <el-progress
          v-if="videoFlag == true"
          type="circle"
          :percentage="videoUploadPercent"
        ></el-progress> -->
      </el-upload>
    </div>
    <div
      style="line-height: 1.2; margin-top: 10px; color: red; font-size: 12px"
      v-if="showInfo"
    >
      {{ infoText }}
    </div>
  </div>
</template>
<script>
  import { baseURL } from '@/config'
  import { postAction, uploadAction } from '@/api/request'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  export default {
    name: 'uploadVideo',
    components: {},
    props: {
      limit: {
        type: Number,
        default: 5,
      },
      maxSize: {
        type: Number,
        default: 1,
      },
      showInfo: {
        type: Boolean,
        default: true,
      },
      disab: {
        type: Boolean,
        default: false,
      },
      infoText: {
        type: String,
        default: '（可拖拽进行排序，视频大小控制在100MB以内，视频格式：MP4）',
      },
      listtype: {
        type: String,
        default: 'picture-card',
      },
      fileType: {
        type: String,
        default: 'video',
      },
    },

    data() {
      return {
        dialogVisible: false,
        Video: '',
        loading: false,
        fileList: [],
        headers: {},
        videoUploadPercent: 0,
        videoFlag: false,
      }
    },
    created() {},
    mounted() {
      console.log('获取token---', this.token)
      // {
      //     ContentType: 'multipart/form-data',
      //     token: token,
      //   }
      this.headers = { token: this.token }
    },
    computed: {
      url() {
        return baseURL
      },
      ...mapGetters({
        token: 'user/token',
      }),
    },
    watch: {
      Video(v) {
        console.log(v, 'watch检测')
        this.$emit('getAudio', v)
      },
    },
    methods: {
      // down
      handleVideoSuccess(res, file) {
        console.log('上传成功', res)
        //获取上传图片地址
        // this.videoFlag = false
        this.videoUploadPercent = 0
        if (res.request_code == 200) {
          this.Video = res.data.url
          this.$emit('uploadVideo', res.data.url)
        } else {
          this.$message.error('语音上传失败，请重新上传！')
          this.clearVideo()
        }
      },
      clearVideo() {
        this.Video = ''
        this.$emit('uploadVideo', this.Video)
      },
      beforeUploadVideo(file) {
        console.log('--==upload,', file)
        this.getTimes(file)
        const isLt10M = file.size / 1024 / 1024 < this.maxSize
        if (
          ['audio/x-m4a', 'audio/mpeg', 'audio/vnd.dlna.adts'].indexOf(
            file.type
          ) == -1
        ) {
          this.$message.error('请上传正确的视频格式')
          return false
        }
        if (!isLt10M) {
          this.$message.error(`上传视频大小不能超过${this.maxSize}MB哦!`)
          return false
        }
      },
      uploadVideoProcess(event, file, fileList) {
        this.videoFlag = true
        this.videoUploadPercent = Number(file.percentage.toFixed(0))
      },
      // up
      // handleChange(file, fileList) {
      //   var FileExt = file.name.replace(/.+\./, '')
      //   console.log('文件名类型', FileExt, '文件，', file)
      //   this.getTimes(file)
      //   if (
      //     ['m4a', 'mp3', 'aac', 'acc', 'wav', 'flac', 'ape'].indexOf(
      //       FileExt.toLowerCase()
      //     ) === -1
      //   ) {
      //     this.$message({
      //       type: 'warning',
      //       message: '请上传.mp3,.m4a,.wav,.aac,.acc.flac,.ape格式的文件',
      //     })
      //     if (this.fileList.length <= 0) {
      //       this.fileList = []
      //     } else {
      //       this.fileList = fileList.slice(0, 1)
      //     }
      //     return false
      //   } else if (this.isLt10M === '0') {
      //     this.$message({
      //       type: 'warning',
      //       message: '上传文件的大小不能超过10M',
      //     })
      //     this.fileList = fileList.slice(0, 1)
      //     return this.isLt10M === '1' ? true : false
      //   } else if (fileList.length > 1) {
      //     this.$confirm('文件已存在，是否确定替换文件', {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       showClose: false,
      //       type: 'warning',
      //     })
      //       .then(() => {
      //         this.fileList = fileList.slice(-1)
      //       })
      //       .catch(() => {
      //         this.fileList = fileList.slice(0, 1)
      //       })
      //   } else {
      //     this.fileList = fileList
      //   }
      // },
      // beforeAvatarUpload(file) {
      //   console.log('上传的文件,', file)
      //   // 文件类型进行判断
      //   const isAudio = file.type === 'audio/mp3' || file.type === 'audio/mpeg'
      //   // 限制上传文件大小 5M
      //   const isLt5M = file.size / 1024 / 1024 < 5
      //   // const isTime60S = this.audioDuration >= 6000 ? true : ''
      //   // 获取时长
      //   this.getTimes(file)
      //   if (!isAudio) {
      //     this.$message.error('上传文件只能是Mp3格式!')
      //     this.fileList = []
      //   } else {
      //     if (!isLt5M) {
      //       this.$message.error('上传文件大小不能超过 5MB!')
      //       this.fileList = []
      //     } else {
      //       if (!isTime60S) {
      //         this.$message.error('上传文件时长不能超过60秒!')
      //         this.fileList = []
      //       }
      //     }
      //   }
      //   return isAudio && isLt5M
      // },
      getTimes(file) {
        console.log('文件类型file,', file)
        var content = file
        //获取录音时长
        var url = URL.createObjectURL(content)
        //经测试，发现audio也可获取视频的时长
        var audioElement = new Audio(url)
        audioElement.addEventListener('loadedmetadata', (_event) => {
          this.audioDuration = parseInt(audioElement.duration)
          console.log('获取录音 shichang', this.audioDuration)
          this.$emit('uploadtime', this.audioDuration)
          // console.log(this.audioDuration);
        })
      },

      async getOssData() {
        return postAction('/api/common/common/oss-sign').then((res) => {
          console.log(res)
          return res.data
        })
      },
      // 生成文件名，作为 key 使用
      generateFileName(ossData, file) {
        const suffix = file.name.slice(file.name.lastIndexOf('.'))
        const filename = Date.now() + suffix
        return ossData.dir + '/' + filename
      },
      async uploadImg(file) {
        this.loading = true
        const ossData = await this.getOssData()
        const key = this.generateFileName(ossData, file.file)
        const formdata = new FormData()

        // 注意参数的顺序，key 必须是第一位，表示OSS存储文件的路径
        formdata.append('key', key)
        formdata.append('OSSAccessKeyId', ossData.accessid)
        formdata.append('policy', ossData.policy)
        formdata.append('signature', ossData.signature)
        // 文件上传成功默认返回 204 状态码，可根据需要修改为 200
        formdata.append('success_action_status', '200')
        // file 必须放在最后一位
        formdata.append('file', file.file)

        await axios
          .post(ossData.host, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            console.log(res)
            if (res.status === 200) {
              this.Video.push('https://res.httoutiao.com/' + key)
              this.$emit('uploadVideo', this.Video)
              this.loading = false
            }
          })

        /*console.log(file.file)
      const formData = new FormData();
      formData.append('file', file.file)
      this.loading = true
      console.log(file)
      uploadAction("/api/index/upload-video",  formData).then((e) => {
        console.log(e)
        if (e.request_code == 200) {
          this.Video.push(e.data.url)
          this.$emit("uploadVideo", this.Video)
          this.loading = false
        }
      })*/
      },
      // 文件超出
      limitLong() {
        this.$message.error(`最多上传${this.limit}张图片`)
        return false
      },
      handleDeleteVideo(index) {
        this.Video.splice(index, 1)
        this.$emit('uploadVideo', this.Video)
      },
      handlePictureCardPreview(file) {
        this.dialogVideoUrl = file.url
        this.dialogVisible = true
      },
      onEnd(e) {
        console.log(e)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .imgList {
    display: flex !important;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .close {
    position: absolute;
    top: -9px;
    right: -4px;
    font-size: 22px;
    cursor: pointer;
  }
</style>
