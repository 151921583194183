<script>
import { getAction, postAction } from "@/api/request";
import WangEditor from "@/components/WangEditor.vue";

export default {
  name: "addEdit",
  components: { WangEditor },
  data() {
    return {
      showadd: false,
      columnlist:[],
      form: {
        /**
         * 内容
         */
        desc: '',
        /**
         * ID，修改必填
         */
        id: '',
        /**
         * 排序
         */
        sort_order:'',
        /**
         * 状态 1启用2禁用
         */
        status: '',
        /**
         * 名称
         */
        title: '',
        /**
         * 栏目ID
         */
        type_id: '',
      },
      rules: {
        desc: [
          { required: true, message: '请输入文案', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        type_id: [
          { required: true, message: '请选择栏目', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    showadd(val) {
      if (!val) {
        this.close()
        this.$refs.form.resetFields()
      }
    }
  },
  mounted() {
    getAction('/api/system/help/type-lists',{limit:-1}).then(res=>{
      this.columnlist=res.data
    })
  },
  methods: {
    getHtmlChang(val,prop){
      console.log(val)
        this.form[prop]=val
    },
    handlersave() {
      this.$refs.form.validate(valid => {
        if (valid){
          postAction("/api/system/help/edit",this.form).then(res=>{
            this.$message({
              type:'success',
              message:res.msg
            })
            this.$emit("getlist")
            this.close()
          })
        }
      })
    },
    show(id) {
      this.showadd = true
      if (id) {
        this.form.id = id
        getAction('/api/system/help/info', { id: id }).then((res) => {
          this.form = Object.assign(this.form, res.data)
          this.$nextTick(() => {
            this.$refs.desc.setHtml(this.form.desc)
          })
        })
      }
    },
    close() {
      this.$refs.desc.clearHtml()
      this.showadd = false
      this.form = {
        /**
         * 内容
         */
        desc: '',
        /**
         * ID，修改必填
         */
        id: '',
        /**
         * 排序
         */
        sort_order:'',
        /**
         * 状态 1启用2禁用
         */
        status: '',
        /**
         * 名称
         */
        title: '',
        /**
         * 栏目ID
         */
        type_id: '',
      }
    }
  }
};
</script>

<template>
  <el-dialog
    title=""
    :visible.sync="showadd"
    width="60%">
    <span>
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入标题" clearable></el-input>
        </el-form-item>
        <el-form-item label="栏目" prop="type_id">
          <el-select v-model="form.type_id" placeholder="请选择栏目" clearable style="width: 160px">
            <el-option v-for="item in columnlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" prop="desc">
          <wang-editor ref="desc" height="300" @getHtmlChang="getHtmlChang($event,'desc')"/>
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input v-model="form.sort_order" placeholder="请输入排序" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态" clearable>
            <el-option label="启用" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<style scoped>

</style>
