<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="发布开始日期"
          end-placeholder="发布结束日期"
          :default-time="['12:00:00']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="datatime"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="数据开始日期"
          end-placeholder="数据结束日期"
          :default-time="['12:00:00']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入产品标题"
          clearable
          v-model="form.keywords"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.category_id"
          clearable
          placeholder="请选择品类"
        >
          <el-option
            v-for="list in cateList"
            :key="list.id"
            :label="list.title"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_related_yjh"
          clearable
          placeholder="关联云交会"
        >
          <el-option
            v-for="list in relatedlist"
            :key="list.id"
            :label="list.title"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.operating_user"
          clearable
          placeholder="最后编辑人"
          filterable
        >
          <el-option
            v-for="list in stafflist"
            :key="list.id"
            :label="list.truename"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.data_type" clearable placeholder="来源">
          <el-option
            v-for="list in datatypelist"
            :key="list.id"
            :label="list.title"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onAdd">新 增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      v-loading="tableloading"
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <div slot="header">
            {{ scope.column.label }}

            <el-tooltip
              v-if="scope.column.label == '总访客数'"
              class="item"
              effect="dark"
              placement="top"
            >
              <div slot="content">
                发布的新品的总访客数是指每日新品详情页的UV，其余的为从每日新品栏目进入产品详情页的UV
              </div>

              <i class="el-icon-warning"></i>
            </el-tooltip>
            <el-tooltip
              v-if="scope.column.label == '总浏览数'"
              class="item"
              effect="dark"
              placement="top"
            >
              <div slot="content">
                发布的新品的总访客数是指每日新品详情页的PV，其余的为从每日新品栏目进入产品详情页的PV
              </div>

              <i class="el-icon-warning"></i>
            </el-tooltip>
            <el-tooltip
              v-if="scope.column.label == '总意向数'"
              class="item"
              effect="dark"
              placement="top"
            >
              <div slot="content">
                发布的新品的总意向数是指点击“请企业联系我”或“查看产品更多详情”数量，其余的为留言成功、样品申请、拨打电话的和
              </div>

              <i class="el-icon-warning"></i>
            </el-tooltip>
            <el-tooltip
              v-if="scope.column.label == '意向率'"
              class="item"
              effect="dark"
              placement="top"
            >
              <div slot="content">意向率=总意向数/总访客数</div>

              <i class="el-icon-warning"></i>
            </el-tooltip>
            <el-tooltip
              v-if="scope.column.label == '点击率'"
              class="item"
              effect="dark"
              placement="top"
            >
              <div slot="content">
                点击率=（列表点击量+首页点击量）/（列表曝光量+首页曝光量）
              </div>

              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
        <!--  slot-scope="scope" -->
        <template #default="{ row }">
          <div v-if="item.label == '封面图'">
            <el-image
              v-for="(item, index) in row[item.prop].split(',')"
              :key="index"
              style="width: 70px; height: 70px"
              :src="item"
              :preview-src-list="[item]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == 'id'">
            <span v-if="row.data_type == 1">{{ row[item.prop] }}</span>
            <span v-else></span>
          </div>
          <div v-else-if="item.label == '关联云交会'">
            <span v-if="row.data_type == 1">
              {{ row[item.prop] == 1 ? '已关联' : '未关联' }}
            </span>
            <span v-else></span>
          </div>
          <div v-else-if="item.label == '品牌'">
            <span v-if="row.data_type == 1">{{ row[item.prop] }}</span>
            <span v-else></span>
          </div>
          <div v-else-if="item.label == '来源'">
            <span v-if="row[item.prop] == 1">发布的新品</span>
            <span v-else-if="row[item.prop] == 2">企业上传的产品</span>
            <span v-else-if="row[item.prop] == 3">每日新品广告</span>
          </div>
          <div v-else-if="item.label == '首次发布时间'">
            <span v-if="row.data_type == 1">{{ row[item.prop] }}</span>
            <span v-else></span>
          </div>
          <div v-else-if="item.label == '是否广告'">
            <span v-if="row.data_type == 1">
              {{ row[item.prop] == 1 ? '是' : '否' }}
            </span>
            <span v-else></span>
          </div>
          <div v-else-if="item.label == '首页曝光量'">
            <span v-if="row.data_type == 1 || row.data_type == 3">
              {{ row[item.prop] }}
            </span>
            <span v-else></span>
          </div>
          <div v-else-if="item.label == '首页点击量'">
            <span v-if="row.data_type == 1 || row.data_type == 3">
              {{ row[item.prop] }}
            </span>
            <span v-else></span>
          </div>
          <div v-else-if="item.label == '总意向数'">
            <a
              href="javascript:;"
              v-if="row.data_type == 1"
              @click="checkIntention(row)"
            >
              {{ row[item.prop] }}
            </a>
            <span v-else>{{ row[item.prop] }}</span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template #default="{ row }">
          <div v-if="row.data_type == 1">
            <el-button
              type="text"
              size="small"
              @click="onEdit(row)"
              style="margin-right: 10px"
            >
              编辑
            </el-button>
            <el-popconfirm title="确认删除？" @confirm="onDelete(row)">
              <el-button slot="reference" type="text" size="small">
                删除
              </el-button>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <editcom ref="editcom" @refresh="getList" />
    <intention ref="intention" @getlist="onSearch()" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import editcom from './editCom'
  import * as excel from '@/utils/excel'
  import intention from './intention'
  export default {
    name: 'index',
    components: { editcom, intention },
    data() {
      return {
        tableloading: false,
        form: {
          keywords: '',
          category_id: '', //品类搜索id
          is_related_yjh: '', //0 未关联 1已关联 ''全部
          operating_user: '', //操作账号
          data_type: '', //数据来源 1.发布的新品 2.企业上传的产品 3.每日新品广告
          start_time: '',
          end_time: '',
          data_start_time: '', //数据开始日期
          data_end_time: '', //数据结束日期
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [1],
        columns: [
          {
            label: 'id',
            prop: 'id',
            width: '50',
          },
          {
            label: '首次发布时间',
            prop: 'created',
            width: '',
          },
          {
            label: '是否广告',
            prop: 'is_advert',
            width: '',
          },
          {
            label: '最后编辑人',
            prop: 'operating_user',
            width: '',
          },
          {
            label: '创建人',
            prop: 'created_user',
            width: '',
          },
          {
            label: '新品标题',
            prop: 'title',
            width: '160',
          },

          {
            label: '封面图',
            prop: 'cover_img',
            width: '',
          },
          {
            label: '关联云交会',
            prop: 'is_related',
            width: '',
          },
          {
            label: '品类',
            prop: 'category',
            width: '',
          },
          {
            label: '二级品类',
            prop: 'cat_name',
            width: '',
          },
          {
            label: '品牌',
            prop: 'brand_name',
            width: '',
          },
          {
            label: '每日上新日期',
            prop: 'publish_time',
            width: '',
          },
          {
            label: '来源',
            prop: 'data_type',
            width: '',
          },
          {
            label: '总点赞',
            prop: 'like_num',
            width: '',
          },
          {
            label: '总收藏',
            prop: 'collect_num',
            width: '',
          },
          {
            label: '总访客数',
            prop: 'visitor_num',
            width: '',
          },
          {
            label: '总浏览数',
            prop: 'view_num',
            width: '',
          },
          {
            label: '总意向数',
            prop: 'intention_num',
            width: '',
          },
          {
            label: '意向率',
            prop: 'intention_ratio',
            width: '',
          },
          {
            label: '首页曝光量',
            prop: 'home_exposure_num',
            width: '',
          },
          {
            label: '首页点击量',
            prop: 'home_click_num',
            width: '',
          },
          {
            label: '列表曝光量',
            prop: 'list_exposure_num',
            width: '',
          },
          {
            label: '列表点击量',
            prop: 'list_click_num',
            width: '',
          },
          {
            label: '点击率',
            prop: 'click_ratio',
            width: '',
          },
        ],
        total: 0,
        cateList: [], //品类列表
        time: [],
        datatime: [],
        datatypelist: [
          {
            id: 1,
            title: '发布的新品',
          },
          {
            id: 2,
            title: '企业上传的产品',
          },
          {
            id: 3,
            title: '每日新品广告',
          },
        ],
        relatedlist: [
          {
            id: 1,
            title: '已关联',
          },
          {
            id: '0',
            title: '未关联',
          },
        ],
        stafflist: [],
        cateChildList: [],
      }
    },
    watch: {
      datatime(v) {
        if (v) {
          this.form.data_start_time = v[0]
          this.form.data_end_time = v[1]
        } else {
          this.form.data_start_time = ''
          this.form.data_end_time = ''
        }
      },
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      // this.handlerInquire()
      this.getList()
      this.getCateList()
      this.getStafflist()
    },
    methods: {
      handlerExport() {
        // let data = JSON.parse(JSON.stringify(this.form))
        // console.log('导出，', data)
        postAction(
          '/api/daily/new-daily/publish-new-daily-export',
          this.form
        ).then((res) => {
          console.log('接口返回', res)
          if (res.request_code == 200) {
            this.$message.success(res.msg)
          }
          // excel.export_json_to_excel({
          //   header: res.data.header,
          //   data: res.data.data,
          //   filename: res.data.name,
          // })
        })
      },
      //查看意向详情
      checkIntention(row) {
        console.log('点击查看,')
        this.$refs.intention.isShowDialog = true
        this.$refs.intention.form.keywords = row.title
        this.$refs.intention.getlistdata()
        this.$refs.intention.getproductlist()
      },
      getStafflist() {
        // /form/form/staff
        getAction('/auth/user/index', { pageSize: 9999 }).then((res) => {
          if (res.request_code == 200) {
            this.stafflist = res.data
          }
        })
      },
      getCateList() {
        getAction('/api/daily/new-daily/category').then((res) => {
          if (res.request_code == 200) {
            this.cateList = res.data
          }
        })
      },
      getCateChild() {
        getAction('/user/index/cate').then((res) => {
          if (res.request_code == 200) {
            this.cateChildList = res.data
          }
        })
      },
      // 查询
      onSearch() {
        this.form.page = 1
        this.getList()
      },
      // 发布新品列表
      getList() {
        this.tableloading = true
        getAction(
          '/api/daily/new-daily/publish-new-daily-list',
          this.form
        ).then((res) => {
          if (res.request_code == 200) {
            this.tabledata = res.data
            this.total = parseInt(res.totalCount)
          }
          this.tableloading = false
        })
      },
      // 编辑操作
      onEdit(row) {
        this.$refs.editcom.isShowDialog = true
        this.$refs.editType = 2
        this.$refs.editcom.getInfo(row)
      },
      //新增操作
      onAdd() {
        this.$refs.editcom.isShowDialog = true
        this.$refs.editType = 1
        this.$refs.editcom.getInfo(row)
      },
      // 新品删除操作
      onDelete(row) {
        getAction('/api/daily/new-daily/new-daily-del', { id: row.id }).then(
          (res) => {
            if (res.request_code == 200) {
              this.$message.success('删除成功')
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      // 选项卡点击操作
      handleClick(tab, event) {
        console.log(tab, event)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getList()
      },
    },
  }
</script>

<style scoped>
  .round {
    width: 380px;
    height: 380px;
  }
  .img {
    max-width: 100%;
  }
</style>
