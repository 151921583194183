var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.form } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: { placeholder: "标题搜索", clearable: "" },
                model: {
                  value: _vm.form.keywords,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "keywords",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.keywords",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择类型", clearable: "" },
                  model: {
                    value: _vm.form.label_id,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "label_id",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.label_id",
                  },
                },
                _vm._l(_vm.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "禁用", value: "0" } }),
                  _c("el-option", { attrs: { label: "启用", value: "1" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getsearch } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerEdit()
                    },
                  },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handlerline(_vm.row)
                    },
                  },
                },
                [_vm._v(" 路线管理 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: { border: "", data: _vm.tabledata, "row-key": "user_id" },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", prop: "id", width: "100" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "封面图",
              prop: "cover_img",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.type == 5 || row.type == 6
                      ? _c(
                          "el-image",
                          {
                            staticStyle: { width: "120px" },
                            attrs: { src: row.img },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "placeholder" },
                                slot: "placeholder",
                              },
                              [
                                _vm._v(" 加载中 "),
                                _c("span", { staticClass: "dot" }, [
                                  _vm._v("..."),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _c(
                          "el-image",
                          {
                            staticStyle: { width: "100px", height: "auto" },
                            attrs: { src: row.cover_img },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "placeholder" },
                                slot: "placeholder",
                              },
                              [
                                _vm._v(" 加载中 "),
                                _c("span", { staticClass: "dot" }, [
                                  _vm._v("..."),
                                ]),
                              ]
                            ),
                          ]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "标题", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类型",
              prop: "label_title",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "活动开始时间",
              prop: "start_time",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "活动结束时间",
              prop: "end_time",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "报名开始时间",
              prop: "apply_start_time",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "报名结束时间",
              prop: "apply_end_time",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "浏览次数",
              prop: "view_num",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "访问人数",
              prop: "visitor_num",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "排序",
              prop: "sort_order",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              prop: "status",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(row.status == 0 ? "禁用" : "开启") + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              prop: "id",
              width: "100",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "10px" },
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handlerEdit(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", {
        ref: "edit",
        on: {
          getlist: function ($event) {
            return _vm.getsearch()
          },
        },
      }),
      _c("linecom", {
        ref: "line",
        on: {
          getlist: function ($event) {
            return _vm.getsearch()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }