<template>
  <el-dialog :title="title" :visible.sync="showedit" width="90%">
    <el-tabs v-model="tabindex" @tab-click="handleClick">
      <el-tab-pane label="平台主推" name="0"></el-tab-pane>
      <el-tab-pane label="近期上线" name="1"></el-tab-pane>
    </el-tabs>
    <el-form ref="form" label-width="80px" :model="form" inline>
      <el-form-item>
        <el-input
          type="text"
          v-model="form.goods_name"
          placeholder="产品名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="一级品类" v-if="form.mall_id == ''">
        <el-select
          v-model="form.one_cate"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="二级品类" v-if="form.mall_id == ''">
        <el-select
          v-model="form.two_cate"
          clearable
          placeholder="二级分类"
          style="width: 160px"
          @change="handlertwo"
        >
          <el-option
            v-for="list in twocatselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="招商区域">
        <el-select
          v-model="form.province"
          clearable
          placeholder="请选择招商区域"
          style="width: 160px"
        >
          <el-option
            v-for="list in provincelist"
            :key="list.id"
            :label="list.name"
            :value="list.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="handlerlist()">
          查询
        </el-button>
      </el-form-item>
    </el-form>

    <div style="display: flex">
      <el-table
        border
        :data="tabledata"
        style="flex: 1"
        :row-key="getRowKey"
        @selection-change="setSelectRows"
        ref="ElTable"
        max-height="500px"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '产品图片'">
              <el-image
                :preview-src-list="[row.img]"
                :src="row.img"
                style="width: 80px; height: 80px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </div>
            <div v-else-if="item.label == '用户状态'">
              {{ row.is_delaer == 1 ? '已认证' : '未认证' }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="selectlist.length > 0"
        style="
          width: 240px;
          margin-left: 10px;
          padding: 0 15px;
          border: 1px solid #ccc;
        "
      >
        <p style="cursor: default">已选择</p>
        <div
          v-for="(list, index) in selectlist"
          :key="index"
          style="line-height: 25px"
        >
          <span>{{ list.goods_name }}</span>
          <span style="margin-left: 8px" @click="closeselect(list, index)">
            <i class="el-icon-delete" style="font-size: 16px"></i>
          </span>
        </div>
      </div>
    </div>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="onclose">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    data() {
      return {
        title: '创建选品方案',
        form: {
          mall_id: '',
          mall_name: '',
          one_cate: '',
          two_cate: '',
          type: '1', //1 平台主推 2 近期上线
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        columns: [
          {
            label: '产品名称',
            prop: 'goods_name',
          },
          {
            label: '产品图片',
            prop: 'img',
            width: '110px',
          },
          {
            label: '规格1',
            prop: 'attr1',
            width: '90px',
          },
          {
            label: '规格2',
            prop: 'attr2',
            width: '90px',
          },
          {
            label: '规格3',
            prop: 'attr3',
            width: '90px',
          },
        ],
        cateselect: [],
        showedit: false,
        pricelist: [],
        showpricelist: false,
        twocatselect: [],
        provincelist: [], //招商区域列表
        total: 0,
        selectRows: [],
        tabledata: [],
        selectlist: [],
        tabindex: 0,
      }
    },
    watch: {
      tabindex(n, o) {
        if (n) {
          this.form.type = Number(this.tabindex) + 1
          this.handlerlist()
        }
      },
    },
    mounted() {
      // 获取一级品类
      getAction('/user/index/cate', { pid: 0 }).then((res) => {
        this.cateselect = res.data
      })
      this.getWinePrice()
      this.handlerlist()
      this.getprovince()
    },
    methods: {
      handleClick(tab, e) {
        this.tabindex = tab.index
        console.log('点击索引，', tab.index)
      },
      closeselect(list, index) {
        this.selectlist.splice(index, 1)
        this.tabledata.forEach((item, i) => {
          if (list.id == item.id) {
            this.$refs.ElTable.toggleRowSelection(this.tabledata[i], false)
          }
        })
      },
      //获取产品列表
      getproduct() {
        postAction('/api/work/sel-plan/product-lists', this.form).then(
          (res) => {
            if (res.code == 0) {
              this.tabledata = res.data.list
              this.total = res.totalCount
            }
          }
        )
      },
      handlerlist() {
        this.form.page = 1
        this.getproduct()
      },
      // 获取招商区域列表
      getprovince() {
        postAction('/api/work/sel-plan/get-province').then((res) => {
          if (res.code == 0) {
            this.provincelist = res.data
          }
        })
      },
      getRowKey(row) {
        return row.id
      },
      setSelectRows(val) {
        console.log('选中行-----', val)
        this.selectRows = val.map((list) => {
          return list.id
        })
        this.selectlist = val
      },
      handlertwo(e) {
        if (this.form.two_cate.length > 3) {
          this.$message.info('最多选择3个品类')
          this.form.two_cate.pop()
        }
      },
      onclose() {
        this.showedit = false
        this.showpricelist = false
        this.form = {
          goods_name: '',
          one_cate: '',
          two_cate: '',
          province: '',
          price_id: '',
          mall_id: '',
        }
        this.selectlist = []
        this.selectRows = []
        this.$refs.ElTable.clearSelection()
      },
      // 保存
      handlersave() {
        console.log('参数========', this.form)
        // if (this.form.price_id && this.form.price_id.length > 0) {
        //   this.form.price_id = this.form.price_id.join(',')
        // }
        // if (this.form.two_cate && this.form.two_cate.length > 0) {
        //   this.form.two_cate = this.form.two_cate.join(',')
        // }
        getAction('/api/work/sel-plan/create-selplanproductmass', {
          id_str: this.selectRows.join(','),
        }).then((res) => {
          this.$message.success('创建成功！')
          this.onclose()
          this.$emit('getlist')
        })
      },
      // 选择品类
      handlercat(e) {
        console.log('选择的品类', e)
        if (e == 1) {
          //白酒类型 显示价格带
          this.showpricelist = true
        } else {
          this.showpricelist = false
        }
        this.form.two_cate = ''
        this.twocatselect = []
        this.getTwocate()
      },
      // 获取二级品类
      getTwocate() {
        getAction('/user/index/cate', { pid: this.form.one_cate }).then(
          (res) => {
            this.twocatselect = res.data
          }
        )
      },
      // 获取价格带数据
      getWinePrice() {
        postAction('/audit/biz-clue/wine-price', {}).then((res) => {
          console.log('价格带-', res)
          if (res.request_code == 200) {
            this.pricelist = res.data
          }
        })
      },
      handlermell(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.mall_id = ''
        } else {
          getAction('/mall/index/mall', { mall_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelectmall(list) {
        this.form.mall_id = list.id
        // if (list.sel_is_like == 1) {
        //   this.mallLimit[this.currentid].name = ''
        //   this.$message.error('客户已匹配该店铺，请勿重复匹配。')
        // } else {
        //   this.mallidlist[this.currentid] = list.id
        // }
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getproduct()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getproduct()
      },
    },
  }
</script>
