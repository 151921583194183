<template>
  <div class="index-container">
    <el-table
      :show-header="false"
      @row-click="rowClick"
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :sortable="item.sort"
        :align="item.align"
      >
        <template #default="{row}">
          <div v-if="item.label=='图标'">
                        <el-image
                          style="width: 30px; height: 30px"
                          :src="row[item.prop]?yidu:weidu">
                          <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                          </div>
                        </el-image>
          </div>
          <div v-else-if="item.label=='类型'">
            {{row[item.prop]==1?'导入':row[item.prop]==2?'导出':'通知'}}
          </div>
          <div v-else-if="item.label=='名称'" style="cursor: pointer">
            <span :style="row.is_read?{color:'#a5a4a4'}:{color: '#050505',fontWeight: bold,}">{{row[item.prop]}}</span>
            <span v-show="(row.type==1||row.type==2)&&row.status=='已完成'" style="cursor: pointer;color: #1ba4ff;border-bottom: 1px solid #1ba4ff">请手动下载</span>
<!--            <span v-show="row.type==3&&row.status=='已失败'" style="cursor: pointer;color: #1ba4ff;border-bottom: 1px solid #1ba4ff">请点击修改</span>-->
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getAction } from "@/api/request";

export default {
  name: "index",
  data() {
    return {
      yidu: require('@/assets/yidu.png'),
      weidu: require('@/assets/weidu.png'),
      form: {
        page: 1,
        limit: 10,
      },
      columns: [
        {
          label: '图标',
          prop: 'is_read',
          width: '80',
          align:'center'
        },
        {
          label: '名称',
          prop: 'title',
          width: '',
          align:'left'
        },
        {
          label: '类型',
          prop: 'type',
          width: '80',
          align:'center'
        },
        {
          label: '时间',
          prop: 'created',
          width: '160',
          align:'center'
        },
      ],
      tabledata: [],
      total: 0,
      layout: "total, sizes, prev, pager, next, jumper",
    }
  },
  mounted() {
    this.handlerlist()
  },
  methods: {
    rowClick(row){
      getAction("/user/task/read",{id:row.id}).then(res=>{
        if((row.type==1||row.type==2)&&row.status=='已完成'){
          window.open(row.out_file)
        }
        this.handlerlist()
       /* if (row.type==3){
          if(row.jump_type==1){
            this.$router.push({path:'/product/productlist',query:{id:row.jump_id}})
          }else if (row.jump_type==2){
            this.$router.push({path:'/enterprise/storeManagement'})
          }

        }*/
      })

    },
    handlerlist(){
      getAction("/user/task/lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
