var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.showliu, width: "60%" },
      on: {
        "update:visible": function ($event) {
          _vm.showliu = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择品类", clearable: "" },
                      model: {
                        value: _vm.form.category_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "category_id", $$v)
                        },
                        expression: "form.category_id",
                      },
                    },
                    _vm._l(_vm.catelist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "选择月榜单", clearable: "" },
                      model: {
                        value: _vm.form.month,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "month", $$v)
                        },
                        expression: "form.month",
                      },
                    },
                    _vm._l(_vm.monthlist, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "选择状态", clearable: "" },
                      model: {
                        value: _vm.form.is_del,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_del", $$v)
                        },
                        expression: "form.is_del",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "在榜", value: "0" } }),
                      _c("el-option", { attrs: { label: "删除", value: "1" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { placeholder: "请输入产品名称", clearable: "" },
                    model: {
                      value: _vm.form.goods_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_name", $$v)
                      },
                      expression: "form.goods_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      type: "daterange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "关联云交会", clearable: "" },
                      model: {
                        value: _vm.form.is_link_goods,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_link_goods", $$v)
                        },
                        expression: "form.is_link_goods",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "否", value: "0" } }),
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSearch } },
                    [_vm._v("查 询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerExport },
                    },
                    [_vm._v("导 出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-descriptions",
            { attrs: { direction: "vertical", column: 8, border: "" } },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      [
                        _vm._v(" 总浏览量（pv） "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "总浏览量是榜单斑的钫问人次统计，仅根据品类和月榜单查询改变",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-warning" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" " + _vm._s(_vm.totallist.total_pv)),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      [
                        _vm._v(" 总访客数（uv） "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "总访客数是榜单页的访问人数统计，仅根据品类和月榜单査询改变",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-warning" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" " + _vm._s(_vm.totallist.total_uv)),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "总点赞数", span: 2 } },
                [_vm._v(" " + _vm._s(_vm.totallist.like_num))]
              ),
              _c("el-descriptions-item", { attrs: { label: "总收藏数" } }, [
                _vm._v(" " + _vm._s(_vm.totallist.collect_num) + " "),
              ]),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      [
                        _vm._v(" 产品页总浏览数 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "产品页总浏览数是产品在榜期间从榜单页进入产品详情页的PV",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-warning" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" " + _vm._s(_vm.totallist.view_pv)),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      [
                        _vm._v(" 产品页总访客数 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "产品页总访客数是产品在榜期间从榜单页进入产品详情页的UV",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-warning" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" " + _vm._s(_vm.totallist.view_uv)),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      [
                        _vm._v(" 总意向数 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "总意问数是指产品在榜期间点击“请企业联系我”、留言成功、样品申请成功、拨打电话成功之和",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-warning" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" " + _vm._s(_vm.totallist.intent_num)),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tabledata },
            },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  prop: item.prop,
                  label: item.label,
                  width: item.width,
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          item.label == "封面图"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "70px",
                                        height: "70px",
                                      },
                                      attrs: {
                                        src: row[item.prop],
                                        "preview-src-list": [row[item.prop]],
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "placeholder" },
                                          slot: "placeholder",
                                        },
                                        [
                                          _vm._v(" 加载中 "),
                                          _c("span", { staticClass: "dot" }, [
                                            _vm._v("..."),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }