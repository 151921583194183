<template>
  <el-dialog
    title=""
    :visible.sync="showDialog"
    width="700px"
    top="5vh"
    :close-on-click-modal="false"
  >
    <el-form
      :model="form"
      ref="form"
      label-position="right"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item label="顶部背景图" prop="toppic">
        <upload-img
          ref="pic"
          :infoText="''"
          :limit="5"
          @getImgs="getGoods_imgImg($event, 'toppic')"
          :maxSize="100"
        />
      </el-form-item>
      <el-form-item label="底部背景图1">
        <upload-img
          ref="bottompic1"
          :infoText="''"
          :limit="5"
          @getImgs="getGoods_imgImg($event, 'bottompic1')"
          :maxSize="100"
        />
      </el-form-item>
      <el-form-item label="跳转类型">
        <el-select
          v-model="form.operation_type"
          clearable
          style="width: 160px"
          placeholder="请选择"
        >
          <el-option label="无跳转" value="1" />
          <el-option label="H5链接" value="2" />
          <el-option label="小程序链接" value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="跳转链接">
        <el-input v-model="form.url" placeholder="请输入链接"></el-input>
      </el-form-item>
      <el-form-item label="底部背景图2">
        <upload-img
          ref="bottompic2"
          :infoText="''"
          :limit="5"
          @getImgs="getGoods_imgImg($event, 'bottompic2')"
          :maxSize="100"
        />
      </el-form-item>
      <el-form-item label="跳转类型">
        <el-select
          v-model="form.operation_type"
          clearable
          style="width: 160px"
          placeholder="请选择"
        >
          <el-option label="无跳转" value="1" />
          <el-option label="H5链接" value="2" />
          <el-option label="小程序链接" value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="跳转链接">
        <el-input v-model="form.url" placeholder="请输入链接"></el-input>
      </el-form-item>
    </el-form>
    <div class="bottom-button">
      <el-button @click="showDialog = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg.vue'
  export default {
    name: 'BanLog',
    components: { UploadImg },
    data() {
      return {
        showDialog: false,
        loading: false,
        user_id: '',
        total: 0,
        form: {},
        list: [],
      }
    },
    created() {},
    mounted() {},
    computed: {},
    watch: {
      showDialog(v) {
        if (v) {
          this.fetchData()
        } else {
          this.closeimg('toppic')
        }
      },
    },
    methods: {
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v.join(',')
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      async fetchData() {
        this.loading = true
        // let data = await postAction('/Users/Lock_log', {
        //   user_id: this.user_id,
        // })
        // console.log('data', data)
        // this.total = data.result[0].total
        // this.list = data.result[0].list
        // this.loading = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
