<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    top="5vh"
    :visible.sync="showEditDialog"
    width="40%"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="100px"
      :model="form"
    >
      <el-form-item label="菜单标题">
        <el-input
          v-model="form.title"
          placeholder="请输入菜单标题"
          style="width: 60%"
        />
      </el-form-item>
      <el-form-item label="菜单图标" prop="pic">
        <upload-img
          ref="pic"
          :info-text="'尺寸：126*126'"
          :limit="1"
          :max-size="100"
          @getImgs="getGoods_imgImg($event, 'icon_img')"
        />
      </el-form-item>

      <el-form-item label="跳转类型">
        <el-select v-model="form.jump_type" clearable placeholder="请选择">
          <el-option label="无跳转" value="0" />
          <el-option label="H5链接" value="1" />
          <el-option label="小程序通用链接" value="2" />
          <el-option label="糖烟酒周刊小程序" value="3" />
          <el-option label="线下研修弹窗" value="4" />
          <el-option label="选品趋势报告弹窗" value="5" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.jump_type == 1 || form.jump_type == 2 || form.jump_type == 3"
        label="跳转链接"
      >
        <el-input v-model="form.jump_url" placeholder="请输入链接" />
      </el-form-item>
      <el-form-item label="菜单排序">
        <el-input
          v-model="form.sort_order"
          placeholder="请输入"
          style="width: 100px"
        />
      </el-form-item>
      <el-form-item label="菜单状态">
        <el-radio v-model="form.status" label="1">启用</el-radio>
        <el-radio v-model="form.status" label="0">禁用</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showEditDialog = false">取消</el-button>
      <el-button @click="handlerSave">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg.vue'
  export default {
    name: 'EditMenu',
    components: { UploadImg },
    data() {
      return {
        showEditDialog: false,
        loading: false,
        title: '新增菜单',
        form: {
          title: '',
          icon_img: '',
          jump_type: '',
          jump_url: '',
          status: '1',
          sort_order: 0,
        },
      }
    },
    computed: {},
    watch: {
      showEditDialog(v) {
        if (v) {
          this.fetchData()
        } else {
          this.closeimg('pic')
          this.onreset()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      onreset() {
        this.form = {
          title: '',
          icon_img: '',
          jump_type: '',
          jump_url: '',
          status: '1',
          sort_order: 0,
        }
      },
      handlerinfo(row) {
        if (row) {
          this.title = '编辑菜单'
          this.form = JSON.parse(JSON.stringify(row))
          this.form.jump_type = String(this.form.jump_type)
          this.form.status = String(this.form.status)
          this.$nextTick(() => {
            this.setImg(row.icon_img, 'pic')
          })
        } else {
          this.title = '新增菜单'
        }
        this.showEditDialog = true
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v.join(',')
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      async fetchData() {
        this.loading = true
      },
      // 保存
      handlerSave() {
        if (this.form.title.length == 0) {
          this.$message.error('请输入菜单标题')
          return false
        }

        if (this.form.icon_img == '' || !this.form.icon_img) {
          this.$message.error('请上传菜单图标')
          return false
        }

        if (this.form.sort_order == '' || this.form.sort_order < 0) {
          this.$message.error('请输入大于0的排序数字')
          return false
        }

        postAction('/user/business-club/edit-menu', this.form).then((res) => {
          if (res.request_code == 200) {
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
          this.showEditDialog = false
          this.$emit('getlist')
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
