<template>
  <div>
    <el-dialog append-to-body :title="title" :visible.sync="isShow" width="50%">
      <div>
        <el-form :model="form" label-width="120px" :rules="rules" ref="form">
          <el-form-item
            v-if="goodstype == 1"
            label="产品名称"
            prop="goods_name"
          >
            <el-input
              placeholder="请输入产品名称"
              v-model="form.goods_name"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item
            v-else-if="goodstype == 2"
            label="产品名称"
            prop="goods_name"
          >
            <el-autocomplete
              v-model="form.goods_name"
              :fetch-suggestions="handlerhuose"
              placeholder="搜索产品名称"
              value-key="goods_name"
              @select="handleSelecthuose"
            />
          </el-form-item>

          <el-form-item v-if="goodstype == 1" label="展示图" prop="goods_img">
            <upload-img
              ref="goods_img"
              infoText="尺寸400x400，大小500K以内"
              :limit="1"
              :booleancorpper="true"
              @uploadImg="uploadImg"
              :maxSize="100"
              :enlarge="2"
              :auto-crop-width="200"
              :auto-crop-height="200"
              :mode="'400px,400px'"
            />
          </el-form-item>
          <el-form-item
            v-else-if="goodstype == 2"
            label="展示图"
            prop="goods_img"
          >
            <el-image
              :src="form.goods_img"
              style="width: 150px; height: 150px; margin-top: 10px"
            />
          </el-form-item>
          <el-form-item label="排序">
            <el-input
              placeholder="请输入"
              v-model="form.sort_order"
              style="width: 120px"
              type="number"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { uploadImg },
    data() {
      return {
        isShow: false,
        goodstype: 1, //1自建产品 2关联产品
        title: '新增产品',
        form: {
          type: '1',
          name: '',
          fid: '',
          yjh_id: '0',
          sort_order: 0,
        },
        yjh_mall_id: '0',
        rules: {
          goods_name: [
            {
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            },
          ],
          goods_img: [
            {
              required: true,
              message: '请上传图片',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    watch: {
      isShow(n, o) {
        if (n) {
          if (this.goodstype == 1) {
            this.setImg(this.form.goods_img, 'goods_img')
          }
        } else {
          this._onreset()
        }
      },
    },
    mounted() {},
    methods: {
      // 关联云交会企业远程搜索
      handlerhuose(queryString, cb) {
        console.log('测试----', queryString)
        // if (queryString == '') {
        //   cb([])
        //   this.form.yjh_id = ''
        // } else {
        //   getAction('/mall/product/goods', {
        //     goods_name: queryString,
        //     shop_id: this.yjh_mall_id,
        //   }).then((res) => {
        //     cb(res.data)
        //   })
        // }
        getAction('/mall/product/goods', {
          goods_name: queryString,
          shop_id: this.yjh_mall_id,
        }).then((res) => {
          cb(res.data)
        })
      },
      // 关联云交会企业选择
      handleSelecthuose(list) {
        console.log('--', list)
        this.form.yjh_id = list.id
        this.form.goods_img = list.goods_img
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.goods_img = ''
        } else {
          this.form.goods_img = val[0]
        }
      },
      handlerinfo(row) {
        this.title = '编辑产品'
        console.log('编辑，', row)
        this.form = Object.assign(this.form, row)
        setTimeout(() => {
          this.$refs.uploadImage.img = [row.goods_img]
        }, 100)
      },
      handlersave() {
        console.log('----产品保存参数，', this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            postAction('/api/afbc/act-meet/goods-edit', this.form).then(
              (res) => {
                console.log('')
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.$emit('getlist')
                  this.isShow = false
                } else {
                  this.$message.error(res.msg)
                }
                this._onreset()
              }
            )
          }
        })
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      setImgArray(v, prop) {
        console.log('图片组合', v, prop)
        if (v[0] != '') this.$refs[prop].img = v
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      _onreset() {
        if (this.goodstype == 1) {
          this.closeimg('goods_img')
        }
        this.form = {
          goods_name: '',
          goods_img: '',
          sort_order: '0',
        }
        this.goodstype = 1
      },
    },
  }
</script>
