var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.disabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            "label-width": "140px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "票券信息",
                "label-width": "140px",
                prop: "cdkey_id",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择发放票券" },
                  model: {
                    value: _vm.form.cdkey_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cdkey_id", $$v)
                    },
                    expression: "form.cdkey_id",
                  },
                },
                _vm._l(_vm.cdkeylist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.title, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发放范围", prop: "send_scope" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择发放范围" },
                  model: {
                    value: _vm.form.send_scope,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "send_scope", $$v)
                    },
                    expression: "form.send_scope",
                  },
                },
                _vm._l(_vm.scopeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.send_scope == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "会员套餐", prop: "vip_groups" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择会员套餐",
                      },
                      model: {
                        value: _vm.form.vip_groups,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "vip_groups", $$v)
                        },
                        expression: "form.vip_groups",
                      },
                    },
                    _vm._l(_vm.vipOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.send_scope == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "开通时段" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      align: "right",
                      "default-time": ["00:00:00", "23:59:59"],
                      "end-placeholder": "结束时间",
                      "start-placeholder": "开始时间",
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    on: { blur: _vm.changeDate },
                    model: {
                      value: _vm.time_arr,
                      callback: function ($$v) {
                        _vm.time_arr = $$v
                      },
                      expression: "time_arr",
                    },
                  }),
                  _c(
                    "el-tooltip",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-left": "5px",
                      },
                      attrs: {
                        content: "开通会员的时间范围，不选择默认不限制",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-warning" })]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.send_scope == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "指定用户", prop: "user_groups" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        filterable: "",
                        loading: _vm.uloading,
                        multiple: "",
                        placeholder: "请输入关键词",
                        remote: "",
                        "remote-method": _vm.remoteMethod,
                        "reserve-keyword": "",
                      },
                      model: {
                        value: _vm.form.user_groups,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "user_groups", $$v)
                        },
                        expression: "form.user_groups",
                      },
                    },
                    _vm._l(_vm.userOptions, function (item) {
                      return _c("el-option", {
                        key: item.user_id,
                        attrs: {
                          label: item.nickname + "【" + item.mobile + "】",
                          value: item.user_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "票券来源", prop: "source" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择票券来源" },
                  model: {
                    value: _vm.form.source,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "source", $$v)
                    },
                    expression: "form.source",
                  },
                },
                _vm._l(_vm.sourceOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.source != 1
            ? _c(
                "el-form-item",
                { attrs: { label: "数据来源", prop: "data_id" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.message },
                    model: {
                      value: _vm.form.data_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "data_id", $$v)
                      },
                      expression: "form.data_id",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "发放形式", prop: "send_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择票券发放形式" },
                  model: {
                    value: _vm.form.send_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "send_type", $$v)
                    },
                    expression: "form.send_type",
                  },
                },
                _vm._l(_vm.sendOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }