<template>
  <div class="index-container">
    <guize/>
    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '福利弹框图片'">
            <el-image
              v-if="row[item.prop]"
              style="width: 180px; height: 30px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '领取弹窗图片'">
            <el-image
              style="width: 30px; height: 30px"
              v-if="row[item.prop]"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '开关'">
            {{row[item.prop]==1?'开启':'关闭'}}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
<!--    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />-->
    <edit ref="edit" @getlist="handlerlist"/>
  </div>
</template>

<script>
import { getAction } from "@/api/request";
import Edit from "./components/edit.vue";
import Guize from "@/views/pages/systemSet/guideSet/components/guize.vue";

export default {
  name: "index",
  components: { Guize, Edit },
  data(){
    return{
      form:{
        page:1,
        limit:10
      },
      total:0,
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata:[],
      columns:[
        {
          label:'品类',
          prop:'cat_pname'
        },
        {
          label:'开关',
          prop:'door'
        },
        {
          label:'福利弹框图片',
          prop:'welfare_pic'
        },
        {
          label:'领取弹窗图片',
          prop:'receive_pic'
        },

      ]
    }
  },
  mounted(){
    this.handlerlist()
  },
  methods:{
    handlerlist(){
      getAction('/api/system/cat-popup/lists',this.form).then(res=>{
        this.tabledata=res.data
        this.total=Number(res.totalCount)
      })
    },
    handleredit(row){
      // this.$refs.edit.form=row
      let data = JSON.parse(JSON.stringify(row))
      this.$refs.edit.handlerinfo(data)
      this.$refs.edit.dialogVisible = true
    },
    handleCurrentChange(val){
      this.form.page=val
      this.handlerlist()
    },
    handleSizeChange(val){
      this.form.limit=val
      this.handlerlist()
    }
  }
};
</script>

<style scoped>

</style>
