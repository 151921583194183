<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="标题"
          style="width: 110px"
        />
      </el-form-item>
      <!-- <el-form-item>
        <el-select
          v-model="form.source_id"
          clearable
          placeholder="来源"
          style="width: 160px"
        >
          <el-option label="云交会" value="1" />
          <el-option label="头条拉取" value="2" />
          <el-option label="企业文章" value="3" />
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-select
          v-model="form.is_check"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option label="上架" value="1" />
          <el-option label="下架" value="0" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_hot"
          clearable
          placeholder="是否热文"
          style="width: 160px"
        >
          <el-option label="是" value="1" />
          <el-option label="否" value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="操作账号">
        <el-select
          v-model="form.admin_id"
          clearable
          placeholder="请选择操作账号"
          style="width: 160px"
          filterable
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.admin_id"
            :value="list.admin_id"
            :label="list.admin_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleradd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      v-loading="tableloading"
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '来源'">
            {{
              row[item.prop] == 1
                ? '云交会'
                : row[item.prop] == 2
                ? '头条拉取'
                : '企业文章'
            }}
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row[item.prop] == 1 ? '上架' : '下架' }}
          </div>
          <div v-else-if="item.label == '头像'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '封面图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '置顶'">
            {{ row[item.prop] == 1 ? '置顶' : '取消置顶' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerstatus(row)"
          >
            {{ row.is_check == 1 ? '下架' : '上架' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-edit ref="add" @getlist="handlerlist" />
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  import AddEdit from './components/articleedit.vue'

  export default {
    name: 'Index',
    components: { AddEdit },
    data() {
      return {
        form: {
          title: '',
          source_id: '1',
          is_check: '',
          page: 1,
          limit: 10,
          type: 2,
        },
        checkselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未审',
          },
          {
            id: 1,
            name: '已审',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '110',
          },
          {
            label: '标题',
            prop: 'title',
            width: '220px',
          },
          {
            label: '来源',
            prop: 'source_id',
            width: '120',
          },
          {
            label: '作者',
            prop: 'author',
            width: '80',
          },
          {
            label: '头像',
            prop: 'author_img',
            width: '110',
          },
          {
            label: '封面图',
            prop: 'cover_img',
            width: '160',
          },
          {
            label: '状态',
            prop: 'is_check',
            width: '120',
          },
          {
            label: '创建时间',
            prop: 'created',
            width: '80',
          },
          {
            label: '发布时间',
            prop: 'push_time',
            width: '160',
          },
          {
            label: '浏览量',
            prop: 'view_count',
            width: '140',
          },
          {
            label: '虚拟浏览量',
            prop: 'virtual_view_count',
            width: '130',
          },
          {
            label: '关联品类',
            prop: 'cate_name',
            width: '80',
          },
          {
            label: '置顶',
            prop: 'is_top',
            width: '130',
          },
        ],
        total: 0,
        areaselect: [],
        classselect: [],
        tableloading: false,
        staffSelect: [],
      }
    },
    mounted() {
      this.handlerInquire()
      this.getstaff()
      // this.handlerSelect()
    },
    methods: {
      getstaff() {
        getAction('/api/stroll/article/admin-list').then((res) => {
          if (res.code == 0) {
            this.staffSelect = res.data
          }
        })
      },
      handleradd() {
        this.$refs.add.showadd = true
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handleredit(row) {
        this.$refs.add.showadd = true
        this.$refs.add.handlerinfo(row)
      },
      handlerstatus(row) {
        getAction('/api/stroll/article/status', { id: row.id }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg)
            this.handlerlist()
          }
        })
      },
      // async handlerSelect() {
      //   const { data } = await getAction('/user/index/area', { pid: 0 })
      //   this.areaselect = data
      //   await getAction('/user/index/cate').then((res) => {
      //     this.classselect = res.data
      //   })
      // },
      handlerlist() {
        this.tableloading = true
        getAction('/api/stroll/article/index', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
          this.tableloading = false
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
