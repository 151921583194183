<!-- banner图 -->
<template>
  <div style="padding: 20px 10px">
    <el-form ref="form" :model="form" inline>
      <el-form-item label="">
        <el-input v-model="form.title" placeholder="标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="form.status"
          clearable
          style="width: 160px"
          placeholder="请选择"
        >
          <el-option
            v-for="list in statusselect"
            :key="list.id"
            :value="list.id"
            :label="list.name"
          />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="位置">
        <el-select
          v-model="form.type"
          clearable
          style="width: 160px"
          placeholder="请选择"
        >
          <el-option
            v-for="list in typeselect"
            :key="list.id"
            :value="list.id"
            :label="list.name"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item label="">
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handlerlist()"
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handleredit()"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tabledata" border ref="table" v-loading="loading">
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="title"
        label="名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="pic" label="图片" align="center">
        <template #default="{ row }">
          <el-image
            :preview-src-list="[row.pic]"
            :src="row.pic"
            style="width: 100px; height: 100px"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot"></div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center" width="90">
        <template #default="{ row }">
          {{ row.status == '1' ? '启用' : '禁用' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="created"
        label="创建时间"
        align="center"
      ></el-table-column>
      <el-table-column prop="name" label="操作" align="center">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-popconfirm title="确认删除吗？" @confirm="deleteRow(row)">
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" @getlist="handlerlist" />
  </div>
</template>
<script>
  import edit from './components/banneredit.vue'
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'posterlist',
    components: { edit },
    data() {
      return {
        tabledata: [],
        loading: false,
        form: {
          status: '-1',
          type: '4',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        statusselect: [
          {
            id: '-1',
            name: '全部',
          },
          {
            id: '0',
            name: '禁用',
          },
          {
            id: '1',
            name: '启用',
          },
        ],
        typeselect: [
          {
            id: '1',
            name: '企业版首页顶部',
          },
          {
            id: '2',
            name: '经销商版首页顶部',
          },
          {
            id: '3',
            name: '综合版首页',
          },
          {
            id: '4',
            name: '万商专区',
          },
        ],
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      handlerlist() {
        this.form.page = 1
        this.getlist()
      },
      getlist() {
        getAction('/api/index/banner-list', this.form).then((res) => {
          if (res.code == 0) {
            this.tabledata = res.data
            this.total = res.totalCount
          }
        })
      },
      // 新增or编辑
      handleredit(row) {
        if (row) {
          //编辑
          this.$refs.edit.isShow = true
          this.$refs.edit.handlerinfo(row)
          // this.$refs.edit.form = JSON.parse(JSON.stringify(row))
        } else {
          //新增
          this.$refs.edit.isShow = true
        }
      },
      // 下架
      offshelfRow() {},
      //删除
      deleteRow(row) {
        getAction('/api/index/banner-del', { id: row.id }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg)
            this.handlerlist()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getlist()
      },
    },
  }
</script>
