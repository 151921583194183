<template>
  <div class="index-container">
    <wang-editor ref="content"/>
    <el-button @click="handlersave" type="primary">提交</el-button>
  </div>
</template>

<script>
import WangEditor from "@/components/WangEditor.vue";
import { postAction } from "@/api/request";
import { nextTick } from "vue";

export default {
  name: "index",
  components: { WangEditor },
  data() {
    return {};
  },
  mounted() {
    this.handlerinfo()
  },
  methods: {
    handlersave() {
      postAction('/api/form/crm/save-answer',{val:this.$refs.content.getHtmlM()}).then(res=>{
        if(res.request_code==200){
          this.$message.success('保存成功')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    handlerinfo(){
      postAction('/api/form/crm/answer-conf').then(res=>{
        nextTick(()=>{
          setTimeout(()=>{
            this.$refs.content.setHtml(res.data)
          },100)
        })
      })
    }
  },
};
</script>

<style scoped>

</style>
