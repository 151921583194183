var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.addshow,
        width: "40%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.addshow = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "图片" } },
                [
                  _c("upload-img", {
                    ref: "img",
                    attrs: { "info-text": "建议尺寸750*350", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "跳转类型" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: "跳转类型", clearable: "" },
                      on: { change: _vm.handlerjuptype },
                      model: {
                        value: _vm.form.jump_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "jump_type", $$v)
                        },
                        expression: "form.jump_type",
                      },
                    },
                    _vm._l(_vm.jumplist, function (list, index) {
                      return _c("el-option", {
                        key: list.jump_id,
                        attrs: { label: list.jump_page, value: list.jump_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.jump_type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "产品" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          "fetch-suggestions": _vm.handlergoods,
                          "value-key": "goods_name",
                          placeholder: "请输入商品名称",
                        },
                        on: { select: _vm.handleSelectgoods },
                        model: {
                          value: _vm.form.ref_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ref_name", $$v)
                          },
                          expression: "form.ref_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.form.jump_type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "店铺" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          "fetch-suggestions": _vm.handlermell,
                          "value-key": "mall_name",
                          placeholder: "请输入店铺名称",
                        },
                        on: { select: _vm.handleSelectmall },
                        model: {
                          value: _vm.form.ref_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ref_name", $$v)
                          },
                          expression: "form.ref_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.jump_type == 12 || _vm.form.jump_type == 3
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.jump_name,
                        prop: "advert_jump_params",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: { placeholder: _vm.jump_name },
                        model: {
                          value: _vm.form.jump_params,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "jump_params", $$v)
                          },
                          expression: "form.jump_params",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { type: "number", placeholder: "排序" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }