<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="50%">
      <div>
        <el-form :model="form" label-width="120px" :rules="rules" ref="form">
          <el-form-item label="关联云交会企业" prop="title">
            <el-autocomplete
              v-model="form.mall_name"
              :fetch-suggestions="handlerhuose"
              placeholder="搜索企业名称"
              value-key="mall_name"
              @select="handleSelecthuose"
            />
          </el-form-item>
          <el-form-item label="排序(非必填)" prop="title">
            <el-input
              placeholder="请输入"
              v-model="form.sort"
              style="width: 220px"
              type="number"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { uploadImg },
    data() {
      return {
        isShow: false,
        title: '关联企业',
        form: {
          type: 4,
          title: '',
          pic: '',
          sort: '',
          status: '',
        },
        rules: {
          title: [
            {
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            },
          ],
        },
        groupSelect: [
          {
            id: '1',
            name: '区域',
          },
          {
            id: '2',
            name: '新渠道',
          },
        ],
        drawSelect: [
          {
            id: '1',
            name: '是',
          },
          {
            id: '2',
            name: '否',
          },
        ],
      }
    },
    watch: {
      isShow(n, o) {
        if (n) {
        } else {
          this._onreset()
        }
      },
    },
    mounted() {},
    methods: {
      // 关联云交会企业远程搜索
      handlerhuose(queryString, cb) {
        console.log('测试----', queryString)
        if (queryString == '') {
          cb([])
          this.form.yjh_id = ''
        } else {
          getAction('/mall/index/mall', { mall_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      // 关联云交会企业选择
      handleSelecthuose(list) {
        console.log('--', list)
        this.form.yjh_id = list.id
      },
      handlerinfo(row) {
        this.title = '编辑店铺'
        console.log('编辑，', row)
        this.form = Object.assign(this.form, row)
        setTimeout(() => {
          this.$refs.uploadImage.img = [row.pic]
        }, 100)
      },
      handlersave() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            postAction('/api/index/banner-edit', this.form).then((res) => {
              console.log('')
              if (res.code == 0) {
                this.$message.success(res.msg)
                this.$emit('getlist')
                this.isShow = false
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.pic = ''
        } else {
          this.form.pic = val[0]
        }
      },
      handlermell(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.jump_object = ''
        } else {
          getAction('/mall/index/mall', { mall_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handlergoods(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.jump_object = ''
        } else {
          getAction('/mall/product/goods', { goods_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelectmall(list) {
        console.log(list)
        this.form.jump_object = list.id
      },
      handleSelectgoods(list) {
        console.log(list)
        this.form.jump_object = list.id
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      _onreset() {
        this.$refs.uploadImage.img = []
        this.form = {
          type: 4,
          title: '',
          pic: '',
          sort: '',
          status: '',
        }
      },
    },
  }
</script>
