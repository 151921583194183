<template>
  <el-dialog :title="title" :visible.sync="showedit" width="80%">
    <el-button type="primary" @click="handlerExport">导出</el-button>
    <el-table
      border
      v-loading="tableloading"
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        prop="company_name"
        label="公司名称"
        width=""
      ></el-table-column>
      <el-table-column
        prop="trade_txt"
        label="经营品类"
        width=""
      ></el-table-column>
      <el-table-column prop="area" label="地区" width=""></el-table-column>
      <el-table-column
        prop="channel_txt"
        label="渠道"
        width=""
      ></el-table-column>
      <el-table-column prop="brand" label="代理品牌" width=""></el-table-column>
      <el-table-column
        prop="apply_name"
        label="申请人姓名"
        width=""
      ></el-table-column>
      <el-table-column
        prop="apply_job"
        label="申请人职务"
        width=""
      ></el-table-column>
      <el-table-column
        prop="inviter_name"
        label="邀请人"
        width=""
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="手机号码"
        width=""
      ></el-table-column>
      <el-table-column
        prop="check_time"
        label="审核通过时间"
        width=""
      ></el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :layout="layout"
      :page-size="limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="showedit = false">取 消</el-button>
      <!-- <el-button type="primary" @click="handlersave">确 定</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'AddEdit',

    data() {
      return {
        showedit: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        total: 0,
        page: 1,
        limit: 10,
        staffname: '', //员工姓名
        date: {},
        rows: {},
      }
    },
    methods: {
      handlerExport() {
        let params = {
          start_date: this.date.start_date,
          end_date: this.date.end_date,
          staff_name: this.staffname,
        }
        getAction('/api/whale/tag/identity-status-export', params).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      searchlist() {
        this.handlerlist(this.rows, this.date)
      },
      handlerlist(row, date) {
        console.log('员工名称，', this.staffname)
        this.rows = row
        this.staffname = row.staff_name
        this.date = date
        let params = {
          type: 2, //认证类型 1企业、2经销商、3其他  这里固定经销商
          status: 2, // 状态 0全部 1待审核 2已审核 3已拒绝  这里固定已审核
          inviter_name: row.staff_name,
          start_check_date: date.start_date,
          end_check_date: date.end_date,
          page: this.page,
          limit: this.limit,
          // source: '空',
        }
        getAction('/audit/identity/lists', params).then((res) => {
          if (res.request_code == 200) {
            this.tabledata = res.data
            this.total = res.totalCount
          }
        })
      },
      handleCurrentChange(val) {
        this.page = val
        this.searchlist()
      },
      handleSizeChange(val) {
        this.limit = val
        this.searchlist()
      },
    },
  }
</script>
