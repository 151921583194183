<template>
  <div style="padding: 20px 10px">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-button type="primary" @click="handleAdd">添加成员</el-button>
      </el-form-item>
    </el-form>
    <AddMember ref="addMemberDialog" @submit="handleAddSubmit" />

    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="ID" prop="id" width="80" />
      <el-table-column align="center" label="用户名" prop="user_name" />
      <el-table-column align="center" label="员工名称" prop="name" />
      <el-table-column align="center" label="权限" prop="type_str" />
      <el-table-column align="center" label="状态" prop="status">
        <template slot-scope="{ row }">
          <span>{{ row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="{ row }">
          <el-button
            v-if="row.is_deleted == 0"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button v-else type="text" @click="handleRestore(row)">
            恢复
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { postAction } from '@/api/request'
  import AddMember from './components/AddMember.vue'

  export default {
    name: 'Member',
    components: {
      AddMember,
    },
    data() {
      return {
        form: {},
        tableData: [],
      }
    },
    mounted() {
      // 页面加载时获取数据
      this.getmemberList()
    },
    methods: {
      //获取员工列表
      getmemberList() {
        postAction('/user/logistics/list-user-type').then((res) => {
          if (res.request_code == 200) {
            let data = res.data
            for (let i = 0; i < data.length; i++) {
              data[i].status = data[i].is_deleted == 1 ? '已删除' : '正常'
            }
            this.tableData = data
          }
        })
      },
      handleAdd() {
        this.$refs.addMemberDialog.show()
      },
      handleAddSubmit(form) {
        // 这里添加调用接口保存数据的逻辑
        console.log('提交的表单数据:', form)
        postAction('/user/logistics/save-logistics-user', form).then((res) => {
          if (res.request_code == 200) {
            this.$message.success('添加成功')
            this.getmemberList()
          }
        })
      },
      handleDelete(row) {
        this.$confirm('确认删除该成员吗？', '提示', {
          type: 'warning',
        })
          .then(() => {
            // 处理删除逻辑
            postAction('/user/logistics/delete-logistics-user', {
              id: row.id,
            }).then((res) => {
              if (res.request_code == 200) {
                this.$message.success('删除成功')
                this.getmemberList()
              }
            })
          })
          .catch(() => {})
      },
      handleRestore(row) {
        this.$confirm('确认恢复该成员吗？', '提示', {
          type: 'warning',
        })
          .then(() => {
            postAction('/user/logistics/delete-logistics-user', {
              id: row.id,
            }).then((res) => {
              if (res.request_code == 200) {
                this.$message.success('删除成功')
                this.getmemberList()
              }
            })
          })
          .catch(() => {})
      },
    },
  }
</script>
