<template>
  <div class="orderTest-container">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item>
        <el-input v-model="form.title" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.type_id"
          placeholder="全部"
          label="直播栏目"
          clearable
        >
          <!-- 0禁用，1启用 -->
          <el-option
            v-for="list in typelist"
            :label="list.name"
            :value="String(list.id)"
            :key="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleAdd">关联直播</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      stripe
      :data="list"
      v-loading="loading"
      style="margin-top: 10px"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '直播封面'">
            <el-image
              style="width: 72px; height: 28px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '直播模式'">
            {{ row[item.prop] == 1 ? '横版' : '竖版' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="120px"
      >
        <template #default="{ row }">
          <el-button type="text" @click.native.prevent="tableEdit(row)">
            编辑
          </el-button>
          <el-button type="text" @click.native.prevent="tableDelete(row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.pagesize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      style="text-align: center"
    ></el-pagination>
    <AddEdit ref="addEdit" @refresh="fetchData"></AddEdit>
    <!-- <LinkLive ref="LinkLive" @refresh="fetchData"></LinkLive> -->
  </div>
</template>
<script>
  import AddEdit from './components/addEdit.vue'
  // import LinkLive from './components/LinkLive.vue'
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'index',
    components: { AddEdit },
    data() {
      return {
        loading: false,
        list: [],
        form: {
          page: 1,
          limit: 10,
          conf_id: 1,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
          },
          {
            label: '直播标题',
            prop: 'title',
            width: '',
          },
          {
            label: '直播封面',
            prop: 'cover_img',
            width: '',
          },
          {
            label: '直播分类',
            prop: 'type_name',
            width: '',
          },
          {
            label: '直播模式',
            prop: 'live_model',
            width: '',
          },
          {
            label: '直播状态',
            prop: 'status_str',
            width: '',
          },
          {
            label: '开播时间',
            prop: 'start_time_str',
            width: '',
          },
          {
            label: '创建时间',
            prop: 'created',
            width: '',
          },
          {
            label: '排序',
            prop: 'sort_order',
            width: '',
          },
        ],
        typelist: [],
        livelist: [],
      }
    },
    created() {},
    mounted() {
      this.searchData()
      this.getTypelist()
    },
    watch: {},
    methods: {
      handlerlivelist() {
        getAction('/live/index/config', this.form).then((res) => {
          this.livelist = res.data
        })
      },
      searchData() {
        this.form.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.loading = true
        try {
          let res = await getAction('/live/index/rel', { ...this.form })
          this.list = res.data
          this.total = Number(res.totalCount) || 0
          this.loading = false
        } catch (err) {
          console.log('接口调用错误', err)
          this.loading = false
        }
      },
      //获取直播栏目下拉框内容数据
      async getTypelist() {
        let res = await getAction('/live/index/type', { ...this.form })
        this.typelist = res.data
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.fetchData()
      },
      handleAdd() {
        this.$refs.addEdit.editType = 1
        this.$refs.addEdit.showDialog = true
        this.$refs.addEdit.selectList = this.typelist
      },
      tableEdit(row) {
        this.$refs.addEdit.editType = 2
        this.$refs.addEdit.form = JSON.parse(JSON.stringify(row))
        // this.$refs.addEdit.form.type_id = this.$refs.addEdit.form
        this.$refs.addEdit.showDialog = true
        this.$refs.addEdit.selectList = this.typelist

        this.$nextTick(() => {
          this.$refs.addEdit.setImg(row.banner_img, 'uploadImage')
        })
      },
      tableDelete(row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res = await postAction('/live/index/del-rel', { id: row.id })
            this.$message.success(res.msg)
            this.fetchData()
          })
          .catch(() => {
            console.log('no')
          })
      },
      tableLink(row) {
        this.$refs.LinkLive.type = row.id
        this.$refs.LinkLive.showDialog = true
        this.$refs.LinkLive.params = row
      },
    },
  }
</script>
<style lang="scss" scoped></style>
