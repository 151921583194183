<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.call_status"
          clearable
          placeholder="外呼状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in calstatus"
            :key="list.status"
            :value="list.status"
            :label="list.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_lock"
          clearable
          placeholder="账号状态"
          style="width: 160px"
        >
          <el-option label="全部" value="" />
          <el-option label="正常" value="0" />
          <el-option label="自动锁定" value="1" />
          <el-option label="白名单" value="2" />
          <el-option label="手动锁定" value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" v-has="'export'" @click="handlerExpore">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    data() {
      return {
        form: {
          is_lock: '',
          call_status: '',
          end_time: '',
          start_time: '',
          page: 1,
          limit: 10,
        },
        timelist: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
          },
          {
            label: '用户名',
            prop: 'nickname',
            width: '220px',
          },
          {
            label: '姓名',
            prop: 'realname',
            width: '220px',
          },
          {
            label: '身份',
            prop: 'role',
            width: '220px',
          },
           {
            label: '公司名称',
            prop: 'company_name',
            width: '220px',
          },
           {
            label: '地区',
            prop: 'region',
            width: '220px',
          },

          {
            label: '行业',
            prop: 'teade',
            width: '220px',
          },
           {
            label: '渠道',
            prop: 'channel',
            width: '220px',
          },
          {
            label: '注册电话',
            prop: 'phone',
            width: '220px',
          },
          {
            label: '注册时间',
            prop: 'created',
            width: '',
          },
          {
            label: '最后浏览时间',
            prop: 'last_activity_time',
            width: '',
          },
          {
            label: '外呼状态',
            prop: 'call_status',
            width: '',
          },
          {
            label: '外呼时间',
            prop: 'actualtime',
            width: '',
          },
          {
            label: '账号状态',
            prop: 'is_lock',
            width: '',
          },
        ],
        total: 0,
        spaceSelect: [],
        calstatus: [], //外呼状态列表
      }
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
      this.getoutstatus()
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerExpore(){
        postAction('/api/form/form/out-tj-export', this.form).then(
          (res) => {
            this.$message.success('已添加到导出队列')
          }
        )
      },
      handlerSelect() {
        getAction('/audit/advert/position-config').then((res) => {
          console.log(res)
          this.spaceSelect = res.data
        })
      },
      handlerpass(row, type) {
        let messagedata
        type == 1
          ? (messagedata = '确认审核通过')
          : (messagedata = '确认审核拒绝')
        this.$confirm(messagedata, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            if (type == 1) {
              postAction('/audit/advert/audit', {
                id: row.id,
                status: type,
              }).then((res) => {
                this.handlerInquire()
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
              })
            } else {
              this.$prompt('请输入理由', '理由', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              })
                .then(({ value }) => {
                  postAction('/audit/advert/audit', {
                    id: row.id,
                    status: type,
                    reason: value,
                  }).then((res) => {
                    this.handlerInquire()
                    this.$message({
                      type: 'success',
                      message: res.msg,
                    })
                  })
                })
                .catch(() => {})
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      handlerrefuse(row, type) {
        postAction('/audit/advert/open', { id: row.id, open: type }).then(
          (res) => {
            this.handlerInquire()
            this.$message({
              type: 'success',
              message: res.msg,
            })
          }
        )
      },

      handlerlist() {
        getAction('/api/form/form/out-tj', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      getoutstatus() {
        getAction('/api/form/form/out-status', this.form).then((res) => {
          this.calstatus = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
