var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        top: "5vh",
        visible: _vm.showEditDialog,
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showEditDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "120px",
            model: _vm.form,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题" } },
            [
              _c("el-input", {
                staticStyle: { width: "80%" },
                attrs: { placeholder: "请输入图片标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片", prop: "img" } },
            [
              _c("upload-img", {
                ref: "pic",
                attrs: { "info-text": "", limit: 1, "max-size": 100 },
                on: {
                  getImgs: function ($event) {
                    return _vm.getGoods_imgImg($event, "img")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二维码类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择图片类型" },
                  on: { change: _vm.changeJumpType },
                  model: {
                    value: _vm.form.jump_type,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "jump_type",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.jump_type",
                  },
                },
                _vm._l(_vm.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.jump_type >= 7
            ? _c(
                "el-form-item",
                { attrs: { label: "跳转ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入id值" },
                    model: {
                      value: _vm.form.jump_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "jump_id", $$v)
                      },
                      expression: "form.jump_id",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "二维码横坐标", prop: "poster_x" } },
            [
              _c("el-input", {
                staticStyle: { width: "40%" },
                attrs: {
                  onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                  placeholder: "请输入二维码横坐标",
                },
                model: {
                  value: _vm.form.poster_config.poster_x,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.poster_config, "poster_x", $$v)
                  },
                  expression: "form.poster_config.poster_x",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二维码纵坐标", prop: "poster_y" } },
            [
              _c("el-input", {
                staticStyle: { width: "40%" },
                attrs: {
                  onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                  placeholder: "请输入二维码纵坐标",
                },
                model: {
                  value: _vm.form.poster_config.poster_y,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.poster_config, "poster_y", $$v)
                  },
                  expression: "form.poster_config.poster_y",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二维码宽度", prop: "poster_qrcode_width" } },
            [
              _c("el-input", {
                staticStyle: { width: "40%" },
                attrs: {
                  onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                  placeholder: "请输入二维码宽度",
                },
                model: {
                  value: _vm.form.poster_config.poster_qrcode_width,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.poster_config, "poster_qrcode_width", $$v)
                  },
                  expression: "form.poster_config.poster_qrcode_width",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二维码高度", prop: "poster_qrcode_height" } },
            [
              _c("el-input", {
                staticStyle: { width: "40%" },
                attrs: {
                  onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                  placeholder: "请输入二维码高度",
                },
                model: {
                  value: _vm.form.poster_config.poster_qrcode_height,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.poster_config,
                      "poster_qrcode_height",
                      $$v
                    )
                  },
                  expression: "form.poster_config.poster_qrcode_height",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二维码圆角", prop: "poster_qrcode_angle" } },
            [
              _c("el-input", {
                staticStyle: { width: "40%" },
                attrs: {
                  onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                  placeholder: "请输入二维码圆角",
                },
                model: {
                  value: _vm.form.poster_config.poster_qrcode_angle,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.poster_config, "poster_qrcode_angle", $$v)
                  },
                  expression: "form.poster_config.poster_qrcode_angle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "效果预览" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.preview } },
                [_vm._v("预览")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文案" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("启用")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("禁用")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                attrs: { placeholder: "请输入", type: "number" },
                model: {
                  value: _vm.form.sort_order,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort_order", $$v)
                  },
                  expression: "form.sort_order",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showEditDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerSave } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }