var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "version-set" },
    [
      _c(
        "div",
        { staticClass: "operation-bar" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", label: "分类名称", width: "180" },
          }),
          _c("el-table-column", { attrs: { prop: "note", label: "备注" } }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: scope.row.status == 1 ? "success" : "info",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.status == 1 ? "启用" : "禁用") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增分类",
            visible: _vm.dialogVisible,
            width: "500px",
            "append-to-body": true,
            "close-on-click-modal": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "note" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入备注" },
                    model: {
                      value: _vm.formData.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "note", $$v)
                      },
                      expression: "formData.note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择状态" },
                      model: {
                        value: _vm.formData.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "status", $$v)
                        },
                        expression: "formData.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "启用", value: "1" } }),
                      _c("el-option", { attrs: { label: "禁用", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleClose()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }