<!-- banner图 -->
<template>
  <div style="padding: 20px 10px">
    <el-form ref="form" :model="form" inline>
      <el-form-item label="">
        <el-input v-model="form.name" placeholder="标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="分组">
        <el-select
          v-model="form.type"
          clearable
          filterable
          placeholder="选择分组"
          style="width: 160px"
        >
          <el-option
            v-for="list in groupSelect"
            :key="list.id"
            :value="list.id"
            :label="list.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handlerlist()"
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handleredit()"
        >
          新增会议
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handlerexportall(row)"
        >
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tabledata" border ref="table" v-loading="loading">
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="会议名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="status" label="分组" align="center" width="90">
        <template #default="{ row }">
          {{ row.type == '1' ? '区域' : '新渠道' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="fid"
        label="fid"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="sort_order"
        label="排序"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column prop="name" label="操作" align="center">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑会议
          </el-button>
          <el-popconfirm title="确认删除吗？" @confirm="deleteRow(row)">
            <el-button slot="reference" size="small" type="text">
              删除会议
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerstore(row)"
          >
            编辑店铺
          </el-button>
          <el-button type="text" @click="handlerqrcode(row)">
            小程序码
          </el-button>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerexport(row)"
          >
            导出
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <listedit ref="listedit" @getlist="handlerlist" />
    <storelist ref="storelist" @getlist="handlerlist" />
    <el-dialog :visible.sync="qrcodeshow" width="30%" center>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-image
          style="width: 220px; height: 220px"
          :src="qrcode"
          :preview-src-list="[qrcode]"
        ></el-image>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="qrcodeshow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import listedit from './components/listedit.vue'
  import storelist from './components/storelist.vue'
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'posterlist',
    components: { listedit, storelist },
    data() {
      return {
        tabledata: [],
        loading: false,
        qrcodeshow: false,
        qrcode: '',
        form: {
          name: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        groupSelect: [
          {
            id: '1',
            name: '区域',
          },
          {
            id: '2',
            name: '新渠道',
          },
        ],
        statusselect: [
          {
            id: '-1',
            name: '全部',
          },
          {
            id: '0',
            name: '禁用',
          },
          {
            id: '1',
            name: '启用',
          },
        ],
        typeselect: [
          {
            id: '1',
            name: '企业版首页顶部',
          },
          {
            id: '2',
            name: '经销商版首页顶部',
          },
          {
            id: '3',
            name: '综合版首页',
          },
          {
            id: '4',
            name: '万商专区',
          },
        ],
      }
    },
    mounted() {
      this.handlerlist()
    },

    methods: {
      handlerqrcode(row) {
        this.qrcodeshow = true
        this.qrcode = row.mini_code
      },
      //导出总
      handlerexportall() {
        getAction('/api/afbc/act-meet/meet-user-lists-export', {
          meet_id: 0,
        }).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        })
      },
      // 导出
      handlerexport(row) {
        getAction('/api/afbc/act-meet/meet-user-lists-export', {
          meet_id: row.id,
        }).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        })
      },

      handlerlist() {
        this.form.page = 1
        this.getlist()
      },
      getlist() {
        getAction('/api/afbc/act-meet/meet-list', this.form).then((res) => {
          if (res.code == 0) {
            this.tabledata = res.data
            this.total = res.totalCount
          }
        })
      },
      // 新增or编辑
      handleredit(row) {
        if (row) {
          //编辑
          this.$refs.listedit.isShow = true
          // this.$refs.listedit.handlerinfo(row)
          this.$refs.listedit.form = JSON.parse(JSON.stringify(row))
        } else {
          //新增
          this.$refs.listedit.isShow = true
        }
      },
      // 编辑店铺
      handlerstore(row) {
        if (row) {
          this.$refs.storelist.isShow = true
          this.$refs.storelist.form = JSON.parse(JSON.stringify(row))
          this.$refs.storelist.handlerlist()
        } else {
          this.$refs.storelist.isShow = true
        }
      },
      // 下架
      offshelfRow() {},
      //删除
      deleteRow(row) {
        getAction('/api/afbc/act-meet/meet-delete', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.handlerlist()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getlist()
      },
    },
  }
</script>
