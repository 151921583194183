<template>
  <el-dialog
    :close-on-click-modal="false"
    title="详情"
    top="5vh"
    :visible.sync="showDialog"
    width="700px"
  >
    <el-form ref="form" label-suffix="：" label-width="130px" :model="form">
      <el-form-item label="用户姓名">{{ form.name }}</el-form-item>
      <el-form-item label="手机号码">{{ form.mobile }}</el-form-item>
      <el-form-item label="公司名称">{{ form.company }}</el-form-item>
      <el-form-item label="主营产品">{{ form.main_product }}</el-form-item>
      <el-form-item label="所在地区">
        {{ form.province + form.city + form.area }}
      </el-form-item>
      <el-form-item label="详细地址">{{ form.address }}</el-form-item>

      <el-form-item label="获取行业咨询">
        {{ form.get_industry_information == 1 ? '酒类' : '食品类' }}
      </el-form-item>
      <el-form-item label="订单留言">{{ form.message }}</el-form-item>
      <el-form-item label="会员类型">{{ form.title }}</el-form-item>
      <el-form-item label="会员价格">{{ form.total_price }}</el-form-item>
      <el-form-item label="会员状态">
        <span v-if="form.vip_status == 1">已开通</span>
        <span v-else-if="form.vip_status == 2">已过期</span>
        <span v-else-if="form.vip_status == 3">未激活</span>
        <span v-else-if="form.vip_status == 4">未支付</span>
      </el-form-item>
      <el-form-item label="开通时间">{{ form.open_time }}</el-form-item>
      <el-form-item label="到期时间">{{ form.expire_time }}</el-form-item>
      <el-form-item label="微信交易订单号">{{ form.pay_no }}</el-form-item>
      <el-form-item label="会员类型">
        {{ form.is_experience == 2 ? '体验' : '正常' }}
      </el-form-item>
      <el-form-item v-if="form.is_experience == 2" label="体验版块">
        <span
          style="margin: 0 5px"
          v-for="(item, i) in form.experience_section"
          :key="i"
        >
          <span v-if="item == 1" style="margin-right: 5px">糖烟酒周刊</span>
          <span v-if="item == 2" style="margin-right: 5px">管理实践课</span>
          <span v-if="item == 3" style="margin-right: 5px">线下研修</span>
          <span v-if="item == 4" style="margin-right: 5px">选品趋势报告</span>
        </span>
      </el-form-item>
      <el-form-item label="邀 请 人">{{ form.invite_user }}</el-form-item>
      <el-form-item label="订单备注">{{ form.remark }}</el-form-item>
      <el-form-item label="订单来源">
        {{ form.platform == 5 ? '后台添加' : '前台支付' }}
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showDialog = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: 'BanLog',
    components: {},
    data() {
      return {
        showDialog: false,
        form: {},
      }
    },
    computed: {},
    watch: {
      showDialog() {},
    },
  }
</script>
<style lang="scss" scoped></style>
