<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item>
        <el-button type="primary" @click="$refs.tongji.showliu=true;$refs.tongji.onSearch()">统计</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="$refs.liu.showliu=true;$refs.liu.getList()">留言统计</el-button>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.month" placeholder="选择月榜单" clearable>
          <el-option
            v-for="item in monthlist"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.category_id" placeholder="选择品类" clearable @change="handlerlist">
          <el-option
            v-for="item in catelist"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-input v-model="form.goods_name" placeholder="榜单产品名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.status" placeholder="状态" clearable>
          <el-option label="已发布" value="1"></el-option>
          <el-option label="待发布" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleradd">新增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerload">确认发布</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tabledata"
      border
      style="width: 100%">
      <el-table-column
        v-for="(item,index) in columns"
        :key="index"
        :prop="item.prop"
        align="center"
        :label="item.label"
        :width="item.width">
        <template #default="{row, $index}">
          <template v-if="item.label=='操作'">
            <span v-if="form.category_id!=''">
              <i class="el-icon-top"  v-if="$index != 0" style="padding: 0px 10px; fontSize:20px; color: #00a0e9" @click="handlertop($index)"/>
              <i class="el-icon-bottom"  v-show="$index < total - 1" style="padding: 0px 10px; fontSize:20px; color: #00a0e9" @click="handlerbottom($index)"/>
            </span>
            <el-button type="text" @click="handleredit(row)">编辑</el-button>
            <el-button type="text" v-if="row.is_link_goods=='否'" @click="handlerStatus(row,'bind')">关联云交会产品</el-button>
            <el-button type="text" v-if="row.is_link_goods=='是'" @click="handlerundo(row)">取消关联</el-button>
            <el-button type="text" @click="handlerdelete(row)">删除</el-button>
          </template>
          <template v-else>
            {{row[item.prop]}}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="form.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
    <bindgoods :categoryList="catelist" @getlist="handlerlist" ref="bind"/>
    <addgoods :catelist="catelist" @refresh="handlerlist" ref="add"/>
    <liulist :catelist="catelist" ref="liu"/>
    <tongji :catelist="catelist" ref="tongji" :monthlist="monthlist"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Bindgoods from "@/views/pages/systemSet/hotmap/components/bindgoods.vue";
import Addgoods from "@/views/pages/systemSet/hotmap/components/addgoods.vue";
import Liulist from "@/views/pages/systemSet/hotmap/components/liulist.vue";
import Tongji from "@/views/pages/systemSet/hotmap/components/tongji.vue";

export default {
  name: "index",
  components: { Tongji, Liulist, Addgoods, Bindgoods },
  data(){
    return{
      tabledata:[],
      total:0,
      form:{
        category_id:'',
        /**
         * 产品名称
         */
        goods_name:'',
        /**
         * 哪月榜单，为空则为待发布
         */
        month:'',
        page:1,
        limit:10
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      columns: [
        {
          prop: 'id',
          label: '产品id',
          width: ''
        },
        {
          prop: 'cate_name',
          label: '品类',
          width: ''
        },
        {
          prop: 'brand_name',
          label: '品牌',
          width: ''
        },
        {
          prop: 'sd_title',
          label: '商单产品名称',
          width: ''
        },
        {
          prop: 'same_title',
          label: '匹配云交会产品名称',
          width: ''
        },
        {
          prop: 'title',
          label: '榜单产品名称',
          width: ''
        },
        {
          prop: 'same_value',
          label: '相似值',
          width: ''
        },
        {
          prop: 'is_link_goods',
          label: '是否关联云交会产品',
          width: ''
        },
        {
          prop: 'sale_value',
          label: '总销售额',
          width: ''
        },
        {
          prop: 'sort',
          label: '排名',
          width: ''
        },
        {
          prop: 'info',
          label: '备注',
          width: ''
        },
        {
          prop: 'status_txt',
          label: '状态',
          width: ''
        },
        {
          prop: 'status_txt',
          label: '操作',
          width: '260'
        },
      ],
      catelist:[],
      monthlist:[],
    }
  },
  mounted() {
    this.handlerInquire()
    this.handlerselect()
  },
  methods: {
    handlerload(){
      // if(this.form.category_id==''){
      //   this.$message({
      //     type: 'error',
      //     message: '请选择品类!'
      //   });
      //   return
      // }
      postAction('/api/other/cate-rank/publish',{
        category_id:this.form.category_id,
      }).then(res=>{
        if(res.code==0){
          this.$message({
            type: 'success',
            message: '发布成功!'
          });
          this.handlerlist()
        }else{
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
    },
    handlerundo(row){
      let data = {
        title: row.title,
        goods_id: 0,
        category_id: row.category_id,
        id: row.id,
        sort:row.sort,
        cover_img:row.cover_img,
      }
        postAction('/api/other/cate-rank/link-goods',data).then(res=>{
          if(res.code==0){
            this.$message({
              type: 'success',
              message: '取消关联成功!'
            });
            this.handlerlist()
          }else{
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
    },
    handlerStatus(row, type){
        // this.$refs.bind.handlershow(row)
      this.$refs.bind.form = {
        // title: row.title,
        goods_id: row.goods_id,
        // category_id: row.category_id,
        id: row.id,
        // sort:row.sort,
        // cover_img:row.cover_img,
      }
      this.$refs.bind.showbind = true
      this.$refs.bind.type = type
    },
    handlertop(i){
      let temp = this.tabledata[i]
      temp.sort = Number(temp.sort) - 1
      this.tabledata[i - 1].sort = Number(this.tabledata[i - 1].sort) + 1
      this.$set(
        this.tabledata,
        i,
        this.tabledata[i - 1]
      )
      this.$set(this.tabledata, i - 1, temp)
      let sorts=[]
      for(let i=0;i<this.tabledata.length;i++){
        sorts.push({
          id:this.tabledata[i].id,
          sort:this.tabledata[i].sort
        })
      }

      postAction('/api/other/cate-rank/batch-sort',{sorts:sorts}).then(res=>{
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.handlerlist()
      })
    },
    handlerbottom(i){
      let temp = this.tabledata[i]
      temp.sort = Number(temp.sort) + 1
      this.tabledata[i + 1].sort = Number(this.tabledata[i + 1].sort) - 1
      this.$set(
        this.tabledata,
        i,
        this.tabledata[i + 1]
      )
      this.$set(this.tabledata, i + 1, temp)
      let sorts=[]
      for(let i=0;i<this.tabledata.length;i++){
        sorts.push({
          id:this.tabledata[i].id,
          sort:this.tabledata[i].sort
        })
      }

      postAction('/api/other/cate-rank/batch-sort',{sorts:sorts}).then(res=>{
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.handlerlist()
      })

    },
    handleradd(){
      this.$refs.add.showadd = true
    },
    handleredit(row){
      if(row.is_link_goods=='是'){
          this.$refs.bind.form = {
            title: row.title,
            goods_id: row.goods_id,
            category_id: row.category_id,
            id: row.id,
            sort:row.sort,
            cover_img:row.cover_img,
          }
        this.$refs.bind.type='bind'
          this.$refs.bind.showbind = true
      }else{
        console.log(row,this.$refs.add)
        this.$refs.add.showadd = true
        this.$refs.add.handlerinfo(row)
      }

    },
    handlerdelete(row){
      this.$confirm('此操作将删除该产品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        postAction('/api/other/cate-rank/delete',{id:row.id}).then(res=>{
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.handlerlist()
        })
      }).catch(() => {})
    },
    async handlerselect(){
      await getAction('/api/other/cate-rank/month').then(res=>{
        this.monthlist = res.data
      })
      await getAction('/api/other/cate-rank/cate').then(res=>{
        this.catelist = res.data
      })
    },
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/api/other/cate-rank/lists',this.form).then(res=>{
        this.tabledata = res.data
        this.total = Number(res.totalCount)

      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    }

  }
};
</script>

<style scoped>

</style>
