<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">添加浮标</el-button>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="请输入浮标名称"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">搜 索</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata"
              style="width: 100%"
              ref="ElTable">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
      <template #default="{ row }">
        <span v-if="item.prop === 'status' || item.prop ==='img'">
          <e v-if="item.prop === 'status'">{{row.status == 1 ? '启用' : '禁用'}}</e>
          <el-image  v-if="item.prop === 'img'"
            style="width: 50px;"
            :src="row[item.prop]" 
            :preview-src-list="[row[item.prop]]">
          </el-image>
          <!-- <img style="width: 50px;" v-if="item.prop === 'img'" :src="row[item.prop]" @click="imgShow(row[item.prop])"> -->
        </span>
        <span v-else>{{row[item.prop]}}</span>
      </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="150">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerdelete(row)"
          >
            删除
          </el-button>
          
        </template>
      </el-table-column>
    </el-table>
        <el-pagination
          background
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          style="text-align: center; margin-top: 10px"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
    
    <!-- 添加企业 -->
    <el-dialog
      title="浮标设置"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="浮标名称">
              <el-input v-model="editForm.title" placeholder="请输入浮标名称"></el-input>
            </el-form-item>
            <el-form-item label="上传浮标">
              <upload-img ref="uploadImage" infoText="图标大小500kb" :limit="1" @uploadImg="uploadImg" :maxSize="100"/>
            </el-form-item>
            <el-form-item label="跳转类型">
              <el-select v-model="editForm.jump_type" placeholder="请选择">
                <el-option
                  v-for="item in jumptype"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="链接地址">
              <el-input v-model="editForm.jump_url" placeholder="请输入跳转链接"></el-input>
            </el-form-item>
            <el-form-item label="报名来源" v-if="editForm.jumptype==22">
              <el-input v-model="editForm.jump_sign" placeholder="请输入报名fid" style="width:150px"></el-input>
            </el-form-item>
            <el-form-item label="状  态">
              <el-switch
                v-model="editForm.status"
                active-text="启用"
                inactive-text="禁用"
                active-value="1"
                inactive-value="2">
              </el-switch>
            </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="editSubmit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import uploadImg from "@/components/uploadImg";
export default {
  name: "setFloat",
  components:{
    uploadImg
  },
  data(){
    return{
      form: {
        limit: 10,
        page: 1,
        title:'',
      },
      tabledata:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      columns: [
        {
          label: 'ID',
          prop: 'id',
          width: '110',
          align:'center'
        },
        {
          label: '标题',
          prop: 'title',
          width: '',
          align:'center'
        },
        {
          label: '图标',
          prop: 'img',
          width: '',
          align:'center'
        },
        {
          label: '跳转链接',
          prop: 'jump_url',
          width: '',
          align:'center'
        },
        {
          label: '状态',
          prop: 'status',
          width: '110',
          align:'center'
        },
        {
          label: '添加时间',
          prop: 'created',
          width: '150',
          align:'center'
        },
      ],
      total: 0,
      dialogVisible:false,
      restaurants: [],
      jumptype:[
        {
          label:'通用小程序',
          value:'12'
        },
        {
          label:'华糖云报名H5(带邀请人)',
          value:'22'
        },
        {
          label:'H5链接',
          value:'3'
        }
      ],
      editForm:{
        id:'',
        title:'',
        img:'',
        jump_type:'',
        jump_url:'',
        jump_sign:'',
        status:'1',
      },
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerdelete(row){
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        postAction("/api/afbc/pic-mall/delete",{id:row.id}).then(res=>{
          this.handlerlist()
          this.$message({
            type: 'success',
            message: res.msg
          });
        })
      }).catch(() => {
      });
    },
    handlerAdd(){
      this.dialogVisible=true
    },
    handleredit(row){
      this.dialogVisible=true;
      this.editForm=JSON.parse(JSON.stringify(row));
      delete this.editForm.created;
      this.$nextTick(()=>{
        // this.$refs.uploadImage.img.push(row.img);
        this.$refs.uploadImage.img=[row.img];
      })
      
    },
    handlerlist() {
      getAction('/api/afbc/pic-mall/lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
    uploadImg(val){
      console.log(val)
      this.editForm.img = val.join(',');

    },
    handleClose(){
      let that = this;
      this.dialogVisible = false;
      this.$refs.uploadImage.img=[];
      
      setTimeout(() => {
        that.editForm={
          id:'',
          title:'',
          img:'',
          jump_type:'',
          jump_url:'',
          jump_sign:'',
          status:'1',
        }
      }, 500);
      
    },
    editSubmit(){
      let that = this;
      let d = JSON.parse(JSON.stringify(this.editForm));
      getAction('/api/afbc/pic-mall/edit', d).then((res) => {
        that.handleClose();
        that.handlerlist()
      })
    },
  }
};
</script>

<style scoped>

</style>
