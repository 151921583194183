<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item >
<!--        测试让修改员工姓名-->
        <el-select v-model="form.htyun_name" filterable clearable style="width: 160px;" placeholder="员工姓名">
          <el-option
            v-for="list in htuserSelect"
            :key="list.userid"
            :label="list.htyun_name"
            :value="list.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.is_contact" clearable style="width: 160px;" placeholder="关联企微">
          <el-option value="1" label="关联"/>
          <el-option value="2" label="无关联"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" style="width: 100%" v-loading="loading">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '营业执照'">
            <span v-for="list in row.business_url">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerEdit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" :userSelect="userSelect" @handlerlist="handlerlist"/>
  </div>
</template>

<script>
import { getAction } from "@/api/request";
import Edit from "@/views/pages/userscrm/Multiple/components/edit.vue";

export default {
  name: "index",
  components: { Edit },
  data() {
    return {
      total:0,
      loading: false,
      layout: "total, sizes, prev, pager, next, jumper",
      userSelect: [],
      htuserSelect:[],
      tabledata:[],
      columns: [
        {
          label: "员工姓名",
          prop: "htyun_name",
          width: "",
        },
        {
          label: "员工一级事业部",
          prop: "htyun_kind",
          width: "",
        },
        {
          label: "员工二级部门",
          prop: "htyun_dept",
          width: "",
        },
        {
          label: "关联企微",
          prop: "is_contact",
          width:"",
        },
        {
          label: "企微姓名",
          prop: "staff_name",
          width:"",
        },
        {
          label: "企微部门",
          prop: "depart_name",
          width:"220px",
        },
      ],
      form: {
        wecom_staff_id: "",
        is_contact: "",
        page: 1,
        limit: 10,
      },
    };
  },
  methods: {
    handlerEdit(row){
      this.$refs.edit.form=JSON.parse(JSON.stringify(row))
      this.$refs.edit.editshow = true
    },
   async handlerSelect(){
     await getAction('/user/wechat-relation/wecom-staff-list').then(res=>{
        this.userSelect = res.data
      })
      await getAction('/form/form/staff').then(res=>{
        this.htuserSelect = res.data
      })
    },
    handlerInquire() {
      this.loading = true
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/user/wechat-relation/list', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
          this.loading = false
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
  mounted() {
    this.handlerSelect()
    this.handlerInquire()
  },
};
</script>

<style scoped>

</style>
