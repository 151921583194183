import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=12372984&scoped=true&"
import script from "./Selection.vue?vue&type=script&lang=js&"
export * from "./Selection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12372984",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\shangjiaGit\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12372984')) {
      api.createRecord('12372984', component.options)
    } else {
      api.reload('12372984', component.options)
    }
    module.hot.accept("./Selection.vue?vue&type=template&id=12372984&scoped=true&", function () {
      api.rerender('12372984', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/review/clouddemand/components/Selection.vue"
export default component.exports