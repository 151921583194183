<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.version"
          clearable
          style="width: 160px"
          placeholder="套餐版本"
          @change="handlerversion"
        >
          <el-option
            v-for="list in versionSelect"
            :key="list.id"
            :value="list.id"
            :label="list.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.meal_id"
          clearable
          style="width: 160px"
          placeholder="套餐名称"
        >
          <el-option
            v-for="list in mealSelect"
            :key="list.id"
            :value="list.id"
            :label="list.title"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="产品名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.service_name"
          clearable
          placeholder="企业服务人员"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.check_type"
          clearable
          placeholder="检测类型"
          style="width: 160px"
        >
                    <el-option label="图片检测" value="1" />
                    <el-option label="文本检测" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option label="未处理" value="0" />
          <el-option label="已处理" value="1" />
        </el-select>
      </el-form-item>

      <el-form-item label="">
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '错误标注'">
            <div v-if="row.check_type==1">
              <span v-for="(list, index) in row[item.prop].split(',')" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
            </div>

            <div v-else>{{ row[item.prop] }}</div>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="160">
        <template #default="{ row }">
            <el-button size="small"  type="text" @click="handlerrefuse(row)">
              删除
            </el-button>
          <el-button v-show="row.status == 0" size="small" type="text" @click="handlerpass(row)">
            已处理
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!--    <space-add ref="add" @getlist="handlerInquire"/>-->
    <!--    <spaceedit ref="edit"/>-->
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/request'

export default {
  watch: {
    timelist(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    },
  },
  data() {
    return {
      form: {
        /**
         * 错误类型1图片检测2文本检测
         */
        check_type:'',
        /**
         * 结束时间
         */
        end_time:'',
        /**
         * 产品名称
         */
        goods_name:'',
        /**
         * 店铺名称
         */
        mall_name:'',
        /**
         * 套餐id
         */
        meal_id:'',
        /**
         * 企业服务人员
         */
        service_name:'',
        /**
         * 开始时间
         */
        start_time:'',
        /**
         * 状态0未处理1已处理
         */
        status:'',
        /**
         * 版本
         */
        version:'',
        page: 1,
        limit: 10,
      },
      timelist:[],
      versionSelect: [],
      mealSelect: [],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: 'id',
          prop: 'id',
          width: '',
        },
        {
          label: '公司名称',
          prop: 'company_name',
          width: '',
        },
        {
          label: '店铺名称',
          prop: 'mall_name',
          width: '',
        },
        {
          label: '当前套餐',
          prop: 'meal_name',
          width: '',
        },
        {
          label: '企业服务人员',
          prop: 'service_name',
          width: '',
        },
        {
          label: '产品名称',
          prop: 'goods_name',
          width: '',
        },
        {
          label: '检测类型',
          prop: 'status_txt',
          width: '',
        },
        {
          label: '错误位置',
          prop: 'column_text',
          width: '',
        },
        {
          label: '错误标注',
          prop: 'error_content',
          width: '220px',
        },
        {
          label: "错误类型",
          prop: "reason",
        },

        {
          label: '状态',
          prop: 'status_text',
          width: '',
        },
        {
          label: '检测时间',
          prop: 'created',
          width: '',
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
    this.handlerSelect()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerExport(){
      getAction('/audit/shop/check-list-export',this.form).then(res=>{
          this.$message({
            type: 'warning',
            message: res.data.msg,
          })
      })
    },
    /*
       * @description: 套餐版本选择获取套餐
       * */
    handlerversion(val) {
      getAction('/mall/index/meal-list', { version: val }).then((res) => {
        this.form.set_meal_id = ''
        this.mealSelect = res.data
      })
    },
    handlerSelect() {
      getAction('/mall/index/select-version').then((res) => {
        this.versionSelect = res.data
      })
    },
    handlerpass(row) {
      getAction('/audit/shop/check-update-status', { id: row.id }).then(
        (res) => {
          this.handlerInquire()
          this.$message({
            type: 'success',
            message: res.msg,
          })
        }
      )
    },
    handlerrefuse(row) {
      getAction('/audit/shop/check-del', { id: row.id }).then(
        (res) => {
          this.handlerInquire()
          this.$message({
            type: 'success',
            message: res.msg,
          })
        }
      )
    },

    handlerlist() {
      getAction('/audit/shop/check-list', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
