<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input
          v-model.trim="form.keywords"
          placeholder="标题搜索"
          style="width: 250px"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model.trim="form.label_id"
          placeholder="请选择类型"
          clearable
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.status" clearable placeholder="状态">
          <el-option label="禁用" value="0" />
          <el-option label="启用" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getsearch">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerEdit()">新增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click.native.prevent="handlerline(row)">
          路线管理
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      v-loading="loading"
      border
      :data="tabledata"
      row-key="user_id"
    >
      <el-table-column align="center" label="ID" prop="id" width="100" />
      <el-table-column
        align="center"
        label="封面图"
        prop="cover_img"
        width="150"
      >
        <template #default="{ row }">
          <el-image
            v-if="row.type == 5 || row.type == 6"
            :src="row.img"
            style="width: 120px"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
          <el-image
            v-else
            :src="row.cover_img"
            style="width: 100px; height: auto"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="标题" prop="title" />
      <el-table-column
        align="center"
        label="类型"
        prop="label_title"
        width="150"
      ></el-table-column>
      <el-table-column
        align="center"
        label="活动开始时间"
        prop="start_time"
        width="150"
      />
      <el-table-column
        align="center"
        label="活动结束时间"
        prop="end_time"
        width="150"
      />
      <el-table-column
        align="center"
        label="报名开始时间"
        prop="apply_start_time"
        width="150"
      />
      <el-table-column
        align="center"
        label="报名结束时间"
        prop="apply_end_time"
        width="150"
      />
      <el-table-column
        align="center"
        label="浏览次数"
        prop="view_num"
        width="150"
      />
      <el-table-column
        align="center"
        label="访问人数"
        prop="visitor_num"
        width="150"
      />
      <el-table-column
        align="center"
        label="排序"
        prop="sort_order"
        width="80"
      />
      <el-table-column align="center" label="状态" prop="status" width="150">
        <template #default="{ row }">
          {{ row.status == 0 ? '禁用' : '开启' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        prop="id"
        width="100"
        fixed="right"
      >
        <template #default="{ row }">
          <el-button
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerEdit(row)"
          >
            编辑
          </el-button>

          <!-- <el-button
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerDel(row)"
          >
            删除
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @getlist="getsearch()" />
    <linecom ref="line" @getlist="getsearch()" />
  </div>
</template>
<script>
  import { delBanner, getAction } from '@/api/request'
  import edit from './components/edit'
  import linecom from './components/line'
  export default {
    name: 'BanLog',
    components: { edit, linecom },
    data() {
      return {
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        loading: false,
        tabledata: [],
        form: {
          page: 1,
          limit: 10,
          keywords: '',
          status: '', //活动状态 0.禁用 1.启用 （全部传空）
        },
        typeOptions: [
          {
            value: '1',
            label: '线下研修',
          },
          {
            value: '2',
            label: '区域对接会',
          },
          {
            value: '3',
            label: '区域活动',
          },
        ],
      }
    },
    mounted() {
      this.getsearch()
      this.gettype()
    },
    methods: {
      gettype() {
        getAction('/api/study/study-trip/event-label').then((res) => {
          if (res.code == 0) {
            this.typeOptions = res.data
          }
        })
      },
      // 线路管理
      handlerline(row) {
        this.$refs.line.showEditDialog = true
        this.$refs.line.getlist(row)
      },
      handlerEdit(row) {
        this.$refs.edit.showEditDialog = true
        if (row) {
          // this.$refs.edit.form = JSON.parse(JSON.stringify(row))
          this.$refs.edit.handlerinfo(row)
        }
      },
      handlerDel(row) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            delBanner('/user/business-club/del-banner', { id: row.id }).then(
              (res) => {
                if (res.request_code == 200) {
                  this.$message.success(res.msg)
                  this.getData()
                } else {
                  this.$message.error('删除失败')
                }
              }
            )
          })
          .catch(() => {})
      },
      getsearch() {
        this.form.page = 1
        this.getData()
      },
      getData() {
        getAction('/api/study/study-trip/event-list', this.form).then((res) => {
          console.log('获取数据', res)
          this.tabledata = res.data
          this.total = res.totalCount
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getData()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getData()
      },
    },
  }
</script>
