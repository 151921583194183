<!-- 留言列表 -->
<template>
  <div style="padding: 20px 10px">
    <el-form ref="form" :model="form" inline>
      <el-form-item label="">
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="">
        <el-input
          v-model="form.keywords"
          placeholder="请输入产品标题/手机号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button size="small" type="primary" @click.native.prevent="search()">
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handlerExport()"
        >
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tabledata" border ref="table" v-loading="loading">
      <el-table-column
        prop="id"
        label="id"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="mall_name"
        label="企业名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_name"
        label="新品标题"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_id"
        label="用户ID"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="realname"
        label="姓名(用户名)"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="nickname"
        label="昵称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="company_name"
        label="公司名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="注册电话"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="created"
        label="留言时间"
        align="center"
      ></el-table-column>
      <el-table-column prop="name" label="操作" align="center">
        <template #default="{ row }">
          <el-popconfirm title="确认删除吗？" @confirm="deleteRow(row)">
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" />
  </div>
</template>
<script>
  import edit from './components/posteredit.vue'
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'posterlist',
    components: { edit },
    data() {
      return {
        tabledata: [],
        loading: false,
        form: {
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        timelist: [],
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.search()
    },
    methods: {
      search() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerExport() {
        postAction(
          '/api/afbc/act-company/message-lists-export',
          this.form
        ).then((res) => {
          if (res.request_code == 200) {
            this.$message.success(res.msg)
          }
        })
      },
      handlerlist() {
        getAction('/api/afbc/act-company/message-lists', this.form).then(
          (res) => {
            if (res.code == 0) {
              this.tabledata = res.data
              this.total = res.totalCount
            }
          }
        )
      },

      //删除
      deleteRow(row) {
        getAction('/api/afbc/act-company/message-del', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.search()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>
