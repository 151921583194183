<template>
  <el-dialog title="批量导入" :visible.sync="showBatch" width="30%">
    <el-form ref="form" label-width="100px">
      <el-form-item label="选择文件:">
        <!-- <input
          v-show="false"
          type="file"
          ref="file"
          @change="handleraddfile"
          accept=".csv,.xls,.xlsx"
        />
        <el-button type="primary" @click="handlersave">选择文件</el-button>
        <span>{{ filename }}</span> -->
        <el-upload
          ref="upload"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          action=""
          :auto-upload="false"
          :on-change="handleChange"
        >
          <el-button size="small" type="primary">
            <i
              class="el-icon-upload el-icon--right"
              style="font-size: 14px"
            ></i>
            点击上传
          </el-button>
          <span style="padding-left: 10px; color: #e6a23c">
            <i class="el-notification__icon el-icon-warning"></i>
            只能上传xlsx/xls文件
          </span>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerdownload">下载模板</el-button>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handlerimport">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { postAction } from '@/api/request'
  import axios from 'axios'

  export default {
    // name: "batch",
    data() {
      return {
        showBatch: false,
        filename: '',
        csvUrl: '',
        fileTemp: null,
        excel_arr: [],
        da: '',
      }
    },
    methods: {
      handleChange(file) {
        this.loadingbut = false
        this.queding_status = '确定'
        this.fileTemp = file.raw
        if (this.fileTemp) {
          if (
            this.fileTemp.type ==
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            this.fileTemp.type == 'application/vnd.ms-excel'
          ) {
            this.excel_arr = this.importfxx(this.fileTemp)
          } else {
            this.$message({
              type: 'warning',
              message: '附件格式错误，请删除后重新上传！',
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '请上传附件！',
          })
        }
      },
      importfxx(obj) {
        var f = obj
        var reader = new FileReader()
        let arr = []
        FileReader.prototype.readAsBinaryString = function (f) {
          var binary = ''
          var wb
          var outdata
          var reader = new FileReader()
          reader.onload = function () {
            var bytes = new Uint8Array(reader.result)
            var length = bytes.byteLength
            for (var i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i])
            }
            var XLSX = require('xlsx')
            wb = XLSX.read(binary, {
              type: 'binary',
            })
            outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
            this.da = [...outdata]
            console.log('导入表格数据，', this.da)
            this.da.map((v) => {
              arr.push({
                name: v['姓名'],
                mobile: v['手机号码'],
                company: v['公司名称'],
                main_product: v['主营产品'],
                pay_no: v['微信支付单号'],
                province: v['省份'],
                city: v['城市'],
                area: v['区域'],
                address: v['详细地址'],
                get_industry_information: v['资讯类型'],
                club_vip: v['套餐信息'],
                invite_user: v['邀请员工（手机号码）'],
              })
            })
          }
          reader.readAsArrayBuffer(f)
        }
        reader.readAsBinaryString(f)
        return arr
      },
      async getOssData() {
        return postAction('/api/common/common/oss-sign').then((res) => {
          console.log(res)
          return res.data
        })
      },
      close() {
        this.showBatch = false
        this.filename = ''
        this.csvUrl = ''
      },
      generateFileName(ossData, file) {
        console.log('走到这了吗', file)
        // const suffix = file.name.slice(file.name.lastIndexOf('.'));
        console.log(ossData.dir)
        return ossData.dir + '/' + file.name
      },
      handlersave() {
        this.$refs.file.click()
      },
      handlerimport() {
        postAction('/user/business-club/vip-user-import', {
          // in_file: this.csvUrl,
          // yjhasync: 1,
          data: JSON.stringify(this.excel_arr),
        }).then((res) => {
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '已开始上传，请耐心等待',
            })
            this.showBatch = false
            this.filename = ''
            this.csvUrl = ''
          }
        })
      },
      async handleraddfile(e) {
        console.log('文件上传', e.target.files[0])
        this.filename = e.target.files[0].name
        const file = e.target.files[0]
        const ossData = await this.getOssData()
        const key = this.generateFileName(ossData, file)
        console.log('走到这了吗2')
        const formdata = new FormData()

        // 注意参数的顺序，key 必须是第一位，表示OSS存储文件的路径
        formdata.append('key', key)
        formdata.append('OSSAccessKeyId', ossData.accessid)
        formdata.append('policy', ossData.policy)
        formdata.append('signature', ossData.signature)
        // 文件上传成功默认返回 204 状态码，可根据需要修改为 200
        formdata.append('success_action_status', '200')
        // file 必须放在最后一位
        formdata.append('file', file)
        console.log('走到这了吗1')
        await axios
          .post(ossData.host, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            if (res.status === 200) {
              e.target.value = ''
              this.csvUrl = 'https://res.httoutiao.com/' + key
            }
          })
      },
      handlerdownload() {
        const url =
          'https://res.httoutiao.com/1/%E5%8D%8E%E7%B3%96%E4%BC%9A%E5%91%98%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx'
        const DomA = document.createElement('a')
        DomA.href = url
        DomA.download = '华糖会员订单导入模板.xlsx'
        DomA.click()
        DomA.remove()
        this.$message({
          type: 'success',
          message: '下载成功',
        })
      },
    },
  }
</script>

<style scoped></style>
