var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.addbanner, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.addbanner = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  model: {
                    value: _vm.catType,
                    callback: function ($$v) {
                      _vm.catType = $$v
                    },
                    expression: "catType",
                  },
                },
                _vm._l(_vm.catTypeList, function (item) {
                  return _c(
                    "span",
                    { key: item.id },
                    [
                      _c("el-radio-button", { attrs: { label: item.id } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "品类" },
                      model: {
                        value: _vm.form.cat_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cat_id", $$v)
                        },
                        expression: "form.cat_id",
                      },
                    },
                    _vm._l(_vm.catSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { label: i.title, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("上传产品")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.catType == "1"
            ? _c(
                "div",
                [
                  _c("p", { staticStyle: { color: "#f00" } }, [
                    _vm._v(
                      " 系统规则:优先展示付费企业，然后是免费企业，数据实时更新。手动设置：可手动设置店铺展示的顺序。手动设置有时效性，且优先级大于系统规则 "
                    ),
                  ]),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "table",
                      attrs: {
                        border: "",
                        data: _vm.tabledata,
                        "row-key": "user_id",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "ID",
                          prop: "id",
                          width: "90",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "店铺LOGO",
                          prop: "name",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "店铺名称",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "排序",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "生效时间",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: { title: "确认删除吗？" },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.onDelete(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                            size: "small",
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v(" 移除 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2266078847
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.catType == "2"
            ? _c(
                "div",
                [
                  _c("p", { staticStyle: { color: "#f00" } }, [
                    _vm._v(
                      " 系统规则：拉取首页每日上新二级页面品类页面产品。手动设置：可手动设置新品展示的顺 序，手动设置有时效性，且优先级大于系统规则。（新品更多规则：同一店铺不限制新品数量且除去下面表格产品）支持手动上传第三方产品 "
                    ),
                  ]),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "table",
                      attrs: {
                        border: "",
                        data: _vm.tabledata,
                        "row-key": "user_id",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "ID",
                          prop: "id",
                          width: "90",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "产品LOGO",
                          prop: "name",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "产品名称",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "店铺",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "发布时间",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "排序",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "是否手动设置",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "是否平台产品",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "生效时间",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: { title: "确认删除吗？" },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.onDelete(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                            size: "small",
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v(" 移除 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2266078847
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.catType == "3"
            ? _c(
                "div",
                [
                  _c("p", { staticStyle: { color: "#f00" } }, [
                    _vm._v(
                      " 系统规则：根据最近7天的产品数据，每日生成一个top10榜单；搜索指数= 0.3*搜索产品曝光（下拉提示+搜索结果页）+0.2*搜索点击进入产品页+0.1*产品浏览次数 +0.3*产品互动数（留言+电话）+0.1*样品申请数。手动设置：可手动设置最热产品展示的顺 序，手动设置有时效性，且优先级大于系统规则。支持手动上传第三方产品 "
                    ),
                  ]),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "table",
                      attrs: {
                        border: "",
                        data: _vm.tabledata,
                        "row-key": "user_id",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "ID",
                          prop: "id",
                          width: "90",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "产品LOGO",
                          prop: "name",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "产品名称",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "店铺",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "浏览数",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "热搜指数",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "排序",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "是否手动设置",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "是否平台产品",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "生效时间",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: { title: "确认删除吗？" },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.onDelete(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                            size: "small",
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v(" 移除 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2266078847
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.catType == "4"
            ? _c(
                "div",
                [
                  _c("p", { staticStyle: { color: "#f00" } }, [
                    _vm._v(
                      " 系统规则：优先展示付费企业产品，然后是免费企业产品，每个企业随机选择一个产品，数据实时更新。手动设置：可手动设置产品展示的顺 序，手动设置有时效性，且优先级大于系统规则。支持手动上传第三方产品 "
                    ),
                  ]),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "table",
                      attrs: {
                        border: "",
                        data: _vm.tabledata,
                        "row-key": "user_id",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "ID",
                          prop: "id",
                          width: "90",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "产品LOGO",
                          prop: "name",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "产品名称",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "店铺",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "排序",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "是否平台产品",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "生效时间",
                          prop: "mobile",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: { title: "确认删除吗？" },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.onDelete(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                            size: "small",
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v(" 移除 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2266078847
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addbanner = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }