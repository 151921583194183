<template>
  <div class="index-container">
    <div class="head" style="margin-bottom: 15px;">
      <el-button type="primary" @click="handleredit()">新增</el-button>
      <el-input placeholder="套餐版本" v-model="version" style="width: 150px;margin-left:100px;margin-right:20px;"></el-input>
      <el-button type="primary" @click="search">查询</el-button>
    </div>
    <el-row>
      <el-col :span="16">
        <el-table
          :data="tablelist"
          border
          style="width: 100%">
          <el-table-column
            prop="title"
            label="套餐名称"
            width="">
          </el-table-column>
          <el-table-column
            prop="version_name"
            label="套餐版本"
            width="">
          </el-table-column>
          <el-table-column
            prop="price"
            label="套餐价格"
            width="">
          </el-table-column>
          <el-table-column
            prop="time"
            label="套餐默认时长（天）"
            width="">
          </el-table-column>
          <el-table-column
            label="状态"
            width="">
            <template #default="{row}">
              <span v-if="row.status == 0">禁用</span>
              <span v-if="row.status == 1">启用</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="操作"
            width="">
            <template slot-scope="scope">
              <el-button type="text" @click="handleredit(scope.row)">编辑</el-button>
              <el-button type="text" @click="handlereinfo(scope.row)">设置套餐内容</el-button>
              <el-button type="text" @click="handlerswitch(scope.row)" v-if="scope.row.status == 0">启用</el-button>
              <el-button type="text" @click="handlerswitch(scope.row)" v-if="scope.row.status == 1">禁用</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 新增套餐 -->
    <el-dialog :title="editpackage==1?'编辑':'新增'" :visible.sync="showedit" width="50%" :before-close="handleclose">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="所属分类：" prop="version">
          <el-select v-model="form.version" placeholder="请选择" style="width: 150px;">
            <el-option :label="item.name" :value="item.id" v-for="item in versionlist" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐名称：" prop="title">
          <el-input v-model="form.title" style="width: 220px;"></el-input>
        </el-form-item>
        <el-form-item label="套餐价格：" prop="price">
          <el-input v-model="form.price" placeholder="单位元，请输入正整数或0" style="width: 220px;"></el-input>
        </el-form-item>
        <el-form-item label="套餐默认时长：" prop="time">
          <el-input v-model="form.time" placeholder="单位天，请输入正整数" style="width: 220px;"></el-input>
          <e class="tips">如有未执行完的服务，则套餐自动延期</e>
        </el-form-item>
        <el-form-item label="套餐结束：" prop="end_condition">
          <el-checkbox-group v-model="end_condition">
            <el-checkbox :label="item.id" :disabled="item.name=='套餐时长'" v-for="item in meallist" :key="item.id">{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleclose">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 使用 packageInfo 组件 -->
    <package-info ref="packageInfo"></package-info>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import WangEditor from "@/components/WangEditor";
import PackageInfo from "@/views/pages/systemSet/packageConfig/components/packageInfo.vue"; // 引入 packageInfo 组件

export default {
  name: "packageManagement",
  components: { WangEditor, PackageInfo }, // 注册 packageInfo 组件
  data(){
    return{
      version:'',
      tablelist:[],
      versionlist:[],
      meallist:[],
      showedit:false,
      editpackage:0,
      form:{
        id:'',
        title:'',
        version:'',
        price:'',
        time:'',
        status:0,
        end_condition:'',
      },
      end_condition:[],
      rules:{
        title:[
          { required: true, message: '请输入套餐名称', trigger: 'blur' },
        ],
        version:[
          { required: true, message: '请选择所属分类', trigger: 'change' },
        ],
        price:[
          { required: true, message: '请输入套餐价格', trigger: 'blur' },
        ],
        time:[
          { required: true, message: '请输入套餐默认时长', trigger: 'blur' },
        ],
        end_condition:[
          { required: true, message: '请选择套餐结束条件', trigger: 'change' },
        ],
      },
    }
  },
  mounted() {
    this.handlerlist()
  },
  methods:{
    handleredit(row){
      if(row){
        this.editpackage=1;
        this.form=JSON.parse(JSON.stringify(row))
      }else{
        this.editpackage=0;
      }
      this.getversion();
      this.getmeal();
      this.showedit=true
      // setTimeout(()=>{
      //   this.$refs.wangcon.editor.setHtml(row.content)
      //   this.$refs.wangxie.editor.setHtml(row.agreement)
      // },100)
    },
    search(){
      this.handlerlist()
    },
    handlerlist(){
      getAction("/mall/index/meal-list",{version_name:this.version}).then(res=>{
        this.tablelist = [];
        let d = res.data;
        d.forEach(item=>{
          if(Number(item.version)>2){
            this.tablelist.push(item)
          }
        })
        
      })
    },
    getversion(){
      getAction("/mall/index/select-version").then(res=>{
        this.versionlist = res.data;
      })
    },
    getmeal(){
      let that = this;
      getAction("/mall/index/meal-condition").then(res=>{
        that.meallist=res.data;
        if(that.editpackage==0){
          // 新增
          that.meallist.forEach(item=>{
            that.end_condition.push(item.id)
          })
          that.form.end_condition=that.end_condition.join(",")
        }else{
          console.log('ssssssssss',that.form.end_condition.split(","),that.end_condition)
          let d = that.form.end_condition.split(",")
          if(d.length>0){
            that.end_condition=[];
            d.forEach(item=>{
              that.end_condition.push(Number(item))
            })
          }
          
        }
        
      })
    },
    // 启用禁用
    handlerswitch(row){
      let that = this;
      let data = JSON.parse(JSON.stringify(row));
      data.status = row.status==1?0:1;
      postAction("/mall/index/edit-meal",data).then(res=>{
        if(res.code==0){
          that.$message.success(res.msg)
          that.handlerlist();
        }else{
          that.$message.error(res.msg)
        }
      })

    },
    handleclose(){
      this.$refs['form'].resetFields();
      this.showedit = false;
      this.form={
                  id:'',
                  title:'',
                  version:'',
                  price:'',
                  time:'',
                  status:0,
                  end_condition:'',
                }
    },
    handlersave(){
      let that = this;
      this.$refs['form'].validate((valid) => {
          if (valid) {
            if(Math.floor(that.form.price) != that.form.price){
              that.$message.error("价格必须为正整数或0")
              return false;
            }
            if(Math.floor(that.form.time) != that.form.time){
              that.$message.error("默认时长必须为正整数")
              return false;
            }
            that.form.end_condition=this.end_condition.join(",")
            let d = JSON.parse(JSON.stringify(that.form))
            getAction("/mall/index/edit-meal",d).then(res=>{
              if(res.code==0){
                that.$message.success("保存成功")
                that.handlerlist();
                that.showedit = false;
                setTimeout(() => {
                  that.form={
                            id:'',
                            title:'',
                            version:'',
                            price:'',
                            time:'',
                            status:0,
                            end_condition:'',
                          }
                }, 300);
                
              }else{
                that.$message.error(res.msg)
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });

    },
    handlereinfo(row){
      this.$refs.packageInfo.dialogVisible = true;
      this.$refs.packageInfo.getinfo(row.id)
    },
  }
};
</script>

<style scoped>
  .tips{
    display: block;
    color:#ccc;
  }
  .vab-theme-blue-black .el-checkbox{
    margin-right: 15px;
  }
</style>
