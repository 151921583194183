<template>
  <div class="index-container">
    <h3>抓取文章</h3>
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['12:00:00']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="文章标题"
          clearable
          v-model="form.keywords"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      v-loading="listLoading"
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '封面图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '文章标题'">
            <a target="_blank" :href="row.like_url" v-text="row.title"></a>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column fixed="right" label="操作" align="center" width="120">
        <template #default="{ row }">
          <el-popconfirm title="确认删除？" @confirm="onDelete(row)">
            <el-button slot="reference" type="text" size="small">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'index',

    data() {
      return {
        listLoading: false,
        form: {
          keywords: '',
          start_time: '',
          end_time: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'id',
            prop: 'id',
            width: '',
          },

          {
            label: '文章标题',
            prop: 'title',
            width: '350',
          },

          {
            label: '抓取来源',
            prop: 'source',
            width: '',
          },
          {
            label: '抓取时间',
            prop: 'addtime',
            width: '',
          },
        ],
        total: 0,
        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.onSearch()
    },
    methods: {
      onSearch() {
        this.form.page = 1
        this.listLoading = true
        this.getList()
      },
      getList() {
        getAction('/api/daily/new-daily/article-caiji-list', this.form).then(
          (res) => {
            if (res.request_code == 200) {
              this.tabledata = res.data.list
              this.total = parseInt(res.data.total)
            }
            this.listLoading = false
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getList()
      },
    },
  }
</script>

<style scoped></style>
