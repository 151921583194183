<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";

export default {
  // name: "liulist",
  props: {
    catelist: {
      type: Array,
      default: () => [],
    },
    monthlist:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      showliu: false,
      totallist:{},
      form: {
        goods_name: '',
        push_start_date: '',
        month:'',
        is_del:'',
        push_end_date: '',
        is_link_goods:'',
        page: 1,
        limit: 10,
        category_id:'',
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '产品id',
          prop: 'goods_id',
          width: '',
        },
        {
          label: '品类',
          prop: 'cate_name',
          width: '160',
        },

        {
          label: '品牌',
          prop: 'brand_name',
          width: '',
        },
        {
          label: '榜单产品名称',
          prop: 'title',
          width: '',
        },
        {
          label: '是否关联云交会产品',
          prop: 'is_link_goods',
          width: '',
        },
        {
          label: '排名',
          prop: 'sort',
          width: '',
        },
        {
          label: '发布时间',
          prop: 'publish_time',
          width: '',
        },
        {
          label: '状态',
          prop: 'is_del_txt',
          width: '',
        },
        {
          label: '点赞',
          prop: 'like_num',
          width: '',
        },
        {
          label: '收藏',
          prop: 'collect_num',
          width: '',
        },
        {
          label: '访客数',
          prop: 'uv',
          width: '',
        },
        {
          label: '浏览数',
          prop: 'pv',
          width: '',
        },
        {
          label: '意向数',
          prop: 'intent_num',
          width: '',
        },
      ],
      total: 0,
      time: [],
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.push_start_date = v[0]
        this.form.push_end_date = v[1]
      } else {
        this.form.push_start_date = ''
        this.form.push_end_date = ''
      }
    },
    showliu(val) {
      if (!val) {
        this.time = []
        this.form = this.$options.data().form
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    handlerExport() {
      let data = JSON.parse(JSON.stringify(this.form))
      postAction('/api/other/cate-rank/census-export', data).then((res) => {
        excel.export_json_to_excel({
          header: res.data.header,
          data: res.data.data,
          filename: res.data.name,
        })
      })
    },
    onSearch() {
      this.form.page = 1
      this.getList()
      this.handlertotal()
    },
    handlertotal(){
      getAction('/api/other/cate-rank/census-total', this.form).then(
        (res) => {
          this.totallist = res.data
        }
      )
    },
    getList() {
      getAction('/api/other/cate-rank/census', this.form).then(
        (res) => {
          if (res.request_code == 200) {
            this.tabledata = res.data
            this.total = parseInt(res.totalCount)
          }
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.getList()
    },
  },
};
</script>

<template>
  <el-dialog
    :visible.sync="showliu"
    width="60%">
    <span>
      <el-form ref="form" :model="form" inline>
        <el-form-item>
          <el-select v-model="form.category_id" placeholder="请选择品类" clearable>
            <el-option
              v-for="item in catelist"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
         <el-form-item>
        <el-select v-model="form.month" placeholder="选择月榜单" clearable>
          <el-option
            v-for="item in monthlist"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
        <el-form-item>
        <el-select v-model="form.is_del" placeholder="选择状态" clearable>
          <el-option
            label="在榜"
            value="0"
          ></el-option>
          <el-option
            label="删除"
            value="1"
          ></el-option>
        </el-select>
      </el-form-item>
         <el-form-item>
        <el-input
          placeholder="请输入产品名称"
          clearable
          v-model="form.goods_name"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
         <el-date-picker
           v-model="time"
           value-format="yyyy-MM-dd"
           format="yyyy-MM-dd"
           type="daterange"
           start-placeholder="开始日期"
           end-placeholder="结束日期"
         ></el-date-picker>
      </el-form-item>
     <el-form-item>
        <el-select v-model="form.is_link_goods" placeholder="关联云交会" clearable>
          <el-option
            label="否"
            value="0"
          ></el-option>
          <el-option
            label="是"
            value="1"
          ></el-option>
        </el-select>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" @click="onSearch">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>
<el-descriptions  direction="vertical" :column="8" border>
  <el-descriptions-item>
    <template slot="label">
            <span>
              总浏览量（pv）
              <el-tooltip class="item" effect="dark" content="总浏览量是榜单斑的钫问人次统计，仅根据品类和月榜单查询改变" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
          </template>
    {{totallist.total_pv}}</el-descriptions-item>
  <el-descriptions-item >
    <template slot="label">
            <span>
              总访客数（uv）
              <el-tooltip class="item" effect="dark" content="总访客数是榜单页的访问人数统计，仅根据品类和月榜单査询改变" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
          </template>
    {{totallist.total_uv}}</el-descriptions-item>
  <el-descriptions-item label="总点赞数" :span="2">
    {{totallist.like_num}}</el-descriptions-item>
  <el-descriptions-item label="总收藏数">
    {{totallist.collect_num}}
  </el-descriptions-item>
  <el-descriptions-item >
     <template slot="label">
            <span>
              产品页总浏览数
              <el-tooltip class="item" effect="dark" content="产品页总浏览数是产品在榜期间从榜单页进入产品详情页的PV" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
          </template>
    {{totallist.view_pv}}</el-descriptions-item>
  <el-descriptions-item >
    <template slot="label">
            <span>
              产品页总访客数
              <el-tooltip class="item" effect="dark" content="产品页总访客数是产品在榜期间从榜单页进入产品详情页的UV" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
          </template>
    {{totallist.view_uv}}</el-descriptions-item>
  <el-descriptions-item >
    <template slot="label">
            <span>
              总意向数
              <el-tooltip class="item" effect="dark" content="总意问数是指产品在榜期间点击“请企业联系我”、留言成功、样品申请成功、拨打电话成功之和" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
          </template>
    {{totallist.intent_num}}</el-descriptions-item>
</el-descriptions>
    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '封面图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
<!--      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template #default="{ row }">
          <el-popconfirm title="确认删除？" @confirm="onDelete(row)">
            <el-button slot="reference" type="text" size="small">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>-->
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination></span>
<!--    <span slot="footer" class="dialog-footer">
    <el-button @click="showliu = false">取 消</el-button>
    <el-button type="primary" @click="showliu = false">确 定</el-button>
  </span>-->
  </el-dialog>
</template>

<style scoped>

</style>
