<script>
import { getAction } from "@/api/request";

export default {
  name: "guize",
  data() {
    return {
      guize: false,
      guizeform: {
        cat_popup_scan: "",
        cat_popup_count:'',
      },
      rules: {
        cat_popup_scan: [
          { required: true, message: "请输入浏览多少秒弹出", trigger: "blur" }
        ],
        cat_popup_count: [
          { required: true, message: "请输入每天共弹出几次", trigger: "blur" }
        ]
      },
    };
  },
  methods: {
    handlerinfo() {
      getAction("/api/system/conf/cat-popup-conf").then(res => {
        this.guizeform = res.data;
      });
      this.guize = true;
    },
    handlerset(){
      this.$refs.guizeform.validate(valid => {
        if (valid){
          getAction("/api/system/conf/set-cat-popup",this.guizeform).then(()=>{
            this.$message({
              type: "success",
              message: "设置成功"
            });
            this.guize = false;
          })
        }
      });

    },
  }
};
</script>

<template>
<div>
  <el-dialog
    :visible.sync="guize"
    width="30%">
      <span>
        <el-form ref="guizeform" :model="guizeform" label-width="120px" :rules="rules">
          <el-form-item label="浏览多少秒弹出" prop="cat_popup_scan">
             <el-input v-model="guizeform.cat_popup_scan" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="每天共弹出几次" prop="cat_popup_count">
             <el-input v-model="guizeform.cat_popup_count" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
      </span>
    <span slot="footer" class="dialog-footer">
        <el-button @click="guize = false">取 消</el-button>
        <el-button type="primary" @click="handlerset">确 定</el-button>
      </span>
  </el-dialog>
  <el-button type="primary" style="margin-bottom: 20px" @click="handlerinfo">设置弹窗规则</el-button>
</div>
</template>

<style scoped>

</style>
