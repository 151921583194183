<template>
<div>
  <el-dialog
    :title="title"
    :visible.sync="addbanner"
    width="50%"
  append-to-body>
    <div>
      <el-form :model="form" label-width="120px" :rules="rules" ref="form">
        <el-form-item label="广告名称" prop="advert_name">
          <el-input maxlength="20" placeholder="最多输入20个字" v-model="form.advert_name" style="width: 220px;"/>
        </el-form-item>
<!--        <el-form-item label="广告位" prop="advert_no">-->
<!--          <el-select v-model="form.advert_no" clearable style="width: 160px;" placeholder="类型" @change="handlerpos">-->
<!--            <el-option v-for="list in typeSelect"-->
<!--                       :key="list.advert_no"-->
<!--                       :value="list.advert_no"-->
<!--                       :label="list.position_name"/>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="广告图" prop="advert_img1">
          <upload-img ref="uploadImage" :infoText="infoText" :limit="1" @uploadImg="uploadImg" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="跳转方式"  prop="advert_jump_type">
<!--          @change="handlerjuptype"-->
          <el-select v-model="form.advert_jump_type" clearable style="width: 160px;" placeholder="跳转方式"  @change="handlerjuptype">
            <el-option v-for="list in toSelect"
                       :key="list.jump_id"
                       :value="list.jump_id"
                       :label="list.jump_page"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="jump_name" v-if="form.advert_jump_type==3||form.advert_jump_type==22||form.advert_jump_type==12||form.advert_jump_type==27" prop="advert_jump_params">
          <el-input  v-model="form.advert_jump_params"  :placeholder="jump_name" style="width: 160px;"/>
        </el-form-item>
        <el-form-item label="选择店铺" v-else-if="form.advert_jump_type==1" prop="advert_jump_params">
          <el-autocomplete
            style="width: 160px;"
            v-model="form.ref_name"
            :fetch-suggestions="handlermell"
            value-key="mall_name"
            clearable
            placeholder="请输入店铺名称"
            @select="handleSelectmall"
          ></el-autocomplete>

        </el-form-item>
        <el-form-item
          v-else-if="form.advert_jump_type == 20"
          label="选择选品日"
          prop="advert_jump_params"
        >
          <el-autocomplete
            v-model="form.ref_name"
            :fetch-suggestions="handlerarea"
            placeholder="请输入选品日名称"
            style="width: 160px"
            clearable
            value-key="title"
            @select="handleSelectarea"
          />
        </el-form-item>
        <el-form-item
          v-else-if="form.advert_jump_type == 21"
          label="线上品类节"
          prop="advert_jump_params"
        >
          <el-autocomplete
            v-model="form.ref_name"
            :fetch-suggestions="handleract"
            placeholder="请输入线上品类节"
            style="width: 160px"
            clearable
            value-key="title"
            @select="handleSelectact"
          />
        </el-form-item>
        <el-form-item
          v-else-if="form.advert_jump_type == 18||form.advert_jump_type == 7"
          label="品类"
          prop="advert_jump_params"
        >
          <!-- @change="handlerjuptype" -->
          <el-select
            v-model="form.advert_jump_params"
            clearable
            placeholder="请选择品类"
            style="width: 160px"
          >
            <el-option
              v-for="list in pllist"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="选择商品" v-else-if="form.advert_jump_type==2" prop="advert_jump_params">
          <el-autocomplete
            style="width: 160px;"
            v-model="form.ref_name"
            :fetch-suggestions="handlergoods"
            value-key="goods_name"
            clearable
            placeholder="请输入商品名称"
            @select="handleSelectgoods"
          ></el-autocomplete>
        </el-form-item>

        <el-form-item label="排序" prop="advert_sort">
          <el-input type="number" v-model="form.advert_sort" placeholder="排序" style="width: 160px;"/>
        </el-form-item>
        <el-form-item label="状态" prop="is_open">
          <el-select v-model="form.is_open" clearable style="width: 160px;" placeholder="状态">
            <el-option v-for="list in statusSelect"
                       :key="list.id"
                       :value="list.id"
                       :label="list.name"/>
          </el-select>
        </el-form-item>
        <el-form-item label="时间" >
          <el-card class="box-card">
            <el-form-item v-for="(list,index) in form.times" :key="index" >
              <div style="display: flex;align-items: center">
                <el-date-picker
                  v-model="list.tiem"
                  @change="handlertiem($event,index)"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['12:00:00']">
                </el-date-picker>
                <i class="el-icon-circle-plus-outline" style="margin: 0px 10px;font-size: 24px" @click="handelraddtime"></i>
                <i class="el-icon-remove-outline" style="font-size: 24px" v-show="form.times.length>1" @click="handlerdelet(index)"></i>
              </div>
            </el-form-item>

          </el-card>

        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addbanner = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import uploadImg from "@/components/uploadImg";
import { getAction, postAction } from "@/api/request";
export default {
  components:{
    uploadImg
  },
  data(){
    var checkjump=(rule,value,callback)=>{
      if(this.form.advert_jump_type==1){
        if(value==''){
          callback(new Error('请选择店铺'))
        }else{
          callback()
        }
      }else if(this.form.advert_jump_type==2){
        if(value==''){
          return callback(new Error('请选择产品'))
        }else{
          callback()
        }
      }else if(this.form.advert_jump_type==3){
        if(value==''){
          return callback(new Error('请输入H5链接'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    return{
      title:'新增',
      addbanner:false,
      jump_name:'',
      form:{
        id:null,
        advert_name:"",
        advert_no:'',
        advert_img1:'',
        advert_jump_type:'',
        advert_jump_params:'',
        ref_name:'',
        is_open:'',
        advert_sort:'',
        times:[{
          tiem:null,
          start_time:'',
          end_time:'',
          times_id:''
        }],
      },
      infoText:"",
      rules:{
        advert_name:[
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        advert_no:[
          { required: true, message: '请选择类型', trigger: 'blur' },
        ],
        advert_img1:[
          { required: true, message: '请上传图片', trigger: 'blur' },
        ],
        advert_jump_type:[
          { required: true, message: '请选择跳转方式', trigger: 'blur' },
        ],
        advert_jump_params:[
          {required: true, validator: checkjump, trigger: 'blur' },
        ],
        advert_sort:[
          { required: true, message: '请输入排序顺序', trigger: 'blur' },
        ],
        is_open:[
          { required: true, message: '请选择状态', trigger: 'blur' },
        ],

      },
      toSelect:[],
      typeSelect:[],
      pllist:[],
      statusSelect: [
         {
          id: 1,
          name: '启用'
        }, {
          id: 0,
          name: '禁用'
        }
      ],
      storSelect:[],
      goodsSelect:[]
    }
  },
  watch:{
    addbanner(val){
      if(!val){
        this.resetForm()
        this.$refs.uploadImage.img=[]
        this.title="新增"
      }
    },
    // 'form.advert_no'(e){
    //   this.handlerpos(e)
    // }
  },
  mounted() {
    this.handlerSelect()
  },
  methods:{
    resetForm() {
      this.$refs.form.resetFields()
      this.form={
        id:null,
        advert_name:"",
        advert_no:'',
        advert_img1:'',
        advert_jump_type:'',
        advert_jump_params:'',
        ref_name:'',
        is_open:'',
        advert_sort:'',
        times:[
          {tiem:null,
            start_time:'',
            end_time:'',
            times_id:''}
        ],
      }
    },
    uploadImg(val){
      console.log(val)
      if(val.length<1){
        this.form.advert_img1=''
      }else{
        this.form.advert_img1=val[0]
      }

    },
    handlertiem(e,index){
      if(e){
        this.form.times[index].start_time=e[0]
        this.form.times[index].end_time=e[1]
      }else{
        this.form.times[index].start_time=''
        this.form.times[index].end_time=''
      }

    },
    handelraddtime(){
      this.form.times.push({
        tiem:null,
        start_time:'',
        end_time:'',
        times_id:''
      })
    },
    handlerdelet(index){
      this.form.times.splice(index,1)
    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.advert_jump_params=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.advert_jump_params=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }

    },
    handlerarea(queryString, cb){
      if (queryString == '') {
        cb([])
        this.form.advert_jump_params = ''
      } else {
        getAction('/live/index/search-area', { title: queryString }).then(
          (res) => {
            cb(res.data)
          }
        )
      }
    },
    handleract(queryString, cb){
      if (queryString == '') {
        cb([])
        this.form.advert_jump_params = ''
      } else {
        getAction('/api/system/category-act/select', { name: queryString }).then(
          (res) => {
            cb(res.data)
          }
        )
      }
    },
    handleSelectmall(list){
      console.log(list)
      this.form.advert_jump_params=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.form.advert_jump_params=list.id
    },
    handleSelectarea(list) {
      console.log(list)
      this.form.advert_jump_params = list.id
    },
    handleSelectact(list){
      console.log(list)
      this.form.advert_jump_params = list.id
    },
  async handlerSelect(){
     await getAction("/audit/advert/position",).then(res=>{
        this.typeSelect=res.data
      })
      let typeSelect=await getAction("/audit/advert/jump-type",)
      console.log(typeSelect.data)
        this.toSelect=typeSelect.data
    let pinlei = await getAction('/user/index/cate')
    this.pllist = pinlei.data
    },
    handlerpos(e){
      console.log(e)
      this.infoText=this.typeSelect.filter(list=>list.advert_no==e)[0].position_page
    },
    handlerinfo(row){
      getAction("/audit/advert/view",{id:row.id}).then(res=>{
        this.title="编辑"
        if(res.data.times.length>0){
          res.data.times.map(list=>{
            list.tiem=[list.start_time,list.end_time]
          })
        }
        res.data.advert_jump_type=String(res.data.advert_jump_type)
        res.data.advert_no=String(res.data.advert_no)
        this.form=Object.assign(this.form,res.data)
        if(this.form.times.length>0){
          this.form.times.map(list=>{
            list.tiem=[list.start_time,list.end_time]
          })
        }else{
          this.form.times=[
            {tiem:null,
              start_time:'',
              end_time:'',
              times_id:''}
          ]
        }
        setTimeout(()=>{
          this.$refs.uploadImage.img=[res.data.advert_img1]
        },100)
      })


    },
    handlersave(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          var data = JSON.parse(JSON.stringify(this.form))
          if (data.times[0].tiem === null) {
            data.times = []
          }
          postAction("/audit/advert/edit",data).then(res=>{
            if(res.request_code==200){
              this.addbanner=false
              this.$message({
                type:'success',
                message:res.msg
              })
              this.$emit("getlist")
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handlerjuptype(e){
      this.form.advert_jump_params=''
      let data = this.toSelect.filter(
        (list) => list.jump_id == e
      )
      if(data.length>0){
        this.jump_name = this.toSelect.filter(
          (list) => list.jump_id == e
        )[0].jump_page
      }else{
        this.jump_name = ''
      }

    }
  }
};
</script>

<style scoped>

</style>
