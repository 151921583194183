var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogVisible, width: "40%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                size: "mini",
                rules: _vm.rules,
              },
            },
            [
              _c("el-form-item", { attrs: { label: "品类" } }, [
                _vm._v(" " + _vm._s(_vm.form.cat_pname) + " "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "开关", prop: "door" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "开关" },
                      model: {
                        value: _vm.form.door,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "door", $$v)
                        },
                        expression: "form.door",
                      },
                    },
                    _vm._l(_vm.optionsList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "福利弹窗", prop: "welfare_pic" } },
                [
                  _c("upload-img", {
                    ref: "welfare_pic",
                    attrs: { "info-text": "尺寸：750*104", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "welfare_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "领取弹框图片", prop: "receive_pic" } },
                [
                  _c("upload-img", {
                    ref: "receive_pic",
                    attrs: {
                      "info-text": "尺寸：300*300，太小图会虚",
                      limit: 1,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "receive_pic")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }