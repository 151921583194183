<template>
  <div style="padding: 20px">
    <!-- ... existing code ... -->
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="">
        <el-form-item>
          <el-select
            v-model="form.htyun_dept"
            clearable
            filterable
            placeholder="一级部门"
            style="width: 160px"
          >
            <el-option
              v-for="(list, index) in oneDeptSelect"
              :key="index"
              :label="list.htyun_kind"
              :value="list.htyun_kind"
            />
          </el-select>
        </el-form-item>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.htyun_kind"
          clearable
          filterable
          placeholder="部门"
          style="width: 160px"
        >
          <el-option
            v-for="(list, index) in deptSelect"
            :key="index"
            :label="list.htyun_dept"
            :value="list.htyun_dept"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.htyun_name"
          clearable
          filterable
          placeholder="邀请人"
        >
          <el-option
            v-for="(i, idx) in staffSelect"
            :key="idx"
            :label="i.name"
            :value="i.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="source"
          clearable
          multiple
          placeholder="全部来源"
          style="width: 200px"
        >
          <el-option
            v-for="(i, idx) in datatypelist"
            :key="idx"
            :label="i.name"
            :value="i.id"
          />
        </el-select>
      </el-form-item>
      <el-tooltip content="只支持筛选邀请人PV、邀请人UV、分享数">
        <!-- <el-icon-question style="cursor: pointer"></el-icon-question> -->
        <i
          class="el-icon-warning-outline"
          style="font-size: 18px; margin-top: 5px; margin-right: 10px"
        ></i>
      </el-tooltip>
      <el-form-item label="">
        <el-date-picker
          v-model="form.dateRange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button type="success" @click="onExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      @sort-change="sortChange"
      border
    >
      <el-table-column prop="htyun_dept" label="一级部门"></el-table-column>
      <el-table-column prop="htyun_kind" label="二级部门"></el-table-column>
      <el-table-column prop="htyun_name" label="邀请人"></el-table-column>
      <el-table-column
        prop="vip1_num"
        label="华糖会员数量980元"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="vip3_num"
        label="老会员数量480元"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="vip4_num"
        label="数字刊资讯数"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="open_num"
        label="已开通数"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="expire_num"
        label="已过期数"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="nopay_num"
        label="未支付数"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="no_activate_num"
        label="未激活数"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="max_flow"
        label="流量类型"
        :formatter="formatFlowType"
      ></el-table-column>
      <el-table-column
        prop="total_pv"
        label="邀请PV"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="total_uv"
        label="邀请UV"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="share_num"
        label="分享数"
        sortable="custom"
      ></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="form.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="form.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <!-- ... existing code ... -->
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    data() {
      return {
        total: 0,
        source: '',
        form: {
          htyun_kind: '',
          htyun_dept: '',
          htyun_name: '',
          model: [],
          firstDepartment: '',
          secondDepartment: '',
          inviter: '',
          source: '',
          dateRange: '',
          page: 1,
          limit: 10,
        },
        staffSelect: [],
        tableData: [],
        oneDeptSelect: [],
        deptSelect: [],
        datatypelist: [
          {
            id: 1,
            name: '华糖会员',
          },
          {
            id: 2,
            name: '数字刊',
          },
          {
            id: 3,
            name: '管理实战课',
          },
          {
            id: 4,
            name: '线下研修',
          },
          {
            id: 5,
            name: '趋势报告',
          },
        ],
      }
    },
    mounted() {
      this.onSearch()
      // this.getStaff()
      this.handlerSelect()
    },
    methods: {
      //排序
      sortChange({ column, prop, order }) {
        console.log('column, prop, order', column, prop, order)
        this.form.orderField = prop
        // 值 asc 小到大  和  desc  大到小]
        if (order === 'descending') {
          this.form.orderBy = 'desc'
        } else if (order === 'ascending') {
          this.form.orderBy = 'asc'
        } else {
          this.form.orderField = ''
          this.form.orderBy = ''
        }
        this.onSearch()
      },
      async handlerSelect() {
        const one_dept = await getAction('api/form/form/dept-one')
        this.oneDeptSelect = one_dept.data
        const dept = await getAction('/form/form/dept')
        this.deptSelect = dept.data
        const staff = await getAction('/form/form/staff')
        this.staffSelect = staff.data
      },
      // 获取员工列表
      // getStaff() {
      //   getAction('api/common/common/users-staff-list').then((res) => {
      //     if (res.request_code == 200) {
      //       this.staffSelect = res.data
      //     }
      //   })
      // },
      formatFlowType(row, column, cellValue) {
        const flowTypeMap = {
          1: '华糖会员',
          2: '数字刊',
          3: '管理实战课',
          4: '线下研修',
          5: '行业报告',
        }
        return flowTypeMap[cellValue] || '未知类型'
      },
      onSearch() {
        this.form.page = 1
        // 查询逻辑
        this.getlistdata()
      },
      onExport() {
        // 导出逻辑
        postAction('/user/business-club/staff-analyze-export', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.data,
              filename: res.data.name,
            })
          }
        )
      },
      getlistdata() {
        let source = JSON.parse(JSON.stringify(this.source))
        this.form.model = source.join(',')
        if (this.form.dateRange != null && this.form.dateRange != '') {
          this.form.start_time = this.form.dateRange[0]
          this.form.end_time = this.form.dateRange[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
        getAction('/user/business-club/staff-analyze-data', this.form).then(
          (res) => {
            this.tableData = res.data
            this.total = res.totalCount
          }
        )
      },
      // 处理每页显示条数变化
      handleSizeChange(val) {
        this.form.limit = val
        this.getlistdata()
      },
      // 处理页码变化
      handleCurrentChange(val) {
        this.form.page = val
        this.getlistdata()
      },
    },
  }
</script>

<style scoped>
  /* ... existing code ... */
</style>
