<template>
  <el-dialog :title="title" :visible.sync="showedit" width="80%">
    <el-button type="primary" @click="handlerExport">导出</el-button>
    <el-table
      border
      v-loading="tableloading"
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        prop="customer_name"
        label="客户姓名"
        width=""
      ></el-table-column>
      <el-table-column
        prop="staff_name"
        label="标签添加人"
        width=""
      ></el-table-column>
      <el-table-column
        prop="createtime"
        label="标签添加时间"
        width=""
      ></el-table-column>
      <el-table-column
        prop="is_perfect"
        label="标签是否已完善"
        width=""
      ></el-table-column>
      <el-table-column prop="83" label="行业" width=""></el-table-column>
      <el-table-column prop="59" label="类别" width=""></el-table-column>
      <el-table-column prop="219" label="身份" width=""></el-table-column>
      <el-table-column prop="191" label="渠道" width=""></el-table-column>
      <el-table-column prop="mobile" label="区域" width=""></el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :layout="layout"
      :page-size="limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="showedit = false">取 消</el-button>
      <!-- <el-button type="primary" @click="handlersave">确 定</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'oklist',

    data() {
      return {
        title: '标签已完善人数',
        tableloading: false,
        showedit: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        total: 0,
        page: 1,
        limit: 10,
        staffname: '', //员工姓名
        date: {},
        rows: {},
      }
    },
    methods: {
      handlerExport() {
        let params = {
          start_date: this.date.start_date,
          end_date: this.date.end_date,
          staff_name: this.staffname,
        }
        getAction('/api/whale/tag/lists-export', params).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },
      searchlist() {
        this.handlerlist(this.rows, this.date)
      },
      handlerlist(row, date) {
        console.log('员工名称，', this.staffname)
        this.rows = row
        this.staffname = row.staff_name
        this.date = date
        let params = {
          start_date: date.start_date,
          end_date: date.end_date,
          staff_name: row.staff_name,
          page: this.page,
          limit: this.limit,
        }
        this.tableloading = true
        getAction('/api/whale/tag/tag-lists', params).then((res) => {
          if (res.request_code == 200) {
            this.tabledata = res.data
            this.total = res.totalCount
          }
          this.tableloading = false
        })
      },
      handleCurrentChange(val) {
        this.page = val
        this.searchlist()
      },
      handleSizeChange(val) {
        this.limit = val
        this.searchlist()
      },
    },
  }
</script>
