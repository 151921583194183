<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="客户姓名">
        <el-input
          v-model="form.name"
          clearable
          placeholder="客户姓名"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="昵称">
        <el-input
          v-model="form.nickname"
          clearable
          placeholder="昵称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input
          v-model="form.company"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>

      <el-form-item label="客户电话">
        <el-input
          v-model="form.mobile"
          clearable
          placeholder="客户电话"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="来源">
        <el-select
          v-model="form.source"
          clearable
          placeholder="来源"
          style="width: 160px"
        >
          <el-option
            v-for="list in sourcelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="提报人">
        <el-input
          v-model="form.plan_name"
          clearable
          placeholder="提报人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="日期类型">
        <el-select
          v-model="form.date_type"
          clearable
          placeholder="提报时间"
          style="width: 160px"
        >
          <el-option
            v-for="list in datetype"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '头像'">
            <el-image
              :preview-src-list="[row.headimg]"
              :src="row.headimg"
              style="width: 80px; height: 80px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '用户状态'">
            {{ row.is_delaer == 1 ? '已认证' : '未认证' }}
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row[item.prop] == 1 ? '喜欢' : '取消喜欢' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import dateFormat from '@/utils/Timeformat'
  import * as excel from '@/utils/excel'

  import vueQr from 'vue-qr'
  export default {
    components: {
      vueQr,
    },
    data() {
      return {
        form: {
          name: '', //需求客户
          root_cat_id: '', //一级品类id
          cat_id: '', //二级品类id
          company: null,
          page: 1,
          limit: 10,
        },
        cateselect: [],
        catselect: [],
        pricelist: [],
        yhylist: [
          {
            id: 3,
            name: '普通云会议',
          },
          {
            id: 4,
            name: '大商直通车',
          },
        ],
        datetype: [
          {
            id: 1,
            name: '提报时间',
          },
        ],
        typeoptions: [
          {
            id: 1,
            name: '指定店铺',
          },
          {
            id: 2,
            name: '品类',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '来源',
            prop: 'source',
            width: '',
          },
          {
            label: '昵称',
            prop: 'nickname',
            width: '',
          },

          {
            label: '提报人',
            prop: 'staff_user_name',
            width: '',
          },
          {
            label: '提报时间',
            prop: 'created',
            width: '',
          },
          {
            label: '浏览数',
            prop: 'view_num',
            width: '',
          },
          {
            label: '"请厂家联系我"数',
            prop: 'like_num',
            width: '',
          },
          {
            label: '"不感兴趣"数',
            prop: 'introduction_num',
            width: '',
          },
          {
            label: '认证经销商',
            prop: 'is_dealer',
            width: '',
          },
          {
            label: '客户名称',
            prop: 'name',
            width: '120px',
          },

          {
            label: '头像',
            prop: 'headimg',
            width: '220px',
          },

          {
            label: '客户电话',
            prop: 'mobile',
            width: '160',
          },
          {
            label: '公司名称',
            prop: 'company',
            width: '',
          },

          {
            label: '行业所在地区',
            prop: 'area',
            width: '',
          },

          {
            label: '渠道',
            prop: 'channel',
            width: '',
          },
          {
            label: '年营业额(万元)',
            prop: 'trade',
            width: '220px',
          },

          {
            label: '终端网点数',
            prop: 'terminal_network',
            width: '',
          },
          {
            label: '代理品牌数',
            prop: 'proxy',
            width: '',
          },
          {
            label: '代理品牌',
            prop: 'proxy_three',
            width: '',
          },
        ],
        total: 0,
        sumtotal: '',
        timelist: [], // dateFormat(new Date() - 3600 * 1000 * 24 * 30),dateFormat(new Date()),
        sourcelist: [
          {
            id: 1,
            name: '选品',
          },
          {
            id: 2,
            name: '群发',
          },
        ],
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      // 获取价格带数据
      getWinePrice() {
        postAction('/audit/biz-clue/wine-price', {}).then((res) => {
          console.log('价格带-', res)
          if (res.request_code == 200) {
            this.pricelist = res.data
          }
        })
      },
      handlerInquire() {
        this.form.id = ''
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerExport() {
        postAction('/api/work/sel-plan/rebuild-export', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },

      handlerlist() {
        postAction('/api/work/sel-plan/rebuild-lists', this.form).then(
          (res) => {
            this.tabledata = res.data.list
            this.total = Number(res.data.total)
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
