<template>
  <div style="padding: 20px">
    <el-tabs v-model="activeTab" @tab-click="handleTabClick">
      <el-tab-pane
        v-for="(tab, index) in tabs"
        :key="index"
        :label="tab.title"
        :name="tab.name"
      ></el-tab-pane>
    </el-tabs>
    <el-form :model="form" inline v-if="activeTab > '0'">
      <el-form-item>
        <el-select
          v-model="form.cate_id"
          clearable
          style="width: 160px"
          placeholder="品类"
          @change="handlercat"
        >
          <el-option
            v-for="list in catSelect"
            :key="list.id"
            :value="list.id"
            :label="list.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAddgoods">
          {{ activeTab == '1' ? '上传店铺' : '上传产品' }}
        </el-button>
      </el-form-item>
    </el-form>
    <div v-if="activeTab == '0'" style="position: relative">
      <el-table border :data="tabledata" style="width: 60%">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="type" label="内容类型">
          <template slot="default" slot-scope="scope">
            <div>
              <span v-if="scope.row.type == 1">名企</span>
              <span v-else-if="scope.row.type == 2">新品</span>
              <span v-else-if="scope.row.type == 3">热品</span>
              <span v-else-if="scope.row.type == 4">产品</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sort_order" label="排序">
          <template slot="default" slot-scope="scope">
            <template>
              <span v-if="scope.$index < 3"></span>
              <el-input
                v-else
                v-model="scope.row.sort_order"
                type="number"
                @change="handleSortChange(scope.row)"
              ></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot="default" slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="'1'"
              :inactive-value="'0'"
              active-color="#13ce66"
              inactive-color="#ccc"
              @change="handleStatusChange(scope.row, scope.$index)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="onShowAdd1(scope.row, scope.$index)"
            >
              编辑
            </el-button>
            <el-button
              v-if="scope.$index > 2"
              type="text"
              size="small"
              @click="confirmDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button
        type="primary"
        @click="onShowAdd1"
        v-if="activeTab == '0'"
        style="position: absolute; bottom: 5px; left: 62%"
      >
        新增
      </el-button>
    </div>
    <div v-if="activeTab == '1'">
      <p style="color: #f00">
        系统规则:优先展示付费企业，然后是免费企业，数据实时更新。手动设置：可手动设置店铺展示的顺序。手动设置有时效性，且优先级大于系统规则
      </p>
      <el-table
        ref="table"
        v-loading="loading"
        border
        :data="tabledata"
        row-key="user_id"
      >
        <el-table-column align="center" label="ID" prop="id" width="90" />
        <el-table-column align="center" label="店铺LOGO" prop="img">
          <template slot-scope="scope">
            <img
              :src="scope.row.img"
              alt="img"
              style="width: 50px; height: 50px"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="店铺名称" prop="goods_name" />
        <el-table-column align="center" label="排序" prop="sort_order" />
        <el-table-column align="center" label="生效时间">
          <template slot="default" slot-scope="scope">
            <span v-if="scope.row.start_time">
              {{ scope.row.start_time }}至{{ scope.row.end_time }}
            </span>
            <span v-else>永久有效</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-popconfirm title="确认删除吗？" @confirm="onDelete(scope.row)">
              <el-button slot="reference" type="text" size="small">
                移除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="activeTab == '2'">
      <p style="color: #f00">
        系统规则：拉取首页每日上新二级页面品类页面产品。手动设置：可手动设置新品展示的顺
        序，手动设置有时效性，且优先级大于系统规则。（新品更多规则：同一店铺不限制新品数量且除去下面表格产品）支持手动上传第三方产品
      </p>
      <el-table
        ref="table"
        v-loading="loading"
        border
        :data="tabledata"
        row-key="user_id"
      >
        <el-table-column align="center" label="ID" prop="id" width="90" />
        <el-table-column align="center" label="产品LOGO" prop="img">
          <template slot-scope="scope">
            <img
              :src="scope.row.img"
              alt="img"
              style="width: 50px; height: 50px"
            />
          </template>
        </el-table-column>

        <el-table-column align="center" label="产品名称" prop="goods_name" />
        <el-table-column align="center" label="店铺" prop="shop_name" />
        <el-table-column align="center" label="发布时间" prop="created" />
        <el-table-column align="center" label="排序" prop="sort_order" />
        <el-table-column align="center" label="生效时间" prop="mobile">
          <template slot="default" slot-scope="scope">
            <span v-if="scope.row.start_time">
              {{ scope.row.start_time }}至{{ scope.row.end_time }}
            </span>
            <span v-else>永久有效</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-popconfirm title="确认删除吗？" @confirm="onDelete(scope.row)">
              <el-button slot="reference" type="text" size="small">
                移除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="activeTab == '3'">
      <p style="color: #f00">
        系统规则：根据最近7天的产品数据，每日生成一个top10榜单；搜索指数=
        0.3*搜索产品曝光（下拉提示+搜索结果页）+0.2*搜索点击进入产品页+0.1*产品浏览次数
        +0.3*产品互动数（留言+电话）+0.1*样品申请数。手动设置有时效性，且优先级大于系统规则。支持手动上传第三方产品
      </p>
      <el-table
        ref="table"
        v-loading="loading"
        border
        :data="tabledata"
        row-key="user_id"
      >
        <el-table-column align="center" label="ID" prop="id" width="90" />
        <el-table-column align="center" label="产品LOGO" prop="name">
          <template slot-scope="scope">
            <img
              :src="scope.row.img"
              alt="img"
              style="width: 50px; height: 50px"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="产品名称" prop="goods_name" />
        <el-table-column align="center" label="店铺" prop="shop_name" />
        <el-table-column align="center" label="浏览数" prop="view_num" />
        <el-table-column align="center" label="热搜指数" prop="index_num" />
        <el-table-column align="center" label="排序" prop="sort_order" />
        <el-table-column align="center" label="生效时间">
          <template slot="default" slot-scope="scope">
            <span v-if="scope.row.start_time">
              {{ scope.row.start_time }}至{{ scope.row.end_time }}
            </span>
            <span v-else>永久有效</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-popconfirm title="确认删除吗？" @confirm="onDelete(scope.row)">
              <el-button slot="reference" type="text" size="small">
                移除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="activeTab > '3'">
      <p style="color: #f00">
        系统规则：优先展示付费企业产品，然后是免费企业产品，每个企业随机选择一个产品，数据实时更新。手动设置：可手动设置产品展示的顺
        序，手动设置有时效性，且优先级大于系统规则。支持手动上传第三方产品
      </p>
      <el-table
        ref="table"
        v-loading="loading"
        border
        :data="tabledata"
        row-key="user_id"
      >
        <el-table-column align="center" label="ID" prop="id" width="90" />
        <el-table-column align="center" label="产品LOGO" prop="name">
          <template slot-scope="scope">
            <img
              :src="scope.row.img"
              alt="img"
              style="width: 50px; height: 50px"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="产品名称" prop="goods_name" />
        <el-table-column align="center" label="店铺" prop="shop_name" />
        <el-table-column align="center" label="排序" prop="sort_order" />
        <el-table-column align="center" label="生效时间" prop="mobile">
          <template slot="default" slot-scope="scope">
            <span v-if="scope.row.start_time">
              {{ scope.row.start_time }}至{{ scope.row.end_time }}
            </span>
            <span v-else>永久有效</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-popconfirm title="确认删除吗？" @confirm="onDelete(scope.row)">
              <el-button slot="reference" type="text" size="small">
                移除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      v-if="activeTab > 0"
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>

    <catinfo ref="catinfo" />
    <add1 ref="add1" @getdata="getdata" />
    <el-dialog title="新增功能" :visible.sync="showAddDialog" width="40%">
      <el-form
        :model="newItem"
        label-width="120px"
        :rules="rules"
        ref="addForm"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="newItem.title"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <upload-img
            ref="icon"
            :infoText="''"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'icon')"
            :maxSize="100"
          />
        </el-form-item>
        <el-form-item label="标题类型">
          <el-radio-group v-model="newItem.title_type">
            <el-radio :label="'1'">文本</el-radio>
            <el-radio :label="'2'">图片</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="产品轮播时间" v-if="currentIndex < 2">
          <el-input
            v-model="newItem.cycle_time"
            type="number"
            style="width: 100px"
          ></el-input>
          秒
        </el-form-item>
        <div v-if="currentIndex == 2">
          <el-form-item label="规则类型">
            <el-radio-group v-model="newItem.rule_type">
              <el-radio label="1">周榜</el-radio>
              <el-radio label="2">月榜</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="规则">
            <el-input
              v-model="newItem.rule_title"
              type="input"
              placeholder="数据来源"
            ></el-input>
          </el-form-item>
          <el-form-item label="规则内容">
            <el-input
              v-model="newItem.rule_content"
              type="textarea"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item label="产品副标题图标">
            <el-radio-group v-model="newItem.goods_sub_icon">
              <el-radio label="1">
                <img
                  style="vertical-align: -9px"
                  src="../../../../assets/yan.png"
                  alt=""
                />
              </el-radio>
              <el-radio label="2">
                <img
                  style="vertical-align: -7px"
                  src="../../../../assets/huo.png"
                  alt=""
                />
              </el-radio>
              <el-radio label="">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="产品副标题数据">
            <el-radio-group
              v-model="newItem.goods_sub_data"
              @change="handleGoodsSubDataChange"
            >
              <el-radio label="a">a浏览量</el-radio>
              <el-radio label="b">b搜索指数</el-radio>
              <el-radio label="">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="产品副标题">
            <el-input
              v-model="newItem.goods_sub_title"
              type="input"
              style="width: 180px"
              maxlength="5"
              show-word-limit
            ></el-input>
            <span style="color: #f00">限制5个字符</span>
          </el-form-item>
        </div>
        <div v-if="currentIndex > 2">
          <el-form-item label="平台产品数量">
            <el-input v-model="newItem.platform_num" type="number"></el-input>
          </el-form-item>
          <el-form-item label="非平台产品数量">
            <el-input
              v-model="newItem.no_platform_num"
              type="number"
            ></el-input>
          </el-form-item>

          <el-form-item label="排序">
            <el-input v-model="newItem.sort_order" type="number"></el-input>
          </el-form-item>
          <el-form-item label="效果">
            <el-radio-group v-model="newItem.display_type">
              <el-radio label="1">横向展示</el-radio>
              <el-radio label="2">树列展示</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <el-form-item label="状态">
          <el-radio-group v-model="newItem.status">
            <el-radio :label="'1'">开启</el-radio>
            <el-radio :label="'2'">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取消</el-button>
        <el-button type="primary" @click="handleAddConfirm">确定</el-button>
      </span>
    </el-dialog>
    <!-- 新增上传产品弹窗 -->
    <el-dialog
      title="上传"
      :visible.sync="uploadProductDialog.visible"
      :before-close="closeUpload"
      width="50%"
    >
      <el-form
        :model="uploadProductDialog.form"
        label-width="120px"
        ref="uploadForm"
      >
        <el-form-item
          label="店铺名称"
          v-if="uploadProductDialog.form.conf_id == 1"
        >
          <el-autocomplete
            v-model="uploadProductDialog.form.mall_name"
            :fetch-suggestions="handlerstore"
            placeholder="请输入店铺名称"
            style="width: 300px"
            value-key="mall_name"
            @select="handleSelectstore"
          />
          <span>可输入关键词模糊搜索</span>
        </el-form-item>
        <div v-else>
          <el-form-item label="产品类型">
            <el-radio-group v-model="uploadProductDialog.form.type">
              <el-radio label="1">平台产品</el-radio>
              <el-radio label="2">非平台产品</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="产品名称">
            <el-autocomplete
              v-model="uploadProductDialog.form.goods_name"
              :fetch-suggestions="handlermell"
              placeholder="请输入产品名称"
              style="width: 300px"
              value-key="goods_name"
              @select="handleSelectmall"
            />
            <span>可输入关键词模糊搜索</span>
          </el-form-item>
        </div>

        <el-form-item label="排序">
          <el-input
            v-model="uploadProductDialog.form.sort_order"
            type="number"
            style="width: 100px"
          ></el-input>
        </el-form-item>
        <div v-if="uploadProductDialog.form.conf_id == 3">
          <el-form-item label="热搜指数">
            <el-input
              v-model="uploadProductDialog.form.index_num"
              type="number"
              style="width: 100px"
            ></el-input>
          </el-form-item>
          <el-form-item label="浏览量">
            <el-input
              v-model="uploadProductDialog.form.view_num"
              type="number"
              style="width: 100px"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="生效时间">
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['12:00:00']"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeUpload">取消</el-button>
        <el-button type="primary" @click="handleUploadConfirm">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import catinfo from './components/catinfo'
  import UploadImg from '@/components/uploadImg'
  import add1 from './components/add1'
  export default {
    components: {
      catinfo,
      UploadImg,
      add1,
    },
    data() {
      return {
        tabledata: [],
        showAddDialog: false,
        loading: false,
        newItem: {
          title: '',
          icon: '',
          title_type: '1', //1文本 2图片
          platform_num: 0,
          no_platform_num: 0,
          display_type: '1', //1 '横向展示' 2竖列
          sort_order: 1,
          status: '1', // 1开启 2关闭
          cycle_time: '', //产品轮播时间
          rule_title: '数据来源',
          rule_content: '',
          rule_type: '1', // 1周榜 2月榜
          goods_sub_title: '', //1眼 2火
          goods_sub_icon: '',
          goods_sub_data: '', //'a' 'b' 产品副标题数据
        },
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        },
        activeTab: '0',
        tabs: [
          {
            title: '品类配置',
            name: '0',
          },
        ],
        filteredTableData: [],
        currentIndex: 3,
        form: {
          page: 1,
          limit: 10,
          conf_id: '', //品类配置id
          cate_id: '1', //品类ID
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        catSelect: [],
        uploadProductDialog: {
          visible: false,
          loading: false,
          options: [],
          form: {
            conf_id: 1,
            cate_id: 1,
            type: '1',
            goods_name: '',
            goods_id: '',
            sort_order: 1,
            view_num: '',
            index_num: '',
          },
        },
        time: [],
        currenttab: '0',
        isGoodsSubDataChanged: false,
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.uploadProductDialog.form.start_time = v[0]
          this.uploadProductDialog.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      showAddDialog(n, o) {
        if (!n) {
          this.resetForm()
        }
      },
      'newItem.goods_sub_data'(n, o) {
        if (!this.isGoodsSubDataChanged) {
          // 如果没有手动更改过，则不执行更新逻辑
          return
        }
        if (n == 'a') {
          this.newItem.goods_sub_title = 'a'
        } else if (n == 'b') {
          this.newItem.goods_sub_title = 'b'
        } else {
          this.newItem.goods_sub_title = ''
        }
      },
    },
    mounted() {
      this.getdata()
      this.getCate()
    },
    methods: {
      closeUpload() {
        this.uploadProductDialog.visible = false
        this._resetUploadForm()
      },
      handleGoodsSubDataChange() {
        this.isGoodsSubDataChanged = true
        this.newItem.goods_sub_title = this.newItem.goods_sub_data
      },
      handlercat(e) {
        this.uploadProductDialog.form.cate_id = e
        this.filterTableData(this.currenttab)
      },
      handlerAddgoods() {
        this.showUploadProductDialog()
      },
      handlerAddStore() {},
      getCate() {
        getAction('/user/index/cate').then((res) => {
          this.catSelect = res.data
        })
      },
      handlerstore(queryString, cb) {
        if (queryString == '' || !queryString) {
          cb([])
          this.form.good_id = ''
        } else {
          console.log('测试----', queryString)
          getAction('/mall/index/mall', {
            mall_name: queryString,
            cat_pid: this.form.cate_id,
          }).then((res) => {
            cb(res.data)
          })
        }
      },
      handleSelectstore(list) {
        this.uploadProductDialog.form.goods_id = list.id
      },
      handlermell(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.good_id = ''
        } else {
          let apiurl = ''
          if (this.uploadProductDialog.form.type == 1) {
            // apiurl = '/mall/product/goods' //平台调用
            getAction('/mall/product/goods', {
              goods_name: queryString,
              cat_pid: this.form.cate_id,
            }).then((res) => {
              cb(res.data)
            })
          } else if (this.uploadProductDialog.form.type == 2) {
            // apiurl = '/api/common/common/daily-list' //非平台调用
            getAction('/api/common/common/daily-list', {
              goods_name: queryString,
              category_id: this.form.cate_id,
            }).then((res) => {
              cb(res.data)
            })
          }
        }
      },
      handleSelectmall(list) {
        this.uploadProductDialog.form.goods_id = list.id
      },
      onDelete(row) {
        getAction('/api/act/category-conf/goods-del', { id: row.id }).then(
          (res) => {
            this.filterTableData(this.currenttab)
          }
        )
      },
      handleTabClick(tab) {
        console.log('点击tab,', tab)
        this.currenttab = tab.name
        this.form.conf_id = this.tabs[tab.name].id
        this.uploadProductDialog.form.conf_id = this.form.conf_id
        this.filterTableData(tab.name)
      },
      onShowAdd1(row, index) {
        this.showAddDialog = true

        console.log('----', index)
        if (index || index == 0) {
          this.currentIndex = index
          this.newItem = JSON.parse(JSON.stringify(row))
          // 设置 goods_sub_title 的初始值
          if (!this.newItem.goods_sub_title) {
            this.newItem.goods_sub_title = this.newItem.goods_sub_data
          }
          this.isGoodsSubDataChanged = false //初始化标志位
        } else {
          this.currentIndex = 3
        }
        this.$nextTick(() => {
          this.setImg(this.newItem.icon, 'icon')
        })
      },
      getdata() {
        getAction('/api/act/category-conf/list').then((res) => {
          this.tabledata = res.data
          this.tabs.push(
            ...res.data.map((item, index) => ({
              title: item.title,
              name: (index + 1).toString(),
              id: item.id,
            }))
          )
          this.activeTab = this.tabs[0].name
        })
      },
      filterTableData(tabName) {
        this.form.page = 1
        console.log('名称,', tabName)
        if (tabName == '0') {
          this.tabledata = []
          this.tabs = [
            {
              title: '品类配置',
              name: '0',
            },
          ]
          this.getdata()
        } else {
          const tabIndex = parseInt(tabName) - 1
          if (tabIndex < this.tabs.length) {
            // 这里假设每个tab对应的数据是根据某个字段来区分的，这里假设是根据index来区分的
            // 如果需要根据其他字段来区分，需要修改这里的逻辑
            // this.filteredTableData = this.tabledata.filter(
            //   (item, index) => index === tabIndex
            // )
            this.loading = true
            getAction('/api/act/category-conf/goods-list', this.form).then(
              (res) => {
                this.tabledata = res.data.list
                this.total = res.totalCount
                this.loading = false
              }
            )
          } else {
            this.filteredTableData = []
          }
        }
      },
      handleSortChange(row) {
        postAction('/api/act/category-conf/edit', {
          id: row.id,
          sort_order: row.sort_order,
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success('排序更新成功')
            this.tabs = [{ title: '品类配置', name: '0' }]
            this.getdata() // 刷新列表，重新获取数据
          } else {
            this.$message.error('排序更新失败')
          }
        })
      },
      handleStatusChange(row, index) {
        // 如果是前两行，同步状态
        if (index < 2) {
          const targetStatus = row.status
          this.tabledata.forEach((item, idx) => {
            if (idx < 2) {
              item.status = targetStatus
            }
          })
        }

        // 发送状态变更请求
        postAction('/api/act/category-conf/edit', {
          id: row.id,
          status: row.status,
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success('状态更新成功')
            this.tabs = [{ title: '品类配置', name: '0' }]
            this.getdata() // 刷新列表，重新获取数据
          } else {
            this.$message.error('状态更新失败')
          }
        })
      },
      confirmDelete(row) {
        this.$confirm('是否确定删除该条记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.handleDelete(row)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      handleDelete(row) {
        postAction('/api/act/category-conf/del', {
          id: row.id,
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success('删除成功')
            this.tabs = [{ title: '品类配置', name: '0' }]
            this.getdata() // 刷新列表，重新获取数据
          } else {
            this.$message.error('删除失败')
          }
        })
      },
      handleImageSuccess(response, file, fileList) {
        this.newItem.icon = response.url
      },
      handleAddConfirm() {
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            postAction('/api/act/category-conf/edit', this.newItem).then(
              (res) => {
                if (res.code == 0) {
                  this.$message.success('新增成功')
                  this.showAddDialog = false
                  this.resetForm()
                  this.tabs = [{ title: '品类配置', name: '0' }]
                  this.getdata() // 刷新列表，重新获取数据
                } else {
                  this.$message.error('新增失败')
                }
              }
            )
          } else {
            console.log('表单验证失败')
            return false
          }
        })
      },
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.newItem[prop] = v.join(',')
        } else {
          this.newItem[prop] = ''
        }
      },
      setImg(v, prop) {
        console.log('复制--')
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      resetForm() {
        this.newItem = {
          title: '',
          icon: '',
          title_type: '1', //1文本 2图片
          platform_num: 0,
          no_platform_num: 0,
          display_type: '1', //1 '横向展示' 2竖列
          sort_order: 1,
          status: '1', // 1开启 2关闭
        }
        this.closeimg('icon')
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.filterTableData()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.filterTableData()
      },
      showUploadProductDialog() {
        this.uploadProductDialog.visible = true
      },
      handleUploadConfirm() {
        this.$refs.uploadForm.validate((valid) => {
          if (valid) {
            // 处理上传逻辑
            postAction(
              '/api/act/category-conf/goods-edit',
              this.uploadProductDialog.form
            ).then((res) => {
              console.log('上传产品:', this.uploadProductDialog.form)
              this.uploadProductDialog.visible = false
              this._resetUploadForm()
              this.filterTableData(this.currenttab)
            })
          } else {
            console.log('表单验证失败')
            return false
          }
        })
      },
      _resetUploadForm() {
        this.uploadProductDialog.form = {
          conf_id: this.form.conf_id,
          cate_id: this.form.cate_id,
          type: '1',
          goods_name: '',
          goods_id: '',
          sort_order: 1,
          view_num: '',
          index_num: '',
        }

        this.time = []
      },
      remoteMethod(query) {
        if (query !== '') {
          this.uploadProductDialog.loading = true
          setTimeout(() => {
            this.uploadProductDialog.loading = false
            this.uploadProductDialog.options = this.catSelect
              .filter((item) => {
                return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
              })
              .map((item) => ({
                value: item.id,
                label: item.name,
              }))
          }, 200)
        } else {
          this.uploadProductDialog.options = []
        }
      },
    },
  }
</script>
