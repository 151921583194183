<script>
import { getAction } from "@/api/request";

export default {
  name: "edit",
  props:{
    userSelect:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {
      editshow: false,
      form: {
        htyun_name: "",
        htyun_kind: "",
        htyun_dept: "",
        wecom_staff_id: "",
        user_id: "",
      },
    };
  },
  methods: {
    handlersave() {
      getAction("/user/wechat-relation/edit", this.form).then(res=>{
          this.$message({
            type: 'success',
            message: '编辑成功!'
          });
          this.editshow = false
          this.$emit('handlerlist')
      })
    },
  }
};
</script>

<template>
<div>
  <el-dialog
    title="编辑"
    :visible.sync="editshow"
    width="30%">
    <span>
      <el-form ref="form" :model="form" label-width="140px">
        <el-form-item label="员工姓名：">{{form.htyun_name}}</el-form-item>
        <el-form-item label="员工一级事业部：">{{form.htyun_kind}}</el-form-item>
        <el-form-item label="员工二级部门：">{{form.htyun_dept}}</el-form-item>
        <el-form-item label="关联企微账号：">
          <el-select v-model="form.wecom_staff_id" filterable clearable style="width: 160px;" placeholder="员工姓名">
           <el-option
             v-for="list in userSelect"
             :key="list.staff_id"
             :label="list.staff_name"
             :value="list.staff_id"
           />
        </el-select>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="editshow = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<style scoped>

</style>
