<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="90%">
    <span>
      <el-form ref="form" inline label-suffix=":" :model="form">
        <el-form-item label="">
          <el-date-picker
            v-model="timelist"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getinfo">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerExporttow">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tabledata" v-loading="tableloading" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '广告图'">
              <el-image
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { getAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'corpper',
    props: {},
    data() {
      return {
        dialogVisible: false,
        title: '',
        tabledata: [],
        tableloading: false,
        form: {
          start_time: '',
          end_time: '',
        },
        timelist: [],
        columns: [
          {
            label: '姓名',
            prop: 'staff_user_name',
            width: '',
          },
          {
            label: '浏览店铺提醒(条)',
            prop: 'view_mall',
            width: '',
          },
          {
            label: '留言提醒(条)',
            prop: 'msg_remind',
            width: '',
          },
          {
            label: '电话提醒(条)',
            prop: 'tel_remind',
            width: '',
          },
          {
            label: '样品申请提醒(条)',
            prop: 'order_remind',
            width: '',
          },
          {
            label: '提醒总数(条)',
            prop: 'remind_num',
            width: '',
          },
          {
            label: '涉及经销商家数',
            prop: 'dealer_num',
            width: '',
          },
          {
            label: '提报企微需求',
            prop: 'biz_num',
            width: '',
          },
          {
            label: '需求转化率',
            prop: 'biz_rate',
            width: '',
          },
          {
            label: '当日通过总需求',
            prop: 'all_biz_num',
            width: '',
          },
          {
            label: '浏览提报需求占比',
            prop: 'view_biz_rate',
            width: '',
          },
        ],
        info: {},
      }
    },
    watch: {
      timelist(v) {
        console.log('------watch,', v)
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {},
    methods: {
      handlerExporttow() {
        // getAction('/api/form/ai-remind/export-detaillists', this.mallform).then(
        //   (res) => {
        //     this.$message.success('导出任务已添加到后台导出队列，请稍后查看')
        //   }
        // )
        getAction('/api/form/ai-remind/export-detaillists', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: '需求列表',
            })
          }
        )
      },

      getinfo() {
        this.tableloading = true

        getAction('/api/form/ai-remind/detail-lists', this.form).then((res) => {
          this.tabledata = res.data
          this.tableloading = false
          this.dialogVisible = true
        })
      },
    },
  }
</script>
