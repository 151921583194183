<template>
  <el-dialog content :visible.sync="showEditPoster" width="400px">
    <div>
      <el-form ref="form" :model="form">
        <el-form-item label="产品标题" prop="goods_id">
          <el-autocomplete
            style="width: 260px"
            v-model="goods_name"
            :fetch-suggestions="handlergoods"
            value-key="goods_name"
            clearable
            placeholder="搜索云交会产品名称"
            @select="handleSelectgoods"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="热搜指数" prop="name">
          <el-input
            style="width: 260px"
            v-model="form.index_num"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="榜单类型">
          <el-select
            v-model="form.type"
            placeholder="榜单类型"
            style="width: 160px"
          >
            <el-option
              v-for="list in typeSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showEditPoster = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction } from '@/api/request'

  export default {
    name: 'addedit',
    data() {
      return {
        showEditPoster: false,
        form: {
          type: '',
          index_num: '',
          goods_id: '',
          id: '',
        },
        goods_name: '',
        typeSelect: [
          {
            id: 1,
            name: '热搜榜',
          },
          {
            id: 2,
            name: '周榜',
          },
          {
            id: 3,
            name: '月榜',
          },
        ],
      }
    },
    watch: {
      showEditPoster(val) {
        if (!val) {
          this.form = {
            index_num: '',
            goods_id: '',
            id: '',
            type: '',
          }
          this.goods_name = ''
        }
      },
    },
    methods: {
      // 搜索产品
      handlergoods(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.goods_id = ''
        } else {
          getAction('/mall/product/goods', { goods_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelectgoods(list) {
        console.log(list)
        this.form.goods_id = list.id
      },
      handlersave() {
        // let param = {
        //   index_num: this.form.index_num,
        //   goods_id: this.form.goods_id,
        // }
        getAction('/api/form/search-rank/edit', this.form).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.$emit('getlist')
          this.showEditPoster = false
        })
      },
      handlerinfo(row, type) {
        this.form = Object.assign(this.form, row)
        this.form.type = type
        this.goods_name = this.form.goods_name
        this.form.goods_id = this.form.goods_id
      },
    },
  }
</script>

<style scoped></style>
