<script>
import { getAction, postAction } from "@/api/request";

export default {
  name: "bindgoods",
  props: {
    categoryList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    showbind(val){
      if(!val){
        this.type = 'binding'
        this.form = this.$options.data().form
      }
    },
  },
  data() {
    return {
      showbind: false,
      type: "binding",
      form: {
        title: "",
        goods_id: 0,
        category_id: "",
        id: "",
        sort:'',
        cover_img:'',
      }
    }
  },

  methods: {
    handlergoods(queryString, cb) {
      if (queryString == '') {
        cb([])
        this.form.goods_id = ''
      } else {
        getAction('/mall/product/goods', { goods_name: queryString }).then(
          (res) => {
            cb(res.data)
          }
        )
      }
    },
    handleSelectgoods(list) {
      console.log(list)
      this.$set(this.form, 'goods_id', list.id)
      // this.form.goods_id = list.id
      this.form.cover_img = list.goods_img
    },
    handlersave(){
      if(this.type=='binding'){
        if(this.form.goods_id==0){
          this.$emit('setform',this.form)
          this.showbind = false
        }else{
          postAction('/api/other/cate-rank/link-goods',this.form).then(res=>{
            if(res.code==0){
              this.$message({
                type: 'success',
                message: '保存成功!'
              });
              this.$emit('setform',this.form)
              this.showbind = false
            }else{
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
        }
      }else{
        if(this.form.goods_id==0){
          this.$message({
            type: 'error',
            message: '请选择商品!'
          });
        }else{
          postAction('/api/other/cate-rank/link-goods',this.form).then(res=>{
            if(res.code==0){
              this.$message({
                type: 'success',
                message: '保存成功!'
              });
              this.$emit('getlist')
              this.showbind = false
            }else{
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
        }
      }
    }
  }
};
</script>

<template>
<div>
  <el-dialog
    :visible.sync="showbind"
    append-to-body
    width="30%">
    <span>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="商品名称">
           <el-autocomplete
             v-model="form.title"
             clearable
             :fetch-suggestions="handlergoods"
             value-key="goods_name"
             placeholder="请输入商品名称"
             @select="handleSelectgoods"
           ></el-autocomplete>
          <el-button type="text" v-if="form.goods_id!='0'" @click="form.goods_id = 0">取消关联</el-button>
        </el-form-item>
        <el-form-item label="商品图片" v-show="form.cover_img">
          <el-image
            style="width: 100px; height: 100px"
            :src="form.cover_img"
            :preview-src-list="[form.cover_img]"
          ></el-image>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-select v-model="form.category_id" placeholder="请选择商品分类">
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showbind = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<style scoped>

</style>
