<!-- 海报编辑 -->
<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="50%">
      <div>
        <el-form :model="form" label-width="120px" :rules="rules" ref="form">
          <el-form-item label="模板类型" prop="title">
            <el-select
              v-model="form.type"
              clearable
              style="width: 160px"
              placeholder="请选择"
            >
              <el-option
                v-for="list in typeselect"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="海报名称" prop="title">
            <el-input
              maxlength="20"
              placeholder="请输入海报名称"
              v-model="form.title"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="海报图片" prop="pic">
            <upload-img
              ref="list_img"
              infoText="最多可上传5张"
              :limit="5"
              @uploadImg="uploadImg"
              @getImgs="getGoods_imgImgs($event, 'list_img')"
              :maxSize="100"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-switch
              v-model="form.status"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#ccc"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { uploadImg },
    data() {
      return {
        isShow: false,
        title: '编辑海报',
        form: {
          list_img: [],
        },
        imglist: [],
        rules: {
          title: [
            {
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            },
          ],
          list_img: [
            {
              required: true,
              message: '请上传图片',
              trigger: 'blur',
            },
          ],
        },
        typeselect: [
          {
            id: '1',
            name: '企业',
          },
          {
            id: '2',
            name: '产品',
          },
        ],
      }
    },
    watch: {
      isShow(n, o) {
        console.log('000000000-', this.form)
        if (n) {
          // this.imglist = this.form.list_img.join(',')
          this.imglist = this.form.list_img
          this.$nextTick(() => {
            this.setImgArray(this.form.list_img, 'list_img')
          })
        } else {
          this._onreset()
        }
      },
    },
    mounted() {},
    methods: {
      uploadImg(val) {
        console.log('uploadimg---', val)
        if (val.length < 1) {
          this.imglist = ''
        } else {
          this.imglist = val
        }
      },
      getGoods_imgImgs(v, prop) {
        if (v[0]) {
          this.form[prop] = v
        } else {
          this.form[prop] = ''
        }
      },
      handlersave() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            console.log('图片，，，', this.imglist)
            this.form.list_img = this.imglist.join(',')
            postAction('/api/afbc/act-company/poster-edit', this.form).then(
              (res) => {
                console.log('')
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.$emit('getlist')
                  this.isShow = false
                } else {
                  this.$message.error(res.msg)
                }
              }
            )
          }
        })
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.imglist = ''
        } else {
          this.imglist = val
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      setImgArray(v, prop) {
        console.log('图片组合', v, prop)
        if (v[0] != '') this.$refs[prop].img = v
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      _onreset() {
        this.form = {
          id: '',
          type: '',
          title: '',
          point: '',
          list_img: '',
          status: '0',
        }
        this.closeimg('list_img')
      },
    },
  }
</script>
