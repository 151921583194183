<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">

      <el-form-item>
        <el-input
          v-model="form.staff_user_name"
          clearable
          placeholder="提报人"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="客户姓名"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.external_user_name"
          clearable
          placeholder="客户昵称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.remark"
          clearable
          placeholder="备注"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="time"
          end-placeholder="提报结束日期"
          range-separator="至"
          start-placeholder="提报开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="timelist"
          end-placeholder="审核结束日期"
          range-separator="至"
          start-placeholder="审核开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
<!--      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option label="待审核" value="1" />
          <el-option label="已审核" value="2" />
          <el-option label="已拒绝" value="3" />
        </el-select>
      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '合作凭证'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '状态'">{{ row[item.prop]==1 ? '已通过' : row[item.prop]==3 ? '已拒绝' : '待审核' }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
<!--      <el-table-column align="center" fixed="right" label="操作" width="160">
        <template #default="{ row }">
          <div v-if="row.status == 2">
            <el-button size="small" type="text" @click="handlerpass(row, 1)">
              通过
            </el-button>
            <el-button size="small" type="text" @click="handlerpass(row, 3)">
              拒绝
            </el-button>
          </div>
            <el-button size="small" type="text" @click="handlerrefuse(row, 1)">
              详情
            </el-button>
        </template>
      </el-table-column>-->
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import dayjs from "dayjs";
  import * as excel from "@/utils/excel";

  export default {
    watch: {
      timelist(v) {
        if (v) {
          this.form.audit_start_time = v[0]
          this.form.audit_end_time = v[1]
        } else {
          this.form.audit_start_time = ''
          this.form.audit_end_time = ''
        }
      },
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    data() {
      return {
        form: {
          /**
           * 审核结束时间
           */
          audit_end_time: dayjs(new Date()).format('YYYY-MM-DD'),
          /**
           * 审核开始时间
           */
          audit_start_time: dayjs(new Date().getTime()-1000*60*60*24*30).format('YYYY-MM-DD'),
          /**
           * 提报结束日期
           */
          end_time: '',
          /**
           * 客户
           */
          external_user_name: '',
          name:'',
          page: 1,
          limit: 10,
          /**
           * 店铺名
           */
          mall_name: '',
          /**
           * 提报人
           */
          staff_user_name: '',
          /**
           * 提报开始日期
           */
          start_time: '',
          remark:'',
        },
        time:[],
        timelist:[dayjs(new Date().getTime()-1000*60*60*24*30).format('YYYY-MM-DD'),dayjs(new Date()).format('YYYY-MM-DD')],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '提报人',
            prop: 'staff_user_name',
            width: '',
          },
          {
            label: '客户名称',
            prop: 'name',
            width: '',
          },
          {
            label: '客户昵称',
            prop: 'external_user_name',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '合作凭证',
            prop: 'img',
            width: '220px',
          },
          {
            label: '备注',
            prop: 'remark',
            width: '',
          },
          {
            label: '提报时间',
            prop: 'created',
            width: '',
          },
          {
            label: '审核时间',
            prop: 'audit_time',
            width: '',
          },
          {
            label: '合计',
            prop: 'reward',
            width: '',
          },

        ],
        shopSelect: [
          {
            id: 1,
            name: '已通过',
          },
          {
            id: 2,
            name: '待审核',
          },
          {
            id: 3,
            name: '已拒绝',
          },
        ],
        total: 0,
        spaceSelect: [],
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlerExport() {
        postAction('/audit/biz-clue/export-cooprewardlists', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      // async handlerSelect() {
      //   await getAction('/audit/advert/position-config').then((res) => {
      //     this.shopSelect = res.data
      //   })
      // },
      handlerpass(row, type) {
       let messagedata
        type==1?messagedata='确认审核通过':messagedata='确认审核拒绝'
        this.$confirm(messagedata, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          if(type==1){
            postAction('/audit/biz-clue/coop-audit', { id: row.id, status: type }).then(
              (res) => {
                this.handlerInquire()
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
              }
            )
          }else{
            this.$prompt('请输入理由', '理由', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(({ value }) => {
              postAction('/audit/biz-clue/coop-audit', { id: row.id, status: type ,reason:value}).then(
                (res) => {
                  this.handlerInquire()
                  this.$message({
                    type: 'success',
                    message: res.msg,
                  })
                }
              )
            }).catch(() => {

            });
          }


        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });


      },
      handlerrefuse(row, type) {

      },

      handlerlist() {
        getAction('/audit/biz-clue/coopreward-lists', this.form).then((res) => {
          this.tabledata = res.data.list
          this.tabledata.map((item)=>{
            item.img=item.img.split(',')
          })
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
