<script>
import { getAction } from "@/api/request";

export default {
  name: "investigate",
  data() {
    return {
      investigateshow: false,
      data: {},
      form: {
        page: 1,
        limit: 10,
        id:'',
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      columns: [
        {
          label: "用户ID",
          prop: "user_id",
          width: ""
        },
        {
          label: "用户头像",
          prop: "headimg",
          width: ""
        },
        {
          label: "用户昵称",
          prop: "nickname",
          width: ""
        },
        {
          label: "是否解决",
          prop: "is_help",
          width: ""
        },
        {
          label: "创建时间",
          prop: "created",
          width: ""
        }
      ]
    };
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/api/system/help/info-tj', this.form).then((res) => {
        this.data = res.data
        this.total = Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    }
  }
};
</script>

<template>
  <el-dialog
    title="满意度调查"
    :visible.sync="investigateshow"
    width="50%">
    <span>
      <el-row :gutter="10">
       <el-col :span="6" style="text-align: center;padding: 10px;background: #e8f4ff;margin: 10px 10px">
         <div>问题已解决次数</div>
         <div>{{data.help}}</div>
       </el-col>
        <el-col :span="6" style="text-align: center;padding: 10px;background: #f7f9fd;margin: 10px 0px">
          <div>问题未解决次数</div>
          <div>{{data.no_help}}</div>
        </el-col>
      </el-row>
      <el-table :data="data.list" border style="width: 100%">
        <el-table-column v-for="(item, index) in columns"
                         :key="index" :label="item.label" :prop="item.prop" :width="item.width">
          <template slot-scope="scope">
            <span v-if="item.prop=='is_help'">{{scope.row[item.prop]==1?'已解决':'未解决'}}</span>
            <span v-else-if="item.prop=='headimg'">
              <img :src="scope.row[item.prop]" style="width: 40px;height: 40px;border-radius: 50%;margin-right: 10px"/>
            </span>
            <span v-else>{{scope.row[item.prop]}}</span>
          </template>
        </el-table-column>
      </el-table>
       <el-pagination
         background
         :current-page="form.page"
         :layout="layout"
         :page-size="form.limit"
         style="text-align: center; margin-top: 10px"
         :total="total"
         @current-change="handleCurrentChange"
         @size-change="handleSizeChange"
       />
    </span>
  </el-dialog>
</template>

<style scoped>

</style>
