var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: "", "label-suffix": ":", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "收件人姓名" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入收件人姓名" },
                model: {
                  value: _vm.form.recipient_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "recipient_name", $$v)
                  },
                  expression: "form.recipient_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收件人手机号" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入收件人手机号" },
                model: {
                  value: _vm.form.recipient_phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "recipient_phone", $$v)
                  },
                  expression: "form.recipient_phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发件人姓名" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入发件人姓名" },
                model: {
                  value: _vm.form.sender_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sender_name", $$v)
                  },
                  expression: "form.sender_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入商品名称" },
                model: {
                  value: _vm.form.goods_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "goods_name", $$v)
                  },
                  expression: "form.goods_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择商品类型" },
                  model: {
                    value: _vm.form.goods_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "goods_type", $$v)
                    },
                    expression: "form.goods_type",
                  },
                },
                _vm._l(_vm.goodsTypeArr, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "月份" } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  placeholder: "请选择月份",
                  type: "month",
                  "value-format": "yyyy年MM月",
                },
                model: {
                  value: _vm.form.month,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "month", $$v)
                  },
                  expression: "form.month",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "物流公司" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择物流公司" },
                  model: {
                    value: _vm.form.logistics_company_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "logistics_company_id", $$v)
                    },
                    expression: "form.logistics_company_id",
                  },
                },
                _vm._l(_vm.wuliuArr, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "物流单号" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入物流单号" },
                model: {
                  value: _vm.form.logistics_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "logistics_number", $$v)
                  },
                  expression: "form.logistics_number",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "付款方式" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择付款方式" },
                  model: {
                    value: _vm.form.pay_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "pay_type", $$v)
                    },
                    expression: "form.pay_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "寄付", value: "1" } }),
                  _c("el-option", { attrs: { label: "到付", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleImport } },
                [_vm._v("导入")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleExport } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleColumnSetting },
                },
                [_vm._v(" 列表字段设置 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-upload", {
        ref: "upload",
        attrs: {
          action: "",
          "before-upload": _vm.beforeUpload,
          "show-file-list": false,
        },
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "left",
              label: "收件人姓名",
              prop: "recipient_name",
              width: "120",
            },
          }),
          _vm._l(_vm.visibleColumns.slice(1), function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: "center",
                label: item.label,
                "min-width": "180",
                prop: item.prop,
              },
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showDeleteConfirm(row)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCheck(row)
                          },
                        },
                      },
                      [_vm._v("查看物流")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm.total > 10
        ? _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "20px" },
            attrs: {
              background: "",
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "列表字段设置",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.selectedColumns,
                callback: function ($$v) {
                  _vm.selectedColumns = $$v
                },
                expression: "selectedColumns",
              },
            },
            _vm._l(_vm.allColumns.slice(1), function (column) {
              return _c(
                "el-checkbox",
                { key: column.key, attrs: { label: column.key } },
                [_vm._v(" " + _vm._s(column.name) + " ")]
              )
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmColumnSetting },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-dialog", {
        ref: "addDialog",
        attrs: {
          "staff-list": _vm.staffSelect,
          "type-list": _vm.goodsTypeArr,
          "wuliu-list": _vm.wuliuArr,
        },
        on: { confirm: _vm.handleAddConfirm },
      }),
      _c("import-dialog", {
        ref: "importDialog",
        attrs: { "type-list": _vm.goodsTypeArr, "wuliu-list": _vm.wuliuArr },
        on: { "import-confirm": _vm.handleImportConfirm },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认删除",
            visible: _vm.deleteDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deleteDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确定要删除这条记录吗？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.confirmDelete } },
                [_vm._v("确认删除")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "logistics-dialog",
          attrs: {
            "close-on-click-modal": false,
            "destroy-on-close": true,
            title: "物流信息",
            top: "5vh",
            visible: _vm.logisticsDialogVisible,
            width: "375px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.logisticsDialogVisible = $event
            },
          },
        },
        [
          _c("iframe", {
            staticStyle: {
              "border-radius": "4px",
              overflow: "hidden",
              height: "80vh",
            },
            attrs: { frameborder: "0", src: _vm.logisticsUrl, width: "100%" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }