var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.editshow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.editshow = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "140px" },
                },
                [
                  _c("el-form-item", { attrs: { label: "员工姓名：" } }, [
                    _vm._v(_vm._s(_vm.form.htyun_name)),
                  ]),
                  _c("el-form-item", { attrs: { label: "员工一级事业部：" } }, [
                    _vm._v(_vm._s(_vm.form.htyun_kind)),
                  ]),
                  _c("el-form-item", { attrs: { label: "员工二级部门：" } }, [
                    _vm._v(_vm._s(_vm.form.htyun_dept)),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联企微账号：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "员工姓名",
                          },
                          model: {
                            value: _vm.form.wecom_staff_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wecom_staff_id", $$v)
                            },
                            expression: "form.wecom_staff_id",
                          },
                        },
                        _vm._l(_vm.userSelect, function (list) {
                          return _c("el-option", {
                            key: list.staff_id,
                            attrs: {
                              label: list.staff_name,
                              value: list.staff_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editshow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }