<template>
  <el-dialog
    :title="title"
    :visible.sync="addshow"
    width="40%"
  append-to-body>
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="图片">
          <upload-img ref="img" info-text="建议尺寸750*350" :limit="1" @getImgs="getGoods_imgImg($event, 'img')"/>
        </el-form-item>
        <el-form-item label="跳转类型">
          <el-select v-model="form.jump_type" placeholder="跳转类型" clearable  style="width: 160px;" @change="handlerjuptype">
            <el-option v-for="(list,index) in jumplist" :key="list.jump_id" :label="list.jump_page" :value="list.jump_id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="产品" v-if="form.jump_type==2">
          <el-autocomplete
            style="width: 160px;"
            v-model="form.ref_name"
            :fetch-suggestions="handlergoods"
            value-key="goods_name"
            placeholder="请输入商品名称"
            @select="handleSelectgoods"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="店铺" v-else-if="form.jump_type==1">
          <el-autocomplete
            style="width: 160px;"
            v-model="form.ref_name"
            :fetch-suggestions="handlermell"
            value-key="mall_name"
            placeholder="请输入店铺名称"
            @select="handleSelectmall"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item :label="jump_name" v-if="form.jump_type==12||form.jump_type==3" prop="advert_jump_params">
          <el-input  v-model="form.jump_params"  :placeholder="jump_name" style="width: 160px;"/>
        </el-form-item>
        <el-form-item label="排序">
          <el-input type="number" style="width: 160px;" v-model="form.sort_order" placeholder="排序"/>
        </el-form-item>


      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import UploadImg from "@/components/uploadImg";
import { nextTick } from "vue";
export default {
  components:{
    UploadImg
  },
  name: "Addbanner",
  data(){
    return{
      title:'添加banner',
      jump_name:'',
      addshow:false,
      jumplist:[],
      form:{
        actid:'',
        id:'',
        jump_type:'',
        sort_order:'',
        ref_name:'',
        jump_params:''
      }
    }
  },
  watch:{
    addshow(val){
      if(!val){
        this.close()
      }
    }
  },
  mounted() {
    this.handlerselect()
  },
  methods:{
    handlerselect() {
      postAction("/audit/advert/jump-type").then(res=>{
        this.jumplist=res.data
      })
    },
    handlerjuptype(e){
      this.form.jump_params=''
      this.jump_name=this.jumplist.filter(list=>list.jump_id==e)[0].jump_page
    },
    handlerinfo(row){
      nextTick(()=>{
        this.setImg(row.img,'img')
      })
      this.form=Object.assign(this.form,row)
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
    handleSelectmall(list){
      console.log(list)
      this.form.jump_params=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.form.jump_params=list.id
    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.jump_params=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.jump_params=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlersave(){
      postAction("/api/theme/banner/edit",this.form).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.$emit("getlist")
        this.close()
      })
    },
    show(){
      this.addshow=true
    },
    close(){
      this.addshow=false
      this.form={
        actid:'',
        id:'',
        jump_type:'',
        sort_order:'',
        ref_name:'',
        jump_params:''
      }
      this.closeimg('img')
    }
  }
};
</script>

<style scoped>

</style>
