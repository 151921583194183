<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="600px"
    top="13vh"
    :close-on-click-modal="false"
    append-to-body
    destroy-on-close
  >
    <div class="orderTest-container" v-loading="loading">
      <el-form
        :rules="rules"
        ref="form"
        :model="form"
        label-position="right"
        label-suffix=":"
        label-width="120px"
      >
        <el-form-item label="直播标题" prop="">
          <el-autocomplete
            v-model="form.title"
            :fetch-suggestions="handlerlive"
            placeholder="请输入直播标题"
            style="width: 300px"
            value-key="title"
            @select="handleSelect"
          />
          <span>可输入关键词模糊搜索</span>
        </el-form-item>
        <el-form-item label="直播栏目" prop="">
          <el-select
            style="width: 200px"
            v-model="form.type"
            filterable
            clearable
            placeholder=""
          >
            <el-option
              v-for="item in selectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="首页轮播图" prop="">
          <el-switch
            v-model="form.is_bannel"
            active-color="#13ce66"
            :active-value="'1'"
            inactive-color="#ccc"
            :inactive-value="'0'"
            active-text="是"
            inactive-text="否"
          />
          <upload-img
            v-if="form.is_bannel == 1"
            ref="uploadImage"
            info-text="尺寸：720 * 280像素,仅支持png、jpg格式图片"
            :limit="1"
            :max-size="100"
            @uploadImg="uploadImg"
          />
        </el-form-item>
        <el-form-item label="预告推荐" prop="">
          <el-switch
            v-model="form.is_herald_recom"
            active-color="#13ce66"
            :active-value="'1'"
            inactive-color="#ccc"
            :inactive-value="'0'"
            active-text="是"
            inactive-text="否"
          />
        </el-form-item>
        <el-form-item label="最新直播" prop="">
          <el-switch
            v-model="form.is_inlatestlive"
            active-color="#13ce66"
            :active-value="'1'"
            inactive-color="#ccc"
            :inactive-value="'0'"
            active-text="是"
            inactive-text="否"
          />
        </el-form-item>

        <el-form-item label="是否上架" prop="">
          <el-switch
            v-model="form.rel_status"
            active-color="#13ce66"
            :active-value="'1'"
            inactive-color="#ccc"
            :inactive-value="'0'"
            active-text="是"
            inactive-text="否"
          />
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            style="width: 120px"
            v-model="form.sort_order"
            type="number"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-button">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import uploadImg from '@/components/uploadImg'
  export default {
    name: 'AddEditLive',
    components: { uploadImg },
    data() {
      return {
        editType: 1,
        loading: false,
        showDialog: false,
        selectList: [],
        // 获取详情参数
        params: {},

        // 提交参数
        form: {
          id: '',
          type: '',
          live_id: '',
          status: '',
          jump_type_id: '',
          sort_order: '0',
        },
        rules: {
          // status: [{ required: true, message: '请选择状态' }],
          // live_id: [{ required: true, message: '请选择标题' }],
        },
        liveTitle: '',
      }
    },
    created() {},
    mounted() {},

    computed: {
      // liveTitle() {
      //   //  <el-option label="普通样式" :value="1"></el-option>
      //   // <el-option label="选品样式" :value="2"></el-option>
      //   // <el-option label="竖版直播样式" :value="3"></el-option>
      //   console.log(this.form.jump_type_id)
      //   // if(this.form.jump_type_id!=undefined){
      //     if (this.form.jump_type_id == 2) {
      //       return "选品日标题"
      //     } else {
      //       return "头条直播标题"
      //     // }
      //   }

      // },
      title() {
        if (this.editType == 1) {
          return '新增'
        } else {
          return '编辑'
        }
      },
    },
    watch: {
      'form.jump_type_id'(val) {
        console.log()
        if (val == 2) {
          this.liveTitle = '选品日标题'
        } else {
          this.liveTitle = '头条直播标题'
        }
      },
      showDialog(v) {
        if (v) {
        } else {
          this.form = { id: '', type: '', live_id: '', status: '' }
          this.params = {}
        }
      },
    },
    methods: {
      handlerlive(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          getAction('/live/index/live-search', {
            title: queryString,
          }).then((res) => {
            cb(res.data)
          })
        }
      },
      handleSelect(list) {
        console.log('---------', list)
        this.form.live_id = list.id
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.banner_img = ''
        } else {
          this.form.banner_img = val[0]
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      async fetchData(row) {
        try {
          let toutiaolist = await postAction('/live/index/type', {
            conf_id: 1,
          })
          this.selectList = toutiaolist.data
          console.log('直播栏目')
        } catch (err) {
          console.log('接口调用错误', err)
          this.loading = false
        }
      },
      handleSubmit() {
        let params = {
          id: this.form.id,
          conf_id: 1,
          sort_order: this.form.sort_order,
          is_bannel: this.form.is_bannel,
          type_id: this.form.type,
          live_id: this.form.live_id,
          status: this.form.rel_status,
          title: this.form.title,
          is_banner: this.form.is_banner,
          banner_img: this.form.banner_img || '',
          is_herald_recom: this.form.is_herald_recom,
          is_inlatestlive: this.form.is_inlatestlive,
        }
        if (this.editType == 1) {
          delete params.id
        }
        this.$refs.form.validate((v) => {
          if (v) {
            postAction('/live/index/edit-rel', params).then((res) => {
              this.$message.success(res.msg)
              this.$emit('refresh')
              this.showDialog = false
            })
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
