<!-- 关联产品 -->
<template>
  <div>
    <el-dialog title="关联产品" :visible.sync="isShow" width="90%">
      <div>
        <el-form ref="form" :model="form" inline>
          <el-form-item label="">
            <el-input
              v-model="form.goods_name"
              placeholder="商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="form.is_on_sale"
              clearable
              style="width: 160px"
              placeholder="请选择"
            >
              <el-option
                v-for="list in statusselect"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-button
              size="small"
              type="primary"
              @click.native.prevent="handlerlist()"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item label="">
            <el-button
              size="small"
              type="primary"
              @click.native.prevent="handleredit()"
            >
              新增产品
            </el-button>
          </el-form-item>
        </el-form>
        <el-table
          :data="tabledata"
          border
          ref="table"
          v-loading="loading"
          row-key="user_id"
        >
          <el-table-column
            prop="id"
            label="ID"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="goods_img"
            label="产品图片"
            align="center"
            width="100"
          >
            <template #default="{ row }">
              <el-image
                :preview-src-list="[row.goods_img]"
                :src="row.goods_img"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="goods_name"
            label="产品标题"
            align="center"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="point"
            label="产品卖点"
            align="center"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="created"
            label="创建时间"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="uv"
            label="访客数(UV)"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="pv"
            label="访问次数(PV)"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="sort_order"
            label="排序"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="is_on_sale"
            label="状态"
            align="center"
            width="90"
          >
            <template #default="{ row }">
              {{ row.is_on_sale == '1' ? '上架' : '下架' }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template #default="{ row }">
              <el-button
                size="small"
                style="margin: 10px"
                type="text"
                @click.native.prevent="handleredit(row)"
              >
                编辑
              </el-button>
              <el-popconfirm title="确认要删除吗？" @confirm="deleteRow(row)">
                <el-button slot="reference" size="small" type="text">
                  删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          :total="total"
          background
          style="text-align: center; margin-top: 10px"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </el-dialog>
    <productedit ref="productedit" @getlist="handlerlist"></productedit>
  </div>
</template>
<script>
  import { getAction } from '@/api/request'
  import productedit from './productedit.vue'
  export default {
    components: { productedit },
    data() {
      return {
        isShow: false,
        tabledata: [],
        loading: false,
        form: {
          page: 1,
          limit: 10,
          is_on_sale: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        statusselect: [
          {
            id: '1',
            name: '上架',
          },
          {
            id: '0',
            name: '下架',
          },
        ],
      }
    },
    methods: {
      handleredit(row) {
        if (row) {
          this.$refs.productedit.isShow = true
          this.$refs.productedit.handlerinfo(row, this.form.company_id)
        } else {
          this.$refs.productedit.isShow = true
          this.$refs.productedit.form.company_id = this.form.company_id
        }
      },
      handlerlist() {
        this.form.page = 1
        this.getlist()
      },
      // 产品列表
      handlerinfo(row) {
        this.form.company_id = row.id
        getAction('/api/afbc/act-company/product-lists', {
          company_id: row.id,
        }).then((res) => {
          if (res.code == 0) {
            this.tabledata = res.data
            this.total = res.totalCount
          }
        })
      },
      getlist() {
        getAction('/api/afbc/act-company/product-lists', this.form).then(
          (res) => {
            if (res.code == 0) {
              this.tabledata = res.data
              this.total = res.totalCount
            }
          }
        )
      },
      deleteRow(row) {
        getAction('/api/afbc/act-company/product-delete', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.handlerlist()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getlist()
      },
    },
  }
</script>
