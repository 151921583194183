<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="">
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.staff_user_id"
          clearable
          filterable
          placeholder="选择员工"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.staff_id"
            :value="list.staff_id"
            :label="list.staff_name"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="action" align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="getviews(scope.row)">
            查看明细
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <detail ref="detail" @getlist="handlerInquire" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import dateFormat from '@/utils/Timeformat'
  import detail from './components/detail.vue'
  export default {
    components: { detail },
    watch: {
      timelist(v) {
        console.log('------watch,', v)
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    data() {
      return {
        form: {
          staff_user_id: '',
          start_time: dateFormat(new Date()),
          end_time: dateFormat(new Date()),
          page: 1,
          limit: 10,
        },
        staffSelect: [],
        timelist: [dateFormat(new Date()), dateFormat(new Date())],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '日期',
            prop: 'date',
            width: '',
          },
          {
            label: '浏览店铺提醒(条)',
            prop: 'view_mall',
            width: '',
          },
          {
            label: '留言提醒(条)',
            prop: 'msg_remind',
            width: '',
          },
          {
            label: '电话提醒(条)',
            prop: 'tel_remind',
            width: '',
          },
          {
            label: '样品申请提醒(条)',
            prop: 'order_remind',
            width: '',
          },
          {
            label: '提醒总数(条)',
            prop: 'remind_num',
            width: '',
          },
          {
            label: '涉及经销商家数',
            prop: 'dealer_num',
            width: '',
          },
          {
            label: '发送员工数',
            prop: 'staff_num',
            width: '',
          },
          {
            label: '人均提醒(条)',
            prop: 'staff_average',
            width: '',
          },
          {
            label: '提报企微需求',
            prop: 'biz_num',
            width: '',
          },
          {
            label: '需求转化率',
            prop: 'biz_rate',
            width: '',
          },
          {
            label: '当日通过总需求',
            prop: 'all_biz_num',
            width: '',
          },
          {
            label: '浏览提报需求占比',
            prop: 'view_biz_rate',
            width: '',
          },
        ],
        total: 0,
        spaceSelect: [],
        calstatus: [], //外呼状态列表
      }
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      // 员工列表
      handlerSelect() {
        getAction('/api/form/ai-remind/staff-lists').then((res) => {
          console.log(res)
          this.staffSelect = res.data
        })
      },
      // 查看明细 TODO 加查看明细的组件
      getviews() {
        this.$refs.detail.dialogVisible = true
        console.log('====================', this.form)
        this.$refs.detail.form.start_time = this.form.start_time
        this.$refs.detail.form.end_time = this.form.end_time
        this.$refs.detail.timelist = this.timelist
        this.$refs.detail.getinfo()
      },

      handlerlist() {
        console.log('---', this.form, this.timelist)
        getAction('/api/form/ai-remind/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
