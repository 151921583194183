var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.isShow, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "120px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联云交会企业", prop: "mall_name" } },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          "fetch-suggestions": _vm.handlerhuose,
                          placeholder: "搜索企业名称",
                          "value-key": "mall_name",
                        },
                        on: { select: _vm.handleSelecthuose },
                        model: {
                          value: _vm.form.mall_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mall_name", $$v)
                          },
                          expression: "form.mall_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业分类", prop: "cat_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.handlerjuptype },
                          model: {
                            value: _vm.form.cat_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cat_id", $$v)
                            },
                            expression: "form.cat_id",
                          },
                        },
                        _vm._l(_vm.cateSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { value: list.id, label: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品", prop: "goodsid" } },
                    [
                      _vm._l(_vm.goodslist, function (list, index) {
                        return _c(
                          "div",
                          { key: index, staticStyle: { "margin-top": "10px" } },
                          [
                            _c(
                              "div",
                              [
                                _c("el-autocomplete", {
                                  attrs: {
                                    "fetch-suggestions": _vm.handlergoods,
                                    placeholder: "请输入标题搜索",
                                    "value-key": "goods_name",
                                  },
                                  on: {
                                    select: function ($event) {
                                      return _vm.handleSelectgoods(
                                        $event,
                                        index
                                      )
                                    },
                                  },
                                  model: {
                                    value: list.goods_name,
                                    callback: function ($$v) {
                                      _vm.$set(list, "goods_name", $$v)
                                    },
                                    expression: "list.goods_name",
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "150px",
                                        height: "150px",
                                        "margin-top": "10px",
                                      },
                                      attrs: { src: list.goods_img },
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-circle-plus-outline",
                                      staticStyle: {
                                        margin: "-30px 10px 0 60px",
                                        "font-size": "24px",
                                      },
                                      on: { click: _vm.handelradd },
                                    }),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.goodslist.length > 1,
                                          expression: "goodslist.length > 1",
                                        },
                                      ],
                                      staticClass: "el-icon-remove-outline",
                                      staticStyle: {
                                        "margin-top": "-30px",
                                        "font-size": "24px",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlerdelet(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _vm.goodslist.length == 0
                        ? _c("div", [
                            _c("i", {
                              staticClass: "el-icon-circle-plus-outline",
                              staticStyle: {
                                margin: "5px 10px 0 20px",
                                "font-size": "24px",
                              },
                              on: { click: _vm.handelradd },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "展馆名称", prop: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入展馆名称", type: "text" },
                        model: {
                          value: _vm.form.number_house,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "number_house", $$v)
                          },
                          expression: "form.number_house",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "展位号", prop: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入展位号", type: "text" },
                        model: {
                          value: _vm.form.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "number", $$v)
                          },
                          expression: "form.number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专属报名链接", prop: "afbc_url" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "https://", type: "text" },
                        model: {
                          value: _vm.form.afbc_url,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "afbc_url", $$v)
                          },
                          expression: "form.afbc_url",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          placeholder: "数字越大靠前排",
                          type: "number",
                        },
                        model: {
                          value: _vm.form.sort_order,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort_order", $$v)
                          },
                          expression: "form.sort_order",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }