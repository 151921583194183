<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="60%">
    <span>
      <el-button type="primary" @click="handlerExporttow">导出</el-button>
      <el-table :data="tabledata" v-loading="tableloading" style="width: 100%">
        <el-table-column prop="d" label="日期" align="center"></el-table-column>
        <el-table-column
          prop="mall_id"
          label="店铺ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mall_name"
          label="店铺名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="all_pv"
          label="访问页面数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pv"
          label="真实访问页面数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="uv"
          label="访问产品/店铺用户数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cat_root_name"
          label="一级品类"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cat_name"
          label="二级品类"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="meal_title"
          label="当前套餐"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        :current-page="mallform.page"
        :layout="layout"
        :page-size="mallform.limit"
        :total="total"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="
          (e) => {
            handleCurrentChange(e)
          }
        "
        @size-change="
          (e) => {
            handleSizeChange(e)
          }
        "
      ></el-pagination>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { VueCropper } from 'vue-cropper'
  import { getAction } from '@/api/request'
  export default {
    name: 'corpper',
    props: {},
    data() {
      return {
        dialogVisible: false,
        title: '',
        tabledata: [],
        tableloading: false,
        mallform: {
          page: 1,
          limit: 10,
        },
        total: 0,
        layout: 'total, prev, pager, next, jumper',
        info: {},
      }
    },
    watch: {
      dialogVisible(n, o) {
        this.mallform.page = 1
      },
    },
    mounted() {},
    methods: {
      handlerExporttow() {
        getAction('/api/form/day-data/mall-view-id-export', this.mallform).then(
          (res) => {
            this.$message.success('导出任务已添加到后台导出队列，请稍后查看')
          }
        )
      },
      getinfo(info) {
        this.tableloading = true
        this.info = info
        this.mallform = {
          page: this.mallform.page,
          limit: this.mallform.limit,
          mall_id: info.id,
          start_date: info.start_date,
          end_date: info.end_date,
        }
        getAction('/api/form/day-data/mall-view-id', this.mallform).then(
          (res) => {
            this.tabledata = res.data
            this.tableloading = false
            this.dialogVisible = true
            this.total = res.totalCount
          }
        )
      },
      handleCurrentChange(val) {
        this.mallform.page = val
        this.getinfo(this.info)
      },
      handleSizeChange(val) {
        this.mallform.limit = val
        this.getinfo(this.info)
      },
    },
  }
</script>
