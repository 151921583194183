<template>
  <div style="padding: 20px">
    <h3>品类统计</h3>
    <div>
      <el-date-picker
        v-model="dateRange"
        range-separator="-"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="margin-right: 10px; width: 260px"
      ></el-date-picker>
      <!-- 添加品类下拉框 -->
      <el-select
        v-model="form.cate_id"
        placeholder="请选择品类"
        clearable
        style="margin-right: 10px; width: 120px"
      >
        <el-option
          v-for="category in catSelect"
          :key="category.id"
          :label="category.name"
          :value="category.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="form.is_source"
        placeholder="是否手动设置"
        clearable
        style="margin-right: 10px; width: 130px"
      >
        <el-option
          v-for="category in sourceSelect"
          :key="category.id"
          :label="category.name"
          :value="category.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="form.is_platform"
        placeholder="是否平台产品"
        clearable
        style="margin-right: 10px; width: 130px"
      >
        <el-option
          v-for="category in platformSelect"
          :key="category.id"
          :label="category.name"
          :value="category.id"
        ></el-option>
      </el-select>
      <el-input
        placeholder="店铺名称"
        v-model="form.mall_name"
        style="margin-right: 10px; width: 160px"
        clearable
      ></el-input>
      <el-input
        placeholder="产品名称"
        v-model="form.goods_name"
        style="margin-right: 10px; width: 160px"
        clearable
      ></el-input>
      <!-- 添加搜索按钮 -->
      <el-button type="primary" @click="handleSearch">搜索</el-button>
    </div>
    <div
      style="line-height: 28px; margin: 15px 0; color: #666; font-size: 15px"
    >
      <i class="el-icon-s-data" style="color: rgb(33, 177, 225)"></i>
      页面总PV数 {{ total_pv }} &nbsp;&nbsp;&nbsp;&nbsp;页面总UV数
      {{ total_uv }}
    </div>
    <div class="totalstyle">
      <div
        v-for="(item, index) in totalconf"
        :key="index"
        :class="{ active: activeIndex === index }"
        @click="setActiveIndex(index)"
      >
        <p>{{ item.title }}</p>
        <p>{{ item.count }}次</p>
        <p>点击数</p>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      border
      @sort-change="sortChange"
    >
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="logo" label="Logo">
        <template slot-scope="scope">
          <img
            :src="scope.row.logo"
            alt="logo"
            style="width: 50px; height: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_name"
        label="标题"
        v-if="activeIndex != 1"
      ></el-table-column>
      <el-table-column
        prop="cat_name"
        label="品类"
        v-if="activeIndex != 1"
      ></el-table-column>
      <el-table-column prop="mall_name" label="店铺"></el-table-column>
      <el-table-column prop="is_auto" label="是否手动设置"></el-table-column>
      <el-table-column
        prop="is_platform"
        label="是否平台产品"
      ></el-table-column>
      <el-table-column label="生效时间">
        <template slot-scope="scope">
          <span v-if="scope.row.start_time">
            {{ scope.row.start_time }}至{{ scope.row.end_time }}
          </span>
          <span v-else>{{ scope.row.is_auto == '是' ? '永久有效' : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态"></el-table-column>
      <el-table-column
        prop="pv"
        label="点击数"
        sortable="custom"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    data() {
      return {
        loading: false,
        dateRange: [],
        pickerOptions: {
          shortcuts: [
            {
              text: '昨日',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        // 添加品类选项
        categories: [
          { value: 'category1', label: '品类1' },
          { value: 'category2', label: '品类2' },
          // 其他品类选项
        ],
        selectedCategory: '',
        tableData: [
          // 示例数据
          {
            id: 1,
            logo: 'https://via.placeholder.com/50',
            title: '示例标题1',
            category: '示例品类1',
            store: '示例店铺1',
            isManual: true,
            isPlatform: false,
            effectiveTime: '2023-01-01',
            status: '启用',
            clickCount: 100,
          },
          {
            id: 2,
            logo: 'https://via.placeholder.com/50',
            title: '示例标题2',
            category: '示例品类2',
            store: '示例店铺2',
            isManual: false,
            isPlatform: true,
            effectiveTime: '2023-02-01',
            status: '禁用',
            clickCount: 200,
          },
        ],
        totalconf: [],
        total_pv: 0,
        total_uv: 0,
        activeIndex: 1, // 添加 activeIndex 状态
        form: {
          page: 1,
          limit: 10,
          conf_id: '1',
          cate_id: '',
          is_platform: '',
          is_source: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        catSelect: [],
        sourceSelect: [
          {
            id: '1',
            name: '是',
          },
          {
            id: '2',
            name: '否',
          },
        ],
        platformSelect: [
          {
            id: '1',
            name: '是',
          },
          {
            id: '2',
            name: '否',
          },
        ],
      }
    },
    watch: {
      dateRange(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.getTotal()
      this.handleSearch()
      this.getCate()
    },
    methods: {
      //排序 升序or降序
      sortChange({ column, prop, order }) {
        console.log('column, prop, order', column, prop, order)
        // this.form.order = prop
        // 值 asc 小到大  和  desc  大到小]
        if (order === 'descending') {
          this.form.order = 1 //'desc'
        } else if (order === 'ascending') {
          this.form.order = 2 //'asc'
        } else {
          // this.form.sort = ''
          this.form.order = ''
        }
        this.fetchData()
      },
      getCate() {
        getAction('/user/index/cate').then((res) => {
          this.catSelect = res.data
        })
      },
      // 添加搜索方法
      handleSearch() {
        // 这里添加查询接口的逻辑
        console.log('搜索条件:', this.dateRange, this.selectedCategory)
        this.getTotal()
        // 调用接口刷新列表数据
        this.fetchData()
      },
      getTotal() {
        getAction('/api/act/category-conf/total-tj', this.form).then((res) => {
          this.total_pv = res.data.total_pv
          this.total_uv = res.data.total_uv
          this.totalconf = res.data.conf
        })
      },
      fetchData() {
        this.loading = true

        getAction('/api/act/category-conf/goods-list-tj', this.form).then(
          (res) => {
            this.loading = false
            this.tableData = res.data.list
            this.total = res.totalCount
          }
        )
      },
      setActiveIndex(index) {
        this.activeIndex = index
        this.form.conf_id = this.totalconf[index].id
        this.handleSearch()
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.fetchData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  /* 添加样式 */
  .totalstyle {
    width: 100%;
    cursor: pointer;
    div {
      text-align: center;
      display: inline-block;
      width: 16.6%;
      &.active {
        color: rgb(33, 177, 225);
      }
      p:nth-child(2) {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
</style>
