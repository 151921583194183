<template>
  <div v-loading="loading" style="border: 1px solid #ccc">
    <Toolbar
      :default-config="toolbarConfig"
      :editor="editor"
      :mode="mode"
      style="border-bottom: 1px solid #ccc"
    />
    <Editor
      v-model="html"
      :default-config="editorConfig"
      :default-content="defaultContent"
      :mode="mode"
      style="overflow-y: hidden"
      :style="'height: ' + height + 'px;'"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
  import Vue from 'vue'
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import { uploadAction } from '@/api/request'
  export default Vue.extend({
    components: { Editor, Toolbar },
    props: {
      height: {
        type: [String, Number],
        default: '500',
      },
      disable: {
        type: Boolean,
        default: false,
      },
      uploadImgSize: {
        type: Number,
        default: 10,
      },
    },
    data() {
      return {
        editor: null,
        loading: false,
        html: '',
        toolbarConfig: {
          excludeKeys: ['group-video'],
        },
        defaultContent: [
          {
            type: 'paragraph',
            lineHeight: '1.8',
            children: [{ text: '', fontFamily: '黑体', fontSize: '18px' }],
          },
        ],
        editorConfig: {
          placeholder: '请输入内容...',
          MENU_CONF: {
            fontSize: {
              fontSizeList: [
                '12px',
                '13px',
                '14px',
                '15px',
                '16px',
                '18px',
                '20px',
                '22px',
                '24px',
                '26px',
                '28px',
                '30px',
                '32px',
                '34px',
                '36px',
                '38px',
                '40px',
                '42px',
                '44px',
                '46px',
                '48px',
              ],
            },
            lineHeight: {
              lineHeightList: [
                '1',
                '1.15',
                '1.2',
                '1.5',
                '1.6',
                '1.8',
                '2',
                '2.5',
              ],
            },
            uploadImage: {
              server: '/api/index/upload-wang',
              fieldName: 'flie',
              allowedFileTypes: ['image/*'],
              onError: this.uploadImgError,
              customUpload: this.customUpload,
            },
          },
        },
        mode: 'default', // or 'simple'
      }
    },
    watch: {
      disable(v) {
        if (v) {
          this.editor.disable()
          console.log('this.disable', this.disable)
        } else {
          console.log('this.disable22222222', this.disable)
          this.editor.enable()
        }
      },
      html(val) {
        this.$emit('getHtmlChang', val)
      },
    },
    mounted() {
      // 模拟 ajax 请求，异步渲染编辑器
    },

    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },
      uploadImgError(file, err, res) {
        this.$message.error(`上传出错,上传图片最大为${this.uploadImgSize}M`)
        console.log(
          `${file.name} 上传出错,上传图片最大为${this.uploadImgSize}M`,
          err,
          res
        )
      },
      setHtml(p) {
        this.html = p
      },
      clearHtml() {
        this.html = ''
      },
      isKong() {
        return this.editor.isEmpty()
      },
      editorO() {
        return this.editor
      },
      getHtmlM() {
        let h = (this.editorO() && this.editorO().getHtml()) || ''
        console.log(this.editorO().getHtml())
        let content = h.replace(/<img[^>]*>/gi, (match, capture) => {
          return match.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/gi, '')
        })
        return content

        // let re = new RegExp('style=""', "g")
        // return h.replace(re, "")
      },
      // 自定义上传图片
      customUpload(file, insertFn) {
        if (file.size > this.uploadImgSize * 1024 * 1024) {
          this.$message.error('上传图片最大是' + this.uploadImgSize + 'M')
          return false
        } else {
          this.loading = true
          uploadAction('/api/index/upload-wang', { file: file }).then((e) => {
            if (e.request_code == 200) {
              console.log('eee', e)
              insertFn(e.data.url, file.name, e.data.url)
              this.loading = false
            } else {
              this.loading = false
              this.$message.error('上传失败')
            }
          })
        }
      },
    },
  })
</script>

<style scoped></style>
