var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.form } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", filterable: "", placeholder: "部门" },
                  on: { change: _vm.handlerbumen },
                  model: {
                    value: _vm.form.htyun_dept,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "htyun_dept", $$v)
                    },
                    expression: "form.htyun_dept",
                  },
                },
                _vm._l(_vm.bumenSelect, function (list, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: list.htyun_dept, value: list.htyun_dept },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "邀请人",
                  },
                  model: {
                    value: _vm.form.invite_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "invite_name", $$v)
                    },
                    expression: "form.invite_name",
                  },
                },
                _vm._l(_vm.staffSelect, function (list) {
                  return _c("el-option", {
                    key: list.userid,
                    attrs: { label: list.htyun_name, value: list.htyun_name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "奖励结束时间",
                  format: "yyyy-MM",
                  "start-placeholder": "奖励开始时间",
                  type: "monthrange",
                  "value-format": "yyyy-MM",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v("导 出")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExportdetail },
                },
                [_vm._v(" 导出全部明细 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "海报背景"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticStyle: {
                                      width: "30px",
                                      height: "70px",
                                    },
                                    attrs: {
                                      "preview-src-list": [row[item.prop]],
                                      src: row[item.prop],
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "placeholder" },
                                        slot: "placeholder",
                                      },
                                      [
                                        _vm._v(" 加载中 "),
                                        _c("span", { staticClass: "dot" }, [
                                          _vm._v("..."),
                                        ]),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : item.label == "海报分类"
                          ? _c("div", [_vm._v(_vm._s(row.type.name))])
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              align: "center",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(row)
                          },
                        },
                      },
                      [_vm._v("查看明细")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "明细信息",
            visible: _vm.dialogVisible,
            width: "85%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { display: "flex" } }, [
            _c("p", [_vm._v("部门: " + _vm._s(_vm.detailinfo.htyun_dept))]),
            _c("p", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("邀请人: " + _vm._s(_vm.detailinfo.htyun_name)),
            ]),
            _c("p", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("时间: " + _vm._s(_vm.detailinfo.time)),
            ]),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.poploading,
                  expression: "poploading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.detailData },
            },
            [
              _c("el-table-column", {
                attrs: { label: "昵称", prop: "nickname", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "经销商公司名称",
                  prop: "company_name",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "渠道",
                  prop: "company_channel",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "客户电话", prop: "phone", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "留言类型", prop: "type", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "店铺", prop: "mall_name", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "产品", prop: "goods_name", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "留言内容", prop: "content", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "客户地区", prop: "city", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "留言时间", prop: "add_time", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "企微好友关系",
                  prop: "friend",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "留言查看时间",
                  prop: "scan_time",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "邀请人", prop: "htyun_name", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "符合奖励", prop: "is_award", align: "center" },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.pagedetail,
              layout: _vm.layout,
              "page-size": _vm.limitdetail,
              total: _vm.totaldetail,
            },
            on: {
              "current-change": _vm.handleCurrentChangedetail,
              "size-change": _vm.handleSizeChangedetail,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerExportdetail(1)
                    },
                  },
                },
                [_vm._v(" 导 出 ")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeDialog } },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }