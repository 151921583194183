<template>
  <el-dialog
    :title="title"
    :visible.sync="showadd"
    width="60%"
    :before-close="onClose"
  >
    <div>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="标题"
            clearable
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="类型" prop="jump_type">
          <el-select
            v-model="form.jump_type"
            clearable
            placeholder="类型"
            style="width: 160px"
          >
            <el-option
              v-for="item in jumplist"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.jump_type == 1" label="跳转店铺" prop="author">
          <el-autocomplete
            style="width: 160px"
            v-model="form.mall_name"
            :fetch-suggestions="handlerarticle"
            value-key="mall_name"
            placeholder="关联店铺"
            @select="handleSelectarticle"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item v-if="form.jump_type == 2" label="跳转产品" prop="author">
          <el-autocomplete
            v-model="form.goods_name"
            :fetch-suggestions="handlerhuose"
            placeholder="搜索产品名称"
            value-key="goods_name"
            @select="handleSelecthuose"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item v-if="form.jump_type == 3" label="H5链接" prop="author">
          <el-input
            v-model="form.jump_object"
            placeholder="请输入H5链接"
            style="width: 260px"
          />
        </el-form-item>
        <el-form-item v-if="form.jump_type == 5" label="热点文章" prop="author">
          <el-input
            v-model="form.jump_object_name"
            placeholder="请选择热点文章"
            style="width: 400px"
          />
        </el-form-item>
        <el-form-item label="背景图" prop="pic">
          <upload-img
            ref="pic"
            :infoText="''"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'pic')"
            :maxSize="100"
          />
        </el-form-item>
        <el-form-item label="起始时间">
          <el-date-picker
            v-model="time"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
            style="width: 340px"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="form.sort"
            placeholder="数字越大越靠前"
            style="width: 120px"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            v-model="form.status"
            :active-value="'1'"
            :inactive-value="'0'"
            active-color="#13ce66"
            inactive-color="#ccc"
          ></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import uploadImg from '@/components/uploadImg'
  import WangEditor from '@/components/WangEditor'
  import { nextTick } from 'vue'

  export default {
    name: 'AddEdit',
    components: {
      uploadImg,
      WangEditor,
    },
    data() {
      return {
        showadd: false,
        title: '',
        shopSelect: [],
        form: {
          id: null,
          type: '5',
          title: '',
          sort: 0,
          pic: '',
          jump_type: '5',
          jump_object: '',
          start_time: '',
          end_time: '',
          status: '1',
        },
        jumplist: [
          {
            id: '1',
            name: '店铺',
          },
          {
            id: '2',
            name: '产品详情页',
          },
          {
            id: '3',
            name: 'H5链接',
          },
          {
            id: '4',
            name: '无跳转',
          },
          {
            id: '5',
            name: '热点文章',
          },
        ],
        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    methods: {
      handlerarticle(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.mall_id = ''
        } else {
          getAction('/mall/index/mall', { mall_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelectarticle(list) {
        console.log(list)
        this.form.jump_object = list.id
      },
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      handlerinfo(row) {
        this.form = Object.assign(this.form, row)
        this.time[0] = this.form.start_time
        this.time[1] = this.form.end_time
        nextTick(() => {
          this.setImg(row.pic, 'pic')
        })
      },
      handlersave() {
        console.log('编辑信息，', this.form)
        postAction('/api/index/banner-edit', this.form).then((res) => {
          if (res.code == 0) {
            this.$message.success('成功')
            this.$emit('getlist')
          } else {
            this.$message.error(res.msg)
          }

          this.onClose()
        })
      },
      handlerhuose(queryString, cb) {
        console.log('测试----', queryString)
        getAction('/mall/product/goods', {
          goods_name: queryString,
        }).then((res) => {
          cb(res.data)
        })
      },
      // 关联产品选择
      handleSelecthuose(list) {
        console.log('--', list)
        this.form.jump_object = list.id
        // this.form.goods_img = list.goods_img
      },
      onClose() {
        this.showadd = false
        this._onreset()
      },
      _onreset() {
        this.closeimg('pic')
        this.form = {
          id: null,
          type: '5',
          title: '',
          sort: 0,
          pic: '',
          jump_type: '5',
          jump_object: '',
          start_time: '',
          end_time: '',
          status: '1',
        }
        this.time = []
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
    },
  }
</script>
