<template>
  <div class="htmember">
    <el-form ref="form" :model="form">
      <div class="formHeader">
        <el-form-item>
        <el-select
          v-model="form.htyun_kind"
          @change="kindChange"
          clearable
          filterable
          placeholder="一级部门"
          style="width: 230px"
        >
          <el-option
            v-for="list in kindselect"
            :key="list.htyun_kind"
            :label="list.htyun_kind"
            :value="list.htyun_kind"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.htyun_dept"
          clearable
          filterable
          placeholder="二级部门"
          style="width: 200px"
        >
          <el-option
            v-for="list in departselect"
            :key="list.htyun_dept"
            :label="list.htyun_dept"
            :value="list.htyun_dept"
          />
        </el-select>
      </el-form-item>
        <el-form-item label="">
        <!-- <el-input v-model="form.htyun_name" placeholder="邀请人"></el-input> -->
        <el-select
          v-model="form.htyun_name"
          clearable
          filterable
          placeholder="邀请人"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.htyun_name"
            :label="list.htyun_name"
            :value="list.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="payTime"
          value-format='yyyy-MM-dd'
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
      
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button type="primary" @click="onExport()">导出</el-button>
      </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="list"
      border
      ref="table"
      v-loading="loading"
      row-key="user_id"
    >
      <el-table-column prop="userid" label="ID" align='center' width="90"></el-table-column>
      <el-table-column prop="htyun_kind" label="一级部门" align='center'></el-table-column>
      <el-table-column prop="htyun_dept" label="部门" align='center'></el-table-column>
      <el-table-column prop="htyun_name" label="邀请人" align='center'></el-table-column>
      <el-table-column prop="friend_num" label="分享好友数"></el-table-column>
      <el-table-column prop="circle_num" label="分享朋友圈数" align='center'></el-table-column>
      <el-table-column prop="invite_pv" label="邀请来pv" align='center'></el-table-column>
      <el-table-column prop="invite_uv" label="邀请来uv" align='center'></el-table-column>
      <el-table-column prop="report_pv" label="报名页pv" align='center'></el-table-column>
      <el-table-column prop="report_uv" label="报名页uv" align='center'></el-table-column>
      <el-table-column prop="complete" label="完成报名" align='center'></el-table-column>
      <el-table-column prop="check_num" label="审核通过" align='center'></el-table-column>
      
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      style="text-align: center; padding: 20px 0"
    ></el-pagination>
    
  </div>
</template>
<script>
  import { postAction } from "@/api/request";
  import * as excel from '@/utils/excel'
export default {
  name: "forward",
  components: { },
  data() {
    return {
      loading: false,
      form:{
        htyun_name:'', //邀请人
        htyun_kind:'', //一级部门
        htyun_dept:'', //二级部门
        start_time:'',
        end_time:'',
        page:1,
        limit:10
      },
      kindselect: [], //一级部门
      departselect: [], //二级部门
      staffSelect:[], //员工
      payTime:'',
      expireTime:'',
      list: [],
      total:0,
      layout: "total, sizes, prev, pager, next , jumper",
      total: 0,
    }
  },
  mounted() {
    this.getlist();
    this.getKind(); //获取一级部门
    this.getstaff(); //获取员工
  },
  methods: {
    getlist(){
      let that = this;
      postAction("/api/afbc/staff-mall/forward",that.form).then(res=>{
        // console.log('ztq',res.data)
        if(res.code == 0){
          that.list = res.data;
          that.total = res.totalCount;
        }else{
          that.$message.error(res.msg)
        }
      }).catch(res=>{
        that.$message.error(res.msg)
      })
    },
    getKind() {
      postAction('/api/form/form/dept-one').then((res) => {
        if (res.request_code == 200) {
          this.kindselect = res.data
        }
      })
    },
    getDepart() {
      postAction('/form/form/dept',{htyun_kind:this.form.htyun_kind}).then((res) => {
        if (res.request_code == 200) {
          this.departselect = res.data
          this.form.htyun_dept = res.data[0].htyun_dept
        }
      })
    },
    getstaff(){
      let that = this;
      let d = {
        htyun_kind:this.form.htyun_kind,
        htyun_dept:this.form.htyun_dept,
        htyun_name:this.form.htyun_name
      }
      postAction('/form/form/staff',d).then((res) => {
        if (res.request_code == 200) {
          this.staffSelect = res.data
        }
      })
    },
    kindChange(value){
      let that = this;
      console.log('ztq',value)
      if(value == ''){
        this.form.htyun_dept = ''
        this.departselect = [];
        return false;
      }
      that.getDepart();
    },
    onSubmit(){
      console.log(this.payTime)
      if(this.payTime!=null && this.payTime!=''){
        this.form.start_time=this.payTime[0];
        this.form.end_time = this.payTime[1];
      }else{
        this.form.start_time='';
      this.form.end_time = '';
      }
      
      this.form.page = 1;
      this.getlist();
    },
    handleCurrentChange(val){
      console.log(val)
      this.form.page = val;
      this.getlist();
    },
    handleSizeChange(val) {
      this.form.page = 1
      this.form.limit = val
      this.getlist();
    },
    onExport(){
      this.loading = true
        postAction('/api/afbc/staff-mall/forward-export', this.form).then((res) => {
          console.log('ztq',res)
          this.loading = false
        })
    },
  }
}
</script>
<style lang="scss" scoped>
  .formHeader{
    display: flex;
    padding: 20px;
    .el-form-item{
      margin-right: 15px;
    }
  }
</style>