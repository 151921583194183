<!-- 关联云交会企业 -->
<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="50%">
      <div>
        <el-form :model="form" label-width="120px" :rules="rules" ref="form">
          <el-form-item label="关联云交会企业" prop="mall_name">
            <el-autocomplete
              v-model="form.mall_name"
              :fetch-suggestions="handlerhuose"
              placeholder="搜索企业名称"
              value-key="mall_name"
              @select="handleSelecthuose"
            />
          </el-form-item>
          <el-form-item label="企业分类" prop="cat_id">
            <el-select
              v-model="form.cat_id"
              clearable
              style="width: 160px"
              placeholder="请选择"
              @change="handlerjuptype"
            >
              <el-option
                v-for="list in cateSelect"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="产品" prop="goodsid">
            <div
              v-for="(list, index) in goodslist"
              :key="index"
              style="margin-top: 10px"
            >
              <div>
                <el-autocomplete
                  v-model="list.goods_name"
                  :fetch-suggestions="handlergoods"
                  placeholder="请输入标题搜索"
                  value-key="goods_name"
                  @select="handleSelectgoods($event, index)"
                />
                <br />
                <span style="display: flex">
                  <!-- <upload-img
                    :ref="'uploadImage' + index"
                    infoText=""
                    @uploadImg="uploadImg"
                    :maxSize="100"
                  /> -->
                  <el-image
                    :src="list.goods_img"
                    style="width: 150px; height: 150px; margin-top: 10px"
                  >
                    <!-- <div slot="placeholder" class="image-slot">
                      加载中
                      <span class="dot">...</span>
                    </div>
                    <div slot="error" class="image-slot"></div> -->
                  </el-image>

                  <i
                    class="el-icon-circle-plus-outline"
                    style="margin: -30px 10px 0 60px; font-size: 24px"
                    @click="handelradd"
                  ></i>
                  <i
                    v-show="goodslist.length > 1"
                    class="el-icon-remove-outline"
                    style="margin-top: -30px; font-size: 24px"
                    @click="handlerdelet(index)"
                  ></i>
                </span>
              </div>
            </div>
            <div v-if="goodslist.length == 0">
              <i
                class="el-icon-circle-plus-outline"
                style="margin: 5px 10px 0 20px; font-size: 24px"
                @click="handelradd"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="展馆名称" prop="">
            <el-input
              placeholder="请输入展馆名称"
              v-model="form.number_house"
              style="width: 220px"
              type="text"
            />
          </el-form-item>
          <el-form-item label="展位号" prop="">
            <el-input
              placeholder="请输入展位号"
              v-model="form.number"
              style="width: 220px"
              type="text"
            />
          </el-form-item>
          <el-form-item label="专属报名链接" prop="afbc_url">
            <el-input
              placeholder="https://"
              v-model="form.afbc_url"
              style="width: 220px"
              type="text"
            />
          </el-form-item>
          <el-form-item label="排序" prop="title">
            <el-input
              placeholder="数字越大靠前排"
              v-model="form.sort_order"
              style="width: 220px"
              type="number"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { uploadImg },
    data() {
      return {
        title: '关联云交会企业',
        form: { goods: [] },
        cateSelect: [],
        rules: {
          mall_name: [
            {
              required: true,
              message: '请输入关联云交会企业',
              trigger: 'blur',
            },
          ],
          cat_id: [
            { required: true, message: '请选择企业分类', trigger: 'blur' },
          ],
          // goodsid: [
          //   { required: true, message: '请上传关联产品', trigger: 'blur' },
          // ],
        },
        goodslist: [],
        isShow: false,
      }
    },

    watch: {
      isShow(n, o) {
        console.log('弹窗,', n)
        if (n) {
          this.getcate()
        } else {
          this._onreset()
        }
      },
    },
    mounted() {},
    methods: {
      handlerjuptype(e) {},
      //产品添加
      handelradd() {
        this.goodslist.push({
          id: '',
          mall_name: '',
        })
        this.form.goods = this.goodslist[0].goods_name
      },
      //删除产品
      handlerdelet(index) {
        this.goodslist.splice(index, 1)
      },
      // 企业分类、品类
      getcate() {
        getAction('/user/index/cate').then((res) => {
          if (res.code == 0) {
            this.cateSelect = res.data
          }
        })
      },
      handlerinfo(row) {
        console.log('编辑进入,', row, this.isShow)
        getAction('/api/afbc/act-company/self-info', { id: row.id }).then(
          (res) => {
            this.form = res.data
            console.log('=-==========', this.form)
            this.goodslist = res.data.goods
          }
        )
      },
      handlersave() {
        this.form.goods = this.goodslist
        let params = this.form

        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.goods.length == 0) {
              this.$message.error('请选择关联产品')
              return false
            }
            postAction('/api/afbc/act-company/self-edit', params).then(
              (res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.isShow = false
                  this.$emit('getlist')
                }
              }
            )
          }
        })
      },
      // 关联云交会企业远程搜索
      handlerhuose(queryString, cb) {
        console.log('测试----', queryString)
        if (queryString == '') {
          cb([])
          this.form.yjh_id = ''
        } else {
          getAction('/mall/index/mall', { mall_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      // 关联云交会企业选择
      handleSelecthuose(list) {
        console.log('--', list)
        this.form.yjh_id = list.id
      },
      // 关联产品远程搜索
      handlergoods(queryString, cb) {
        if (queryString == '') {
          cb([])
          // this.form.yjh_id = ''
        } else {
          getAction('/mall/product/goods', { goods_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      // 关联产品选择
      handleSelectgoods(list, index) {
        console.log('关联产品----', list, index)
        this.goodslist[index].yjh_id = list.id
        this.goodslist[index].goods_name = list.goods_name
        this.goodslist[index].goods_img = list.goods_img
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.pic = ''
        } else {
          this.form.pic = val[0]
        }
      },
      setImg(v, prop) {
        console.log('=========', prop, v)
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      _onreset() {
        this.form = {
          mall_name: '',
          cat_id: '',
          afbc_url: '',
          sort_order: '',
        }
        this.goodslist = []
        this.$refs.form.clearValidate()
      },
    },
  }
</script>
