<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.company_name"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="产品名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.goods_id"
          clearable
          placeholder="产品ID"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_pid"
          clearable
          placeholder="一级品类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级品类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in checkselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.admin_name"
          clearable
          placeholder="变更人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_on_sale"
          clearable
          placeholder="是否重新上架"
          style="width: 160px"
        >
          <el-option
            label="上架"
            value="1"
          />
          <el-option
            label="下架"
            value="0"
          />
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '展示图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '相册'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '店铺名称'">
            {{ row[item.prop] }}
          </div>
          <div v-else-if="item.label == '店铺审核状态'">
            {{ row[item.prop] }}
          </div>
          <div v-else-if="item.label == '抖音开关'">
            {{ row[item.prop]==0?'不显示':'显示' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <div >
            <template v-if="row.status == 2">
              <el-button
                size="small"
                type="text"
                @click.native.prevent="handlerexamine(row)"
              >
                拒绝
              </el-button>
              <el-popconfirm title="确认审核通过？" @confirm="handlertong(row)">
                <el-button
                  size="small"
                  style="margin: 10px"
                  slot="reference"
                  type="text"
                >
                  通过
                </el-button>
              </el-popconfirm>
            </template>
            <el-button size="small" type="text" @click="browseRow(row)">
              详情
            </el-button>
            <el-button v-has="'need'" type="text" @click="openenterprise(row)">企业后台</el-button>
<!--            <el-button
              size="small"
              type="text"
              @click.native.prevent="handlerdouyin(row)"
            >
              {{row.is_douyin_show_info==0?'开启抖音图片':'关闭抖音图片'}}

            </el-button>-->
<!--            <el-button
              v-has="'views'"
              icon="el-icon-edit"
              type="text"
              @click.native.prevent="setViews(row)"
            >
              设置浏览量
            </el-button>-->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <browse ref="browse" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import browse from './components/browse'
  export default {
    name: 'Index',
    components: { browse },
    data() {
      return {
        form: {
          /**
           * 变更人
           */
          admin_name: '',
          /**
           * 二级品类id
           */
          cat_id: '',
          /**
           * 一级品类id
           */
          cat_pid: '',
          /**
           * 企业名称
           */
          company_name: '',
          /**
           * 变更结束时间
           */
          end_time: '',
          /**
           * 产品id
           */
          goods_id: '',
          /**
           * 产品名称
           */
          goods_name: '',
          /**
           * 是否重新上架1是0否，空全部
           */
          is_on_sale: '',
          /**
           * 店铺名称
           */
          mall_name: '',
          /**
           * 变更开始时间
           */
          start_time: '',
          /**
           * 状态 1已审核 2申请中 3已驳回
           */
          status: '',
          page: 1,
          limit: 10,
        },
        mallSelsect: [],
        showSelsect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '是',
          },
          {
            id: 1,
            name: '否',
          },
        ],
        checkselect: [
          {
            id: 3,
            name: '已驳回',
          },
          {
            id: 1,
            name: '已审核',
          },
          {
            id: 2,
            name: '申请中',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '产品ID',
            prop: 'goods_id',
            width: '80',
          },
          {
            label: '产品名称',
            prop: 'goods_name',
            width: '110',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '110',
          },

          {
            label: '企业名称',
            prop: 'company_name',
            width: '120',
          },

          {
            label: '品类',
            prop: 'cat_pname',
            width: '110',
          },
          {
            label: '二级品类',
            prop: 'cat_name',
            width: '110',
          },
          {
            label: '变更时间',
            prop: 'op_time',
            width: '',
          },
          {
            label: '变更人',
            prop: 'admin_name',
            width: '',
          },
          {
            label: '是否重新上架',
            prop: 'is_on_sale',
            width: '80',
          },



          {
            label: '审核状态',
            prop: 'status_txt',
            width: '200',
          },
          {
            label: '拒绝原因',
            prop: 'reason',
            width: '80',
          },

        ],
        total: 0,
        cateselect: [],
        catselect: [],
        timelist: [],
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      if(this.$route.query.goods_id){
        this.form.goods_id=this.$route.query.goods_id
      }
      if(this.$route.query.mall_id){
        this.form.mall_id=this.$route.query.mall_id
      }
      console.log(this.$route.query.goods_id,'參數')
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      openenterprise(row){
        postAction("/mall/shop/jump-login-dealer",{mall_id:row.mall_id}).then(res=>{
          console.log(res.data)
          // window.open('http://192.168.9.46:10001/#/enterprise/storeManagement?access_token='+res.data.token,'_blank')
          if(process.env.NODE_ENV === 'development'){
            window.open('http://testqi.htyunjiaohui.com/#/enterprise/storeManagement?access_token='+res.data.token,'_blank')
          } else if (process.env.NODE_ENV === 'pre') {
              window.open(
                'http://pqi.htyunjiaohui.com/#/enterprise/storeManagement?access_token=' +
                  res.data.token,
                '_blank'
              )
            }else{
            window.open('http://qi.htyunjiaohui.com/#/enterprise/storeManagement?access_token='+res.data.token,'_blank')
          }

        })
      },
      setViews(row){
        this.$prompt('当前虚拟浏览量为：'+row.browser_count_virtual, '设置虚拟浏览量', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\d+/,
          inputErrorMessage: '请输入具体数字',
          inputValue: row.browser_count_virtual,
          inputPlaceholder: '请输入虚拟浏览量数字',
          inputValidator: (value) => {
            if (!value) {
              return '请输入虚拟浏览量数字'
            }
            if (value<=0) {
              return '请输入正确的虚拟浏览量数字'
            }
            return true
          },
          inputAutofocus: true,
        }).then(({ value }) => {
          getAction('/mall/product/update-virtual-view', {goods_id:row.id,count:value}).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        }).catch(() => {

        });
      },
      handlerdouyin(row){
        let statys=row.is_douyin_show_info
        if(statys==1){
          postAction('/audit/shop/goods-douyin-show-info', {
            id: row.id,
            is_douyin_show_info: 0,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        }else{
          postAction('/audit/shop/goods-douyin-show-info', {
            id: row.id,
            is_douyin_show_info: 1,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        }
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      browseRow(row) {
        console.log(row)
        this.$refs.browse.handlerinfo(row)
        this.$refs.browse.diashow()
      },
      handlerexamine(row) {
        this.$prompt('理由', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          postAction('/audit/shop/goods-material-audit', {
            id: row.id,
            status: 3,
            reason: value,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        })
      },
      handlertong(row) {
        postAction('/audit/shop/goods-material-audit', {
          id: row.id,
          status: 1,
          reason: '',
        }).then((res) => {
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.handlerInquire()
              }
            });

        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerlist() {
        getAction('/audit/shop/goods-material-list', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
