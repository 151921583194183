var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showDialog, width: "50%" },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "110px", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "需求类型" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.genre,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "genre", $$v)
                    },
                    expression: "form.genre",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("品类需求"),
                  ]),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("指定店铺需求"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "匹配形式" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.match_way,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "match_way", $$v)
                    },
                    expression: "form.match_way",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("需求上传"),
                  ]),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("指定店铺云会议"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.genre == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "选择云交会店铺" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      clearable: "",
                      "fetch-suggestions": _vm.fetchGoods,
                      placeholder: "请输入店铺名称",
                      value: "id",
                      "value-key": "mall_name",
                    },
                    on: {
                      focus: function ($event) {
                        return _vm.handleFocusMall(_vm.index)
                      },
                      select: _vm.handleSelectmall,
                    },
                    model: {
                      value: _vm.form.this_mall_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "this_mall_name", $$v)
                      },
                      expression: "form.this_mall_name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.genre == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "一级品类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "一级品类" },
                      on: { change: _vm.getTwocate },
                      model: {
                        value: _vm.form.root_cat_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "root_cat_id", $$v)
                        },
                        expression: "form.root_cat_id",
                      },
                    },
                    _vm._l(_vm.onecateselect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.genre == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "二级品类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "二级品类" },
                      model: {
                        value: _vm.form.cat_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cat_id", $$v)
                        },
                        expression: "form.cat_id",
                      },
                    },
                    _vm._l(_vm.catselect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.genre == 1 && _vm.form.root_cat_id == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "价格带" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckChange },
                      model: {
                        value: _vm.wine_price_id,
                        callback: function ($$v) {
                          _vm.wine_price_id = $$v
                        },
                        expression: "wine_price_id",
                      },
                    },
                    _vm._l(_vm.pricelist, function (item, idx) {
                      return _c(
                        "el-checkbox",
                        { key: idx, attrs: { label: item.id } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "需求详情" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "detail", $$v)
                  },
                  expression: "form.detail",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "付费企业推荐" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.rec,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rec", $$v)
                  },
                  expression: "form.rec",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "沟通方式" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.img_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "img_type", $$v)
                    },
                    expression: "form.img_type",
                  },
                },
                _vm._l(_vm.connectlist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.img_type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "沟通截图" } },
                [
                  _c("upload-img", {
                    ref: "uploadImage",
                    attrs: { "info-text": _vm.infoText, "max-size": 100 },
                    on: { uploadImg: _vm.uploadImg },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.img_type == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "沟通视频" } },
                [
                  _c("upload-video", {
                    ref: "video",
                    attrs: { limit: 1, "info-text": "", maxSize: 100 },
                    on: {
                      getVideos: function ($event) {
                        return _vm.getVideos($event, "video")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.img_type == 3
            ? _c(
                "el-form-item",
                { attrs: { label: "沟通语音" } },
                [
                  _c("upload-audio", {
                    ref: "audio_url",
                    attrs: {
                      listtype: "text",
                      fileType: "audio",
                      limit: 1,
                      "info-text": "",
                      maxSize: 5,
                    },
                    on: {
                      getAudio: function ($event) {
                        return _vm.getAudio($event, "audio_url")
                      },
                      uploadtime: _vm.getTimes,
                    },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.form.audio_url))]),
                ],
                1
              )
            : _vm._e(),
          _vm.form.img_type == 3
            ? _c(
                "el-form-item",
                { attrs: { label: "图片说明" } },
                [
                  _c("upload-img", {
                    ref: "uploadImage2",
                    attrs: { "info-text": "", "max-size": 100, limit: 4 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImgs($event, "audio_img")
                      },
                      uploadImg: _vm.uploadImg2,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "需求形式" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("找产品代理"),
                  ]),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("找代工")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "提报人" } }, [
            _vm._v(" " + _vm._s(_vm.form.staff_user_name) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "提报时间" } }, [
            _vm._v(" " + _vm._s(_vm.form.created) + " "),
          ]),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning" },
              on: {
                click: function ($event) {
                  return _vm.handlercheck(1)
                },
              },
            },
            [_vm._v("审核通过")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger" },
              on: {
                click: function ($event) {
                  return _vm.handlercheck(2)
                },
              },
            },
            [_vm._v("审核拒绝")]
          ),
        ],
        1
      ),
      _c("Refusereason", {
        ref: "refusereason",
        attrs: { rowid: _vm.rowid },
        on: { getlist: _vm.handlerInquire },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }