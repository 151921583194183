<!-- 新增/编辑产品 -->
<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="50%">
      <div>
        <el-form :model="form" label-width="120px" :rules="rules" ref="form">
          <el-form-item label="产品名称" prop="goods_name">
            <el-input
              placeholder="请输入产品名称"
              v-model="form.goods_name"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="产品卖点" prop="point">
            <el-input
              placeholder="请输入产品卖点"
              v-model="form.point"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="产品图片" prop="list_img">
            <upload-img
              ref="list_img"
              infoText="图片尺寸750*750，大小不超过2M"
              :limit="5"
              @uploadImg="uploadImg"
              @getImgs="getGoods_imgImgs($event, 'list_img')"
              :maxSize="100"
            />
          </el-form-item>
          <el-form-item label="产品信息" prop="title">
            <el-form-item label="品类" prop="title">
              <el-input
                placeholder="例如：饮品-果蔬饮品"
                v-model="form.attr_category"
                style="width: 220px"
              />
            </el-form-item>
            <el-form-item label="包装规格" prop="title">
              <el-input
                placeholder="例如：10瓶/箱"
                v-model="form.attr_spec"
                style="width: 220px"
              />
            </el-form-item>
            <el-form-item label="保质期" prop="title">
              <el-input
                placeholder="例如：12个月"
                v-model="form.attr_date"
                style="width: 220px"
              />
            </el-form-item>
            <el-form-item label="市场零售价" prop="title">
              <el-input
                placeholder="例如：6元"
                v-model="form.attr_market_price"
                style="width: 220px"
              />
            </el-form-item>
            <el-form-item label="招商区域" prop="title">
              <el-input
                placeholder="例如：山东、河北"
                v-model="form.attr_area"
                style="width: 220px"
              />
            </el-form-item>
          </el-form-item>
          <el-form-item label="排序" prop="title">
            <el-input
              placeholder="数字越大靠前显示"
              v-model="form.sort_order"
              type="number"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-switch
              v-model="form.is_on_sale"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="上架"
              inactive-text="下架"
              active-color="#13ce66"
              inactive-color="#ccc"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { uploadImg },
    data() {
      return {
        isShow: false,
        title: '新增',
        form: {
          company_id: '',
        },
        rules: {
          goods_name: [
            {
              required: true,
              message: '请输入产品名称',
              trigger: 'blur',
            },
          ],
          list_img: [
            {
              required: true,
              message: '请上传产品图',
              trigger: 'blur',
            },
          ],
        },
        imglist: [],
      }
    },
    watch: {
      isShow(n, o) {
        if (!n) {
          this._onreset()
        }
      },
    },
    mounted() {},
    methods: {
      handlerinfo(row, company_id) {
        console.log('-=======', company_id)
        this.form.company_id = company_id

        getAction('/api/afbc/act-company/product-info', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.form = res.data
              this.imglist = res.data.list_img.split(',')
              this.$nextTick(() => {
                let imgs = this.form.list_img.split(',')
                // if(imgs.length ==1){

                // }else{

                // }
                this.setImgArray(imgs, 'list_img')
              })
              this.$emit('getlist')
            }
          }
        )
      },
      handlersave() {
        console.log('参数，', this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            console.log('图片，，，', this.imglist)
            this.form.list_img = this.imglist.join(',')
            postAction('/api/afbc/act-company/product-edit', this.form).then(
              (res) => {
                console.log('')
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.$emit('getlist')
                  this.isShow = false
                } else {
                  this.$message.error(res.msg)
                }
              }
            )
          }
        })
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.imglist = ''
        } else {
          this.imglist = val
        }
      },
      getGoods_imgImgs(v, prop) {
        if (v[0]) {
          this.form[prop] = v
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      setImgArray(v, prop) {
        if (v[0] != '') this.$refs[prop].img = v
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      _onreset() {
        this.form = {
          id: '',
          company_id: '',
          goods_name: '',
          point: '',
          list_img: '',
          attr_category: '',
          attr_spec: '',
          attr_date: '',
          attr_area: '',
          attr_market_price: '',
          sort_order: '',
          is_on_sale: 0,
        }
        this.closeimg('list_img')
      },
    },
  }
</script>
