var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showtow,
        width: "60%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showtow = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "160px",
                "label-suffix": "：",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "栏目名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "栏目名称" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "二级页背景色" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "二级页背景色" },
                    model: {
                      value: _vm.form.bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bg_color", $$v)
                      },
                      expression: "form.bg_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "栏目首页背景图" } },
                [
                  _c("upload-img", {
                    ref: "home_img",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "home_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小程序分享图" } },
                [
                  _c("upload-img", {
                    ref: "share_img",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "share_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "H5分享图" } },
                [
                  _c("upload-img", {
                    ref: "h5_share_img",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "h5_share_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联类型" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: "关联类型", clearable: "" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "产品", value: "1" } }),
                      _c("el-option", { attrs: { label: "店铺", value: "2" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "排序" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享标题" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "分享标题" },
                    model: {
                      value: _vm.form.share_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_title", $$v)
                      },
                      expression: "form.share_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享描述" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "分享描述" },
                    model: {
                      value: _vm.form.share_desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_desc", $$v)
                      },
                      expression: "form.share_desc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "底部按钮背景色" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "底部按钮背景色" },
                    model: {
                      value: _vm.form.button_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "button_color", $$v)
                      },
                      expression: "form.button_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "底部按钮文字" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "底部按钮文字" },
                    model: {
                      value: _vm.form.button_text,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "button_text", $$v)
                      },
                      expression: "form.button_text",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "底部按钮文字颜色" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "底部按钮文字颜色" },
                    model: {
                      value: _vm.form.button_text_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "button_text_color", $$v)
                      },
                      expression: "form.button_text_color",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }