var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "满意度调查",
        visible: _vm.investigateshow,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.investigateshow = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "center",
                    padding: "10px",
                    background: "#e8f4ff",
                    margin: "10px 10px",
                  },
                  attrs: { span: 6 },
                },
                [
                  _c("div", [_vm._v("问题已解决次数")]),
                  _c("div", [_vm._v(_vm._s(_vm.data.help))]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "center",
                    padding: "10px",
                    background: "#f7f9fd",
                    margin: "10px 0px",
                  },
                  attrs: { span: 6 },
                },
                [
                  _c("div", [_vm._v("问题未解决次数")]),
                  _c("div", [_vm._v(_vm._s(_vm.data.no_help))]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.data.list, border: "" },
            },
            _vm._l(_vm.columns, function (item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  label: item.label,
                  prop: item.prop,
                  width: item.width,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.prop == "is_help"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row[item.prop] == 1
                                      ? "已解决"
                                      : "未解决"
                                  )
                                ),
                              ])
                            : item.prop == "headimg"
                            ? _c("span", [
                                _c("img", {
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px",
                                    "border-radius": "50%",
                                    "margin-right": "10px",
                                  },
                                  attrs: { src: scope.row[item.prop] },
                                }),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(scope.row[item.prop])),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }