<template>
  <div>
    <el-dialog append-to-body :title="title" :visible.sync="isShow" width="50%">
      <div>
        <el-form :model="form" label-width="120px" :rules="rules" ref="form">
          <el-form-item label="会议名称" prop="name">
            <el-input
              placeholder="请输入"
              v-model="form.name"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="会议分组" prop="type">
            <el-select
              v-model="form.type"
              clearable
              style="width: 160px"
              placeholder=""
            >
              <el-option
                v-for="list in groupSelect"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="是否抽奖" prop="is_award">
            <el-select
              v-model="form.is_award"
              clearable
              style="width: 160px"
              placeholder=""
            >
              <el-option
                v-for="list in drawSelect"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="fid" prop="fid">
            <el-input
              placeholder="请输入fid"
              v-model="form.fid"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="排序">
            <el-input
              placeholder="请输入"
              v-model="form.sort_order"
              style="width: 120px"
              type="number"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { uploadImg },
    data() {
      return {
        isShow: false,
        title: '新增会议',
        form: {
          type: '1',
          name: '',
          fid: '',
          is_award: '1',
          sort_order: 0,
        },
        rules: {
          name: [
            {
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            },
          ],
          type: [
            {
              required: true,
              message: '请选择分组',
              trigger: 'blur',
            },
          ],
          is_award: [
            {
              required: true,
              message: '请选择是否抽奖',
              trigger: 'blur',
            },
          ],
          fid: [
            {
              required: true,
              message: '请输入fid',
              trigger: 'blur',
            },
          ],
        },
        groupSelect: [
          {
            id: '1',
            name: '区域',
          },
          {
            id: '2',
            name: '新渠道',
          },
        ],
        drawSelect: [
          {
            id: '1',
            name: '是',
          },
          {
            id: '2',
            name: '否',
          },
        ],
      }
    },
    watch: {
      isShow(n, o) {
        if (n) {
        } else {
          this._onreset()
        }
      },
    },
    mounted() {},
    methods: {
      handlerinfo(row) {
        this.title = '编辑会议'
        console.log('编辑，', row)
        this.form = Object.assign(this.form, row)
        setTimeout(() => {
          this.$refs.uploadImage.img = [row.pic]
        }, 100)
      },
      handlersave() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            postAction('/api/afbc/act-meet/meet-edit', this.form).then(
              (res) => {
                console.log('')
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.$emit('getlist')
                  this.isShow = false
                } else {
                  this.$message.error(res.msg)
                }
              }
            )
          }
        })
      },

      _onreset() {
        this.form = {
          mall_name: '',
          sort_order: '0',
        }
      },
    },
  }
</script>
