import { render, staticRenderFns } from "./HotRulesInfo.vue?vue&type=template&id=a6bc9b3e&scoped=true&"
import script from "./HotRulesInfo.vue?vue&type=script&lang=js&"
export * from "./HotRulesInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6bc9b3e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\shangjiaGit\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a6bc9b3e')) {
      api.createRecord('a6bc9b3e', component.options)
    } else {
      api.reload('a6bc9b3e', component.options)
    }
    module.hot.accept("./HotRulesInfo.vue?vue&type=template&id=a6bc9b3e&scoped=true&", function () {
      api.rerender('a6bc9b3e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/systemSet/hotset/HotRulesInfo.vue"
export default component.exports