<template>
  <div class="index-container">
    <h3>用户留言</h3>
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['12:00:00']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入产品标题/手机号/用户id"
          clearable
          v-model="form.keywords"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '封面图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template #default="{ row }">
          <el-popconfirm title="确认删除？" @confirm="onDelete(row)">
            <el-button slot="reference" type="text" size="small">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'

  export default {
    name: 'index',

    data() {
      return {
        form: {
          keywords: '',
          start_time: '',
          end_time: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'id',
            prop: 'id',
            width: '',
          },

          {
            label: '新品标题',
            prop: 'title',
            width: '160',
          },

          {
            label: '用户ID',
            prop: 'user_id',
            width: '',
          },
          {
            label: '姓名(用户名)',
            prop: 'realname',
            width: '',
          },
          {
            label: '昵称',
            prop: 'nickname',
            width: '',
          },
          {
            label: '公司名称',
            prop: 'company_name',
            width: '',
          },
          {
            label: '注册电话',
            prop: 'mobile',
            width: '',
          },
          {
            label: '留言时间',
            prop: 'created',
            width: '',
          },
        ],
        total: 0,
        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.getList()
    },
    methods: {
      handlerExport() {
        let data = JSON.parse(JSON.stringify(this.form))
        postAction('/api/daily/new-daily/message-export', data).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        })
      },
      onSearch() {
        this.form.page = 1
        this.getList()
      },
      getList() {
        getAction('/api/daily/new-daily/message-list', this.form).then(
          (res) => {
            if (res.request_code == 200) {
              this.tabledata = res.data.list
              this.total = parseInt(res.data.total)
            }
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getList()
      },
    },
  }
</script>

<style scoped></style>
