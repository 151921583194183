var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "关联产品", visible: _vm.isShow, width: "90%" },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商品名称" },
                        model: {
                          value: _vm.form.goods_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goods_name", $$v)
                          },
                          expression: "form.goods_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.form.is_on_sale,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_on_sale", $$v)
                            },
                            expression: "form.is_on_sale",
                          },
                        },
                        _vm._l(_vm.statusselect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { value: list.id, label: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handlerlist()
                            },
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleredit()
                            },
                          },
                        },
                        [_vm._v(" 新增产品 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    data: _vm.tabledata,
                    border: "",
                    "row-key": "user_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      label: "ID",
                      align: "center",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_img",
                      label: "产品图片",
                      align: "center",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-image",
                              {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: {
                                  "preview-src-list": [row.goods_img],
                                  src: row.goods_img,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "placeholder" },
                                    slot: "placeholder",
                                  },
                                  [
                                    _vm._v(" 加载中 "),
                                    _c("span", { staticClass: "dot" }, [
                                      _vm._v("..."),
                                    ]),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_name",
                      label: "产品标题",
                      align: "center",
                      width: "140",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "point",
                      label: "产品卖点",
                      align: "center",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "created",
                      label: "创建时间",
                      align: "center",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "uv",
                      label: "访客数(UV)",
                      align: "center",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pv",
                      label: "访问次数(PV)",
                      align: "center",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sort_order",
                      label: "排序",
                      align: "center",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "is_on_sale",
                      label: "状态",
                      align: "center",
                      width: "90",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.is_on_sale == "1" ? "上架" : "下架"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "10px" },
                                attrs: { size: "small", type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleredit(row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认要删除吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteRow(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "text",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                staticStyle: { "text-align": "center", "margin-top": "10px" },
                attrs: {
                  "current-page": _vm.form.page,
                  layout: _vm.layout,
                  "page-size": _vm.form.limit,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("productedit", {
        ref: "productedit",
        on: { getlist: _vm.handlerlist },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }