<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">添加企业</el-button>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="请输入企业名称"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">搜 索</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata"
              style="width: 100%"
              ref="ElTable">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
      <template #default="{ row }">
        <span v-if="item.prop === 'status'">
          {{row.status == 1 ? '启用' : '禁用'}}
        </span>
        <span v-else>{{row[item.prop]}}</span>
      </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="150">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerdelete(row)"
          >
            删除
          </el-button>
          
        </template>
      </el-table-column>
    </el-table>
        <el-pagination
          background
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          style="text-align: center; margin-top: 10px"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
    <!-- 添加企业 -->
    <el-dialog
      title="添加企业"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="企业名称">
            <el-autocomplete
              v-model="editForm.name"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入企业名称"
              value-key="mall_name"
              @select="handleSelect"
            ></el-autocomplete>
            </el-form-item>
            <el-form-item label="状  态">
              <el-switch
                v-model="editForm.status"
                active-text="启用"
                inactive-text="禁用"
                active-value="1"
                inactive-value="2">
              </el-switch>
            </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import autoInput from "@/components/autoInput.vue";
export default {
  name: "enterpriselist",
  components: { 
    autoInput
  },
  data(){
    return{
      form: {
        limit: 10,
        page: 1,
        mall_name:'',
      },
      tabledata:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      columns: [
        {
          label: 'ID',
          prop: 'id',
          width: '110',
          align:'center'
        },
        {
          label: '店铺名称',
          prop: 'mall_name',
          width: '',
          align:'center'
        },
        {
          label: 'UV',
          prop: 'uv',
          width: '110',
          align:'center'
        },
        {
          label: 'PV',
          prop: 'pv',
          width: '110',
          align:'center'
        },
        {
          label: '状态',
          prop: 'status',
          width: '110',
          align:'center'
        },
        {
          label: '添加时间',
          prop: 'created',
          width: '150',
          align:'center'
        },
      ],
      total: 0,
      dialogVisible:false,
      restaurants: [],
      editForm:{
        id:'',
        name:'',
        mall_id:'',
        status:'1',
      },
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerdelete(row){
      this.$confirm('此操作将删除该企业, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        postAction("/api/afbc/staff-mall/delete",{id:row.id}).then(res=>{
          this.handlerlist()
          this.$message({
            type: 'success',
            message: res.msg
          });
        })
      }).catch(() => {
      });
    },
    handlerAdd(){
      this.dialogVisible=true
    },
    handleredit(row){
      this.dialogVisible=true;
      this.editForm.id = row.id;
      this.editForm.name = row.mall_name;
      this.editForm.mall_id = row.mall_id;
      this.editForm.status = row.status;
    },
    handlerlist() {
      getAction('/api/afbc/staff-mall/lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
    querySearchAsync(queryString, cb) {
      let that = this;
        if(queryString){
          getAction('/mall/index/mall', {mall_name:that.editForm.name}).then((res) => {
            cb(res.data)
          })
        }else{
          cb([])
        }

      },
    createStateFilter(queryString) {
      return (state) => state.mall_name.includes(queryString.toLowerCase())
    },
    handleSelect(item) {
      console.log(item);
      this.editForm.mall_id = item.id;
    },
    handleClose(){
      let that = this;
      this.dialogVisible = false;
      setTimeout(() => {
        that.editForm={
          name:'',
          mall_id:'',
          status:'1',
        }
      }, 500);
      
    },
    editSubmit(){
      let that = this;
      let d = {
        id:that.editForm.id,
        mall_id:that.editForm.mall_id,
        status :that.editForm.status,
      }
      getAction('/api/afbc/staff-mall/edit', d).then((res) => {
        that.handleClose();
        that.handlerlist()
      })
    },
  }
};
</script>

<style scoped>

</style>
