var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: _vm.title,
            visible: _vm.isShow,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "120px",
                    rules: _vm.rules,
                  },
                },
                [
                  _vm.storetype == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "店铺/企业名称", prop: "mall_name" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "请输入店铺/企业名称" },
                            model: {
                              value: _vm.form.mall_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mall_name", $$v)
                              },
                              expression: "form.mall_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.storetype == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "店铺/企业名称", prop: "mall_name" },
                        },
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              "fetch-suggestions": _vm.handlerhuose,
                              placeholder: "搜索企业名称",
                              "value-key": "mall_name",
                            },
                            on: { select: _vm.handleSelecthuose },
                            model: {
                              value: _vm.form.mall_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mall_name", $$v)
                              },
                              expression: "form.mall_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序(非必填)", prop: "sort_order" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入", type: "number" },
                        model: {
                          value: _vm.form.sort_order,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort_order", $$v)
                          },
                          expression: "form.sort_order",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }