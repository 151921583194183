<!-- 海报模板 -->
<template>
  <div style="padding: 20px 10px">
    <el-form ref="form" :model="form">
      <el-form-item label="">
        <!-- <el-input v-model="form.name" placeholder="姓名"></el-input> -->
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handleredit()"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tabledata" border ref="table" v-loading="loading">
      <el-table-column prop="type" label="模板类型" align="center" width="90">
        <template #default="{ row }">
          {{ row.type == '1' ? '企业' : '产品' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="海报名称"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column prop="list_img" label="海报" align="center">
        <template #default="{ row }">
          <span v-for="(list, index) in row.list_img" :key="index">
            <el-image
              :preview-src-list="[list]"
              :src="list"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center" width="90">
        <template #default="{ row }">
          {{ row.status == '1' ? '启用' : '禁用' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="created"
        label="创建时间"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column prop="name" label="操作" align="center">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-popconfirm title="确认删除吗？" @confirm="deleteRow(row)">
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" @getlist="handlerlist" />
  </div>
</template>
<script>
  import edit from './components/posteredit.vue'
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'posterlist',
    components: { edit },
    data() {
      return {
        tabledata: [],
        loading: false,
        form: {
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      handlerlist() {
        this.form.page = 1
        this.getlist()
      },
      getlist() {
        getAction('/api/afbc/act-company/poster-list', this.form).then(
          (res) => {
            if (res.code == 0) {
              this.tabledata = res.data
              this.total = res.totalCount
            }
          }
        )
      },
      // 新增or编辑
      handleredit(row) {
        if (row) {
          //编辑
          this.$refs.edit.isShow = true

          this.$refs.edit.form = JSON.parse(JSON.stringify(row))

          // this.$refs.edit.setImgArray(row.list_img, 'list_img')
        } else {
          //新增
          this.$refs.edit.isShow = true
        }
      },
      //删除
      deleteRow(row) {
        getAction('/api/afbc/act-company/poster-del', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.handlerlist()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getlist()
      },
    },
  }
</script>
