<template>
  <el-dialog
    title=""
    :visible.sync="showEditDialog"
    width="700px"
    top="5vh"
    :close-on-click-modal="false"
  >
    <div class="detail">
      <img
        :src="info.headimg"
        width="90px"
        height="90px"
        class="headimg"
        alt=""
      />
      <div class="info">
        <h4>公司：{{ info.company_name }}</h4>

        <el-form inline label-width="80px">
          <el-form-item label="姓名:">{{ info.realname }}</el-form-item>
          <el-form-item label="电话:">{{ info.phone }}</el-form-item>
          <el-form-item label="行业:">{{ info.trade }}</el-form-item>
          <el-form-item label="注册:">{{ info.reg }}</el-form-item>
          <el-form-item label="昵称:">{{ info.nickname }}</el-form-item>
          <el-form-item label="地区:">
            {{ info.reg }}
          </el-form-item>
          <el-form-item label="渠道:">
            {{ info.channel }}
          </el-form-item>
          <el-form-item label="最近访问:">{{ info.last_scan }}</el-form-item>
          <el-form-item label="粉丝:">{{ info.fans }}</el-form-item>
        </el-form>
      </div>
    </div>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="互动记录" name="first">
          <div v-for="(item, index) in hdlog" :key="index">
            <p>{{ item.date }} {{ item.content }}</p>
          </div>
          <!-- 分页 -->
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :total="total"
            :page-size="form.limit"
            :current-page="form.page"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="需求记录" name="second">
          <div v-for="(item, index) in hdlog" :key="index">
            <p>{{ item.date }} {{ item.content }}</p>
          </div>
          <el-pagination
            background
            layout="total, sizes,prev, pager, next"
            :total="total"
            :page-size="form.limit"
            :current-page="form.page"
            @current-change="handleCurrentChange2"
            @size-change="handleSizeChange2"
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="跟进记录" name="third">
          <div v-for="(item, index) in hdlog" :key="index">
            <p>{{ item.date }} {{ item.content }}</p>
          </div>
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :total="total"
            :page-size="form.limit"
            :current-page="form.page"
            @current-change="handleCurrentChange3"
            @size-change="handleSizeChange3"
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="认证信息" name="fourth">
          <p class="certinfo">
            <span>审核时间：{{ certinfo.audit_time }}</span>
            <span>审核人：{{ certinfo.audit_user }}</span>
            <span>状态：{{ certinfo.status_txt }}</span>
            <span>来源：{{ certinfo.source }}</span>
          </p>
          <div class="table">
            <table>
              <tr>
                <td>
                  <p>公司名称：</p>
                </td>
                <td>
                  <div v-text="certinfo.company_name"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>经营品类：</p>
                </td>
                <td>
                  <div v-text="certinfo.trade"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>地区：</p>
                </td>
                <td>
                  <div v-text="certinfo.area"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>渠道：</p>
                </td>
                <td>
                  <div v-text="certinfo.channel"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>代理品牌：</p>
                </td>
                <td>
                  <div v-text="certinfo.brand"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>申请人姓名：</p>
                </td>
                <td>
                  <div v-text="certinfo.apply_name"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>申请人职务：</p>
                </td>
                <td>
                  <div v-text="certinfo.apply_job"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>手机号码：</p>
                </td>
                <td>
                  <div v-text="certinfo.phone"></div>
                </td>
              </tr>
            </table>
          </div>
          <div class="imglist" style="display: flex">
            <div
              class="imgcon"
              style="text-align: center"
              v-if="certinfo.business_url"
            >
              <img :src="certinfo.business_url" style="height: 200px" />
              <p>营业执照</p>
            </div>
            <div
              class="imgcon"
              style="margin-left: 20px; text-align: center"
              v-if="certinfo.sfzm"
            >
              <img :src="certinfo.sfzm" style="height: 200px" />
              <p>身份证明</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="会员信息" name="five">
          <div class="table">
            <table>
              <tr>
                <td>
                  <p>会员ID</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_id"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>姓名</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_name"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>微信支付订单号</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_pay_no"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>手机号码</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_mobile"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>公司名称</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_company"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>套餐类型</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_title"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>价格</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_price"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>会员状态</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_status"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>开通时间</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_open_time"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>到期时间</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_expire_time"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>邀请人</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_inviter"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>来源</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_source"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>添加人</p>
                </td>
                <td>
                  <div v-text="memberinfo.club_operate"></div>
                </td>
              </tr>
            </table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="工商信息" name="six">
          <div class="table">
            <table>
              <tr>
                <td>
                  <p>企业名称</p>
                </td>
                <td>
                  <div v-text="businessinfo.company"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>法定代表人名称</p>
                </td>
                <td>
                  <div v-text="businessinfo.oper_name"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>邮箱</p>
                </td>
                <td>
                  <div v-text="businessinfo.email"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>电话</p>
                </td>
                <td>
                  <div v-text="businessinfo.tel"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>官网</p>
                </td>
                <td>
                  <div v-text="businessinfo.web_site_list"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>简介</p>
                </td>
                <td>
                  <div v-text="businessinfo.desc"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>成立日期</p>
                </td>
                <td>
                  <div v-text="businessinfo.start_date"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>登记状态</p>
                </td>
                <td>
                  <div v-text="businessinfo.status"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>省份</p>
                </td>
                <td>
                  <div v-text="businessinfo.province"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>城市</p>
                </td>
                <td>
                  <div v-text="businessinfo.city"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>区域</p>
                </td>
                <td>
                  <div v-text="businessinfo.county"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>更新日期</p>
                </td>
                <td>
                  <div v-text="businessinfo.updated_date"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>统一社会信用代码</p>
                </td>
                <td>
                  <div v-text="businessinfo.credit_code"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>注册资本</p>
                </td>
                <td>
                  <div v-text="businessinfo.regist_capi"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>企业类型</p>
                </td>
                <td>
                  <div v-text="businessinfo.econ_kind"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>注册地址</p>
                </td>
                <td>
                  <div v-text="businessinfo.address"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>经营范围</p>
                </td>
                <td>
                  <div v-text="businessinfo.scope"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>营业期限始</p>
                </td>
                <td>
                  <div v-text="businessinfo.term_start"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>营业期限至</p>
                </td>
                <td>
                  <div v-text="businessinfo.term_end"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>核准日期</p>
                </td>
                <td>
                  <div v-text="businessinfo.check_date"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>组织机构代码</p>
                </td>
                <td>
                  <div v-text="businessinfo.org_no"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>是否上市</p>
                </td>
                <td>
                  <div v-text="businessinfo.is_on_stock"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>股票代码（如A股和港股同时存在，优先显示A股代码）</p>
                </td>
                <td>
                  <div v-text="businessinfo.stock_number"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>上市类型</p>
                </td>
                <td>
                  <div v-text="businessinfo.stock_type"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>企业Logo地址</p>
                </td>
                <td>
                  <div v-text="businessinfo.image_url"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>企业性质</p>
                </td>
                <td>
                  <div v-text="businessinfo.ent_type"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>实缴资本</p>
                </td>
                <td>
                  <div v-text="businessinfo.rec_cap"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>行政区划代码</p>
                </td>
                <td>
                  <div v-text="businessinfo.area_code"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>曾用名</p>
                </td>
                <td>
                  <div v-text="businessinfo.original_name"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>注销日期</p>
                </td>
                <td>
                  <div v-text="businessinfo.cancle_date"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>注销原因</p>
                </td>
                <td>
                  <div v-text="businessinfo.cancle_reason"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>吊销日期</p>
                </td>
                <td>
                  <div v-text="businessinfo.revoke_date"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>吊销原因</p>
                </td>
                <td>
                  <div v-text="businessinfo.revoke_reason"></div>
                </td>
              </tr>
            </table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bottom-button" slot="footer">
      <el-button @click="showEditDialog = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { getAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg.vue'
  export default {
    name: 'BanLog',
    components: { UploadImg },
    data() {
      return {
        showEditDialog: false,
        loading: false,
        user_id: '',
        form: {},
        list: [],
        info: {},
        hdlog: [], //互动记录
        userid: '',
        activeName: 'first',
        certinfo: {}, //认证信息
        memberinfo: {}, //会员信息
        businessinfo: {}, //工商信息
        total: 0,
        totalxq: 0,
        totalgj: 0,
        form: {
          limit: 10,
          page: 1,
        },
        formhd: {
          limit: 3,
          page: 1,
        },
        formxq: {
          limit: 3,
          page: 1,
        },
        formgj: {
          limit: 3,
          page: 1,
        },
      }
    },

    created() {},
    mounted() {},
    computed: {},
    watch: {
      showEditDialog(v) {
        this.form.page = 1
        this.activeName = 'first'
      },
    },
    methods: {
      //互动记录分页
      handleCurrentChange(val) {
        this.form.page = val
        this.getrecord(0)
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getrecord(0)
      },
      //需求记录分页
      handleCurrentChange2(val) {
        this.form.page = val
        this.getrecord(1)
      },
      handleSizeChange2(val) {
        this.form.limit = val
        this.getrecord(1)
      },
      //跟进记录分页
      handleCurrentChange3(val) {
        this.form.page = val
        this.getrecord(2)
      },
      handleSizeChange3(val) {
        this.form.limit = val
        this.getrecord(2)
      },
      handleClick(tab, event) {
        console.log(tab, event)
        this.form.page = 1
        // this.formhd.page = 1
        // this.formxq.page = 1
        // this.formgj.page = 1
        if (tab.index < 3) {
          this.getrecord(tab.index)
        } else if (tab.index == 3) {
          this.getcert()
        } else if (tab.index == 4) {
          this.getmember()
        } else if (tab.index == 5) {
          this.getbussiness()
        }
      },
      getrecord(index) {
        let curpage = 1
        let curlimit = 10
        // if (index == 1) {
        //   curpage = this.formhd.page
        //   curlimit = this.formhd.limit
        // }
        // if (index == 2) {
        //   curpage = this.formxq.page
        //   curlimit = this.formxq.limit
        // }
        // if (index == 3) {
        //   curpage = this.formgj.page
        //   curlimit = this.formgj.limit
        // }
        curpage = this.form.page
        curlimit = this.form.limit
        let params = {
          user_id: this.userid,
          type: index ? Number(index) + 1 : 1,
          page: curpage,
          limit: curlimit,
        }
        getAction('/user/etl/get-record', params).then((res) => {
          console.log('-----------getrecord', res, index)
          if (res.code == 0) {
            this.hdlog = res.data
            this.total = res.totalCount
            // this.totalxq = res.totalCount
            // this.totalgj = res.totalCount
            // this.$nextTick(() => {
            //   if (index == 1) {
            //     this.total = res.totalCount
            //   } else if (index == 2) {
            //     this.totalxq = res.totalCount
            //   } else if (index == 3) {
            //     this.totalgj = res.totalCount
            //   }
            // })

            console.log('=========', this.total, this.totalxq, res.totalCount)
          }
        })
      },
      //认证信息
      getcert() {
        let params = {
          user_id: this.userid,
        }
        getAction('/user/etl/cert-info', params).then((res) => {
          console.log('-----------cert', res)
          if (res.code == 0) {
            this.certinfo = res.data
          }
        })
      },
      // 会员信息
      getmember() {
        let params = {
          user_id: this.userid,
        }
        getAction('/user/etl/member-info', params).then((res) => {
          console.log('-----------member', res)
          if (res.code == 0) {
            this.memberinfo = res.data
          }
        })
      },
      //工商信息
      getbussiness() {
        let params = {
          user_id: this.userid,
        }
        getAction('/user/etl/business-info', params).then((res) => {
          console.log('-----------member', res)
          if (res.code == 0) {
            this.businessinfo = res.data
          }
        })
      },
      getinfo(userid) {
        console.log('-----111111', userid)
        this.userid = userid
        getAction('/user/etl/info', { user_id: userid }).then((res) => {
          if (res.code == 0) {
            this.info = res.data
          }
          this.getrecord()
        })
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v.join(',')
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      async fetchData() {
        this.loading = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .detail {
    display: flex;
  }
  .headimg {
    border-radius: 50%;
  }
  .info {
    h4 {
      text-indent: 3em;
      padding: 0;
      margin: 0;
      line-height: 22px;
      height: 22px;
    }
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
  .table table {
    width: 100%;
    background: #cccccc;
    margin: 10px 0;
    font-size: 13px;
    color: #333;
  }

  .table table td {
    background: #fff;
    padding: 5px;
  }

  .table table td:nth-child(1) {
    width: 100px;
  }
  .certinfo span {
    margin-right: 30px;
  }
</style>
