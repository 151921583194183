<template>
  <el-dialog
    :title="title"
    top="5vh"
    :visible.sync="dialogFormVisible"
    width="50%"
    @close="close"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="120px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="票券标题" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入票券标题"
          style="width: 60%"
        />
      </el-form-item>
      <el-form-item label="票券图标" prop="img">
        <upload-img
          ref="pic"
          :info-text="''"
          :limit="1"
          :max-size="100"
          @getImgs="getGoods_imgImg($event, 'img')"
        />
      </el-form-item>
      <el-form-item label="票券详情" prop="content">
        <wang-editor ref="content" />
      </el-form-item>

      <el-form-item label="票券金额" prop="price">
        <el-input
          v-model="form.price"
          oninput="value=value.replace(/[^0-9.]/g,'')"
          placeholder="请输入票券金额"
          style="width: 30%"
        />
      </el-form-item>
      <el-form-item label="票券张数" prop="total_num">
        <el-input
          v-model="form.total_num"
          oninput="value=value.replace(/[^0-9]/g,'')"
          placeholder="请输入票券张数"
          style="width: 30%"
        />
        / 张
      </el-form-item>
      <el-form-item v-if="form.issued_num > 0">
        <font style="color: #ff4d4f">
          截至目前票券已发放 {{ form.issued_num }} / 张
        </font>
      </el-form-item>
      <el-form-item label="发放张数" prop="send_num">
        <el-input
          v-model="form.send_num"
          oninput="value=value.replace(/[^0-9]/g,'')"
          placeholder="请输入发放张数"
          style="width: 30%"
        />
        / 张
      </el-form-item>
      <div class="formgroup">
        <span class="formlabel">*</span>
        <el-form-item label="发放时间">
          <el-date-picker
            v-model="time_arr"
            align="right"
            :default-time="['00:00:00', '23:59:59']"
            end-placeholder="结束时间"
            start-placeholder="开始时间"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            @blur="changeDate(1)"
          />
        </el-form-item>
      </div>
      <el-form-item label="有效期限">
        <el-radio-group v-model="form.validity_limit">
          <el-radio-button :label="1">时间限制</el-radio-button>
          <el-radio-button :label="2">永久有效</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.validity_limit == 1" label="核销时间">
        <el-date-picker
          v-model="time_arr2"
          align="right"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束时间"
          start-placeholder="开始时间"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @blur="changeDate(2)"
        />
      </el-form-item>
      <el-form-item v-if="form.validity_limit == 1" label="有效期显示">
        <el-radio-group v-model="form.validity_show">
          <el-radio-button :label="1">核销时间</el-radio-button>
          <el-radio-button :label="2">自定义文案</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.validity_limit == 1 && form.validity_show == 2"
        label="自定义文案"
        prop="use_content"
      >
        <el-input
          v-model="form.use_content"
          placeholder="请输入自定义文案"
          style="width: 60%"
        />
      </el-form-item>
      <el-form-item label="票券类型">
        <el-select
          v-model.trim="form.cdkey_type"
          clearable
          placeholder="请选择票券类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="图片状态">
        <el-radio v-model="form.status" label="1">启用</el-radio>
        <el-radio v-model="form.status" label="0">禁用</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="handlerSave">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/request'
  import WangEditor from '@/components/WangEditor'
  import UploadImg from '@/components/uploadImg.vue'
  export default {
    name: 'Edit',
    components: { UploadImg, WangEditor },
    data() {
      return {
        title: '',
        time_arr: [],
        time_arr2: [],
        form: {
          title: '',
          img: '',
          price: 0,
          content: '',
          total_num: 0,
          send_num: 0,
          send_start_time: '',
          send_end_time: '',
          validity_limit: 1,
          validity_show: 1,
          use_content: '',
          use_start_time: '',
          use_end_time: '',
          cdkey_type: '1',
          status: '1',
        },
        typeOptions: [
          {
            value: '1',
            label: '住宿代金券',
          },
          {
            value: '2',
            label: '会员代金券',
          },
        ],
        rules: {
          title: [
            { required: true, trigger: 'blur', message: '请输入票券标题' },
          ],
          price: [
            { required: true, trigger: 'blur', message: '请输入票券金额' },
          ],
          total_num: [
            { required: true, trigger: 'blur', message: '请输入票券张数' },
          ],
          send_num: [
            { required: true, trigger: 'blur', message: '请输入单次发放张数' },
          ],
        },
        dialogFormVisible: false,
      }
    },
    mounted() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '新增票券'
        } else {
          this.title = '编辑票券'
          this.form = Object.assign({}, row)
          this.time_arr = [this.form.send_start_time, this.form.send_end_time]
          if (this.form.validity_limit == 1) {
            this.time_arr2 = [this.form.use_start_time, this.form.use_end_time]
          }
          this.$nextTick(() => {
            this.setImg(row.img, 'pic')
            this.$refs.content.setHtml(row.content)
          })
        }
        this.dialogFormVisible = true
      },
      changeDate(type) {
        if (type == 1) {
          this.form.send_start_time = this.time_arr[0]
          this.form.send_end_time = this.time_arr[1]
        } else {
          this.form.use_start_time = this.time_arr2[0]
          this.form.use_end_time = this.time_arr2[1]
        }
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.closeimg('pic')
        this.$refs.content.clearHtml()
        this.dialogFormVisible = false
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      // 保存
      handlerSave() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.send_start_time == '') {
              this.$message.error('请选择票券发放时间')
              return false
            }

            if (
              parseInt(this.form.total_num) < parseInt(this.form.issued_num)
            ) {
              this.$message.error('票券张数不得小于已发放张数')
              return false
            }

            if (parseInt(this.form.total_num) < parseInt(this.form.send_num)) {
              this.$message.error('票券张数不得小于单次发放张数')
              return false
            }

            if (
              this.form.validity_limit == 1 &&
              this.form.send_start_time == ''
            ) {
              this.$message.error('请选择票券核销时间')
              return false
            }

            if (this.form.validity_show == 2 && this.form.use_content == '') {
              this.$message.error('请输入自定义文案')
              return false
            }
            var $content = this.$refs.content.getHtmlM()
            this.form.content =
              $content != '<p style="line-height: 1.8;"><br></p>'
                ? $content
                : ''
            postAction('/user/cdkey-code/voucher-save', this.form).then(
              (res) => {
                if (res.request_code == 200) {
                  this.$message.success(res.msg)
                } else {
                  this.$message.error(res.msg)
                }
                this.close()
                this.$emit('fetch-data')
              }
            )
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .formgroup {
    position: relative;
    .formlabel {
      position: absolute;
      top: 1vh;
      left: 4.7vh;
      color: #ff4d4f;
    }
  }
</style>
