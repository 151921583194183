var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "批量导入 " + "\n" + "更换物流信息，请联系技术部更新",
        visible: _vm.dialogVisible,
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c("span", [_vm._v("批量导入")]),
              _c(
                "span",
                {
                  staticStyle: {
                    color: "#e6a23c",
                    "font-size": "12px",
                    "margin-left": "10px",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(" 更换物流信息，请联系技术部更新 "),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "100px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c("el-form-item", { attrs: { label: "选择文件:", required: "" } }, [
            _c(
              "div",
              { staticClass: "upload-container" },
              [
                _c(
                  "el-upload",
                  {
                    ref: "upload",
                    attrs: {
                      accept: ".xlsx,.xls",
                      action: "",
                      "auto-upload": false,
                      "file-list": _vm.fileList,
                      limit: 1,
                      "on-change": _vm.handleFileChange,
                      "on-remove": _vm.handleFileRemove,
                    },
                  },
                  [
                    _vm.fileList.length === 0
                      ? [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [
                              _c("i", {
                                staticClass: "el-icon-upload el-icon--right",
                              }),
                              _vm._v(" 点击上传 "),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              staticStyle: { color: "#e6a23c" },
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _c("i", { staticClass: "el-icon-warning" }),
                              _vm._v(" 只能上传xlsx/xls文件 "),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              staticStyle: { color: "#e6a23c" },
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _c("i", { staticClass: "el-icon-warning" }),
                              _vm._v(" 请务必选择好相对应的物流公司 "),
                            ]
                          ),
                        ],
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "物流公司:", required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.logisticsCompany,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "logisticsCompany", $$v)
                    },
                    expression: "form.logisticsCompany",
                  },
                },
                _vm._l(_vm.wuliuList, function (item, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: item.id, value: item.id } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "月份:", prop: "month" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  placeholder: "选择时间",
                  type: "month",
                  "value-format": "yyyy年MM月",
                },
                model: {
                  value: _vm.form.month,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "month", $$v)
                  },
                  expression: "form.month",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型:", required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.productType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "productType", $$v)
                    },
                    expression: "form.productType",
                  },
                },
                _vm._l(_vm.typeList, function (item, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: item.id, value: item.id } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleImport } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }