<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input
          v-model.trim="form.keywords"
          clearable
          placeholder="搜索标题"
          style="width: 250px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model.trim="form.cdkey_type"
          clearable
          placeholder="票券类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model.trim="form.use_status"
          clearable
          placeholder="票券状态"
        >
          <el-option
            v-for="item in useOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.status" clearable>
          <el-option label="禁用" value="0" />
          <el-option label="启用" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleEdit()">新增票券</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlSendLog()">手动发放</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      v-loading="loading"
      border
      :data="tabledata"
      row-key="user_id"
    >
      <el-table-column align="center" label="ID" prop="id" width="80" />
      <el-table-column align="center" label="票券标题" prop="title" />
      <el-table-column
        align="center"
        label="票券图标"
        prop="icon_img"
        width="100"
      >
        <template #default="{ row }">
          <el-image
            v-if="row.img"
            :src="row.img"
            style="width: 80px; height: auto"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="票券金额" prop="price" />
      <el-table-column align="center" label="票券张数" prop="total_num" />
      <el-table-column align="center" label="已发放数" prop="issued_num" />
      <el-table-column align="center" label="已核销数" prop="write_off_num" />
      <el-table-column align="center" label="单次发放数" prop="send_num" />
      <el-table-column align="center" label="发放时间">
        <template #default="{ row }">
          {{ row.send_start_time }} 至 {{ row.send_end_time }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="有限期限" prop="validity_limit">
        <template #default="{ row }">
          <font v-if="row.validity_limit == 1">时间限制</font>
          <font v-else>永久有效</font>
        </template>
      </el-table-column>
      <el-table-column align="center" label="核销时间">
        <template #default="{ row }">
          <span v-if="row.validity_limit == 1">
            {{ row.use_start_time }} 至 {{ row.use_end_time }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="票券类型" prop="cdkey_type">
        <template #default="{ row }">
          <font v-if="row.cdkey_type == 1">住宿代金券</font>
          <font v-else-if="row.cdkey_type == 2">会员抵扣券</font>
        </template>
      </el-table-column>
      <el-table-column align="center" label="票券状态" prop="status">
        <template #default="{ row }">
          {{ row.status == 0 ? '禁用' : '开启' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="created" />
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        prop="id"
        width="100"
      >
        <template #default="{ row }">
          <el-button type="text" @click.native.prevent="handleEdit(row)">
            编辑票券
          </el-button>
          <el-button
            style="margin-left: 0"
            type="text"
            @click.native.prevent="handleSendCdkeyLog(row)"
          >
            发放记录
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <sendLog ref="sendLog" />
    <sendCdkeyLog ref="sendCdkeyLog" />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>
<script>
  import { getAction } from '@/api/request'
  import edit from '@/views/pages/userscrm/cdkey/components/edit'
  import sendLog from '@/views/pages/userscrm/cdkey/components/sendLog'
  import sendCdkeyLog from '@/views/pages/userscrm/cdkey/components/sendCdkeyLog'
  export default {
    name: 'BanLog',
    components: { edit, sendLog, sendCdkeyLog },
    data() {
      return {
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        loading: false,
        tabledata: [],
        form: {
          keywords: '',
          cdkey_type: '',
          use_status: '',
          status: '1',
          page: 1,
          limit: 10,
        },
        useOptions: [
          {
            value: '0',
            label: '未生效',
          },
          {
            value: '1',
            label: '使用中',
          },
          {
            value: '2',
            label: '已过期',
          },
        ],
        typeOptions: [
          {
            value: 0,
            label: '全部',
          },
          {
            value: 1,
            label: '住宿代金券',
          },
          {
            value: 2,
            label: '会员代金券',
          },
        ],
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleEdit(row) {
        if (row) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handlSendLog() {
        this.$refs.sendLog.showData()
      },
      handleSendCdkeyLog(val) {
        this.$refs.sendCdkeyLog.showData({ cdkey_id: val.id })
      },
      fetchData() {
        getAction('/user/cdkey-code/voucher-list', this.form).then((res) => {
          console.log('获取数据', res)
          this.tabledata = res.data
          this.total = res.totalCount
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.fetchData()
      },
    },
  }
</script>
