<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.type"
          clearable
          placeholder="代理类型"
          style="width: 160px"
        >
          <el-option
            v-for="list in typeselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <!--              <el-select v-model="form.mall_id" clearable style="width: 160px;" placeholder="代理店铺">
                <el-option v-for="list in mallselect"
                           :key="list.id"
                           :value="list.id"
                           :label="list.mall_name"/>
              </el-select>-->
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="代理店铺"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>

        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="代理商品"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_pid"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mobile"
          clearable
          placeholder="电话"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.is_free_mall" clearable style="width: 160px;" placeholder="呼入方">
          <el-option
                     value="1"
                     label="平台"/>
          <el-option
            value="0"
            label="企业"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.province_id"
          clearable
          placeholder="省份"
          style="width: 160px"
          @change="handleProChange"
        >
          <el-option
            v-for="list in province"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.city_id"
          clearable
          placeholder="市"
          style="width: 160px"
          @change="handlerCity"
        >
          <el-option
            v-for="list in city"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 260px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <!--          <div v-if="item.label=='用户名'">
&lt;!&ndash;            {{row.user.username}}&ndash;&gt;
          </div>
          <div v-else-if="item.label=='代理商品'">
&lt;!&ndash;            {{row.goods.goods_name}}&ndash;&gt;
          </div>
          <div v-else-if="item.label=='代理店铺'">
&lt;!&ndash;            {{row.mall.mall_name}}&ndash;&gt;
          </div>
          <div v-else>{{row[item.prop]}}</div>-->
          <div>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <!--      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">

          <el-button
            @click.native.prevent="handlerinfo(row)"
            type="text"
            style="margin-left: 10px"
            size="small">
            详情
          </el-button>
          <el-button
            @click.native.prevent="handlerbusiness(row)"
            type="text"
            size="small">
            工商信息
          </el-button>
        </template>
      </el-table-column>-->
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  import * as excel from '@/utils/excel'

  export default {
    name: 'Index',
    data() {
      return {
        form: {
          type: 1,
          company: '',
          name: '',
          goods_name: '',
          mall_name: '',
          job: '',
          page: 1,
          limit: 10,
          province_id:'',
          city_id:'',
          area_id:'',
          is_free_mall:'',
        },
        time:[],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        catselect: [],
        cateselect: [],
        columns: [
          {
            label: 'id',
            prop: 'user_id',
            width: '110',
          },
          {
            label: '呼入方',
            prop: 'is_free_mall_txt',
          },
          {
            label: '代理类型',
            prop: 'type_txt',
            width: '220px',
          },
          {
            label: '代理商品',
            prop: 'goods_name',
            width: '120',
          },
          {
            label: '代理店铺',
            prop: 'mall_name',
            width: '80',
          },
          {
            label: '一级品类',
            prop: 'cat_pname',
            width: '120',
          },
          {
            label: '二级品类',
            prop: 'cat_name',
            width: '120',
          },
          {
            label: '用户名',
            prop: 'username',
            width: '110',
          },
          {
            label: '姓名',
            prop: 'name',
            width: '110',
          },
          {
            label: '公司名称',
            prop: 'company',
            width: '110',
          },
          // {
          //   label: '经营品类',
          //   prop: 'user_trade',
          //   width: '110',
          // },
          // {
          //   label: '渠道',
          //   prop: 'user_channel',
          //   width: '110',
          // },
          {
            label: '地区',
            prop: 'address',
            width: 'address',
          },
          {
            label: '代理品牌',
            prop: 'brand',
            width: '110',
          },
          {
            label: '关注品类',
            prop: 'user_trade',
            width: '110',
          },
          {
            label: '用户渠道',
            prop: 'user_channel',
            width: '160',
          },
          {
            label: '电话',
            prop: 'mobile',
            width: '120',
          },
          // {
          //   label: '地址',
          //   prop: 'address',
          //   width: '80',
          // },
          {
            label: '商家备注',
            prop: 'memo',
            width: '160',
          },
          {
            label: '添加时间',
            prop: 'create_time_txt',
            width: '140',
          },
        ],
        total: 0,
        mallselect: [],
        goodsselect: [],
        typeselect: [
          {
            id: 1,
            name: '商品',
          },
          {
            id: 3,
            name: '店铺',
          },
        ],
        province:[],
        city: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
   async mounted() {
      if(this.$route.query){
        this.form.mall_name=this.$route.query.mall_name
        this.form.type=''
      }
     await this.handlerInquire()
     await this.getProvince()
     await this.handlerSelect()
    },
    methods: {
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      // 获取省份
      async getProvince() {
        const provin = await getAction('/user/index/area', { pid: 0 })
        this.province = provin.data
        console.log('省份')
      },
      // 获取市
      async getCityList(e) {
        const citydata = await getAction('/user/index/area', { pid: e })
        this.city = citydata.data
      },
      handleProChange(e) {
        this.form.city_id = ''
        if(e!=''){
          this.getCityList(e)
        }
      },
      handlerlist() {
        getAction('/mall/proxy/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerExport() {
        getAction('/mall/proxy/export-lists', this.form).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: '代理意向',
          })
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
