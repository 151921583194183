<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['12:00:00']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入产品标题"
          clearable
          v-model="form.keywords"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.category_id" clearable>
          <el-option
            v-for="list in cateList"
            :key="list.id"
            :label="list.title"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_related_yjh"
          clearable
          placeholder="关联云交会"
        >
          <el-option
            v-for="list in relatedlist"
            :key="list.id"
            :label="list.title"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.operating_user"
          clearable
          placeholder="操作账号"
          filterable
        >
          <el-option
            v-for="list in stafflist"
            :key="list.id"
            :label="list.truename"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '封面图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '是否广告'">
            {{ row[item.prop] == '1' ? '是' : '否' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template #default="{ row }">
          <el-button
            style="margin-right: 10px"
            @click.native.prevent="onEdit(row)"
            type="text"
            size="small"
          >
            编辑
          </el-button>
          <el-popconfirm title="确认删除？" @confirm="onDelete(row)">
            <el-button slot="reference" type="text" size="small">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <editcom ref="editcom" @refresh="getList" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import editcom from './editCom'
  export default {
    name: 'index',
    components: { editcom },
    data() {
      return {
        form: {
          keywords: '',
          start_time: '',
          end_time: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [1],
        columns: [
          {
            label: 'id',
            prop: 'id',
            width: '',
          },

          {
            label: '新品标题',
            prop: 'title',
            width: '160',
          },

          {
            label: '封面图',
            prop: 'cover_img',
            width: '',
          },
          {
            label: '关联云交会',
            prop: 'goods_title',
            width: '',
          },
          {
            label: '品类',
            prop: 'category',
            width: '',
          },
          {
            label: '品牌',
            prop: 'brand_name',
            width: '',
          },
          {
            label: '每日上新日期',
            prop: 'publish_time',
            width: '',
          },
          {
            label: '点赞',
            prop: 'like_num',
            width: '',
          },
          {
            label: '收藏',
            prop: 'collect_num',
            width: '',
          },
          {
            label: '访客数',
            prop: 'visitor_num',
            width: '',
          },
          {
            label: '浏览数',
            prop: 'view_num',
            width: '',
          },
          {
            label: '首页曝光量',
            prop: 'index_view_num',
            width: '',
          },
          {
            label: '发布时间',
            prop: 'modified',
            width: '',
          },
          {
            label: '是否广告',
            prop: 'is_advert',
            width: '',
          },
          {
            label: '操作账号',
            prop: 'operating_user',
            width: '',
          },
        ],
        total: 0,
        cateList: [], //品类列表
        time: [],
        relatedlist: [
          {
            id: 1,
            title: '已关联',
          },
          {
            id: '',
            title: '未关联',
          },
        ],
        stafflist: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      // this.handlerInquire()
      this.getList()
      this.getCateList()
      this.getStafflist()
    },
    methods: {
      getStafflist() {
        // /form/form/staff
        getAction('/auth/user/index', { pageSize: 9999 }).then((res) => {
          if (res.request_code == 200) {
            this.stafflist = res.data
          }
        })
      },
      getCateList() {
        getAction('/api/daily/new-daily/category').then((res) => {
          if (res.request_code == 200) {
            this.cateList = res.data
          }
        })
      },
      // 查询
      onSearch() {
        this.form.page = 1
        this.getList()
      },
      // 发布新品列表
      getList() {
        getAction(
          '/api/daily/new-daily/publish-new-daily-list',
          this.form
        ).then((res) => {
          if (res.request_code == 200) {
            this.tabledata = res.data.list
            this.total = parseInt(res.data.total)
          }
        })
      },
      // 编辑操作
      onEdit(row) {
        this.$refs.editcom.isShowDialog = true
        this.$refs.editType = 2
        this.$refs.editcom.getInfo(row)
      },
      //新增操作
      onAdd() {
        this.$refs.editcom.isShowDialog = true
        this.$refs.editType = 1
        this.$refs.editcom.getInfo(row)
      },
      // 新品删除操作
      onDelete(row) {
        getAction('/api/daily/new-daily/new-daily-del', { id: row.id }).then(
          (res) => {
            if (res.request_code == 200) {
              this.$message.success('删除成功')
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      // 选项卡点击操作
      handleClick(tab, event) {
        console.log(tab, event)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getList()
      },
    },
  }
</script>

<style scoped>
  .round {
    width: 380px;
    height: 380px;
  }
  .img {
    max-width: 100%;
  }
</style>
