<template>
  <div class="index-container">
    <div class="tabcon">
      <p @click="tabindex = 1" :class="tabindex == 1 ? 'active' : ''">
        用户列表
      </p>
      <p @click="tabindex = 2" :class="tabindex == 2 ? 'active' : ''">
        统计视图
      </p>
    </div>
    <div v-if="tabindex == 1">
      <el-form ref="form" inline :model="form">
        <el-form-item label="">
          <el-input
            v-model.trim="form.keywords"
            placeholder="公司名称、昵称、手机号"
          />
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="form.cert_status"
            clearable
            multiple
            placeholder="认证状态"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in certstatus"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="form.member_status"
            clearable
            multiple
            placeholder="会员状态"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in memberstatus"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="form.trade"
            clearable
            multiple
            placeholder="行业"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in tradestatus"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="form.channel"
            clearable
            multiple
            placeholder="渠道"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in channelstatus"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.pid"
            clearable
            placeholder="省份"
            style="width: 120px"
            @change="handlerprovince"
          >
            <el-option
              v-for="list in provinceSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.cid"
            clearable
            placeholder="城市"
            style="width: 120px"
            @change="handlercity"
          >
            <el-option
              v-for="list in citySelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select
            v-model="form.aid"
            clearable
            placeholder="地区"
            style="width: 120px"
          >
            <el-option
              v-for="list in districtSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="">
          <el-select
            v-model="form.pid"
            clearable
            placeholder="地区"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in plist"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="">
          <el-select
            v-model="form.cert_status"
            clearable
            placeholder="华糖粉丝"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in certstatus"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="">
          <el-select
            v-model="form.hd"
            clearable
            multiple
            collapse-tags
            placeholder="互动内容"
            style="width: 200px"
            @change="handleChange"
          >
            <el-option
              v-for="(i, idx) in hdstatus"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-date-picker
            v-model="time"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchdata">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="exportdata">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="list"
        border
        ref="table"
        v-loading="loading"
        row-key="user_id"
      >
        <!-- 选择框 -->
        <!-- <el-table-column
        align="center"
        type="selection"
        width="50"
        fixed="left"
        reserve-selection
      ></el-table-column> -->
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :fixed="item.fixed"
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.prop == 'headimg'">
              <el-image
                style="width: 50px; height: 50px"
                :src="row.headimg"
                :preview-src-list="[row.headimg]"
              ></el-image>
            </div>
            <div v-else-if="item.prop == 'is_dealer'">
              {{ row[item.prop] == 1 ? '是' : '否' }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          align="center"
          label="操作"
          width="110px"
        >
          <template #default="{ row }">
            <el-button @click="handleDetail(row.user_id)" type="text">
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        style="text-align: center; padding: 20px 0"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
      <detail ref="detail" @getlist="searchdata" />
    </div>
    <div v-else>
      <div class="dealerinfo">
        <p>已认证经销商：{{ cert_dealer_num }}</p>
        <div>
          <span>用户总数：{{ dealer_num }}</span>
          <span>未认证：{{ not_cert_dealer_num }}</span>
        </div>
      </div>
      <div style="max-width: 1600px" class="echartbox">
        <div id="allnum"></div>
        <div id="infowz"></div>
        <div id="auth_industry"></div>
        <br />
        <div id="auth_channel" class="style1"></div>
        <div id="auth_province" class="style1"></div>
        <div id="data_pv" class="style1"></div>
        <br />
        <div id="data_uv"></div>
        <div id="data_zpxq"></div>
        <div id="data_ypsp"></div>
        <br />
        <div id="data_lyjxs"></div>
        <div id="data_ydjcs"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import detail from './components/detail.vue'
  import echarts from 'echarts'
  import dateFormat from '@/utils/Timeformat'
  export default {
    name: 'VIPManagement',
    components: { detail },
    data() {
      return {
        tabindex: 1,

        list: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        loading: false,
        columns: [
          {
            label: '用户ID',
            prop: 'user_id',
            width: '',
          },
          {
            label: '公司名称',
            prop: 'company_name',
            width: '',
          },
          {
            label: '昵称',
            prop: 'nickname',
            width: '',
          },
          {
            label: '姓名',
            prop: 'realname',
            width: '',
          },
          {
            label: '电话',
            prop: 'phone',
            width: '',
          },
          {
            label: '已认证',
            prop: 'is_dealer',
            width: '',
          },
          {
            label: '会员套餐',
            prop: 'club_title',
            width: '',
          },
          {
            label: '套餐截止日期',
            prop: 'club_expire_time',
            width: '',
          },
          {
            label: '华糖粉丝',
            prop: 'fans',
            width: '',
          },
          {
            label: '地区',
            prop: 'area',
            width: '',
          },
          {
            label: '行业',
            prop: 'trade',
            width: '',
          },
          {
            label: '渠道',
            prop: 'channel',
            width: '',
          },
          {
            label: '注册时间',
            prop: 'register_time',
            width: '',
          },
          {
            label: '最近访问',
            prop: 'last_scan_time',
            width: '',
          },
          {
            label: '跟进客服',
            prop: 'follow_customer',
            width: '',
          },
        ],
        certstatus: [
          {
            id: 1,
            name: '已认证',
          },
          {
            id: 2,
            name: '未认证',
          },
        ],
        memberstatus: [
          {
            id: 1,
            name: '980套餐',
          },
          {
            id: 2,
            name: '960套餐',
          },
          {
            id: 3,
            name: '480套餐',
          },
        ],
        tradestatus: [], //行业
        channelstatus: [], //渠道
        hdstatus: [
          {
            id: 1,
            name: '访问',
          },
          {
            id: 2,
            name: '留言',
          },
          {
            id: 3,
            name: '样品申请',
          },
          {
            id: 4,
            name: '需求提报',
          },
          {
            id: 5,
            name: '有跟进记录',
          },
          {
            id: 6,
            name: '无互动',
          },
        ],
        form: {
          page: 1,
          limit: 10,
          hd: '',
        },
        provinceSelect: [],
        citySelect: [],
        districtSelect: [],
        time: [
          dateFormat(new Date() - 3600 * 1000 * 24 * 30 * 6),
          dateFormat(new Date()),
        ],
        barcolor: [
          '#42BFAC',
          '#4B85FF',
          '#3FB2D7',
          '#90A1C0',
          '#A079FE',
          '#6E65F3',
          '#30C4AE',
          '#4D6FFF',
          '#30BEE2',
          '#ABA8DC',
          '#7C9ADC',
        ],
        base: '',
        not_cert_dealer_num: '',
        cert_dealer_num: '',
        dealer_num: '',
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      tabindex(n, o) {
        if (n == 2) {
          this.getnuminfo()
        }
      },
    },
    mounted() {
      this.searchdata()
      this.getbase()
      this.form.hd = this.getDefaultSelected() //this.hdstatus.slice(0, 5).map((item) => item.id) // 默认选中前5个
      console.log('====', this.form.hd)
    },
    methods: {
      getDefaultSelected() {
        // 返回除“无互动”外的5个选项的id
        return this.hdstatus
          .filter((item) => item.name !== '无互动')
          .map((item) => item.id)
      },
      handleChange(value) {
        console.log('value', value)
        let lastItem = value[value.length - 1]
        if (lastItem == 6) {
          // 如果选择了“无互动”，则清除其他选项
          this.form.hd = [6]
        } else {
          // 如果没有选择“无互动”，则允许选择其他选项
          this.form.hd = value.filter((item) => item !== 6)
        }
      },
      isDisabled(id) {
        // 如果选择了“无互动”，则禁用其他选项
        // return this.form.hd.includes(6) && id !== 6
      },
      // 基础筛选数据
      getbase() {
        getAction('/user/etl/search-base').then((res) => {
          if (res.request_code == 200) {
            this.certstatus = res.data.cert_status
            this.memberstatus = res.data.member_status
            this.tradestatus = res.data.trade
            this.channelstatus = res.data.channel
            this.hdstatus = res.data.hd
            this.provinceSelect = res.data.pid
          }
        })
      },
      // 获取省份列表
      handlerprovince(e) {
        getAction('/user/index/area', { pid: e }).then((res) => {
          this.citySelect = res.data
          this.form.city_id = ''
          this.form.area_id = ''
          this.districtSelect = []
        })
      },
      //获取城市列表
      handlercity(e) {
        getAction('/user/index/area', { pid: e }).then((res) => {
          this.districtSelect = res.data
          this.form.area_id = ''
        })
      },
      searchdata() {
        this.form.page = 1
        this.fetchData()
      },
      exportdata() {
        let query = JSON.parse(JSON.stringify(this.form))
        query.cert_status = query.cert_status.join(',')
        query.member_status = query.member_status.join(',')
        query.trade = query.trade.join(',')
        query.channel = query.channel.join(',')
        query.hd = query.hd.join(',')
        getAction('/user/etl/export-lists', query).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },
      // 获取列表数据
      fetchData() {
        let query = JSON.parse(JSON.stringify(this.form))
        query.cert_status = query.cert_status.join(',')
        query.member_status = query.member_status.join(',')
        query.trade = query.trade.join(',')
        query.channel = query.channel.join(',')
        query.hd = query.hd.join(',')
        if (!query.start_time) {
          query.start_time = this.time[0]
          query.end_time = this.time[1]
        }

        getAction('/user/etl/lists', query).then((res) => {
          if (res.request_code == 200) {
            this.list = res.data
            this.total = res.totalCount
          }
        })
      },
      handleDetail(userid) {
        this.$refs.detail.showEditDialog = true
        // this.$refs.detail.fetchData(userid)
        this.$refs.detail.getinfo(userid)
      },
      //查看详情
      handleCurrentChange(val) {
        this.form.page = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.fetchData()
      },
      getnuminfo() {
        getAction('/user/etl/count-view').then((res) => {
          if (res.request_code == 200) {
            this.cert_dealer_num = res.data.cert_dealer_num
            this.not_cert_dealer_num = res.data.not_cert_dealer_num
            this.dealer_num = res.data.dealer_num
            this.getallnum(res.data.chart1)
            this.getindustry(res.data.chart2)
            this.getchannel(res.data.chart3)
            this.getprovince(res.data.chart4)
            this.getinfowz(res.data.chart11)
            this.getdatapv(res.data.chart5)
            this.getdatauv(res.data.chart6)
            this.getdatazpxq(res.data.chart7)
            this.getdataypsp(res.data.chart8)
            this.getdatalyjxs(res.data.chart9)
            this.getdataydjcs(res.data.chart10)
          }
        })
      },
      //折线图
      getallnum(data) {
        console.log('第一个图表数据，', data)
        let that = this
        var data_new = []
        var max = 0
        data.forEach((item) => {
          data_new.push({
            value: item.num,
            name: item.name,
          })
          if (max < Number(item.num)) {
            max = Number(item.num)
          }
        })
        // console.log(max)
        let color = [
          '#4D6FFF',
          '#00B8AD',
          '#58B44C',
          '#FFAE00',
          '#FF7800',
          '#EB0000',
        ]

        let option = {
          title: {
            text: '',
          },
          tooltip: {
            trigger: 'item',
            // formatter: '{a} <br/>{b} : {c}%'
          },
          series: [
            {
              name: '',
              type: 'funnel',
              left: '10%',
              top: 26,
              bottom: 20,
              width: '80%',
              min: 0,
              max: max, // data的最大值
              minSize: '20%',
              maxSize: '100%',
              sort: 'descending',
              gap: 2,
              label: {
                show: true,
                position: 'inside',
              },
              labelLine: {
                length: 10,
                lineStyle: {
                  width: 1,
                  type: 'solid',
                },
              },
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1,
              },
              emphasis: {
                // label: {
                //   fontSize: 20
                // }
              },
              data: data_new,
              color: color,
            },
          ],
        }
        const myChart_allnum = echarts.init(document.getElementById('allnum'))
        console.log('数据，', myChart_allnum)
        myChart_allnum.setOption(option)
      },
      // 行业已认证经销商数量
      getindustry(data) {
        let that = this
        let list = []
        let data_list = []
        var max = 0

        for (var i = data.length - 1; i >= 0; i--) {
          list.push(data[i].name)
          data_list.push(data[i].num)
          if (i == 0) {
            max = data[i].num
          } else {
            if (max < data[i].num) {
              max = data[i].num
            }
          }
        }
        var color = []
        if (data.length > that.barcolor.length) {
          data.forEach((item, index) => {
            if (index < that.barcolor.length) {
              color.push(that.barcolor[index])
            } else {
              let index1 = index - that.barcolor.length
              color.push(that.barcolor[index1])
            }
          })
        } else {
          data.forEach((item, index) => {
            color.push(that.barcolor[index])
          })
        }
        // console.log(color)
        let option = {
          title: {
            text: '行业已认证经销商数量',
            subtext: '',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            // formatter: function (indexs) {
            //   var percent;
            //   // console.log(indexs)
            //   that.info.time.forEach((item, index) => {
            //     // console.log(indexs[0].value)
            //     // console.log(item.count)
            //     if (indexs[0].value == item.count) {
            //       percent = index
            //     }
            //   })
            //   // console.log(percent)
            //   return '<b style="margin:0 10px">' + that.info.time[percent].percent + '</b>'
            // },
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            // data: ['2011年', '2012年']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '11%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            // boundaryGap: [0, max]
          },
          yAxis: {
            type: 'category',
            data: list,
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 12,
              itemStyle: {
                normal: {
                  color: function (params) {
                    //自定义颜色
                    return color[color.length - params.dataIndex - 1]
                  }, //'#FF593D',
                  label: {
                    show: true,
                    textStyle: {
                      color: '#fff',
                      fontSize: 10,
                    },
                  },
                },
              },

              data: data_list,
            },
          ],
        }
        var map = echarts.init(document.getElementById('auth_industry'))
        map.setOption(option)
      },
      // 经销商信息完整度数量
      getinfowz(data) {
        let that = this
        let list = []
        let data_list = []
        var max = 0

        console.log(data)

        for (var i = data.length - 1; i >= 0; i--) {
          list.push(data[i].name)
          data_list.push(data[i].num)
          if (i == 0) {
            max = data[i].num
          } else {
            if (max < data[i].num) {
              max = data[i].num
            }
          }
        }
        var color = []
        if (data.length > that.barcolor.length) {
          data.forEach((item, index) => {
            if (index < that.barcolor.length) {
              color.push(that.barcolor[index])
            } else {
              let index1 = index - that.barcolor.length
              color.push(that.barcolor[index1])
            }
          })
        } else {
          data.forEach((item, index) => {
            color.push(that.barcolor[index])
          })
        }

        console.log(list)

        // console.log(color)
        let option = {
          title: {
            text: '经销商信息完整度数量',
            subtext: '',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',

            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            // data: ['2011年', '2012年']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '15%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            // boundaryGap: [0, max]
          },
          yAxis: {
            type: 'category',
            data: list,
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 12,
              itemStyle: {
                normal: {
                  color: function (params) {
                    //自定义颜色
                    return color[color.length - params.dataIndex - 1]
                  }, //'#FF593D',
                  label: {
                    show: true,
                    textStyle: {
                      color: '#fff',
                      fontSize: 10,
                    },
                  },
                },
              },

              data: data_list,
            },
          ],
        }
        var map = echarts.init(document.getElementById('infowz'))
        map.setOption(option)
      },
      // 渠道已认证经销商数量
      getchannel(data) {
        let that = this
        let list = []
        let data_list = []
        var max = 0

        for (var i = data.length - 1; i >= 0; i--) {
          list.push(data[i].name)
          data_list.push(data[i].num)
          if (i == 0) {
            max = data[i].num
          } else {
            if (max < data[i].num) {
              max = data[i].num
            }
          }
        }
        var color = []
        if (data.length > that.barcolor.length) {
          data.forEach((item, index) => {
            if (index < that.barcolor.length) {
              color.push(that.barcolor[index])
            } else {
              let index1 = index - that.barcolor.length
              color.push(that.barcolor[index1])
            }
          })
        } else {
          data.forEach((item, index) => {
            color.push(that.barcolor[index])
          })
        }
        // console.log(color)
        let option = {
          title: {
            text: '渠道已认证经销商数量',
            subtext: '',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            // data: ['2011年', '2012年']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '11%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            // boundaryGap: [0, max]
          },
          yAxis: {
            type: 'category',
            data: list,
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 12,
              itemStyle: {
                normal: {
                  color: function (params) {
                    //自定义颜色
                    return color[color.length - params.dataIndex - 1]
                  }, //'#FF593D',
                  label: {
                    show: true,
                    textStyle: {
                      color: '#fff',
                      fontSize: 10,
                    },
                  },
                },
              },

              data: data_list,
            },
          ],
        }
        var map = echarts.init(document.getElementById('auth_channel'))
        map.setOption(option)
      },
      // 省份已认证经销商数量
      getprovince(data) {
        let that = this
        let list = []
        let data_list = []
        var max = 0

        for (var i = data.length - 1; i >= 0; i--) {
          list.push(data[i].name)
          data_list.push(data[i].num)
          if (i == 0) {
            max = data[i].num
          } else {
            if (max < data[i].num) {
              max = data[i].num
            }
          }
        }
        var color = []
        if (data.length > that.barcolor.length) {
          data.forEach((item, index) => {
            if (index < that.barcolor.length) {
              color.push(that.barcolor[index])
            } else {
              let index1 = index - that.barcolor.length
              color.push(that.barcolor[index1])
            }
          })
        } else {
          data.forEach((item, index) => {
            color.push(that.barcolor[index])
          })
        }
        // console.log(color)
        let option = {
          title: {
            text: '省份已认证经销商数量',
            subtext: '',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            // data: ['2011年', '2012年']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            // boundaryGap: [0, max]
          },
          yAxis: {
            type: 'category',
            data: list,
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 12,
              itemStyle: {
                normal: {
                  color: function (params) {
                    //自定义颜色
                    return color[color.length - params.dataIndex - 1]
                  }, //'#FF593D',
                  label: {
                    show: true,
                    textStyle: {
                      color: '#fff',
                      fontSize: 10,
                    },
                  },
                },
              },

              data: data_list,
            },
          ],
        }
        var map = echarts.init(document.getElementById('auth_province'))
        map.setOption(option)
      },
      // 近6个月云交会pv数据
      getdatapv(data) {
        let date = []
        let this_year = []
        let last_year = []
        data.this_year.forEach((item, index) => {
          date.push(item.date)
          this_year.push(item.num)
        })
        data.last_year.forEach((item, index) => {
          last_year.push(item.num)
        })
        console.log('进来6个月数据，', data)
        let option = {
          title: {
            text: '近6个月云交会PV数据',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['本年', '前一年'],
            x: 'center',
            y: 'top',
            padding: [40, 0, 0, 0],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '20%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            // boundaryGap: [0, 0.01]
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            data: date,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '本年',
              type: 'bar',
              data: this_year,
            },
            {
              name: '前一年',
              type: 'bar',
              data: last_year,
            },
          ],
          color: ['#4B85FF', '#42BFAC'],
        }
        var map = echarts.init(document.getElementById('data_pv'))
        map.setOption(option)
      },
      // 近6个月云交会uv数据
      getdatauv(data) {
        let date = []
        let last_year = []
        let this_year = []

        data.this_year.forEach((item, index) => {
          date.push(item.date)
          this_year.push(item.num)
        })
        data.last_year.forEach((item, index) => {
          last_year.push(item.num)
        })

        let option = {
          title: {
            text: '近6个月云交会UV数据',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['本年', '前一年'],
            x: 'center',
            y: 'top',
            padding: [40, 0, 0, 0],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '20%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            // boundaryGap: [0, 0.01]
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            data: date,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '本年',
              type: 'bar',
              data: this_year,
            },
            {
              name: '前一年',
              type: 'bar',
              data: last_year,
            },
          ],
          color: ['#4B85FF', '#42BFAC'],
        }
        var map = echarts.init(document.getElementById('data_uv'))
        map.setOption(option)
      },
      // 近6个月发布找品需求经销商家数
      getdatazpxq(data) {
        let date = []
        let last_year = []
        let this_year = []
        data.this_year.forEach((item, index) => {
          date.push(item.date)
          this_year.push(item.num)
        })
        data.last_year.forEach((item, index) => {
          last_year.push(item.num)
        })

        let option = {
          title: {
            text: '近6个月发布找品需求经销商家数',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['本年', '前一年'],
            x: 'center',
            y: 'top',
            padding: [40, 0, 0, 0],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '20%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            // boundaryGap: [0, 0.01]
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            data: date,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '本年',
              type: 'bar',
              data: this_year,
            },
            {
              name: '前一年',
              type: 'bar',
              data: last_year,
            },
          ],
          color: ['#4B85FF', '#42BFAC'],
        }
        var map = echarts.init(document.getElementById('data_zpxq'))
        map.setOption(option)
      },
      // 近6个月样品申请经销商家数
      getdataypsp(data) {
        let date = []
        let last_year = []
        let this_year = []
        data.this_year.forEach((item, index) => {
          date.push(item.date)
          this_year.push(item.num)
        })
        data.last_year.forEach((item, index) => {
          last_year.push(item.num)
        })

        let option = {
          title: {
            text: '近6个月样品申请经销商家数',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['本年', '前一年'],
            x: 'center',
            y: 'top',
            padding: [40, 0, 0, 0],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '20%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            // boundaryGap: [0, 0.01]
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            data: date,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '本年',
              type: 'bar',
              data: this_year,
            },
            {
              name: '前一年',
              type: 'bar',
              data: last_year,
            },
          ],
          color: ['#4B85FF', '#42BFAC'],
        }
        var map = echarts.init(document.getElementById('data_ypsp'))
        map.setOption(option)
      },
      // 近6个月留言经销商家数
      getdatalyjxs(data) {
        let date = []
        let last_year = []
        let this_year = []
        data.this_year.forEach((item, index) => {
          date.push(item.date)
          this_year.push(item.num)
        })
        data.last_year.forEach((item, index) => {
          last_year.push(item.num)
        })

        let option = {
          title: {
            text: '近6个月留言经销商家数',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['本年', '前一年'],
            x: 'center',
            y: 'top',
            padding: [40, 0, 0, 0],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '20%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            // boundaryGap: [0, 0.01]
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            data: date,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '本年',
              type: 'bar',
              data: this_year,
            },
            {
              name: '前一年',
              type: 'bar',
              data: last_year,
            },
          ],
          color: ['#4B85FF', '#42BFAC'],
        }
        var map = echarts.init(document.getElementById('data_lyjxs'))
        map.setOption(option)
      },
      // 近6个月已对接厂商经销商家数
      getdataydjcs(data) {
        let date = []
        let last_year = []
        let this_year = []
        data.this_year.forEach((item, index) => {
          date.push(item.date)
          this_year.push(item.num)
        })
        data.last_year.forEach((item, index) => {
          last_year.push(item.num)
        })

        let option = {
          title: {
            text: '近6个月已对接厂商经销商家数',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['本年', '前一年'],
            x: 'center',
            y: 'top',
            padding: [40, 0, 0, 0],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '20%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            // boundaryGap: [0, 0.01]
            position: 'bottom',
            color: '#ff0000',
            axisLabel: {
              color: '#333333',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //x轴轴线颜色
              },
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            data: date,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: '#333',
              fontSize: 14,
            },
            splitLine: {
              //去掉网格线
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: '#eeeef3',
              },
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: '#E3E3E8', //y轴轴线颜色
              },
            },
          },
          series: [
            {
              name: '本年',
              type: 'bar',
              data: this_year,
            },
            {
              name: '前一年',
              type: 'bar',
              data: last_year,
            },
          ],
          color: ['#4B85FF', '#42BFAC'],
        }
        var map = echarts.init(document.getElementById('data_ydjcs'))
        map.setOption(option)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dealerinfo {
    p {
      color: #4b85ff;
      font-weight: bold;
      font-size: 18px;
    }
    span {
      color: #999;
      font-size: 16px;
      margin-right: 30px;
    }
  }
  .tabcon {
    display: flex;

    p {
      padding: 3px 8px;
      border-radius: 2px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
    .active {
      background: #4b85ff;
      color: #fff;
      border: 1px solid #4b85ff;
    }
  }
  .echartbox {
    // height: 1200px;
    div {
      display: inline-block;
      margin-top: 20px;
      width: 30%;
      height: 400px;
      &.style1 {
        height: 750px;
      }
    }
  }
</style>
