<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    top="5vh"
    :visible.sync="showEditDialog"
    @close="onclose"
    width="50%"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="140px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入标题"
          style="width: 80%"
        />
      </el-form-item>
      <el-form-item label="类型" prop="label_id">
        <el-select
          v-model.trim="form.label_id"
          clearable
          placeholder="请选择活动类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="封面图" prop="cover_img">
        <upload-img
          ref="cover_img"
          :info-text="'建议尺寸：750*350'"
          :limit="10"
          :max-size="100"
          @getImgs="getGoods_imgImgs($event, 'cover_img')"
        />
      </el-form-item>
      <el-form-item label="活动介绍">
        <div>
          <p v-for="(item, index) in introductions" :key="index">
            <span style="display: inline-block; width: 250px">
              {{ item.title }}
            </span>
            <el-button @click="editintroduction(item, index)" type="text">
              编辑
            </el-button>
            <el-button @click="deleteintroduction(item, index)" type="text">
              删除
            </el-button>
          </p>
        </div>
        <el-button @click="addIntroduction" type="primary">
          添加活动介绍
        </el-button>
      </el-form-item>
      <el-form-item label="精选路线">
        <el-radio v-model="form.is_optimal_route" label="1">开启</el-radio>
        <el-radio v-model="form.is_optimal_route" label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item label="活动日期">
        <el-date-picker
          v-model="time"
          end-placeholder="活动结束日期"
          format="yyyy-MM-dd"
          start-placeholder="活动开始日期"
          style="width: 350px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="是否开启报名">
        <el-radio v-model="form.is_open_apply" label="1">开启</el-radio>
        <el-radio v-model="form.is_open_apply" label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item label="绑定报名">
        <el-select
          v-model.trim="form.event_id"
          clearable
          filterable
          placeholder="报名ID"
          style="width: 350px"
        >
          <el-option
            v-for="item in eventOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否显示报名倒计时">
        <el-radio v-model="form.is_count_down" label="1">显示</el-radio>
        <el-radio v-model="form.is_count_down" label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item label="是否配置客服">
        <el-radio v-model="form.is_config_customer" label="1">是</el-radio>
        <el-radio v-model="form.is_config_customer" label="0">否</el-radio>
      </el-form-item>
      <el-form-item
        label="客服入口图标"
        v-if="form.is_config_customer == 1"
        prop="customer_img"
      >
        <upload-img
          ref="customer_img"
          :info-text="'建议尺寸：136*136'"
          :limit="1"
          :max-size="100"
          @getImgs="getGoods_imgImg($event, 'customer_img')"
        />
      </el-form-item>
      <el-form-item
        v-if="form.is_config_customer == 1"
        label="活动负责人"
        prop="duty_person"
      >
        <el-select
          v-model.trim="form.duty_person"
          clearable
          filterable
          placeholder="请选择活动负责人"
          style="width: 300px"
        >
          <el-option
            v-for="item in dutyOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.is_config_customer == 1"
        label="是否显示专属客服"
      >
        <el-radio v-model="form.is_exclusive_customer" label="1">是</el-radio>
        <el-radio v-model="form.is_exclusive_customer" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="排序">
        <el-input
          v-model="form.sort_order"
          :min="1"
          placeholder="请输入"
          style="width: 100px"
          type="number"
        />
      </el-form-item>
      <el-form-item label="是否热门">
        <el-radio v-model="form.is_hot" label="1">是</el-radio>
        <el-radio v-model="form.is_hot" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否主页展示">
        <el-radio v-model="form.is_home_show" label="1">是</el-radio>
        <el-radio v-model="form.is_home_show" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio v-model="form.status" label="1">启用</el-radio>
        <el-radio v-model="form.status" label="0">禁用</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onclose">取消</el-button>
      <el-button type="primary" @click="handlerSave">确定</el-button>
    </div>
    <introduction ref="introduction" @getlist="getlist" @getdata="getdata" />
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg.vue'
  import introduction from './introduction'

  export default {
    name: 'BanLog',
    components: { introduction, UploadImg },
    data() {
      return {
        showEditDialog: false,
        title: '',
        time: [],
        form: {
          id: '',
          title: '',
          label_id: '',
          cover_img: '',
          introduce: [],
          is_optimal_route: '1', //精选路线 0.关闭 1.开启
          start_time: '',
          end_time: '',
          is_open_apply: '1', // 活动报名 0.关闭 1.开启
          event_id: '',
          is_count_down: '1', //报名倒计时 0.关闭 1.开启
          is_config_customer: '1', //客服入口 0.关闭 1.开启
          customer_img: '',
          duty_person: '',
          is_exclusive_customer: '1', //专属客服 0.关闭 1.开启
          customer_id: '',
          sort_order: '',
          is_hot: '1', //是否热门 0否 1是
          is_home_show: '1', //是否主页展示 0 否 1是
          status: '1', //0禁用 1启用
        },
        typeOptions: [
          {
            value: '1',
            label: '线下研修',
          },
          {
            value: '2',
            label: '区域对接会',
          },
          {
            value: '3',
            label: '区域活动',
          },
        ],
        eventOptions: [], //绑定报名活动下拉
        dutyOptions: [], //活动负责人下拉
        introductions: [],
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          label_id: [
            { required: true, message: '请选择活动类型', trigger: 'change' },
          ],
          cover_img: [
            { required: true, message: '请上传图片', trigger: 'blur' },
          ],
          duty_person: [
            { required: true, message: '请选择活动负责人', trigger: 'change' },
          ],
        },
      }
    },
    watch: {
      showEditDialog(v) {
        if (v) {
          this.geteventlist()
          this.getstafflist()
          this.gettype()
        }
      },
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        }
      },
    },
    mounted() {},
    methods: {
      gettype() {
        getAction('/api/study/study-trip/event-label').then((res) => {
          if (res.code == 0) {
            this.typeOptions = res.data
          }
        })
      },
      //获取报名列表
      geteventlist() {
        getAction('/api/common/common/event-apply-list').then((res) => {
          if (res.code == 0) {
            this.eventOptions = res.data
          }
        })
      },
      //获取员工列表
      getstafflist() {
        getAction('/api/common/common/users-staff-list').then((res) => {
          if (res.code == 0) {
            this.dutyOptions = res.data
          }
        })
      },
      onclose() {
        console.log('--11-1-1-1-')
        this.showEditDialog = false

        this.form = {
          id: '',
          title: '',
          label_id: '',
          cover_img: '',
          introduce: [],
          is_optimal_route: '1', //精选路线 0.关闭 1.开启
          start_time: '',
          end_time: '',
          is_open_apply: '1', // 活动报名 0.关闭 1.开启
          event_id: '',
          is_count_down: '1', //报名倒计时 0.关闭 1.开启
          is_config_customer: '1', //客服入口 0.关闭 1.开启
          customer_img: '',
          duty_person: '',
          is_exclusive_customer: '1', //专属客服 0.关闭 1.开启
          customer_id: '',
          sort_order: '',
          is_hot: '1', //是否热门 0否 1是
          is_home_show: '1', //是否主页展示 0 否 1是
          status: '1', //0禁用 1启用
        }
        this.time = []
        this.introductions = []
        this.closeimg('cover_img')
        this.closeimg('customer_img')
      },
      //删除活动介绍
      deleteintroduction(item, index) {
        this.introductions.splice(index, 1)
      },
      //编辑活动介绍
      editintroduction(item, index) {
        this.$refs.introduction.showEditDialog = true
        if (item) {
          this.$refs.introduction.form = item
          this.$refs.introduction.form.id = index //添加索引，方便编辑后保存对应
        }
      },
      //获取子组件传来的数据
      getdata(data) {
        console.log('子组件传来的值', data)
        if (data.id !== '' || data.id) {
          this.introductions[data.id] = data
          console.log('=======1,')
        } else {
          this.introductions.push(data)
        }
        console.log('活动介绍，', this.introductions)
      },
      //
      handlerinfo(row) {
        console.log('参数，', row)
        getAction('/api/study/study-trip/event-detail', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.form = res.data
              this.time = [this.form.start_time, this.form.end_time]
              this.introductions = this.form.introduce
              this.$nextTick(() => {
                this.setImgArray(this.form.cover_img, 'cover_img')
                this.setImg(this.form.customer_img, 'customer_img')
              })
            }
          }
        )
        // this.form = JSON.parse(JSON.stringify(row))
        // this.time = [this.form.start_time, this.form.end_time]
        // this.$nextTick(() => {
        //   this.setImg(this.form.cover_img, 'cover_img')
        // })
        // this.$forceUpdate()
        console.log('参数from，', this.form)
      },
      //获取列表
      getlist() {},
      //添加活动介绍
      addIntroduction() {
        this.$refs.introduction.showEditDialog = true
      },
      handlerSave() {
        let params = this.form
        params.introduce = JSON.stringify(this.introductions)
        console.log('-----------------------,', this.form)
        // params.cover_img = params.cover_img.join(',')
        // Array.prototype.join.call(params.cover_img, ',')
        if (Array.isArray(params.cover_img)) {
          params.cover_img = params.cover_img.join(',')
        }
        console.log('-----------------------000,', params)
        this.$refs.form.validate((valid) => {
          if (valid) {
            postAction('/api/study/study-trip/event-save', params).then(
              (res) => {
                console.log('保存，', res.data)
                if (res.code == 0) {
                  this.$emit('getlist')
                  this.$message.success(res.msg)
                  this.showEditDialog = false
                } else {
                  this.$message.error(res.msg)
                }
              }
            )
          }
        })
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v.join(',')
        } else {
          this.form[prop] = ''
        }
      },
      getGoods_imgImgs(v, prop) {
        if (v[0]) {
          this.form[prop] = v
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      setImgArray(v, prop) {
        if (v[0] != '') this.$refs[prop].img = v
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
    },
  }
</script>
