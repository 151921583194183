import { render, staticRenderFns } from "./categoryKu.vue?vue&type=template&id=59d48af7&scoped=true&"
import script from "./categoryKu.vue?vue&type=script&lang=js&"
export * from "./categoryKu.vue?vue&type=script&lang=js&"
import style0 from "./categoryKu.vue?vue&type=style&index=0&id=59d48af7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59d48af7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\shangjiaGit\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59d48af7')) {
      api.createRecord('59d48af7', component.options)
    } else {
      api.reload('59d48af7', component.options)
    }
    module.hot.accept("./categoryKu.vue?vue&type=template&id=59d48af7&scoped=true&", function () {
      api.rerender('59d48af7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/activity/survey/components/categoryKu.vue"
export default component.exports