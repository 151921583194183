<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="50%">
      <div>
        <el-form :model="form" label-width="120px" :rules="rules" ref="form">
          <el-form-item label="名称" prop="title">
            <el-input
              placeholder="请输入"
              v-model="form.title"
              style="width: 220px"
            />
          </el-form-item>

          <el-form-item label="图片" prop="pic">
            <upload-img
              ref="uploadImage"
              infoText="尺寸 750*573，大小不超过2M"
              :limit="1"
              @uploadImg="uploadImg"
              :maxSize="100"
            />
          </el-form-item>
          <!-- <el-form-item label="跳转方式" prop="jump_type">
            <el-select
              v-model="form.jump_type"
              clearable
              style="width: 160px"
              placeholder="跳转方式"
            >
              <el-option
                v-for="list in toSelect"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="输入H5链接"
            v-if="form.jump_type == 3"
            prop="jump_object"
          >
            <el-input
              v-model="form.jump_object"
              placeholder="输入H5链接"
              style="width: 160px"
            />
          </el-form-item>
          <el-form-item
            label="选择店铺"
            v-else-if="form.jump_type == 1"
            prop="jump_object"
          >
            <el-autocomplete
              style="width: 160px"
              v-model="form.jump_object_name"
              :fetch-suggestions="handlermell"
              value-key="mall_name"
              placeholder="请输入店铺名称"
              @select="handleSelectmall"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item
            label="选择商品"
            v-else-if="form.jump_type == 2"
            prop="jump_object"
          >
            <el-autocomplete
              style="width: 160px"
              v-model="form.jump_object_name"
              :fetch-suggestions="handlergoods"
              value-key="goods_name"
              placeholder="请输入商品名称"
              @select="handleSelectgoods"
            ></el-autocomplete>
          </el-form-item> -->
          <el-form-item label="状态">
            <el-switch
              v-model="form.status"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#ccc"
            ></el-switch>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input
              placeholder="数字越大靠前排"
              v-model="form.sort"
              style="width: 220px"
              type="number"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { uploadImg },
    data() {
      return {
        isShow: false,
        title: '新增',
        form: {
          type: 4,
          title: '',
          pic: '',
          sort: '',
          status: '',
        },
        rules: {
          title: [
            {
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            },
          ],
        },
        toSelect: [
          {
            id: '1',
            name: '店铺首页',
          },
          {
            id: '2',
            name: '产品详情页',
          },
          {
            id: '3',
            name: 'H5链接',
          },
          {
            id: '4',
            name: '无跳转',
          },
        ],
      }
    },
    watch: {
      isShow(n, o) {
        if (n) {
        } else {
          this._onreset()
        }
      },
    },
    mounted() {},
    methods: {
      handlerinfo(row) {
        this.title = '编辑'
        console.log('编辑，', row)
        this.form = Object.assign(this.form, row)
        setTimeout(() => {
          this.$refs.uploadImage.img = [row.pic]
        }, 100)
      },
      handlersave() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            postAction('/api/index/banner-edit', this.form).then((res) => {
              console.log('')
              if (res.code == 0) {
                this.$message.success(res.msg)
                this.$emit('getlist')
                this.isShow = false
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.pic = ''
        } else {
          this.form.pic = val[0]
        }
      },
      handlermell(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.jump_object = ''
        } else {
          getAction('/mall/index/mall', { mall_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handlergoods(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.jump_object = ''
        } else {
          getAction('/mall/product/goods', { goods_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelectmall(list) {
        console.log(list)
        this.form.jump_object = list.id
      },
      handleSelectgoods(list) {
        console.log(list)
        this.form.jump_object = list.id
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      _onreset() {
        this.$refs.uploadImage.img = []
        this.form = {
          type: 4,
          title: '',
          pic: '',
          sort: '',
          status: '',
        }
      },
    },
  }
</script>
