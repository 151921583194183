<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in shopSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company"
          clearable
          placeholder="公司名称"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="申请人姓名"
          style="width: 160px"
        />
      </el-form-item>
      <!--      <el-form-item>
        <el-input
          v-model="form.source_act"
          clearable
          placeholder="展会"
          style="width: 160px"
        />
      </el-form-item>-->
      <el-form-item>
        <el-input
          v-model="form.mobile"
          clearable
          placeholder="主管理员手机号"
          :maxlength="11"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.inviter_name"
          clearable
          placeholder="邀请人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.op_name"
          clearable
          placeholder="审核人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.source"
          clearable
          placeholder="来源"
          style="width: 160px"
        >
          <el-option label="普通通道" value="1" />
          <el-option label="绿色通道" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_contacts"
          clearable
          placeholder="联系方式"
          style="width: 160px"
        >
          <el-option label="有" value="1" />
          <el-option label="无" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>

      <el-form-item>
        <el-input
          v-model="form.source_act"
          clearable
          placeholder="展会"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.pay_type"
          clearable
          placeholder="套餐"
          style="width: 160px"
        >
          <el-option
            v-for="list in mealselect"
            :key="list.id"
            :label="list.title"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '营业执照'">
            <el-image
              :preview-src-list="[row.business_url]"
              :src="row.business_url"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '当前套餐'">
            <span v-if="row.meal">{{ row.meal.title }}</span>
          </div>
          <div v-else-if="item.label == '当前套餐到期时间'">
            <span v-if="row.meal">{{ row.meal.failure_time }}</span>
          </div>
          <div v-else-if="item.label == '联系方式'">
            <span>{{ row.is_contacts == 1 ? '有' : '无' }}</span>
          </div>
          <div v-else-if="item.label == '店铺等级'">
            <el-select
              v-model="row[item.prop]"
              :disabled="row.status != 1"
              @change="handlerlevel($event, row)"
            >
              <el-option label="无级别" value="0" />
              <el-option label="一级" value="1" />
              <el-option label="二级" value="2" />
              <el-option label="三级" value="3" />
            </el-select>
          </div>
          <div v-else-if="item.label == '店铺logo'">
            <el-image
              :preview-src-list="[row.logo]"
              :src="row.logo"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '企业员工身份证明材料'">
            <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
              <span v-for="(list, index) in row[item.prop]" :key="index">
                <el-image
                  :preview-src-list="[list]"
                  :src="list"
                  style="width: 100px; height: 100px"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中
                    <span class="dot">...</span>
                  </div>
                </el-image>
              </span>
            </el-row>
          </div>
          <div v-else-if="item.label == '佐证材料'">
            <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
              <span v-for="(list, index) in row[item.prop]" :key="index">
                <el-image
                  :preview-src-list="[list]"
                  :src="list"
                  style="width: 100px; height: 100px"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中
                    <span class="dot">...</span>
                  </div>
                </el-image>
              </span>
            </el-row>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="260">
        <template #default="{ row }">
          <el-popconfirm
            v-show="row.status == 2"
            title="确认审核通过？"
            @confirm="deleteRow(row, 1)"
          >
            <el-button slot="reference" size="small" type="text">
              通过
            </el-button>
          </el-popconfirm>

          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerinfo(row)"
          >
            详情
          </el-button>
          <el-popconfirm
            v-show="row.status == 2"
            size="small"
            style="margin-left: 10px"
            title="确认审核拒绝？"
            type="text"
            @confirm="deleteRow(row, 3)"
          >
            <el-button slot="reference" size="small" type="text">
              拒绝
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-show="row.status == 1"
            style="margin-left: 10px"
            title="确认审核拒绝？"
            @confirm="deleteRow2(row.id)"
          >
            <el-button slot="reference" size="small" type="text">
              拒绝
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerbusiness(row)"
          >
            工商信息
          </el-button>
          <el-button type="text" @click="seegoods(row)">查看产品</el-button>
          <el-button v-has="'need'" type="text" @click="openenterprise(row)">
            企业后台
          </el-button>
          <el-button
            v-has="'edit'"
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <edit ref="edit" @getlist="handlerInquire" />
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <info ref="info" @getlist="handlerInquire" />
    <business ref="business" />
    <new-store ref="newStore" @getList="handlerInquire" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import info from './components/info'
  import Business from './components/business'
  import Edit from './components/edit'
  import NewStore from '@/views/pages/enterprise/storereview/components/newStore.vue'
  export default {
    name: 'Index',
    components: {
      NewStore,
      Edit,
      Business,
      info,
    },
    data() {
      return {
        form: {
          status: 0,
          company: '',
          name: '',
          job: '',
          page: 1,
          limit: 10,
          source_act: '',
        },
        mealselect: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
          },
          {
            label: '公司名称',
            prop: 'company_name',
            width: '220',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '110px',
          },
          {
            label: '当前套餐',
            prop: 'meal',
            width: '140px',
          },
          {
            label: '当前套餐到期时间',
            prop: 'meal',
            width: '200px',
          },
          {
            label: '店铺等级',
            prop: 'level',
            width: '110px',
          },
          {
            label: '营业执照',
            prop: 'business_url',
            width: '120',
          },
          {
            label: '企业员工身份证明材料',
            prop: 'employee_materials_url',
            width: '120',
          },
          {
            label: '所属行业',
            prop: 'trade_txt',
            width: '120',
          },
          {
            label: '佐证材料',
            prop: 'evidence_url',
            width: '300',
          },
          {
            label: '申请人姓名',
            prop: 'apply_name',
            width: '110',
          },
          {
            label: '申请人职务',
            prop: 'apply_job',
            width: '110',
          },
          {
            label: '主管理员手机号',
            prop: 'mobile',
            width: '160',
          },
          {
            label: '联系方式',
            prop: 'is_contacts',
            width: '110',
          },
          {
            label: '店铺logo',
            prop: 'logo',
            width: '120',
          },
          {
            label: '招商省份',
            prop: 'province',
            width: '150',
          },
          {
            label: '所在地址',
            prop: 'address',
            width: '160',
          },
          {
            label: '创立时间',
            prop: 'created_time',
            width: '140',
          },
          {
            label: '工厂面积',
            prop: 'plant_area',
            width: '80',
          },
          {
            label: '员工人数',
            prop: 'staffs_number',
            width: '80',
          },
          {
            label: '样板市场',
            prop: 'model_market',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'inviter_name',
            width: '200',
          },
          {
            label: '业务员（旧后台数据）',
            prop: 'yewu',
            width: '',
          },
          {
            label: '拒绝原因',
            prop: 'reason',
            width: '150',
          },
          {
            label: '来源',
            prop: 'source_txt',
            width: '80',
          },
          {
            label: '展会',
            prop: 'source_act',
            width: '120',
          },
          {
            label: '状态',
            prop: 'status_txt',
            width: '80',
          },
          {
            label: '审核时间',
            prop: 'check_time',
            width: '',
          },
          {
            label: '审核人',
            prop: 'op_name',
            width: '',
          },
          // {
          //   label: "操作",
          //   prop: "invite",
          //   width: "",
          // },
        ],
        shopSelect: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 1,
            name: '已审核',
          },
          {
            id: 2,
            name: '待审核',
          },
          {
            id: 3,
            name: '已拒绝',
          },
        ],
        total: 0,
        timelist: [],
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_check_date = v[0]
          this.form.end_check_date = v[1]
        } else {
          this.form.start_check_date = ''
          this.form.end_check_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerselect()
    },
    methods: {
      handlerAdd() {
        this.$refs.newStore.showadd = true
      },
      handlerselect() {
        getAction('/mall/index/meal').then((res) => {
          this.mealselect = res.data
          this.mealselect.push({
            id: 0,
            title: '免费店铺',
          })
        })
      },
      handleredit(row) {
        this.$refs.edit.handlerinfo(row)
        this.$refs.edit.showedit = true
      },
      openenterprise(row) {
        postAction('/mall/shop/jump-login-dealer', { mall_id: row.id }).then(
          (res) => {
            console.log(res.data)
            // window.open('http://192.168.9.46:10001/#/enterprise/storeManagement?access_token='+res.data.token,'_blank')
            if (process.env.NODE_ENV === 'development') {
              window.open(
                'http://testqi.htyunjiaohui.com/#/enterprise/storeManagement?access_token=' +
                  res.data.token,
                '_blank'
              )
            } else if (process.env.NODE_ENV === 'pre') {
              window.open(
                'http://pqi.htyunjiaohui.com/#/enterprise/storeManagement?access_token=' +
                  res.data.token,
                '_blank'
              )
            } else {
              window.open(
                'http://qi.htyunjiaohui.com/#/enterprise/storeManagement?access_token=' +
                  res.data.token,
                '_blank'
              )
            }
          }
        )
      },
      seegoods(row) {
        const goodspages = this.$router.resolve({
          path: '/enterprise/productMan',
          query: { mall_id: row.id },
        })
        window.open(goodspages.href, '_blank')
      },
      handlerlevel(event, row) {
        getAction('/audit/shop/shop-update-level', {
          id: row.id,
          level: event,
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerlist()
        })
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerinfo(row) {
        let data = JSON.parse(JSON.stringify(row))
        console.log(data)
        // if(data.evidence_url==''){
        //   data.evidence_url=new Array()
        // }else{
        //   data.evidence_url=data.evidence_url.split(',')
        // }
        // if(data.employee_materials_url==''){
        //   data.employee_materials_url=new Array()
        // }else{
        //   data.employee_materials_url=data.employee_materials_url.split(',')
        // }
        this.$refs.info.form = data
        this.$refs.info.showinfo = true
      },
      handlerlist() {
        getAction('/audit/shop/lists', this.form).then((res) => {
          // this.tabledata = res.data
          this.tabledata = res.data.map((list) => {
            if (list.evidence_url.length == 0) {
              list.evidence_url = new Array()
            } else {
              list.evidence_url = list.evidence_url.split(',')
            }
            if (list.employee_materials_url.length == 0) {
              list.employee_materials_url = new Array()
            } else {
              list.employee_materials_url =
                list.employee_materials_url.split(',')
            }
            return list
          })
          console.log(this.tabledata, '这个是什么')
          this.total = Number(res.totalCount)
        })
      },
      deleteRow2(id) {
        this.$prompt('理由', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          postAction('/audit/shop/two-reject', {
            id: id,
            reason: value,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        })
      },
      deleteRow(row, status) {
        if (status == 3) {
          this.$prompt('理由', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            postAction('/audit/shop/audit', {
              id: row.id,
              status: status,
              reason: value,
            }).then((res) => {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            })
          })
        } else {
          postAction('/audit/shop/audit', { id: row.id, status }).then(
            (res) => {
              if (res.request_code == 200) {
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.handlerInquire()
              }
            }
          )
        }
      },
      handlerbusiness(row) {
        console.log(row)
        this.$refs.business.handlerinfo(row.company_name)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
