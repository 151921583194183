<template>
  <div>
    <!-- 其他现有代码 -->

    <!-- 弹框组件 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-button-group class="tab">
        <el-button :type="activeTab === '1' ? 'primary' : ''" @click="tabClick('1')">意向线索</el-button>
        <el-button :type="activeTab === '2' ? 'primary' : ''" @click="tabClick('2')">交互</el-button>
        <el-button :type="activeTab === '3' ? 'primary' : ''" @click="tabClick('3')">主动筛选</el-button>
        <el-button :type="activeTab === '4' ? 'primary' : ''" @click="tabClick('4')">广告</el-button>
        <el-button :type="activeTab === '5' ? 'primary' : ''" @click="tabClick('5')">产品数和其他</el-button>
        <el-button :type="activeTab === '6' ? 'primary' : ''" @click="tabClick('6')">前台显示内容</el-button>
      </el-button-group>
      <div class="radioBox">
        <!-- 意向线索 -->
        <el-form class="intention" v-if="activeTab == '1'" ref="intention" :rules="rules" :model="intention" label-width="120px">
          <el-form-item label="单次浏览时长：" prop="duration">
            <el-input v-model="intention.duration" placeholder="请输入" style="width: 150px;"></el-input>
            <e class="tips">浏览时长指的是：单次浏览店铺页和产品页多少秒进入意向线索</e>
          </el-form-item>
          <el-form-item label="累计浏览次数：" prop="scan_count">
            <el-input v-model="intention.scan_count" placeholder="请输入" style="width: 150px;"></el-input>
            <e class="tips">累计浏览次数指的是：自上线起，累计浏览多少次进入意向线索</e>
          </el-form-item>
          <el-form-item label="套餐条数：" prop="meal_count">
            <el-input v-model="intention.meal_count" placeholder="请输入" style="width: 150px;"></el-input>
            <e class="tips">套餐条数指的是：范围是浏览产品页+浏览店铺页+留言+样品申请+电话咨询，超出条数后不显示关键信息。</e>
          </el-form-item>
          <el-form-item label="套餐默认时长内，完成后：" prop="default_is_create" class="intentionBox1">
            <el-radio-group v-model="intention.default_is_create">
              <el-radio label="1">继续提供</el-radio>
              <el-radio label="2">不再提供</el-radio>
            </el-radio-group>
            <el-tooltip class="item" effect="dark" content="套餐默认的时间内，意向线索条数完成了套餐对应的条数，是否还能接收到意向线索" placement="top">
              <e class="el-icon-question"></e>
            </el-tooltip>
          </el-form-item>
          <el-form-item class="intentionBox2" v-if="intention.default_is_create=='1'" label="套餐默认时长内，完成后，再提供的显示完整信息：" prop="default_is_create_complete">
            <el-checkbox-group v-model="intention.default_is_create_complete">
              <el-checkbox label="1">留言</el-checkbox>
              <el-checkbox label="2">样品申请</el-checkbox>
              <el-checkbox label="3">电话咨询</el-checkbox>
              <el-checkbox label="4">浏览产品和店铺</el-checkbox>
              <el-tooltip class="item" effect="dark" content="收到的意向线索，哪些类型的需要显示完整的信息（姓名、公司名称和电话）" placement="top">
                <e class="el-icon-question"></e>
              </el-tooltip>
            </el-checkbox-group>
            
          </el-form-item>
          <el-form-item label="套餐延期下，完成后：" prop="delay_is_create" class="intentionBox1">
            <el-radio-group v-model="intention.delay_is_create">
              <el-radio label="1">继续提供</el-radio>
              <el-radio label="2">不再提供</el-radio>
            </el-radio-group>
            <el-tooltip class="item" effect="dark" content="超出套餐默认时间后，套餐进入延期状态，是否还能接收到意向线索" placement="top">
              <e class="el-icon-question"></e>
            </el-tooltip>
          </el-form-item>
          <el-form-item class="intentionBox3" v-if="intention.delay_is_create=='1'" label="套餐延期下，完成后，再提供的显示完整信息：" prop="delay_is_create_complete">
            <el-checkbox-group v-model="intention.delay_is_create_complete">
              <el-checkbox label="1">留言</el-checkbox>
              <el-checkbox label="2">样品申请</el-checkbox>
              <el-checkbox label="3">电话咨询</el-checkbox>
              <el-checkbox label="4">浏览产品和店铺</el-checkbox>
              <el-tooltip class="item" effect="dark" content="收到的意向线索，哪些类型的需要显示完整的信息（姓名、公司名称和电话）" placement="top">
                <e class="el-icon-question"></e>
              </el-tooltip>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <!-- 交互 -->
        <el-form v-if="activeTab == '2'" ref="interactive" :rules="jhrules" :model="interactive" label-width="200px">
          <div>
            <p>设置留言相关</p>
            <el-form-item label="查看已接收的加密的留言：" prop="is_message_less">
              <el-radio-group v-model="interactive.is_message_less">
                <el-radio label="1">扣除意向线索次数</el-radio>
                <el-radio label="2">不扣除任何次数</el-radio>
                <el-tooltip class="item" effect="dark" content="旧留言指的是购买最新套餐之前的留言" placement="top" style="margin-left:-2y0px">
                  <e class="el-icon-question"></e>
                </el-tooltip>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
        <!-- 主动筛选 -->
        <el-form v-if="activeTab == '3'" ref="filter" :rules="sxrules" :model="filter" label-width="200px">
          <el-form-item label="开启主动筛选功能：" prop="is_select">
            <el-switch v-model="filter.is_select" active-color="#1890ff" inactive-color="#dcdfe6" active-value="1" inactive-value="2"></el-switch>
          </el-form-item>
          <div v-if="filter.is_select==1">
            <el-form-item label="主动筛选次数计算周期：">
              <e class="required" style="left: -173px;">*</e>
              <el-radio-group v-model="filter.select_week">
                <el-radio label="1">一次性</el-radio>
                <el-radio label="2">每天</el-radio>
                <el-radio label="3">每周</el-radio>
                <el-radio label="4">每月</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="主动筛选次数：">
              <e class="required" style="left: -117px;">*</e>
              <el-input v-model="filter.select_count" placeholder="请输入" style="width: 150px;"></el-input>
            </el-form-item>
            <el-form-item label="套餐默认时长内，达成后：">
              <e class="required" style="left: -187px">*</e>
              <el-radio-group v-model="filter.default_is_select">
                <el-radio label="1">继续提供</el-radio>
                <el-radio label="2">不再提供</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="套餐延期后，达成后：">
              <e class="required" style="left: -159px">*</e>
              <el-radio-group v-model="filter.delay_is_select">
                <el-radio label="1">继续提供</el-radio>
                <el-radio label="2">不再提供</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          

        </el-form>
        <!-- 广告 -->
        <el-form v-if="activeTab == '4'" ref="ads" :rules="adsrules" :model="ads" label-width="180px">
          <el-form-item label="广告总曝光量计算规则：" prop="ad_rule">
            <el-radio-group v-model="ads.ad_rule">
              <el-radio label="1">各广告位有对应的曝光量</el-radio>
              <el-radio label="2">不区分广告位，只计算总量</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="广告达到曝光量：" prop="ad_exposure_is_remove">
            <el-radio-group v-model="ads.ad_exposure_is_remove">
              <el-radio label="1">下架</el-radio>
              <el-radio label="2">保持上架</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item label="所有广告总曝光量：" prop="exposure_max" v-if="ads.ad_rule == '2'">
            <el-input v-model="ads.exposure_max" placeholder="请输入" style="width: 200px;"></el-input>
          </el-form-item>
          <el-divider v-if="ads.ad_rule == '2'"></el-divider>
          <div style="position: relative;width: 420px;">
            <div v-for="(i,index) in ads.ad_position_content" :key="index" style="margin-bottom: 30px;">
              <el-form-item label="广告位置：" class="ads-position">
                <e class="required">*</e>
                <el-select v-model="i.advert_no" placeholder="请选择" style="width: 200px;">
                  <el-option v-for="(item,index) in positionlist" :key="index" :label="item.position_name" :value="item.advert_no"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="广告最大曝光量：" class="ads-nums" v-if="ads.ad_rule == '1'">
                <e class="required">*</e>
                <el-input v-model="i.advert_exposure" placeholder="请输入" style="width: 200px;"></el-input>
              </el-form-item>
            </div>
            <e class="el-icon-circle-plus-outline" @click="adsadd"></e>
            <e class="el-icon-remove-outline" @click="adsremove" v-if="ads.ad_position_content.length > 1"></e>
          </div>
        </el-form>
        <!-- 产品数和其他 -->
        <el-form v-if="activeTab == '5'" ref="product" :rules="cprules" :model="product" label-width="120px">
          <div>
            <p>设置最大产品数量</p>
            <el-form-item label="最大产品数量：" prop="product_num">
              <el-input v-model="product.product_num" placeholder="请输入" style="width: 150px;"></el-input>
            </el-form-item>
          </div>
          <el-divider></el-divider>
          <div style="width: 420px;position: relative;">
            <p>设置线下服务内容</p>
            <div v-for="(item,index) in product.offline_content" :key="index" style="margin-bottom: 35px;">
              <el-form-item label="内容名称：" >
                <el-select placeholder="请选择" v-model="item.type">
                  <el-option :label="i.name" :value="i.id" v-for="(i,index1) in mealoffine" :key="index1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="内容数量：" >
                <el-input v-model="item.count" style="width:215px;" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="套餐默认时长内，达成后：" class="productlabel">
                <el-radio-group v-model="item.default_is_create">
                  <el-radio label="1">继续提供</el-radio>
                  <el-radio label="2">不再提供</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="套餐延期后，达成后：" class="productlabel productlabel1">
                <el-radio-group v-model="item.delay_is_create">
                  <el-radio label="1">继续提供</el-radio>
                  <el-radio label="2">不再提供</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <e class="el-icon-circle-plus-outline" style="bottom: 5px;" @click="productadd"></e>
            <e class="el-icon-remove-outline" style="bottom: 5px;" @click="productremove" v-if="product.offline_content.length > 1"></e>
          </div>
        </el-form>
        <el-form v-if="activeTab == '6'" ref="content" :rules="rules" :model="content" label-width="120px">
          <el-form-item label="展示内容">
              <wang-editor ref="wangcon" :height="300" :upload-img-size="300"/>
            </el-form-item>
          <el-form-item label="服务协议">
            <wang-editor ref="wangxie" :height="300" :upload-img-size="300"/>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSave">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import WangEditor from "@/components/WangEditor";

export default {
  components: { WangEditor }, 
  name: "packageInfo",
  data() {
    return {
      id:'',
      dialogVisible: false,
      activeTab: '1', // 默认选中意向线索
      istabclick: false, // tab是否点击
      ischange:0, // 是否修改表单内容
      intention:{
        duration:'',
        scan_count:'',
        meal_count:'',
        default_is_create:'',
        default_is_create_complete:[],
        delay_is_create:'',
        delay_is_create_complete:[],
      }, // 意向线索
      interactive:{
        is_message_less:'',
      }, // 交互
      filter:{
        is_select:'',
        select_week:'',
        select_count:'',
        default_is_select:'',
        delay_is_select:'',
      }, // 主动筛选
      positionlist:[], // 广告位置列表
      ads:{
        ad_rule:'',
        ad_exposure_is_remove:'',
        exposure_max:'',
        ad_position_content:[
          {
            advert_no:'',
            advert_exposure:'',
          }
        ],
      }, // 广告
      mealoffine:[],
      product:{
        product_num:'',
        offline_content:[
          {
            type:'',
            count:'',
            default_is_create:'',
            delay_is_create:'',
          }
        ],
      }, // 产品数和其他
      content:{
        content:'', // 内容
        agreement:'', // 协议
      },
      rules: {
        // 表单验证规则
        duration:[
          { required: true, message: '请输入单次浏览时长', trigger: 'blur' },
        ],
        scan_count:[
          { required: true, message: '请输入累计浏览次数', trigger: 'blur' },
        ],
        meal_count:[
          { required: true, message: '请输入套餐条数', trigger: 'blur' },
        ],
        default_is_create:[
          { required: true, message: '请选择', trigger: 'change' },
        ],
        delay_is_create:[
          { required: true, message: '请选择', trigger: 'change' },
        ],
       
      },
      jhrules:{
        is_message_less:[
          { required: true, message: '请选择查看已接收的加密留言', trigger: 'change' },
        ],
      },
      sxrules:{
        is_select:[
          { required: true, message: '请选择开启主动筛选功能', trigger: 'change' },
        ],
        select_week:[
          { required: true, message: '请选择主动筛选次数周期', trigger: 'change' },
        ],
        select_count:[
          { required: true, message: '请输入主动筛选次数', trigger: 'blur' },
        ],
        default_is_select:[
          { required: true, message: '请选择', trigger: 'change' },
        ],
        delay_is_select:[
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      adsrules:{
        ad_rule:[
          { required: true, message: '请选择广告曝光量计算规则', trigger: 'change' }
        ],
        ad_exposure_is_remove:[
          { required: true, message: '请选择广告达到曝光量', trigger: 'change' }
        ],
        exposure_max:[
          { required: true, message: '请输入所有广告总曝光量', trigger: 'blur' }
        ],
      },
      cprules:{
        product_num:[
          { required: true, message: '请输入最大产品数量', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    intention: {
      handler(newVal, oldVal) {
        console.log('intention changed:', newVal, oldVal);
        this.ischange++;
        
      },
      deep: true // 深度监听
    },
    interactive: {
      handler(newVal, oldVal) {
        console.log('interactive changed:', newVal, oldVal);
        this.ischange++;
      },
      deep: true // 深度监听
    },
    filter: {
      handler(newVal, oldVal) {
        console.log('filter changed:', newVal, oldVal);
        this.ischange++;
      },
      deep: true // 深度监听
    },
    ads: {
      handler(newVal, oldVal) {
        console.log('ads changed:', newVal, oldVal);
        this.ischange++;
      },
      deep: true // 深度监听
    },
    mealoffine:{
      handler(newVal, oldVal) {
        console.log('mealoffine changed:', newVal, oldVal);
        this.ischange++;
      },
      deep: true // 深度监听
    },
    product:{
      handler(newVal, oldVal) {
        console.log('product changed:', newVal, oldVal);
        this.ischange++;
      },
      deep: true // 深度监听
    },
    content:{
      handler(newVal, oldVal) {
        console.log('content changed:', newVal, oldVal);
        if(this.ischange==0){
          this.ischange=1;
        }else{
          this.ischange++;
        }
      },
      deep: true // 深度监听
    },
  },
  methods: {
    tabClick(i){
      if(!this.istabclick){
        return false;
      }
      if(this.ischange>0){
        this.$confirm('当前内容未保存，是否保存？')
        .then(_ => {
          console.log('确定')
          this.handleSave();
          this.activeTab=i;
        })
        .catch(_ => {
          console.log('取消')
          this.activeTab=i;
          this.ischange=0;
        });
      }else{
        
        this.activeTab=i;
        this.ischange=0;
      }
      
      if(this.activeTab == '6'){
        setTimeout(()=>{
          this.$refs.wangcon.editor.setHtml(this.content.content)
          this.$refs.wangxie.editor.setHtml(this.content.agreement)
        },100)
      }
    },
    getinfo(id){
      let that = this;
      that.id = id;
      postAction('/mall/index/get-content',{id:id}).then(res=>{
        console.log('getinfo----',res.data.intention_content.length)
        that.getposition();//获取广告位置
        that.getmealoffine();//获取线下内容列表
        if(res.data.intention_content.length==undefined){
          this.intention = res.data.intention_content
          this.intention.default_is_create =  String(this.intention.default_is_create)
          if(this.intention.default_is_create_complete!=undefined&& this.intention.default_is_create_complete!=''){
            this.intention.default_is_create_complete = this.intention.default_is_create_complete.split(',')
          }else{
            this.$set(this.intention,'default_is_create_complete',[])
          }
          this.intention.delay_is_create = String(this.intention.delay_is_create)
          if(this.intention.delay_is_create_complete!=undefined&& this.intention.delay_is_create_complete!=''){
            this.intention.delay_is_create_complete = this.intention.delay_is_create_complete.split(',')
          }else{
            this.$set(this.intention,'delay_is_create_complete',[])
          }
          
        }
        
        if(res.data.interaction_content.length==undefined){
          this.interactive = res.data.interaction_content
          if(this.interactive.is_message_less!=undefined){
            this.interactive.is_message_less = String(this.interactive.is_message_less)
          }else{
            this.$set(this.interactive,'is_message_less','')
          }
          
        }
        
        if(res.data.select_content.length==undefined){
          this.filter = res.data.select_content
          this.filter.default_is_select = String(this.filter.default_is_select)
          this.filter.delay_is_select = String(this.filter.delay_is_select)
          this.filter.select_week = String(this.filter.select_week)
          this.filter.is_select = String(this.filter.is_select)

        }
        
        if(res.data.ad_content.length==undefined){
          this.ads = res.data.ad_content
          this.ads.ad_rule = String(this.ads.ad_rule)
          this.ads.ad_exposure_is_remove = String(this.ads.ad_exposure_is_remove)
        }
        
        
        this.product.product_num = res.data.product_num
        if(res.data.offline_content.length>0){
          this.product.offline_content = res.data.offline_content
        }
       
        if(res.data.content!=null){
          this.content.content = res.data.content
        }
        if(res.data.agreement!=null){
          this.content.agreement = res.data.agreement
        }

        setTimeout(() => {
          console.log(this.ischange)
          this.ischange=0;
          // 先限制tab点击，待数据加载完成后再取消限制
          this.istabclick=true;
        }, 300);
        
      })
    },
    getposition(){
      let that = this;
      getAction('/audit/advert/position').then(res=>{
        that.positionlist = res.data;
      })
    },
    getmealoffine(){
      let that = this;
      getAction('/mall/index/meal-offline').then(res=>{
        that.mealoffine = res.data;
      })
    },
    // 增加广告位
    adsadd(){
      let len = this.ads.ad_position_content.length;
      if(this.ads.ad_position_content[len-1].advert_no == ''){
        this.$message.warning('请先选择广告位')
        return false;
      }
      if(this.ads.ad_rule==1){
        if(this.ads.ad_position_content[len-1].advert_exposure == ''){
          this.$message.warning('请先输入广告最大曝光量')
          return false;
        }
      }
      
      this.ads.ad_position_content.push(
          {
            advert_no:'',
            advert_exposure:'',
          }
        )
    },
    // 移除广告位
    adsremove(){
      this.ads.ad_position_content.splice(this.ads.ad_position_content.length-1,1)
    },
    // 增加线下服务内容
    productadd(){
      let len = this.product.offline_content.length;
      let d = this.product.offline_content[len-1];
      console.log(d)
      if(d.type==''){
        this.$message.warning('请先选择内容名称')
        return false;
      }
      if(d.count==''){
        this.$message.warning('请先输入内容数量')
        return false;
      }
      if(d.default_is_create==''){
        this.$message.warning('请先选择套餐默认时长内，达成后的操作')
        return false;
      }
      if(d.delay_is_create==''){
        this.$message.warning('请先选择套餐延期后，达成后的操作')
        return false;
      }
      this.product.offline_content.push(
          {
            type:'',
            count:'',
            default_is_create:'',
            delay_is_create:'',
          }
        )
    },
    // 删除线下服务内容
    productremove(){
      this.product.offline_content.splice(this.product.offline_content.length-1,1)
    },
    handleClose(done) {
      let that = this;
      if(that.ischange>0){
        this.$confirm('当前内容未保存，是否保存？')
        .then(_ => {
          // that.dialogVisible = false;
          // that.activeTab = '1';
          // that.ischange = 0;
          that.handleSave(0);
        })
        .catch(_ => {
          that.dialogVisible = false;
          that.activeTab = '1';
          that.ischange = 0;
          // 意向线索
          that.intention={
            duration:'',
            scan_count:'',
            meal_count:'',
            default_is_create:'',
            default_is_create_complete:[],
            delay_is_create:'',
            delay_is_create_complete:[],
          }; 
          // 交互
          that.interactive={
            is_message_less:'',
          };
          // 主动筛选
          that.filter={
            is_select:'',
            select_week:'',
            select_count:'',
            default_is_select:'',
            delay_is_select:'',
          };
          // 广告
          that.ads={
            ad_rule:'',
            ad_exposure_is_remove:'',
            exposure_max:'',
            ad_position_content:[
              {
                advert_no:'',
                advert_exposure:'',
              }
            ],
          };
          // 产品数和其他
          that.product={
            product_num:'',
            offline_content:[
              {
                type:'',
                count:'',
                default_is_create:'',
                delay_is_create:'',
              }
            ],
          };
          that.content={
            content:'', // 内容
            agreement:'', // 协议
          };
        });
      }else{
        that.dialogVisible = false;
          that.activeTab = '1';
          // 意向线索
          that.intention={
            duration:'',
            scan_count:'',
            meal_count:'',
            default_is_create:'',
            default_is_create_complete:[],
            delay_is_create:'',
            delay_is_create_complete:[],
          }; 
          // 交互
          that.interactive={
            is_message_less:'',
          };
          // 主动筛选
          that.filter={
            is_select:'',
            select_week:'',
            select_count:'',
            default_is_select:'',
            delay_is_select:'',
          };
          // 广告
          that.ads={
            ad_rule:'',
            ad_exposure_is_remove:'',
            exposure_max:'',
            ad_position_content:[
              {
                advert_no:'',
                advert_exposure:'',
              }
            ],
          };
          // 产品数和其他
          that.product={
            product_num:'',
            offline_content:[
              {
                type:'',
                count:'',
                default_is_create:'',
                delay_is_create:'',
              }
            ],
          };
          that.content={
            content:'', // 内容
            agreement:'', // 协议
          };
      }
      
    },
    handleSave(type) {
      let that = this;
      // 保存逻辑
      if(this.activeTab==1){
        // 意向线索
        console.log(this.intention)
        this.$refs['intention'].validate((valid) => {
          if (valid) {
            let data = JSON.stringify(this.intention);
            data = JSON.parse(data);
            this.$set(data,'id',this.id)
            if(data.default_is_create=='1'){
              data.default_is_create_complete = data.default_is_create_complete.join(',')
            }else{
              data.default_is_create_complete = ''
            }
            if(data.delay_is_create=='1'){
              data.delay_is_create_complete = data.delay_is_create_complete.join(',')
            }else{
              data.delay_is_create_complete = ''
            }
           
            postAction('/mall/index/set-intention-content',data).then(res=>{
              if(res.code == 0){
                this.$message.success('保存成功');
                that.ischange=0;
                if(type==0){
                  // 关闭弹框操作
                  that.handleClose();
                }
              }else{
                this.$message.error(res.message);
              }
            })
           
          }
        })
      }else if(this.activeTab==2){
        //交互
        this.$refs['interactive'].validate((valid) => {
          if(valid){
            let data = JSON.stringify(this.interactive);
            data = JSON.parse(data);
            this.$set(data,'id',this.id)
            postAction('/mall/index/set-interaction-content',data).then(res=>{
              if(res.code == 0){
                this.$message.success('保存成功');
                that.ischange=0
                if(type==0){
                  // 关闭弹框操作
                  that.handleClose();
                }
              }else{
                this.$message.error(res.message);
              }
            })
          }
        })
      }else if(this.activeTab==3){
        // 主动筛选
        this.$refs['filter'].validate((valid) => {
          if(valid){
            if(this.filter.is_select==1){
              if(this.filter.select_week==''){
                this.$message.error('请选择主动筛选次数计算周期');
                return false;
              }
              if(this.filter.select_count==''){
                return this.$message.error('请输入主动筛选次数');
              }
              if(this.filter.default_is_select==''){
                return this.$message.error('请选择套餐默认时长内，达成后的操作');
              }
              if(this.filter.delay_is_select==''){
                return this.$message.error('请选择套餐延期后，达成后的操作');
              }
            }else{
              this.filter.select_week=''
              this.filter.select_count=''
              this.filter.default_is_select=''
              this.filter.delay_is_select=''
            }

            let data = JSON.stringify(this.filter);
            data = JSON.parse(data);
            this.$set(data,'id',this.id)
            postAction('/mall/index/set-select-content',data).then(res=>{
              if(res.code == 0){
                this.$message.success('保存成功');
                that.ischange=0
                if(type==0){
                  // 关闭弹框操作
                  that.handleClose();
                }
              }else{
                this.$message.error(res.message);
              }
            })
          }
        })
      }else if(this.activeTab==4){
        // 设置广告
        this.$refs['ads'].validate((valid) => {
          if(valid){
            let len = this.ads.ad_position_content.length;
            if(len==1){
              if(this.ads.ad_position_content[0].advert_no==''){
                this.$message.error('请选择广告位置');
                return false;
              }
              if(this.ads.ad_position_content[0].ad_rule=='1'){
                if(this.ads.ad_position_content[0].advert_exposure==''){
                  this.$message.error('请输入广告最大曝光量');
                  return false;
                }
              }
              
            }else{
              if(this.ads.ad_position_content[0].ad_rule=='1'){
                if(this.ads.ad_position_content[len-1].advert_no!=''||this.ads.ad_position_content[len-1].advert_exposure!=''){
                  this.$message.error('请选择广告位置或者输入广告最大曝光量');
                  return false;
                }
                if(this.ads.ad_position_content[len-1].advert_no=='' && this.ads.ad_position_content[len-1].advert_exposure==''){
                  // 最后一个什么也没设置，移除
                  this.ads.ad_position_content.splice(len-1,1);
                }
              }else{
                if(this.ads.ad_position_content[len-1].advert_no==''){
                  // 最后一个什么也没设置，移除
                  this.ads.ad_position_content.splice(len-1,1);
                }
              }
              
            }
            let data = JSON.stringify(this.ads);
            data = JSON.parse(data);
            this.$set(data,'id',this.id)
            postAction('/mall/index/set-ad-content',data).then(res=>{
              if(res.code == 0){
                this.$message.success('保存成功');
                that.ischange=0
                if(type==0){
                  // 关闭弹框操作
                  that.handleClose();
                }
              }else{
                this.$message.error(res.message);
              }
            })
          }
        })
      }else if(this.activeTab==5){
        // 产品数和其他
        this.$refs['product'].validate((valid) => {
          if(valid){
            let len = this.product.offline_content.length;
            if(this.product.offline_content[len-1].type==''&&this.product.offline_content[len-1].count==''&&this.product.offline_content[len-1].default_is_create==''&&this.product.offline_content[len-1].delay_is_create==''){
              // 如果都没有填写，要判断是否是最后一个，如果是最后一个，则移除
              if(len>1){
                this.product.offline_content.splice(len-1,1)
                return false;
              }
            }
            // if(this.product.offline_content[len-1].type==''){
            //   this.$message.error('请选择线下服务内容名称');
            //   return false;
            // }
            // if(this.product.offline_content[len-1].count==''){
            //   this.$message.error('请选择线下服务内容数量');
            //   return false;
            // }
            // if(this.product.offline_content[len-1].default_is_create==''){
            //   this.$message.error('请选择套餐默认时长内，达成后的操作');
            //   return false;
            // }
            // if(this.product.offline_content[len-1].delay_is_create==''){
            //   this.$message.error('请选择套餐延期后，达成后的操作');
            //   return false;
            // }
            let data = JSON.stringify(this.product);
            data = JSON.parse(data);
            this.$set(data,'id',this.id)
            postAction('/mall/index/set-other-content',data).then(res=>{
              if(res.code == 0){
                this.$message.success('保存成功');
                that.ischange=0
                if(type==0){
                  // 关闭弹框操作
                  that.handleClose();
                }
              }else{
                this.$message.error(res.message);
              }
            })
          }
        })
      }else if(this.activeTab==6){
        //前台显示内容
        this.content.content=this.$refs.wangcon.getHtmlM()
        this.content.agreement=this.$refs.wangxie.getHtmlM()
        this.$set(this.content,'id',this.id)
        postAction("/mall/index/set-content",this.content).then(res=>{
          if(res.code == 0){
            this.$message.success('保存成功');
            that.ischange=0
            if(type==0){
              // 关闭弹框操作
              that.handleClose();
            }
          }else{
            this.$message.error(res.message);
          }
        }).catch(err=>{
          console.error(err)
        })
      }
      
      // this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
  ::v-deep .el-dialog__header{
    padding: 0;
  }
  ::v-deep .tab .el-button{
    color:#1890ff;
    border:1px solid #1890ff;
    border-left:0;
    min-width: 100px;
    border-left:1px solid #1890ff;
  }
  ::v-deep .tab .el-button--primary{
    color:#fff;
  }
  
  .el-form-item{
    position: relative;
  }
  .radioBox{
    margin-top: 20px;
  }
  .tips{
    display: block;
    color:#ccc;
  }
  .el-icon-question{
    font-size: 20px;
    margin-left: 5px;
  }
  .required{
    color:red;
    position: absolute;
    top: 0;
  }
  .ads-position .required{
    left: -88px;
  }
  .ads-nums .required{
    left: -130px;
  }
  ::v-deep .el-dialog__footer{
    text-align: center;
  }
  ::v-deep .intention  .intentionBox1 .el-form-item__label{
    width: 190px!important;
  }
  ::v-deep .intention .intentionBox2 .el-form-item__label{
    width: 350px!important;
  }
  ::v-deep .intention .intentionBox3 .el-form-item__label{
    width: 320px!important;
  }
  .el-icon-circle-plus-outline{
    font-size: 25px;
    position: absolute;
    right: 0;
    bottom: 2px;
}
.el-icon-remove-outline{
  font-size: 25px;
  position: absolute;
  right: -35px;
  bottom: 2px;
}
::v-deep .productlabel .el-form-item__label{
  width: 185px!important;
}
.productlabel .required{
  left: -120px;
}
.productlabel1 .required{
  left: -92px;
}
</style>