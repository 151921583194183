var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c("FormMaker", {
        ref: "formMaker",
        attrs: { labels: _vm.formLabel },
        on: { getFormData: _vm.handleQuery },
        scopedSlots: _vm._u([
          {
            key: "start",
            fn: function () {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.showBatchDialog(1)
                          },
                        },
                      },
                      [_vm._v(" 批量设为手动锁定 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.showBatchDialog(3)
                          },
                        },
                      },
                      [_vm._v(" 批量设为正常 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.showBatchDialog(2)
                          },
                        },
                      },
                      [_vm._v(" 批量设为白名单 ")]
                    ),
                  ],
                  1
                ),
                _c("div"),
              ]
            },
            proxy: true,
          },
          {
            key: "endbut",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "move-query-btn" },
                  [
                    _c(
                      "el-badge",
                      { attrs: { value: _vm.badeNum, type: "primary" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.showMoveQuery = !_vm.showMoveQuery
                              },
                            },
                          },
                          [_vm._v(" 高级查询 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse-transition",
                      { staticStyle: { "z-index": "1000" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showMoveQuery,
                                expression: "showMoveQuery",
                              },
                            ],
                            ref: "move-query",
                            staticClass: "move-query",
                          },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "form2",
                                staticStyle: { padding: "15px" },
                                attrs: {
                                  model: _vm.form2,
                                  inline: "",
                                  "label-position": "right",
                                  "label-width": "120px",
                                  "label-suffix": ":",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "用户ID", prop: "userid" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { clearable: "" },
                                      model: {
                                        value: _vm.form2.userid,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form2, "userid", $$v)
                                        },
                                        expression: "form2.userid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "昵称", prop: "nickname" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { clearable: "" },
                                      model: {
                                        value: _vm.form2.nickname,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form2, "nickname", $$v)
                                        },
                                        expression: "form2.nickname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "渠道", prop: "channel" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form2.channel,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form2, "channel", $$v)
                                          },
                                          expression: "form2.channel",
                                        },
                                      },
                                      _vm._l(
                                        _vm.qudaoSelect,
                                        function (i, idx) {
                                          return _c("el-option", {
                                            key: idx,
                                            attrs: {
                                              value: i.id,
                                              label: i.channel_name,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否活跃用户",
                                      prop: "active",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form2.active,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form2, "active", $$v)
                                          },
                                          expression: "form2.active",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { value: "1", label: "否" },
                                        }),
                                        _c("el-option", {
                                          attrs: { value: "2", label: "是" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            "拥有手机号且半年登录两次及以上的才算为活跃用户",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-question",
                                          staticStyle: {
                                            color: "#ccc",
                                            "margin-left": "5px",
                                            cursor: "pointer",
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "有无电话",
                                      prop: "is_mobile",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form2.is_mobile,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form2,
                                              "is_mobile",
                                              $$v
                                            )
                                          },
                                          expression: "form2.is_mobile",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            value: "1",
                                            label: "无电话",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            value: "2",
                                            label: "有电话",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "电话", prop: "mobile" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { clearable: "" },
                                      model: {
                                        value: _vm.form2.mobile,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form2, "mobile", $$v)
                                        },
                                        expression: "form2.mobile",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "按注册时间",
                                      prop: "time",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        type: "daterange",
                                        "range-separator": "-",
                                        "value-format": "yyyy-MM-dd",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                      },
                                      model: {
                                        value: _vm.form2.time,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form2, "time", $$v)
                                        },
                                        expression: "form2.time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "按最近浏览时间",
                                      prop: "active_time",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form2.active_time,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form2,
                                              "active_time",
                                              $$v
                                            )
                                          },
                                          expression: "form2.active_time",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            value: "1",
                                            label: "有最近浏览时间",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            value: "2",
                                            label: "没有最近浏览时间",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "center" },
                                  [
                                    _c(
                                      "el-button",
                                      { on: { click: _vm.handleReset } },
                                      [_vm._v("重置")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.handleMoreQuery },
                                      },
                                      [_vm._v(" 确定 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.showMoveQuery
                      ? _c("div", {
                          ref: "dialog",
                          staticClass: "dialog",
                          on: {
                            click: function ($event) {
                              _vm.showMoveQuery = false
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "end",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleExport },
                  },
                  [_vm._v("导出")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: { data: _vm.list, border: "", "row-key": "user_id" },
          on: { "selection-change": _vm.selectChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "selection",
              width: "50",
              fixed: "left",
              "reserve-selection": "",
            },
          }),
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                fixed: item.fixed,
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.prop == "headimg"
                          ? _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                  },
                                  attrs: {
                                    src: row.headimg,
                                    "preview-src-list": [row.headimg],
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "210px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    row.is_lock_text == "自动锁定" ||
                    row.is_lock_text == "手动锁定"
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleNormal(row)
                                },
                              },
                            },
                            [_vm._v(" 设为正常 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleWhileList(row)
                                },
                              },
                            },
                            [_vm._v(" 设为白名单 ")]
                          ),
                        ]
                      : row.is_lock_text == "正常"
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleLock(row)
                                },
                              },
                            },
                            [_vm._v(" 设为手动锁定 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleWhileList(row)
                                },
                              },
                            },
                            [_vm._v(" 设为白名单 ")]
                          ),
                        ]
                      : row.is_lock_text == "白名单"
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleLock(row)
                                },
                              },
                            },
                            [_vm._v(" 设为手动锁定 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleNormal(row)
                                },
                              },
                            },
                            [_vm._v(" 设为正常 ")]
                          ),
                        ]
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleBanLog(row)
                          },
                        },
                      },
                      [_vm._v("封号记录")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.pagesize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("HandleBatch", {
        ref: "handleBatch",
        on: {
          handleBatchLock: _vm.handleBatchLock,
          handleBatchNormal: _vm.handleBatchNormal,
          handleBatchWhileList: _vm.handleBatchWhileList,
          handleLock: _vm.handleTableLock,
          handleWhileList: _vm.handleTableWhileList,
          handleNormal: _vm.handleTableNormal,
        },
      }),
      _c("Edit", { ref: "edit", on: { refresh: _vm.fetchData } }),
      _c("BanLog", { ref: "banLog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }