<template>
  <div class="search-word-rank">
    <el-input
      placeholder="请输入搜索词/手机号/用户昵称"
      clearable
      v-model="form.title"
      style="width: 210px; margin-right: 10px"
    />
    <!-- 日期选择器 -->
    <el-date-picker
      v-model="dateRange"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="handleDateChange"
      style="margin-right: 10px"
    ></el-date-picker>

    <!-- 查询按钮 -->
    <el-button type="primary" @click="handleSearch">查询</el-button>

    <!-- 导出按钮 -->
    <el-button type="success" @click="handleExport">导出</el-button>

    <!-- 表格 -->
    <el-table :data="tableData" style="width: 100%; margin-top: 10px">
      <el-table-column prop="title" label="搜索词"></el-table-column>
      <el-table-column prop="nickname" label="用户昵称"></el-table-column>
      <el-table-column prop="phone" label="手机号"></el-table-column>
      <el-table-column
        prop="goods_count"
        label="搜索产品结果"
      ></el-table-column>
      <el-table-column prop="is_enter" label="是否登记"></el-table-column>
      <el-table-column prop="goods_click" label="点击产品"></el-table-column>
      <el-table-column prop="article_click" label="点击资讯"></el-table-column>
      <el-table-column prop="mall_click" label="点击店铺"></el-table-column>
      <el-table-column prop="created" label="搜索时间"></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    data() {
      return {
        dateRange: [], // 日期范围
        tableData: [], // 表格数据
        currentPage: 1, // 当前页码
        pageSize: 10, // 每页显示条数
        total: 0, // 总条数
        form: {
          start_time: '',
          end_time: '',
          title: '',
          page: 1,
          limit: 10,
          goods_count: 2, //有结果 2无结果
        },
      }
    },
    watch: {
      dateRange(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handleSearch()
    },
    methods: {
      getlist() {
        getAction('/api/form/search-rank/search-record', this.form).then(
          (res) => {
            if (res.code == 0) {
              this.tableData = res.data.list
              this.total = res.totalCount
            }
          }
        )
      },
      // 处理日期变化
      handleDateChange(val) {
        console.log('日期范围:', val)
      },
      // 处理查询
      handleSearch() {
        this.form.page = 1
        this.getlist()
      },
      // 处理导出
      handleExport() {
        postAction(
          '/api/form/search-rank/search-record-export',
          this.form
        ).then((res) => {
          this.$message({
            message: '已加入导出队列，请稍后查看',
            type: 'success',
          })
        })
      },
      // 处理每页显示条数变化
      handleSizeChange(val) {
        this.form.limit = val
        this.getlist()
      },
      // 处理页码变化
      handleCurrentChange(val) {
        this.form.page = val
        this.getlist()
      },
    },
  }
</script>

<style scoped>
  .search-word-rank {
    padding: 20px;
  }
</style>
