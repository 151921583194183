<template>
  <div>
    <el-dialog title="详情" :visible.sync="showinfo" width="50%">
      <div>
        <el-descriptions :title="form.set_meal_name" :column="2">
          <el-descriptions-item label="套餐开始时间">
            {{ form.effective_time }}
          </el-descriptions-item>
          <el-descriptions-item label="套餐结束时间">
            {{ form.failure_time }}
          </el-descriptions-item>
          <el-descriptions-item label="线上匹配查看总次数">
            {{ form.setmeal_match_views_num }}
          </el-descriptions-item>
          <el-descriptions-item label="线上匹配查看剩余次数">
            {{ form.surplus_match_views_num }}
          </el-descriptions-item>
          <el-descriptions-item label="云会议总次数">
            {{ form.setmeal_referrals_num }}
          </el-descriptions-item>
          <el-descriptions-item label="云会议剩余次数">
            {{ form.surplus_referrals_num }}
          </el-descriptions-item>
          <el-descriptions-item label="大商直通车总次数">
            {{ form.setmeal_through_train_num }}
          </el-descriptions-item>
          <el-descriptions-item label="大商直通车剩余次数">
            {{ form.surplus_through_train_num }}
          </el-descriptions-item>
          <el-descriptions-item label="发送邀请总次数">
            {{ form.setmeal_send_invite_num }}
          </el-descriptions-item>
          <el-descriptions-item label="发送邀请剩余次数">
            {{ form.surplus_send_invite_num }}
          </el-descriptions-item>
        </el-descriptions>
        <p @click="showMore()" v-if="!isShowmore" style="cursor: default">
          展开更多
          <i class="el-icon-arrow-down" style="font-size: 14px"></i>
        </p>
        <el-descriptions :column="2" v-if="isShowmore">
          <el-descriptions-item label="区域游学考察总次数">
            {{ form.setmeal_offline_study_tour }}
          </el-descriptions-item>
          <el-descriptions-item label="区域游学考察剩余次数">
            {{ form.surplus_offline_study_tour }}
          </el-descriptions-item>
          <el-descriptions-item label="抖音直播总次数">
            {{ form.setmeal_offline_explosive_products }}
          </el-descriptions-item>
          <el-descriptions-item label="抖音直播剩余次数">
            {{ form.surplus_offline_explosive_products }}
          </el-descriptions-item>
          <el-descriptions-item label="视频号直播总次数">
            {{ form.setmeal_offline_miniwecat_live }}
          </el-descriptions-item>
          <el-descriptions-item label="视频号直播剩余次数">
            {{ form.surplus_offline_miniwecat_live }}
          </el-descriptions-item>
          <el-descriptions-item label="公众号专稿推介总次数">
            {{ form.setmeal_offline_mp_article }}
          </el-descriptions-item>
          <el-descriptions-item label="公众号专稿推介剩余次数">
            {{ form.surplus_offline_mp_article }}
          </el-descriptions-item>
          <el-descriptions-item label="热品推荐视频总次数">
            {{ form.setmeal_offline_mp_video }}
          </el-descriptions-item>
          <el-descriptions-item label="热品推荐视频剩余次数">
            {{ form.surplus_offline_mp_video }}
          </el-descriptions-item>
          <el-descriptions-item label="大商直通车总次数">
            {{ form.setmeal_through_train_num }}
          </el-descriptions-item>
          <el-descriptions-item label="大商直通车剩余次数">
            {{ form.surplus_through_train_num }}
          </el-descriptions-item>
        </el-descriptions>
        <h4>历史套餐情况</h4>
        <el-table :data="form.history" style="width: 100%">
          <el-table-column prop="setmeal" label="套餐名" width="180">
            <template #default="{ row }">
              <div>{{ row.setMeal.title }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="effective_time"
            label="套餐开始时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="failure_time"
            label="套餐结束时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="delay_num"
            label="延期次数"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showinfo = false">取 消</el-button>
        <el-button type="primary" @click="showinfo = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/request'

  export default {
    name: 'infocom',
    data() {
      return {
        showinfo: false,
        form: {},
        isShowmore: false,
      }
    },
    watch: {
      showinfo(n, o) {
        if (!n) {
          this.isShowmore = false
        }
      },
    },
    methods: {
      showMore() {
        this.isShowmore = true
      },
      handlerinfo(id, mall_id) {
        postAction('/mall/setmeal/info', { id: id, meal_id: mall_id }).then(
          (res) => {
            this.form = res.data
          }
        )
      },
    },
  }
</script>

<style scoped></style>
