<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-select
          v-model="form.htyun_dept"
          clearable
          filterable
          placeholder="部门"
          style="width: 160px"
          @change="handlerbumen"
        >
          <el-option
            v-for="(list, index) in bumenSelect"
            :key="index"
            :label="list.htyun_dept"
            :value="list.htyun_dept"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.invite_name"
          clearable
          filterable
          placeholder="邀请人"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.userid"
            :label="list.htyun_name"
            :value="list.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="奖励结束时间"
          format="yyyy-MM"
          start-placeholder="奖励开始时间"
          style="width: 340px"
          type="monthrange"
          value-format="yyyy-MM"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExportdetail">
          导出全部明细
        </el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%" v-loading="loading">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template #default="{ row }">
          <el-button type="primary" @click="onEdit(row)">查看明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 弹窗 -->
    <el-dialog title="明细信息" :visible.sync="dialogVisible" width="85%">
      <div style="display: flex">
        <p>部门: {{ detailinfo.htyun_dept }}</p>
        <p style="margin-left: 20px">邀请人: {{ detailinfo.htyun_name }}</p>
        <p style="margin-left: 20px">时间: {{ detailinfo.time }}</p>
      </div>
      <el-table
        v-loading="poploading"
        border
        :data="detailData"
        style="width: 100%"
      >
        <el-table-column label="昵称" prop="nickname" align="center" />
        <el-table-column
          label="经销商公司名称"
          prop="company_name"
          align="center"
        />
        <el-table-column label="渠道" prop="company_channel" align="center" />
        <el-table-column label="客户电话" prop="phone" align="center" />
        <el-table-column label="留言类型" prop="type" align="center" />
        <el-table-column label="店铺" prop="mall_name" align="center" />
        <el-table-column label="产品" prop="goods_name" align="center" />
        <el-table-column label="留言内容" prop="content" align="center" />
        <el-table-column label="客户地区" prop="city" align="center" />
        <el-table-column label="留言时间" prop="add_time" align="center" />
        <el-table-column label="企微好友关系" prop="friend" align="center" />
        <el-table-column label="留言查看时间" prop="scan_time" align="center" />
        <el-table-column label="邀请人" prop="htyun_name" align="center" />
        <el-table-column label="符合奖励" prop="is_award" align="center" />
      </el-table>
      <el-pagination
        background
        :current-page="pagedetail"
        :layout="layout"
        :page-size="limitdetail"
        style="text-align: center; margin-top: 10px"
        :total="totaldetail"
        @current-change="handleCurrentChangedetail"
        @size-change="handleSizeChangedetail"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlerExportdetail(1)">
          导 出
        </el-button>
        <el-button type="primary" @click="closeDialog">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'Index',
    data() {
      return {
        loading: false,
        poploading: false,
        form: {
          htyun_dept: '',
          htyun_name: '',
          invite_id: '',
          invite_name: '',
          start_time: '',
          end_time: '',
          page: 1,
          limit: 10,
        },
        time: [],
        staffSelect: [],
        bumenSelect: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '部门',
            prop: 'htyun_dept',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'invite_name',
            width: '',
          },
          // {
          //   label: '已提交需求数量',
          //   prop: 'commit_count',
          //   width: '',
          // },
          // {
          //   label: '待审核需求数量',
          //   prop: 'nopass_count',
          //   width: '',
          // },
          // {
          //   label: '已通过需求数量',
          //   prop: 'pass_count',
          //   width: '',
          // },
          // {
          //   label: '需求符合奖励数量',
          //   prop: 'award_find_count',
          //   width: '',
          // },
          {
            label: '邀请留言数量',
            prop: 'invite_count',
            width: '',
          },
          {
            label: '留言被查看数量',
            prop: 'scan_count',
            width: '',
          },
          {
            label: '留言符合奖励数量',
            prop: 'award_message_count',
            width: '',
          },
          {
            label: '获得奖励数量',
            prop: 'award_count',
            width: '',
          },
          {
            label: '获得奖励金额',
            prop: 'award_money',
            width: '',
          },
        ],
        total: 0,
        dialogVisible: false,
        detailinfo: {
          htyun_dept: '',
          htyun_name: '',
          time: '',
        },
        detailData: [],
        invite_id: '',
        totaldetail: 0,
        pagedetail: 1,
        limitdetail: 10,
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      onEdit(row) {
        this.dialogVisible = true
        this.invite_id = row.invite_id
        this.pagedetail = 1
        this.getDetail(row)
        // this.detailinfo = {
        //   htyun_dept: row.htyun_dept,
        //   invite_name: row.invite_name,
        //   time: this.form.start_time + ' - ' + this.form.end_time,
        // }
        // this.detailData =
      },
      closeDialog() {
        this.dialogVisible = false
      },
      exportDetail() {
        // 导出逻辑
        console.log('导出明细')
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        await getAction('/form/form/dept').then((res) => {
          console.log(res)
          this.bumenSelect = res.data
        })
      },
      handlerbumen(v) {
        this.form.invite_name = ''
        getAction('/form/form/staff', { htyun_dept: v }).then((res) => {
          console.log(res)
          this.staffSelect = res.data
        })
      },
      getDetail() {
        this.poploading = true
        let params = {
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          invite_id: this.invite_id,
          page: this.pagedetail,
          limit: this.limitdetail,
        }
        getAction('/api/form/form/find-invite-message-detail', params).then(
          (res) => {
            this.poploading = false
            if (res.request_code == 200) {
              this.detailData = res.data.list
              this.detailinfo = res.data.info
              this.totaldetail = res.totalCount
            }
          }
        )
      },
      handlerlist() {
        this.loading = true
        getAction('/api/form/form/find-invite-tj', this.form).then((res) => {
          this.loading = false
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerExport() {
        getAction('/api/form/form/find-invite-tj-export', {
          ...this.form,
          yjhasync: 1,
        }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: '已添加导出任务',
            })
          }
        })
      },
      handlerExportdetail(type) {
        getAction('/api/form/form/find-invite-message-detail-export', {
          ...this.form,
          invite_id: type == 1 ? this.invite_id : '',
        }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: '已添加导出任务',
            })
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
      handleCurrentChangedetail(val) {
        this.pagedetail = val
        this.getDetail()
      },
      handleSizeChangedetail(val) {
        this.limitdetail = val
        this.getDetail()
      },
    },
  }
</script>

<style scoped></style>
