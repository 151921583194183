<template>
  <el-dialog :title="title" :visible.sync="showDialog" width="50%">
    <el-form ref="form" label-width="110px" :model="form">
      <el-form-item label="需求类型">
        <el-radio-group v-model="form.genre">
          <el-radio label="1">品类需求</el-radio>
          <el-radio label="2">指定店铺需求</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="匹配形式">
        <el-radio-group v-model="form.match_way">
          <el-radio label="1">需求上传</el-radio>
          <el-radio label="2">指定店铺云会议</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选择云交会店铺" v-if="form.genre == 2">
        <el-autocomplete
          v-model="form.this_mall_name"
          clearable
          :fetch-suggestions="fetchGoods"
          placeholder="请输入店铺名称"
          style="width: 400px"
          value="id"
          value-key="mall_name"
          @focus="handleFocusMall(index)"
          @select="handleSelectmall"
        />
        <!-- <div
          v-for="(item, index) in mallLimit"
          :key="index"
          style="display: flex"
        >
          <el-form-item :label="'店铺' + (index + 1)">
            <el-autocomplete
              v-model="item.name"
              clearable
              :fetch-suggestions="fetchGoods"
              placeholder="请输入店铺名称"
              style="width: 400px"
              value="id"
              value-key="mall_name"
              @focus="handleFocusMall(index)"
              @select="handleSelectmall"
            />
          </el-form-item> -->

        <!-- <i
            v-show="mallLimit.length > 1"
            class="el-icon-remove-outline"
            style="font-size: 24px; margin-left: 15px"
            @click="handledelet(index)"
          ></i>
          <i
            v-if="mallLimit.length - 1 == index && mallLimit.length < 3"
            class="el-icon-circle-plus-outline"
            style="font-size: 24px"
            @click="handleAdd"
          ></i> -->
        <!-- </div> -->
      </el-form-item>
      <el-form-item label="一级品类" v-if="form.genre == 1">
        <!-- {{ form.cat_pname }} -->
        <el-select
          v-model="form.root_cat_id"
          clearable
          placeholder="一级品类"
          style="width: 160px"
          @change="getTwocate"
        >
          <el-option
            v-for="list in onecateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="二级品类" v-if="form.genre == 1">
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级品类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.genre == 1 && form.root_cat_id == 1"
        label="价格带"
      >
        <el-checkbox-group v-model="wine_price_id" @change="handleCheckChange">
          <el-checkbox
            v-for="(item, idx) in pricelist"
            :key="idx"
            :label="item.id"
          >
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="需求详情">
        <el-input v-model="form.detail" type="textarea" />
      </el-form-item>
      <el-form-item label="付费企业推荐">
        <el-input v-model="form.rec" type="textarea" />
      </el-form-item>
      <el-form-item label="沟通方式">
        <el-select
          v-model="form.img_type"
          clearable
          placeholder="请选择"
          style="width: 160px"
        >
          <el-option
            v-for="list in connectlist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item v-if="form.img_type == 1" label="沟通截图">
        <upload-img
          ref="uploadImage"
          :info-text="infoText"
          :max-size="100"
          @uploadImg="uploadImg"
        />
      </el-form-item>
      <el-form-item v-if="form.img_type == 2" label="沟通视频">
        <upload-video
          ref="video"
          :limit="1"
          info-text=""
          @getVideos="getVideos($event, 'video')"
          :maxSize="100"
        />
      </el-form-item>
      <el-form-item v-if="form.img_type == 3" label="沟通语音">
        <upload-audio
          ref="audio_url"
          :listtype="'text'"
          :fileType="'audio'"
          :limit="1"
          info-text=""
          @getAudio="getAudio($event, 'audio_url')"
          @uploadtime="getTimes"
          :maxSize="5"
        />

        <span>{{ form.audio_url }}</span>
      </el-form-item>
      <el-form-item v-if="form.img_type == 3" label="图片说明">
        <upload-img
          ref="uploadImage2"
          :info-text="''"
          :max-size="100"
          :limit="4"
          @getImgs="getGoods_imgImgs($event, 'audio_img')"
          @uploadImg="uploadImg2"
        />
      </el-form-item>
      <el-form-item label="需求形式">
        <el-radio-group v-model="form.type">
          <el-radio label="1">找产品代理</el-radio>
          <el-radio label="2">找代工</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="提报人">
        {{ form.staff_user_name }}
      </el-form-item>
      <el-form-item label="提报时间">
        {{ form.created }}
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showDialog = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import uploadImg from '@/components/uploadImg'
  import UploadVideo from '@/components/uploadVideo'
  import UploadAudio from '@/components/uploadAudio'

  export default {
    name: 'AddEdit',
    components: {
      uploadImg,
      UploadVideo,
      UploadAudio,
    },
    props: {
      rowid: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        upLoadAudio: 'http://testbmapi.htyunjiaohui.com/api/index/upload-file',
        showDialog: false,
        title: '编辑',
        infoText: '',
        onecateselect: [], //一级品类
        catselect: [], //二级品类
        pricelist: [], //价格带
        reasonlist: [],
        remark: [],
        wine_price_id: [],
        other: '',
        form: {
          id: '',
          product_id: '',
          goods_name: '',
          sort: 0,
          status: '2',
          wine_price_id: '',
        },
        connectlist: [
          {
            id: 1,
            name: '沟通截图',
          },
          {
            id: 2,
            name: '沟通视频',
          },
          {
            id: 3,
            name: '沟通语音',
          },
        ],
        mallLimit: [
          // {
          //   name: null,
          // },
        ],
        mallidlist: [],
        audioDuration: '',
        fileList: [],
        currentid: '',
      }
    },
    watch: {
      showDialog(val) {
        if (!val) {
          this.remark = []
          this.other = ''
          this.onReset()
        }
        this.wine_price_id = this.form.wine_price_id.split(',')
      },
      'form.root_cat_id'(n, o) {
        console.log('n---------------', n, o)
        if (o) {
          this.form.cat_id = ''
        }
      },
    },
    mounted() {
      this.remark = []
      this.other = ''
      this.getPriceList()
    },

    methods: {
      // 上传语音的时长
      getTimes(time) {
        console.log('语音，', time)
        this.form.audio_time = time
      },
      handlerDetail(row) {
        console.log('沟通图，', row)
        if (row.audio_img && row.audio_img.length > 0) {
          this.$nextTick(() => {
            this.setImgArray(row.audio_img, 'uploadImage2')
          })
        }
        setTimeout(() => {
          this.setVideo(row.screenshot[0], 'video')
        }, 100)

        this.mallidlist = row.this_mall.split(',')
        console.log('指定店铺，', this.mallidlist)
        // row.this_mall_name.forEach((val, idx) => {
        //   console.log('---------------')
        //   // this.mallLimit.push({ name: null })
        //   this.mallLimit[idx].name = val
        // })
      },

      getVideos(v, prop) {
        console.log('回显,', v, prop)
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      getAudio(v, prop) {
        if (v) {
          this.form[prop] = v
        } else {
          this.form[prop] = ''
        }
      },
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      getGoods_imgImgs(v, prop) {
        if (v[0]) {
          this.form[prop] = v
        } else {
          this.form[prop] = ''
        }
      },
      setVideo(v, prop) {
        console.log('setVideo,', v, prop, this.$refs[prop])
        if (v) this.$refs[prop].Video = [v]
      },
      setImg(v, prop) {
        console.log(v, prop)
        if (v) {
          this.$refs[prop].img = [v]
        } else {
          this.$refs[prop].img = []
        }
      },
      setImgArray(v, prop) {
        if (v[0] != '') this.$refs[prop].img = v
      },
      fetchGoods(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.mall_id = ''
        } else {
          getAction('/audit/biz-clue/mall', {
            mall_name: queryString,
            id: this.rowid,
          }).then((res) => {
            cb(res.data)
          })
        }
      },
      handleFocusMall(index) {
        this.currentid = index
      },
      // 根据索引获取当前选择店铺的id
      handleSelectmall(list) {
        console.log('-----------', list, this.mallLimit)
        // this.mallidlist[this.currentid] = list.id
        this.form.this_mall = list.id
      },
      handleAdd() {
        // if (this.mallLimit.length < 3) {
        this.mallLimit.push({
          name: null,
        })
        // }
      },
      // 删除指定店铺
      handledelet(index) {
        this.mallLimit.splice(index, 1)
      },
      handleCheckChange() {},
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.screenshot = []
        } else {
          this.form.screenshot = val
        }
      },
      uploadImg2(val) {
        if (val.length < 1) {
          this.form.screenshot = []
        } else {
          this.form.screenshot = val
        }
      },
      getTwocate(e) {
        console.log('获取二级品类', e)
        getAction('/user/index/cate', { pid: e }).then((res) => {
          this.catselect = res.data
        })
      },
      handlercat(pid) {
        console.log('父级品类，', pid)
        getAction('/user/index/cate', { pid: 0 }).then((res) => {
          this.onecateselect = res.data
        })
        if (pid != 0) {
          getAction('/user/index/cate', { pid: pid }).then((res) => {
            this.catselect = res.data
          })
        }

        setTimeout(() => {
          if (this.form.screenshot.length == 1) {
            this.$refs.uploadImage.img = [this.form.screenshot]
          } else {
            this.$refs.uploadImage.img = this.form.screenshot
          }
        }, 100)
        // this.form = Object.assign(this.form, String())
      },
      getPriceList() {
        getAction('/audit/biz-clue/wine-price').then((res) => {
          if (res.request_code == 200) {
            this.pricelist = res.data
          }
        })
      },
      handlersave() {
        console.log('确定', this.form, this.wine_price_id, this.audio_img)
        console.log('选择的店铺,', this.mallidlist)
        postAction('/audit/biz-clue/edit-biz', {
          id: this.rowid,
          type: this.form.type, //1找产品代理，2找代工
          one_cate: this.form.root_cat_id, //一级品类
          two_cate: this.form.cat_id, //二级品类
          wine_price: this.wine_price_id.join(','), //价格带
          // screenshot: this.form.screenshot.join(','), //沟通截图
          detail: this.form.detail, //需求详情
          rec: this.form.rec, //付费企业推荐
          genre: this.form.genre, //需求类型
          match_way: this.form.match_way, //匹配形式
          img_type: this.form.img_type, //沟通方式
          img:
            this.form.img_type == 3
              ? this.form.audio_url
              : this.form.img_type == 2
              ? this.form.video
              : this.form.screenshot.join(','), //this.form.screenshot.join(','), //截图or视频or录音
          audio_time: this.form.audio_time, //语音时长
          audio_img:
            this.form.img_type == 3 ? this.form.screenshot.join(',') : '', //语音图片说明
          this_mall: this.form.this_mall, //this.mallidlist.join(','), //指定店铺
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.showDialog = false
          this.onReset()
          this.$emit('getlist')
        })
      },
      onReset() {
        console.log('重置数据')
        this.form = {
          id: '',
          product_id: '',
          goods_name: '',
          sort: 0,
          status: '2',
          genre: '',
          wine_price_id: '',
        }
        this.mallidlist = []
        // this.mallLimit = [{ name: null }]
      },
    },
  }
</script>

<style scoped>
  .vab-theme-blue-black .el-checkbox {
    display: block !important;
  }
</style>
