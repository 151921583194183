var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showadd, width: "60%" },
          on: {
            "update:visible": function ($event) {
              _vm.showadd = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "120px",
                    rules: _vm.rules,
                    "label-suffix": "：",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称", prop: "title" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                      _vm.form.goods_id == 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.handlerguanlian },
                            },
                            [_vm._v("关联云交会")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "卖点", prop: "sell_point" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.sell_point,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sell_point", $$v)
                          },
                          expression: "form.sell_point",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品图", prop: "cover_img" } },
                    [
                      _c("upload-img", {
                        ref: "cover_img",
                        attrs: {
                          infoText: "图片尺寸750*750，仅支持jpg和png格式图片",
                          limit: 5,
                          maxSize: 100,
                        },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImgs($event, "cover_img")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "参考信息", prop: "attr" } },
                    [
                      _vm._l(_vm.form.attr, function (list, index) {
                        return _c(
                          "el-row",
                          { key: index, attrs: { gutter: 10, type: "flex" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "分类" } },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "分类" },
                                      model: {
                                        value: list.title,
                                        callback: function ($$v) {
                                          _vm.$set(list, "title", $$v)
                                        },
                                        expression: "list.title",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "内容" } },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "内容" },
                                      model: {
                                        value: list.content,
                                        callback: function ($$v) {
                                          _vm.$set(list, "content", $$v)
                                        },
                                        expression: "list.content",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm.form.attr.length > 1
                                ? _c("i", {
                                    staticClass: "el-icon-delete-solid",
                                    on: {
                                      click: function ($event) {
                                        return _vm.form.attr.splice(index, 1)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: index < _vm.form.attr.length - 1,
                                    expression: "index < form.attr.length - 1",
                                  },
                                ],
                                staticClass: "el-icon-bottom",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerbottom(index)
                                  },
                                },
                              }),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: index > 0,
                                    expression: "index > 0",
                                  },
                                ],
                                staticClass: "el-icon-top",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlertop(index)
                                  },
                                },
                              }),
                            ]),
                          ],
                          1
                        )
                      }),
                      _c("i", {
                        staticClass: "el-icon-circle-plus-outline",
                        staticStyle: { "font-size": "24px" },
                        on: {
                          click: function ($event) {
                            return _vm.form.attr.push({
                              title: "",
                              content: "",
                              sort_order: _vm.form.attr.length + 1,
                            })
                          },
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "营养成分表" } },
                    [
                      _c("upload-img", {
                        ref: "ingredient_list",
                        attrs: { infoText: "", limit: 5, maxSize: 100 },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImgs(
                              $event,
                              "ingredient_list"
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌", prop: "brand_name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "分类" },
                        model: {
                          value: _vm.form.brand_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "brand_name", $$v)
                          },
                          expression: "form.brand_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属分类", prop: "category_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "所属分类" },
                          model: {
                            value: _vm.form.category_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "category_id", $$v)
                            },
                            expression: "form.category_id",
                          },
                        },
                        _vm._l(_vm.catelist, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排名", prop: "sort" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("bindgoods", {
                ref: "bindgoods",
                attrs: { categoryList: _vm.catelist },
                on: { setform: _vm.setform },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showadd = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }