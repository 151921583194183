var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        top: "5vh",
        visible: _vm.dialogFormVisible,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "120px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "票券标题", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: { placeholder: "请输入票券标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "票券图标", prop: "img" } },
            [
              _c("upload-img", {
                ref: "pic",
                attrs: { "info-text": "", limit: 1, "max-size": 100 },
                on: {
                  getImgs: function ($event) {
                    return _vm.getGoods_imgImg($event, "img")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "票券详情", prop: "content" } },
            [_c("wang-editor", { ref: "content" })],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "票券金额", prop: "price" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: {
                  oninput: "value=value.replace(/[^0-9.]/g,'')",
                  placeholder: "请输入票券金额",
                },
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", $$v)
                  },
                  expression: "form.price",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "票券张数", prop: "total_num" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: {
                  oninput: "value=value.replace(/[^0-9]/g,'')",
                  placeholder: "请输入票券张数",
                },
                model: {
                  value: _vm.form.total_num,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "total_num", $$v)
                  },
                  expression: "form.total_num",
                },
              }),
              _vm._v(" / 张 "),
            ],
            1
          ),
          _vm.form.issued_num > 0
            ? _c(
                "el-form-item",
                [
                  _c("font", { staticStyle: { color: "#ff4d4f" } }, [
                    _vm._v(
                      " 截至目前票券已发放 " +
                        _vm._s(_vm.form.issued_num) +
                        " / 张 "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "发放张数", prop: "send_num" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: {
                  oninput: "value=value.replace(/[^0-9]/g,'')",
                  placeholder: "请输入发放张数",
                },
                model: {
                  value: _vm.form.send_num,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "send_num", $$v)
                  },
                  expression: "form.send_num",
                },
              }),
              _vm._v(" / 张 "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "formgroup" },
            [
              _c("span", { staticClass: "formlabel" }, [_vm._v("*")]),
              _c(
                "el-form-item",
                { attrs: { label: "发放时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      align: "right",
                      "default-time": ["00:00:00", "23:59:59"],
                      "end-placeholder": "结束时间",
                      "start-placeholder": "开始时间",
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.changeDate(1)
                      },
                    },
                    model: {
                      value: _vm.time_arr,
                      callback: function ($$v) {
                        _vm.time_arr = $$v
                      },
                      expression: "time_arr",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "有效期限" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.validity_limit,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "validity_limit", $$v)
                    },
                    expression: "form.validity_limit",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("时间限制"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("永久有效"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.validity_limit == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "核销时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      align: "right",
                      "default-time": ["00:00:00", "23:59:59"],
                      "end-placeholder": "结束时间",
                      "start-placeholder": "开始时间",
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.changeDate(2)
                      },
                    },
                    model: {
                      value: _vm.time_arr2,
                      callback: function ($$v) {
                        _vm.time_arr2 = $$v
                      },
                      expression: "time_arr2",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.validity_limit == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "有效期显示" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.validity_show,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "validity_show", $$v)
                        },
                        expression: "form.validity_show",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("核销时间"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("自定义文案"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.validity_limit == 1 && _vm.form.validity_show == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "自定义文案", prop: "use_content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { placeholder: "请输入自定义文案" },
                    model: {
                      value: _vm.form.use_content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "use_content", $$v)
                      },
                      expression: "form.use_content",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "票券类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择票券类型" },
                  model: {
                    value: _vm.form.cdkey_type,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "cdkey_type",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.cdkey_type",
                  },
                },
                _vm._l(_vm.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片状态" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("启用")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("禁用")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerSave } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }