<template>
  <el-dialog
    title=""
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
  >
    <el-table
      :data="tabledata"
      border
      ref="table"
      v-loading="loading"
      row-key="user_id"
    >
      <el-table-column
        align="center"
        prop="title"
        width="150"
        label="标题"
      ></el-table-column>
      <el-table-column align="center" prop="icon_img" width="150" label="图片">
        <template #default="{ row }">
          <el-image :src="row[item.prop]" style="width: 100px; height: 100px">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="jump_type_name"
        width="150"
        label="跳转"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="id"
        width="150"
        label="排序"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="status"
        width="150"
        label="状态"
      ></el-table-column>
      <el-table-column align="center" prop="id" width="" label="操作">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerEdit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
  import { postAction, getAction } from '@/api/request'
  import edit from './menuedit'
  export default {
    name: 'BanLog',
    components: { edit },
    data() {
      return {
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        loading: false,
        showDialog: false,
        tabledata: [],
        form: {
          page: 1,
          limit: 10,
          title: '菜单标题',
          status: '', //0禁用，1启用
        },
      }
    },
    methods: {
      handlerEdit() {
        this.$refs.edit.showEditDialog = true
      },
      getData() {
        getAction('/user/business-club/lists-menu', this.form).then((res) => {
          console.log('获取数据', res)
          this.tabledata = res.data
          this.total = res.totalCount
        })
      },
    },
  }
</script>
