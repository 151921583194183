var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.form } },
        [
          _c(
            "el-form-item",
            [
              _c("el-cascader", {
                attrs: {
                  placeholder: "企微员工姓名及部门",
                  filterable: "",
                  options: _vm.options,
                  props: {
                    multiple: true,
                    leafOnly: true,
                    emitPath: false,
                    label: "name",
                    value: "id",
                  },
                  "collapse-tags": "",
                  clearable: "",
                },
                model: {
                  value: _vm.form.depart_ids,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "depart_ids", $$v)
                  },
                  expression: "form.depart_ids",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "duration" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "浏览时长（单位秒）" },
                model: {
                  value: _vm.form.duration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "duration", $$v)
                  },
                  expression: "form.duration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "mall_id" } },
            [
              _c("el-autocomplete", {
                staticStyle: { width: "220px" },
                attrs: {
                  "fetch-suggestions": _vm.handlermell,
                  "value-key": "mall_name",
                  clearable: "",
                  placeholder: "请输入店铺名称",
                },
                on: { select: _vm.handleSelectmall },
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "start_time" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "结束日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择数据段" },
                  model: {
                    value: _vm.form.is_new,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_new", $$v)
                    },
                    expression: "form.is_new",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      label: "被邀请人显示最早数据（单个店铺去重）",
                      value: 1,
                    },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "被邀请人显示最早数据（所有店铺去重）",
                      value: 2,
                    },
                  }),
                  _c("el-option", {
                    attrs: { label: "被邀请人显示全部数据", value: 0 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "是否员工" },
                  model: {
                    value: _vm.form.click_staff,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "click_staff", $$v)
                    },
                    expression: "form.click_staff",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "是", value: 1 } }),
                  _c("el-option", { attrs: { label: "否", value: 2 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "店铺" },
                  model: {
                    value: _vm.form.is_pay,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_pay", $$v)
                    },
                    expression: "form.is_pay",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "付费店铺", value: 1 } }),
                  _c("el-option", { attrs: { label: "免费店铺", value: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "邀请人", clearable: "" },
                model: {
                  value: _vm.form.htyun_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "htyun_name", $$v)
                  },
                  expression: "form.htyun_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "浏览页面类型" },
                  model: {
                    value: _vm.form.info_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "info_type", $$v)
                    },
                    expression: "form.info_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "产品", value: 4 } }),
                  _c("el-option", { attrs: { label: "店铺", value: 5 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "产品名称", clearable: "" },
                model: {
                  value: _vm.form.goods_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "goods_name", $$v)
                  },
                  expression: "form.goods_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v("导 出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              align: "center",
              label: item.label,
              prop: item.prop,
              width: item.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      item.label == "海报背景"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-image",
                                {
                                  staticStyle: {
                                    width: "30px",
                                    height: "70px",
                                  },
                                  attrs: {
                                    "preview-src-list": [row[item.prop]],
                                    src: row[item.prop],
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "placeholder" },
                                      slot: "placeholder",
                                    },
                                    [
                                      _vm._v(" 加载中 "),
                                      _c("span", { staticClass: "dot" }, [
                                        _vm._v("..."),
                                      ]),
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : item.label == "海报分类"
                        ? _c("div", [_vm._v(_vm._s(row.type.name))])
                        : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }