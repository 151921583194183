var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c("h2", { staticStyle: { "text-align": "center" } }, [
        _vm._v("答题记录"),
      ]),
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.form } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "姓名/公司名称/邀请人" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "已完成答题",
                  },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.datiStatusList, function (list, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v(" 导 出 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
            })
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetail(row)
                          },
                        },
                      },
                      [_vm._v(" 明细记录 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "明细记录", visible: _vm.isShowEdit, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.isShowEdit = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { border: "", data: _vm.tabledatalog },
                },
                _vm._l(_vm.columnslog, function (item, tableIndex) {
                  return _c("el-table-column", {
                    key: tableIndex,
                    attrs: {
                      align: "center",
                      label: item.label,
                      prop: item.prop,
                      width: item.width,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isShowEdit = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }