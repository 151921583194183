<!-- 名企类型新增or编辑弹窗 -->
<template>
  <div class="add1">
    <el-dialog :title="title" :visible.sync="showAddDialog" width="40%">
      <el-form
        :model="newItem"
        label-width="120px"
        :rules="rules"
        ref="addForm"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="newItem.title"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <upload-img
            ref="icon"
            :infoText="''"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'icon')"
            :maxSize="100"
          />
        </el-form-item>
        <el-form-item label="标题类型">
          <el-radio-group v-model="newItem.title_type">
            <el-radio label="1">文本</el-radio>
            <el-radio label="2">图片</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="平台产品数量">
          <el-input v-model="newItem.platform_num" type="number"></el-input>
        </el-form-item>
        <el-form-item label="非平台产品数量">
          <el-input v-model="newItem.no_platform_num" type="number"></el-input>
        </el-form-item>
        <el-form-item label="效果">
          <el-radio-group v-model="newItem.display_type">
            <el-radio label="1">横向展示</el-radio>
            <el-radio label="2">树列展示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="newItem.sort_order" type="number"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="newItem.status">
            <el-radio label="1">开启</el-radio>
            <el-radio label="2">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onclose">取消</el-button>
        <el-button type="primary" @click="handleAddConfirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg'
  import { nextTick } from 'vue'
  export default {
    components: { UploadImg },
    data() {
      return {
        title: '新增',
        showAddDialog: false,
        newItem: {
          title: '',
          icon: '',
          title_type: '1', //1文本 2图片
          platform_num: 0,
          no_platform_num: 0,
          display_type: '1', //1 '横向展示' 2竖列
          sort_order: 0,
          status: '1', // 1开启 2关闭
        },
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        },
      }
    },
    methods: {
      onclose(){
        this.showAddDialog = false
        this.resetForm()
      },
      getinfo(row, index) {
        if (row) {
          console.log('----row', row, index)
          this.newItem = row
          this.title = '编辑'
          this.$nextTick(() => {
            if (this.$refs['icon']) {
              this.setImg(this.newItem.icon, 'icon')
            } else {
              console.error('Ref icon is not defined in getinfo')
            }
          })
        } else {
          this.title = '新增'
        }
      },
      handleAddConfirm() {
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            postAction('/api/act/category-conf/edit', this.newItem).then(
              (res) => {
                if (res.code == 0) {
                  this.$message.success('新增成功')
                  this.showAddDialog = false
                  this.resetForm()
                  // this.getdata() // 刷新列表，重新获取数据
                } else {
                  this.$message.error('新增失败')
                }
              }
            )
          } else {
            console.log('表单验证失败')
            return false
          }
        })
      },
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.newItem[prop] = v.join(',')
        } else {
          this.newItem[prop] = ''
        }
      },
      setImg(v, prop) {
        console.log('复制--', v, prop)
        if (v && this.$refs[prop]) {
          this.$refs[prop].img = [v]
        } else {
          console.error(`Ref ${prop} is not defined or value is not provided`)
        }
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      resetForm() {
        this.newItem = {
          title: '',
          icon: '',
          title_type: '1', //1文本 2图片
          platform_num: 0,
          no_platform_num: 0,
          display_type: '1', //1 '横向展示' 2竖列
          sort_order: 0,
          status: '1', // 1开启 2关闭
        }
        this.closeimg('icon')
      },
    },
  }
</script>
<style></style>
