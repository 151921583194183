<template>
  <div style="padding: 10px">
    <el-select
      v-model="filterStatus"
      placeholder="请选择任务状态"
      @change="handleFilterChange"
      clearable
      style="margin-right: 10px"
    >
      <el-option label="待开始" value="1"></el-option>
      <el-option label="正在执行" value="2"></el-option>
      <el-option label="执行完毕" value="3"></el-option>
      <el-option label="已停止" value="4"></el-option>
    </el-select>
    <el-button type="primary" @click="search()">查询</el-button>
    <el-button type="primary" @click="onCreat()">创建任务</el-button>

    <el-table :data="tasks" style="width: 100%; margin-top: 10px" border>
      <el-table-column prop="id" label="任务ID"></el-table-column>
      <el-table-column prop="company_name" label="轮播企业"></el-table-column>
      <el-table-column prop="goods" label="轮播产品">
        <template slot-scope="scope">
          <span
            v-for="item in scope.row.goods"
            :key="item.id"
            style="margin-right: 10px"
            >{{ item.goods_name }}，</span>
        </template>
      </el-table-column>
      <el-table-column prop="task_status" label="任务状态"></el-table-column>
      <el-table-column prop="start_time" label="开始时间"></el-table-column>
      <el-table-column prop="end_time" label="结束时间"></el-table-column>
      <el-table-column prop="created" label="创建时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.task_status_id != 4"
            size="mini"
            type="text"
            @click="handleEdit(scope.$index, scope.row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="scope.row.task_status_id == 2"
            size="mini"
            type="text"
            @click="handleStop(scope.$index, scope.row)"
          >
            停止任务
          </el-button>
          <el-button
            v-if="scope.row.task_status_id == 4"
            size="mini"
            type="text"
            style="color: #333; cursor: default"
          >
            已停止
          </el-button>
          <el-button v-if="scope.row.status == 4" type="text" size="mini">
            已停止
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.$index, scope.row)"
          >
            删除任务
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <el-dialog
      title="创建任务"
      :visible.sync="showCreateTaskDialog"
      width="40%"
      :before-close="onClose"
      :close-on-click-modal="false"
    >
      <!-- 创建任务表单内容 -->
      <el-form :model="newTask" label-width="100px" :rules="rules" ref="newTaskForm">
        <el-form-item label="轮播企业" >
          <el-select
            v-model="newTask.company_id"
            clearable
            style="width: 160px"
            placeholder="请选择"
            @change="getGoodsList"
          >
            <el-option
              v-for="list in companyList"
              :key="list.id"
              :value="list.id"
              :label="list.mall_name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="轮播产品" prop="goods_ids">
          <el-select
            v-model="newTask.goods_ids"
            multiple
            filterable
            placeholder="请选择轮播产品"
            clearable
            collapse-tags>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.goods_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始结束时间">
          <el-date-picker
            v-model="timelist"
            :default-time="['00:00:00']"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            style="width: 340px"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <!-- <el-form-item label="开始时间">
          <el-date-picker
            v-model="newTask.start_time"
            type="datetime"
            placeholder="选择开始时间"
          ></el-date-picker>
        </el-form-item> -->
        <!-- <el-form-item label="结束时间">
          <el-date-picker
            v-model="newTask.end_time"
            type="datetime"
            placeholder="选择结束时间"
          ></el-date-picker>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="createTask">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    data() {
      return {
        filterStatus: '',
        selectloading: false,
        options:[],
        rules:{
          goods_ids: [
            { required: true, message: '请选择轮播产品', trigger: 'blur' },
          ],
        },
        loading: false,
        form: {
          status: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        showCreateTaskDialog: false,
        companyList: [],
        newTask: {
          company: '',
          start_time: '',
          end_time: '',
          goods_ids: [],
        },
        tasks: [],
        timelist: [],
      }
    },
    watch: {
      timelist(v) {
        console.log('-----------------------', v)
        if (v) {
          this.newTask.start_time = v[0]
          this.newTask.end_time = v[1]
        } else {
          this.newTask.start_time = ''
          this.newTask.end_time = ''
        }
      },
    },
    computed: {
      filteredTasks() {
        if (this.filterStatus) {
          return this.tasks.filter((task) => task.status === this.filterStatus)
        }
        return this.tasks
      },
    },
    mounted() {
      this.search()
    },
    methods: {
      getGoodsList(e){
          getAction('/api/afbc/act-company/task-select-goods',{
            company_id:e,
          }).then((res)=>{
            this.options = res.data
          })
      },
      onCreat() {
        this.showCreateTaskDialog = true
        this.getcompany()
      },
      onClose() {
        this.$refs.newTaskForm.resetFields()
        this.showCreateTaskDialog = false

        this.timelist = []
        this.newTask = {
          company: '',
          startTime: '',
          endTime: '',
          goods_ids: [],
        }
      },
      getcompany() {
        getAction('/api/afbc/act-company/task-company').then((res) => {
          if (res.code == 0) {
            this.companyList = res.data
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getList()
      },
      search() {
        this.form.page = 1
        this.form.task_status_id = this.filterStatus
        this.getList()
      },
      getList() {
        getAction('/api/afbc/act-company/task-list', this.form).then((res) => {
          if (res.code == 0) {
            this.tasks = res.data.list
            this.total = res.totalCount
          }
        })
      },
      // handleFilterChange(e) {
      //   // 筛选任务状态
      //   this.getList()
      // },
      handleEdit(index, row) {
        console.log('编辑任务', index, row)
        this.onCreat()
        let newData =JSON.parse(JSON.stringify(row))
        this.getGoodsList(newData.company_id)
        if(newData.goods_ids==''){
          newData.goods_ids = []
        }else{
          newData.goods_ids = newData.goods_ids.split(',')
        }
        this.newTask = newData
        this.timelist = [this.newTask.start_time, this.newTask.end_time]
      },
      handleStop(index, row) {
        console.log('停止任务', index, row)
        this.$confirm('确认停止任务？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          getAction('/api/afbc/act-company/task-edit', {
            id: row.id,
            status: '2',
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.search()
            }
          })
        })
      },
      handleDelete(index, row) {
        console.log('删除任务', index, row)
        this.$confirm('确认删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          getAction('/api/afbc/act-company/task-del', {
            id: row.id,
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.search()
            }
          })
        })
      },
      createTask() {
        this.$refs.newTaskForm.validate((valid) => {
          if (valid) {
            let newData = JSON.parse(JSON.stringify(this.newTask))
            newData.goods_ids = newData.goods_ids.join(',')
            postAction('/api/afbc/act-company/task-edit', newData).then(
              (res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg)
                }
                this.onClose()
                this.search()
              }
            )
          }
        })
        // 创建任务逻辑
        // this.tasks.push({
        //   ...this.newTask,
        //   status: 'ongoing',
        //   createTime: new Date().toLocaleString(),
        // })

        // this.showCreateTaskDialog = false
        // this.newTask = { taskId: '', company: '', startTime: '', endTime: '' }
        console.log('参数,', this.newTask)

      },
    },
  }
</script>

<style scoped>
  /* 样式可以根据需要调整 */
</style>
