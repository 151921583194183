<template>
  <el-dialog
    :title="'批量导入 ' + '\n' + '更换物流信息，请联系技术部更新'"
    :visible.sync="dialogVisible"
    width="30%"
  >
    <!-- 对话框标题部分 -->
    <template #title>
      <span>批量导入</span>
      <span style="color: #e6a23c; font-size: 12px; margin-left: 10px">
        <i class="el-icon-warning"></i>
        更换物流信息，请联系技术部更新
      </span>
    </template>
    <!-- 表单部分 -->
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <!-- 文件上传部分 -->
      <el-form-item label="选择文件:" required>
        <div class="upload-container">
          <el-upload
            ref="upload"
            accept=".xlsx,.xls"
            action=""
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
            :on-change="handleFileChange"
            :on-remove="handleFileRemove"
          >
            <template v-if="fileList.length === 0">
              <el-button size="small" type="primary">
                <i class="el-icon-upload el-icon--right"></i>
                点击上传
              </el-button>
              <div slot="tip" class="el-upload__tip" style="color: #e6a23c">
                <i class="el-icon-warning"></i>
                只能上传xlsx/xls文件
              </div>
            </template>
            <template v-else>
              <div slot="tip" class="el-upload__tip" style="color: #e6a23c">
                <i class="el-icon-warning"></i>
                请务必选择好相对应的物流公司
              </div>
            </template>
          </el-upload>
        </div>
      </el-form-item>
      <!-- 物流公司选择 -->
      <el-form-item label="物流公司:" required>
        <el-radio-group v-model="form.logisticsCompany">
          <el-radio
            v-for="(item, index) in wuliuList"
            :key="index"
            :label="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 月份选择 -->
      <el-form-item label="月份:" prop="month">
        <el-date-picker
          v-model="form.month"
          placeholder="选择时间"
          style="width: 100%"
          type="month"
          value-format="yyyy年MM月"
        />
      </el-form-item>
      <!-- 类型选择 -->
      <el-form-item label="类型:" required>
        <el-radio-group v-model="form.productType">
          <el-radio
            v-for="(item, index) in typeList"
            :key="index"
            :label="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <!-- 对话框底部按钮 -->
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleImport">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  /**
   * @description 批量导入物流信息对话框组件
   * 用于上传Excel文件并导入物流相关信息
   * 支持选择物流公司、月份和产品类型
   */
  import * as XLSX from 'xlsx'
  import { postAction } from '@/api/request'

  export default {
    name: 'ImportDialog',
    // 组件接收的属性
    props: {
      // 物流公司列表
      wuliuList: {
        type: Array,
        default: () => [],
      },
      // 产品类型列表
      typeList: {
        type: Array,
        default: () => [],
      },
    },
    // 组件数据
    data() {
      return {
        dialogVisible: false,
        fileList: [],
        fileName: '',
        form: {
          logisticsCompany: '',
          month: '',
          productType: '',
          file: null,
        },
        excelData: [],
        rules: {
          month: [{ required: true, message: '请选择月份', trigger: 'change' }],
        },
      }
    },
    // 监听属性变化
    watch: {
      // 监听产品类型列表变化，自动选择第一个类型
      typeList: {
        immediate: true,
        handler(val) {
          if (val && val.length > 0) {
            this.form.productType = val[0].id
          }
        },
      },
      // 监听物流公司列表变化，自动选择第一个物流公司
      wuliuList: {
        immediate: true,
        handler(val) {
          if (val && val.length > 0) {
            this.form.logisticsCompany = val[0].id
          }
        },
      },
    },
    methods: {
      /**
       * @description 显示导入对话框
       * 重置表单数据和文件列表
       */
      show() {
        this.dialogVisible = true
        this.fileList = []
        this.form = {
          logisticsCompany:
            this.wuliuList.length > 0 ? this.wuliuList[0].id : '',
          month: '',
          productType: this.typeList.length > 0 ? this.typeList[0].id : '',
          file: null,
        }
        this.excelData = []
      },

      /**
       * @description 处理文件移除事件
       */
      handleFileRemove() {
        this.fileList = []
        this.form.file = null
        this.excelData = []
      },

      /**
       * @description 处理文件选择变化
       * @param {Object} file - 选择的文件对象
       */
      handleFileChange(file) {
        if (file && file.raw) {
          this.fileName = file.name
          console.log('上传的文件名：', this.fileName)
          if (
            file.raw.type ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.raw.type === 'application/vnd.ms-excel'
          ) {
            this.form.file = file.raw
            this.fileList = [file]
            //
          } else {
            this.$message.warning('只能上传Excel文件!')
            this.fileList = []
          }
        }
      },

      /**
       * @description 解析Excel文件内容
       * @param {File} file - 要解析的Excel文件
       */
      async parseExcelFile(file) {
        return new Promise((resolve, reject) => {
          let arr = []
          const reader = new FileReader()
          reader.onload = (e) => {
            try {
              const data = new Uint8Array(e.target.result)
              const workbook = XLSX.read(data, { type: 'array' })
              const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
              // 使用header: 1获取原始数据（包含表头）
              const rawData = XLSX.utils.sheet_to_json(firstSheet, {
                header: 1,
              })
              // 跳过表头行，处理数据行
              if (this.form.logisticsCompany == 1) {
                //EMS
                arr = rawData
                  .slice(2)
                  .filter((row) =>
                    row.some(
                      (cell) =>
                        cell !== null && cell !== undefined && cell !== ''
                    )
                  )
                  // 添加过滤物流单号为空的数据
                  .filter((row) => {
                    const logisticsNumber = row[1]
                    return (
                      logisticsNumber !== null &&
                      logisticsNumber !== undefined &&
                      logisticsNumber !== ''
                    )
                  })
                  .map((row) => ({
                    logistics_order: row[0] || '', // 客户订单
                    logistics_number: row[1] || '', // 物流单号
                    sender_name: row[2] || '', // 发件人姓名
                    sender_phone: row[3] || '', // 发件人手机号
                    sender_mobile: row[4] || '', // 发件人电话
                    sender_address: row[5] || '', // 发件人地址
                    sender_company: row[6] || '', // 发件人公司
                    recipient_name: row[7] || '', // 收件人姓名
                    recipient_phone: row[8] || '', // 收件人手机号
                    recipient_mobile: row[9] || '', // 收件人电话
                    recipient_address: row[10] || '', // 收件人地址
                    recipient_company: row[11] || '', // 收件人公司
                    logistics_goods: row[12] || '', // 寄递产品
                    pay_type: row[13] === '寄付' ? '1' : '2', // 付款方式
                    send_price: row[14] || '', // 寄递费
                    goods_type: row[15] || '', // 商品类型
                    goods_detail: row[16] || '', // 内件详情
                    goods_weight: row[17] || '', // 重量kg
                    goods_length: row[18] || '', // 长（cm）
                    goods_width: row[19] || '', // 宽（cm）
                    goods_height: row[20] || '', // 高（cm）
                    note: row[21] || '', // 备注
                    insurance_price: row[22] || '', // 保价金额
                    collect_price: row[23] || '', // 代收货款
                    is_password: row[24] === '是' ? '1' : '0', // 密码投递（收取服务费1元）
                    return_service: row[25] === '电子返单' ? '1' : '2', // 返单服务
                  }))
                console.log('EMS数据:', arr)
              } else if (this.form.logisticsCompany == 2) {
                //圆通
                arr = rawData
                  .slice(1)
                  .filter((row) =>
                    row.some(
                      (cell) =>
                        cell !== null && cell !== undefined && cell !== ''
                    )
                  )
                  // 添加过滤物流单号为空的数据
                  .filter((row) => {
                    const logisticsNumber = row[3]
                    return (
                      logisticsNumber !== null &&
                      logisticsNumber !== undefined &&
                      logisticsNumber !== ''
                    )
                  })
                  .map((row) => ({
                    recipient_name: row[0] || '', // 收件人姓名
                    recipient_phone: row[1] || '', // 收件人手机号
                    recipient_mobile: row[2] || '', // 收件人电话
                    recipient_address: row[3] || '', // 收件人地址
                    sender_name: row[4] || '', // 发件人姓名
                    sender_phone: row[5] || '', // 发件人手机号
                    sender_mobile: row[6] || '', // 发件人电话
                    sender_address: row[7] || '', // 发件人地址
                    logistics_number: row[8] || '', // 物流单号
                    goods_name: row[9] || '', // 商品名称
                    goods_num: row[10] || '', // 商品数量
                    note: row[11] || '', // 备注
                    collect_price: row[12] || '', // 代收货款
                    insurance_price: row[13] || '', // 保价金额
                    cod_price: row[14] || '', // 到付费
                    goods_weight: row[15] || '', // 重量kg
                  }))
                console.log('圆通数据:', arr)
              }
              resolve(arr)
            } catch (error) {
              reject(error)
            }
          }
          reader.onerror = () => reject(new Error('文件读取失败'))
          reader.readAsArrayBuffer(file)
        })
      },
      close() {
        this.dialogVisible = false
        this.fileList = []
        this.form.file = null
        this.excelData = []
      },
      /**
       * @description 处理导入操作
       * 验证表单数据并处理Excel数据
       */
      handleImport() {
        // 验证表单
        if (!this.form.file) {
          this.$message.warning('请上传Excel文件')
          return
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.uploadImport()
          }
        })
      },
      uploadImport() {
        //将上传的文件赋值给数组
        let filedata = this.form.file
        this.parseExcelFile(filedata).then((data) => {
          this.excelData = data
          console.log('转换的数据：', this.excelData)
          if (this.excelData.length == 0) {
            this.$message.warning('Excel文件无有效数据')
            return
          }
          console.log('文件名：', this.fileName)
          // 根据ID获取物流公司名称
          const selectedCompany = this.wuliuList.find(
            (item) => item.id === this.form.logisticsCompany
          )
          // 判断上传的文件名是否包含选择的物流公司名称
          if (
            !selectedCompany ||
            this.fileName.indexOf(selectedCompany.name) === -1
          ) {
            this.$message.warning('上传的Excel文件和选择的物流公司不匹配')
            return
          }
          // 处理导入数据，为每条数据添加物流公司、月份和产品类型信息
          const processedData = this.excelData.map((item) => {
            return {
              ...item,
              logisticsCompany: this.form.logisticsCompany,
              month: this.form.month,
              productType: this.form.productType,
            }
          })
          console.log('文件+物流公司等信息：', processedData)
          postAction('/user/logistics/import-logistics', {
            data: JSON.stringify(processedData),
            logistics_company_id: this.form.logisticsCompany,
            goods_type: this.form.productType,
            month: this.form.month,
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '已开始上传，请耐心等待',
              })
              this.close()
              this.$emit('import-confirm')
              this.$message.success('导入成功')
            } else {
              this.$message.warning(res.msg)
            }
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-upload__tip {
    line-height: 1.2;
    margin-top: 0;
    margin-left: 10px;
    display: inline-block;
  }

  .el-upload {
    display: flex;
    align-items: center;
  }

  .upload-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  :deep(.el-upload-list) {
    margin: 0 0 0 10px;
  }
</style>
