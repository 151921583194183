<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="分配结束日期"
          format="yyyy-MM-dd"
          start-placeholder="分配开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="follow_time"
          end-placeholder="跟进结束日期"
          format="yyyy-MM-dd"
          start-placeholder="跟进开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.source" placeholder="请选择来源">
          <el-option label="分配" value="1"></el-option>
          <el-option label="抢单" value="2"></el-option>
          <el-option label="网格化" value="3"></el-option>
          <el-option label="星级提升" value="4"></el-option>
          <el-option label="万商重点企业" value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.follow_status" placeholder="请选择跟进结果" clearable >
          <el-option label="提需求" value="1"></el-option>
          <el-option label="未联系上" value="2"></el-option>
          <el-option label="非行业人士" value="3"></el-option>
          <el-option label="无效数据" value="4"></el-option>
          <el-option label="暂无找品需求" value="5"></el-option>
          <el-option label="有已对接" value="6"></el-option>
         <el-option label="有找品需求" value="7"></el-option>
          <el-option label="云交会已合作" value="8"></el-option>
          <el-option label="有效沟通" value="9"></el-option>
         </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.staff_user_id" placeholder="请选择客服" clearable filterable multiple collapse-tags>
          <el-option
            v-for="(item, index) in staffList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.friend_flag" placeholder="请选择好友关系">
          <el-option label="非企微非微信好友" value="1"></el-option>
          <el-option label="微信好友（企微非好友）" value="2"></el-option>
          <el-option label="企微好友（微信非好友）" value="3"></el-option>
          <el-option label="企微好友且微信好友" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.platform_flag" placeholder="请选择平台厂家">
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.nickname" placeholder="请输入云交会昵称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.platform" placeholder="请输入企业名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column type="index" width="55" align="center"></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :align="item.align"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { postAction } from "@/api/request";
import * as excel from "@/utils/excel";
import dayjs from "dayjs";

export default {
  name: "index",
  data() {
    return {
      time:[dayjs(new Date()).format('YYYY-MM-DD'),dayjs(new Date()).format('YYYY-MM-DD')],
      follow_time:[],
      staffList:[],
      tabledata: [],
      form: {
        page: 1,
        limit: 10,
        /**
         * 企业名称
         */
        company_name:'',
        /**
         * 分配结束日期，年月日
         */
        divide_end_time:dayjs(new Date()).format('YYYY-MM-DD'),
        /**
         * 分配开始日期，年月日
         */
        divide_start_time:dayjs(new Date()).format('YYYY-MM-DD'),
        /**
         * 跟进结束时间
         */
        follow_end_time:'',
        /**
         * 跟进开始时间
         */
        follow_start_time:'',
        /**
         * 跟进结果，1有效沟通，2未联系上，3非行业人士，4无效数据，5暂无找品需求，6有待对接，7有已对接
         */
        follow_status:'',
        /**
         * 好友关系，1非企微非微信好友，2微信好友（企微非好友），3企微好友（微信非好友），4企微好友且微信好友
         */
        friend_flag:'',
        /**
         * 云交会昵称
         */
        nickname:'',
        /**
         * 平台厂家，1是，2否
         */
        platform:'',
        /**
         * 来源，1分配，2抢单，3网格化，4星级提升
         */
        source:'',
        /**
         * 客服userid
         */
        staff_user_id:[],
      },
      total: 0,
      layout: "total, sizes, prev, pager, next, jumper",
      columns: [
        {
          label: "分配日期",
          prop: "divide_date",
          width: "110",
          align: "center",
        },
        {
          label: "云交会昵称",
          prop: "nickname",
          width: "220px",
          align: "center",
        },
        {
          label: "企业名称",
          prop: "company_name",
          width: "120",
          align: "center",
        },
        {
          label: "手机号",
          prop: "mobile",
          width: "120",
          align: "center",
        },
        {
          label: "跟进客服",
          prop: "htyun_name",
          width: "80",
          align: "center",
        },
        {
          label: "来源",
          prop: "source",
          width: "110",
          align: "center",
        },
        {
          label: "加好友",
          prop: "friend_flag",
          width: "110",
          align: "center",
        },
        {
          label: "有效沟通",
          prop: "is_effect",
          width: "110",
          align: "center",
        },
        {
          label: "跟进结果",
          prop: "follow_status",
          width: "80",
          align: "center",
        },
        {
          label: "厂家",
          prop: "factory",
          width: "120",
          align: "center",
        },
        {
          label: "平台厂家",
          prop: "is_platform",
          width: "120",
          align: "center",
        },
        {
          label: "付费/免费",
          prop: "pay_txt",
          width: "120",
          align: "center",
        },
        {
          label: "业务",
          prop: "yewu",
          width: "120",
          align: "center",
        },
        {
          label: "产品",
          prop: "goods",
          width: "120",
          align: "center",
        },
        {
          label: "是否云交会合作",
          prop: "is_coop",
          width: "",
          align: "center",
        },
        {
          label: "打款金额",
          prop: "price",
          width: "",
          align: "center",
        },
        {
          label: "一级品类需求",
          prop: "one_cate",
          width: "",
          align: "center",
        },
        {
          label: "二级品类需求",
          prop: "two_cate",
          width: "",
          align: "center",
        },
        {
          label: "企微需求",
          prop: "is_biz",
          width: "110",
          align: "center",
        },
        {
          label: "备注",
          prop: "remark",
          width: "120",
          align: "center",
        },
      ],
    };
  },
  watch: {
    time(v) {
      if (v) {
        this.form.divide_start_time = v[0]
        this.form.divide_end_time = v[1]
      } else {
        this.form.divide_start_time = ''
        this.form.divide_end_time = ''
      }
    },
    follow_time(v) {
      if (v) {
        this.form.follow_start_time = v[0]
        this.form.follow_end_time = v[1]
      } else {
        this.form.follow_start_time = ''
        this.form.follow_end_time = ''
      }
    }
  },
  mounted() {
    this.handlerlist();
    this.handlerSelect()
  },
  methods: {

    handlerSelect(){
      postAction('/api/form/crm/custom-lists').then((res) => {
        console.log(res)
        this.staffList = res.data
      })
    },
    handlerlist() {
      let data = JSON.parse(JSON.stringify(this.form))
      data.staff_user_id = data.staff_user_id.join(',')
      postAction("/api/form/crm/lists", data).then((res) => {
        this.tabledata = res.data.list;
        this.total = Number(res.totalCount);
      });
    },
    handlerInquire() {
      this.form.page = 1;
      this.handlerlist();
    },
    handlerExport() {
      let data = JSON.parse(JSON.stringify(this.form))
      data.staff_user_id = data.staff_user_id.join(',')
      postAction("/api/form/crm/export-lists", data).then(
        (res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          });
        }
      );
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.handlerlist();
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.handlerlist();
    },
  },
};
</script>

<style scoped>

</style>
