<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="55%"
  >
    <el-form ref="form" inline :model="queryForm">
      <el-form-item>
        <el-input
          v-model.trim="queryForm.keywords"
          clearable
          placeholder="请输入搜索内容"
          style="width: 250px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model.trim="queryForm.status"
          clearable
          placeholder="核销状态"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        align="center"
        label="ID"
        prop="id"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="用户昵称"
        prop="nickname"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="票券码"
        prop="code"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="票券来源"
        prop="source"
        width="100"
      >
        <template #default="{ row }">
          <font v-if="row.source == 1">商业家会员</font>
          <font v-else-if="row.source == 2">活动抽奖</font>
          <font v-else-if="row.source == 3">邀约裂变</font>
          <font v-else-if="row.source == 4">联络员邀约</font>
          <font v-else-if="row.source == 5">报名签到</font>
          <font v-else>--</font>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="核销状态"
        prop="status"
        width="100"
      >
        <template #default="{ row }">
          <font v-if="row.status == 0">未核销</font>
          <font v-else-if="row.status == 1">已核销</font>
          <font v-else>已扣除</font>
        </template>
      </el-table-column>
      <el-table-column align="center" label="核销时间" width="150">
        <template #default="{ row }">
          <span v-if="row.status == 1">
            {{ row.modified }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="发放时间"
        prop="created"
        show-overflow-tooltip
        width="150"
      />
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        prop="id"
        width="100"
      >
        <template #default="{ row }">
          <el-button
            v-if="row.status == 0"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleDeductCdkey(row)"
          >
            扣除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </el-dialog>
</template>
<script>
  import { delBanner, getAction } from '@/api/request'
  export default {
    name: 'SendCdkeyLog',
    data() {
      return {
        list: [],
        title: '',
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          stystem_id: '',
          cdkey_id: '',
          keywords: '',
          status: '',
          page: 1,
          limit: 10,
        },
        statusOptions: [
          {
            value: '',
            label: '全部',
          },
          {
            value: '0',
            label: '未核销',
          },
          {
            value: '1',
            label: '已核销',
          },
          {
            value: '2',
            label: '已扣除',
          },
        ],
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      handleDeductCdkey(row) {
        this.$confirm(
          '请确认是否扣除此用户票卷，扣除后票卷将不可恢复！',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            delBanner('/user/cdkey-code/cdkey-deduct', { id: row.id }).then(
              (res) => {
                if (res.request_code == 200) {
                  this.$message.success(res.msg)
                  this.fetchData()
                } else {
                  this.$message.error('扣除失败')
                }
              }
            )
          })
          .catch(() => {})
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData(val) {
        getAction('/user/cdkey-code/send-cdkey-log', this.queryForm).then(
          (res) => {
            this.list = res.data
            this.total = res.totalCount
            this.listLoading = false
          }
        )
      },
      showData(row) {
        ;(this.dialogFormVisible = true), (this.listLoading = true)
        if (row.stystem_id) {
          this.queryForm.stystem_id = row.stystem_id
        }
        if (row.cdkey_id) {
          this.queryForm.cdkey_id = row.cdkey_id
        }
        this.fetchData()
      },
    },
  }
</script>
