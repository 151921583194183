import { render, staticRenderFns } from "./secondary.vue?vue&type=template&id=7b698f22&scoped=true"
import script from "./secondary.vue?vue&type=script&lang=js"
export * from "./secondary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b698f22",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\work\\yunjiaohui\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b698f22')) {
      api.createRecord('7b698f22', component.options)
    } else {
      api.reload('7b698f22', component.options)
    }
    module.hot.accept("./secondary.vue?vue&type=template&id=7b698f22&scoped=true", function () {
      api.rerender('7b698f22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/systemSet/theme/components/secondary.vue"
export default component.exports