<template>
  <div>
    <!-- 日期选择器 -->
    <el-date-picker
      v-model="time"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      style="margin-right: 10px"
    ></el-date-picker>

    <!-- 查询按钮 -->
    <el-button type="primary" @click="handleSearch">查询</el-button>

    <!-- 导出按钮 -->
    <el-button type="success" @click="handlerExport">导出</el-button>

    <!-- 表格 -->
    <el-table border :data="tableData" style="width: 100%; margin-top: 10px">
      <el-table-column prop="total_pv" label="总浏览次数PV"></el-table-column>
      <el-table-column prop="total_uv" label="总访客数UV"></el-table-column>
      <el-table-column
        prop="share_friend"
        label="员工分享好友次数"
      ></el-table-column>
      <el-table-column
        prop="share_circle"
        label="员工分享朋友圈次数"
      ></el-table-column>
      <el-table-column prop="invite_uv" label="邀请进入的UV"></el-table-column>
      <el-table-column prop="invite_pv" label="邀请进入的PV"></el-table-column>
    </el-table>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'

  export default {
    name: 'Index',
    data() {
      return {
        time: [],
        form: {
          start_time: '',
          end_time: '',
        },
        total: '',
        tableData: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handleSearch()
    },
    methods: {
      handleSearch() {
        getAction('/api/afbc/act-company/afbc-tj', this.form).then((res) => {
          this.tableData = [res.data]
          this.total = Number(res.totalCount)
        })
      },
      handlerExport() {
        postAction('/api/afbc/act-company/afbc-tj-export', this.form).then(
          (res) => {
            this.$message({
              message: '已加入导出队列，请稍后查看',
              type: 'success',
            })
          }
        )
      },
    },
  }
</script>
