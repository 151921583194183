<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";

export default {
  // name: "liulist",
  props: {
    catelist: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showliu: false,
      form: {
        keywords: '',
        start_time: '',
        end_time: '',
        page: 1,
        limit: 10,
        category_id:'',
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: 'id',
          prop: 'id',
          width: '',
        },

        {
          label: '榜单产品名称',
          prop: 'title',
          width: '160',
        },
        {
          label: '品类',
          prop: 'cate_name',
          width: '160',
        },

        {
          label: '用户ID',
          prop: 'user_id',
          width: '',
        },
        {
          label: '姓名(用户名)',
          prop: 'realname',
          width: '',
        },
        {
          label: '昵称',
          prop: 'nickname',
          width: '',
        },
        {
          label: '公司名称',
          prop: 'company_name',
          width: '',
        },
        {
          label: '注册电话',
          prop: 'mobile',
          width: '',
        },
        {
          label: '留言时间',
          prop: 'message_created',
          width: '',
        },
      ],
      total: 0,
      time: [],
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    },
    showliu(val) {
      if (!val) {
        this.time = []
        this.form = this.$options.data().form
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    onDelete(row){
      postAction('/api/other/cate-rank/delete-message', {id:row.id}).then((res) => {
        if (res.request_code == 200) {
          this.getList()
        }
      })
    },
    handlerExport() {
      let data = JSON.parse(JSON.stringify(this.form))
      postAction('/api/other/cate-rank/message-export', data).then((res) => {
        excel.export_json_to_excel({
          header: res.data.header,
          data: res.data.data,
          filename: res.data.name,
        })
      })
    },
    onSearch() {
      this.form.page = 1
      this.getList()
    },
    getList() {
      getAction('/api/other/cate-rank/message', this.form).then(
        (res) => {
          if (res.request_code == 200) {
            this.tabledata = res.data
            this.total = parseInt(res.totalCount)
          }
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.getList()
    },
  },
};
</script>

<template>
  <el-dialog
    :visible.sync="showliu"
    width="60%">
    <span><el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入产品标题/手机号/用户id"
          clearable
          v-model="form.keywords"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
          <el-select v-model="form.category_id" placeholder="请选择分类" clearable>
            <el-option
              v-for="item in catelist"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '封面图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template #default="{ row }">
          <el-popconfirm title="确认删除？" @confirm="onDelete(row)">
            <el-button slot="reference" type="text" size="small">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination></span>
<!--    <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="showliu = false">取 消</el-button>-->
<!--    <el-button type="primary" @click="showliu = false">确 定</el-button>-->
<!--  </span>-->
  </el-dialog>
</template>

<style scoped>

</style>
