var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "分配结束日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "分配开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择客服",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.form.staff_user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staff_user_id", $$v)
                    },
                    expression: "form.staff_user_id",
                  },
                },
                _vm._l(_vm.staffList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerImport },
                },
                [_vm._v("分配任务导入")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            data: _vm.tabledata,
            "show-summary": "",
            "summary-method": _vm.getSummaries,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", width: "55", align: "center" },
          }),
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: item.align,
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "广告图"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      "preview-src-list": [row[item.prop]],
                                      src: row[item.prop],
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "placeholder" },
                                        slot: "placeholder",
                                      },
                                      [
                                        _vm._v(" 加载中 "),
                                        _c("span", { staticClass: "dot" }, [
                                          _vm._v("..."),
                                        ]),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : item.label == "操作"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onsave(row)
                                      },
                                    },
                                  },
                                  [_vm._v("查看详情")]
                                ),
                              ],
                              1
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("h4", { staticStyle: { color: "red" } }, [
        _vm._v("跟进率=跟进数量÷分配数量x100% 未跟进=分配数量-跟进数量"),
      ]),
      _c("h4", { staticStyle: { color: "red" } }, [
        _vm._v(
          "有效沟通率=有效沟通÷(有效沟通+未联系上+非行业人士+无效数据+暂无找品需求)x100%"
        ),
      ]),
      _c("info", { ref: "info" }),
      _c("import-ser", { ref: "importSer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }