var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "600px",
        top: "13vh",
        "close-on-click-modal": false,
        "append-to-body": "",
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "right",
                "label-suffix": ":",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "直播标题", prop: "" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      "fetch-suggestions": _vm.handlerlive,
                      placeholder: "请输入直播标题",
                      "value-key": "title",
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                  _c("span", [_vm._v("可输入关键词模糊搜索")]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "直播栏目", prop: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { filterable: "", clearable: "", placeholder: "" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.selectList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "首页轮播图", prop: "" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "active-value": "1",
                      "inactive-color": "#ccc",
                      "inactive-value": "0",
                      "active-text": "是",
                      "inactive-text": "否",
                    },
                    model: {
                      value: _vm.form.is_bannel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_bannel", $$v)
                      },
                      expression: "form.is_bannel",
                    },
                  }),
                  _vm.form.is_bannel == 1
                    ? _c("upload-img", {
                        ref: "uploadImage",
                        attrs: {
                          "info-text":
                            "尺寸：720 * 280像素,仅支持png、jpg格式图片",
                          limit: 1,
                          "max-size": 100,
                        },
                        on: { uploadImg: _vm.uploadImg },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预告推荐", prop: "" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "active-value": "1",
                      "inactive-color": "#ccc",
                      "inactive-value": "0",
                      "active-text": "是",
                      "inactive-text": "否",
                    },
                    model: {
                      value: _vm.form.is_herald_recom,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_herald_recom", $$v)
                      },
                      expression: "form.is_herald_recom",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最新直播", prop: "" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "active-value": "1",
                      "inactive-color": "#ccc",
                      "inactive-value": "0",
                      "active-text": "是",
                      "inactive-text": "否",
                    },
                    model: {
                      value: _vm.form.is_inlatestlive,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_inlatestlive", $$v)
                      },
                      expression: "form.is_inlatestlive",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否上架", prop: "" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "active-value": "1",
                      "inactive-color": "#ccc",
                      "inactive-value": "0",
                      "active-text": "是",
                      "inactive-text": "否",
                    },
                    model: {
                      value: _vm.form.rel_status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "rel_status", $$v)
                      },
                      expression: "form.rel_status",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-button" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }