var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-word-rank" },
    [
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "value-format": "yyyy-MM-dd",
        },
        on: { change: _vm.handleDateChange },
        model: {
          value: _vm.dateRange,
          callback: function ($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange",
        },
      }),
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "10px" },
          attrs: { type: "primary" },
          on: { click: _vm.handleSearch },
        },
        [_vm._v(" 查询 ")]
      ),
      _c(
        "el-button",
        { attrs: { type: "success" }, on: { click: _vm.handleExport } },
        [_vm._v("导出")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: { data: _vm.tableData },
        },
        [
          _c("el-table-column", { attrs: { prop: "title", label: "搜索词" } }),
          _c("el-table-column", {
            attrs: { prop: "lookup_count", label: "搜索次数" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.page,
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.form.limit,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }