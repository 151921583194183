<template>
  <div class="version-set">
    <div class="operation-bar">
      <el-button type="primary" @click="handleAdd">新增</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        prop="name"
        label="分类名称"
        width="180"
      ></el-table-column>
      <el-table-column prop="note" label="备注"></el-table-column>
      <el-table-column prop="status" label="状态" width="120">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status == 1 ? 'success' : 'info'">
            {{ scope.row.status == 1 ? '启用' : '禁用' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleEdit(scope.row)">
            <!-- {{ scope.row.status == 1 ? '禁用' : '启用' }} -->
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增弹窗 -->
    <el-dialog
      title="新增分类"
      :visible.sync="dialogVisible"
      width="500px"
      :append-to-body="true"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formRef"
        label-width="100px"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input
            v-model="formData.name"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input
            v-model="formData.note"
            type="textarea"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="formData.status" placeholder="请选择状态">
            <el-option label="启用" :value="'1'"></el-option>
            <el-option label="禁用" :value="'0'"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'VersionSet',
    data() {
      return {
        tableData: [],
        dialogVisible: false,
        formData: {
          name: '',
          note: '',
          status: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入分类名称', trigger: 'blur' },
          ],
          status: [
            { required: true, message: '请选择状态', trigger: 'change' },
          ],
        },
      }
    },
    mounted() {
      this.getList()
    },
    watch: {
      dialogVisible(val) {
        if (!val) {
          this.resetForm()
        }
      },
    },
    methods: {
      handleClose() {
        this.resetForm()
        this.dialogVisible = false
      },
      getList() {
        getAction('/mall/index/version-list').then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.tableData = res.data
          }
        })
      },
      handleEdit(row) {
        console.log('编辑', row)
        this.dialogVisible = true
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },

      // 添加新增方法
      handleAdd() {
        this.dialogVisible = true

        this.formData = {
          categoryName: '',
          remark: '',
          status: '',
        }
      },
      submitForm() {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            console.log('表单提交', this.formData)
            postAction('/mall/index/version-edit', this.formData).then(
              (res) => {
                if (res.code == 0) {
                  this.$message.success('编辑成功')
                  this.dialogVisible = false
                  this.getList()
                  this.resetForm()
                }
              }
            )
          } else {
            return false
          }
        })
      },
      resetForm() {
        this.formData = {
          name: '',
          note: '',
          status: '',
        }
        this.$refs.formRef.resetFields()
      },
    },
  }
</script>

<style scoped>
  .version-set {
    padding: 20px;
  }
  .dialog-footer {
    text-align: right;
  }
</style>
