import { render, staticRenderFns } from "./add.vue?vue&type=template&id=592e1277&scoped=true&"
import script from "./add.vue?vue&type=script&lang=js&"
export * from "./add.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "592e1277",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\shangjiaGit\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('592e1277')) {
      api.createRecord('592e1277', component.options)
    } else {
      api.reload('592e1277', component.options)
    }
    module.hot.accept("./add.vue?vue&type=template&id=592e1277&scoped=true&", function () {
      api.rerender('592e1277', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/Wanshang/Popular/components/add.vue"
export default component.exports