<template>
  <el-dialog title="预览" :visible.sync="show" width="90%">
    <div>
      <el-descriptions border :column="1">
        <el-descriptions-item label="编号">
          {{ form.goods_sn }}
        </el-descriptions-item>
        <el-descriptions-item label="品类">
          {{ form.cat_txt }}
        </el-descriptions-item>
        <el-descriptions-item label="细分品类">
          {{ form.cat_p_txt }}
        </el-descriptions-item>
        <el-descriptions-item label="产品名称">
          {{ form.goods_name }}
        </el-descriptions-item>
<!--        <el-descriptions-item label="产品亮点">-->
<!--          {{ form.short_point }}-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item label="渠道到岸价">
          {{ form.channel_price }}
        </el-descriptions-item>
        <el-descriptions-item label="产品标签">
          <span v-for="list in form.goods_labels" style="margin-left: 10px">
            {{ list.name }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="渠道">
          <span v-for="list in form.goods_channel" style="margin-left: 10px">
            {{ list.channel_name }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="规格内容">
          <div v-if="form.attr_category">品类-{{ form.attr_category }}</div>
          <div v-if="form.attr_spec">包装规格-{{ form.attr_spec }}</div>
          <div v-if="form.attr_date">保质期-{{ form.attr_date }}</div>
          <div v-if="form.market_price">市场零售价-{{ form.market_price }}</div>
          <div v-if="form.attr_area">招商区域-{{ form.attr_area }}</div>
          <div
            v-for="(list, index) in form.goods_specifications"
            :key="index"
            style="margin-left: 10px"
          >
            {{ list.attr_name }}-{{ list.attr_value }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="规格-品类" v-if="form.attr_category">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.attr_category"
          />
        </el-descriptions-item>
        <el-descriptions-item label="规格-包装规格" v-if="form.attr_spec">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.attr_spec"
          />
        </el-descriptions-item>
        <el-descriptions-item label="规格-保质期" v-if="form.attr_date">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.attr_date"
          />
        </el-descriptions-item>
        <el-descriptions-item label="规格-招商区域" v-if="form.attr_area">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.attr_area"
          />
        </el-descriptions-item>
        <el-descriptions-item label="渠道到岸价" v-if="form.channel_price">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.channel_price"
          />
        </el-descriptions-item>
        <el-descriptions-item label="产品亮点" v-if="form.short_point">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.short_point"
          />
        </el-descriptions-item>
        <el-descriptions-item label="产品主图">
          <el-image
            :preview-src-list="[form.goods_img]"
            :src="form.goods_img"
            style="width: 100px; height: 100px"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="产品相册">
          <div v-for="list in form.goods_list_img">
            <el-image
              :preview-src-list="[list]"
              :src="list"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="产品描述">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.goods_desc"
          />
        </el-descriptions-item>
        <el-descriptions-item label="产品价格">
          {{ form.market_price }}
        </el-descriptions-item>
        <el-descriptions-item label="视频">
          <video
            v-if="form.video && form.video.length > 0"
            :src="form.video"
            style="height: 100px; width: 200px"
            controls
          ></video>
        </el-descriptions-item>
        <el-descriptions-item label="摘要">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.remark"
          />
        </el-descriptions-item>
        <el-descriptions-item label="卖点">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.point"
          />
        </el-descriptions-item>
        <el-descriptions-item label="招商政策">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            class="textimgw"
            v-html="form.policy"
          />
        </el-descriptions-item>
        <el-descriptions-item label="排序">
          {{ form.sort_order }}
        </el-descriptions-item>
        <el-descriptions-item label="是否可以申请样品">
          <el-tag v-if="form.demo_apply == 1" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="样品库存">
          {{ form.goods_number }}
        </el-descriptions-item>
        <el-descriptions-item label="是否上架">
          <el-tag v-if="form.is_on_sale == 1" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="show = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { postAction } from '@/api/request'

  export default {
    name: 'Browse',
    data() {
      return {
        show: false,
        form: {},
      }
    },
    methods: {
      diashow() {
        this.show = true
      },
      clean() {
        this.show = false
      },
      handlerinfo(id) {
        postAction('/mall/product/goods-view', { goods_id: id }).then((res) => {
          this.form = res.data
        })
      },
    },
  }
</script>

<style scoped>
  ::v-deep .textimgw img {
    max-width: 80% !important;
    height: auto !important;
  }
</style>
