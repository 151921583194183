var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: "", "label-suffix": ":", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "回复状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusselect, function (list, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "拨打状态" },
                  model: {
                    value: _vm.form.is_call,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_call", $$v)
                    },
                    expression: "form.is_call",
                  },
                },
                _vm._l(_vm.callselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "110px" },
                attrs: { clearable: "", placeholder: "联系方式" },
                model: {
                  value: _vm.form.contact,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contact", $$v)
                  },
                  expression: "form.contact",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "请选择商品" },
                model: {
                  value: _vm.form.goods_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "goods_name", $$v)
                  },
                  expression: "form.goods_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "一级分类" },
                  on: { change: _vm.handlercat },
                  model: {
                    value: _vm.form.cat_pid,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cat_pid", $$v)
                    },
                    expression: "form.cat_pid",
                  },
                },
                _vm._l(_vm.cateselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "二级分类" },
                  model: {
                    value: _vm.form.cat_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cat_id", $$v)
                    },
                    expression: "form.cat_id",
                  },
                },
                _vm._l(_vm.catselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "姓名" },
                model: {
                  value: _vm.form.realname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "realname", $$v)
                  },
                  expression: "form.realname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "店铺名称" },
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "province_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "省" },
                  on: { change: _vm.handleProChange },
                  model: {
                    value: _vm.form.province_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "province_id", $$v)
                    },
                    expression: "form.province_id",
                  },
                },
                _vm._l(_vm.provinceSelect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: Number(list.id) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "city_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "市" },
                  model: {
                    value: _vm.form.city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "city_id", $$v)
                    },
                    expression: "form.city_id",
                  },
                },
                _vm._l(_vm.citylist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: Number(list.id) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "结束日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              align: "center",
              label: item.label,
              prop: item.prop,
              width: item.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      item.label == "店铺名称"
                        ? _c("div", [
                            _vm._v(" " + _vm._s(row.mall.mall_name) + " "),
                          ])
                        : item.label == "留言产品"
                        ? _c("div", [
                            _vm._v(" " + _vm._s(row.goods[item.prop]) + " "),
                          ])
                        : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }