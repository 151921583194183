var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogFormVisible, width: "65%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "el-form",
            {
              attrs: { inline: true, model: _vm.queryForm },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleEdit },
                    },
                    [_vm._v(" 创建任务 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.list, border: "" },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              prop: "id",
              width: "80",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "增加虚拟浏览量",
              prop: "num",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "任务模式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.plan_type == 1
                      ? _c("span", [_vm._v("单次任务")])
                      : _c("span", [_vm._v("分时任务")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "执行模式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.model == 1
                      ? _c("span", [_vm._v("立即执行")])
                      : _c("span", [_vm._v("延时执行")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "任务时长",
              prop: "time",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "任务状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.status == 0
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("待执行"),
                        ])
                      : _vm._e(),
                    row.status == 1
                      ? _c("el-tag", [_vm._v("执行中")])
                      : _vm._e(),
                    row.status == 2
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("执行完毕"),
                        ])
                      : _vm._e(),
                    row.status == 3
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("执行终止"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "执行时间",
              prop: "execute_time",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "160", label: "结束时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.status == 2
                      ? _c("span", [
                          _vm._v(" " + _vm._s(row.finish_time) + " "),
                        ])
                      : _vm._e(),
                    row.status == 3
                      ? _c("span", [_vm._v(" " + _vm._s(row.abort_time) + " ")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "created",
              width: "160",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.status == 0 || row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDel(row)
                              },
                            },
                          },
                          [_vm._v(" 终止任务 ")]
                        )
                      : _vm._e(),
                    row.plan_type == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlequeueLog(row)
                              },
                            },
                          },
                          [_vm._v(" 队列详情 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.queryForm.page,
          layout: _vm.layout,
          "page-size": _vm.queryForm.limit,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("queueLog", { ref: "queueLog" }),
      _c("edit-live-task", {
        ref: "editLiveTask",
        on: { "fetch-data": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }