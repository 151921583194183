<template>
  <div>
    <el-dialog :title="title" :visible.sync="addbanner" width="50%">
      <div>
        <el-radio-group v-model="catType" style="margin-bottom: 30px">
          <span v-for="item in catTypeList" :key="item.id">
            <el-radio-button :label="item.id">{{ item.name }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
      <el-form ref="form" :model="form" inline>
        <el-form-item>
          <el-select v-model="form.cat_id" clearable placeholder="品类">
            <el-option
              v-for="(i, idx) in catSelect"
              :key="idx"
              :label="i.title"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">上传产品</el-button>
        </el-form-item>
      </el-form>
      <!-- 1 -->
      <div v-if="catType == '1'">
        <p style="color: #f00">
          系统规则:优先展示付费企业，然后是免费企业，数据实时更新。手动设置：可手动设置店铺展示的顺序。手动设置有时效性，且优先级大于系统规则
        </p>
        <el-table
          ref="table"
          v-loading="loading"
          border
          :data="tabledata"
          row-key="user_id"
        >
          <el-table-column align="center" label="ID" prop="id" width="90" />
          <el-table-column align="center" label="店铺LOGO" prop="name" />
          <el-table-column align="center" label="店铺名称" prop="mobile" />
          <el-table-column align="center" label="排序" prop="mobile" />
          <el-table-column align="center" label="生效时间" prop="mobile" />
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-popconfirm
                title="确认删除吗？"
                @confirm="onDelete(scope.row)"
              >
                <el-button slot="reference" type="text" size="small">
                  移除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="catType == '2'">
        <p style="color: #f00">
          系统规则：拉取首页每日上新二级页面品类页面产品。手动设置：可手动设置新品展示的顺
          序，手动设置有时效性，且优先级大于系统规则。（新品更多规则：同一店铺不限制新品数量且除去下面表格产品）支持手动上传第三方产品
        </p>
        <el-table
          ref="table"
          v-loading="loading"
          border
          :data="tabledata"
          row-key="user_id"
        >
          <el-table-column align="center" label="ID" prop="id" width="90" />
          <el-table-column align="center" label="产品LOGO" prop="name" />
          <el-table-column align="center" label="产品名称" prop="mobile" />
          <el-table-column align="center" label="店铺" prop="mobile" />
          <el-table-column align="center" label="发布时间" prop="mobile" />
          <el-table-column align="center" label="排序" prop="mobile" />
          <el-table-column align="center" label="是否手动设置" prop="mobile" />
          <el-table-column align="center" label="是否平台产品" prop="mobile" />
          <el-table-column align="center" label="生效时间" prop="mobile" />
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-popconfirm
                title="确认删除吗？"
                @confirm="onDelete(scope.row)"
              >
                <el-button slot="reference" type="text" size="small">
                  移除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="catType == '3'">
        <p style="color: #f00">
          系统规则：根据最近7天的产品数据，每日生成一个top10榜单；搜索指数=
          0.3*搜索产品曝光（下拉提示+搜索结果页）+0.2*搜索点击进入产品页+0.1*产品浏览次数
          +0.3*产品互动数（留言+电话）+0.1*样品申请数。手动设置：可手动设置最热产品展示的顺
          序，手动设置有时效性，且优先级大于系统规则。支持手动上传第三方产品
        </p>
        <el-table
          ref="table"
          v-loading="loading"
          border
          :data="tabledata"
          row-key="user_id"
        >
          <el-table-column align="center" label="ID" prop="id" width="90" />
          <el-table-column align="center" label="产品LOGO" prop="name" />
          <el-table-column align="center" label="产品名称" prop="mobile" />
          <el-table-column align="center" label="店铺" prop="mobile" />
          <el-table-column align="center" label="浏览数" prop="mobile" />
          <el-table-column align="center" label="热搜指数" prop="mobile" />
          <el-table-column align="center" label="排序" prop="mobile" />
          <el-table-column align="center" label="是否手动设置" prop="mobile" />
          <el-table-column align="center" label="是否平台产品" prop="mobile" />
          <el-table-column align="center" label="生效时间" prop="mobile" />
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-popconfirm
                title="确认删除吗？"
                @confirm="onDelete(scope.row)"
              >
                <el-button slot="reference" type="text" size="small">
                  移除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="catType == '4'">
        <p style="color: #f00">
          系统规则：优先展示付费企业产品，然后是免费企业产品，每个企业随机选择一个产品，数据实时更新。手动设置：可手动设置产品展示的顺
          序，手动设置有时效性，且优先级大于系统规则。支持手动上传第三方产品
        </p>
        <el-table
          ref="table"
          v-loading="loading"
          border
          :data="tabledata"
          row-key="user_id"
        >
          <el-table-column align="center" label="ID" prop="id" width="90" />
          <el-table-column align="center" label="产品LOGO" prop="name" />
          <el-table-column align="center" label="产品名称" prop="mobile" />
          <el-table-column align="center" label="店铺" prop="mobile" />
          <el-table-column align="center" label="排序" prop="mobile" />
          <el-table-column align="center" label="是否平台产品" prop="mobile" />
          <el-table-column align="center" label="生效时间" prop="mobile" />
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-popconfirm
                title="确认删除吗？"
                @confirm="onDelete(scope.row)"
              >
                <el-button slot="reference" type="text" size="small">
                  移除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addbanner = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    data() {
      return {
        title: '',
        addbanner: false,
        loading: false,
        catType: 'top',
        form: {},
        catTypeList: [
          {
            id: '1',
            name: '名企精选',
          },
          {
            id: '2',
            name: '新品上新',
          },
        ],
        catSelect: [], //品类下拉
        tabledata: [],
      }
    },
    methods: {
      onSubmit() {},
      handlersave() {},
      handlerinfo(row) {
        this.addbanner = true
        this.catType = row.id
      },
    },
  }
</script>
