var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "meeting" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "80px", inline: "" },
        },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "活动名称" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerEdit()
                    },
                  },
                },
                [_vm._v("新增调查")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.checkcate()
                    },
                  },
                },
                [_vm._v("品类库")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "ElTable",
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _c("el-table-column", {
            attrs: { width: "80px", prop: "id", label: "ID", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { width: "140px", prop: "", label: "标题", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.title,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("span", { staticClass: "shenglue" }, [
                          _vm._v(_vm._s(scope.row.title)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "80px",
              prop: "",
              label: "封面主图",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "40px" },
                      attrs: { src: scope.row.main_img },
                      on: {
                        click: function ($event) {
                          _vm.bigimgShow = true
                          _vm.bigimg = scope.row.main_img
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "80px",
              prop: "",
              label: "主题图",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "40px" },
                      attrs: { src: scope.row.title_img },
                      on: {
                        click: function ($event) {
                          _vm.bigimgShow = true
                          _vm.bigimg = scope.row.title_img
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "80px",
              prop: "bg_color",
              label: "背景色",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "80px",
              prop: "",
              label: "抽奖图",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "40px" },
                      attrs: { src: scope.row.award_img },
                      on: {
                        click: function ($event) {
                          _vm.bigimgShow = true
                          _vm.bigimg = scope.row.award_img
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "160px",
              prop: "award_url",
              label: "抽奖链接",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              prop: "",
              label: "H5分享主题",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.share_app_title,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("span", { staticClass: "shenglue" }, [
                          _vm._v(_vm._s(scope.row.share_app_title)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              prop: "",
              label: "分享描述",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.share_app_content,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("span", { staticClass: "shenglue" }, [
                          _vm._v(_vm._s(scope.row.share_app_content)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "80px",
              prop: "",
              label: "分享图",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "40px" },
                      attrs: { src: scope.row.share_app_img },
                      on: {
                        click: function ($event) {
                          _vm.bigimgShow = true
                          _vm.bigimg = scope.row.share_app_img
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              prop: "",
              label: "小程序分享主题",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.share_mini_title,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("span", { staticClass: "shenglue" }, [
                          _vm._v(_vm._s(scope.row.share_mini_title)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "80px",
              prop: "",
              label: "分享图",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "40px" },
                      attrs: { src: scope.row.share_mini_img },
                      on: {
                        click: function ($event) {
                          _vm.bigimgShow = true
                          _vm.bigimg = scope.row.share_mini_img
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "80px",
              prop: "",
              label: "海报图",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { height: "40px" },
                      attrs: { src: scope.row.poster_img },
                      on: {
                        click: function ($event) {
                          _vm.bigimgShow = true
                          _vm.bigimg = scope.row.poster_img
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "155px",
              prop: "created",
              label: "时间",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              prop: "",
              label: "操作",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerguanlian(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 题目关联 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCopyLink(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 复制链接 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.bigimgShow,
              expression: "bigimgShow",
            },
          ],
          staticClass: "bigimg",
          on: {
            click: function ($event) {
              _vm.bigimgShow = false
            },
          },
        },
        [_c("img", { attrs: { src: _vm.bigimg } })]
      ),
      _c("addSurvey", { ref: "add", on: { getlist: _vm.handlerInquire } }),
      _c("related", { ref: "related", on: { getlist: _vm.handlerInquire } }),
      _c("categoryKu", { ref: "cate" }),
      _c("info", { ref: "info" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }