var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c("guize"),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "福利弹框图片"
                          ? _c(
                              "div",
                              [
                                row[item.prop]
                                  ? _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "180px",
                                          height: "30px",
                                        },
                                        attrs: {
                                          src: row[item.prop],
                                          "preview-src-list": [row[item.prop]],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中"),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : item.label == "领取弹窗图片"
                          ? _c(
                              "div",
                              [
                                row[item.prop]
                                  ? _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "30px",
                                          height: "30px",
                                        },
                                        attrs: {
                                          src: row[item.prop],
                                          "preview-src-list": [row[item.prop]],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中"),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : item.label == "开关"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row[item.prop] == 1 ? "开启" : "关闭"
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "220",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleredit(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("edit", { ref: "edit", on: { getlist: _vm.handlerlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }