<template>
  <el-dialog
    :before-close="handleClose"
    title="添加类型"
    :visible.sync="visible"
    width="30%"
  >
    <el-form label-width="80px" :model="form">
      <el-form-item label="类型名称">
        <el-input v-model="form.name" placeholder="请输入类型名称" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: 'AddType',
    data() {
      return {
        visible: false,
        form: {
          name: '',
        },
      }
    },
    methods: {
      show() {
        this.visible = true
      },
      handleClose() {
        this.visible = false
        this.form.name = ''
      },
      handleSubmit() {
        if (!this.form.name) {
          this.$message.warning('请输入类型名称')
          return
        }
        this.$emit('submit', this.form.name)
        this.handleClose()
      },
    },
  }
</script>
