var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogFormVisible, width: "80%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.queryForm } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: { clearable: "", placeholder: "搜索标题" },
                model: {
                  value: _vm.queryForm.keywords,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.queryForm,
                      "keywords",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "queryForm.keywords",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "发放范围" },
                  model: {
                    value: _vm.queryForm.send_scope,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.queryForm,
                        "send_scope",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "queryForm.send_scope",
                  },
                },
                _vm._l(_vm.scopeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "发放状态" },
                  model: {
                    value: _vm.queryForm.status,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.queryForm,
                        "status",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "queryForm.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlSend()
                    },
                  },
                },
                [_vm._v("发放票券")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              prop: "id",
              "show-overflow-tooltip": "",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "票券标题",
              prop: "title",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "发放范围", prop: "send_scope" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.send_scope == 1
                      ? _c("font", [_vm._v("指定套餐")])
                      : _c("font", [_vm._v("指定用户")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "发放人群",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.send_scope == 1
                      ? _c("font", [_vm._v(_vm._s(row.vip_groups_title))])
                      : _c("font", [_vm._v(_vm._s(row.user_groups_title))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "会员开通时段", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.send_scope == 1 && row.start_time != 0
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(row.start_time) +
                              " 至 " +
                              _vm._s(row.end_time) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "发放用户",
              prop: "total_user_num",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(_vm._s(row.total_user_num) + "/人")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "已发放用户",
              prop: "issued_user_num",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(_vm._s(row.issued_user_num) + "/人")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "票券来源",
              prop: "source",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.source == 1
                      ? _c("font", [_vm._v("商业家会员")])
                      : row.source == 2
                      ? _c("font", [_vm._v("活动抽奖")])
                      : row.source == 3
                      ? _c("font", [_vm._v("邀约裂变")])
                      : row.source == 4
                      ? _c("font", [_vm._v("联络员邀约")])
                      : row.source == 5
                      ? _c("font", [_vm._v("报名签到")])
                      : _c("font", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "发放形式",
              prop: "send_type",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.send_type == 1
                      ? _c("font", [_vm._v("补发发放")])
                      : _c("font", [_vm._v("全部发放")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "失败原因",
              prop: "reason",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "发放状态",
              prop: "status",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.status == 0
                      ? _c("font", [_vm._v("发放中")])
                      : row.status == 1
                      ? _c("font", [_vm._v("发放成功")])
                      : _c("font", [_vm._v("发放失败")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "发放时间",
              prop: "created",
              "show-overflow-tooltip": "",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              prop: "id",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleSendCdkeyLog(row)
                          },
                        },
                      },
                      [_vm._v(" 发放记录 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("send", { ref: "send", on: { "fetch-data": _vm.fetchData } }),
      _c("sendCdkeyLog", { ref: "sendCdkeyLog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }