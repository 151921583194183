<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="90%">
      <div>
        <el-form ref="form" :model="form" inline>
          <el-form-item label="">
            <el-input
              v-model="form.mall_name"
              placeholder="输入企业/店铺名称"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="">
            <el-button
              size="small"
              type="primary"
              @click.native.prevent="handlerlist()"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item label="">
            <el-button
              size="small"
              type="primary"
              @click.native.prevent="handleredit()"
            >
              新增店铺
            </el-button>
          </el-form-item>
          <el-form-item label="">
            <el-button
              size="small"
              type="primary"
              @click.native.prevent="handlerrelated()"
            >
              关联店铺
            </el-button>
          </el-form-item>
        </el-form>
        <el-table :data="tabledata" border ref="table" v-loading="loading">
          <el-table-column
            prop="id"
            label="ID"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="mall_name"
            label="店铺/企业名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="sort_order"
            label="排序"
            align="center"
          ></el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template #default="{ row }">
              <el-button
                size="small"
                style="margin: 10px"
                type="text"
                @click.native.prevent="handleredit(row)"
              >
                编辑店铺
              </el-button>
              <el-popconfirm title="确认删除吗？" @confirm="deleteRow(row)">
                <el-button slot="reference" size="small" type="text">
                  删除
                </el-button>
              </el-popconfirm>
              <el-button
                size="small"
                style="margin: 10px"
                type="text"
                @click.native.prevent="handlerproduct(row)"
              >
                编辑产品
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          :total="total"
          background
          style="text-align: center; margin-top: 10px"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
        <goodslist ref="goodslist" @getlist="handlerlist" />
        <storeedit ref="storeedit" @getlist="handlerlist" />
        <!-- <relatedstore ref="relatedstore" @getlist="handlerlist" /> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  import storeedit from './storeedit.vue'
  import goodslist from './goodslist.vue'
  // import relatedstore from './relatedstore.vue'
  export default {
    components: { uploadImg, storeedit, goodslist },
    data() {
      return {
        isShow: false,
        title: '店铺列表',
        loading: false,
        tabledata: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        form: {
          type: 4,
          title: '',
          pic: '',
          sort: '',
          status: '',
        },

        groupSelect: [
          {
            id: '1',
            name: '区域',
          },
          {
            id: '2',
            name: '新渠道',
          },
        ],
        drawSelect: [
          {
            id: '1',
            name: '是',
          },
          {
            id: '2',
            name: '否',
          },
        ],
      }
    },
    watch: {
      isShow(n, o) {
        if (n) {
        } else {
          this._onreset()
        }
      },
    },
    mounted() {},
    methods: {
      deleteRow(row) {
        getAction('/api/afbc/act-meet/mall-delete', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.handlerlist()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      // 编辑产品
      handlerproduct(row) {
        this.$refs.goodslist.isShow = true
        this.$refs.goodslist.form.meet_mall_id = row.id
        this.$refs.goodslist.handlerlist()
      },
      //查询
      handlerlist() {
        this.form.page = 1
        this.form.meet_id = this.form.id
        this.getlist()
      },
      // 获取列表数据
      getlist() {
        getAction('/api/afbc/act-meet/mall-list', this.form).then((res) => {
          if (res.code == 0) {
            this.tabledata = res.data
            this.total = res.totalCount
          }
        })
      },
      //新增/编辑企业
      handleredit(row) {
        console.log('编辑开始', this.form)
        if (row) {
          this.$refs.storeedit.isShow = true
          this.$refs.storeedit.form = JSON.parse(JSON.stringify(row))
          if (row.yjh_id == 0) {
            //自建店铺
            this.$refs.storeedit.title = '编辑企业'
            this.$refs.storeedit.storetype = 1
          } else {
            this.$refs.storeedit.title = '编辑关联企业'
          }
        } else {
          this.$refs.storeedit.title = '新增企业'
          this.$refs.storeedit.isShow = true
          this.$refs.storeedit.form.meet_id = this.form.meet_id
          this.$refs.storeedit.storetype = 1
        }
      },
      //关联企业
      handlerrelated() {
        this.$refs.storeedit.title = '关联企业'
        this.$refs.storeedit.storetype = 2
        this.$refs.storeedit.form.meet_id = this.form.meet_id
        this.$refs.storeedit.isShow = true
      },
      //分页
      handleCurrentChange(val) {
        this.form.page = val
        this.getlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getlist()
      },
    },
  }
</script>
