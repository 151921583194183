<script>
import UploadImg from "@/components/uploadImg.vue";
import { getAction, postAction } from "@/api/request";

export default {
  name: "addFuWu",
  components: { UploadImg },
  data() {
    return {
      showedit: false,
      showform:{
        setmeal_offline_study_tour: '',
        surplus_offline_study_tour: '',
        setmeal_offline_explosive_products: '',
        surplus_offline_explosive_products: '',
        setmeal_offline_miniwecat_live: '',
        surplus_offline_miniwecat_live: '',
        setmeal_offline_mp_article: '',
        surplus_offline_mp_article: '',
        setmeal_offline_mp_video: '',
        surplus_offline_mp_video: '',
      },
      form: {
        mall_id: '',
        type: '',
        nums: 1,
        remark: '',
        meeting_img: '',
      },
      rules: {
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        nums: [{ required: true, message: "请输入次数", trigger: "blur" }],
      },
      typeSelect: [
        // {
        //   id: 7,
        //   name: '线下区域游学考察',
        // },
        // {
        //   id: 8,
        //   name: '线下云交会热品推荐视频',
        // },
        // {
        //   id: 9,
        //   name: '线下云交会公众号专稿推介',
        // },
        // {
        //   id: 10,
        //   name: '线下云交会视频号',
        // },
        // {
        //   id: 11,
        //   name: '线下云交会抖音直播',
        // },
      ],
    };
  },
  watch: {
    showedit(v) {
      if (!v) {
        this.form = {
          mall_id: '',
          type: '',
          nums: '',
          remark: '',
          meeting_img: '',
        }
        this.$refs.imgs.img = []
      }
    }
  },
  methods: {
    handlertypearr(e){
      getAction('/mall/setmeal/get-offline', {id:e}).then(res=>{
        this.typeSelect=res.data
      })
    },
    handleradd(){
      this.$refs.form.validate(valid=>{
        if(valid){
          postAction("/mall/setmeal/offline-deduct-setmeal",this.form).then(res=>{
            this.$message.success("添加成功")
            this.showedit=false
            this.$emit("getlist")
          })
        }
      })

    },
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v.join(',')
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      console.log()
      if(this.$refs[prop]!=undefined){
        this.$refs[prop].forEach(item=>{
          console.log(item)
          item.img=[]
        })
      }
    },
  }
};
</script>

<template>
  <el-dialog
    title="添加线下服务内容"
    :visible.sync="showedit"
    width="800px">
    <div>
      <el-descriptions :column="2">
        <el-descriptions-item label="区域游学考察总次数">{{ Number(showform.setmeal_offline_study_tour)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.surplus_offline_study_tour)<0?0:Number(showform.surplus_offline_study_tour)}}</el-descriptions-item>
        <el-descriptions-item label="抖音直播总次数">{{Number(showform.setmeal_offline_explosive_products)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.surplus_offline_explosive_products)<0?0:Number(showform.surplus_offline_explosive_products)}}</el-descriptions-item>
        <el-descriptions-item label="小程序/视频号直播总次数">
          {{Number(showform.setmeal_offline_miniwecat_live)}}
        </el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">
          {{Number(showform.surplus_offline_miniwecat_live)<0?0:Number(showform.surplus_offline_miniwecat_live)}}
        </el-descriptions-item>
        <el-descriptions-item label="公众号专稿推介总次数">{{Number(showform.setmeal_offline_mp_article)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.surplus_offline_mp_article)<0?0:Number(showform.surplus_offline_mp_article)}}</el-descriptions-item>
        <el-descriptions-item label="热品推荐视频总次数">{{Number(showform.setmeal_offline_mp_video)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.surplus_offline_mp_video)<0?0:Number(showform.surplus_offline_mp_video)}}</el-descriptions-item>
        <el-descriptions-item label="公众号文章推广总次数">{{Number(showform.wechat_total)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.wechat_last)}}</el-descriptions-item>
        <el-descriptions-item label="产品创意短视频总次数">{{Number(showform.short_video_total)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.short_video_last)}}</el-descriptions-item>
        <el-descriptions-item label="视频号直播总次数">{{Number(showform.video_total)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.video_last)}}</el-descriptions-item>
        <el-descriptions-item label="社群转发造势总次数">{{Number(showform.group_total)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.group_last)}}</el-descriptions-item>
        <el-descriptions-item label="意向线索跟进总次数">{{Number(showform.follow_total)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.follow_last)}}</el-descriptions-item>
        <el-descriptions-item label="高意向客户推荐总次数">{{Number(showform.intention_total)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.intention_last)}}</el-descriptions-item>
        <el-descriptions-item label="地级市经销商活动/对接总次数">{{Number(showform.docking_total)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.docking_last)}}</el-descriptions-item>

      </el-descriptions>
      <el-divider></el-divider>
      <el-form ref="form" label-suffix=":" :model="form" :rules="rules" label-width="160px">

        <el-form-item label="添加类型" prop="type">
          <el-select
            v-model="form.type"
            clearable
            placeholder="添加类型"
            style="width: 220px"
          >
            <el-option
              v-for="list in typeSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="添加次数" prop="nums">
          <el-input-number :min="1" v-model="form.nums" placeholder="添加次数" />
<!--          @input="(val)=>{val.replace(/\d+/g, '')}"-->
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="请输入内容" rows="4" type="textarea" maxlength="50" style="width: 220px"/>
        </el-form-item>
        <el-form-item label="图片" >
          <div style="display: flex;align-items: center">
            <upload-img ref="imgs" :limit="5" :info-text="''" @getImgs="getGoods_imgImg($event, 'imgs')" :maxSize="100"/>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showedit = false">取 消</el-button>
      <el-button type="primary" @click="handleradd">确 定</el-button>
    </div>
  </el-dialog>
</template>

<style scoped>

</style>
