<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-select
          v-model="form.htyun_dept"
          clearable
          filterable
          placeholder="部门"
          style="width: 160px"
        >
          <el-option
            v-for="(list, index) in bumenSelect"
            :key="index"
            :label="list.htyun_dept"
            :value="list.htyun_dept"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.htyun_name"
          placeholder="邀请人"
          clearable
          style="width: 130px"
        />
      </el-form-item>
      <el-form-item>
        <!-- <el-date-picker
          v-model="time"
          end-placeholder="结束时间"
          format="yyyy-MM--yy"
          start-placeholder="开始时间"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM--yy"
        /> -->
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%"
      @sort-change="sortChange"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        v-loading="loading"
        :sortable="item.sort"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="">
        <template #default="{ row }">
          <el-button size="small" type="text" @click="goDetail(row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 详情弹窗 -->
    <el-dialog title="详情" :visible.sync="dialogVisible" width="80%">
      <div class="titlename">{{ currentInviteName }}</div>
      <el-form ref="detailForm" inline :model="detailForm">
        <el-form-item>
          <el-input v-model="detailForm.title" placeholder="文章名称" />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="timeRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleDetailSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleDetailExport">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table
        border
        :data="detailTableData"
        style="width: 100%"
        v-loading="detailloading"
      >
        <el-table-column prop="id" label="文章ID" width="100" />
        <el-table-column prop="title" label="文章名称" width="" />
        <el-table-column prop="friend_count" label="分享好友数" width="120" />
        <el-table-column prop="circle_count" label="分享朋友圈" width="120" />
        <el-table-column prop="invite_pv" label="邀请来PV" width="120" />
        <el-table-column prop="invite_uv" label="邀请来UV" width="120" />
        <el-table-column prop="goods_pv" label="产品点击PV" width="120" />
        <el-table-column prop="goods_uv" label="产品UV" width="120" />
      </el-table>
      <el-pagination
        background
        :current-page="detailForm.page"
        :layout="layout"
        :page-size="detailForm.limit"
        style="text-align: center; margin-top: 10px"
        :total="detailtotal"
        @current-change="handleCurrentChangedetail"
        @size-change="handleSizeChangedetail"
      />
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'Index',
    data() {
      return {
        loading: false,
        detailloading: false,
        form: {
          htyun_dept: '',
          htyun_name: '',
          htyun_name: '',
          start_time: '',
          end_time: '',
          order: '',
          sort: '',
          page: 1,
          limit: 10,
        },
        time: [],
        timeRange: [],
        bumenSelect: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '部门',
            prop: 'htyun_dept',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'htyun_name',
            width: '',
          },
          {
            label: '分享好友数',
            prop: 'friend_count',
            width: '',
            sort: 'custom',
          },
          {
            label: '分享朋友圈',
            prop: 'circle_count',
            width: '',
            sort: 'custom',
          },
          {
            label: '邀请来PV',
            prop: 'invite_pv',
            width: '',
            sort: 'custom',
          },
          {
            label: '邀请来UV',
            prop: 'invite_uv',
            width: '',
            sort: 'custom',
          },
          {
            label: '产品PV',
            prop: 'goods_pv',
            width: '',
            sort: 'custom',
          },
          {
            label: '产品UV',
            prop: 'goods_uv',
            width: '',
            sort: 'custom',
          },
        ],
        total: 0,
        detailtotal: 0,
        dialogVisible: false,
        detailForm: {
          article_title: '',
          page: 1,
          limit: 10,
        },
        detailTableData: [],
        currentInviteName: '',
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },

      timeRange(v) {
        console.log('时间，', v)
        if (v) {
          console.log('111')
          this.detailForm.start_time = v[0]
          this.detailForm.end_time = v[1]
        } else {
          console.log('222')
          this.detailForm.start_time = ''
          this.detailForm.end_time = ''
        }
      },
      dialogVisible(v) {
        if (!v) {
          this.detailForm.page = 1
          this.detailForm.limit = 10
          this.detailForm.userid = ''
          this.detailForm.title = ''
          this.detailForm.start_time = ''
          this.detailForm.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      //排序
      sortChange({ column, prop, order }) {
        console.log('column, prop, order', column, prop, order)
        this.form.sort = prop
        // 值 asc 小到大  和  desc  大到小]
        if (order === 'descending') {
          this.form.order = 'desc'
        } else if (order === 'ascending') {
          this.form.order = 'asc'
        } else {
          this.form.sort = ''
          this.form.order = ''
        }
        this.handlerInquire()
      },
      handleDetailSearch() {
        this.detailForm.page = 1
        this.detailForm.limit = 10

        this.fetchDetailData()
      },
      //查看详情
      goDetail(row) {
        console.log('当前的时间', this.form)
        this.currentInviteName = row.htyun_name
        this.dialogVisible = true
        this.detailForm.userid = row.userid
        this.timeRange = [this.form.start_time, this.form.end_time]
        this.detailForm.start_time = this.form.start_time
        this.detailForm.end_time = this.form.end_time

        this.fetchDetailData()
      },
      fetchDetailData() {
        this.detailloading = true
        getAction('/api/stroll/article/tranfer-detail', this.detailForm).then(
          (res) => {
            this.detailloading = false
            this.detailTableData = res.data.list
            this.detailtotal = Number(res.totalCount)
          }
        )
      },
      handleDetailExport() {
        getAction('/api/stroll/article/tranfer-detail-export', {
          ...this.detailForm,
          yjhasync: 1,
        }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: '已添加导出任务',
            })
          }
        })
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        await getAction('/form/form/dept').then((res) => {
          console.log(res)
          this.bumenSelect = res.data
        })
      },
      handlerlist() {
        this.loading = true
        getAction('/api/stroll/article/tranfer', this.form).then((res) => {
          this.loading = false
          this.tabledata = res.data.list
          this.total = Number(res.totalCount)
        })
      },

      handlerExport() {
        getAction('/api/stroll/article/tranfer-export', {
          ...this.form,
          yjhasync: 1,
        }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: '已添加导出任务',
            })
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
      handleCurrentChangedetail(val) {
        this.detailForm.page = val
        this.fetchDetailData()
      },
      handleSizeChangedetail(val) {
        this.detailForm.limit = val
        this.fetchDetailData()
      },
    },
  }
</script>

<style scoped>
  .titlename {
    position: absolute;
    top: 15px;
    left: 130px;
    line-height: 30px;
    font-size: 18px;
  }
</style>
