var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "添加管理",
            visible: _vm.visible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "员工姓名" } },
                [
                  _c("el-autocomplete", {
                    attrs: {
                      "fetch-suggestions": _vm.querySearch,
                      placeholder: "请搜索员工姓名",
                      "trigger-on-focus": false,
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "权限" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.form.types,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "types", $$v)
                          },
                          expression: "form.types",
                        },
                      },
                      _vm._l(_vm.customTypes, function (item) {
                        return _c(
                          "el-checkbox",
                          { key: item.id, attrs: { label: item.id } },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "custom-add-btn",
                        staticStyle: { "margin-left": "18px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.handleAddType },
                      },
                      [_vm._v(" 添加 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AddType", {
        ref: "addTypeDialog",
        on: { submit: _vm.handleTypeSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }