var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "详情",
        top: "5vh",
        visible: _vm.showDialog,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-suffix": "：",
            "label-width": "130px",
            model: _vm.form,
          },
        },
        [
          _c("el-form-item", { attrs: { label: "用户姓名" } }, [
            _vm._v(_vm._s(_vm.form.name)),
          ]),
          _c("el-form-item", { attrs: { label: "手机号码" } }, [
            _vm._v(_vm._s(_vm.form.mobile)),
          ]),
          _c("el-form-item", { attrs: { label: "公司名称" } }, [
            _vm._v(_vm._s(_vm.form.company)),
          ]),
          _c("el-form-item", { attrs: { label: "主营产品" } }, [
            _vm._v(_vm._s(_vm.form.main_product)),
          ]),
          _c("el-form-item", { attrs: { label: "所在地区" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.form.province + _vm.form.city + _vm.form.area) +
                " "
            ),
          ]),
          _c("el-form-item", { attrs: { label: "详细地址" } }, [
            _vm._v(_vm._s(_vm.form.address)),
          ]),
          _c("el-form-item", { attrs: { label: "获取行业咨询" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.form.get_industry_information == 1 ? "酒类" : "食品类"
                ) +
                " "
            ),
          ]),
          _c("el-form-item", { attrs: { label: "订单留言" } }, [
            _vm._v(_vm._s(_vm.form.message)),
          ]),
          _c("el-form-item", { attrs: { label: "会员类型" } }, [
            _vm._v(_vm._s(_vm.form.title)),
          ]),
          _c("el-form-item", { attrs: { label: "会员价格" } }, [
            _vm._v(_vm._s(_vm.form.total_price)),
          ]),
          _c("el-form-item", { attrs: { label: "会员状态" } }, [
            _vm.form.vip_status == 1
              ? _c("span", [_vm._v("已开通")])
              : _vm.form.vip_status == 2
              ? _c("span", [_vm._v("已过期")])
              : _vm.form.vip_status == 3
              ? _c("span", [_vm._v("未激活")])
              : _vm.form.vip_status == 4
              ? _c("span", [_vm._v("未支付")])
              : _vm._e(),
          ]),
          _c("el-form-item", { attrs: { label: "开通时间" } }, [
            _vm._v(_vm._s(_vm.form.open_time)),
          ]),
          _c("el-form-item", { attrs: { label: "到期时间" } }, [
            _vm._v(_vm._s(_vm.form.expire_time)),
          ]),
          _c("el-form-item", { attrs: { label: "邀 请 人" } }, [
            _vm._v(_vm._s(_vm.form.invite_user)),
          ]),
          _c("el-form-item", { attrs: { label: "订单备注" } }, [
            _vm._v(_vm._s(_vm.form.remark)),
          ]),
          _c("el-form-item", { attrs: { label: "订单来源" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.form.platform == 5 ? "后台添加" : "前台支付") +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }