<template>
  <div class="index-container">
    <el-tabs type="card" @tab-click="handleClick">
      <el-tab-pane label="头条文章">
        <grabcom ref="grabcom" />
      </el-tab-pane>
      <el-tab-pane label="公众号文章">
        <wxcom ref="wxcom" />
      </el-tab-pane>
      <el-tab-pane label="待编辑文章">
        <tobeeditcom ref="tobeeditcom" />
      </el-tab-pane>
      <el-tab-pane label="已发布文章">
        <publishcom ref="publishcom" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import grabcom from './grabcom'
  import tobeeditcom from './tobeeditcom'
  import publishcom from './publishcom'
  import wxcom from './wxcom'

  export default {
    name: 'index',
    components: { grabcom, tobeeditcom, publishcom, wxcom },
    data() {
      return {
        tabtype: 1,
        form: {
          keywords: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'id',
            prop: 'id',
            width: '',
          },

          {
            label: '新品标题',
            prop: 'name',
            width: '160',
          },

          {
            label: '封面图',
            prop: 'banner_pic',
            width: '',
          },
          {
            label: '品类',
            prop: 'bg_color',
            width: '',
          },
          {
            label: '品牌',
            prop: 'menu_bg_color',
            width: '',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      // this.handlerInquire()
    },
    methods: {
      // 分拣操作
      onSort(row) {},
      // 删除操作
      onDelete(row) {},
      // 选项卡点击操作
      handleClick(tab, event) {
        console.log(tab.index, event)
        if (tab.index == 0) {
          this.$refs.grabcom.handlerInquire()
        } else if (tab.index == 1) {
          this.$refs.wxcom.handlerInquire()
        } else if (tab.index == 2) {
          console.log('-------------')
          this.$refs.tobeeditcom.handlerInquire()
        } else if (tab.index == 3) {
          this.$refs.publishcom.handlerInquire()
        }
      },
    },
  }
</script>

<style scoped>
  .round {
    width: 380px;
    height: 380px;
  }
  .img {
    max-width: 100%;
  }
</style>
