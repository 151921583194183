<template>
  <div class="search-word-rank">
    <!-- 日期选择器 -->
    <el-date-picker
      v-model="dateRange"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      @change="handleDateChange"
    ></el-date-picker>

    <!-- 查询按钮 -->
    <el-button type="primary" @click="handleSearch" style="margin-left: 10px">
      查询
    </el-button>

    <!-- 导出按钮 -->
    <el-button type="success" @click="handleExport">导出</el-button>

    <!-- 表格 -->
    <el-table :data="tableData" style="width: 100%; margin-top: 10px">
      <el-table-column prop="title" label="搜索词"></el-table-column>
      <el-table-column prop="lookup_count" label="搜索次数"></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="form.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="form.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'

  export default {
    data() {
      return {
        dateRange: [], // 日期范围
        tableData: [], // 表格数据
        currentPage: 1, // 当前页码
        pageSize: 10, // 每页显示条数
        total: 0, // 总条数
        form: {
          start_time: '',
          end_time: '',
          title: '',
          page: 1,
          limit: 10,
        },
      }
    },
    watch: {
      dateRange(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handleSearch()
    },
    methods: {
      getlist() {
        getAction('/api/form/search-rank/search-title-rank', this.form).then(
          (res) => {
            if (res.code == 0) {
              this.tableData = res.data.list
              this.total = res.totalCount
            }
          }
        )
      },
      // 处理日期变化
      handleDateChange(val) {
        console.log('日期范围:', val)
      },
      // 处理查询
      handleSearch() {
        this.form.page = 1
        this.getlist()
      },
      // 处理导出
      handleExport() {
        postAction(
          '/api/form/search-rank/search-title-rank-export',
          this.form
        ).then((res) => {
          this.$message({
            message: '已加入导出队列，请稍后查看',
            type: 'success',
          })
        })
      },
      // 处理每页显示条数变化
      handleSizeChange(val) {
        this.limit = val
        this.getlist()
      },
      // 处理页码变化
      handleCurrentChange(val) {
        this.page = val
        this.getlist()
      },
    },
  }
</script>

<style scoped>
  .search-word-rank {
    padding: 20px;
  }
</style>
