<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input v-model="form.goods_name" clearable placeholder="产品名称" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.mall_name" clearable placeholder="店铺名称" />
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.is_pay" placeholder="是否付费">
          <el-option
            v-for="item in optionsList"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.company_name" clearable placeholder="企业名称" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.inviter_name" clearable placeholder="邀请人" />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          v-loading="loading"
          :disabled="loading"
          type="primary"
          @click="handlerExport"
        >
          导出
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%"
      @sort-change="sortChange"
      v-loading="loadinglist"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        :sortable="item.sort"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '是否付费'">
            {{ row[item.prop] == 1 ? '是' : '否' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import dayjs from 'dayjs'
  export default {
    name: 'Index',
    data() {
      return {
        optionsList:[
          {
            id: 1,
            label: '是'
          },
          {
            id: 0,
            label: '否'
          }
        ],
        loadinglist: false,
        loading: false,
        form: {
          goods_name: '',
          inviter_name: '',
          start_date: dayjs(Date.now() - 1000 * 24 * 3600 * 6).format(
            'YYYY-MM-DD'
          ),
          end_date: dayjs(Date.now()).format('YYYY-MM-DD'),
          order_field: 'view_nums',
          order: 'desc',
          page: 1,
          limit: 10,
        },
        time: [
          dayjs(Date.now() - 1000 * 24 * 3600 * 6).format('YYYY-MM-DD'),
          dayjs(Date.now()).format('YYYY-MM-DD'),
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '产品ID',
            prop: 'id',
            width: '',
          },

          {
            label: '产品名称',
            prop: 'goods_name',
            width: '',
          },

          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '是否付费',
            prop: 'is_pay',
            width: '',
          },
          {
            label: '企业名称',
            prop: 'company_name',
            width: '',
          },

          {
            label: '产品上传日期',
            prop: 'created',
            width: '',
          },

          {
            label: '邀请人',
            prop: 'inviter_name',
            width: '',
          },
          {
            label: '真实浏览',
            prop: 'view_nums',
            width: '',
            sort: 'custom',
          },
          {
            label: '虚拟浏览',
            prop: 'virtual_view_nums',
            width: '',
            sort: 'custom',
          },
          {
            label: '访问用户数',
            prop: 'uv',
            width: '',
            sort: 'custom',
          },
          {
            label: '电话',
            prop: 'intention_nums',
            width: '',
            sort: 'custom',
          },

          {
            label: '留言',
            prop: 'suggest_nums',
            width: '',
            sort: 'custom',
          },

          {
            label: '样品申请',
            prop: 'sample_nums',
            width: '',
            sort: 'custom',
          },
          {
            label: '分享',
            prop: 'share_nums',
            width: '',
            sort: 'custom',
          },
        ],
        staffSelect: [],
        total: 0,
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlerExport() {
        this.loading = true
        postAction('/api/statistics/goods/export', this.form).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
          this.loading = false
        })
      },
      //排序
      sortChange({ column, prop, order }) {
        console.log('column, prop, order', column, prop, order)
        this.form.order_field = prop
        // 值 asc 小到大  和  desc  大到小]
        if (order === 'descending') {
          this.form.order = 'desc'
        } else if (order === 'ascending') {
          this.form.order = 'asc'
        } else {
          this.form.order_field = ''
          this.form.order = ''
        }
        this.handlerlist()
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist() {
        if (this.form.start_date && this.form.end_date) {
          this.loadinglist = true
          getAction('/api/statistics/goods/lists', this.form).then((res) => {
            this.tabledata = res.data
            this.total = Number(res.totalCount)
            this.loadinglist = false
          })
        } else {
          this.$message({
            message: '请选择查询时间范围',
            type: 'warning',
          })
        }
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
