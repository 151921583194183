var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.isShowDialog,
        width: "800px",
        top: "13vh",
        "close-on-click-modal": false,
        "append-to-body": "",
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShowDialog = $event
        },
        close: _vm.onClose,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-suffix": ":",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品标题", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "390px" },
                    attrs: {
                      placeholder: "最多32个字",
                      maxlength: "32",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "卖点", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "390px" },
                    attrs: {
                      placeholder: "最多32个字",
                      maxlength: "32",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.sell_point,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sell_point", $$v)
                      },
                      expression: "form.sell_point",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "封面图", prop: "cover_img" } },
                [
                  _c("upload-img", {
                    ref: "cover_img",
                    attrs: {
                      infoText: "图片尺寸750*750，仅支持jpg和png格式图片",
                      limit: 50,
                      maxSize: 100,
                      booleancorpper: true,
                      enlarge: 4,
                      "auto-crop-width": 187.5,
                      "auto-crop-height": 187.5,
                      mode: "750px,750px",
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg(
                          $event,
                          "cover_img",
                          _vm.index
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "产品属性" } },
                [
                  _vm._l(_vm.productAttrList, function (item, i) {
                    return _c(
                      "div",
                      { key: i, staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c(
                          "div",
                          { staticClass: "productcard" },
                          [
                            _c("span", [_vm._v("标题：")]),
                            _c("el-input", {
                              staticStyle: { width: "30%" },
                              attrs: { placeholder: "请输入产品名称" },
                              model: {
                                value: _vm.productAttrList[i].title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.productAttrList[i], "title", $$v)
                                },
                                expression: "productAttrList[i].title",
                              },
                            }),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v("内容：")]
                            ),
                            _c("el-input", {
                              staticStyle: { width: "30%" },
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.productAttrList[i].content,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.productAttrList[i],
                                    "content",
                                    $$v
                                  )
                                },
                                expression: "productAttrList[i].content",
                              },
                            }),
                            i > 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "btnicon min",
                                    on: {
                                      click: function ($event) {
                                        return _vm.subList(i)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-delete",
                                      staticStyle: { "font-size": "18px" },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: i > 0,
                                    expression: "i > 0",
                                  },
                                ],
                                staticClass: "btnicon min",
                                on: {
                                  click: function ($event) {
                                    return _vm.moveUp(i)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-top",
                                  staticStyle: {
                                    "font-size": "18px",
                                    "font-weight": "bold",
                                    "margin-right": "5px",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: i < _vm.productAttrList.length - 1,
                                    expression:
                                      "i < productAttrList.length - 1",
                                  },
                                ],
                                staticClass: "btnicon min",
                                on: {
                                  click: function ($event) {
                                    return _vm.moveDown(i)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-bottom",
                                  staticStyle: {
                                    "font-size": "18px",
                                    "font-weight": "bold",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "addbtn", on: { click: _vm.addList } },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus-outline",
                        staticStyle: { "font-size": "28px" },
                      }),
                    ]
                  ),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营养成分表", prop: "ingredient_list" } },
                [
                  _c("upload-img", {
                    ref: "ingredient_list",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "ingredient_list")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属分类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { filterable: "", clearable: "", placeholder: "" },
                      on: { change: _vm.getCateChild },
                      model: {
                        value: _vm.form.category_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "category_id", $$v)
                        },
                        expression: "form.category_id",
                      },
                    },
                    _vm._l(_vm.cateList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "二级分类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { filterable: "", clearable: "", placeholder: "" },
                      model: {
                        value: _vm.form.cat_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cat_id", $$v)
                        },
                        expression: "form.cat_id",
                      },
                    },
                    _vm._l(_vm.cateChildList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联云交会产品", prop: "" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "390px" },
                    attrs: {
                      "fetch-suggestions": _vm.handlergoods,
                      "value-key": "goods_name",
                      clearable: "",
                      placeholder: "请输入产品名称",
                    },
                    on: { select: _vm.handleSelectgoods },
                    model: {
                      value: _vm.form.goods_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_title", $$v)
                      },
                      expression: "form.goods_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布时间", prop: "status" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.form.publish_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "publish_time", $$v)
                      },
                      expression: "form.publish_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "390px" },
                    attrs: { placeholder: "请输入品牌名称" },
                    model: {
                      value: _vm.form.brand_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "brand_name", $$v)
                      },
                      expression: "form.brand_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "首页显示", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "位置",
                      },
                      model: {
                        value: _vm.form.index_show,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "index_show", $$v)
                        },
                        expression: "form.index_show",
                      },
                    },
                    _vm._l(_vm.showList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否广告" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "是",
                      "inactive-text": "否",
                      "active-value": "1",
                      "inactive-value": "0",
                    },
                    model: {
                      value: _vm.form.is_advert,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_advert", $$v)
                      },
                      expression: "form.is_advert",
                    },
                  }),
                ],
                1
              ),
              _vm.form.is_advert == 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "曝光类型", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "180px" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "位置",
                              },
                              model: {
                                value: _vm.form.advert_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "advert_type", $$v)
                                },
                                expression: "form.advert_type",
                              },
                            },
                            _vm._l(_vm.exposureList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.advert_type == 2 || _vm.form.advert_type == 3
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "设置曝光量", prop: "" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "110px" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请设置曝光量",
                                },
                                model: {
                                  value: _vm.form.max_exposure_num,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "max_exposure_num", $$v)
                                  },
                                  expression: "form.max_exposure_num",
                                },
                              }),
                              _c("span", [
                                _vm._v(
                                  " 达到设置数量后，产品自动从首页下架，上一款非广告产品代替 "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.advert_type == 1 || _vm.form.advert_type == 3
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "设置时段" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  type: "datetimerange",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "default-time": ["12:00:00"],
                                },
                                model: {
                                  value: _vm.time,
                                  callback: function ($$v) {
                                    _vm.time = $$v
                                  },
                                  expression: "time",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { filterable: "", clearable: "", placeholder: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }