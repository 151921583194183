var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.isShow, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "120px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业名称", prop: "mall_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入企业名称" },
                        model: {
                          value: _vm.form.mall_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mall_name", $$v)
                          },
                          expression: "form.mall_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "主营产品", prop: "main_product" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入主营产品" },
                        model: {
                          value: _vm.form.main_product,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "main_product", $$v)
                          },
                          expression: "form.main_product",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业分类", prop: "cat_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.handlerjuptype },
                          model: {
                            value: _vm.form.cat_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cat_id", $$v)
                            },
                            expression: "form.cat_id",
                          },
                        },
                        _vm._l(_vm.cateSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { value: list.id, label: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头像", prop: "logo" } },
                    [
                      _c("upload-img", {
                        ref: "uploadImage",
                        attrs: {
                          infoText: "建议尺寸500*500,大小不超过2MB",
                          limit: 1,
                          maxSize: 100,
                          booleancorpper: true,
                          enlarge: 2,
                          "auto-crop-width": 250,
                          "auto-crop-height": 250,
                          mode: "500px,500px",
                        },
                        on: { uploadImg: _vm.uploadImg },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司介绍", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "请输入公司介绍",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.form.introduce,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "introduce", $$v)
                          },
                          expression: "form.introduce",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "招商代理区域", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "请输入招商代理区域",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.form.province,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "province", $$v)
                          },
                          expression: "form.province",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "展馆名称", prop: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入展馆名称", type: "text" },
                        model: {
                          value: _vm.form.number_house,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "number_house", $$v)
                          },
                          expression: "form.number_house",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "展位号", prop: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入展位号", type: "text" },
                        model: {
                          value: _vm.form.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "number", $$v)
                          },
                          expression: "form.number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专属报名链接", prop: "afbc_url" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "https://", type: "text" },
                        model: {
                          value: _vm.form.afbc_url,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "afbc_url", $$v)
                          },
                          expression: "form.afbc_url",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          placeholder: "数字越大靠前排",
                          type: "number",
                        },
                        model: {
                          value: _vm.form.sort_order,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort_order", $$v)
                          },
                          expression: "form.sort_order",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }