<template>
  <div>
    <el-dialog title="详情" :visible.sync="showinfo" width="60%" center>
      <div>
        <el-form ref="form" :model="form" label-width="130px" label-suffix="：">
          <el-form-item label="公司名称">{{ form.company_name }}</el-form-item>
          <el-form-item label="店铺名称">{{ form.mall_name }}</el-form-item>
          <el-form-item label="营业执照">
            <el-image
              style="width: 100px; height: 100px"
              v-if="form.business_url"
              :src="form.business_url"
              :preview-src-list="[form.business_url]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </el-form-item>
          <el-form-item label="企业员工身份证明材料">
            <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
              <span
                v-for="(list, index) in form.employee_materials_url"
                :key="index"
              >
                <el-image
                  v-if="list"
                  :preview-src-list="[list]"
                  :src="list"
                  style="width: 100px; height: 100px"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中
                    <span class="dot">...</span>
                  </div>
                </el-image>
              </span>
            </el-row>
          </el-form-item>
          <el-form-item label="佐证材料">
            <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
              <span v-for="(list, index) in form.evidence_url" :key="index">
                <el-image
                  v-if="list"
                  :preview-src-list="[list]"
                  :src="list"
                  style="width: 100px; height: 100px"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中
                    <span class="dot">...</span>
                  </div>
                </el-image>
              </span>
            </el-row>
          </el-form-item>
          <el-form-item label="所属行业">{{ form.trade_txt }}</el-form-item>
          <el-form-item label="申请人姓名">{{ form.apply_name }}</el-form-item>
          <el-form-item label="申请人职务">{{ form.apply_job }}</el-form-item>
          <el-form-item label="主管理员手机号">{{ form.mobile }}</el-form-item>
          <el-form-item label="店铺logo">
            <el-image
              style="width: 100px; height: 100px"
              v-if="form.logo"
              :src="form.logo"
              :preview-src-list="[form.logo]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </el-form-item>
          <el-form-item label="所在地址">{{ form.address }}</el-form-item>
          <el-form-item label="创立时间">{{ form.created_time }}</el-form-item>
          <el-form-item label="工厂面积">{{ form.plant_area }}</el-form-item>
          <el-form-item label="员工人数">{{ form.staffs_number }}</el-form-item>
          <el-form-item label="授权书/合作协议">
            <el-image
              style="width: 100px; height: 100px"
              v-if="form.agreement_url"
              :src="form.agreement_url"
              :preview-src-list="[form.agreement_url]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </el-form-item>
          <el-form-item label="商标注册证/商标注册申请受理通知">
            <el-image
              style="width: 100px; height: 100px"
              v-if="form.trademark_url"
              :src="form.trademark_url"
              :preview-src-list="[form.trademark_url]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </el-form-item>
          <el-form-item label="食品经营许可证">
            <el-image
              style="width: 100px; height: 100px"
              v-if="form.food_blicense_url"
              :src="form.food_blicense_url"
              :preview-src-list="[form.food_blicense_url]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </el-form-item>
          <el-form-item label="食品生产许可证">
            <el-image
              style="width: 100px; height: 100px"
              v-if="form.food_plicense_url"
              :src="form.food_plicense_url"
              :preview-src-list="[form.food_plicense_url]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </el-form-item>
          <el-form-item label="样板市场">
            <el-input
              style="overflow: auto"
              type="textarea"
              class="textimgw"
              :rows="6"
              v-html="form.model_market"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司简介">
            <el-input
              style="overflow: auto"
              type="textarea"
              class="textimgw"
              :rows="6"
              v-html="form.introduction"
            ></el-input>
          </el-form-item>
          <el-form-item label="扶持政策">
            <el-input
              style="overflow: auto"
              type="textarea"
              class="textimgw"
              :rows="6"
              v-html="form.supportive_policy"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-show="form.status != 1" @click="deleteRow(form, 3)">
          拒绝
        </el-button>
        <el-button
          v-show="form.status != 1"
          type="primary"
          @click="deleteRow(form, 1)"
        >
          通过
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/request'

  export default {
    name: 'info',
    data() {
      return {
        showinfo: false,
        form: {},
      }
    },
    methods: {
      deleteRow(id, status) {
        postAction('/audit/shop/audit', { id: id, status }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.$emit('getlist')
          }
        })
      },
    },
  }
</script>
<style scoped>
  ::v-deep .textimgw img {
    max-width: 80% !important;
    height: auto !important;
  }
</style>
