<script>
import { getAction, postAction } from "@/api/request";
import WangEditor from "@/components/WangEditor.vue";

export default {
  name: "addEdit",
  data() {
    return {
      showadd: false,
      columnlist:[],
      form: {

        /**
         * ID，修改必填
         */
        id: '',
        /**
         * 排序
         */
        sort_order:'',
        /**
         * 状态 1启用2禁用
         */
        status: '',
        /**
         * 名称
         */
        name: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur',max:8,min:2 },
        ],

      },
    }
  },
  watch: {
    showadd(val) {
      if (!val) {
        this.close()
        this.$refs.form.resetFields()
      }
    }
  },
  mounted() {
    getAction('/api/system/help/type-lists',{limit:-1}).then(res=>{
      this.columnlist=res.data
    })
  },
  methods: {
    handlersave() {
      this.$refs.form.validate(valid => {
        if (valid){
          postAction("/api/system/help/type-edit",this.form).then(res=>{
            this.$message({
              type:'success',
              message:res.msg
            })
            this.$emit("getlist")
            this.close()
          })
        }
      })
    },
    show(row) {
      this.showadd = true
      this.form = Object.assign(this.form, row)
    },
    close() {
      this.showadd = false
      this.form = {
        /**
         * ID，修改必填
         */
        id: '',
        /**
         * 排序
         */
        sort_order:'',
        /**
         * 状态 1启用2禁用
         */
        status: '',
        /**
         * 名称
         */
        name: '',
      }
    }
  }
};
</script>

<template>
  <el-dialog
    title=""
    :visible.sync="showadd"
    center
    width="30%">
    <span>
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <el-form-item label="栏目名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入标题" clearable></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input v-model="form.sort_order" placeholder="请输入排序" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态" clearable>
            <el-option label="正常" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<style scoped>

</style>
