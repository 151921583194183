var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: tab.title, name: tab.name },
          })
        }),
        1
      ),
      _vm.activeTab > "0"
        ? _c(
            "el-form",
            { attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "品类" },
                      on: { change: _vm.handlercat },
                      model: {
                        value: _vm.form.cate_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cate_id", $$v)
                        },
                        expression: "form.cate_id",
                      },
                    },
                    _vm._l(_vm.catSelect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { value: list.id, label: list.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerAddgoods },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.activeTab == "1" ? "上传店铺" : "上传产品"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab == "0"
        ? _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "60%" },
                  attrs: { border: "", data: _vm.tabledata },
                },
                [
                  _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "标题" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "内容类型" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                scope.row.type == 1
                                  ? _c("span", [_vm._v("名企")])
                                  : scope.row.type == 2
                                  ? _c("span", [_vm._v("新品")])
                                  : scope.row.type == 3
                                  ? _c("span", [_vm._v("热品")])
                                  : scope.row.type == 4
                                  ? _c("span", [_vm._v("产品")])
                                  : _vm._e(),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      836665916
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sort_order", label: "排序" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              [
                                scope.$index < 3
                                  ? _c("span")
                                  : _c("el-input", {
                                      attrs: { type: "number" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleSortChange(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.sort_order,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "sort_order", $$v)
                                        },
                                        expression: "scope.row.sort_order",
                                      },
                                    }),
                              ],
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      820327298
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": "1",
                                  "inactive-value": "0",
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ccc",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleStatusChange(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.status,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "status", $$v)
                                  },
                                  expression: "scope.row.status",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2159505900
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShowAdd1(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 编辑 ")]
                              ),
                              scope.$index > 2
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmDelete(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      642923601
                    ),
                  }),
                ],
                1
              ),
              _vm.activeTab == "0"
                ? _c(
                    "el-button",
                    {
                      staticStyle: {
                        position: "absolute",
                        bottom: "5px",
                        left: "62%",
                      },
                      attrs: { type: "primary" },
                      on: { click: _vm.onShowAdd1 },
                    },
                    [_vm._v(" 新增 ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab == "1"
        ? _c(
            "div",
            [
              _c("p", { staticStyle: { color: "#f00" } }, [
                _vm._v(
                  " 系统规则:优先展示付费企业，然后是免费企业，数据实时更新。手动设置：可手动设置店铺展示的顺序。手动设置有时效性，且优先级大于系统规则 "
                ),
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    border: "",
                    data: _vm.tabledata,
                    "row-key": "user_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ID",
                      prop: "id",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "店铺LOGO", prop: "img" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: scope.row.img, alt: "img" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1820575917
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "店铺名称",
                      prop: "goods_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "排序",
                      prop: "sort_order",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "生效时间" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.start_time
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.start_time) +
                                        "至" +
                                        _vm._s(scope.row.end_time) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [_vm._v("永久有效")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      50084502
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: { title: "确认删除吗？" },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.onDelete(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        size: "small",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v(" 移除 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2266078847
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab == "2"
        ? _c(
            "div",
            [
              _c("p", { staticStyle: { color: "#f00" } }, [
                _vm._v(
                  " 系统规则：拉取首页每日上新二级页面品类页面产品。手动设置：可手动设置新品展示的顺 序，手动设置有时效性，且优先级大于系统规则。（新品更多规则：同一店铺不限制新品数量且除去下面表格产品）支持手动上传第三方产品 "
                ),
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    border: "",
                    data: _vm.tabledata,
                    "row-key": "user_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ID",
                      prop: "id",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "产品LOGO", prop: "img" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: scope.row.img, alt: "img" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1820575917
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "产品名称",
                      prop: "goods_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "店铺",
                      prop: "shop_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "发布时间",
                      prop: "created",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "排序",
                      prop: "sort_order",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "生效时间",
                      prop: "mobile",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.start_time
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.start_time) +
                                        "至" +
                                        _vm._s(scope.row.end_time) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [_vm._v("永久有效")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      50084502
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: { title: "确认删除吗？" },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.onDelete(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        size: "small",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v(" 移除 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2266078847
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab == "3"
        ? _c(
            "div",
            [
              _c("p", { staticStyle: { color: "#f00" } }, [
                _vm._v(
                  " 系统规则：根据最近7天的产品数据，每日生成一个top10榜单；搜索指数= 0.3*搜索产品曝光（下拉提示+搜索结果页）+0.2*搜索点击进入产品页+0.1*产品浏览次数 +0.3*产品互动数（留言+电话）+0.1*样品申请数。手动设置有时效性，且优先级大于系统规则。支持手动上传第三方产品 "
                ),
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    border: "",
                    data: _vm.tabledata,
                    "row-key": "user_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ID",
                      prop: "id",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "产品LOGO", prop: "name" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: scope.row.img, alt: "img" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1820575917
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "产品名称",
                      prop: "goods_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "店铺",
                      prop: "shop_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "浏览数",
                      prop: "view_num",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "热搜指数",
                      prop: "index_num",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "排序",
                      prop: "sort_order",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "生效时间" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.start_time
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.start_time) +
                                        "至" +
                                        _vm._s(scope.row.end_time) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [_vm._v("永久有效")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      50084502
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: { title: "确认删除吗？" },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.onDelete(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        size: "small",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v(" 移除 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2266078847
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab > "3"
        ? _c(
            "div",
            [
              _c("p", { staticStyle: { color: "#f00" } }, [
                _vm._v(
                  " 系统规则：优先展示付费企业产品，然后是免费企业产品，每个企业随机选择一个产品，数据实时更新。手动设置：可手动设置产品展示的顺 序，手动设置有时效性，且优先级大于系统规则。支持手动上传第三方产品 "
                ),
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    border: "",
                    data: _vm.tabledata,
                    "row-key": "user_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ID",
                      prop: "id",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "产品LOGO", prop: "name" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: scope.row.img, alt: "img" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1820575917
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "产品名称",
                      prop: "goods_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "店铺",
                      prop: "shop_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "排序",
                      prop: "sort_order",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "生效时间",
                      prop: "mobile",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.start_time
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.start_time) +
                                        "至" +
                                        _vm._s(scope.row.end_time) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [_vm._v("永久有效")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      50084502
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: { title: "确认删除吗？" },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.onDelete(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        size: "small",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v(" 移除 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2266078847
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab > 0
        ? _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          })
        : _vm._e(),
      _c("catinfo", { ref: "catinfo" }),
      _c("add1", { ref: "add1", on: { getdata: _vm.getdata } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增功能",
            visible: _vm.showAddDialog,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.newItem,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newItem.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "title", $$v)
                      },
                      expression: "newItem.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片" } },
                [
                  _c("upload-img", {
                    ref: "icon",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "icon")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newItem.title_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.newItem, "title_type", $$v)
                        },
                        expression: "newItem.title_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("文本"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("图片"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.currentIndex < 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "产品轮播时间" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.newItem.cycle_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.newItem, "cycle_time", $$v)
                          },
                          expression: "newItem.cycle_time",
                        },
                      }),
                      _vm._v(" 秒 "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentIndex == 2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "规则类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.newItem.rule_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newItem, "rule_type", $$v)
                                },
                                expression: "newItem.rule_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("周榜"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("月榜"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "规则" } },
                        [
                          _c("el-input", {
                            attrs: { type: "input", placeholder: "数据来源" },
                            model: {
                              value: _vm.newItem.rule_title,
                              callback: function ($$v) {
                                _vm.$set(_vm.newItem, "rule_title", $$v)
                              },
                              expression: "newItem.rule_title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "规则内容" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", placeholder: "请输入" },
                            model: {
                              value: _vm.newItem.rule_content,
                              callback: function ($$v) {
                                _vm.$set(_vm.newItem, "rule_content", $$v)
                              },
                              expression: "newItem.rule_content",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品副标题图标" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.newItem.goods_sub_icon,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newItem, "goods_sub_icon", $$v)
                                },
                                expression: "newItem.goods_sub_icon",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _c("img", {
                                  staticStyle: { "vertical-align": "-9px" },
                                  attrs: {
                                    src: require("../../../../assets/yan.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _c("img", {
                                  staticStyle: { "vertical-align": "-7px" },
                                  attrs: {
                                    src: require("../../../../assets/huo.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("el-radio", { attrs: { label: "" } }, [
                                _vm._v("无"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品副标题数据" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.handleGoodsSubDataChange },
                              model: {
                                value: _vm.newItem.goods_sub_data,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newItem, "goods_sub_data", $$v)
                                },
                                expression: "newItem.goods_sub_data",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "a" } }, [
                                _vm._v("a浏览量"),
                              ]),
                              _c("el-radio", { attrs: { label: "b" } }, [
                                _vm._v("b搜索指数"),
                              ]),
                              _c("el-radio", { attrs: { label: "" } }, [
                                _vm._v("无"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品副标题" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              type: "input",
                              maxlength: "5",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.newItem.goods_sub_title,
                              callback: function ($$v) {
                                _vm.$set(_vm.newItem, "goods_sub_title", $$v)
                              },
                              expression: "newItem.goods_sub_title",
                            },
                          }),
                          _c("span", { staticStyle: { color: "#f00" } }, [
                            _vm._v("限制5个字符"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentIndex > 2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "平台产品数量" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.newItem.platform_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.newItem, "platform_num", $$v)
                              },
                              expression: "newItem.platform_num",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "非平台产品数量" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.newItem.no_platform_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.newItem, "no_platform_num", $$v)
                              },
                              expression: "newItem.no_platform_num",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.newItem.sort_order,
                              callback: function ($$v) {
                                _vm.$set(_vm.newItem, "sort_order", $$v)
                              },
                              expression: "newItem.sort_order",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "效果" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.newItem.display_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newItem, "display_type", $$v)
                                },
                                expression: "newItem.display_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("横向展示"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("树列展示"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newItem.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.newItem, "status", $$v)
                        },
                        expression: "newItem.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showAddDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAddConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传",
            visible: _vm.uploadProductDialog.visible,
            "before-close": _vm.closeUpload,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.uploadProductDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: {
                model: _vm.uploadProductDialog.form,
                "label-width": "120px",
              },
            },
            [
              _vm.uploadProductDialog.form.conf_id == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "店铺名称" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          "fetch-suggestions": _vm.handlerstore,
                          placeholder: "请输入店铺名称",
                          "value-key": "mall_name",
                        },
                        on: { select: _vm.handleSelectstore },
                        model: {
                          value: _vm.uploadProductDialog.form.mall_name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.uploadProductDialog.form,
                              "mall_name",
                              $$v
                            )
                          },
                          expression: "uploadProductDialog.form.mall_name",
                        },
                      }),
                      _c("span", [_vm._v("可输入关键词模糊搜索")]),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.uploadProductDialog.form.type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.uploadProductDialog.form,
                                    "type",
                                    $$v
                                  )
                                },
                                expression: "uploadProductDialog.form.type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("平台产品"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("非平台产品"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称" } },
                        [
                          _c("el-autocomplete", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              "fetch-suggestions": _vm.handlermell,
                              placeholder: "请输入产品名称",
                              "value-key": "goods_name",
                            },
                            on: { select: _vm.handleSelectmall },
                            model: {
                              value: _vm.uploadProductDialog.form.goods_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.uploadProductDialog.form,
                                  "goods_name",
                                  $$v
                                )
                              },
                              expression: "uploadProductDialog.form.goods_name",
                            },
                          }),
                          _c("span", [_vm._v("可输入关键词模糊搜索")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.uploadProductDialog.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.uploadProductDialog.form,
                          "sort_order",
                          $$v
                        )
                      },
                      expression: "uploadProductDialog.form.sort_order",
                    },
                  }),
                ],
                1
              ),
              _vm.uploadProductDialog.form.conf_id == 3
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "热搜指数" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { type: "number" },
                            model: {
                              value: _vm.uploadProductDialog.form.index_num,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.uploadProductDialog.form,
                                  "index_num",
                                  $$v
                                )
                              },
                              expression: "uploadProductDialog.form.index_num",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "浏览量" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { type: "number" },
                            model: {
                              value: _vm.uploadProductDialog.form.view_num,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.uploadProductDialog.form,
                                  "view_num",
                                  $$v
                                )
                              },
                              expression: "uploadProductDialog.form.view_num",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "生效时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "default-time": ["12:00:00"],
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeUpload } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleUploadConfirm },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }