<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExporttab">导 出</el-button>
      </el-form-item>
            <el-form-item>
        <el-button type="primary" @click="handlerExportInfo">导出明细</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '操作'">
             <el-button type="text" @click="handlersort(row)">查看员工排行</el-button>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog title="员工排行" :visible.sync="dialogVisible" width="900px">
      <div>
        <el-form :model="fetch" inline>
          <el-form-item>
            <el-input v-model="fetch.htyun_name" clearable placeholder="姓名" style="width: 160px" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerfind">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerExport">导 出</el-button>
          </el-form-item>
        </el-form>
        <el-table border :data="sortdata" style="width: 100%">
          <el-table-column label="姓名" prop="htyun_name" width="" align="center">
          </el-table-column>
          <el-table-column label="邀请进入的UV（除去员工）" prop="uv" width="" align="center">
          </el-table-column>
          <el-table-column label="邀请进入的PV（去除员工）" prop="pv" width="" align="center">
          </el-table-column>
          <el-table-column label="邀请的留言数量（去除员工）" prop="suggest_num" width="" align="center">
          </el-table-column>
          <el-table-column label="邀请的样品申请数量（去除员工）" prop="order_num" width="" align="center">
          </el-table-column>
          <el-table-column label="邀请的电话资询数量（去除员工）" prop="phone_num" width="" align="center">
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="fetch.page"
          :layout="layout"
          :page-size="fetch.limit"
          style="text-align: center; margin-top: 10px"
          :total="sorttotal"
          @current-change="handleCurrentfetch"
          @size-change="handleSizefetch"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import dayjs from "dayjs";
export default {
  name: 'Index',
  data() {
    return {
      form: {
        mall_name:'',
        start_time: '',
        end_time: '',
        page: 1,
        limit: 10,
      },
      sortdata:[],
      dialogVisible:false,
      sorttotal:0,
      fetch:{
        end_time: '',
        limit: '10',
        page: '1',
        /**
         * 店铺id
         */
        shop_id: '',
        /**
         * 开始时间
         */
        start_time: '',
        htyun_name:'',
      },
      time: [],
      staffSelect:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '店铺名称',
          prop: 'shop_name',
          width: '',
        },

        {
          label: '邀请进入的UV（去除员工）',
          prop: 'uv',
          width: '',
        },

        {
          label: '邀请进入的PV（去除员工）',
          prop: 'pv',
          width: '',
        },
        {
          label: '邀请的留言数量（去除员工）',
          prop: 'suggest_num',
          width: '',
        },
        {
          label: '邀请的样品申请数量（去除员工）',
          prop: 'order_num',
          width: '',
        },
        {
          label: '邀请的电话资询数量（去除员工）',
          prop: 'phone_num',
          width: '',
        },
        {
          label: '操作',
          prop: '',
          width: '',
        }
      ],
      total: 0,
    }
  },
  watch: {
    dialogVisible(v) {
      if(!v){
        this.fetch={
          end_time: '',
          limit: '10',
          page: '1',
          /**
           * 店铺id
           */
          shop_id: '',
          /**
           * 开始时间
           */
          start_time: '',
          htyun_name:'',
        }
      }
    },
    time(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    },
  },
  created() {
    this.time=[dayjs(new Date().getTime()-1000*3600*24*7).format('YYYY-MM-DD'),dayjs(new Date().getTime()).format('YYYY-MM-DD')]
    this.form.start_time = dayjs(new Date().getTime()-1000*3600*24*7).format('YYYY-MM-DD')
    this.form.end_time = dayjs(new Date().getTime()).format('YYYY-MM-DD')
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/api/form/form/share-shop-rank-tj', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handlerfind(){
      this.fetch.page=1
      getAction('/api/form/form/share-staff-rank-tj', {...this.fetch}).then(
        (res) => {
          this.sortdata = res.data
          this.sorttotal = Number(res.totalCount)
          this.dialogVisible = true
        }
      )
    },
    handlersort(row){
      this.fetch.shop_id = row.shop_id
      this.fetch.start_time = this.form.start_time
      this.fetch.end_time = this.form.end_time
      getAction('/api/form/form/share-staff-rank-tj', {...this.fetch}).then(
        (res) => {
          this.sortdata = res.data
          this.sorttotal = Number(res.totalCount)
          this.dialogVisible = true
        }
      )
    },
    handlerExportInfo(){
      getAction('/api/form/form/share-staff-rank-tj-all-export', this.form).then(
        (res) => {
          this.$message.success('已添加到导出队列')
        }
      )
    },
    handlerExporttab(){
      getAction('/api/form/form/share-shop-rank-tj-export', this.form).then(
        (res) => {
          this.$message.success('已添加到导出队列')
        }
      )
    },
    handlerExport() {
      postAction('/api/form/form/share-staff-rank-tj-export', this.fetch).then(
        (res) => {
         this.$message.success('已添加到导出队列')
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
    handleCurrentfetch(val) {
      this.fetch.page = val
      this.handlerfind()
    },
    handleSizefetch(val) {
      this.fetch.limit = val
      this.handlerfind()
    },
  },
}
</script>

<style scoped></style>
