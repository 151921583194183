<script xmlns="http://www.w3.org/1999/html">
import { postAction } from "@/api/request";

export default {
  name: "info",
  data() {
    return {
      title: "",
      dialogVisible: false,
      tabledata: [],
      form_data:{},
      form: {
        page: 1,
        limit: 10},
      total: 0,
      layout: "total, sizes, prev, pager, next, jumper",
      columns: [
        {
          label: "分配日期",
          prop: "divide_date",
          width: "110",
          align: "center"
        },
        {
          label: "云交会昵称",
          prop: "nickname",
          width: "220px",
          align: "center"
        },
        {
          label: "企业名称",
          prop: "company_name",
          width: "120",
          align: "center"
        },
        {
          label: "手机号",
          prop: "mobile",
          width: "120",
          align: "center"
        },
        {
          label: "跟进客服",
          prop: "htyun_name",
          width: "80",
          align: "center"
        },
        {
          label: "来源",
          prop: "source",
          width: "110",
          align: "center"
        },
        {
          label: "加好友",
          prop: "friend_flag",
          width: "110",
          align: "center"
        },
        {
          label: "有效沟通",
          prop: "is_effect",
          width: "110",
          align: "center",
        },
        {
          label: "跟进结果",
          prop: "follow_status",
          width: "80",
          align: "center"
        },
        {
          label: "厂家",
          prop: "factory",
          width: "120",
          align: "center"
        },
        {
          label: "平台厂家",
          prop: "is_platform",
          width: "120",
          align: "center"
        },
        {
          label: "付费/免费",
          prop: "pay_txt",
          width: "120",
          align: "center",
        },
        {
          label: "业务",
          prop: "yewu",
          width: "120",
          align: "center",
        },
        {
          label: "产品",
          prop: "goods",
          width: "120",
          align: "center"
        },
        {
          label: "是否云交会合作",
          prop: "is_coop",
          width: "",
          align: "center",
        },
        {
          label: "打款金额",
          prop: "price",
          width: "",
          align: "center",
        },
        {
          label: "一级品类需求",
          prop: "one_cate",
          width: "",
          align: "center",
        },
        {
          label: "二级品类需求",
          prop: "two_cate",
          width: "",
          align: "center",
        },
        {
          label: "企微需求",
          prop: "is_biz",
          width: "110",
          align: "center",
        },
        {
          label: "备注",
          prop: "remark",
          width: "120",
          align: "center"
        }
      ]
    };
  },
  methods: {
    handlerinfo(row) {
      let data = JSON.parse(JSON.stringify(row));
      this.form_data=data;
      postAction("/api/form/crm/lists", {...this.form,id: data.id,divide_start_time: data.start_time,divide_end_time: data.end_time}).then((res) => {
        this.tabledata = res.data.list;
        this.total = Number(res.totalCount);
      });
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.handlerinfo(this.form_data);
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.handlerinfo(this.form_data);
    }
  }
};
</script>

<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="80%">
    <span>
      <el-row>
        <el-col :span="24" type="flex" justify="center" align="center">
          <el-form :inline="true" :model="form_data" label-width="80px">
            <el-form-item :label="form_data.htyun_name">
              {{ form_data.start_time }} {{ form_data.end_time }}
            </el-form-item>
            </br>
            <el-form-item label="分配数量">
              {{ form_data.num }}
            </el-form-item>
            <el-form-item label="跟进">{{form_data.follow_num}}</el-form-item>
            <el-form-item label="有效沟通">{{form_data.status_1_num}}</el-form-item>
            <el-form-item label="未跟进">{{form_data.no_follow_num}}</el-form-item>
            <el-form-item label="跟进率">{{form_data.follow_rate}}</el-form-item>
            <el-form-item label="有效沟通率">{{form_data.effect_rate}}</el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table border :data="tabledata" style="width: 100%">
      <el-table-column type="index" width="55" align="center"></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :align="item.align"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    </span>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
  </span>
  </el-dialog>
</template>

<style scoped>

</style>
