<script xmlns="http://www.w3.org/1999/html">
import { postAction } from "@/api/request";
import axios from "axios";

export default {
  name: "importSer",
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "",
        in_file:''
      },
      tableData: [],
      total: 0,
      layout: "total, sizes, prev, pager, next, jumper",
      rules: {
        name: [
          { required: true, message: "请输入任务名", trigger: "blur" }
        ],
        in_file:[
          { required: true,message: "请选择文件",  trigger: 'change' }
        ]
      },
      tableform:{
        page: 1,
        limit: 10
      }
    };
  },
  watch: {
    dialogVisible(v) {
      if (!v) {
        this.form={
          name: "",
          in_file:''
        }
        this.$refs.form.resetFields();
      }
    }
  },
  mounted() {
    this.handlerlist();
  },
  methods: {
    handlerdownload(){
      const url= 'https://res.httoutiao.com/1/%E4%BB%BB%E5%8A%A1%E5%88%86%E9%85%8D%E8%A1%A8.csv'
      const DomA = document.createElement('a')
      DomA.href = url
      DomA.download = '后台导入经销商库模板.csv'
      DomA.click()
      DomA.remove()
      this.$message({
        type: 'success',
        message: '下载成功'
      })
    },
    async getOssData() {
      return postAction("/api/common/common/oss-sign").then(res=>{
        console.log(res)
        return res.data
      })
    },
    generateFileName(ossData, file){
      console.log("走到这了吗",file)
      // const suffix = file.name.slice(file.name.lastIndexOf('.'));
      console.log(ossData.dir)
      return ossData.dir +'/'+ file.name;
    },
    async handleraddfile(e){
      console.log("文件上传",e.target.files[0])
      const file = e.target.files[0]
      const ossData = await this.getOssData()
      const key = this.generateFileName(ossData, file)
      console.log("走到这了吗2")
      const formdata = new FormData()

      // 注意参数的顺序，key 必须是第一位，表示OSS存储文件的路径
      formdata.append('key', key)
      formdata.append('OSSAccessKeyId', ossData.accessid)
      formdata.append('policy', ossData.policy)
      formdata.append('signature', ossData.signature)
      // 文件上传成功默认返回 204 状态码，可根据需要修改为 200
      formdata.append('success_action_status', '200')
      // file 必须放在最后一位
      formdata.append('file', file)
      console.log("走到这了吗1")
      await axios.post(ossData.host, formdata,{
        headers:{
          'Content-Type': "multipart/form-data",
        }
      }).then(res=>{
        if(res.status === 200) {
          e.target.value = ""
          this.form.in_file = 'https://res.httoutiao.com/'+key
          this.$message.success('文件已选中请输入任务名后提交')
        }

      })
    },
    handlerexport(){
      this.$refs.file.click()
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          postAction('/api/form/crm/import',this.form).then((res)=>{
            this.$message.success(res.msg)
            this.dialogVisible = false
            this.handlerlist();
          })
        }
      });

    },
    handlerView(row){
      postAction('/api/form/crm/revoke',{id:row.id}).then((res)=>{
        this.$message.success(res.msg)
        this.handlerlist();
      })
    },
    handlerlist(){
      postAction('/api/form/crm/import-lists',this.tableform).then((res)=>{
        this.tableData = res.data.list
        this.total = res.totalCount
      })
    },
    handleCurrentChange(val) {
      this.tableform.page = val;
      this.handlerlist();
    },
    handleSizeChange(val) {
      this.tableform.limit = val;
      this.handlerlist();
    }
  }
};
</script>

<template>
  <el-dialog
    title="任务导入"
    :visible.sync="dialogVisible"
    width="60%">
    <span>
      <el-form ref="form" :model="form" inline :rules="rules">
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="请输入任务名" />
          </br>
          <span style="color: red">提交后晚20:00系统会自动执行分配，执行前可撤回</span>
        </el-form-item>
        <el-form-item prop="in_file">
          <input v-show="false" type="file" ref="file" @change="handleraddfile" accept=".csv,.xls,.xlsx"/>
          <el-button icon="el-icon-plus" type="warning" @click="handlerexport">选择导入文件</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
        <el-button type="primary" @click="handlerdownload">
          下载导入模版
        </el-button>
      </el-form>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="name" label="任务名称" width="180"></el-table-column>
        <el-table-column prop="file_name" label="文件名称" width="180"></el-table-column>
        <el-table-column prop="admin_name" label="操作人" width="180"></el-table-column>
        <el-table-column prop="time" label="导入时间"></el-table-column>
        <el-table-column prop="status_txt" label="任务状态"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template #default="{row}">
            <el-button type="text" size="small" @click="handlerView(row)" v-if="row.status==1">撤回</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="tableform.limit"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </span>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">关闭</el-button>
  </span>
  </el-dialog>
</template>

<style scoped>

</style>
