var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        closed: _vm.handleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "160px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("h3", [_vm._v("收件人信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "收件人姓名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入收件人姓名" },
                    model: {
                      value: _vm.form.recipient_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "recipient_name", $$v)
                      },
                      expression: "form.recipient_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人公司" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入收件人公司" },
                    model: {
                      value: _vm.form.recipient_company,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "recipient_company", $$v)
                      },
                      expression: "form.recipient_company",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人手机号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "手机号和电话必填一个" },
                    model: {
                      value: _vm.form.recipient_phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "recipient_phone", $$v)
                      },
                      expression: "form.recipient_phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人电话" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "手机号和电话必填一个" },
                    model: {
                      value: _vm.form.recipient_mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "recipient_mobile", $$v)
                      },
                      expression: "form.recipient_mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人地址", prop: "recipient_address" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入收件人地址" },
                    model: {
                      value: _vm.form.recipient_address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "recipient_address", $$v)
                      },
                      expression: "form.recipient_address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("h3", [_vm._v("发件人信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "发件人姓名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入发件人姓名" },
                    model: {
                      value: _vm.form.sender_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sender_name", $$v)
                      },
                      expression: "form.sender_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发件人公司" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入发件人公司" },
                    model: {
                      value: _vm.form.sender_company,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sender_company", $$v)
                      },
                      expression: "form.sender_company",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发件人手机号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入发件人手机号" },
                    model: {
                      value: _vm.form.sender_phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sender_phone", $$v)
                      },
                      expression: "form.sender_phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发件人电话" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入发件人电话" },
                    model: {
                      value: _vm.form.sender_mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sender_mobile", $$v)
                      },
                      expression: "form.sender_mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发件人地址", prop: "sender_address" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入发件人地址" },
                    model: {
                      value: _vm.form.sender_address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sender_address", $$v)
                      },
                      expression: "form.sender_address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("h3", [_vm._v("商品信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.form.goods_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_name", $$v)
                      },
                      expression: "form.goods_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内件详情" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内件详情" },
                    model: {
                      value: _vm.form.goods_detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_detail", $$v)
                      },
                      expression: "form.goods_detail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品数量" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1 },
                    model: {
                      value: _vm.form.goods_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_num", $$v)
                      },
                      expression: "form.goods_num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.goods_type_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "goods_type_id", $$v)
                        },
                        expression: "form.goods_type_id",
                      },
                    },
                    _vm._l(_vm.typeList, function (item, index) {
                      return _c(
                        "el-radio",
                        {
                          key: index,
                          attrs: { label: item.id, value: item.id },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "月份", prop: "month" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      placeholder: "请选择月份",
                      type: "month",
                      "value-format": "yyyy年MM月",
                    },
                    model: {
                      value: _vm.form.month,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "month", $$v)
                      },
                      expression: "form.month",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "重量(kg)" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, precision: 2 },
                    model: {
                      value: _vm.form.goods_weight,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_weight", $$v)
                      },
                      expression: "form.goods_weight",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "尺寸" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", gap: "10px" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "120px" },
                        attrs: { placeholder: "长" },
                        model: {
                          value: _vm.form.goods_length,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goods_length", $$v)
                          },
                          expression: "form.goods_length",
                        },
                      },
                      [_c("template", { slot: "suffix" }, [_vm._v("cm")])],
                      2
                    ),
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "120px" },
                        attrs: { placeholder: "宽" },
                        model: {
                          value: _vm.form.goods_width,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goods_width", $$v)
                          },
                          expression: "form.goods_width",
                        },
                      },
                      [_c("template", { slot: "suffix" }, [_vm._v("cm")])],
                      2
                    ),
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "120px" },
                        attrs: { placeholder: "高" },
                        model: {
                          value: _vm.form.goods_height,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goods_height", $$v)
                          },
                          expression: "form.goods_height",
                        },
                      },
                      [_c("template", { slot: "suffix" }, [_vm._v("cm")])],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入备注",
                      rows: 3,
                      type: "textarea",
                    },
                    model: {
                      value: _vm.form.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "note", $$v)
                      },
                      expression: "form.note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("h3", [_vm._v("物流信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "客户订单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入客户订单号" },
                    model: {
                      value: _vm.form.logistics_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "logistics_order", $$v)
                      },
                      expression: "form.logistics_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物流公司" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择物流公司",
                      },
                      model: {
                        value: _vm.form.logistics_company_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "logistics_company_id", $$v)
                        },
                        expression: "form.logistics_company_id",
                      },
                    },
                    _vm._l(_vm.wuliuList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物流单号", prop: "logistics_number" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入物流单号" },
                    model: {
                      value: _vm.form.logistics_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "logistics_number", $$v)
                      },
                      expression: "form.logistics_number",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "寄递产品" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入寄递产品" },
                    model: {
                      value: _vm.form.logistics_goods,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "logistics_goods", $$v)
                      },
                      expression: "form.logistics_goods",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "付款方式", prop: "pay_type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.pay_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pay_type", $$v)
                        },
                        expression: "form.pay_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("寄付"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("到付"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "寄速费" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请输入寄速费" },
                      model: {
                        value: _vm.form.send_price,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "send_price", $$v)
                        },
                        expression: "form.send_price",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "到付费" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请输入到付费" },
                      model: {
                        value: _vm.form.cod_price,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cod_price", $$v)
                        },
                        expression: "form.cod_price",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "保价金额" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请输入保价金额" },
                      model: {
                        value: _vm.form.insurance_price,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "insurance_price", $$v)
                        },
                        expression: "form.insurance_price",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "代收货款" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请输入代收货款" },
                      model: {
                        value: _vm.form.collect_price,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "collect_price", $$v)
                        },
                        expression: "form.collect_price",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "white-space": "nowrap",
                    "margin-right": "20px",
                  },
                  attrs: { label: "密码投递 (收取服务费1元)" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.is_password,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_password", $$v)
                        },
                        expression: "form.is_password",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: "0" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "返单服务" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.return_service,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "return_service", $$v)
                        },
                        expression: "form.return_service",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("电子返单"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("实物返单"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }