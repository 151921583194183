<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="formkind"
          clearable
          filterable
          multiple
          placeholder="一级事业部"
          style="width: 250px"
        >
          <el-option
            v-for="list in kindselect"
            :key="list"
            :label="list"
            :value="list"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formdept"
          clearable
          multiple
          filterable
          placeholder="二级部门"
          style="width: 250px"
        >
          <el-option
            v-for="list in departselect"
            :key="list"
            :label="list"
            :value="list"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.staff_name"
          clearable
          placeholder="员工姓名"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导出全部</el-button>
      </el-form-item>
    </el-form>
    <div style="color: #999; line-height: 40px">
      本页面数据范围：截止到昨日24：00，所有员工的好友数量及标签缺失数量
    </div>
    <el-table
      border
      v-loading="tableloading"
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        prop="staff_name"
        label="员工姓名"
        width=""
      ></el-table-column>
      <el-table-column
        prop="htyun_kind"
        label="一级事业部"
        width=""
      ></el-table-column>
      <el-table-column
        prop="htyun_dept"
        label="二级部门"
        width=""
      ></el-table-column>
      <el-table-column
        prop="nums"
        label="好友数量"
        width=""
        :render-header="rendernum"
      >
        <!-- <template slot-scope="scope">
          <a @click="showDatalist(scope.row)">
            {{ scope.row.cert_num }}
          </a>
        </template> -->
      </el-table-column>
      <el-table-column
        prop="nums_hy"
        label="好友“行业”无标签数量"
        width=""
      ></el-table-column>
      <el-table-column
        prop="nums_cate"
        label="好友“主营类别”无标签数量"
        width=""
      ></el-table-column>
      <el-table-column
        prop="nums_identity"
        label="好友“客户身份”无标签数量"
        width=""
      ></el-table-column>
      <el-table-column
        prop="nums_channel"
        label="好友“渠道”无标签数量"
        width=""
      ></el-table-column>
      <el-table-column
        prop="nums_area"
        label="好友“区域”无标签数量"
        width=""
      ></el-table-column>
      <el-table-column prop="htyun_dept" label="操作" width="">
        <template slot-scope="scope">
          <el-button type="primary" @click="handlerExport(scope.row)">
            导出
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'

  export default {
    data() {
      return {
        tableloading: false,
        form: {
          name: '',
          page: 1,
          limit: 10,
          start_time: '',
          end_time: '',
          depart_id: '',
        },
        time: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],

        total: 0,
        sumtotal: '',
        departselect: [],
        kindselect: [],
        formkind: [],
        formdept: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.getKind()
      this.getDepart()
    },
    methods: {
      getKind() {
        postAction('/api/whale/tag/get-kind').then((res) => {
          if (res.request_code == 200) {
            this.kindselect = res.data
          }
        })
      },
      getDepart() {
        postAction('/api/whale/tag/get-dept').then((res) => {
          if (res.request_code == 200) {
            this.departselect = res.data
          }
        })
      },
      rendernum(h, { column, $index }) {
        console.log('render------------', h, column)
        return [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content: '显示截止到目前，所有员工的好友数量及标签缺失数量', // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ]
      },
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '提报人') {
            sums[index] = '共计'
          }
          if (column.label == '点击匹配查看按钮次数') {
            sums[index] = this.sumtotal.click_match_view_btn_num
          }
          if (column.label == '已匹配已查看') {
            sums[index] = this.sumtotal.match_num
          }

          console.log('合计数', this.sumtotal)
          // if (column.label === '合计') {
          //   sums[index] = this.sumtotal
          // }
        })
        return sums
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerExport(row) {
        postAction('/api/whale/tag/customer-lists-record-export', {
          staff_name: row ? row.staff_name : '',
          kind: this.form.kind,
          dept: this.form.dept,
        }).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },

      async handlerlist() {
        this.tableloading = true

        this.form.kind = this.formkind.join(',')
        this.form.dept = this.formdept.join(',')
        await postAction('/api/whale/tag/customer-lists', this.form).then(
          (res) => {
            this.tabledata = res.data
            this.total = Number(res.totalCount)
            this.tableloading = false
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
