<template>
  <div class="index-container">
    <FormMaker :labels="formLabel" ref="formMaker" @getFormData="handleQuery">
      <template #start>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="showBatchDialog(1)">
            批量设为手动锁定
          </el-button>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="showBatchDialog(3)">
            批量设为正常
          </el-button>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="showBatchDialog(2)">
            批量设为白名单
          </el-button>
        </el-form-item>
        <div></div>
      </template>

      <template #endbut>
        <div class="move-query-btn">
          <el-badge :value="badeNum" type="primary">
            <el-button type="primary" @click="showMoveQuery = !showMoveQuery">
              高级查询
            </el-button>
          </el-badge>
          <el-collapse-transition style="z-index: 1000">
            <div class="move-query" ref="move-query" v-show="showMoveQuery">
              <el-form
                style="padding: 15px"
                :model="form2"
                ref="form2"
                inline
                label-position="right"
                label-width="120px"
                label-suffix=":"
              >
                <el-form-item label="用户ID" prop="userid">
                  <el-input
                    clearable
                    style="width: 200px"
                    v-model="form2.userid"
                  ></el-input>
                </el-form-item>
                <!--                <el-form-item label="用户名" prop="username">
                  <el-input clearable style="width: 200px" v-model="form2.username"></el-input>
                </el-form-item>-->
                <el-form-item label="昵称" prop="nickname">
                  <el-input
                    clearable
                    style="width: 200px"
                    v-model="form2.nickname"
                  ></el-input>
                </el-form-item>
                <el-form-item label="渠道" prop="channel">
                  <el-select
                    placeholder=""
                    v-model="form2.channel"
                    clearable
                    style="width: 200px"
                  >
                    <el-option
                      v-for="(i, idx) in qudaoSelect"
                      :key="idx"
                      :value="i.id"
                      :label="i.channel_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="是否活跃用户" prop="active">
                  <el-select
                    placeholder=""
                    v-model="form2.active"
                    clearable
                    style="width: 200px"
                  >
                    <el-option value="1" label="否"></el-option>
                    <el-option value="2" label="是"></el-option>
                  </el-select>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="拥有手机号且半年登录两次及以上的才算为活跃用户"
                    placement="top"
                  >
                    <i
                      class="el-icon-question"
                      style="color: #ccc; margin-left: 5px; cursor: pointer"
                    ></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label="有无电话" prop="is_mobile">
                  <el-select
                    placeholder=""
                    v-model="form2.is_mobile"
                    clearable
                    style="width: 200px"
                  >
                    <el-option value="1" label="无电话"></el-option>
                    <el-option value="2" label="有电话"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="电话" prop="mobile">
                  <el-input
                    clearable
                    style="width: 200px"
                    v-model="form2.mobile"
                  ></el-input>
                </el-form-item>
                <el-form-item label="按注册时间" prop="time">
                  <el-date-picker
                    style="width: 200px"
                    v-model="form2.time"
                    type="daterange"
                    range-separator="-"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="按最近浏览时间" prop="active_time">
                  <!-- 默认0，1有最近浏览时间，2没有最近浏览时间 -->
                  <el-select
                    placeholder=""
                    v-model="form2.active_time"
                    clearable
                    style="width: 200px"
                  >
                    <el-option value="1" label="有最近浏览时间"></el-option>
                    <el-option value="2" label="没有最近浏览时间"></el-option>
                  </el-select>
                </el-form-item>
                <div class="center">
                  <el-button @click="handleReset">重置</el-button>
                  <el-button type="primary" @click="handleMoreQuery">
                    确定
                  </el-button>
                </div>
              </el-form>
            </div>
          </el-collapse-transition>
          <div
            class="dialog"
            v-if="showMoveQuery"
            ref="dialog"
            @click="showMoveQuery = false"
          ></div>
        </div>
      </template>
      <template #end>
        <el-button type="primary" @click="handleExport">导出</el-button>
      </template>
    </FormMaker>

    <el-table
      :data="list"
      border
      ref="table"
      v-loading="loading"
      row-key="user_id"
      @selection-change="selectChange"
    >
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
        fixed="left"
        reserve-selection
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :fixed="item.fixed"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.prop == 'headimg'">
            <el-image
              style="width: 50px; height: 50px"
              :src="row.headimg"
              :preview-src-list="[row.headimg]"
            ></el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="210px">
        <template #default="{ row }">
          <el-button @click="handleEdit(row)" type="text">编辑</el-button>
          <template
            v-if="
              row.is_lock_text == '自动锁定' || row.is_lock_text == '手动锁定'
            "
          >
            <el-button @click="handleNormal(row)" type="text">
              设为正常
            </el-button>
            <el-button @click="handleWhileList(row)" type="text">
              设为白名单
            </el-button>
          </template>
          <template v-else-if="row.is_lock_text == '正常'">
            <el-button @click="handleLock(row)" type="text">
              设为手动锁定
            </el-button>
            <el-button @click="handleWhileList(row)" type="text">
              设为白名单
            </el-button>
          </template>
          <template v-else-if="row.is_lock_text == '白名单'">
            <el-button @click="handleLock(row)" type="text">
              设为手动锁定
            </el-button>
            <el-button @click="handleNormal(row)" type="text">
              设为正常
            </el-button>
          </template>
          <!-- <el-button @click="handleLock(row)" type="text">设为手动锁定</el-button>
          <el-button @click="handleNormal(row)" type="text">设为正常</el-button>
          <el-button @click="handleWhileList(row)" type="text">设为白名单</el-button> -->
          <el-button @click="handleBanLog(row)" type="text">封号记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.pagesize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      style="text-align: center; margin-top: 10px"
    ></el-pagination>
    <HandleBatch
      ref="handleBatch"
      @handleBatchLock="handleBatchLock"
      @handleBatchNormal="handleBatchNormal"
      @handleBatchWhileList="handleBatchWhileList"
      @handleLock="handleTableLock"
      @handleWhileList="handleTableWhileList"
      @handleNormal="handleTableNormal"
    ></HandleBatch>
    <Edit ref="edit" @refresh="fetchData"></Edit>
    <BanLog ref="banLog"></BanLog>
  </div>
</template>
<script>
  import FormMaker from '@/components/formMaker'
  import HandleBatch from './components/handleBatch'
  import Edit from './components/edit'
  import BanLog from './components/banLog'
  import { postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'VIPManagement',
    components: { FormMaker, HandleBatch, Edit, BanLog },
    data() {
      return {
        loading: false,
        list: [],
        showMoveQuery: false,
        fullLoading: null,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        formLabel: [
          {
            type: 'input',
            label: '姓名（用户名）',
            place: '请输入姓名（用户名）',
            width: '180',
            prop: 'username',
          },
          // {
          //   type: "input",
          //   label: "昵称",
          //   place: "请输入昵称",
          //   width: "180",
          //   prop: "nickname",
          // },
          // {
          //   type: "input",
          //   label: "姓名",
          //   place: "请输入姓名",
          //   width: "180",
          //   prop: "realname",
          // },
          {
            type: 'input',
            label: '公司名称',
            place: '请输入公司名称',
            width: '180',
            prop: 'company',
          },
          {
            type: 'input',
            label: '地区',
            place: '请输入地区',
            width: '180',
            prop: 'region',
          },
          {
            type: 'select',
            label: '身份',
            place: '请选择身份',
            width: '160',
            selectListName: 'shenfenSelect',
            prop: 'identity',
          },
          {
            type: 'select',
            label: '行业',
            place: '请选择行业',
            width: '160',
            selectListName: 'hangyeSelect',
            selectLabel: 'cat_name',
            prop: 'industry',
          },
          // {
          //   type: "input",
          //   label: "电话",
          //   place: "请输入电话",
          //   width: "180",
          //   prop: "mobile",
          // },
          {
            type: 'select',
            label: '账号状态',
            place: '请选择账号状态',
            width: '130',
            selectListName: 'accountSelect',
            prop: 'is_lock',
          },
          // {
          //   type: "select",
          //   label: "有无锁定记录",
          //   place: "请选择有无锁定记录",
          //   width: "180",
          //   selectListName: "lockLogSelect",
          //   prop: "a",
          // },
        ],
        accountSelect: [
          // 0默认，1正常，2自动锁定，3白名单，4手动锁定
          { id: '1', name: '正常' },
          { id: '3', name: '白名单' },
          { id: '2', name: '自动锁定' },
          { id: '4', name: '手动锁定' },
        ],
        lockLogSelect: [
          { id: '', name: '有' },
          { id: '', name: '无' },
        ],
        qudaoSelect: [],
        form: {
          page: 1,
          pagesize: 10,
        },
        form2: {},
        columns: [
          {
            label: '用户ID',
            prop: 'id',
            width: '',
          },
          {
            label: '姓名（用户名）',
            prop: 'username',
            width: '',
          },
          {
            label: '头像',
            prop: 'headimg',
            width: '',
          },
          {
            label: '昵称',
            prop: 'nickname',
            width: '',
          },
          // {
          //   label: "姓名",
          //   prop: "realname",
          //   width: "",
          // },
          {
            label: '公司名称',
            prop: 'company',
            width: '',
          },
          {
            label: '地区',
            prop: 'region',
            width: '',
          },
          {
            label: '身份',
            prop: 'identity',
            width: '',
          },
          {
            label: '行业',
            prop: 'trade_txt',
            width: '',
          },
          {
            label: '渠道',
            prop: 'channel_txt',
            width: '',
          },
          {
            label: '注册电话',
            prop: 'authorized_phone',
            width: '',
          },
          {
            label: '注册时间',
            prop: 'created_txt',
            width: '',
          },
          {
            label: '最近浏览时间',
            prop: 'last_scan_time',
            width: '',
          },
          {
            label: '有无锁定记录',
            prop: 'lock_record',
            width: '',
          },
          {
            label: '最近锁定时间',
            prop: 'lock_time',
            width: '',
          },
          {
            label: '最近锁定原因',
            prop: 'lock_reason',
            width: '',
          },
          {
            label: '是否为活跃用户',
            prop: 'active_txt',
            width: '',
            fixed: 'right',
          },
          {
            label: '账号状态',
            prop: 'account_status_txt',
            width: '',
            fixed: 'right',
          },
        ],
        selectRow: [],
      }
    },
    created() {},
    mounted() {
      this.initSelect()
      this.fetchData()
    },
    computed: {
      // 计算高级查询输入了几个
      badeNum() {
        let num = 0
        // if (this.time.length) {
        //   num++
        // }
        Object.keys(this.form2).forEach((key) => {
          if (this.form2[key]) {
            num++
          }
        })
        return num
      },
    },
    watch: {
      // time(v) {
      //   if (v) {
      //     this.form2.reg_stime = v[0]
      //     this.form2.reg_etime = v[1]
      //   } else {
      //     this.form2.reg_stime = ""
      //     this.form2.reg_etime = ""
      //   }
      // },
    },
    methods: {
      handleExport() {
        // this.loading = true
        let params = {
          ...this.$refs.formMaker.form,
          ...this.form2,
        }
        this.form = params
        postAction('/user/index/lists-export', this.form).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
          // this.loading = false
        })
      },
      // 确定高级查询下拉的位置
      // initDialogPos() {
      //   this.$nextTick(() => {
      //     const dom = this.$refs["move-query"]
      //     const xy = dom.getBoundingClientRect()
      //     console.log("dom:----------------------------- ", dom.getBoundingClientRect())
      //     if(xy.x<=0){
      //       dom.style.left ='0px'
      //     }else if(xy.x)
      //   })
      // },
      async initSelect() {
        // let qudao = await postAction("/product/Channel_list")
        let shenfen = await postAction('/audit/identity/identity')
        let hangyeSelect = await postAction('/user/index/cate')

        // const qudao_ = qudao.result
        const shenfen_ = shenfen.data
        const hangyeSelect_ = hangyeSelect.data
        // qudao_.unshift({ channel_name: "全部", id: "-1" })
        shenfen_.unshift({ name: '全部', id: '-1' })
        hangyeSelect_.unshift({ cat_name: '全部', id: '-1' })
        // console.log("qudao_", qudao_)
        // this.qudaoSelect = qudao_
        this.$refs.formMaker.addSelect('accountSelect', this.accountSelect)
        this.$refs.formMaker.addSelect('lockLogSelect', this.lockLogSelect)
        this.$refs.formMaker.addSelect('shenfenSelect', shenfen_)
        this.$refs.formMaker.addSelect('hangyeSelect', hangyeSelect_)
      },
      async fetchData(data = null) {
        let form = {}
        if (data) {
          form = data
        } else {
          form = this.form
        }
        if (form.time && form.time.length == 2) {
          form.reg_stime = form.time[0]
          form.reg_etime = form.time[1]
        }
        this.loading = true
        try {
          let data = await postAction('/user/index/lists', form)
          this.list = data.data
          this.total = Number(data.totalCount)
          this.loading = false
        } catch (e) {
          this.loading = false
          this.list = []
        }
      },
      handleQuery(formData = {}) {
        this.form.page = 1
        this.form = { ...this.form, ...formData, ...this.form2 }
        this.clearTableSelect()
        this.fetchData()
      },
      // 批量操作
      getSelectRowIds() {
        const rows = this.selectRow
        const ids = []
        rows.forEach((i) => {
          ids.push(i.user_id)
        })
        return ids.join()
      },
      // 批量更改状态
      /**
       * is_lock帐号状态，0正常，2白名单，3手动锁定
       */
      showBatchDialog(type) {
        const rows = this.selectRow
        if (rows.length == 0) {
          this.$message.error('请先勾选需要操作的选项')
          return false
        } else {
          this.$refs.handleBatch.type = type
          this.$refs.handleBatch.showDialog = true
        }
      },
      async handleBatch(ids, is_lock, reason = null) {
        this.fullLoading = this.$loading({ fullscreen: true })
        try {
          let data = await postAction('/Users/Lock_multichk', {
            ids,
            is_lock,
            reason,
          })
          console.log('data', data)
          this.$message.success(data.msg)
          this.fetchData()
          this.fullLoading.close()
        } catch (err) {
          this.fullLoading.close()
        }
      },
      async handleNoBatch(user_id, is_lock, reason = '') {
        console.log('user_id', user_id)
        this.fullLoading = this.$loading({ fullscreen: true })
        try {
          let data = await postAction('/Users/Lock_submit', {
            user_id,
            is_lock,
            reason,
          })
          console.log('data', data)
          this.$message.success(data.msg)
          this.fetchData()
          this.fullLoading.close()
        } catch (err) {
          this.fullLoading.close()
        }
      },
      handleBatchLock(v) {
        const ids = this.getSelectRowIds()
        console.log('handleBatchLock', v)
        if (ids) {
          this.handleBatch(ids, 3, v.val)
        }
      },
      handleBatchNormal() {
        const ids = this.getSelectRowIds()
        if (ids) {
          this.handleBatch(ids, 0)
        }
      },
      handleBatchWhileList() {
        const ids = this.getSelectRowIds()
        if (ids) {
          this.handleBatch(ids, 2)
        }
      },
      // 表格操作栏
      handleEdit(row) {
        this.$refs.edit.form = row
        this.$refs.edit.form.reason = row.lock_reason
        this.$refs.edit.showDialog = true
      },
      handleLock(row) {
        // this.showBatchDialog(1, 1)
        this.$refs.handleBatch.type = 1
        this.$refs.handleBatch.row = row
        this.$refs.handleBatch.showDialog = true
      },
      handleNormal(row) {
        // this.showBatchDialog(3, 1)
        this.$refs.handleBatch.type = 3
        this.$refs.handleBatch.row = row
        this.$refs.handleBatch.showDialog = true
      },
      handleWhileList(row) {
        // this.showBatchDialog(2, 1)
        this.$refs.handleBatch.type = 2
        this.$refs.handleBatch.row = row
        this.$refs.handleBatch.showDialog = true
      },
      handleBanLog(row) {
        this.$refs.banLog.user_id = row.user_id
        this.$refs.banLog.showDialog = true
      },

      handleTableLock(v) {
        console.log('handleTableLock', v)
        const { user_id, is_lock, reason } = v
        this.handleNoBatch(user_id, is_lock, reason)
      },
      handleTableWhileList(v) {
        console.log('handleTableWhileList', v)
        const { user_id, is_lock, reason } = v
        this.handleNoBatch(user_id, is_lock)
      },
      handleTableNormal(v) {
        console.log('handleTableNormal', v)
        const { user_id, is_lock, reason } = v
        this.handleNoBatch(user_id, is_lock)
      },
      // 表格勾选
      selectChange(val) {
        this.selectRow = val
      },
      // 清空表格勾选
      clearTableSelect() {
        this.$refs.table.clearSelection()
        this.selectRow = []
      },
      handleCurrentChange(val) {
        this.form.page = val
        const data = { ...this.form, ...this.form2 }
        this.fetchData(data)
      },
      handleSizeChange(val) {
        this.form.page = 1
        const data = { ...this.form, ...this.form2 }
        this.form.pagesize = val
        this.fetchData(data)
      },
      handleReset() {
        // this.form2 = {}
        Object.keys(this.form2).forEach((e) => {
          this.form2[e] = ''
        })
        this.form2.reg_stime = ''
        this.form2.reg_etime = ''
      },
      async fetchData2(data2) {
        this.loading = true
        try {
          let data = await postAction('/user/index/lists', data2)
          this.list = data.data
          this.total = Number(data.totalCount)
          this.loading = false
        } catch (e) {
          this.loading = false
          this.list = []
        }
      },
      handleMoreQuery() {
        this.form.page = 1
        const data = {
          ...this.form,
          ...this.$refs.formMaker.form,
          ...this.form2,
        }
        this.form = data
        if (data.time && data.time.length == 2) {
          data.reg_stime = data.time[0]
          data.reg_etime = data.time[1]
        }
        console.log('data', data)
        this.clearTableSelect()
        this.fetchData2(data)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dialog {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #ccc;
    opacity: 0;
    z-index: 4;
  }
  .move-query-btn {
    position: relative;
    .move-query {
      z-index: 1000;
      background-color: #fff;
      width: 380px;
      position: absolute;
      top: 40px;
      left: 0;
      border-radius: 4px;
      box-shadow: 0 0 5px 2px #ccc;
      ::deep {
        input {
          margin: 0 !important;
        }
      }
    }
  }
</style>
