<template>
  <div>
    <el-dialog
      :before-close="handleClose"
      title="添加管理"
      :visible.sync="visible"
      width="30%"
    >
      <el-form label-width="80px" :model="form">
        <el-form-item label="员工姓名">
          <el-autocomplete
            v-model="name"
            :fetch-suggestions="querySearch"
            placeholder="请搜索员工姓名"
            :trigger-on-focus="false"
            @select="handleSelect"
          />
        </el-form-item>
        <el-form-item label="权限">
          <div style="display: flex; align-items: center">
            <el-checkbox-group v-model="form.types">
              <el-checkbox
                v-for="item in customTypes"
                :key="item.id"
                :label="item.id"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
            <el-button
              class="custom-add-btn"
              style="margin-left: 18px"
              type="primary"
              @click="handleAddType"
            >
              添加
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </span>
    </el-dialog>
    <AddType ref="addTypeDialog" @submit="handleTypeSubmit" />
  </div>
</template>

<script>
  import AddType from './AddType.vue'
  import { getAction } from '@/api/request'

  export default {
    name: 'AddMember',
    components: {
      AddType,
    },
    data() {
      return {
        visible: false,
        form: {
          user_id: '',
          types: [],
        },
        name: '',
        customTypes: [],
        searchTimeout: null,
        searchResults: [],
      }
    },
    mounted() {
      this.gettypedata()
    },
    methods: {
      show() {
        this.visible = true
      },
      gettypedata() {
        getAction('user/logistics/type').then((res) => {
          if (res.request_code == 200) {
            this.customTypes = res.data
          }
        })
      },
      handleClose() {
        this.visible = false
        this.form.user_id = ''
        this.name = ''
        this.form.types = []
      },
      handleSubmit() {
        if (!this.form.user_id) {
          this.$message.warning('请输入员工姓名并选择')
          return
        }
        if (this.form.types.length === 0) {
          this.$message.warning('请选择权限')
          return
        }
        console.log('提交数据:', this.form)
        this.$emit('submit', this.form)
        this.handleClose()
      },
      handleAddType() {
        this.$refs.addTypeDialog.show()
      },
      handleTypeSubmit(typeName) {
        if (this.customTypes.some((type) => type.name === typeName)) {
          this.$message.warning('该类型已存在')
          return
        }
        getAction('/user/logistics/save-type', { name: typeName }).then(
          (res) => {
            if (res.request_code === 200) {
              this.$message.success('添加成功')
              this.gettypedata()
            } else {
              this.$message.error('添加失败')
            }
          }
        )
      },
      querySearch(queryString, cb) {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout)
        }
        this.searchTimeout = setTimeout(() => {
          if (queryString) {
            getAction('/auth/user/index', { truename: queryString }).then(
              (res) => {
                if (res.request_code === 200) {
                  const results = res.data.map((item) => ({
                    value: item.truename,
                    id: item.id,
                    ...item,
                  }))
                  cb(results)
                } else {
                  cb([])
                }
              }
            )
          } else {
            cb([])
          }
        }, 300)
      },
      handleSelect(item) {
        this.name = item.value
        this.form.user_id = item.id
        console.log('Selected item:', this.form)
      },
    },
  }
</script>

<style scoped>
  .custom-add-btn {
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    transition: all 0.3s;
    border: 1px solid #409eff;
  }

  .custom-add-btn:hover {
    border-color: #66b1ff;
    color: #fff;
  }
</style>
