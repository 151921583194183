var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "70%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-button-group",
            { staticClass: "tab" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: _vm.activeTab === "1" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.tabClick("1")
                    },
                  },
                },
                [_vm._v(" 意向线索 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.activeTab === "2" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.tabClick("2")
                    },
                  },
                },
                [_vm._v(" 交互 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.activeTab === "3" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.tabClick("3")
                    },
                  },
                },
                [_vm._v(" 主动筛选 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.activeTab === "4" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.tabClick("4")
                    },
                  },
                },
                [_vm._v(" 广告 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.activeTab === "5" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.tabClick("5")
                    },
                  },
                },
                [_vm._v(" 产品数和其他 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.activeTab === "6" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.tabClick("6")
                    },
                  },
                },
                [_vm._v(" 前台显示内容 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "radioBox" },
            [
              _vm.activeTab == "1"
                ? _c(
                    "el-form",
                    {
                      ref: "intention",
                      staticClass: "intention",
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.intention,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "单次浏览时长：", prop: "duration" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.intention.duration,
                              callback: function ($$v) {
                                _vm.$set(_vm.intention, "duration", $$v)
                              },
                              expression: "intention.duration",
                            },
                          }),
                          _c("e", { staticClass: "tips" }, [
                            _vm._v(
                              " 浏览时长指的是：单次浏览店铺页和产品页多少秒进入意向线索 "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "累计浏览次数：",
                            prop: "scan_count",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.intention.scan_count,
                              callback: function ($$v) {
                                _vm.$set(_vm.intention, "scan_count", $$v)
                              },
                              expression: "intention.scan_count",
                            },
                          }),
                          _c("e", { staticClass: "tips" }, [
                            _vm._v(
                              " 累计浏览次数指的是：自上线起，累计浏览多少次进入意向线索 "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "套餐条数：", prop: "meal_count" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.intention.meal_count,
                              callback: function ($$v) {
                                _vm.$set(_vm.intention, "meal_count", $$v)
                              },
                              expression: "intention.meal_count",
                            },
                          }),
                          _c("e", { staticClass: "tips" }, [
                            _vm._v(
                              " 套餐条数指的是：范围是浏览产品页+浏览店铺页+留言+样品申请+电话咨询，超出条数后不显示关键信息。 "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "intentionBox1",
                          attrs: {
                            label: "套餐默认时长内，完成后：",
                            prop: "default_is_create",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.intention.default_is_create,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.intention,
                                    "default_is_create",
                                    $$v
                                  )
                                },
                                expression: "intention.default_is_create",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("继续提供"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("不再提供"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "套餐默认的时间内，意向线索条数完成了套餐对应的条数，是否还能接收到意向线索",
                                placement: "top",
                              },
                            },
                            [_c("e", { staticClass: "el-icon-question" })],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.intention.default_is_create == "1"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "intentionBox2",
                              attrs: {
                                label:
                                  "套餐默认时长内，完成后，再提供的显示完整信息：",
                                prop: "default_is_create_complete",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value:
                                      _vm.intention.default_is_create_complete,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.intention,
                                        "default_is_create_complete",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "intention.default_is_create_complete",
                                  },
                                },
                                [
                                  _c("el-checkbox", { attrs: { label: "1" } }, [
                                    _vm._v("留言"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "2" } }, [
                                    _vm._v("样品申请"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "3" } }, [
                                    _vm._v("电话咨询"),
                                  ]),
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: "4" } },
                                    [
                                      _vm._v(" 浏览产品和店铺 "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "收到的意向线索，哪些类型的需要显示完整的信息（姓名、公司名称和电话）",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("e", {
                                            staticClass: "el-icon-question",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: "6" } },
                                    [
                                      _vm._v(" 指定店铺需求 "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "来自企微的指定店铺需求（前端显示是也称为“定向推荐”）",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("e", {
                                            staticClass: "el-icon-question",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("el-checkbox", { attrs: { label: "7" } }, [
                                    _vm._v("请厂家联系我"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "intentionBox1",
                          attrs: {
                            label: "套餐延期下，完成后：",
                            prop: "delay_is_create",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.intention.delay_is_create,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.intention,
                                    "delay_is_create",
                                    $$v
                                  )
                                },
                                expression: "intention.delay_is_create",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("继续提供"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("不再提供"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "超出套餐默认时间后，套餐进入延期状态，是否还能接收到意向线索",
                                placement: "top",
                              },
                            },
                            [_c("e", { staticClass: "el-icon-question" })],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.intention.delay_is_create == "1"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "intentionBox3",
                              attrs: {
                                label:
                                  "套餐延期下，完成后，再提供的显示完整信息：",
                                prop: "delay_is_create_complete",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value:
                                      _vm.intention.delay_is_create_complete,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.intention,
                                        "delay_is_create_complete",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "intention.delay_is_create_complete",
                                  },
                                },
                                [
                                  _c("el-checkbox", { attrs: { label: "1" } }, [
                                    _vm._v("留言"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "2" } }, [
                                    _vm._v("样品申请"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "3" } }, [
                                    _vm._v("电话咨询"),
                                  ]),
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: "4" } },
                                    [
                                      _vm._v(" 浏览产品和店铺 "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "收到的意向线索，哪些类型的需要显示完整的信息（姓名、公司名称和电话）",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("e", {
                                            staticClass: "el-icon-question",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("el-checkbox", { attrs: { label: "6" } }, [
                                    _vm._v("指定店铺需求"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "7" } }, [
                                    _vm._v("请厂家联系我"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeTab == "2"
                ? _c(
                    "el-form",
                    {
                      ref: "interactive",
                      attrs: {
                        rules: _vm.jhrules,
                        model: _vm.interactive,
                        "label-width": "200px",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("p", [_vm._v("设置留言相关")]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "查看已接收的加密的留言：",
                                prop: "is_message_less",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.interactive.is_message_less,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.interactive,
                                        "is_message_less",
                                        $$v
                                      )
                                    },
                                    expression: "interactive.is_message_less",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("扣除意向线索次数"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("不扣除任何次数"),
                                  ]),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      staticStyle: { "margin-left": "-2y0px" },
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "旧留言指的是购买最新套餐之前的留言",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("e", {
                                        staticClass: "el-icon-question",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.activeTab == "3"
                ? _c(
                    "el-form",
                    {
                      ref: "filter",
                      attrs: {
                        rules: _vm.sxrules,
                        model: _vm.filter,
                        "label-width": "200px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开启主动筛选功能：",
                            prop: "is_select",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#1890ff",
                              "inactive-color": "#dcdfe6",
                              "active-value": "1",
                              "inactive-value": "2",
                            },
                            model: {
                              value: _vm.filter.is_select,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "is_select", $$v)
                              },
                              expression: "filter.is_select",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.filter.is_select == 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "主动筛选次数计算周期：" } },
                                [
                                  _c(
                                    "e",
                                    {
                                      staticClass: "required",
                                      staticStyle: { left: "-173px" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.filter.select_week,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "select_week",
                                            $$v
                                          )
                                        },
                                        expression: "filter.select_week",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1" } },
                                        [_vm._v("一次性")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "2" } },
                                        [_vm._v("每天")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "3" } },
                                        [_vm._v("每周")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "4" } },
                                        [_vm._v("每月")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "主动筛选次数：" } },
                                [
                                  _c(
                                    "e",
                                    {
                                      staticClass: "required",
                                      staticStyle: { left: "-117px" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "150px" },
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.filter.select_count,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "select_count",
                                          $$v
                                        )
                                      },
                                      expression: "filter.select_count",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "套餐默认时长内，达成后：" },
                                },
                                [
                                  _c(
                                    "e",
                                    {
                                      staticClass: "required",
                                      staticStyle: { left: "-187px" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.filter.default_is_select,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "default_is_select",
                                            $$v
                                          )
                                        },
                                        expression: "filter.default_is_select",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1" } },
                                        [_vm._v("继续提供")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "2" } },
                                        [_vm._v("不再提供")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "套餐延期后，达成后：" } },
                                [
                                  _c(
                                    "e",
                                    {
                                      staticClass: "required",
                                      staticStyle: { left: "-159px" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.filter.delay_is_select,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "delay_is_select",
                                            $$v
                                          )
                                        },
                                        expression: "filter.delay_is_select",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1" } },
                                        [_vm._v("继续提供")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "2" } },
                                        [_vm._v("不再提供")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeTab == "4"
                ? _c(
                    "el-form",
                    {
                      ref: "ads",
                      attrs: {
                        rules: _vm.adsrules,
                        model: _vm.ads,
                        "label-width": "180px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "广告总曝光量计算规则：",
                            prop: "ad_rule",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ads.ad_rule,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ads, "ad_rule", $$v)
                                },
                                expression: "ads.ad_rule",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("各广告位有对应的曝光量"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("不区分广告位，只计算总量"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "广告达到曝光量：",
                            prop: "ad_exposure_is_remove",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ads.ad_exposure_is_remove,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ads,
                                    "ad_exposure_is_remove",
                                    $$v
                                  )
                                },
                                expression: "ads.ad_exposure_is_remove",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("下架"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("保持上架"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _vm.ads.ad_rule == "2"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "所有广告总曝光量：",
                                prop: "exposure_max",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.ads.exposure_max,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ads, "exposure_max", $$v)
                                  },
                                  expression: "ads.exposure_max",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.ads.ad_rule == "2" ? _c("el-divider") : _vm._e(),
                      _c(
                        "div",
                        {
                          staticStyle: { position: "relative", width: "420px" },
                        },
                        [
                          _vm._l(
                            _vm.ads.ad_position_content,
                            function (i, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: {
                                    "margin-bottom": "30px",
                                    position: "relative",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "ads-position",
                                      attrs: { label: "广告位置：" },
                                    },
                                    [
                                      _c("e", { staticClass: "required" }, [
                                        _vm._v("*"),
                                      ]),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: i.advert_no,
                                            callback: function ($$v) {
                                              _vm.$set(i, "advert_no", $$v)
                                            },
                                            expression: "i.advert_no",
                                          },
                                        },
                                        _vm._l(
                                          _vm.positionlist,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.position_name,
                                                value: item.advert_no,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.ads.ad_rule == "1"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticClass: "ads-nums",
                                          attrs: { label: "广告最大曝光量：" },
                                        },
                                        [
                                          _c("e", { staticClass: "required" }, [
                                            _vm._v("*"),
                                          ]),
                                          _c("el-input", {
                                            staticStyle: { width: "200px" },
                                            attrs: { placeholder: "请输入" },
                                            model: {
                                              value: i.advert_exposure,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  i,
                                                  "advert_exposure",
                                                  $$v
                                                )
                                              },
                                              expression: "i.advert_exposure",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.ads.ad_position_content.length > 1
                                    ? _c("e", {
                                        staticClass: "el-icon-remove-outline",
                                        on: {
                                          click: function ($event) {
                                            return _vm.adsremove(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          _c("e", {
                            staticClass: "el-icon-circle-plus-outline",
                            style: {
                              right:
                                _vm.ads.ad_position_content.length > 1
                                  ? "-35px"
                                  : "",
                            },
                            on: { click: _vm.adsadd },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeTab == "5"
                ? _c(
                    "el-form",
                    {
                      ref: "product",
                      attrs: {
                        rules: _vm.cprules,
                        model: _vm.product,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("p", [_vm._v("设置最大产品数量")]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大产品数量：",
                                prop: "product_num",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "150px" },
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.product.product_num,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.product, "product_num", $$v)
                                  },
                                  expression: "product.product_num",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _c(
                        "div",
                        {
                          staticStyle: { width: "420px", position: "relative" },
                        },
                        [
                          _c("p", [_vm._v("设置线下服务内容")]),
                          _vm._l(
                            _vm.product.offline_content,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: {
                                    "margin-bottom": "35px",
                                    position: "relative",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "内容名称：" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: item.type,
                                            callback: function ($$v) {
                                              _vm.$set(item, "type", $$v)
                                            },
                                            expression: "item.type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.mealoffine,
                                          function (i, index1) {
                                            return _c("el-option", {
                                              key: index1,
                                              attrs: {
                                                label: i.name,
                                                value: i.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "内容数量：" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "215px" },
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: item.count,
                                          callback: function ($$v) {
                                            _vm.$set(item, "count", $$v)
                                          },
                                          expression: "item.count",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "productlabel",
                                      attrs: {
                                        label: "套餐默认时长内，达成后：",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.default_is_create,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "default_is_create",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.default_is_create",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v("继续提供")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "2" } },
                                            [_vm._v("不再提供")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "productlabel productlabel1",
                                      attrs: { label: "套餐延期后，达成后：" },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.delay_is_create,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "delay_is_create",
                                                $$v
                                              )
                                            },
                                            expression: "item.delay_is_create",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v("继续提供")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "2" } },
                                            [_vm._v("不再提供")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.product.offline_content.length > 1
                                    ? _c("e", {
                                        staticClass: "el-icon-remove-outline",
                                        staticStyle: { bottom: "5px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.productremove(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          _c("e", {
                            staticClass: "el-icon-circle-plus-outline",
                            staticStyle: { bottom: "5px" },
                            style: {
                              right:
                                _vm.product.offline_content.length > 1
                                  ? "-35px"
                                  : "",
                            },
                            on: { click: _vm.productadd },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeTab == "6"
                ? _c(
                    "el-form",
                    {
                      ref: "content",
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.content,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "展示内容" } },
                        [
                          _c("wang-editor", {
                            ref: "wangcon",
                            attrs: { height: 300, "upload-img-size": 300 },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务协议" } },
                        [
                          _c("wang-editor", {
                            ref: "wangxie",
                            attrs: { height: 300, "upload-img-size": 300 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }