<template>
  <div class="meeting">
    <el-form ref="form" :model="formlist" label-width="80px" inline>
      <el-form-item>
        <el-input
          v-model="formlist.act_name"
          clearable
          placeholder="名称"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search()">查 询</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" style="width: 100%" ref="ElTable">
      <el-table-column
        width="90px"
        prop="id"
        label="活动ID"
        align="center"
      />
      <el-table-column
        prop="act_name"
        label="活动名称"
        align="center"
      />
      <el-table-column
        width="160px"
        prop="start_time"
        label="开始时间"
        align="center"
      />
      <el-table-column
        width="160px"
        prop="end_time"
        label="结束时间"
        align="center"
      />
      <el-table-column
        width="120px"
        prop="yun_state"
        label="华糖云是否发布"
        align="center"
      />
      <el-table-column width="120px" prop="" label="首页浮动图标" align="center">
        <template slot-scope="scope">
          <!-- {{ scope.row.is_top == 1 ? '是' : '否' }} -->
          <img style="height:50px;" :src="scope.row.float_icon" @click="bigimgShow=true;bigimg=scope.row.float_icon">
        </template>
      </el-table-column>
      <el-table-column
        width="100px"
        prop="float_click"
        label="浮标唤起量"
        align="center"
      />
      <el-table-column
        width="100px"
        prop="goods_click"
        label="产品唤起量"
        align="center"
      />
      <el-table-column width="100px" prop="" label="二维码" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.qrcode" style="width:40px;" @click="bigimgShow=true;bigimg=scope.row.qrcode">
        </template>
      </el-table-column>
      <el-table-column width="140px" prop="" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="handlerEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="handlerguanlian(scope.row)">关联产品</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
          background
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          style="text-align: center; margin-top: 10px"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />

    <div class="bigimg" v-show="bigimgShow" @click="bigimgShow=false">
      <img :src="bigimg">
    </div>
    <!-- 编辑 -->
    <div>
      <el-dialog
        title="编辑"
        :visible.sync="editVisible"
        width="40%"
        :before-close="handleClose">
        <div class="form">
          <el-form ref="editFolatForm" :model="form" label-width="80px">
            <el-form-item label="浮动图标">
              <upload-img :disab="disabled" ref="float" :limit="1" info-text="图片尺寸：128px*515px"  @getImgs="getfloatimg($event, 'float')"/>
            </el-form-item>
<!--            <el-form-item label="跳转类型">
              <el-select v-model="form.jump_type" placeholder="请选择">
                <el-option
                  v-for="item in jump_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="跳转方式"  prop="jump_type">
              <!--          @change="handlerjuptype"-->
              <el-select v-model="form.jump_type" clearable style="width: 160px;" placeholder="跳转方式"  @change="handlerjuptype">
                <el-option v-for="list in toSelect"
                           :key="list.jump_id"
                           :value="list.jump_id"
                           :label="list.jump_page"/>
              </el-select>
            </el-form-item>
            <el-form-item :label="jump_name" v-if="form.jump_type==3||form.jump_type==22||form.jump_type==12" prop="jump_val">
              <el-input  v-model="form.jump_val"  :placeholder="jump_name" style="width: 160px;"/>
            </el-form-item>
            <el-form-item
              v-else-if="form.jump_type == 10"
              label="选择特色展馆"
              prop="ref_name"
            >
              <el-autocomplete
                v-model="form.ref_name"
                :fetch-suggestions="handlerhuose"
                placeholder="请输入特色展馆名称"
                value-key="house_name"
                @select="handleSelecthuose"
              />
            </el-form-item>
            <el-form-item label="选择店铺" v-else-if="form.jump_type==1" prop="jump_val">
              <el-autocomplete
                style="width: 160px;"
                v-model="form.ref_name"
                :fetch-suggestions="handlermell"
                value-key="mall_name"
                clearable
                placeholder="请输入店铺名称"
                @select="handleSelectmall"
              ></el-autocomplete>

            </el-form-item>
            <el-form-item
              v-else-if="form.jump_type == 20"
              label="选择选品日"
              prop="jump_val"
            >
              <el-autocomplete
                v-model="form.ref_name"
                :fetch-suggestions="handlerarea"
                placeholder="请输入选品日名称"
                style="width: 160px"
                clearable
                value-key="title"
                @select="handleSelectarea"
              />
            </el-form-item>
            <el-form-item
              v-else-if="form.jump_type == 21"
              label="线上品类节"
              prop="jump_val"
            >
              <el-autocomplete
                v-model="form.ref_name"
                :fetch-suggestions="handleract"
                placeholder="请输入线上品类节"
                style="width: 160px"
                clearable
                value-key="name"
                @select="handleSelectact"
              />
            </el-form-item>
            <el-form-item
              v-else-if="form.jump_type == 18||form.jump_type == 7"
              label="品类"
              prop="jump_val"
            >
              <!-- @change="handlerjuptype" -->
              <el-select
                v-model="form.jump_val"
                clearable
                placeholder="请选择品类"
                style="width: 160px"
              >
                <el-option
                  v-for="list in pllist"
                  :key="list.id"
                  :label="list.name"
                  :value="list.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="选择商品" v-else-if="form.jump_type==2" prop="jump_val">
              <el-autocomplete
                style="width: 160px;"
                v-model="form.ref_name"
                :fetch-suggestions="handlergoods"
                value-key="goods_name"
                clearable
                placeholder="请输入商品名称"
                @select="handleSelectgoods"
              ></el-autocomplete>
            </el-form-item>
          </el-form>
          <p slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleEditok">保 存</el-button>
            <el-button @click="handleClose()">取 消</el-button>
          </p>
        </div>

      </el-dialog>
    </div>
    <div>
      <el-dialog
        title="编辑"
        :visible.sync="relatedVisible"
        width="50%"
        :before-close="relateClose">
        <div class="related">
          <div class="header">
            <el-button type="primary" @click="relatedEdit(0)">新增</el-button>
            <!-- <el-input style="width:200px;margin:0 20px;"
              v-model="relatedForm.goods_name"
              clearable
              placeholder="请输入产品名称关键字"
            /> -->
            <el-autocomplete style="width:200px;margin:0 20px;"
            v-model="goods_name"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入产品名称关键字"
            @select="relatedSelect"
          ></el-autocomplete>
            <el-button type="primary" @click="getrelated()">查 询</el-button>
          </div>
          <el-table border :data="relatedList" style="width: 100%" ref="relatedTable">
            <el-table-column
              prop="goods_name"
              label="产品名称"
              align="center"
            />
            <el-table-column
              prop="state_text"
              label="状态"
              align="center"
            />
            <el-table-column width="140px" prop="" label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="relatedEdit(scope.row)">编辑</el-button>
                <el-button type="text" @click="relatedjin(scope.row)">
                  {{ scope.row.state==1?'禁用':'启用'}}
                </el-button>
                <el-button type="text" @click="relatedDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            :current-page="relatedForm.page"
            :layout="relatedlayout"
            :page-size="relatedForm.limit"
            style="text-align: center; margin-top: 10px"
            :total="relatedTotal"
            @current-change="relatedCurrentChange"
            @size-change="relatedSizeChange"
          />

        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        :title="relatedEditTitle"
        :visible.sync="relatedEditShow"
        width="30%"
        :before-close="relatedEditClose">
        <div class="relatedEdit">
          <el-autocomplete
            v-model="relatedVal"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入内容"
            @select="relatedSelect"
          ></el-autocomplete>
          <p slot="footer" class="dialog-footer">
            <el-button type="primary" @click="relatedEditok()">保 存</el-button>
            <el-button @click="relatedEditClose()">关 闭</el-button>
          </p>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import UploadImg from "@/components/uploadImg";
import { getAction, postAction } from "@/api/request";
export default {
  name: 'Meeting',
  components: { UploadImg },
  data() {
    return {
      formlist:{
        act_name:'',
        page:1,
        limit:10,
      },
      tabledata:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      bigimg:'',
      bigimgShow:false,
      editVisible:false,
      disabled:false,
      form:{
        id:'',
        float_icon:'',
        jump_type:'',
        jump_val:'',
        ref_name:'',
      },
      jump_type:[
        {
          label:'云交会首页',
          value:'3'
        },
      ],

      relatedVisible:false,
      jump_name:'',
      toSelect:[],
      relatedList:[],
      goods_name:'',
      relatedForm:{
        meeting_id:'',
        goods_id:'',
        page:1,
        limit:10,
      },
      relatedTotal:0,
      relatedlayout:'total, sizes, prev, pager, next, jumper',
      relatedEditShow:false,
      relatedEditTitle:'新增',
      restaurants: [],
      relatedVal: '',
      timeout:  null,
      relatedEditForm:{
        goods_id:'',
        id:'',
      },
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.handlerInquire();
    this.handlerSelect()
  },
  methods: {
    handlerInquire(){
      let that = this;
      postAction("/api/hty/yun/act-lists",that.formlist).then(res=>{
          if(res.code==0){
            that.tabledata = res.data
            that.total = res.totalCount
          }
      })
    },
    async handlerSelect(){

      let typeSelect=await getAction("/audit/advert/jump-type",)
      this.toSelect=typeSelect.data

    },
    search(){
      this.formlist.page = 1;
      this.handlerInquire();
    },
    handleCurrentChange(val) {
      this.formlist.page = val
      this.handlerInquire()
    },
    handleSizeChange(val) {
      this.formlist.limit = val
      this.handlerInquire()
    },
    handlerEdit(row){
      this.form.id = row.id;
      this.form.float_icon = row.float_icon;
      if(row.jump_type!=0){
        this.form.jump_type = row.jump_type;
      }
      this.form.jump_val = row.jump_val;
      this.form.ref_name=row.ref_name;
      this.editVisible = true;
      setTimeout(() => {
        this.setImg(row.float_icon,"float")
      }, 100);
    console.log(this.form)
    },
    handlerguanlian(row){
      let that = this;
      that.relatedForm.meeting_id = row.id;
      that.relatedVisible = true;
      that.getrelated();
    },
    setImg(v, prop) {
      console.log(v, prop)
      if (v) {
        this.$refs[prop].img = [v]
      }else{
        this.$refs[prop].img = []
      }
    },
    getfloatimg(v, prop){
      this.form.float_icon = v[0]
    },
    handleClose(){
      this.editVisible = false;
      this.form={
        id:'',
        float_icon:'',
        jump_type:'',
        jump_val:'',
      }

      this.setImg('','float')
    },
    handleEditok(){
      console.log(this.form)
      // if(that.form.jump_type=='3'){
      //   that.form.jump_val = '';
      // }
      postAction("/api/hty/yun/act-edit",this.form).then(res=>{
          if(res.code==0){
            this.editVisible = false;
            this.form={
              id:'',
              float_icon:'',
              jump_type:'',
              jump_val:'',
            }

            this.setImg('','float')
            this.handlerInquire();
          }
      })

    },
    getrelated(){
      let that = this;
      postAction("/api/hty/yun/goods-lists",that.relatedForm).then(res=>{
          if(res.code==0){
            that.relatedList = res.data
            that.relatedTotal = res.totalCount
          }
      })

    },
    relatedCurrentChange(val) {
      this.relatedForm.page = val
      // this.handlerInquire()
    },
    relatedSizeChange(val) {
      this.relatedForm.limit = val
      // this.handlerInquire()
    },
    relatedEdit(row){
      let that = this;
      that.relatedEditShow = true;
      if(row!=0){
        // 编辑
        that.relatedVal = row.goods_name;
        that.relatedEditForm.id = row.id;
        that.relatedEditForm.goods_id = row.goods_id;
        that.relatedEditTitle = '编辑';
      }else{
        that.relatedEditTitle = '新增';
      }
    },
    relatedjin(row){
      let that = this;
      if(row.state ==1){
        var state = 0
      }else{
        var state = 1
      }
      var data = {
        id:row.id,
        state:state,
      }
      postAction("/api/hty/yun/goods-edit",data).then(res=>{
          if(res.code==0){
            that.getrelated();
          }
      })

    },
    relatedDel(row){
      let that = this;
      this.$confirm('确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          postAction("/api/hty/yun/goods-delete",{"id":row.id}).then(res=>{
              if(res.code==0){
                that.$message.success('删除成功')
                that.getrelated();
              }
          })

        })



    },
    relateClose(){
      this.relatedVisible=false;
    },
    getproduct(queryString,cb){
      let that = this;
      if(queryString==''){
        var val = ''
        if(!that.relatedEditShow){
          that.relatedForm.goods_id='';
        }
      }else{
        var val = queryString
      }
      postAction("/mall/product/goods",{"goods_name":val}).then(res=>{
          if(res.code==0){
            that.restaurants = res.data.map(item=>{
              return {
                value:item.goods_name,
                id:item.id
              }
            })
           if(cb!=undefined){
            var restaurants = that.restaurants;
            var results = queryString ? restaurants.filter(that.createStateFilter(queryString)) : restaurants;

            clearTimeout(that.timeout);
            that.timeout = setTimeout(() => {
              cb(results);
            }, 3000 * Math.random());
           }
          }
      })

    },
    querySearchAsync(queryString, cb) {
      this.getproduct(queryString,cb)
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    relatedSelect(item) {
      console.log(item);
      if(this.relatedEditShow){
        this.relatedEditForm.goods_id = item.id;
      }else{
        this.relatedForm.goods_id = item.id;
      }

    },
    relatedEditClose(){
      this.relatedEditShow = false;
      this.relatedVal = '';
      this.relatedEditForm={
                              goods_id:'',
                              id:'',
                            };
    },
    relatedEditok(){
      let that = this;
      if(that.relatedEditTitle=='新增'){
        var data = {
          meeting_id:that.relatedForm.meeting_id,
          goods_id:that.relatedEditForm.goods_id,
        }
      }else{
        var data = {
          id:that.relatedEditForm.id,
          goods_id:that.relatedEditForm.goods_id,
        }
      }
      postAction("/api/hty/yun/goods-edit",data).then(res=>{
          if(res.code==0){
            that.relatedEditShow = false;
            that.relatedVal = '';
            that.relatedEditForm={
                                    goods_id:'',
                                    id:'',
                                  };
            that.getrelated();
          }
      })
    },



    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.jump_val=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlerhuose(queryString, cb) {
      if (queryString == '') {
        cb([])
        this.form.jump_val = ''
      } else {
        getAction('/api/index/house', { house_name: queryString }).then(
          (res) => {
            cb(res.data)
          }
        )
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.jump_val=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }

    },
    handlerarea(queryString, cb){
      if (queryString == '') {
        cb([])
        this.form.jump_val = ''
      } else {
        getAction('/live/index/search-area', { title: queryString }).then(
          (res) => {
            cb(res.data)
          }
        )
      }
    },
    handleract(queryString, cb){
      if (queryString == '') {
        cb([])
        this.form.jump_val = ''
      } else {
        getAction('/api/system/category-act/select', { name: queryString }).then(
          (res) => {
            cb(res.data)
          }
        )
      }
    },
    handleSelecthuose(list) {
      console.log(list)
      this.form.jump_val = list.id
    },
    handleSelectmall(list){
      console.log(list)
      this.form.jump_val=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.form.jump_val=list.id
    },
    handleSelectarea(list) {
      console.log(list)
      this.form.jump_val = list.id
    },
    handleSelectact(list){
      console.log(list)
      this.form.jump_val = list.id
    },



    handlerjuptype(e){
      this.form.jump_val=''
      let data = this.toSelect.filter(
        (list) => list.jump_id == e
      )
      if(data.length>0){
        this.jump_name = this.toSelect.filter(
          (list) => list.jump_id == e
        )[0].jump_page
      }else{
        this.jump_name = ''
      }

    }

  },


}
</script>

<style lang="scss" scoped>
  .meeting{
    padding:20px;
  }
  .bigimg{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .bigimg img{
      width:15%;
      max-height: 80%;
      object-fit: contain;
  }
  .dialog-footer{
    text-align:right;
    margin-top:40px;
  }
  .related{
    .header{
      margin-bottom:20px;
    }
    // .relatedTable{
    //   margin-bottom:20px;
    // }
    // .relatedTable /deep/ .el-table__header-wrapper{
    //   display: none;
    // }
    // .relatedTable /deep/ .el-table__body-wrapper{
    //   margin-top:0;
    // }
  }
</style>
