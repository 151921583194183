var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "预览", visible: _vm.show, width: "90%" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _vm.shoutext == "是"
            ? _c("h4", { staticStyle: { color: "#f56c6c" } }, [
                _vm._v(" 产品重新上架，建议仔细核查 "),
              ])
            : _vm._e(),
          _c(
            "el-descriptions",
            { attrs: { border: "", column: 1 } },
            [
              _vm.form.goods_sn
                ? _c("el-descriptions-item", { attrs: { label: "编号" } }, [
                    _vm._v(" " + _vm._s(_vm.form.goods_sn) + " "),
                  ])
                : _vm._e(),
              _vm.form.cat_txt
                ? _c("el-descriptions-item", { attrs: { label: "品类" } }, [
                    _vm._v(" " + _vm._s(_vm.form.cat_txt) + " "),
                  ])
                : _vm._e(),
              _vm.form.cat_p_txt
                ? _c("el-descriptions-item", { attrs: { label: "细分品类" } }, [
                    _vm._v(" " + _vm._s(_vm.form.cat_p_txt) + " "),
                  ])
                : _vm._e(),
              _vm.form.goods_name
                ? _c("el-descriptions-item", { attrs: { label: "产品名称" } }, [
                    _vm._v(" " + _vm._s(_vm.form.goods_name) + " "),
                  ])
                : _vm._e(),
              _vm.form.goods_labels
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "产品标签" } },
                    _vm._l(_vm.form.goods_labels, function (list) {
                      return _c(
                        "span",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v(" " + _vm._s(list.name) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.form.channel_name
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "渠道" } },
                    _vm._l(_vm.form.goods_channel, function (list) {
                      return _c(
                        "span",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v(" " + _vm._s(list.channel_name) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.form.goods_specifications
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "规格" } },
                    _vm._l(_vm.form.goods_specifications, function (list) {
                      return _c(
                        "span",
                        { staticStyle: { "margin-left": "10px" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(list.attr_name) +
                              "-" +
                              _vm._s(list.attr_value) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.form.goods_img
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "产品主图" } },
                    [
                      _c(
                        "el-image",
                        {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            "preview-src-list": [_vm.form.goods_img],
                            src: _vm.form.goods_img,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "placeholder" },
                              slot: "placeholder",
                            },
                            [
                              _vm._v(" 加载中 "),
                              _c("span", { staticClass: "dot" }, [
                                _vm._v("..."),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.goods_list_img
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "产品相册" } },
                    _vm._l(_vm.form.goods_list_img, function (list) {
                      return _c(
                        "div",
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: { "preview-src-list": [list], src: list },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder",
                                },
                                [
                                  _vm._v(" 加载中 "),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("..."),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.form.goods_desc
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "产品描述" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.goods_desc) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.market_price
                ? _c("el-descriptions-item", { attrs: { label: "产品价格" } }, [
                    _vm._v(" " + _vm._s(_vm.form.market_price) + " "),
                  ])
                : _vm._e(),
              _vm.form.video
                ? _c("el-descriptions-item", { attrs: { label: "视频" } }, [
                    _vm.form.video && _vm.form.video.length > 0
                      ? _c("video", {
                          staticStyle: { height: "100px", width: "200px" },
                          attrs: { src: _vm.form.video, controls: "" },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.form.attr_category
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "规格-品类" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.attr_category) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.attr_spec
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "规格-包装规格" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.attr_spec) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.attr_date
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "规格-保质期" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.attr_date) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.attr_area
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "规格-招商区域" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.attr_area) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.channel_price
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "渠道到岸价" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.channel_price) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.short_point
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "产品亮点" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.short_point) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.remark
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "摘要" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.remark) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.point
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "卖点" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.point) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.policy
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "招商政策" } },
                    [
                      _c("el-input", {
                        staticClass: "textimgw",
                        staticStyle: { overflow: "auto" },
                        attrs: { disabled: "", rows: 6, type: "textarea" },
                        domProps: { innerHTML: _vm._s(_vm.form.policy) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.sort_order
                ? _c("el-descriptions-item", { attrs: { label: "排序" } }, [
                    _vm._v(" " + _vm._s(_vm.form.sort_order) + " "),
                  ])
                : _vm._e(),
              _vm.form.demo_apply
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "是否可以申请样品" } },
                    [
                      _vm.form.demo_apply == 1
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("是"),
                          ])
                        : _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("否"),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.goods_number
                ? _c("el-descriptions-item", { attrs: { label: "样品库存" } }, [
                    _vm._v(" " + _vm._s(_vm.form.goods_number) + " "),
                  ])
                : _vm._e(),
              _vm.form.is_on_sale
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "是否上架" } },
                    [
                      _vm.form.is_on_sale == 1
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("是"),
                          ])
                        : _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("否"),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }