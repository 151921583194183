<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="900px"
    @close="close"
  >
    <el-form
      ref="form"
      :inline="true"
      label-width="140px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="票券信息" label-width="140px" prop="cdkey_id">
        <el-select
          v-model="form.cdkey_id"
          clearable
          placeholder="请选择发放票券"
        >
          <el-option
            v-for="list in cdkeylist"
            :key="list.id"
            :label="list.title"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="发放范围" prop="send_scope">
        <el-select v-model="form.send_scope" placeholder="请选择发放范围">
          <el-option
            v-for="item in scopeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.send_scope == 1"
        label="会员套餐"
        prop="vip_groups"
      >
        <el-select
          v-model="form.vip_groups"
          collapse-tags
          multiple
          placeholder="请选择会员套餐"
          style="width: 250px"
        >
          <el-option
            v-for="item in vipOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.send_scope == 1" label="开通时段">
        <el-date-picker
          v-model="time_arr"
          align="right"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束时间"
          start-placeholder="开始时间"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @blur="changeDate"
        />
        <el-tooltip
          content="开通会员的时间范围，不选择默认不限制"
          style="font-size: 16px; margin-left: 5px"
        >
          <i class="el-icon-warning" />
        </el-tooltip>
      </el-form-item>
      <el-form-item
        v-if="form.send_scope == 2"
        label="指定用户"
        prop="user_groups"
      >
        <el-select
          v-model="form.user_groups"
          filterable
          :loading="uloading"
          multiple
          placeholder="请输入关键词"
          remote
          :remote-method="remoteMethod"
          reserve-keyword
          style="width: 250px"
        >
          <el-option
            v-for="item in userOptions"
            :key="item.user_id"
            :label="item.nickname + '【' + item.mobile + '】'"
            :value="item.user_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="票券来源" prop="source">
        <el-select v-model="form.source" placeholder="请选择票券来源">
          <el-option
            v-for="item in sourceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.source != 1" label="数据来源" prop="data_id">
        <el-input v-model="form.data_id" :placeholder="message" />
      </el-form-item>
      <el-form-item label="发放形式" prop="send_type">
        <el-select v-model="form.send_type" placeholder="请选择票券发放形式">
          <el-option
            v-for="item in sendOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :disabled="disabled" type="primary" @click="save()">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'Send',
    data() {
      return {
        cdkeylist: [],
        vipOptions: [],
        time_arr: [],
        userOptions: [],
        scopeOptions: [
          {
            value: 1,
            label: '指定套餐',
          },
          {
            value: 2,
            label: '指定用户',
          },
        ],
        sourceOptions: [
          {
            value: 1,
            label: '商业家会员',
          },
          {
            value: 2,
            label: '活动抽奖',
          },
          {
            value: 3,
            label: '邀约裂变',
          },
          {
            value: 4,
            label: '联络员邀约',
          },
          {
            value: 5,
            label: '报名签到',
          },
        ],
        sendOptions: [
          {
            value: 1,
            label: '补发发放',
          },
          {
            value: 2,
            label: '全部发放',
          },
        ],
        disabled: false,
        form: {
          cdkey_id: '',
          send_scope: 1,
          vip_groups: '',
          user_groups: '',
          start_time: '',
          end_time: '',
          source: 1,
          data_id: '',
          send_type: 1,
        },
        rules: {
          cdkey_id: [
            { required: true, trigger: 'blur', message: '请选择发放票券' },
          ],
        },
        title: '',
        uloading: false,
        dialogFormVisible: false,
      }
    },
    computed: {
      message() {
        switch (this.form.source) {
          case 2:
            return '请输入头条活动中奖记录ID'
          case 3:
            return '请输入邀约裂变活动ID'
          case 4:
            return '请输入联络员邀约活动ID'
          case 5:
            return '请输入签到活动ID'
          default:
            return '请输入票券数据来源ID'
        }
      },
    },
    created() {},
    methods: {
      showEdit() {
        this.title = '发放票券'
        getAction('/user/cdkey-code/voucher-option').then((res) => {
          this.cdkeylist = res.data
        })
        getAction('/user/business-club/vip-info').then((res) => {
          this.vipOptions = res.data
        })
        this.dialogFormVisible = true
      },
      remoteMethod(query) {
        if (query !== '') {
          this.uloading = true
          setTimeout(() => {
            this.uloading = false
            getAction('/api/common/common/get-user-info', {
              search: query,
            }).then((res) => {
              this.userOptions = res.data
            })
          }, 200)
        } else {
          this.options = []
        }
      },
      changeDate() {
        this.form.start_time = this.time_arr[0]
        this.form.end_time = this.time_arr[1]
      },
      close() {
        this.$refs['form'].resetFields()
        this.time_arr = []
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.send_scope == 1) {
              if (this.form.vip_groups == '') {
                this.$message.error('请选择指定发放套餐')
                return false
              }
            } else {
              if (this.form.user_groups == '') {
                this.$message.error('请选择指定发放用户')
                return false
              }
            }

            if (this.form.source != 1 && this.form.data_id == '') {
              if (this.form.source == 2) {
                this.$message.error('请输入头条活动中奖记录ID')
              } else if (this.form.source == 3) {
                this.$message.error('请输入邀约裂变活动ID')
              } else if (this.form.source == 4) {
                this.$message.error('请输入联络员邀约活动ID')
              } else if (this.form.source == 5) {
                this.$message.error('请输入签到活动ID')
              } else {
                this.$message.error('请输入票券数据来源ID')
              }
              return false
            }

            const loading = this.$loading({
              lock: true,
              text: '操作处理中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })
            postAction('/user/cdkey-code/voucher-issue', this.form).then(
              (res) => {
                if (res.request_code == 200) {
                  loading.close()
                  this.$message.success(res.msg)
                  this.close()
                  this.$emit('fetch-data')
                } else {
                  loading.close()
                  this.$message.error(res.msg)
                }
              }
            )
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped>
  .el-form-item {
    width: 100%;
  }
</style>
