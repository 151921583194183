var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "start-placeholder": "发布开始日期",
                  "end-placeholder": "发布结束日期",
                  "default-time": ["12:00:00"],
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "start-placeholder": "数据开始日期",
                  "end-placeholder": "数据结束日期",
                  "default-time": ["12:00:00"],
                },
                model: {
                  value: _vm.datatime,
                  callback: function ($$v) {
                    _vm.datatime = $$v
                  },
                  expression: "datatime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { placeholder: "请输入产品标题", clearable: "" },
                model: {
                  value: _vm.form.keywords,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "keywords", $$v)
                  },
                  expression: "form.keywords",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择品类" },
                  model: {
                    value: _vm.form.category_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "category_id", $$v)
                    },
                    expression: "form.category_id",
                  },
                },
                _vm._l(_vm.cateList, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.title, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "关联云交会" },
                  model: {
                    value: _vm.form.is_related_yjh,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_related_yjh", $$v)
                    },
                    expression: "form.is_related_yjh",
                  },
                },
                _vm._l(_vm.relatedlist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.title, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    placeholder: "最后编辑人",
                    filterable: "",
                  },
                  model: {
                    value: _vm.form.operating_user,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "operating_user", $$v)
                    },
                    expression: "form.operating_user",
                  },
                },
                _vm._l(_vm.stafflist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.truename, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "来源" },
                  model: {
                    value: _vm.form.data_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "data_type", $$v)
                    },
                    expression: "form.data_type",
                  },
                },
                _vm._l(_vm.datatypelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.title, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSearch } },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onAdd } },
                [_vm._v("新 增")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v("导 出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableloading,
              expression: "tableloading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _vm._v(" " + _vm._s(scope.column.label) + " "),
                            scope.column.label == "总访客数"
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: { effect: "dark", placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " 发布的新品的总访客数是指每日新品详情页的UV，其余的为从每日新品栏目进入产品详情页的UV "
                                        ),
                                      ]
                                    ),
                                    _c("i", { staticClass: "el-icon-warning" }),
                                  ]
                                )
                              : _vm._e(),
                            scope.column.label == "总浏览数"
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: { effect: "dark", placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " 发布的新品的总访客数是指每日新品详情页的PV，其余的为从每日新品栏目进入产品详情页的PV "
                                        ),
                                      ]
                                    ),
                                    _c("i", { staticClass: "el-icon-warning" }),
                                  ]
                                )
                              : _vm._e(),
                            scope.column.label == "总意向数"
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: { effect: "dark", placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " 发布的新品的总意向数是指点击“请企业联系我”或“查看产品更多详情”数量，其余的为留言成功、样品申请、拨打电话的和 "
                                        ),
                                      ]
                                    ),
                                    _c("i", { staticClass: "el-icon-warning" }),
                                  ]
                                )
                              : _vm._e(),
                            scope.column.label == "意向率"
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: { effect: "dark", placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [_vm._v("意向率=总意向数/总访客数")]
                                    ),
                                    _c("i", { staticClass: "el-icon-warning" }),
                                  ]
                                )
                              : _vm._e(),
                            scope.column.label == "点击率"
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: { effect: "dark", placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " 点击率=（列表点击量+首页点击量）/（列表曝光量+首页曝光量） "
                                        ),
                                      ]
                                    ),
                                    _c("i", { staticClass: "el-icon-warning" }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "封面图"
                          ? _c(
                              "div",
                              _vm._l(
                                row[item.prop].split(","),
                                function (item, index) {
                                  return _c(
                                    "el-image",
                                    {
                                      key: index,
                                      staticStyle: {
                                        width: "70px",
                                        height: "70px",
                                      },
                                      attrs: {
                                        src: item,
                                        "preview-src-list": [item],
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "placeholder" },
                                          slot: "placeholder",
                                        },
                                        [
                                          _vm._v(" 加载中 "),
                                          _c("span", { staticClass: "dot" }, [
                                            _vm._v("..."),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            )
                          : item.label == "id"
                          ? _c("div", [
                              row.data_type == 1
                                ? _c("span", [_vm._v(_vm._s(row[item.prop]))])
                                : _c("span"),
                            ])
                          : item.label == "关联云交会"
                          ? _c("div", [
                              row.data_type == 1
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row[item.prop] == 1
                                            ? "已关联"
                                            : "未关联"
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span"),
                            ])
                          : item.label == "品牌"
                          ? _c("div", [
                              row.data_type == 1
                                ? _c("span", [_vm._v(_vm._s(row[item.prop]))])
                                : _c("span"),
                            ])
                          : item.label == "来源"
                          ? _c("div", [
                              row[item.prop] == 1
                                ? _c("span", [_vm._v("发布的新品")])
                                : row[item.prop] == 2
                                ? _c("span", [_vm._v("企业上传的产品")])
                                : row[item.prop] == 3
                                ? _c("span", [_vm._v("每日新品广告")])
                                : _vm._e(),
                            ])
                          : item.label == "首次发布时间"
                          ? _c("div", [
                              row.data_type == 1
                                ? _c("span", [_vm._v(_vm._s(row[item.prop]))])
                                : _c("span"),
                            ])
                          : item.label == "是否广告"
                          ? _c("div", [
                              row.data_type == 1
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row[item.prop] == 1 ? "是" : "否"
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span"),
                            ])
                          : item.label == "首页曝光量"
                          ? _c("div", [
                              row.data_type == 1 || row.data_type == 3
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                  ])
                                : _c("span"),
                            ])
                          : item.label == "首页点击量"
                          ? _c("div", [
                              row.data_type == 1 || row.data_type == 3
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                  ])
                                : _c("span"),
                            ])
                          : item.label == "总意向数"
                          ? _c("div", [
                              row.data_type == 1
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.checkIntention(row)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                                  )
                                : _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                            ])
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              align: "center",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.data_type == 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEdit(row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.onDelete(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "small",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("editcom", { ref: "editcom", on: { refresh: _vm.getList } }),
      _c("intention", {
        ref: "intention",
        on: {
          getlist: function ($event) {
            return _vm.onSearch()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }