var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加线下服务内容",
        visible: _vm.showedit,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-descriptions",
            { attrs: { column: 2 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "区域游学考察总次数" } },
                [
                  _vm._v(
                    _vm._s(Number(_vm.showform.setmeal_offline_study_tour))
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(
                  _vm._s(
                    Number(_vm.showform.surplus_offline_study_tour) < 0
                      ? 0
                      : Number(_vm.showform.surplus_offline_study_tour)
                  )
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "抖音直播总次数" } },
                [
                  _vm._v(
                    _vm._s(
                      Number(_vm.showform.setmeal_offline_explosive_products)
                    )
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(
                  _vm._s(
                    Number(_vm.showform.surplus_offline_explosive_products) < 0
                      ? 0
                      : Number(_vm.showform.surplus_offline_explosive_products)
                  )
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "小程序/视频号直播总次数" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        Number(_vm.showform.setmeal_offline_miniwecat_live)
                      ) +
                      " "
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      Number(_vm.showform.surplus_offline_miniwecat_live) < 0
                        ? 0
                        : Number(_vm.showform.surplus_offline_miniwecat_live)
                    ) +
                    " "
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "公众号专稿推介总次数" } },
                [
                  _vm._v(
                    _vm._s(Number(_vm.showform.setmeal_offline_mp_article))
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(
                  _vm._s(
                    Number(_vm.showform.surplus_offline_mp_article) < 0
                      ? 0
                      : Number(_vm.showform.surplus_offline_mp_article)
                  )
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "热品推荐视频总次数" } },
                [_vm._v(_vm._s(Number(_vm.showform.setmeal_offline_mp_video)))]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(
                  _vm._s(
                    Number(_vm.showform.surplus_offline_mp_video) < 0
                      ? 0
                      : Number(_vm.showform.surplus_offline_mp_video)
                  )
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "公众号文章推广总次数" } },
                [_vm._v(_vm._s(Number(_vm.showform.wechat_total)))]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(_vm._s(Number(_vm.showform.wechat_last))),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "产品创意短视频总次数" } },
                [_vm._v(_vm._s(Number(_vm.showform.short_video_total)))]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(_vm._s(Number(_vm.showform.short_video_last))),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "视频号直播总次数" } },
                [_vm._v(_vm._s(Number(_vm.showform.video_total)))]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(_vm._s(Number(_vm.showform.video_last))),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "社群转发造势总次数" } },
                [_vm._v(_vm._s(Number(_vm.showform.group_total)))]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(_vm._s(Number(_vm.showform.group_last))),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "意向线索跟进总次数" } },
                [_vm._v(_vm._s(Number(_vm.showform.follow_total)))]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(_vm._s(Number(_vm.showform.follow_last))),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "高意向客户推荐总次数" } },
                [_vm._v(_vm._s(Number(_vm.showform.intention_total)))]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(_vm._s(Number(_vm.showform.intention_last))),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "地级市经销商活动/对接总次数" } },
                [_vm._v(_vm._s(Number(_vm.showform.docking_total)))]
              ),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(_vm._s(Number(_vm.showform.docking_last))),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-suffix": ":",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "160px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "添加类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { clearable: "", placeholder: "添加类型" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.typeSelect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "添加次数", prop: "nums" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, placeholder: "添加次数" },
                    model: {
                      value: _vm.form.nums,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nums", $$v)
                      },
                      expression: "form.nums",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      placeholder: "请输入内容",
                      rows: "4",
                      type: "textarea",
                      maxlength: "50",
                    },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "图片" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("upload-img", {
                      ref: "imgs",
                      attrs: { limit: 5, "info-text": "", maxSize: 100 },
                      on: {
                        getImgs: function ($event) {
                          return _vm.getGoods_imgImg($event, "imgs")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showedit = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleradd } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }