<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input
          v-model="form.apply_name"
          clearable
          placeholder="请输入经销商名称"
        />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.phone" clearable placeholder="经销商手机号" />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="请输入产品名称"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="请输入店铺名称"
        />
      </el-form-item>

      <el-form-item>
        <el-select
          v-model="form.data_type"
          clearable
          filterable
          placeholder="选择触发条件"
          style="width: 160px"
        >
          <el-option
            v-for="list in datatypelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_dealer"
          clearable
          filterable
          placeholder="经销商是否认证"
          style="width: 160px"
        >
          <el-option
            v-for="list in dealerSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_intent"
          clearable
          filterable
          placeholder="是否进入意向线索"
          style="width: 160px"
        >
          <el-option
            v-for="list in intentSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>
    <div class="totalstyle">
      <div>
        <h4>帮我联系点击次数</h4>
        <p>{{ totaltj.contact_num }}</p>
      </div>
      <div>
        <h4>暂时不用点击次数</h4>
        <p>{{ totaltj.refuse_num }}</p>
      </div>
      <div>
        <h4>已认证经销商点击次数</h4>
        <p>{{ totaltj.cert_num }}</p>
      </div>
      <div>
        <h4>未认证经销商点击次数</h4>
        <p>{{ totaltj.no_cert_num }}</p>
      </div>
    </div>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'Index',
    data() {
      return {
        datatypelist: [],
        dealerSelect: [
          {
            id: '',
            name: '全部',
          },
          {
            id: '1',
            name: '是',
          },
          {
            id: '0',
            name: '否',
          },
        ],
        intentSelect: [
          {
            id: '0',
            name: '全部',
          },
          {
            id: '1',
            name: '是',
          },
          {
            id: '2',
            name: '否',
          },
        ],
        totaltj: [],
        form: {
          apply_name: '',
          phone: '',
          start_time: '',
          end_time: '',
          mall_name: '',
          goods_name: '',
          data_type: '',
          is_dealer: null,
          page: 1,
          limit: 10,
        },
        time: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '经销商公司名称',
            prop: 'company_name',
            width: '',
          },
          {
            label: '经销商姓名',
            prop: 'apply_name',
            width: '',
          },
          {
            label: '电话号码',
            prop: 'phone',
            width: '',
          },

          {
            label: '是否认证',
            prop: 'is_dealer',
            width: '',
          },
          {
            label: '经营品类',
            prop: 'company_cate',
            width: '',
          },
          {
            label: '地区',
            prop: 'company_city',
            width: '',
          },
          {
            label: '触发条件',
            prop: 'data_type',
            width: '',
          },

          {
            label: '产品名称',
            prop: 'goods_name',
            width: '',
          },

          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '企业名称',
            prop: 'mall_company',
            width: '',
          },
          {
            label: '店铺品类',
            prop: 'mall_trade',
            width: '',
          },
          {
            label: '套餐版本',
            prop: 'mall_version',
            width: '',
          },
          {
            label: '当前套餐',
            prop: 'mall_meal',
            width: '',
          },
          {
            label: '企业电话',
            prop: 'mall_phone',
            width: '',
          },
          {
            label: '业务员',
            prop: 'mall_yewu',
            width: '',
          },
          {
            label: '点击时间',
            prop: 'created',
            width: '',
          },
          {
            label: '是否进入意向线索',
            prop: 'is_intent',
            width: '',
          },
        ],
        staffSelect: [
          {
            id: '-1',
            name: '免费店铺',
          },
          {
            id: '1',
            name: '银卡套餐',
          },
          {
            id: '2',
            name: '金卡套餐',
          },
          {
            id: '3',
            name: '钻石套餐',
          },
          {
            id: '4',
            name: '铂金套餐',
          },
        ],
        total: 0,
        preStartTime: '',
        preEndTime: '',
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerDatatype()
      this.handlerTotal()
    },
    methods: {
      async handlerTotal() {
        const tjdata = await getAction('/api/form/contact/total-tj', this.form)
        this.totaltj = tjdata.data
      },
      async handlerDatatype() {
        const datalist = await getAction('/api/form/contact/data-type')
        this.datatypelist = datalist.data
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
        if (
          this.form.start_time !== this.preStartTime ||
          this.form.end_time !== this.preEndTime
        ) {
          this.handlerTotal()
          this.preStartTime = this.form.start_time
          this.preEndTime = this.form.end_time
        }
      },
      handlerlist() {
        getAction('/api/form/contact/list', this.form).then((res) => {
          this.tabledata = res.data.list
          this.total = Number(res.totalCount)
        })
      },
      handlerExport() {
        postAction('/api/form/contact/list-export', this.form).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        })
      },
      openenterprise(row) {
        console.log('行数据', row)
        postAction('/mall/shop/jump-login-dealer', {
          mall_id: row.mall_id,
        }).then((res) => {
          console.log(res.data)
          // window.open('http://192.168.9.46:10001/#/enterprise/storeManagement?access_token='+res.data.token,'_blank')
          if (process.env.NODE_ENV === 'development') {
            window.open(
              'http://testqi.htyunjiaohui.com/#/enterprise/storeManagement?access_token=' +
                res.data.token,
              '_blank'
            )
          } else if (process.env.NODE_ENV === 'pre') {
            window.open(
              'http://pqi.htyunjiaohui.com/#/enterprise/storeManagement?access_token=' +
                res.data.token,
              '_blank'
            )
          } else {
            window.open(
              'http://qi.htyunjiaohui.com/#/enterprise/storeManagement?access_token=' +
                res.data.token,
              '_blank'
            )
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .totalstyle {
    display: flex;
    width: 100%;
    text-align: center;
    div {
      flex: 1;
    }
  }
</style>
