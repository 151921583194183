var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: "", "label-suffix": ":", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "客户" },
                model: {
                  value: _vm.form.external_user_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "external_user_name", $$v)
                  },
                  expression: "form.external_user_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "提报人" },
                model: {
                  value: _vm.form.staff_user_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "staff_user_name", $$v)
                  },
                  expression: "form.staff_user_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "店铺名称" },
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "经销商公司名称" },
                model: {
                  value: _vm.form.draw_company,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "draw_company", $$v)
                  },
                  expression: "form.draw_company",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "提报结束日期",
                  "range-separator": "至",
                  "start-placeholder": "提报开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "审核结束日期",
                  "range-separator": "至",
                  "start-placeholder": "审核开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.timelist,
                  callback: function ($$v) {
                    _vm.timelist = $$v
                  },
                  expression: "timelist",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "审核状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.shopSelect, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v("导 出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                width: item.width,
                "render-header": item.tooltip
                  ? _vm.renderHeaderWithTooltip
                  : null,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "合作凭证"
                          ? _c(
                              "div",
                              _vm._l(row[item.prop], function (list, index) {
                                return _c(
                                  "span",
                                  { key: index },
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                        },
                                        attrs: {
                                          "preview-src-list": [list],
                                          src: list,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中 "),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : item.prop == "is_match_view"
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(row[item.prop] ? "是" : "否") + " "
                              ),
                            ])
                          : item.prop == "is_invite"
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(row[item.prop] ? "是" : "否") + " "
                              ),
                            ])
                          : item.prop == "is_call"
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(row[item.prop] ? "是" : "否") + " "
                              ),
                            ])
                          : item.prop == "mall_order"
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(row[item.prop] ? "是" : "否") + " "
                              ),
                            ])
                          : item.prop == "is_suggest"
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(row[item.prop] ? "是" : "否") + " "
                              ),
                            ])
                          : item.label == "状态"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row[item.prop] == 1
                                      ? "已通过"
                                      : row[item.prop] == 3
                                      ? "已拒绝"
                                      : "待审核"
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.status == 2
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerpass(row, 1)
                                  },
                                },
                              },
                              [_vm._v(" 通过 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerpass(row, 3)
                                  },
                                },
                              },
                              [_vm._v(" 拒绝 ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerrefuse(row)
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogTitle,
                visible: _vm.dialogVisible,
                width: "40%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-descriptions",
                { attrs: { border: "", column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "提报人" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.staff_user_name) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "客户" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.external_user_name) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "店铺名称" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.mall_name) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "提报时间" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.created) + " "),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "合作凭证" } },
                    _vm._l(_vm.detail.img, function (list, index) {
                      return _c(
                        "span",
                        { key: index },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: { "preview-src-list": [list], src: list },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder",
                                },
                                [
                                  _vm._v(" 加载中 "),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("..."),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.remark) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }