var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: "", "label-suffix": ":", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "250px" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    placeholder: "一级事业部",
                  },
                  model: {
                    value: _vm.formkind,
                    callback: function ($$v) {
                      _vm.formkind = $$v
                    },
                    expression: "formkind",
                  },
                },
                _vm._l(_vm.kindselect, function (list) {
                  return _c("el-option", {
                    key: list,
                    attrs: { label: list, value: list },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "250px" },
                  attrs: {
                    clearable: "",
                    multiple: "",
                    filterable: "",
                    placeholder: "二级部门",
                  },
                  model: {
                    value: _vm.formdept,
                    callback: function ($$v) {
                      _vm.formdept = $$v
                    },
                    expression: "formdept",
                  },
                },
                _vm._l(_vm.departselect, function (list) {
                  return _c("el-option", {
                    key: list,
                    attrs: { label: list, value: list },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "员工姓名" },
                model: {
                  value: _vm.form.staff_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "staff_name", $$v)
                  },
                  expression: "form.staff_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "结束日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                  clearable: false,
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerCertExport },
                },
                [_vm._v(" 导出全部经销商身份通过数据 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v(" 导出全部标签数据 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerTradeExport },
                },
                [_vm._v(" 导出全部企微品类需求数据 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerBizExport },
                },
                [_vm._v(" 导出全部企微指定店铺需求数据 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerSelExport },
                },
                [_vm._v(" 导出全部选品方案数据 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerMessageExport },
                },
                [_vm._v(" 导出全部留言数据 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerFindExport },
                },
                [_vm._v(" 导出全部经销商找品数据 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableloading,
              expression: "tableloading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "staff_name", label: "员工姓名", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "htyun_kind", label: "一级事业部", width: "" },
          }),
          _c("el-table-column", {
            attrs: { prop: "htyun_dept", label: "二级部门", width: "" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cert_num",
              label: "经销商身份通过次数",
              width: "",
              "render-header": _vm.renderCertnum,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showDatalist(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.cert_num) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "is_ok",
              label: "标签已完善人数",
              width: "",
              "render-header": _vm.renderIsok,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showDatalistOk(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.is_ok) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "biz_trade_num",
              label: "企微品类需求被查看得奖励次数",
              width: "",
              "render-header": _vm.renderBizTradeNum,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showDatalistTrade(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.biz_trade_num) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "biz_num",
              label: "企微指定店铺需求通过次数",
              width: "",
              "render-header": _vm.renderBizNum,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showDatalistBiz(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.biz_num) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sel_num",
              label: "选品方案喜欢奖励次数",
              width: "",
              "render-header": _vm.renderSelNum,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showDatalistSel(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.sel_num) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "message_num",
              label: "留言且被查看奖励次数",
              width: "",
              "render-header": _vm.renderMessageNum,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showDatalistMessage(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.message_num) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "find_num",
              label: "经销商找品发布需求奖励次数",
              width: "",
              "render-header": _vm.renderFindNum,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showDatalistFind(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.find_num) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticStyle: { "line-height": "40px", color: "#999" } }, [
        _vm._v(
          " 提示：选品方案奖励、留言查看奖励、经销商找品奖励显示筛选日期所在月的数据，例如筛选日期为8.30-9.1，则这三项数据查出来为8月和9月两个整月的数据 "
        ),
      ]),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("list", { ref: "certlist" }),
      _c("listok", { ref: "oklist" }),
      _c("listtrade", { ref: "tradelist" }),
      _c("listbiz", { ref: "bizlist" }),
      _c("listsel", { ref: "sellist" }),
      _c("listfind", { ref: "findlist" }),
      _c("listmessage", { ref: "messagelist" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }