<!-- 企业列表 -->
<template>
  <div style="padding: 20px 10px">
    <el-form ref="form" :model="form" inline>
      <el-form-item label="">
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="">
        <el-input
          v-model="form.mall_name"
          placeholder="搜索企业名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handlerlist()"
        >
          搜索
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handleradd(1)"
        >
          新增企业
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button
          size="small"
          type="primary"
          @click.native.prevent="handleradd(2)"
        >
          关联云交会企业
        </el-button>
      </el-form-item>
    </el-form>
    <div>
      <a href="javascript:;" style="cursor: default">
        万商专区首页UV：{{ total_tj.uv }}
      </a>
      <a href="JavaScript:;" style="margin-left: 40px; cursor: default">
        万商专区首页PV：{{ total_tj.pv }}
      </a>
    </div>
    <el-table
      :data="tabledata"
      border
      ref="table"
      v-loading="loading"
      @sort-change="sortChange"
    >
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="mall_name"
        label="企业名称"
        align="center"
        width="110"
      ></el-table-column>
      <el-table-column
        prop="number_house"
        label="展馆名称"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="number"
        label="展位号"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column prop="logo" label="企业头像" align="center" width="120">
        <template #default="{ row }">
          <el-image
            :preview-src-list="[row.logo]"
            :src="row.logo"
            style="width: 100px; height: 100px"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot"></div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="cat_name"
        label="公司分类"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="yjh_id"
        label="是否关联云交会"
        align="center"
        width="90"
      >
        <template #default="{ row }">
          {{ row.yjh_id != '0' ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="uv"
        label="访客数(UV)"
        align="center"
        width="90"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="pv"
        label="访问次数(PV)"
        align="center"
        width="90"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="poster_count"
        label="生成海报数"
        align="center"
        width="90"
        sortable="custom"
      ></el-table-column>

      <el-table-column
        prop="sort_order"
        label="排序"
        align="center"
        sortable="custom"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="created"
        label="创建时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="操作"
        align="center"
        fixed="right"
        width="110px"
      >
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            style="margin: 10px"
            v-if="row.yjh_id == '0'"
            slot="reference"
            size="small"
            type="text"
            @click="relativeRow(row)"
          >
            关联产品
          </el-button>
          <el-popconfirm title="确认删除吗？" @confirm="deleteRow(row)">
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" @getlist="handlerlist()" />
    <relativelist ref="product" @getlist="handlerlist()" />
    <relativecompany ref="company" @getlist="handlerlist()" />
  </div>
</template>
<script>
  import edit from './components/enterpriseedit.vue'
  import relativelist from './components/relativelist.vue'
  import relativecompany from './components/relativecompany.vue'
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'posterlist',
    components: { edit, relativelist, relativecompany },
    data() {
      return {
        tabledata: [],
        loading: false,
        form: {
          page: 1,
          limit: 10,
          sort: '', //pv uv poster_count sort_order
          order: '', //desc 降序 asc 升序
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        total_tj: {},
        timelist: [],
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      //排序
      sortChange({ column, prop, order }) {
        console.log('column, prop, order', column, prop, order)
        this.form.sort = prop
        // 值 asc 小到大  和  desc  大到小]
        if (order === 'descending') {
          this.form.order = 'desc'
        } else if (order === 'ascending') {
          this.form.order = 'asc'
        } else {
          this.form.order_field = ''
          this.form.order = ''
        }
        this.handlerlist()
      },
      handlerlist() {
        this.form.page = 1
        this.getlistdata()
      },
      getlistdata() {
        getAction('/api/afbc/act-company/list', this.form).then((res) => {
          if (res.code == 0) {
            this.tabledata = res.data.list
            this.total = res.totalCount
            this.total_tj = res.data.total_tj
          }
        })
      },
      //新增
      handleradd(type) {
        if (type == 1) {
          // 自建新增
          this.$refs.edit.isShow = true
        } else {
          //非自建新增
          this.$refs.company.isShow = true
        }
      },
      //编辑
      handleredit(row) {
        if (row.yjh_id != '0') {
          //非自建 编辑 关联云交会企业
          this.$refs.company.isShow = true
          this.$refs.company.handlerinfo(row)
        } else {
          //自建编辑
          this.$refs.edit.isShow = true
          this.$refs.edit.handlerinfo(row)
        }
      },
      // 关联产品
      relativeRow(row) {
        this.$refs.product.isShow = true
        this.$refs.product.handlerinfo(row)
      },
      //删除
      deleteRow(row) {
        getAction('/api/afbc/act-company/delete', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.handlerlist()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getlistdata()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getlistdata()
      },
    },
  }
</script>
