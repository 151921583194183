var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "详情", visible: _vm.showinfo, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.showinfo = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-descriptions",
                { attrs: { title: _vm.form.set_meal_name, column: 2 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "套餐开始时间" } },
                    [_vm._v(" " + _vm._s(_vm.form.effective_time) + " ")]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "套餐结束时间" } },
                    [_vm._v(" " + _vm._s(_vm.form.failure_time) + " ")]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "线上匹配查看总次数" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.form.setmeal_match_views_num) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "线上匹配查看剩余次数" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.form.surplus_match_views_num) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "云会议总次数" } },
                    [_vm._v(" " + _vm._s(_vm.form.setmeal_referrals_num) + " ")]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "云会议剩余次数" } },
                    [_vm._v(" " + _vm._s(_vm.form.surplus_referrals_num) + " ")]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "大商直通车总次数" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.form.setmeal_through_train_num) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "大商直通车剩余次数" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.form.surplus_through_train_num) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "发送邀请总次数" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.form.setmeal_send_invite_num) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "发送邀请剩余次数" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.form.surplus_send_invite_num) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              !_vm.isShowmore
                ? _c(
                    "p",
                    {
                      staticStyle: { cursor: "default" },
                      on: {
                        click: function ($event) {
                          return _vm.showMore()
                        },
                      },
                    },
                    [
                      _vm._v(" 展开更多 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down",
                        staticStyle: { "font-size": "14px" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.isShowmore
                ? _c(
                    "el-descriptions",
                    { attrs: { column: 2 } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "区域游学考察总次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.setmeal_offline_study_tour) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "区域游学考察剩余次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.surplus_offline_study_tour) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "抖音直播总次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.form.setmeal_offline_explosive_products
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "抖音直播剩余次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.form.surplus_offline_explosive_products
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "视频号直播总次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.setmeal_offline_miniwecat_live) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "视频号直播剩余次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.surplus_offline_miniwecat_live) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "公众号专稿推介总次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.setmeal_offline_mp_article) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "公众号专稿推介剩余次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.surplus_offline_mp_article) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "热品推荐视频总次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.setmeal_offline_mp_video) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "热品推荐视频剩余次数" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.surplus_offline_mp_video) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "公众号文章推广总次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.wechat_total)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "公众号文章推广剩余次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.wechat_last)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "产品创意短视频总次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.short_video_total)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "产品创意短视频剩余次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.short_video_last)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "视频号直播总次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.video_total)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "视频号直播剩余次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.video_last)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "社群转发造势总次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.group_total)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "社群转发造势剩余次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.group_last)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "意向线索跟进总次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.follow_total)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "意向线索跟进剩余次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.follow_last)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "高意向客户推荐总次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.intention_total)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "高意向客户推荐剩余次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.intention_last)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "地级市经销商活动/对接总次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.docking_total)))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "地级市经销商活动/对接剩余次数" } },
                        [_vm._v(_vm._s(Number(_vm.form.docking_last)))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("h4", [_vm._v("历史套餐情况")]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.form.history },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "setmeal", label: "套餐名", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [_vm._v(_vm._s(row.setMeal.title))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "effective_time",
                      label: "套餐开始时间",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "failure_time",
                      label: "套餐结束时间",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "delay_num",
                      label: "延期次数",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showinfo = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showinfo = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }