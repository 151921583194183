var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showbind, "append-to-body": "", width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.showbind = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          clearable: "",
                          "fetch-suggestions": _vm.handlergoods,
                          "value-key": "goods_name",
                          placeholder: "请输入商品名称",
                        },
                        on: { select: _vm.handleSelectgoods },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                      _vm.form.goods_id != "0"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.form.goods_id = 0
                                },
                              },
                            },
                            [_vm._v("取消关联")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.cover_img,
                          expression: "form.cover_img",
                        },
                      ],
                      attrs: { label: "商品图片" },
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          src: _vm.form.cover_img,
                          "preview-src-list": [_vm.form.cover_img],
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品分类" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择商品分类" },
                          model: {
                            value: _vm.form.category_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "category_id", $$v)
                            },
                            expression: "form.category_id",
                          },
                        },
                        _vm._l(_vm.categoryList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showbind = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }