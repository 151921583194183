<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input v-model="form.mall_name" clearable placeholder="店铺名称" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.company_name" clearable placeholder="公司名称" />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.meal_type"
          clearable
          filterable
          placeholder="当前套餐"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";
export default {
  name: 'Index',
  data() {
    return {
      form: {
        company_name: '',
        start_time: '',
        end_time: '',
        mall_name: '',
        meal_type:'',
        page: 1,
        limit: 10,
      },
      time: [],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '店铺名称',
          prop: 'mall_name',
          width: '',
        },
        {
          label: '店铺id',
          prop: 'id',
          width: '',
        },
        {
          label: '公司名称',
          prop: 'company_name',
          width: '',
        },

        {
          label: '当前套餐',
          prop: 'title',
          width: '',
        },
        {
          label: '所有累计匹配',
          prop: 'total',
          width: '',
        },
        {
          label: '所有累计查看',
          prop: 'total_scan',
          width: '',
        },
        {
          label: '当前累计匹配（留言+推荐）',
          prop: 'now',
          width: '',
        },

        {
          label: '当前累计匹配（留言）',
          prop: 'now_message',
          width: '',
        },

        {
          label: '当前累计匹配（推荐）',
          prop: 'now_match',
          width: '',
        },
        {
          label: '当前累计匹配（指定店铺）',
          prop: 'now_appoint',
          width: '',
        },
        {
          label: '当前累计查看',
          prop: 'now_scan',
          width: '',
        },
      ],
      staffSelect: [
        {
          id:'-1',
          name:'免费店铺'
        },{
          id:'1',
          name:'银卡套餐'
        },{
          id:'2',
          name:'金卡套餐'
        },{
          id:'3',
          name:'钻石套餐'
        },{
          id:'4',
          name:'铂金套餐'
        },
      ],
      total: 0,
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    },
  },
  mounted() {
    this.handlerInquire()
    // this.handlerSelect()
  },
  methods: {
    // async handlerSelect() {
    //   const staff = await getAction('/api/poster/type/select')
    //   this.staffSelect = staff.data
    // },
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/api/form/form/mall-match-list', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handlerExport() {
      postAction('/api/form/form/mall-match-list-export', this.form).then(
        (res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
