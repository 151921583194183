<template>
  <div>
    <el-dialog append-to-body :title="title" :visible.sync="isShow" width="90%">
      <div>
        <el-form ref="form" :model="form" inline>
          <el-form-item label="">
            <el-input
              v-model="form.goods_name"
              placeholder="输入产品名称"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="">
            <el-button
              size="small"
              type="primary"
              @click.native.prevent="handlerlist()"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item label="" v-if="yjh_mall_id == 0">
            <el-button
              size="small"
              type="primary"
              @click.native.prevent="handleredit()"
            >
              新增产品
            </el-button>
          </el-form-item>
          <el-form-item label="" v-else>
            <el-button
              size="small"
              type="primary"
              @click.native.prevent="handlerrelated()"
            >
              关联产品
            </el-button>
          </el-form-item>
        </el-form>
        <el-table :data="tabledata" border ref="table" v-loading="loading">
          <el-table-column
            prop="id"
            label="ID"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="goods_name"
            label="产品名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="sort_order"
            label="排序"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="goods_img"
            label="产品图片"
            align="center"
            width="100"
          >
            <template #default="{ row }">
              <el-image
                :preview-src-list="[row.goods_img]"
                :src="row.goods_img"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template #default="{ row }">
              <el-button
                size="small"
                style="margin: 10px"
                type="text"
                @click.native.prevent="handleredit(row)"
              >
                编辑产品
              </el-button>
              <el-popconfirm title="确认删除吗？" @confirm="deleteRow(row)">
                <el-button slot="reference" size="small" type="text">
                  删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          :total="total"
          background
          style="text-align: center; margin-top: 10px"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
        <goodsedit ref="goodsedit" @getlist="handlerlist" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  import goodsedit from './goodsedit.vue'
  export default {
    components: { uploadImg, goodsedit },
    data() {
      return {
        isShow: false,
        title: '产品列表',
        loading: false,
        yjh_mall_id: 0,
        tabledata: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        form: {
          meet_mall_id: '',
          goods_name: '',
        },

        groupSelect: [
          {
            id: '1',
            name: '区域',
          },
          {
            id: '2',
            name: '新渠道',
          },
        ],
        drawSelect: [
          {
            id: '1',
            name: '是',
          },
          {
            id: '2',
            name: '否',
          },
        ],
      }
    },
    watch: {
      isShow(n, o) {
        if (n) {
        } else {
          this._onreset()
        }
      },
    },
    mounted() {},
    methods: {
      // 删除
      deleteRow(row) {
        getAction('/api/afbc/act-meet/goods-delete', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.handlerlist()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      // 编辑产品
      handlerproduct(row) {
        this.$refs.goodsedit.isShow = true
      },
      //查询
      handlerlist() {
        this.form.page = 1
        this.form.meet_id = this.form.id
        this.getlist()
      },
      // 获取列表数据
      getlist() {
        getAction('/api/afbc/act-meet/goods-list', this.form).then((res) => {
          if (res.code == 0) {
            this.tabledata = res.data.list
            this.total = res.totalCount
            this.yjh_mall_id = res.data.yjh_mall_id
          }
        })
      },
      //新增/编辑企业
      handleredit(row) {
        console.log('编辑开始', this.form)
        this.$refs.goodsedit.isShow = true
        this.$refs.goodsedit.form.meet_mall_id = this.form.meet_mall_id
        this.$refs.goodsedit.yjh_mall_id = this.yjh_mall_id
        if (row) {
          this.$refs.goodsedit.form = JSON.parse(JSON.stringify(row))
          if (row.yjh_id == 0) {
            this.$refs.goodsedit.goodstype = 1
          } else {
            this.$refs.goodsedit.goodstype = 2
          }
        }
      },
      //关联企业
      handlerrelated() {
        this.$refs.goodsedit.isShow = true
        this.$refs.goodsedit.form.meet_mall_id = this.form.meet_mall_id
        this.$refs.goodsedit.goodstype = 2
        this.$refs.goodsedit.yjh_mall_id = this.yjh_mall_id
      },
      //分页
      handleCurrentChange(val) {
        this.form.page = val
        this.getlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getlist()
      },
    },
  }
</script>
