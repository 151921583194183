<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item prop="htyun_kind">
        <el-select v-model="form.htyun_kind" clearable filterable style="width: 160px;" placeholder="一级事业部"
                   @change="setNameSelect">
          <el-option v-for="(list,index) in deptSelect"
                     :key="index"
                     :value="list.htyun_kind"
                     :label="list.htyun_kind" />
        </el-select>
      </el-form-item>
      <el-form-item prop="htyun_dept">
        <el-select v-model="form.htyun_dept" clearable filterable style="width: 220px;" placeholder="二级部门">
          <el-option v-for="(list,index) in deptTowSelect"
                     :key="index"
                     :value="list.htyun_dept"
                     :label="list.htyun_dept" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.htyun_name" clearable filterable style="width: 160px;" placeholder="邀请人">
          <el-option v-for="(list,index) in nameSelect"
                     :key="index"
                     :value="list.htyun_name"
                     :label="list.htyun_name" />
        </el-select>
      </el-form-item>
      <el-form-item>
<!--        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />-->
        <el-date-picker
          v-model="form.created"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" style="width: 100%" @sort-change="setSort">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        :sortable="item.sort"

      >
      </el-table-column>
      <el-table-column align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="onsave(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
import { getAction } from "@/api/request";

export default {
  name: "Index",
  watch: {
    time(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    },
  },
  data() {
    return {
      time:[],
      form: {
        page: 1,
        limit: 10,
        created:'',
        /**
         * 结束时间
         */
        end_time: '',
        /**
         * 员工部门
         */
        htyun_kind: '',
        /**
         * 员工部门
         */
        htyun_dept: '',
        /**
         * 员工姓名
         */
        htyun_name: '',
        /**
         * 排序字段 pv,uv
         */
        order: '',
        /**
         * desc 降序 ，asc 升序
         */
        sort: '',
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '一级事业部',
          prop: 'htyun_kind',
          width: '',
        },
        {
          label: '二级部门',
          prop: 'htyun_dept',
          width: '',
        },
        {
          label: '邀请人',
          prop: 'htyun_name',
          width: '',
        },
        {
          label: '审核中',
          prop: 'wait',
          width: '',
          sort: 'custom',
        },

        {
          label: '已审核',
          prop: 'pass',
          width: '',
          sort: 'custom',
        },
        {
          label: '已拒绝',
          prop: 'no_pass',
          width: '',
          sort: 'custom',
        },
      ],
      total: 0,
      deptSelect: [],
      deptTowSelect: [],
      nameSelect:[]
    }
  },
  mounted() {
    this.handlerSelect()
    this.handlerInquire()
  },
  methods:{
    async handlerSelect() {
      const dept = await getAction("/api/form/form/dept-one");
      this.deptSelect = dept.data;
      const staff = await getAction("/form/form/staff");
      this.nameSelect = staff.data;
    },
    async setNameSelect(e) {
      this.form.htyun_name = "";
      this.form.htyun_dept = [];
      const staTow = await getAction("/form/form/dept", { htyun_kind: e });
      this.deptTowSelect= staTow.data
    },
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    onsave(row){
      this.$router.push({
        path: '/dealerMan/dealerCertification',
        query: {
          htyun_name: row.htyun_name
        }
      })
    },
    //排序
    setSort({ column, prop, order }) {
      console.log('column, prop, order', column, prop, order)
      this.form.sort = prop
      // 值 asc 小到大  和  desc  大到小]
      if (order === 'descending') {
        this.form.order = 'desc'
      } else if (order === 'ascending') {
        this.form.order = 'asc'
      } else {
        this.form.sort = ''
        this.form.order = ''
      }
      this.handlerlist()
    },
    handlerlist() {
      getAction('/api/form/form/staff-invite-cert', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handlerExport() {
      getAction('/api/form/form/staff-invite-cert-export', { ...this.form }).then(
        (res) => {
          if (res.request_code == 200){
            this.$message({
              type: 'success',
              message: '已添加导出任务',
            })
          }
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>
