var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.guize, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.guize = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "guizeform",
                  attrs: {
                    model: _vm.guizeform,
                    "label-width": "120px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "浏览多少秒弹出",
                        prop: "cat_popup_scan",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.guizeform.cat_popup_scan,
                          callback: function ($$v) {
                            _vm.$set(_vm.guizeform, "cat_popup_scan", $$v)
                          },
                          expression: "guizeform.cat_popup_scan",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "每天共弹出几次",
                        prop: "cat_popup_count",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.guizeform.cat_popup_count,
                          callback: function ($$v) {
                            _vm.$set(_vm.guizeform, "cat_popup_count", $$v)
                          },
                          expression: "guizeform.cat_popup_count",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.guize = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerset } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "primary" },
          on: { click: _vm.handlerinfo },
        },
        [_vm._v("设置弹窗规则")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }