var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "meeting" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.formlist, "label-width": "80px", inline: "" },
        },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "名称" },
                model: {
                  value: _vm.formlist.act_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formlist, "act_name", $$v)
                  },
                  expression: "formlist.act_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "ElTable",
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "90px",
              prop: "id",
              label: "活动ID",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "act_name", label: "活动名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              width: "160px",
              prop: "start_time",
              label: "开始时间",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "160px",
              prop: "end_time",
              label: "结束时间",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              prop: "yun_state",
              label: "华糖云是否发布",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              prop: "",
              label: "首页浮动图标",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { height: "50px" },
                      attrs: { src: scope.row.float_icon },
                      on: {
                        click: function ($event) {
                          _vm.bigimgShow = true
                          _vm.bigimg = scope.row.float_icon
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              prop: "float_click",
              label: "浮标唤起量",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              prop: "goods_click",
              label: "产品唤起量",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              prop: "",
              label: "二维码",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "40px" },
                      attrs: { src: scope.row.qrcode },
                      on: {
                        click: function ($event) {
                          _vm.bigimgShow = true
                          _vm.bigimg = scope.row.qrcode
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "140px", prop: "", label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerguanlian(scope.row)
                          },
                        },
                      },
                      [_vm._v("关联产品")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.bigimgShow,
              expression: "bigimgShow",
            },
          ],
          staticClass: "bigimg",
          on: {
            click: function ($event) {
              _vm.bigimgShow = false
            },
          },
        },
        [_c("img", { attrs: { src: _vm.bigimg } })]
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "编辑",
                visible: _vm.editVisible,
                width: "40%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "editFolatForm",
                      attrs: { model: _vm.form, "label-width": "80px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "浮动图标" } },
                        [
                          _c("upload-img", {
                            ref: "float",
                            attrs: {
                              disab: _vm.disabled,
                              limit: 1,
                              "info-text": "图片尺寸：128px*515px",
                            },
                            on: {
                              getImgs: function ($event) {
                                return _vm.getfloatimg($event, "float")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳转方式", prop: "jump_type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: { clearable: "", placeholder: "跳转方式" },
                              on: { change: _vm.handlerjuptype },
                              model: {
                                value: _vm.form.jump_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "jump_type", $$v)
                                },
                                expression: "form.jump_type",
                              },
                            },
                            _vm._l(_vm.toSelect, function (list) {
                              return _c("el-option", {
                                key: list.jump_id,
                                attrs: {
                                  value: list.jump_id,
                                  label: list.jump_page,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.jump_type == 3 ||
                      _vm.form.jump_type == 22 ||
                      _vm.form.jump_type == 12
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.jump_name, prop: "jump_val" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "160px" },
                                attrs: { placeholder: _vm.jump_name },
                                model: {
                                  value: _vm.form.jump_val,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "jump_val", $$v)
                                  },
                                  expression: "form.jump_val",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.form.jump_type == 10
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择特色展馆",
                                prop: "ref_name",
                              },
                            },
                            [
                              _c("el-autocomplete", {
                                attrs: {
                                  "fetch-suggestions": _vm.handlerhuose,
                                  placeholder: "请输入特色展馆名称",
                                  "value-key": "house_name",
                                },
                                on: { select: _vm.handleSelecthuose },
                                model: {
                                  value: _vm.form.ref_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ref_name", $$v)
                                  },
                                  expression: "form.ref_name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.form.jump_type == 1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "选择店铺", prop: "jump_val" } },
                            [
                              _c("el-autocomplete", {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  "fetch-suggestions": _vm.handlermell,
                                  "value-key": "mall_name",
                                  clearable: "",
                                  placeholder: "请输入店铺名称",
                                },
                                on: { select: _vm.handleSelectmall },
                                model: {
                                  value: _vm.form.ref_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ref_name", $$v)
                                  },
                                  expression: "form.ref_name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.form.jump_type == 20
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "选择选品日", prop: "jump_val" },
                            },
                            [
                              _c("el-autocomplete", {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  "fetch-suggestions": _vm.handlerarea,
                                  placeholder: "请输入选品日名称",
                                  clearable: "",
                                  "value-key": "title",
                                },
                                on: { select: _vm.handleSelectarea },
                                model: {
                                  value: _vm.form.ref_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ref_name", $$v)
                                  },
                                  expression: "form.ref_name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.form.jump_type == 21
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "线上品类节", prop: "jump_val" },
                            },
                            [
                              _c("el-autocomplete", {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  "fetch-suggestions": _vm.handleract,
                                  placeholder: "请输入线上品类节",
                                  clearable: "",
                                  "value-key": "name",
                                },
                                on: { select: _vm.handleSelectact },
                                model: {
                                  value: _vm.form.ref_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ref_name", $$v)
                                  },
                                  expression: "form.ref_name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.form.jump_type == 18 || _vm.form.jump_type == 7
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "品类", prop: "jump_val" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "160px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择品类",
                                  },
                                  model: {
                                    value: _vm.form.jump_val,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "jump_val", $$v)
                                    },
                                    expression: "form.jump_val",
                                  },
                                },
                                _vm._l(_vm.pllist, function (list) {
                                  return _c("el-option", {
                                    key: list.id,
                                    attrs: { label: list.name, value: list.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.form.jump_type == 2
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "选择商品", prop: "jump_val" } },
                            [
                              _c("el-autocomplete", {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  "fetch-suggestions": _vm.handlergoods,
                                  "value-key": "goods_name",
                                  clearable: "",
                                  placeholder: "请输入商品名称",
                                },
                                on: { select: _vm.handleSelectgoods },
                                model: {
                                  value: _vm.form.ref_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ref_name", $$v)
                                  },
                                  expression: "form.ref_name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleEditok },
                        },
                        [_vm._v("保 存")]
                      ),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleClose()
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "编辑",
                visible: _vm.relatedVisible,
                width: "50%",
                "before-close": _vm.relateClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.relatedVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "related" },
                [
                  _c(
                    "div",
                    { staticClass: "header" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.relatedEdit(0)
                            },
                          },
                        },
                        [_vm._v("新增")]
                      ),
                      _c("el-autocomplete", {
                        staticStyle: { width: "200px", margin: "0 20px" },
                        attrs: {
                          "fetch-suggestions": _vm.querySearchAsync,
                          placeholder: "请输入产品名称关键字",
                        },
                        on: { select: _vm.relatedSelect },
                        model: {
                          value: _vm.goods_name,
                          callback: function ($$v) {
                            _vm.goods_name = $$v
                          },
                          expression: "goods_name",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getrelated()
                            },
                          },
                        },
                        [_vm._v("查 询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "relatedTable",
                      staticStyle: { width: "100%" },
                      attrs: { border: "", data: _vm.relatedList },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "goods_name",
                          label: "产品名称",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "state_text",
                          label: "状态",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "140px",
                          prop: "",
                          label: "操作",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.relatedEdit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.relatedjin(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.state == 1 ? "禁用" : "启用"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.relatedDel(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "10px",
                    },
                    attrs: {
                      background: "",
                      "current-page": _vm.relatedForm.page,
                      layout: _vm.relatedlayout,
                      "page-size": _vm.relatedForm.limit,
                      total: _vm.relatedTotal,
                    },
                    on: {
                      "current-change": _vm.relatedCurrentChange,
                      "size-change": _vm.relatedSizeChange,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.relatedEditTitle,
                visible: _vm.relatedEditShow,
                width: "30%",
                "before-close": _vm.relatedEditClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.relatedEditShow = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "relatedEdit" },
                [
                  _c("el-autocomplete", {
                    attrs: {
                      "fetch-suggestions": _vm.querySearchAsync,
                      placeholder: "请输入内容",
                    },
                    on: { select: _vm.relatedSelect },
                    model: {
                      value: _vm.relatedVal,
                      callback: function ($$v) {
                        _vm.relatedVal = $$v
                      },
                      expression: "relatedVal",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.relatedEditok()
                            },
                          },
                        },
                        [_vm._v("保 存")]
                      ),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.relatedEditClose()
                            },
                          },
                        },
                        [_vm._v("关 闭")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }