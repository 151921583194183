<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-select v-model="form.status" clearable>
          <el-option label="禁用" value="0" />
          <el-option label="启用" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerEdit()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      v-loading="loading"
      border
      :data="tabledata"
      row-key="user_id"
    >
      <el-table-column align="center" label="ID" prop="id" width="100" />
      <el-table-column align="center" label="标题" prop="title" />
      <el-table-column align="center" label="图标" prop="icon_img" width="150">
        <template #default="{ row }">
          <el-image :src="row.icon_img" style="width: 100px; height: 100px">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="跳转"
        prop="jump_type_name"
        width="150"
      />
      <el-table-column
        align="center"
        label="排序"
        prop="sort_order"
        width="150"
      />
      <el-table-column align="center" label="状态" prop="status" width="150">
        <template #default="{ row }">
          {{ row.status == 0 ? '禁用' : '开启' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" prop="id" width="200">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerDel(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @getlist="getData" />
  </div>
</template>
<script>
  import { getAction } from '@/api/request'
  import edit from './components/menuedit'
  export default {
    name: 'BanLog',
    components: { edit },
    data() {
      return {
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        tabledata: [],
        form: {
          page: 1,
          limit: 10,
          status: '1', //0禁用，1启用
        },
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      handlerEdit(row) {
        if (row) {
          this.$refs.edit.handlerinfo(row)
        } else {
          this.$refs.edit.handlerinfo()
        }
      },
      getData() {
        getAction('/user/business-club/lists-menu', this.form).then((res) => {
          this.tabledata = res.data
          this.total = res.totalCount
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getData()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getData()
      },
    },
  }
</script>
