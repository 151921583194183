var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c("h3", [_vm._v("品类统计")]),
      _c(
        "div",
        [
          _c("el-date-picker", {
            staticStyle: { "margin-right": "10px", width: "260px" },
            attrs: {
              "range-separator": "-",
              "picker-options": _vm.pickerOptions,
              "value-format": "yyyy-MM-dd",
              format: "yyyy-MM-dd",
              type: "daterange",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
            },
            model: {
              value: _vm.dateRange,
              callback: function ($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange",
            },
          }),
          _c(
            "el-select",
            {
              staticStyle: { "margin-right": "10px", width: "120px" },
              attrs: { placeholder: "请选择品类", clearable: "" },
              model: {
                value: _vm.form.cate_id,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "cate_id", $$v)
                },
                expression: "form.cate_id",
              },
            },
            _vm._l(_vm.catSelect, function (category) {
              return _c("el-option", {
                key: category.id,
                attrs: { label: category.name, value: category.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticStyle: { "margin-right": "10px", width: "130px" },
              attrs: { placeholder: "是否手动设置", clearable: "" },
              model: {
                value: _vm.form.is_source,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "is_source", $$v)
                },
                expression: "form.is_source",
              },
            },
            _vm._l(_vm.sourceSelect, function (category) {
              return _c("el-option", {
                key: category.id,
                attrs: { label: category.name, value: category.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticStyle: { "margin-right": "10px", width: "130px" },
              attrs: { placeholder: "是否平台产品", clearable: "" },
              model: {
                value: _vm.form.is_platform,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "is_platform", $$v)
                },
                expression: "form.is_platform",
              },
            },
            _vm._l(_vm.platformSelect, function (category) {
              return _c("el-option", {
                key: category.id,
                attrs: { label: category.name, value: category.id },
              })
            }),
            1
          ),
          _c("el-input", {
            staticStyle: { "margin-right": "10px", width: "160px" },
            attrs: { placeholder: "店铺名称", clearable: "" },
            model: {
              value: _vm.form.mall_name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "mall_name", $$v)
              },
              expression: "form.mall_name",
            },
          }),
          _c("el-input", {
            staticStyle: { "margin-right": "10px", width: "160px" },
            attrs: { placeholder: "产品名称", clearable: "" },
            model: {
              value: _vm.form.goods_name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "goods_name", $$v)
              },
              expression: "form.goods_name",
            },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSearch } },
            [_vm._v("搜索")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "line-height": "28px",
            margin: "15px 0",
            color: "#666",
            "font-size": "15px",
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-s-data",
            staticStyle: { color: "rgb(33, 177, 225)" },
          }),
          _vm._v(
            " 页面总PV数 " +
              _vm._s(_vm.total_pv) +
              "     页面总UV数 " +
              _vm._s(_vm.total_uv) +
              " "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "totalstyle" },
        _vm._l(_vm.totalconf, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class: { active: _vm.activeIndex === index },
              on: {
                click: function ($event) {
                  return _vm.setActiveIndex(index)
                },
              },
            },
            [
              _c("p", [_vm._v(_vm._s(item.title))]),
              _c("p", [_vm._v(_vm._s(item.count) + "次")]),
              _c("p", [_vm._v("点击数")]),
            ]
          )
        }),
        0
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "sort-change": _vm.sortChange },
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
          _c("el-table-column", {
            attrs: { prop: "logo", label: "Logo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "50px", height: "50px" },
                      attrs: { src: scope.row.logo, alt: "logo" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm.activeIndex != 1
            ? _c("el-table-column", {
                attrs: { prop: "goods_name", label: "标题" },
              })
            : _vm._e(),
          _vm.activeIndex != 1
            ? _c("el-table-column", {
                attrs: { prop: "cat_name", label: "品类" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { prop: "mall_name", label: "店铺" },
          }),
          _c("el-table-column", {
            attrs: { prop: "is_auto", label: "是否手动设置" },
          }),
          _c("el-table-column", {
            attrs: { prop: "is_platform", label: "是否平台产品" },
          }),
          _c("el-table-column", {
            attrs: { label: "生效时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.start_time
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.start_time) +
                              "至" +
                              _vm._s(scope.row.end_time) +
                              " "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(scope.row.is_auto == "是" ? "永久有效" : "")
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { prop: "status", label: "状态" } }),
          _c("el-table-column", {
            attrs: { prop: "pv", label: "点击数", sortable: "custom" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }