<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="80%">
    <el-form ref="form" inline :model="queryForm">
      <el-form-item>
        <el-input
          v-model.trim="queryForm.keywords"
          clearable
          placeholder="搜索标题"
          style="width: 250px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model.trim="queryForm.send_scope"
          clearable
          placeholder="发放范围"
        >
          <el-option
            v-for="item in scopeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model.trim="queryForm.status"
          clearable
          placeholder="发放状态"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlSend()">发放票券</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        align="center"
        label="ID"
        prop="id"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="票券标题"
        prop="title"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="发放范围" prop="send_scope">
        <template #default="{ row }">
          <font v-if="row.send_scope == 1">指定套餐</font>
          <font v-else>指定用户</font>
        </template>
      </el-table-column>
      <el-table-column align="center" label="发放人群" show-overflow-tooltip>
        <template #default="{ row }">
          <font v-if="row.send_scope == 1">{{ row.vip_groups_title }}</font>
          <font v-else>{{ row.user_groups_title }}</font>
        </template>
      </el-table-column>
      <el-table-column align="center" label="会员开通时段"  width="100">
        <template #default="{ row }">
          <span v-if="row.send_scope == 1 && row.start_time != 0">
            {{ row.start_time }} 至 {{ row.end_time }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="发放用户"
        prop="total_user_num"
        width="100"
      >
        <template #default="{ row }">{{ row.total_user_num }}/人</template>
      </el-table-column>
      <el-table-column
        align="center"
        label="已发放用户"
        prop="issued_user_num"
        width="100"
      >
        <template #default="{ row }">{{ row.issued_user_num }}/人</template>
      </el-table-column>
      <el-table-column
        align="center"
        label="票券来源"
        prop="source"
        width="100"
      >
        <template #default="{ row }">
          <font v-if="row.source == 1">商业家会员</font>
          <font v-else-if="row.source == 2">活动抽奖</font>
          <font v-else-if="row.source == 3">邀约裂变</font>
          <font v-else-if="row.source == 4">联络员邀约</font>
          <font v-else-if="row.source == 5">报名签到</font>
          <font v-else>--</font>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="发放形式"
        prop="send_type"
        width="100"
      >
        <template #default="{ row }">
          <font v-if="row.send_type == 1">补发发放</font>
          <font v-else>全部发放</font>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="失败原因"
        prop="reason"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="发放状态"
        prop="status"
        width="100"
      >
        <template #default="{ row }">
          <font v-if="row.status == 0">发放中</font>
          <font v-else-if="row.status == 1">发放成功</font>
          <font v-else>发放失败</font>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="发放时间"
        prop="created"
        show-overflow-tooltip
        width="150"
      />
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        prop="id"
        width="80"
      >
        <template #default="{ row }">
          <el-button
            type="text"
            @click.native.prevent="handleSendCdkeyLog(row)"
          >
            发放记录
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <send ref="send" @fetch-data="fetchData" />
    <sendCdkeyLog ref="sendCdkeyLog" />
  </el-dialog>
</template>
<script>
  import { getAction } from '@/api/request'
  import send from '@/views/pages/userscrm/cdkey/components/send'
  import sendCdkeyLog from '@/views/pages/userscrm/cdkey/components/sendCdkeyLog'
  export default {
    name: 'SendLog',
    components: { send, sendCdkeyLog },
    data() {
      return {
        list: [],
        title: '',
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          keywords: '',
          send_scope: '',
          status: '',
          pageNo: 1,
          pageSize: 10,
        },
        scopeOptions: [
          {
            value: 1,
            label: '指定套餐',
          },
          {
            value: 2,
            label: '指定用户',
          },
        ],
        statusOptions: [
          {
            value: '0',
            label: '发放中',
          },
          {
            value: '1',
            label: '发放成功',
          },
          {
            value: '2',
            label: '发放失败',
          },
        ],
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      handlSend() {
        this.$refs.send.showEdit()
      },
      handleSendCdkeyLog(val) {
        this.$refs.sendCdkeyLog.showData({ stystem_id: val.id })
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        getAction('/user/cdkey-code/stystem-send-log', this.queryForm).then(
          (res) => {
            this.list = res.data
            this.total = res.totalCount
            this.listLoading = false
          }
        )
      },
      showData() {
        ;(this.dialogFormVisible = true), (this.listLoading = true)
        this.fetchData()
      },
    },
  }
</script>
