var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c("wang-editor", { ref: "content" }),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
        [_vm._v("提交")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }