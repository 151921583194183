<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form" >
<!--      :rules="rules"-->
<!--      <el-form-item prop="htyun_kind">
        <el-select v-model="form.htyun_kind" clearable filterable multiple collapse-tags style="width: 160px;" placeholder="一级事业部"
                   @change="setNameSelect">
          <el-option v-for="(list,index) in deptSelect"
                     :key="index"
                     :value="list.htyun_kind"
                     :label="list.htyun_kind" />
        </el-select>
      </el-form-item>
      <el-form-item prop="htyun_dept">
        <el-select v-model="form.htyun_dept" clearable filterable multiple collapse-tags style="width: 220px;" placeholder="二级部门">
          <el-option v-for="(list,index) in deptTowSelect"
                     :key="index"
                     :value="list.htyun_dept"
                     :label="list.htyun_dept" />
        </el-select>
      </el-form-item>-->
      <el-form-item>
        <el-cascader
          v-model="form.depart_ids"
          placeholder="企微员工姓名及部门"
          filterable
          :options="options"
          :props="{ multiple: true,leafOnly: true,emitPath: false, label: 'name', value: 'id' }"
          collapse-tags
          clearable/>
      </el-form-item>
<!--      <el-form-item>
        <el-select v-model="form.htyun_name" clearable filterable style="width: 160px;" placeholder="员工姓名">
          <el-option v-for="(list,index) in nameSelect"
                     :key="index"
                     :value="list.htyun_name"
                     :label="list.htyun_name" />
        </el-select>
      </el-form-item>-->
      <el-form-item prop="duration">
        <el-input v-model="form.duration" style="width: 160px" clearable placeholder="浏览时长（单位秒）" />
      </el-form-item>
      <el-form-item prop="mall_id">
        <el-autocomplete
          style="width: 220px;"
          v-model="form.mall_name"
          :fetch-suggestions="handlermell"
          value-key="mall_name"
          clearable
          placeholder="请输入店铺名称"
          @select="handleSelectmall"
        ></el-autocomplete>

      </el-form-item>
<!--      <el-form-item>
        <el-autocomplete
          style="width: 160px;"
          v-model="form.goods_name"
          :fetch-suggestions="handlergoods"
          value-key="goods_name"
          clearable
          placeholder="请输入商品名称"
          @select="handleSelectgoods"
        ></el-autocomplete>
      </el-form-item>-->

      <el-form-item prop="start_time">
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.is_new" clearable placeholder="请选择数据段">
          <el-option
            label="被邀请人显示最早数据（单个店铺去重）"
            :value="1">
          </el-option>
          <el-option
            label="被邀请人显示最早数据（所有店铺去重）"
            :value="2">
          </el-option>
          <el-option
            label="被邀请人显示全部数据"
            :value="0">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.click_staff" clearable placeholder="是否员工">
          <el-option
            label="是"
            :value="1">
          </el-option>
          <el-option
            label="否"
            :value="2">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.is_pay" clearable placeholder="店铺">
          <el-option
            label="付费店铺"
            :value="1">
          </el-option>
          <el-option
            label="免费店铺"
            :value="0">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.htyun_name" placeholder="邀请人" clearable/>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.info_type" clearable placeholder="浏览页面类型">
          <el-option
            label="产品"
            :value="4">
          </el-option>
          <el-option
            label="店铺"
            :value="5">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.goods_name" placeholder="产品名称" clearable/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>
<!--    <h6>备注：按店铺搜索时展示店铺主页和该店铺下的所有产品页的相关信息</h6>@sort-change="sortChange" :sortable="item.sort"-->
    <el-table border :data="tabledata" style="width: 100%"  v-loading="loading">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"

      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";

export default {
  name: "Index",
  data() {
    return {
      loading: false,
      options:[],
      form: {
        depart_ids:[],
        is_pay:'',
        is_new:0,
        click_staff:'',
        htyun_name:'',
        info_type:'',
        goods_name:'',
        mall_name:'',
        /**
         * 时长
         */
        duration: '',
        /**
         * 结束时间
         */
        end_time: '',
        /**
         * 二级部门
         */
        htyun_dept: [],
        /**
         * 一级部门
         */
        htyun_kind: [],
        /**
         * 店铺id
         */
        mall_id: '',
        /**
         * 开始时间
         */
        start_time: '',
        page: 1,
        limit: 10
      },
      rules: {
        htyun_kind: [
          { required: true, message: "请选择一级事业部", trigger: "change" }
        ],
        htyun_dept: [
          { required: true, message: "请选择二级部门", trigger: "change" }
        ],
        duration: [
          { required: true, message: "请输入浏览时长", trigger: "change" }
        ],
        mall_id: [
          { required: true, message: "请选择店铺", trigger: "change" }
        ],
        start_time: [
          { required: true, message: "请选择时间", trigger: "change" }
        ],
      },
      time: [],
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "用户ID",
          prop: "id",
          width: ""
        },
        {
          label: "用户IP",
          prop: "ip",
          width: ""
        },
        {
          label: "昵称",
          prop: "nickname",
          width: ""
        },

        {
          label: "姓名",
          prop: "username",
          width: ""
        },
        {
          label: "电话",
          prop: "phone",
          width: ""
        },
        {
          label: "是否员工",
          prop: "click_staff",
          width: ""
        },
        {
          label: "是否经销商",
          prop: "is_dealer",
          width: ""
        },
        {
          label: "是否店铺管理员",
          prop: "is_mall",
          width: ""
        },
        {
          label: "公司名",
          prop: "company_name",
          width: ""
        },

        {
          label: "地区",
          prop: "region",
          width: ""
        },

        {
          label: "邀请人",
          prop: "htyun_name",
          width: "",
          sort: '',
        },
        {
          label: "企微员工姓名",
          prop: "wecom_staff",
          width: ""
        },
        {
          label: "所在部门",
          prop: "wecom_depart",
          width: "",
          sort: '',
        },
        {
          label: "时间",
          prop: "add_time",
          width: "",
          sort: '',
        },
        {
          label: "时长（秒）",
          prop: "duration",
          width: ""
        },
        {
          label: "浏览页面类型",
          prop: "info_type",
          width: ""
        },
        {
          label: "产品名称",
          prop: "goods_name",
          width: ""
        },
        {
          label: "店铺名称",
          prop: "mall_name",
          width: ""
        },
        {
          label: "是否付费",
          prop: "is_pay",
          width: "",
          sort: '',
        },
        {
          label: "套餐版本",
          prop: "pay_version",
          width: "",
          sort: '',
        },
        {
          label: "套餐名称",
          prop: "pay_type",
          width: "",
          sort: '',
        },
        {
          label: "是否留言",
          prop: "is_message",
          width: ""
        },
        {
          label: "是否电话",
          prop: "is_phone",
          width: ""
        },
        {
          label: "是否样品申请",
          prop: "is_order",
          width: ""
        },
      ],
      total: 0,
      deptSelect: [],
      deptTowSelect:[],
      nameSelect: []
    };
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_time = v[0];
        this.form.end_time = v[1];
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
    }
  },
  mounted() {
    // this.handlerInquire();
    this.handlerSelect();
  },
  methods: {
    //排序
    sortChange({ column, prop, order }) {
      console.log('column, prop, order', column, prop, order)
      this.form.sort = prop
      // 值 asc 小到大  和  desc  大到小]
      if (order === 'descending') {
        this.form.order = 'desc'
      } else if (order === 'ascending') {
        this.form.order = 'asc'
      } else {
        this.form.sort = ''
        this.form.order = ''
      }
      this.handlerlist()
    },
    async handlerSelect() {
      const dept = await getAction("/api/form/form/dept-one");
      this.deptSelect = dept.data;
      const staff = await getAction("/user/wechat-relation/wecom-depat-list");
      this.options = staff.data;

    },
    async setNameSelect(e) {
      this.form.htyun_name = "";
      this.form.htyun_dept = [];
      const staTow = await getAction("/form/form/dept", { htyun_kind: e });
      this.deptTowSelect= staTow.data
    },
    handlerInquire() {
      this.form.page = 1;
      this.handlerlist();
    },
    handlerlist() {
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      let data = JSON.parse(JSON.stringify(this.form));
      data.depart_ids = data.depart_ids.join(",");
          this.loading = true;
          getAction("/api/form/day-data/mall-flow-list", data).then(
            (res) => {
              this.tabledata = res.data;
              this.total = Number(res.totalCount);
              this.loading = false;
            }
          );
      //   } else {
      //     return false;
      //   }
      // })

    },
    handlerExport() {
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      let data = JSON.parse(JSON.stringify(this.form));
      data.depart_ids = data.depart_ids.join(",");
          postAction("/api/form/day-data/mall-flow-list-export", data).then(
            (res) => {
              this.$message({
                message: "已加入导出队列，请稍后查看",
                type: "success",
              })
            }
          );
        // } else {
        //   return false;
        // }
      // })

    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.mall_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.goods_id=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }

    },
    handleSelectmall(list){
      console.log(list)
      this.form.mall_id=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.form.goods_id=list.id
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.handlerlist();
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.handlerlist();
    }
  }
};
</script>

<style scoped></style>
