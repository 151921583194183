var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        top: "5vh",
        visible: _vm.showEditDialog,
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showEditDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "100px",
            model: _vm.form,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "菜单标题" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: { placeholder: "请输入菜单标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "菜单图标", prop: "pic" } },
            [
              _c("upload-img", {
                ref: "pic",
                attrs: {
                  "info-text": "尺寸：126*126",
                  limit: 1,
                  "max-size": 100,
                },
                on: {
                  getImgs: function ($event) {
                    return _vm.getGoods_imgImg($event, "icon_img")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "跳转类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.jump_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "jump_type", $$v)
                    },
                    expression: "form.jump_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "无跳转", value: "0" } }),
                  _c("el-option", { attrs: { label: "H5链接", value: "1" } }),
                  _c("el-option", {
                    attrs: { label: "小程序通用链接", value: "2" },
                  }),
                  _c("el-option", {
                    attrs: { label: "糖烟酒周刊小程序", value: "3" },
                  }),
                  _c("el-option", {
                    attrs: { label: "线下研修弹窗", value: "4" },
                  }),
                  _c("el-option", {
                    attrs: { label: "选品趋势报告弹窗", value: "5" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.jump_type == 1 ||
          _vm.form.jump_type == 2 ||
          _vm.form.jump_type == 3
            ? _c(
                "el-form-item",
                { attrs: { label: "跳转链接" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入链接" },
                    model: {
                      value: _vm.form.jump_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "jump_url", $$v)
                      },
                      expression: "form.jump_url",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "菜单排序" } },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.sort_order,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort_order", $$v)
                  },
                  expression: "form.sort_order",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "菜单状态" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("启用")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("禁用")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showEditDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c("el-button", { on: { click: _vm.handlerSave } }, [_vm._v("确定")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }