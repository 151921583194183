<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="分配结束日期"
          format="yyyy-MM-dd"
          start-placeholder="分配开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.staff_user_id" placeholder="请选择客服" clearable filterable multiple collapse-tags>
          <el-option
            v-for="(item, index) in staffList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导出</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerImport">分配任务导入</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" style="width: 100%"  show-summary
              :summary-method="getSummaries">
      <el-table-column type="index" width="55" align="center"></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :align="item.align"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '操作'">
            <el-button type="text" size="mini" @click="onsave(row)">查看详情</el-button>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <h4 style="color: red">跟进率=跟进数量÷分配数量x100%  未跟进=分配数量-跟进数量</h4>
    <h4 style="color: red">有效沟通率=有效沟通÷(有效沟通+未联系上+非行业人士+无效数据+暂无找品需求)x100%</h4>
    <info ref="info" />
    <import-ser ref="importSer" />
  </div>
</template>

<script>
import { postAction } from "@/api/request";
import * as excel from "@/utils/excel";
import Info from "./components/info.vue";
import dayjs from "dayjs";
import ImportSer from "@/views/pages/datastatis/allocation/components/importSer.vue";

export default {
  name: "index",
  components: { ImportSer, Info },
  watch: {
    time(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    }
  },
  data() {
    return {
      time:[dayjs(new Date()).format('YYYY-MM-DD'),dayjs(new Date()).format('YYYY-MM-DD')],
      tabledata: [],
      form: {
        page: 1,
        limit: 10,
        start_time:dayjs(new Date()).format('YYYY-MM-DD'),
        end_time: dayjs(new Date()).format('YYYY-MM-DD'),
        staff_user_id: [],
      },
      total: 0,
      layout: "total, sizes, prev, pager, next, jumper",
      columns: [
        {
          label: "客服",
          prop: "htyun_name",
          width: "",
          align: "center",
        },
        {
          label: "分配数量(人)",
          prop: "num",
          width: "",
          align: "center",
        },
        {
          label: "跟进数量(人)",
          prop: "follow_num",
          width: "",
          align: "center",
        },
        {
          label: "未跟进(人)",
          prop: "no_follow_num",
          width: "",
          align: "center",
        },
        {
          label: "有效沟通（人）",
          prop: "effect_num",
          width: "",
          align: "center",
        },
        {
          label: "有效沟通(次)",
          prop: "status_1_num",
          width: "",
          align: "center",
        },
        {
          label: "未联系上(次)",
          prop: "status_2_num",
          width: "",
          align: "center",
        },
        {
          label: "非行业人士(次)",
          prop: "status_3_num",
          width: "",
          align: "center",
        },
        {
          label: "无效数据(次)",
          prop: "status_4_num",
          width: "",
          align: "center",
        },
        {
          label: "暂无找品需求(次)",
          prop: "status_5_num",
          width: "",
          align: "center",
        },

        {
          label: "跟进率",
          prop: "follow_rate",
          width: "",
          align: "center",
        },
        {
          label: "有效沟通率",
          prop: "effect_rate",
          width: "",
          align: "center",
        },

        {
          label: "操作",
          prop: "remark",
          width: "120",
          align: "center",
        },
      ],
      staffList:[],
      sum:{}
    };
  },
  mounted() {
    this.handlerlist();
    this.handlerSelect()
  },
  methods: {
    handlerImport(){
      this.$refs.importSer.dialogVisible = true
    },
    onsave(row){
      let data = JSON.parse(JSON.stringify(row));
      data.start_time = this.form.start_time
      data.end_time = this.form.end_time
      this.$refs.info.handlerinfo(data)
      this.$refs.info.dialogVisible = true
    },
    handlerSelect(){
      postAction('/api/form/crm/custom-lists').then((res) => {
        console.log(res)
        this.staffList = res.data
      })
    },
    getSummaries({ columns, data }) {
      const sums = []
      columns.forEach((column, index) => {
        if (column.label === '客服') {
          sums[index] = '总计'
        }
        if (column.label === '跟进率') {
          sums[index] ='平均'+ this.sum.average_follow_rate
        }
        if (column.label === '有效沟通率') {
          sums[index] = '平均'+this.sum.average_effect_rate
        }


        const values = data.map(item => Number(item[column.property]));
        if (column.property === 'num'||
          column.property === 'follow_num'||
          column.property === 'no_follow_num'||
          column.property === 'no_follow_num'||
          column.property === 'status_1_num'||
          column.property === 'status_2_num') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }

      })
      return sums
    },
    handlerlist() {
      let data = JSON.parse(JSON.stringify(this.form))
      data.staff_user_id = data.staff_user_id.join(',')
      postAction("/api/form/crm/divide-count", data).then((res) => {
        this.tabledata = res.data.list;
        this.total = Number(res.totalCount);
        this.sum={
          average_follow_rate: res.data.average_follow_rate,
          average_effect_rate: res.data.average_effect_rate
        }
      });
    },
    handlerInquire() {
      this.form.page = 1;
      this.handlerlist();
    },
    handlerExport() {
      let data = JSON.parse(JSON.stringify(this.form))
      data.staff_user_id = data.staff_user_id.join(',')
      postAction("/api/form/crm/export-dividecount", data).then(
        (res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          });
        }
      );
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.handlerlist();
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.handlerlist();
    },
  },
};
</script>

<style scoped>

</style>
