var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: { data: _vm.tabledata, border: "", "row-key": "user_id" },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "title",
              width: "150",
              label: "标题",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "icon_img",
              width: "150",
              label: "图片",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: { src: row[_vm.item.prop] },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "placeholder" },
                            slot: "placeholder",
                          },
                          [
                            _vm._v(" 加载中 "),
                            _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "jump_type_name",
              width: "150",
              label: "跳转",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", width: "150", label: "排序" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "status",
              width: "150",
              label: "状态",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", width: "", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handlerEdit(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }