<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="请输入标题"
          style="width: 220px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.jump_type"
          clearable
          placeholder="类型"
          style="width: 160px"
        >
          <el-option
            v-for="item in jumplist"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleradd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      v-loading="tableloading"
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '类型'">
            <span v-if="row[item.prop] == 1">店铺首页</span>
            <span v-else-if="row[item.prop] == 2">产品详情页</span>
            <span v-else-if="row[item.prop] == 3">H5链接</span>
            <span v-else-if="row[item.prop] == 4">无跳转</span>
            <span v-else-if="row[item.prop] == 5">热点文章</span>
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row[item.prop] == 1 ? '上架' : '下架' }}
          </div>
          <div v-else-if="item.label == '头像'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '背景图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '置顶'">
            {{ row[item.prop] == 1 ? '置顶' : '取消置顶' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerdelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-edit ref="add" @getlist="handlerlist" />
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  import AddEdit from './components/edit.vue'

  export default {
    name: 'Index',
    components: { AddEdit },
    data() {
      return {
        form: {
          title: '',
          status: '-1', // 状态，1启用，0禁用，-1全部
          jump_type: '', //跳转类型，1店铺，2产品详情页，3H5链接，4无跳转5热点文章（这是全部的跳转类型，按需要展示）
          page: 1,
          limit: 10,
          type: 5, //位置 1企业版首页顶部2经销商版首页顶部3综合版4万商专区5热点顶部轮播图
        },
        jumplist: [
          {
            id: 1,
            name: '店铺',
          },
          {
            id: 2,
            name: '产品详情页',
          },
          {
            id: 3,
            name: 'H5链接',
          },
          {
            id: 4,
            name: '无跳转',
          },
          {
            id: 5,
            name: '热点文章',
          },
        ],

        checkselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未审',
          },
          {
            id: 1,
            name: '已审',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '110',
          },
          {
            label: '标题',
            prop: 'title',
            width: '220px',
          },
          {
            label: '背景图',
            prop: 'pic',
            width: '120',
          },
          {
            label: '类型',
            prop: 'jump_type',
            width: '80',
          },
          {
            label: '曝光量',
            prop: 'display_count',
            width: '80',
          },
          {
            label: '点击UV',
            prop: 'uv',
            width: '80',
          },
          {
            label: '点击PV',
            prop: 'pv',
            width: '80',
          },
          {
            label: '排序',
            prop: 'sort',
            width: '80',
          },
          {
            label: '状态',
            prop: 'status',
            width: '80',
          },
          {
            label: '发布时间',
            prop: 'created',
            width: '140',
          },
        ],
        total: 0,
        areaselect: [],
        classselect: [],
        tableloading: false,
        staffSelect: [],
        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.getstaff()
      // this.handlerSelect()
    },
    methods: {
      getstaff() {
        getAction('/api/stroll/article/admin-list').then((res) => {
          if (res.code == 0) {
            this.staffSelect = res.data
          }
        })
      },
      handlerdelete(row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          getAction('/api/index/banner-del', { id: row.id }).then((res) => {
            if (res.code == 0) {
              this.$message.success('删除成功')
              this.handlerInquire()
            }
          })
        })
      },
      handleradd(row) {
        this.$refs.add.showadd = true
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handleredit(row) {
        this.$refs.add.showadd = true
        this.$refs.add.handlerinfo(row)
        // if (row) {
        //   this.$refs.add.form = JSON.parse(JSON.stringify(row))
        // }
      },
      handlerstatus(row) {
        getAction('/api/stroll/article/status', { id: row.id }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg)
            this.handlerlist()
          }
        })
      },
      // async handlerSelect() {
      //   const { data } = await getAction('/user/index/area', { pid: 0 })
      //   this.areaselect = data
      //   await getAction('/user/index/cate').then((res) => {
      //     this.classselect = res.data
      //   })
      // },
      handlerlist() {
        this.tableloading = true
        getAction('/api/index/banner-list', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
          this.tableloading = false
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
