<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="来源">
        <el-select
          v-model="form.source"
          clearable
          placeholder="来源"
          style="width: 160px"
        >
          <el-option
            v-for="list in sourcelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="需求类型">
        <el-select
          v-model="form.genre"
          clearable
          placeholder="需求类型"
          style="width: 160px"
        >
          <el-option
            v-for="list in genrelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="需求客户">
        <el-input
          v-model="form.name"
          clearable
          placeholder="需求客户"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item label="昵称">
        <el-input
          v-model="form.nickname"
          clearable
          placeholder="昵称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item label="一级品类">
        <el-select
          v-model="form.root_cat_id"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="二级品类">
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="提报人">
        <el-input
          v-model="form.tibao_name"
          clearable
          placeholder="提报人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="审核人">
        <el-input
          v-model="form.shenhe_name"
          clearable
          placeholder="审核人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="需求电话">
        <el-input
          v-model="form.telephone"
          clearable
          maxlength="11"
          placeholder="需求电话"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input
          v-model="form.company"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>
      <!-- <el-form-item>
        <el-input
          v-model="form.tibao_shenhe_name"
          clearable
          placeholder="提报人"
          style="width: 160px"
        />
      </el-form-item> -->
      <el-form-item label="审核状态">
        <el-select
          v-model="form.status"
          clearable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in showSelsect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="选品方案状态">
        <el-select
          v-model="form.sel_status"
          clearable
          placeholder="选品方案状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in selStatusList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="云会议状态">
        <el-select
          v-model="form.meeting_status"
          clearable
          placeholder="云会议状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in meetingStatusList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="匹配形式">
        <el-select
          v-model="form.match_way"
          clearable
          placeholder="匹配形式"
          style="width: 160px"
        >
          <el-option
            v-for="list in matchtypelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="时间类型">
        <el-select
          v-model="form.time_type"
          clearable
          placeholder="查询时间"
          style="width: 160px"
        >
          <el-option
            v-for="list in timetypelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.type"
          clearable
          placeholder="需求形式"
          style="width: 160px"
        >
          <el-option
            v-for="list in xuqiutypelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.wine_price"
          clearable
          placeholder="价格带"
          style="width: 160px"
        >
          <el-option
            v-for="list in winepricelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="省份">
        <el-select
          v-model="form.pid"
          clearable
          placeholder="省"
          style="width: 160px"
          @change="handleProChange"
        >
          <el-option
            v-for="list in provinceselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="市">
        <el-select
          v-model="form.cid"
          clearable
          placeholder="市"
          style="width: 160px"
        >
          <el-option
            v-for="list in citylist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="服务人员">
        <el-input
          v-model="form.service_name"
          clearable
          placeholder="服务人员"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="是否被查看过">
        <el-select
          v-model="form.be_view"
          clearable
          placeholder="是否被查看过"
          style="width: 160px"
        >
          <el-option
            v-for="list in beviewlist"
            :key="list.name"
            :label="list.name"
            :value="list.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item
        v-if="
          userid == 797 ||
          userid == 37 ||
          userid == 10000025 ||
          userid == 10000010 ||
          userid == 10000013 ||
          userid == 10000007
        "
      >
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="listLoading"
      border
      :data="tabledata"
      :height="height"
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '沟通截图'">
            <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
              <span v-for="(list, index) in row[item.prop]" :key="index">
                <el-image
                  :preview-src-list="[list]"
                  :src="list"
                  style="width: 100px; height: 100px"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中
                    <span class="dot">...</span>
                  </div>
                  <div slot="error" class="image-slot"></div>
                </el-image>
              </span>
            </el-row>
          </div>
          <div v-else-if="item.label == '沟通视频'">
            <div v-if="row.screenshot_type == 2">
              <span v-for="(list, index) in row[item.prop]" :key="index">
                <video
                  controls
                  :src="list"
                  style="width: 100%; height: 100px"
                />
              </span>
            </div>
          </div>
          <div v-else-if="item.label == '沟通语音'">
            <div v-if="row.screenshot_type == 3">
              <p>{{ row.audio_time }}s</p>
              <a href="javascript:;" @click="showVideoText(row)">语音转文字</a>
              <br />
              <a @click="playVideo(row[item.prop])">播放</a>
            </div>
          </div>
          <div v-else-if="item.label == '沟通语音-图片说明'">
            <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
              <span v-for="(list, index) in row[item.prop]" :key="index">
                <el-image
                  :preview-src-list="[list]"
                  :src="list"
                  style="width: 100px; height: 100px"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中
                    <span class="dot">...</span>
                  </div>
                  <div slot="error" class="image-slot"></div>
                </el-image>
              </span>
            </el-row>
          </div>
          <div v-else-if="item.label == '提审头像'">
            <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
              <el-image
                :preview-src-list="[row.then_avatar]"
                :src="row.then_avatar"
                style="width: 50px; height: 50px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">暂无头像</div>
              </el-image>
            </el-row>
          </div>
          <div v-else-if="item.label == '实时头像'">
            <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
              <el-image
                :preview-src-list="[row.avatar]"
                :src="row.avatar"
                style="width: 50px; height: 50px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">暂无头像</div>
              </el-image>
            </el-row>
          </div>
          <!--          <div v-else-if="item.label=='相册'">
            <el-image
              style="width: 100px; height: 100px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>-->
          <div v-else-if="item.label == '需求分类'">
            <el-tag v-show="row[item.prop] == 1">选品需求</el-tag>
            <el-tag v-show="row[item.prop] == 2" type="success">
              会议需求
            </el-tag>
          </div>
          <div v-else-if="item.label == '选品方案预览'">
            <!--            <el-image
              v-show="row.sel_status>=2"
              style="width: 100px; height: 100px"
              :src="row.plan_qr"
              :preview-src-list="[row.plan_qr]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>-->
            <vue-qr
              v-show="row.sel_status >= 2"
              :margin="10"
              :size="100"
              :text="row.plan_qr"
            />
            <p v-show="row.sel_status >= 2">请用企业微信扫码</p>
          </div>
          <div v-else-if="item.label == '信息复核'">
            <el-button type="text" @click="handleCheckinfo(row)">
              查看
            </el-button>
          </div>

          <div v-else-if="item.label == '级别'">
            <el-select
              v-model="row[item.prop]"
              :disabled="row.status != 2"
              @change="handlerlevel($event, row)"
            >
              <el-option label="无级别" value="0" />
              <el-option label="一级（小商）" value="1" />
              <el-option label="二级（中商）" value="2" />
              <el-option label="三级（大商）" value="3" />
            </el-select>
          </div>
          <div v-else-if="item.label == '选品方案'">
            <el-tag v-show="row[item.prop] == 0">不可创建</el-tag>
            <el-button
              v-show="row[item.prop] == 1"
              type="text"
              @click="handlerplan(row)"
            >
              待创建
            </el-button>
            <el-button
              v-show="row[item.prop] == 2"
              type="text"
              @click="handlerplan(row)"
            >
              已创建可编辑预览
            </el-button>
            <el-button
              v-show="row[item.prop] == 3"
              type="text"
              @click="handlerplan(row)"
            >
              已被查看
            </el-button>
            <el-button
              v-show="row[item.prop] == 4"
              type="text"
              @click="handlerplan(row)"
            >
              已确认
            </el-button>
            <el-button
              v-show="row[item.prop] > 1"
              type="text"
              @click="handlerfasong(row)"
            >
              {{ row.is_send == 1 ? '已发送' : '发送' }}
            </el-button>
          </div>
          <div v-else-if="item.label == '云会议'">
            <el-tag v-show="row[item.prop] == 0">不可创建</el-tag>
            <el-button
              v-show="row[item.prop] == 1"
              type="text"
              @click="handlerplan(row)"
            >
              待创建
            </el-button>
            <el-button
              v-show="row[item.prop] == 2"
              type="text"
              @click="handlerplan(row)"
            >
              已创建可查看
            </el-button>
          </div>
          <div v-else-if="item.label == '已指定店铺'">
            <div v-for="list in row.this_mall_name" style="margin-bottom: 10px">
              {{ list }}
            </div>
          </div>
          <div v-else-if="item.label == '不匹配店铺'">
            <div
              v-for="list in row.no_match_mall_name"
              style="margin-bottom: 10px"
            >
              {{ list }}
            </div>
          </div>
          <div v-else-if="item.label == '是否云交会用户'">
            {{ row[item.prop] == 1 ? '是' : '不是' }}
          </div>
          <div v-else-if="item.label == '经销商身份'">
            <span v-if="row[item.prop] == '审'" style="color: #f00">
              {{ row[item.prop] }}
            </span>
            <template v-else>
              <el-popover trigger="hover">
                {{ row.identity_reason }}
                <template #reference>
                  <span>{{ row[item.prop] }}</span>
                </template>
              </el-popover>
            </template>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <!-- 未失效 且 未过期 -->
          <div v-if="row.is_invalid == 1 && row.is_expire != 2">
            <!-- status 1 待审核  status -1 待定-->
            <div v-if="row.status == 1 || row.status == -2">
              <el-button
                size="small"
                type="text"
                @click.native.prevent="handlerexamine(row)"
              >
                拒绝
              </el-button>
              <el-popconfirm
                title="确认审核通过？"
                @confirm="handlertong(row, 2)"
              >
                <el-button
                  size="small"
                  style="margin: 10px"
                  slot="reference"
                  type="text"
                >
                  通过
                </el-button>
              </el-popconfirm>
              <!-- <el-popconfirm
                title="确认审核待定？"
                @confirm="handlertong(row, -1)"
              >
                <el-button
                  size="small"
                  style="margin: 10px"
                  slot="reference"
                  type="text"
                >
                  待定
                </el-button>
              </el-popconfirm> -->
            </div>

            <div v-if="row.status == 2">
              <el-popconfirm title="确认失效？" @confirm="handlertong(row, 4)">
                <el-button
                  size="small"
                  style="margin: 10px"
                  slot="reference"
                  type="text"
                >
                  失效
                </el-button>
              </el-popconfirm>
            </div>
            <el-button
              v-if="row.status == 1"
              size="mini"
              style="margin: 10px"
              type="text"
              @click="handlerperfect(row)"
            >
              待完善
            </el-button>
            <el-button
              v-if="row.status == -2"
              size="mini"
              style="margin: 10px"
              type="text"
              @click="handlerEditperfect(row)"
            >
              编辑
            </el-button>
            <!-- <el-button
              v-if="row.status == -1 || row.status == 1"
              size="mini"
              style="margin: 10px"
              type="text"
              @click="appointMall(row)"
            >
              {{ row.is_this_mall == 0 ? '指定店铺' : '已指定店铺' }}
            </el-button> -->
            <el-button
              v-if="row.status == -1 || row.status == 1"
              size="mini"
              style="margin: 10px"
              type="text"
              @click="disappointMall(row)"
            >
              不匹配店铺
            </el-button>

            <!-- <el-button
              v-if="row.status == -1 || row.status == 1"
              size="mini"
              style="margin: 10px"
              type="text"
              @click="disappointPaymentMall(row)"
            >
              不匹配所有付费店铺
            </el-button> -->
          </div>
          <!-- <el-button
            size="mini"
            style="margin: 10px"
            type="text"
            @click="changeCustomer(row.id)"
          >
            客户画像
          </el-button> -->
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click="changeLog(row)"
          >
            修改记录
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <selection ref="select" @getlist="handlerInquire" />
    <Refusereason ref="refusereason" :rowid="rowid" @getlist="handlerInquire" />
    <!-- <Customer ref="customer" /> -->
    <el-dialog
      v-loading="loading"
      append-to-body
      title="编辑客户画像"
      :visible.sync="showCustomer"
      width="50%"
    >
      <div>
        <el-form ref="form" label-width="150px" :model="customerInfo">
          <el-form-item label="姓名">
            <el-input
              v-model="customerInfo.name"
              placeholder="请输入姓名"
              style="width: 200px"
              type="text"
            />
          </el-form-item>
          <el-form-item label="客户电话">
            <el-input v-model="customerInfo.telephone" type="textarea" />
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input v-model="customerInfo.corp_name" type="textarea" />
          </el-form-item>
          <el-form-item label="行业所在地区" prop="pid">
            <el-select
              v-model="customerInfo.pid"
              clearable
              placeholder="省"
              style="width: 160px"
              @change="handlerpro"
            >
              <el-option
                v-for="(list, index) in provinceselect"
                :key="index"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
            <el-select
              v-model="customerInfo.cid"
              clearable
              placeholder="市"
              style="width: 160px"
            >
              <el-option
                v-for="list in aceselect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="年营业额（万元）">
            <el-input
              v-model="customerInfo.biz_trade"
              style="width: 150px"
              type="number"
            />
          </el-form-item>
          <el-form-item label="终端网点数">
            <el-input v-model="customerInfo.biz_terminal_network" />
          </el-form-item>
          <el-form-item label="代理品牌数">
            <el-input v-model="customerInfo.biz_proxy" />
          </el-form-item>
          <el-form-item label="代理知名品牌">
            <el-input v-model="customerInfo.biz_proxy_three" />
          </el-form-item>
          <el-form-item label="代理品牌">
            <el-input v-model="customerInfo.proxy" />
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="customerInfo.remark" type="textarea" />
          </el-form-item>
          <el-form-item label="渠道">
            <el-checkbox-group v-model="qdchannellist">
              <el-checkbox
                v-for="(item, index) in qdcheckList"
                :key="index"
                :label="item.id"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="品类">
            <el-checkbox-group v-model="plcatelist">
              <el-checkbox
                v-for="(pl, index) in plcheckList"
                :key="index"
                :label="pl.id"
              >
                {{ pl.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" style="margin: 0 auto">
          <el-button @click="showCustomer = false">取 消</el-button>
          <el-button type="primary" @click="handlersave">确 定</el-button>
        </span>
      </div>
    </el-dialog>
    <AppointMall ref="appointmall" :rowid="rowid" @getlist="handlerInquire" />
    <DisappointMall
      ref="disappointmall"
      :rowid="rowid"
      @getlist="handlerInquire"
    />
    <ChangeLog
      ref="changelog"
      :rowid="rowid"
      :username="username"
      @getlist="handlerInquire"
    />
    <Checkinfo ref="checkinfo" />
    <Noperfect ref="noperfect" :rowid="rowid" @getlist="handlerInquire" />
    <Editnoperfect
      ref="editnoperfect"
      :rowid="rowid"
      @getlist="handlerInquire"
    />
    <el-dialog
      :visible="isShowVideoText"
      append-to-body
      width="40%"
      @close="closeVideoText"
    >
      <!-- <div v-if="videoText.length == 0">未获取到语音</div> -->
      <div>{{ videoText }}</div>
    </el-dialog>
    <!-- <div v-if="isShowPlayVideo">
      <audio autoplay>
        <source
          src="https://video.httoutiao.com/d808b864vodcq1301367265/de6edb581397757890389361373/BIJ5FW7xsgoA.m4a"
        />
        <source
          src="https://video.httoutiao.com/d808b864vodcq1301367265/de6edb581397757890389361373/BIJ5FW7xsgoA.m4a"
        />
        您的浏览器不支持 audio 元素。
      </audio>
    </div> -->
    <!-- 方案一 弹窗显示语音播放器 -->
    <el-dialog
      :visible="isShowVideoPlayer"
      append-to-body
      width="40%"
      @close="closeVideoPlayer"
    >
      <div><audio ref="videoplayer" :src="videoUrl" controls></audio></div>
    </el-dialog>
    <!-- 方案二 本页面播放-->
    <!-- <audio ref="audioPlayer" style="display: none"></audio> -->
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import Selection from '@/views/pages/review/clouddemand/components/Selection'
  import Refusereason from '@/views/pages/review/clouddemand/components/RefuseReason'
  import ChangeLog from './components/Changelog.vue'
  import vueQr from 'vue-qr'
  import dateFormat from '@/utils/Timeformat'
  import * as excel from '@/utils/excel'
  // import Customer from '@/views/pages/review/clouddemand/components/Customer'
  // import Customer from 'components/Customer'
  import AppointMall from './components/AppointMall'
  import DisappointMall from './components/DisappointMall'
  import Checkinfo from './components/Checkinfo'
  import Noperfect from './components/NoPerfect'
  import Editnoperfect from './components/EditNoperfect'
  export default {
    name: 'Index',
    components: {
      Selection,
      vueQr,
      Refusereason,
      ChangeLog,
      AppointMall,
      DisappointMall,
      Checkinfo,
      Noperfect,
      Editnoperfect,
    },
    data() {
      return {
        loading: false,
        listLoading: false,
        form: {
          source: '',
          name: '',
          nickname: '',
          status: 1, //-2待完善-1待定1未审核2已通过3未通过4已失效5已过期6匹配完
          telephone: '',
          root_cat_id: '',
          cat_id: '',
          company: '',
          tibao_shenhe_name: '',
          page: 1,
          limit: 10,
          tibao_name: '',
          shenhe_name: '',
          time_type: 2,
          start_time: dateFormat(new Date() - 3600 * 1000 * 24 * 30),
          end_time: dateFormat(new Date()),
          pid: '',
          cid: '',
        },
        timelist: [
          dateFormat(new Date() - 3600 * 1000 * 24 * 30),
          dateFormat(new Date()),
        ],
        mallSelsect: [],
        winepricelist: [],
        matchtypelist: [
          {
            id: 1,
            name: '需求上传',
          },
          {
            id: 2,
            name: '指定店铺云会议',
          },
        ],
        timetypelist: [
          {
            id: 1,
            name: '审核时间',
          },
          {
            id: 2,
            name: '提报时间',
          },
        ],
        xuqiutypelist: [
          {
            id: 1,
            name: '找产品代理',
          },
          {
            id: 2,
            name: '找代工',
          },
        ],
        showSelsect: [
          {
            id: -2,
            name: '待完善',
          },
          // {
          //   id: -1,
          //   name: '待定',
          // },
          {
            id: 1,
            name: '待审核',
          },
          {
            id: 2,
            name: '审核通过',
          },
          {
            id: 3,
            name: '审核拒绝',
          },
          {
            id: 4,
            name: '已失效',
          },
          {
            id: 5,
            name: '已过期',
          },
          {
            id: 6,
            name: '匹配完',
          },
        ],
        checkselect: [
          {
            id: 1,
            name: '选品需求',
          },
          {
            id: 2,
            name: '会议需求',
          },
        ],
        sourcelist: [
          {
            id: '',
            name: '全部',
          },
          {
            id: 1,
            name: '企微侧边栏',
          },
          {
            id: 2,
            name: '24春糖调查',
          },
        ],
        genrelist: [
          {
            id: 1,
            name: '品类需求',
          },
          {
            id: 2,
            name: '指定店铺需求',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '来源',
            prop: 'source_text',
            width: '',
          },
          {
            label: '经销商身份',
            prop: 'identity',
            width: '',
          },
          {
            label: '昵称',
            prop: 'external_user_name',
            width: '',
          },
          // {
          //   label: '提审头像',
          //   prop: 'then_avatar',
          //   width: '',
          // },
          {
            label: '实时头像',
            prop: 'avatar',
            width: '',
          },
          {
            label: '需求客户',
            prop: 'name',
            width: '',
          },

          {
            label: '一级品类',
            prop: 'cat_pname',
            width: '',
          },
          {
            label: '二级品类',
            prop: 'cat_name',
            width: '',
          },
          {
            label: '价格带',
            prop: 'wine_price',
            width: '',
          },
          {
            label: '需求详情',
            prop: 'detail',
            width: '115',
          },
          // {
          //   label: '付费企业推荐',
          //   prop: 'rec',
          //   width: '115',
          // },
          {
            label: '沟通截图',
            prop: 'screenshot',
            width: '100',
          },
          {
            label: '沟通视频',
            prop: 'screenshot',
            width: '150',
          },
          {
            label: '沟通语音',
            prop: 'audio_url',
            width: '150',
          },
          {
            label: '沟通语音-图片说明',
            prop: 'audio_img',
            width: '150',
          },
          {
            label: '需求形式',
            prop: 'type_txt',
            width: '150',
          },
          // {
          //   label: '沟通记录',
          //   prop: 'detail',
          //   width: '',
          // },
          {
            label: '客户电话',
            prop: 'telephone',
            width: '115',
          },

          {
            label: '公司名称',
            prop: 'company',
            width: '160',
          },
          {
            label: '行业所在地区',
            prop: 'area',
            width: '',
          },
          {
            label: '渠道',
            prop: 'channel_name',
            width: '',
          },
          {
            label: '年营业额（万元）',
            prop: 'trade',
            width: '',
          },
          {
            label: '终端网点数',
            prop: 'terminal_network',
            width: '',
          },
          {
            label: '代理品牌数',
            prop: 'proxy',
            width: '',
          },
          {
            label: '代理知名品牌',
            prop: 'proxy_three',
            width: '',
          },
          // {
          //   label: '备注',
          //   prop: 'remark',
          //   width: '',
          // },
          {
            label: '提报人',
            prop: 'staff_user_name',
            width: '',
          },
          {
            label: '提报时间',
            prop: 'created',
            width: '',
          },

          // {
          //   label: '级别',
          //   prop: 'level',
          //   width: '110px',
          // },
          // {
          //   label: '选品方案',
          //   prop: 'sel_status',
          //   width: '160',
          // },
          // {
          //   label: '选品方案预览',
          //   prop: 'plan_qr',
          //   width: '160',
          // },
          // {
          //   label: '云会议',
          //   prop: 'meeting_status',
          //   width: '120',
          // },
          // {
          //   label: '提示',
          //   prop: 'tips',
          //   width: '',
          // },
          // {
          //   label: '信息复核',
          //   prop: 'tips',
          //   width: '',
          // },
          {
            label: '审核人姓名',
            prop: 'check_user_name',
            width: '',
          },
          {
            label: '审核时间',
            prop: 'check_time',
            width: '',
          },
          {
            label: '需求类型',
            prop: 'genre_text',
            width: '',
          },

          {
            label: '已指定店铺',
            prop: 'this_mall_name',
            width: '',
          },
          {
            label: '服务人员',
            prop: 'service_name',
            width: '',
          },
          {
            label: '不匹配店铺',
            prop: 'no_match_mall_name',
            width: '',
          },
          {
            label: '匹配形式',
            prop: 'match_way_txt',
            width: '',
          },
          {
            label: '是否云交会用户',
            prop: 'is_yjh',
            width: '',
          },
          {
            label: '是否被查看过',
            prop: 'be_view',
            width: '',
          },
          {
            label: '状态',
            prop: 'status_text',
            width: '',
          },
        ],
        total: 0,
        cateselect: [],
        catselect: [],
        height: 600,
        showRefuseReason: false,
        rowid: '',
        userid: '', //当前用户id 张老师正式环境797 仅次用户可看到导出按钮
        showCustomer: false,
        formcustomer: {
          name: '',
          pid: '',
          cid: '',
        },
        customerInfo: {
          pid: '',
          cid: '',
        },
        provinceselect: [],
        aceselect: [],
        plcheckList: [],
        qdcheckList: [],
        plcatelist: [],
        qdchannellist: [],
        username: '', //修改记录需要的用户昵称
        meetingStatusList: [
          {
            id: 1,
            name: '不可创建',
          },
          {
            id: 2,
            name: '待创建',
          },
          {
            id: 3,
            name: '已创建',
          },
          {
            id: 4,
            name: '已完成',
          },
          {
            id: 5,
            name: '无效',
          },
        ],
        selStatusList: [
          {
            id: 1,
            name: '不可创建',
          },
          {
            id: 2,
            name: '待创建',
          },
          {
            id: 3,
            name: '已创建未发送',
          },
          {
            id: 4,
            name: '已发送未查看',
          },
          {
            id: 5,
            name: '已查看未确认',
          },
          {
            id: 6,
            name: '已确认',
          },
        ],
        beviewlist: [
          {
            name: '是',
          },
          {
            name: '否',
          },
        ],
        citylist: [], //市列表
        isShowVideoText: false, //是否显示转文字弹窗
        videoText: '', //转文字的语音
        isShowVideoPlayer: false, //是否显示语音播放控件
        videoUrl: '',
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      plcatelist(v) {
        console.log('品类，', v)
      },
      qdchannellist(v) {
        console.log('渠道，', v)
      },
      'customerInfo.pid'(val) {
        console.log('省id', val)
      },
    },
    created() {
      // this.getheight()
    },
    mounted() {
      this.userid = localStorage.getItem('userid')
      this.handlerInquire()
      this.handlerSelect()
      this.getplList()
      this.getqdList()
      this.getWinePrice()
      this.getProvince()
    },
    methods: {
      // 语音转文字
      showVideoText(item) {
        this.isShowVideoText = true
        if (item.audio_text.length == 0) {
          this.videoText = '正在获取信息，请稍后…'
          postAction('/audit/biz-clue/get-transres', { id: item.id }).then(
            (res) => {
              if (res.request_code == 200) {
                this.videoText = res.data
              } else {
                this.videoText = '未获取到语音'
              }
            }
          )
        } else {
          this.videoText = item.audio_text
        }
      },
      // 关闭语音转文字弹窗
      closeVideoText() {
        this.isShowVideoText = false
      },
      //播放语音
      playVideo(url) {
        this.isShowVideoPlayer = true
        this.videoUrl = url
        // const audioPlayer = this.$refs.audioPlayer
        // audioPlayer.src = url
        // audioPlayer.play()
      },
      // 关闭播放语音弹窗
      closeVideoPlayer() {
        this.isShowVideoPlayer = false
        const videoPlayer = this.$refs.videoplayer
        videoPlayer.pause()
      },
      // 编辑待完善信息
      handlerEditperfect(row) {
        console.log('====0', row)
        this.$refs.editnoperfect.showDialog = true
        this.$refs.editnoperfect.handlercat(row.root_cat_id)
        this.$refs.editnoperfect.handlerDetail(row)
        this.$refs.editnoperfect.form = JSON.parse(JSON.stringify(row))
        this.$refs.editnoperfect.form.img_type = Number(row.img_type)

        this.rowid = row.id
      },
      // 待完善
      handlerperfect(row) {
        this.$confirm('是否设置待完善？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$refs.noperfect.showDialog = true
          this.rowid = row.id
        })
      },
      // 信息复核弹窗
      handleCheckinfo(row) {
        this.$refs.checkinfo.showCheckinfo = true
        this.$refs.checkinfo.handlerlist(row)
      },
      // 获取价格带数据
      getWinePrice() {
        postAction('/audit/biz-clue/wine-price', {}).then((res) => {
          console.log('价格带-', res)
          if (res.request_code == 200) {
            this.winepricelist = res.data
          }
        })
      },
      // 指定店铺
      appointMall(row) {
        this.$refs.appointmall.showAppointMall = true
        this.$refs.appointmall.handlerlist(row)
        this.rowid = row.id
      },
      // 不匹配店铺
      disappointMall(row) {
        this.$refs.disappointmall.showdisAppointMall = true
        this.$refs.disappointmall.handlerlist(row)
        this.rowid = row.id
      },
      //不匹配所有付费店铺
      disappointPaymentMall(row) {
        if (row.is_no_match_all_fee_mall == 1) {
          this.$message.info('已设置不匹配所有付费店铺')
          return false
        } else {
          this.$confirm('是否不匹配所有付费店铺？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              postAction('/audit/biz-clue/nomatch-feemall', {
                id: row.id,
              }).then((res) => {
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.handlerInquire()
              })
            })
            .catch(() => {})
        }
      },
      // 修改记录
      changeLog(row) {
        console.log('昵称--------------', row)
        this.$refs.changelog.showChangeLog = true
        this.currentrowid = row.id
        this.username = row.external_user_name
        this.rowid = row.id
        this.username = row.external_user_name
        this.$refs.changelog.handlerlist('info', row.id, row.external_user_name)
      },
      // 修改客户画像
      changeCustomer(id) {
        this.showCustomer = true
        this.qdchannellist = []
        this.plcatelist = []

        getAction('/audit/biz-clue/draw', { id }).then((res) => {
          if (res.request_code == 200) {
            this.customerInfo = res.data
            this.customerInfo.id = id
            this.customerInfo.pid = res.data.pid.toString()
            this.customerInfo.cid = res.data.cid.toString()
            if (this.customerInfo.cate_id.length > 0) {
              this.plcatelist = this.customerInfo.cate_id.split(',')
            }
            if (this.customerInfo.channel_id.length > 0) {
              this.qdchannellist = this.customerInfo.channel_id.split(',')
            }
            this.getProvince()
            this.getAreaList(res.data.pid)
          }
        })
      },

      handlersave() {
        this.customerInfo.channel_id = this.qdchannellist.toString()
        this.customerInfo.cate_id = this.plcatelist.toString()
        postAction('/audit/biz-clue/edit-draw', this.customerInfo).then(
          (res) => {
            if (res.request_code == 200) {
              this.$message.success('保存成功')
              this.showCustomer = false
              this.handlerInquire()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      // 获取品类列表
      getplList() {
        getAction('/audit/biz-clue/draw-cate').then((res) => {
          if (res.request_code == 200) {
            this.plcheckList = res.data
          }
        })
      },
      // 获取渠道列表
      getqdList() {
        getAction('/audit/biz-clue/draw-channel').then((res) => {
          if (res.request_code == 200) {
            this.qdcheckList = res.data
          }
        })
      },
      handleProChange(e) {
        this.form.cid = ''
        this.getCityList(e)
      },
      handlerpro(e) {
        this.customerInfo.cid = ''
        // this.aceselect = []
        console.log('-1--1-1', this.aceselect)
        this.getAreaList(e)
      },
      // 获取省份
      async getProvince() {
        // const { data } = await getAction('/user/index/area', { pid: 0 })
        // this.areaselect = data

        const provin = await getAction('/user/index/area', { pid: 0 })
        this.provinceselect = provin.data
        console.log('省份')
      },
      // 获取市区
      async getAreaList(e) {
        if (this.customerInfo.cid == 0) {
          this.customerInfo.cid = ''
        }
        const citydata = await getAction('/user/index/area', { pid: e })
        this.aceselect = citydata.data
      },
      // 获取市
      async getCityList(e) {
        const citydata = await getAction('/user/index/area', { pid: e })
        this.citylist = citydata.data
      },
      getheight() {
        let divcont = document.querySelector('.index-container')
        this.height = divcont.clientHeight - 320
        console.log(divcont.clientHeight, this.height)
      },
      handlerExport() {
        getAction('/audit/biz-clue/export-lists', this.form).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: '需求列表',
          })
        })
      },
      handlerfasong(row) {
        postAction('/audit/biz-clue/send-plan', { id: row.id }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        })
      },
      handlercloud(row) {
        this.$router.push({ path: '/review/CloudConf', query: { id: row.id } })
      },
      handlerlevel(event, row) {
        postAction('/audit/biz-clue/level', { id: row.id, level: event }).then(
          (res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerlist()
          }
        )
      },
      handlerplan(row) {
        this.$refs.select.showSelection = true
        this.$refs.select.form.biz_clue_id = row.id
        this.$refs.select.handlerlist()
        this.$refs.select.title = row.external_user_name
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlerexamine(row) {
        this.$confirm('是否确认审核不通过？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          // 填写拒绝理由
          // this.showRefuseReason = true
          this.$refs.refusereason.showRefuseReason = true
          this.rowid = row.id
          // postAction('/audit/biz-clue/audit', { id: row.id, status: 3 }).then(
          //   (res) => {
          //     this.$message({
          //       type: 'success',
          //       message: res.msg,
          //     })
          //     this.handlerInquire()
          //   }
          // )
        })
      },
      handlertong(row, status) {
        postAction('/audit/biz-clue/audit', {
          id: row.id,
          status: status,
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerlist() {
        this.listLoading = true
        postAction('/audit/biz-clue/lists', this.form)
          .then((res) => {
            this.tabledata = res.data
            this.total = Number(res.totalCount)
          })
          .finally(() => {
            this.listLoading = false
          })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
