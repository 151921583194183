var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "div",
        { staticClass: "head", staticStyle: { "margin-bottom": "15px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleredit()
                },
              },
            },
            [_vm._v("新增")]
          ),
          _c("el-input", {
            staticStyle: {
              width: "150px",
              "margin-left": "100px",
              "margin-right": "20px",
            },
            attrs: { placeholder: "套餐版本" },
            model: {
              value: _vm.version,
              callback: function ($$v) {
                _vm.version = $$v
              },
              expression: "version",
            },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.search } },
            [_vm._v("查询")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tablelist, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "套餐名称", width: "" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "version_name",
                      label: "套餐版本",
                      width: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "price", label: "套餐价格", width: "" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "time",
                      label: "套餐默认时长（天）",
                      width: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "状态", width: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.status == 0
                              ? _c("span", [_vm._v("禁用")])
                              : _vm._e(),
                            row.status == 1
                              ? _c("span", [_vm._v("启用")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "操作", width: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleredit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlereinfo(scope.row)
                                  },
                                },
                              },
                              [_vm._v("设置套餐内容")]
                            ),
                            scope.row.status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerswitch(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("启用")]
                                )
                              : _vm._e(),
                            scope.row.status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerswitch(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("禁用")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editpackage == 1 ? "编辑" : "新增",
            visible: _vm.showedit,
            width: "50%",
            "before-close": _vm.handleclose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showedit = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属分类：", prop: "version" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.version,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "version", $$v)
                        },
                        expression: "form.version",
                      },
                    },
                    _vm._l(_vm.versionlist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "套餐名称：", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "套餐价格：", prop: "price" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "单位元，请输入正整数或0" },
                    model: {
                      value: _vm.form.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "price", $$v)
                      },
                      expression: "form.price",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "套餐默认时长：", prop: "time" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "单位天，请输入正整数" },
                    model: {
                      value: _vm.form.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "time", $$v)
                      },
                      expression: "form.time",
                    },
                  }),
                  _c("e", { staticClass: "tips" }, [
                    _vm._v("如有未执行完的服务，则套餐自动延期"),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "套餐结束：", prop: "end_condition" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.end_condition,
                        callback: function ($$v) {
                          _vm.end_condition = $$v
                        },
                        expression: "end_condition",
                      },
                    },
                    _vm._l(_vm.meallist, function (item) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.id,
                          attrs: {
                            label: item.id,
                            disabled: item.name == "套餐时长",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleclose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("package-info", { ref: "packageInfo" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }