var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-date-picker", {
        staticStyle: { "margin-right": "10px" },
        attrs: {
          type: "daterange",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "value-format": "yyyy-MM-dd",
        },
        model: {
          value: _vm.time,
          callback: function ($$v) {
            _vm.time = $$v
          },
          expression: "time",
        },
      }),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleSearch } },
        [_vm._v("查询")]
      ),
      _c(
        "el-button",
        { attrs: { type: "success" }, on: { click: _vm.handlerExport } },
        [_vm._v("导出")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: { border: "", data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "total_pv", label: "总浏览次数PV" },
          }),
          _c("el-table-column", {
            attrs: { prop: "total_uv", label: "总访客数UV" },
          }),
          _c("el-table-column", {
            attrs: { prop: "share_friend", label: "员工分享好友次数" },
          }),
          _c("el-table-column", {
            attrs: { prop: "share_circle", label: "员工分享朋友圈次数" },
          }),
          _c("el-table-column", {
            attrs: { prop: "invite_uv", label: "邀请进入的UV" },
          }),
          _c("el-table-column", {
            attrs: { prop: "invite_pv", label: "邀请进入的PV" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }