import { render, staticRenderFns } from "./association.vue?vue&type=template&id=f10a83a2&scoped=true&"
import script from "./association.vue?vue&type=script&lang=js&"
export * from "./association.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f10a83a2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\shangjiaGit\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f10a83a2')) {
      api.createRecord('f10a83a2', component.options)
    } else {
      api.reload('f10a83a2', component.options)
    }
    module.hot.accept("./association.vue?vue&type=template&id=f10a83a2&scoped=true&", function () {
      api.rerender('f10a83a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/systemSet/theme/components/association.vue"
export default component.exports