<template>
  <!-- <div></div> -->
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input v-model="form.title" clearable placeholder="标题" />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%"
      :row-key="getRowKey"
      @selection-change="setSelectRows"
      ref="ElTable"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :reserve-selection="true"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '来源'">头条拉取</div>
          <div v-else-if="item.label == '拉取状态'">
            {{ row[item.prop] == 1 ? '已拉取' : '未拉取' }}
          </div>
          <div v-else-if="item.label == '封面图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>

          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            v-if="row.is_push == 1"
          >
            已分拣
          </el-button>
          <el-button
            v-else
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            分拣
          </el-button>
          <!-- <el-popconfirm title="确认要删除吗？" @confirm="deleteRow(row)">
            <el-button
              style="margin-left: 10px"
              slot="reference"
              size="small"
              type="text"
            >
              删除
            </el-button>
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" align="middle" justify="space-between">
      <el-col :span="6">
        <el-button type="warning" @click="handlerPulling">批量拉取</el-button>
      </el-col>
      <el-col :span="12">
        <el-pagination
          background
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          style="text-align: center; margin-top: 10px"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'Index',
    data() {
      return {
        form: {
          is_check: -1,
          class_id: '',
          name: '',
          mobile: '',
          province_id: '',
          page: 1,
          limit: 10,
          type: 2, //1逛逛 2热点
        },
        checkselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未审',
          },
          {
            id: 1,
            name: '已审',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'article_id',
            width: '80',
          },
          {
            label: '封面图',
            prop: 'lit_pic',
            width: '',
          },
          {
            label: '文章标题',
            prop: 'title',
            width: '220px',
          },

          {
            label: '来源',
            prop: 'source_id',
            width: '80',
          },
          {
            label: '原文时间',
            prop: 'created',
            width: '',
          },
        ],
        total: 0,
        areaselect: [],
        classselect: [],
        selectRows: [],
        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      deleteRow(row) {
        console.log('删除ID，', row)
        postAction('/api/stroll/article/delete', { id: row.article_id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handleredit(row) {
        postAction('/api/stroll/article/la-qu', {
          ids: [row.article_id],
          type: 2,
          source_id: 2,
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerlist()
        })
      },
      handlerPulling() {
        if (this.selectRows.length > 0) {
          postAction('/api/stroll/article/la-qu', {
            ids: this.selectRows,
          }).then((res) => {
            this.$refs.ElTable.clearSelection()
            this.selectRows = []
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请先选择数据',
          })
        }
      },
      // async handlerSelect() {
      //   const { data } = await getAction('/user/index/area', { pid: 0 })
      //   this.areaselect = data
      //   await getAction('/user/index/cate').then((res) => {
      //     this.classselect = res.data
      //   })
      // },
      getRowKey(row) {
        return row.article_id
      },
      setSelectRows(val) {
        this.selectRows = val.map((list) => {
          return list.article_id
        })
      },
      handlerlist() {
        getAction('/api/stroll/article/ht-lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
