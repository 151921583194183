<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input v-model="form.mall_id" clearable placeholder="店铺ID" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.mall_name" clearable placeholder="店铺名称" />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company_name"
          clearable
          placeholder="公司名称"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.pay_type"
          clearable
          filterable
          placeholder="当前套餐"
          style="width: 160px"
        >
          <el-option
            v-for="list in mealSelect"
            :key="list.id"
            :label="list.title"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.phone"
          clearable
          placeholder="店铺管理员手机号"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.yewu"
          clearable
          filterable
          placeholder="业务员/邀请人/旧后台业务员"
          style="width: 220px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.userid"
            :label="list.htyun_name"
            :value="list.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_goods"
          clearable
          filterable
          placeholder="是否有通过产品"
          style="width: 160px"
        >
          <el-option
            v-for="list in isgoodsSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="首次产品通过时间">
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="最近一次操作时间">
        <el-date-picker
          v-model="time2"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else-if="item.label == '最近一次操作日志'">
            <a
              style="cursor: pointer"
              v-if="row.last_op_type < 3"
              @click="jumpDetail(row)"
            >
              {{ row[item.prop] }}
            </a>
            <span v-else>{{ row[item.prop] }}</span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <browse ref="browse" />
    <info ref="info" @getlist="handlerInquire" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import browse from '@/views/pages/enterprise/goodsInfor/components/browse'
  import info from '@/views/pages/enterprise/information/components/info'
  export default {
    name: 'Index',
    components: { browse, info },
    data() {
      return {
        form: {
          company_name: '',
          start_time: '',
          end_time: '',
          first_start_time: '',
          first_end_time: '',
          last_start_time: '',
          last_end_time: '',
          mall_name: '',
          meal_type: '',
          page: 1,
          limit: 10,
        },
        time: [],
        time2: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '店铺ID',
            prop: 'mall_id',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },

          {
            label: '公司名称',
            prop: 'company_name',
            width: '',
          },

          {
            label: '当前套餐',
            prop: 'title',
            width: '',
          },
          {
            label: '是否有通过产品',
            prop: 'is_goods',
            width: '',
          },
          {
            label: '首次发布产品时间',
            prop: 'first_goods_time',
            width: '',
          },

          {
            label: '业务对接人',
            prop: 'service_name',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'invite_id',
            width: '',
          },
          {
            label: '业务员(旧后台)',
            prop: 'yewu',
            width: '',
          },

          {
            label: '管理员手机号',
            prop: 'phone',
            width: '',
          },
          {
            label: '最近一次操作时间',
            prop: 'last_op_time',
            width: '',
          },
          {
            label: '最近一次操作日志',
            prop: 'last_op_log',
            width: '',
          },
        ],
        mealSelect: [],
        staffSelect: [],
        total: 0,
        isgoodsSelect: [
          {
            id: '0',
            name: '否',
          },
          {
            id: '1',
            name: '是',
          },
        ],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.first_start_time = v[0]
          this.form.first_end_time = v[1]
        } else {
          this.form.first_start_time = ''
          this.form.first_end_time = ''
        }
      },
      time2(v) {
        if (v) {
          this.form.last_start_time = v[0]
          this.form.last_end_time = v[1]
        } else {
          this.form.last_start_time = ''
          this.form.last_end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.getmeal()
      this.handlerSelect()
    },
    methods: {
      jumpDetail(row) {
        if (row.last_op_type == 1) {
          //店铺
          this.$refs.info.form = row
          this.$refs.info.showinfo = true
        } else if (row.last_op_type == 2) {
          //产品
          row.id = row.last_op_goods_id
          this.$refs.browse.handlerinfo(row)
          this.$refs.browse.diashow()
        }
      },
      getmeal() {
        getAction('mall/index/meal').then((res) => {
          console.log('套餐接口', res)
          this.mealSelect = res.data
          this.mealSelect.push({ id: 0, title: '免费店铺' })
        })
      },
      async handlerSelect() {
        const staff = await getAction('/form/form/staff')
        this.staffSelect = staff.data
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist() {
        getAction('/api/form/form/mall-update-tj', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerExport() {
        postAction('/api/form/form/mall-match-list-export', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.data,
              filename: res.data.name,
            })
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
