<script>
import UploadImg from "@/components/uploadImg.vue";
import { nextTick } from "vue";
import { postAction } from "@/api/request";

export default {
  name: "edit",
  components: { UploadImg },
  data() {
    return {
      dialogVisible: false,
      optionsList:[
        {
          value: '1',
          label: "开启"
        },
        {
          value: '2',
          label: "关闭"
        }
      ],
      form: {
        /**
         * 品类名称
         */
        cat_pname: '',
        /**
         * 开关1开2关
         */
        door: '',
        id: '',
        /**
         * 领取弹窗图片
         */
        receive_pic: '',
        /**
         * 福利弹窗图片
         */
        welfare_pic: '',
      },
      rules: {
        door: [
          { required: true, message: "请选择开关", trigger: "blur" },
        ],
        welfare_pic: [
          { required: true, message: "请上传福利弹窗图片", trigger: "blur" },
        ],
        receive_pic: [
          { required: true, message: "请上传领取弹框图片",trigger: "blur" },
        ]
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        // console.log(this.$options.data().form)
        this.form = this.$options.data().form
        this.$refs.form.resetFields();
        this.closeimg('receive_pic')
        this.closeimg('welfare_pic')
      }
    }
  },
  mounted() {
  },
  methods: {
    handlersave(){
      this.$refs.form.validate(valid => {
        if (valid) {
          postAction('/api/system/cat-popup/edit',this.form).then(res=>{
            if(res.request_code==200){
              this.$message({
                type:'success',
                message:res.msg
              })
              this.dialogVisible = false
              this.$emit('getlist')
            }
          })
        } else {
          return false;
        }
      })
    },
    handlerinfo(row){
      this.form = row
      nextTick(()=>{
        this.setImg(row.receive_pic, 'receive_pic')
        this.setImg(row.welfare_pic, 'welfare_pic')
      })
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%">
    <span>
      <el-form ref="form" :model="form" label-width="120px" size="mini" :rules="rules">
        <el-form-item label="品类">
          {{form.cat_pname}}
        </el-form-item>
        <el-form-item label="开关" prop="door">
          <el-select v-model="form.door" placeholder="开关">
          <el-option
            v-for="item in optionsList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="福利弹窗" prop="welfare_pic">
           <upload-img ref="welfare_pic" info-text="尺寸：750*104" :limit="1" @getImgs="getGoods_imgImg($event, 'welfare_pic')"/>
        </el-form-item>
        <el-form-item label="领取弹框图片" prop="receive_pic">
          <upload-img ref="receive_pic" info-text="尺寸：300*300，太小图会虚" :limit="1" @getImgs="getGoods_imgImg($event, 'receive_pic')"/>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<style scoped>

</style>
