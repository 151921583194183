var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { center: "", title: "新增", visible: _vm.addshow, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.addshow = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择店铺" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      disabled: _vm.edit,
                      "fetch-suggestions": _vm.handlermell,
                      placeholder: "请输入店铺名称",
                      "value-key": "mall_name",
                    },
                    on: { select: _vm.handleSelectmall },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "公司名称" } }, [
                _vm._v(" " + _vm._s(_vm.company_name) + " "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "选择套餐版本" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        disabled: _vm.edit,
                        clearable: "",
                        placeholder: "选择套餐版本",
                      },
                      on: { change: _vm.handlerselect },
                      model: {
                        value: _vm.form.version,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "version", $$v)
                        },
                        expression: "form.version",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 1, label: "第一版" } }),
                      _c("el-option", { attrs: { value: 2, label: "第二版" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "套餐内容" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        clearable: "",
                        disabled: _vm.edit,
                        placeholder: "套餐内容",
                      },
                      model: {
                        value: _vm.form.set_meal_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "set_meal_id", $$v)
                        },
                        expression: "form.set_meal_id",
                      },
                    },
                    _vm._l(_vm.mealselect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.title, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "数量" } },
                [
                  _c("el-input-number", {
                    attrs: { disabled: _vm.edit, label: "数量", min: 1 },
                    model: {
                      value: _vm.form.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "业务对接人" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        clearable: "",
                        multiple: "",
                        filterable: "",
                        placeholder: "业务对接人",
                        disabled: _vm.edit,
                      },
                      model: {
                        value: _vm.form.inviteids,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "inviteids", $$v)
                        },
                        expression: "form.inviteids",
                      },
                    },
                    _vm._l(_vm.staffSelect, function (list) {
                      return _c("el-option", {
                        key: list.htyun_name,
                        attrs: {
                          value: list.htyun_name,
                          label: list.htyun_name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "企业服务人员" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "企业服务人员",
                      },
                      model: {
                        value: _vm.form.service_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "service_name", $$v)
                        },
                        expression: "form.service_name",
                      },
                    },
                    _vm._l(_vm.staffSelect, function (list) {
                      return _c("el-option", {
                        key: list.htyun_name,
                        attrs: {
                          value: list.htyun_name,
                          label: list.htyun_name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      maxlength: "50",
                      placeholder: "备注",
                      rows: 4,
                      type: "textarea",
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.addshow = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.edit,
                  expression: "!edit",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.handlersave },
            },
            [_vm._v(" 确 定 ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.edit,
                  expression: "edit",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.handlersaveedit },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }