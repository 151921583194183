<template>
  <el-dialog
    title="扣除云会议"
    :visible.sync="showedit"
    width="1200px">
    <div>
      <el-descriptions :column="3">
        <el-descriptions-item label="云会议总数">{{ Number(showform.setmeal_referrals_num)+Number(showform.give_setmeal_referrals_num)}}</el-descriptions-item>
        <el-descriptions-item label="赠送剩余次数">{{Number(showform.give_surplus_referrals_num)}}</el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">{{Number(showform.surplus_referrals_num)}}</el-descriptions-item>
        <el-descriptions-item label="大商直通车总次数">{{Number(showform.setmeal_through_train_num)+Number(showform.give_setmeal_through_train_num)}}</el-descriptions-item>
        <el-descriptions-item label="赠送剩余次数">
          {{Number(showform.give_surplus_through_train_num)}}
        </el-descriptions-item>
        <el-descriptions-item label="套餐剩余次数">
          {{Number(showform.surplus_through_train_num)}}
        </el-descriptions-item>
        <el-descriptions-item label="本次扣除云会议次数">{{setmeal}}</el-descriptions-item>
        <el-descriptions-item label="本次扣除大商直通车次数">{{surplus}}</el-descriptions-item>
      </el-descriptions>
      <p style="color: red">请认真填写，不可恢复，需谨慎操作</p>
      <div v-for="(item,index) in form.content" :key="index">
        <el-divider></el-divider>
          <el-form ref="form" :model="item" label-width="120px" :rules="rules">
            <el-form-item label="扣除类型" prop="type">
              <el-select v-model="item.type" placeholder="请选择" clearable>
                <el-option
                  :disabled="setmeal>=Number(showform.setmeal_referrals_num)+Number(showform.give_setmeal_referrals_num)"
                  label="云会议"
                  :value="3"/>
                  <el-option
                    :disabled="surplus>=Number(showform.setmeal_through_train_num)+Number(showform.give_setmeal_through_train_num)"
                    label="大商直通车"
                    :value="4"/>
              </el-select>
            </el-form-item>
            <el-form-item label="经销商意愿凭证" prop="prove_img">
              <div style="display: flex;align-items: center">
                <upload-img ref="prove_img" :limit="5" :info-text="''" @getImgs="getGoods_imgImg($event, 'prove_img',index)" :maxSize="100"/>
                <el-tooltip class="item" effect="dark" content="经销商意愿凭证(说明:请上传能证明经销商云会议意愿的截图)" placement="top-start">
                  <i class="el-icon-warning" style="margin-left: 10px"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="会议截图" >
              <div style="display: flex;align-items: center">
                <upload-img ref="meeting_img" :limit="5" :info-text="''" @getImgs="getGoods_imgImg($event, 'meeting_img',index)" :maxSize="100"/>
                <el-tooltip class="item" effect="dark" content="会议截图(说明:请上传会议中的截图，要求显示全部参会人员)" placement="top-start">
                  <i class="el-icon-warning" style="margin-left: 10px"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="会议云录制链接" prop="video_url">
              <div style="display: flex;align-items: center">
              <el-input clearable v-model="item.video_url" placeholder="请输入会议云录制链接"  style="width: 160px;"></el-input>
                <el-tooltip class="item" effect="dark" content="会议云录制链接(说明:请上传腾讯会议云录制文件链接)" placement="top-start">
                  <i class="el-icon-warning" style="margin-left: 10px"></i>
                </el-tooltip>
              </div>
            </el-form-item>
<!--            <el-form-item label="会议明细链接" prop="detail_url">
              <div style="display: flex;align-items: center">
              <el-input clearable v-model="item.detail_url" placeholder="请输入会议明细链接"  style="width: 160px;"></el-input>
                <el-tooltip class="item" effect="dark" content="会议明细链接(说明:请在腾讯会议中生成会议明细，并上传文件链接)" placement="top-start">
                  <i class="el-icon-warning" style="margin-left: 10px"></i>
                </el-tooltip>
              </div>
            </el-form-item>-->
            <el-form-item label="提报人" prop="staff_id">
              <div style="display: flex;align-items: center">
              <el-autocomplete
                style="width: 160px;"
                v-model="item.staff_name"
                :fetch-suggestions="(queryString,cb)=>{handlerstaff(queryString,cb,index)}"
                value-key="staff_name"
                placeholder="请输入提报人"
                @select="(list)=>{handleSelectstaff(list,index)}"
              ></el-autocomplete>
                <el-tooltip class="item" effect="dark" content="提报人(说明:请选择厂商云会议的提报人)" placement="top-start">
                  <i class="el-icon-warning" style="margin-left: 10px"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="经销商" prop="dealer_id">
              <div style="display: flex;align-items: center">
              <el-autocomplete
                style="width: 160px;"
                v-model="item.dealer_name"
                :fetch-suggestions="(queryString,cb)=>{handlerdealer(queryString,cb,index)}"
                value-key="dealer_name"
                placeholder="请输入昵称/手机号"
                @select="(list)=>{handleSelectdealer(list,index)}"
              ></el-autocomplete>
                <el-tooltip class="item" effect="dark" content="经销商(说明:请与提报人确认经销商的微信昵称或手机号，通过昵称或手机号搜索指定)" placement="top-start">
                  <i class="el-icon-warning" style="margin-left: 10px"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="会议时间" prop="open_time">
              <el-date-picker
                v-model="item.open_time"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择会议时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="大商凭证" prop="business_img" v-if="item.type==4">
              <div style="display: flex;align-items: center">
              <upload-img ref="business_img" :limit="5" :info-text="''" @getImgs="getGoods_imgImg($event, 'business_img',index)" :maxSize="100"/>
                <el-tooltip class="item" effect="dark" content="大商凭证(说明:请上传能证明大商身份的凭证，包括但不限于公司规模、公司官网介绍等)" placement="top-start">
                  <i class="el-icon-warning" style="margin-left: 10px"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="会议备注" prop="remark">
              <el-input clearable v-model="item.remark" placeholder="请输入会议备注" style="width: 160px;"></el-input>
            </el-form-item>
          </el-form>
        <el-button v-show="index == form.content.length - 1" type="primary" @click="handleradd">添加</el-button>
        <el-button v-show="form.content.length!=1" type="primary" @click="handlerdelect(index)">删除</el-button>
<!--        <el-divider></el-divider>-->
      </div>

    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showedit = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import UploadImg from "@/components/uploadImg";

export default {
  name: "editcom",
  components: { UploadImg },
  computed:{
    setmeal(){
      let num=0
      this.form.content.forEach(item=>{
        if(item.type==3){
          num++
        }

      })
      return num
    },
    surplus(){
      let num=0
      this.form.content.forEach(item=>{
        if(item.type==4){
          num++
        }

      })
      return num
    }
  },
  data(){
    return{
      showedit:false,
      showform:{
        referrals:0,
        through_train:0
      },
      typeSelect:[{
        id: 3,
        name: '云会议'
      },{
        id: 4,
        name: '大商直通车'
      }],
      form:{
        mall_id:'',
        content:[{
          type:3,
          meeting_img:'',
          open_time:'',
          prove_img:'',
          video_url:'',
          detail_url:'',
          staff_id:'',
          staff_name:'',
          dealer_id:'',
          dealer_name:'',
          business_img:'',
          remark:'',
        }]
      },
      rules:{
        type:[
          { required: true, message: "请选择扣除类型", trigger: "blur" }
        ],
        video_url:[
          { required: true, message: "请输入云会议录制链接", trigger: "blur" }
        ],
        // detail_url:[
        //   { required: true, message: "请输入云会明细链接", trigger: "blur" }
        // ],
        staff_id:[
          { required: true, message: "请输入提报人", trigger: "change" }
        ],
        dealer_id:[
          { required: true, message: "请输入经销商", trigger: "change" }
        ],
        open_time:[
          { required: true, message: "请选择时间", trigger: "blur" }
        ],
        business_img:[
          { required: true, message: "请上传大商凭证", trigger: "blur" }
        ],
      },
    }
  },
  watch:{
    showedit(val){
      if(!val){
        this.closeimg("business_img")
        this.closeimg("prove_img")
        this.closeimg("meeting_img")
        this.form={
          mall_id:'',
          content:[{
            type:3,
            prove_img:'',
            meeting_img:'',
            open_time:'',
            video_url:'',
            detail_url:'',
            staff_id:'',
            staff_name:'',
            dealer_id:'',
            dealer_name:'',
            business_img:'',
            remark:'',
          }]
        }
      }
    }
  },
  methods:{
    handlerinfo(){
      getAction("/mall/setmeal/deduct-detail",{mall_id:this.form.mall_id}).then(res=>{
        this.$set(this.showform,res.data)
        this.showform=Object.assign(this.showform,res.data)
        console.log(this.showform,res)
        this.showedit=true
      })
    },
    handlerdelect(index){
      this.form.content.splice(index,1)
    },
    handleradd(){
      this.form.content.push({
        type:'',
        prove_img:'',
        meeting_img:'',
        open_time:'',
        video_url:'',
        detail_url:'',
        staff_id:'',
        staff_name:'',
        dealer_id:'',
        dealer_name:'',
        business_img:'',
        remark:'',
      })
    },
    handlerstaff(queryString,cb,index){
      if(queryString==''){
        cb([])
        this.form.content[index].staff_id=''
      }else{
        getAction("/mall/setmeal/staff-crm",{staff_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectstaff(list,index){
      console.log(list)
      this.form.content[index].staff_id=list.staff_id
      this.form.content[index].staff_name=list.staff_name
    },
    handlerdealer(queryString,cb,index){
      if(queryString==''){
        cb([])
        this.form.content[index].dealer_id=''
      }else{
        getAction("/mall/setmeal/dealer-crm",{staff_id:this.form.content[index].staff_id,keyword:queryString}).then(res=>{
          let datalist=[]
          if(res.data.length>0){
            datalist = res.data.map(list=>{
              list.dealer_name=list.dealer_name+'/'+list.remark_mobiles
             return list
           })
          }
          cb(datalist);
        })
      }
    },
    handleSelectdealer(list,index){
      console.log(list)
      this.form.content[index].dealer_id=list.dealer_id
      this.form.content[index].dealer_name=list.dealer_name
    },
    handlersave(){
      console.log(this.$refs.form)
      let formbool = true
      try {
        this.$refs.form.forEach(item=>{
          console.log(item.validate((valid) => {
            if(!valid){
              formbool = false
              throw Error();
            }
          }))
        })
        if(formbool){
          postAction("/mall/setmeal/deduct-setmeal", this.form).then(res => {
            console.log(res)
            if (res.request_code == 200) {
              this.$message({ type: 'success', message: res.msg })
              this.showedit = false
              this.$emit("getlist")
            }
          })
        }
      }catch (e) {}
    },
    getGoods_imgImg(v, prop, index) {
      if (v[0]) {
        this.form.content[index][prop] = v.join(',')
      } else {
        this.form.content[index][prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      console.log()
      if(this.$refs[prop]!=undefined){
        this.$refs[prop].forEach(item=>{
          console.log(item)
          item.img=[]
        })
      }
    },
  }
};
</script>

<style scoped>

</style>
