var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible: _vm.showEditDialog,
        width: "700px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showEditDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-position": "right",
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.form.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "url", $$v)
                  },
                  expression: "form.url",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "轮播图", prop: "toppic" } },
            [
              _c("upload-img", {
                ref: "pic",
                attrs: { infoText: "", limit: 5, maxSize: 100 },
                on: {
                  getImgs: function ($event) {
                    return _vm.getGoods_imgImg($event, "toppic")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "跳转类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.operation_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "operation_type", $$v)
                    },
                    expression: "form.operation_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "无跳转", value: "1" } }),
                  _c("el-option", { attrs: { label: "H5链接", value: "2" } }),
                  _c("el-option", {
                    attrs: { label: "糖烟酒周刊小程序", value: "3" },
                  }),
                  _c("el-option", {
                    attrs: { label: "小程序通用链接", value: "4" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "跳转链接" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入链接" },
                model: {
                  value: _vm.form.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "url", $$v)
                  },
                  expression: "form.url",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [_vm._v("启用")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [_vm._v("禁用")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom-button" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showEditDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c("el-button", { on: { click: _vm.handlerSave } }, [_vm._v("确定")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }