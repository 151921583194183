<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="formkind"
          clearable
          filterable
          multiple
          placeholder="一级事业部"
          style="width: 250px"
        >
          <el-option
            v-for="list in kindselect"
            :key="list"
            :label="list"
            :value="list"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formdept"
          clearable
          multiple
          filterable
          placeholder="二级部门"
          style="width: 250px"
        >
          <el-option
            v-for="list in departselect"
            :key="list"
            :label="list"
            :value="list"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.staff_name"
          clearable
          placeholder="员工姓名"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          :clearable="false"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerCertExport">
          导出全部经销商身份通过数据
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">
          导出全部标签数据
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerTradeExport">
          导出全部企微品类需求数据
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerBizExport">
          导出全部企微指定店铺需求数据
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerSelExport">
          导出全部选品方案数据
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerMessageExport">
          导出全部留言数据
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerFindExport">
          导出全部经销商找品数据
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      v-loading="tableloading"
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        prop="staff_name"
        label="员工姓名"
        width=""
      ></el-table-column>
      <el-table-column
        prop="htyun_kind"
        label="一级事业部"
        width=""
      ></el-table-column>
      <el-table-column
        prop="htyun_dept"
        label="二级部门"
        width=""
      ></el-table-column>
      <el-table-column
        prop="cert_num"
        label="经销商身份通过次数"
        width=""
        :render-header="renderCertnum"
      >
        <template slot-scope="scope">
          <a @click="showDatalist(scope.row)">
            {{ scope.row.cert_num }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_ok"
        label="标签已完善人数"
        width=""
        :render-header="renderIsok"
      >
        <template slot-scope="scope">
          <a @click="showDatalistOk(scope.row)">
            {{ scope.row.is_ok }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="biz_trade_num"
        label="企微品类需求被查看得奖励次数"
        width=""
        :render-header="renderBizTradeNum"
      >
        <template slot-scope="scope">
          <a @click="showDatalistTrade(scope.row)">
            {{ scope.row.biz_trade_num }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="biz_num"
        label="企微指定店铺需求通过次数"
        width=""
        :render-header="renderBizNum"
      >
        <template slot-scope="scope">
          <a @click="showDatalistBiz(scope.row)">
            {{ scope.row.biz_num }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="sel_num"
        label="选品方案喜欢奖励次数"
        width=""
        :render-header="renderSelNum"
      >
        <template slot-scope="scope">
          <a @click="showDatalistSel(scope.row)">
            {{ scope.row.sel_num }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="message_num"
        label="留言且被查看奖励次数"
        width=""
        :render-header="renderMessageNum"
      >
        <template slot-scope="scope">
          <a @click="showDatalistMessage(scope.row)">
            {{ scope.row.message_num }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="find_num"
        label="经销商找品发布需求奖励次数"
        width=""
        :render-header="renderFindNum"
      >
        <template slot-scope="scope">
          <a @click="showDatalistFind(scope.row)">
            {{ scope.row.find_num }}
          </a>
        </template>
      </el-table-column>

      <!-- <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        :render-header="renderPrice($event, item.label)"
      >
        <template #default="{ row }">
          <div v-if="item.label == '沟通图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '会议室截图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column> -->
      <!-- <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerExport(row)"
          >
            导出标签数据
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div style="line-height: 40px; color: #999">
      提示：选品方案奖励、留言查看奖励、经销商找品奖励显示筛选日期所在月的数据，例如筛选日期为8.30-9.1，则这三项数据查出来为8月和9月两个整月的数据
    </div>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <list ref="certlist" />
    <listok ref="oklist" />
    <listtrade ref="tradelist" />
    <listbiz ref="bizlist" />
    <listsel ref="sellist" />
    <listfind ref="findlist" />
    <listmessage ref="messagelist" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import dateFormat from '@/utils/Timeformat'
  import list from './components/list.vue'
  import listok from './components/listok.vue'
  import listtrade from './components/listtrade.vue'
  import listbiz from './components/listbiz.vue'
  import listsel from './components/listsel.vue'
  import listmessage from './components/listmessage.vue'
  import listfind from './components/listfind.vue'
  // import { param } from 'dist/static/UEditor/third-party/jquery-1.10.2'

  export default {
    components: {
      list,
      listok,
      listtrade,
      listbiz,
      listsel,
      listmessage,
      listfind,
    },
    data() {
      return {
        form: {
          staff_name: '', //员工姓名
          page: 1,
          limit: 10,
          start_date: dateFormat(new Date() - 3600 * 1000 * 24), //dateFormat(new Date() - 3600 * 1000 * 24),
          end_date: dateFormat(new Date() - 3600 * 1000 * 24), //dateFormat(new Date() - 3600 * 1000 * 24),
          dept: '', //部门
          kind: '', //事业部
        },
        tableloading: false,
        time: [
          dateFormat(new Date() - 3600 * 1000 * 24),
          dateFormat(new Date() - 3600 * 1000 * 24),
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        total: 0,
        departselect: [],
        kindselect: [],
        formdept: [],
        formkind: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.getKind()
      this.getDepart()
    },
    methods: {
      // 查看经销商身份通过次数
      showDatalist(row) {
        console.log('员工名称,', row)
        this.$refs.certlist.showedit = true
        let date = {
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        }
        this.$refs.certlist.handlerlist(row, date)
      },
      // 查看标签已完善人数
      showDatalistOk(row) {
        console.log('查看，', this.$refs.oklist)
        this.$refs.oklist.showedit = true
        let date = {
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        }
        this.$refs.oklist.handlerlist(row, date)
      },
      // 查看企微品类需求被查看奖励次数
      showDatalistTrade(row) {
        this.$refs.tradelist.showedit = true
        let date = {
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        }
        this.$refs.tradelist.handlerlist(row, date)
      },
      // 查看企微指定店铺需求数据
      showDatalistBiz(row) {
        this.$refs.bizlist.showedit = true
        let date = {
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        }
        this.$refs.bizlist.handlerlist(row, date)
      },
      // 查看选品方案数据
      showDatalistSel(row) {
        this.$refs.sellist.showedit = true
        let date = {
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        }
        this.$refs.sellist.handlerlist(row, date)
      },
      // 查看留言数据
      showDatalistMessage(row) {
        this.$refs.messagelist.showedit = true
        let date = {
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        }
        this.$refs.messagelist.handlerlist(row, date)
      },
      // 查看经销商找品数据
      showDatalistFind(row) {
        this.$refs.findlist.showedit = true
        let date = {
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        }
        this.$refs.findlist.handlerlist(row, date)
      },

      //经销商身份通过
      renderCertnum(h, { column, $index }) {
        console.log('render------------', h, column)
        return [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content: '显示员工邀请了多少个经销商进行身份认证并通过', // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ]
      },
      //标签已完善
      renderIsok(h, { column, $index }) {
        console.log('render------------', h, column)
        return [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content:
                  '显示员工在企微给多少个经销商打满了五个标签，五个标签包含行业、类别、身份、渠道、区域（省份和行政区划）', // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ]
      },
      //企微指定店铺需求通过次数
      renderBizTradeNum(h, { column, $index }) {
        console.log('render------------', h, column)
        return [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content:
                  ' 显示员工在企微给经销商提报需求，需求在云交会被企业查看后，员工获得奖励次数', // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ]
      },
      //企微指定店铺需求通过次数
      renderBizNum(h, { column, $index }) {
        console.log('render------------', h, column)
        return [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content:
                  '显示员工在企微给经销商提报指定店铺需求，需求被通过次数', // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ]
      },
      //选品方案喜欢奖励次数
      renderSelNum(h, { column, $index }) {
        console.log('render------------', h, column)
        return [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content:
                  '显示员工在企微给经销商提报选品方案，经销商点击喜欢后，员工获得奖励次数', // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ]
      },
      //留言且被查看奖励次数
      renderMessageNum(h, { column, $index }) {
        console.log('render------------', h, column)
        return [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content:
                  '显示员工邀请经销商在云交会给企业留言，且该留言被企业查看，员工获得奖励次数。按筛选时间所在月，显示这些月的数据', // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ]
      },
      //经销商找品发布需求奖励次数
      renderFindNum(h, { column, $index }) {
        console.log('render------------', h, column)
        return [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content:
                  '显示员工邀请在经销商在“经销商找品”栏目中发布需求，且被审核通过，员工得奖励次数。按筛选时间所在月，显示这些月的数据', // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ]
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      getKind() {
        postAction('/api/whale/tag/get-kind').then((res) => {
          if (res.request_code == 200) {
            this.kindselect = res.data
          }
        })
      },
      getDepart() {
        postAction('/api/whale/tag/get-dept').then((res) => {
          if (res.request_code == 200) {
            this.departselect = res.data
          }
        })
      },
      //导出全部经销商身份通过数据
      handlerCertExport() {
        let params = {}
        params.kind = this.form.kind
        params.start_date = this.form.start_date
        params.end_date = this.form.end_date
        params.dept = this.form.dept
        params.staff_name = this.form.staff_name

        getAction('/api/whale/tag/identity-status-export', params).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      // 导出全部企微品类需求数据
      handlerTradeExport() {
        let params = {}
        params.kind = this.form.kind
        params.start_date = this.form.start_date
        params.end_date = this.form.end_date
        params.dept = this.form.dept
        params.staff_name = this.form.staff_name

        getAction('/api/whale/tag/biz-trade-reward-export', params).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      // 导出全部企微指定店铺需求数据
      handlerBizExport() {
        let params = {}
        params.kind = this.form.kind
        params.start_date = this.form.start_date
        params.end_date = this.form.end_date
        params.dept = this.form.dept
        params.staff_name = this.form.staff_name

        getAction('/api/whale/tag/biz-reward-export', params).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },
      // 导出全部选品方案数据
      handlerSelExport() {
        let params = {}
        params.kind = this.form.kind
        params.start_date = this.form.start_date
        params.end_date = this.form.end_date
        params.dept = this.form.dept
        params.staff_name = this.form.staff_name

        getAction('/api/whale/tag/sel-plan-reward-export', params).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      // 导出全部留言数据
      handlerMessageExport() {
        let params = {}
        params.kind = this.form.kind
        params.start_date = this.form.start_date
        params.end_date = this.form.end_date
        params.dept = this.form.dept
        params.staff_name = this.form.staff_name

        getAction('/api/whale/tag/message-award-lists-export', params).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      //导出全部经销商找品数据
      handlerFindExport() {
        let params = {}
        params.kind = this.form.kind
        params.start_date = this.form.start_date
        params.end_date = this.form.end_date
        params.dept = this.form.dept
        params.staff_name = this.form.staff_name

        getAction('/api/whale/tag/find-award-lists-export', params).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      // 导出全部标签
      handlerExport(row) {
        console.log('---', row)
        let params = {}
        params.kind = this.form.kind
          params.start_date = this.form.start_date
          params.end_date = this.form.end_date
          params.dept = this.form.dept
          params.staff_name = this.form.staff_name
        getAction('/api/whale/tag/lists-export', params).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },

      async handlerlist() {
        this.tableloading = true
        this.form.kind = this.formkind.join(',')
        this.form.dept = this.formdept.join(',')
        await postAction('/api/whale/tag/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
          this.tableloading = false
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
