<template>
  <el-dialog
    :title="title"
    :visible.sync="showtow"
    width="60%"
    append-to-body>
    <div>
      <el-form ref="form" :model="form" label-width="160px" label-suffix="：">
        <el-form-item label="栏目名称" >
          <el-input placeholder="栏目名称" v-model="form.title"/>
        </el-form-item>
        <el-form-item label="二级页背景色" >
          <el-input placeholder="二级页背景色" v-model="form.bg_color"/>
        </el-form-item>
        <el-form-item label="栏目首页背景图" >
          <upload-img ref="home_img" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'home_img')"/>
        </el-form-item>
<!--        <el-form-item label="二级页banner" >
          <upload-img ref="banner" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'banner')"/>
        </el-form-item>-->

        <el-form-item label="小程序分享图" >
          <upload-img ref="share_img" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'share_img')"/>
        </el-form-item>
        <el-form-item label="H5分享图" >
          <upload-img ref="h5_share_img" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'h5_share_img')"/>
        </el-form-item>
        <el-form-item label="关联类型" >
          <el-select v-model="form.type" placeholder="关联类型" clearable  style="width: 160px;">
            <el-option label="产品" value="1"/>
            <el-option label="店铺" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" >
          <el-input placeholder="排序" v-model="form.sort_order"/>
        </el-form-item>
        <el-form-item label="分享标题" >
          <el-input placeholder="分享标题" v-model="form.share_title"/>
        </el-form-item>
        <el-form-item label="分享描述" >
          <el-input placeholder="分享描述" v-model="form.share_desc"/>
        </el-form-item>
        <el-form-item label="底部按钮背景色" >
          <el-input placeholder="底部按钮背景色" v-model="form.button_color"/>
        </el-form-item>
        <el-form-item label="底部按钮文字" >
          <el-input placeholder="底部按钮文字" v-model="form.button_text"/>
        </el-form-item>
        <el-form-item label="底部按钮文字颜色" >
          <el-input placeholder="底部按钮文字颜色" v-model="form.button_text_color"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import UploadImg from "@/components/uploadImg";
import { postAction } from "@/api/request";
import { nextTick } from "vue";
export default {
  name: "addTow",
  components: { UploadImg },
  data(){
    return{
      title:'添加二级分类',
      showtow:false,
      form:{
        actid:"",
        id:0,
        button_text_color:'',
        type:'',
        title:'',
        share_title:'',
        sort_order:'',
        share_desc:'',
        button_color:'',
        button_text:'',
        bg_color:'',
      }
    }
  },
  watch:{
    showtow(val){
      if(!val){
        this.close()
      }
    }
  },
  methods:{
    handlerinfo(row){
      nextTick(()=>{
        this.setImg(row.home_img,'home_img')
        this.setImg(row.banner,'banner')
        this.setImg(row.share_img,'share_img')
        this.setImg(row.h5_share_img,'h5_share_img')
      })
      this.form=Object.assign(this.form,row)
    },
    handlersave(){
      postAction("/api/theme/menu/edit",this.form).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.close()
        this.$emit("getlist")
      })
    },
    show(){
      this.showtow=true
    },
    close(){
      this.showtow=false
      this.form={
        actid:"",
        id:0,
        button_text_color:'',
        type:'',
        title:'',
        share_title:'',
        sort_order:'',
        share_desc:'',
        button_color:'',
        button_text:'',
        bg_color:'',
      }
      this.closeimg('home_img')
      this.closeimg('banner')
      this.closeimg('share_img')
      this.closeimg('h5_share_img')
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
