<template>
  <div class="meeting">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item>
        <el-input v-model="form.title" clearable placeholder="活动名称" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search()">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerEdit()">新增调查</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="checkcate()">品类库</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" style="width: 100%" ref="ElTable">
      <el-table-column width="80px" prop="id" label="ID" align="center" />
      <el-table-column width="140px" prop="" label="标题" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.title"
            placement="top-start"
          >
            <span class="shenglue">{{ scope.row.title }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="80px" prop="" label="封面主图" align="center">
        <template slot-scope="scope">
          <img
            style="width: 40px"
            :src="scope.row.main_img"
            @click="
              bigimgShow = true
              bigimg = scope.row.main_img
            "
          />
        </template>
      </el-table-column>
      <el-table-column width="80px" prop="" label="主题图" align="center">
        <template slot-scope="scope">
          <img
            style="width: 40px"
            :src="scope.row.title_img"
            @click="
              bigimgShow = true
              bigimg = scope.row.title_img
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        width="80px"
        prop="bg_color"
        label="背景色"
        align="center"
      />
      <el-table-column width="80px" prop="" label="抽奖图" align="center">
        <template slot-scope="scope">
          <img
            style="width: 40px"
            :src="scope.row.award_img"
            @click="
              bigimgShow = true
              bigimg = scope.row.award_img
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        width="160px"
        prop="award_url"
        label="抽奖链接"
        align="center"
      />
      <el-table-column width="120px" prop="" label="H5分享主题" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.share_app_title"
            placement="top-start"
          >
            <span class="shenglue">{{ scope.row.share_app_title }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="120px" prop="" label="分享描述" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.share_app_content"
            placement="top-start"
          >
            <span class="shenglue">{{ scope.row.share_app_content }}</span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column width="80px" prop="" label="分享图" align="center">
        <template slot-scope="scope">
          <img
            style="width: 40px"
            :src="scope.row.share_app_img"
            @click="
              bigimgShow = true
              bigimg = scope.row.share_app_img
            "
          />
        </template>
      </el-table-column>

      <el-table-column
        width="120px"
        prop=""
        label="小程序分享主题"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.share_mini_title"
            placement="top-start"
          >
            <span class="shenglue">{{ scope.row.share_mini_title }}</span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column width="80px" prop="" label="分享图" align="center">
        <template slot-scope="scope">
          <img
            style="width: 40px"
            :src="scope.row.share_mini_img"
            @click="
              bigimgShow = true
              bigimg = scope.row.share_mini_img
            "
          />
        </template>
      </el-table-column>
      <el-table-column width="80px" prop="" label="海报图" align="center">
        <template slot-scope="scope">
          <img
            style="height: 40px"
            :src="scope.row.poster_img"
            @click="
              bigimgShow = true
              bigimg = scope.row.poster_img
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        width="155px"
        prop="created"
        label="时间"
        align="center"
      />
      <el-table-column
        width="120px"
        prop=""
        label="操作"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="handlerguanlian(scope.row)">
            题目关联
          </el-button>
          <el-button type="text" @click="handlerEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="text" @click="handleCopyLink(scope.row)">
            复制链接
          </el-button>
          <!-- <el-button type="text" @click="handleDetail(scope.row)">
            答题详情
          </el-button>
          <el-button type="text" @click="handleDetail2(scope.row)">
            调查详情
          </el-button>
          <el-button type="text" @click="handleDraw(scope.row)">
            抽奖统计
          </el-button>
          <el-button type="text" @click="handleStaff(scope.row)">
            员工统计
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />

    <div class="bigimg" v-show="bigimgShow" @click="bigimgShow = false">
      <img :src="bigimg" />
    </div>
    <!-- 编辑/新增调查 -->
    <addSurvey ref="add" @getlist="handlerInquire" />
    <!-- 题目关联 -->
    <related ref="related" @getlist="handlerInquire" />
    <categoryKu ref="cate"></categoryKu>
    <info ref="info" />
  </div>
</template>
<script>
  import UploadImg from '@/components/uploadImg'
  import addSurvey from './add'
  import related from './related'
  import categoryKu from './components/categoryKu'
  import { getAction, postAction } from '@/api/request'
  import info from './components/info'
  export default {
    name: 'survey',
    components: {
      UploadImg,
      addSurvey,
      related,
      categoryKu,
      info,
    },
    data() {
      return {
        form: {
          title: '',
          page: 1,
          limit: 10,
          status: '',
        },
        tabledata: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        bigimg: '',
        bigimgShow: false,
      }
    },
    computed: {},
    watch: {},
    mounted() {
      let that = this
      that.handlerInquire()
    },
    methods: {
      // 答题统计详情
      handleDetail(row) {
        if (row.role_question_id == 0) {
          //无身份
          this.$refs.info.form.conf_id = row.id
          this.$refs.info.handlerlist()
          this.$refs.info.show = true
        } else {
          //有身份
          this.$router.push({
            path: '/datastatis/survey/wanshang/qiutang2024/dati',
            query: { conf_id: row.id },
          })
        }
      },
      // 调查详情
      handleDetail2(row) {
        this.$router.push({
          path: '/datastatis/survey/wanshang/qiutang2024/detail',
          query: { conf_id: row.id },
        })
      },
      // 抽奖统计
      handleDraw(row) {
        this.$router.push({
          path: '/datastatis/survey/wanshang/qiutang2024/draw',
          query: { conf_id: row.id },
        })
      },
      // 员工统计
      handleStaff(row) {
        this.$router.push({
          path: '/datastatis/survey/wanshang/qiutang2024/index',
          query: { conf_id: row.id },
        })
      },
      // 复制链接
      handleCopyLink(row) {
        console.log('-------------', row)
        let url =
          'https://www.shiyetoutiao.cn/h5/yunjiaohui/other/wenjuan/template/dati/zheng/index.html?share_type=51&id=' +
          row.id +
          '&tongji_type=83&conf_id=' +
          row.id //正式地址
        if (
          process.env.NODE_ENV == 'development' ||
          process.env.NODE_ENV == 'test'
        ) {
          url =
            'https://www.shiyetoutiao.cn/h5/yunjiaohui/other/wenjuan/template/dati/test/index.html?share_type=51&id=' +
            row.id +
            '&tongji_type=83&conf_id=' +
            row.id //测试地址
        }
        let shareLink = url
        var input = document.createElement('input') // 直接构建input
        input.value = shareLink // 设置内容
        document.body.appendChild(input) // 添加临时实例
        input.select() // 选择实例内容
        document.execCommand('Copy') // 执行复制
        document.body.removeChild(input) // 删除临时实例
        this.$message.success('复制调查问卷链接成功')
      },
      handlerInquire() {
        let that = this
        postAction('/api/system/research/conf-list', that.form).then((res) => {
          if (res.code == 0) {
            that.tabledata = res.data
            that.total = res.totalCount
          }
        })
      },
      search() {
        this.form.page = 1
        this.handlerInquire()
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerInquire()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerInquire()
      },
      setImg(v, prop) {
        console.log(v, prop)
        if (v) {
          this.$refs[prop].img = [v]
        } else {
          this.$refs[prop].img = []
        }
      },
      getfloatimg(v, prop) {
        this.editFloat.float_icon = v[0]
      },
      handlerEdit(row) {
        if (row) {
          this.$refs.add.getinfo(row.id)
          this.$refs.add.title = '编辑'
        } else {
          this.$refs.add.title = '新增'
        }
        this.$refs.add.addShow = true
      },
      handlerguanlian(row) {
        this.$refs.related.id = row.id
        this.$refs.related.title = row.title
        this.$refs.related.getinfo(row.id)
        this.$refs.related.relatedShow = true
      },
      checkcate() {
        this.$refs.cate.getinfo()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .meeting {
    padding: 20px;
  }
  .bigimg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bigimg img {
    width: 15%;
    max-height: 80%;
    object-fit: contain;
  }
  .dialog-footer {
    text-align: right;
    margin-top: 40px;
  }
  .related {
    .header {
      margin-bottom: 20px;
    }
    // .relatedTable{
    //   margin-bottom:20px;
    // }
    // .relatedTable /deep/ .el-table__header-wrapper{
    //   display: none;
    // }
    // .relatedTable /deep/ .el-table__body-wrapper{
    //   margin-top:0;
    // }
  }
  .shenglue {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-break: break-all;
  }
</style>
