var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px 10px" } },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.timelist,
                  callback: function ($$v) {
                    _vm.timelist = $$v
                  },
                  expression: "timelist",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "搜索企业名称" },
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handlerlist()
                    },
                  },
                },
                [_vm._v(" 搜索 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleradd(1)
                    },
                  },
                },
                [_vm._v(" 新增企业 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleradd(2)
                    },
                  },
                },
                [_vm._v(" 关联云交会企业 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", [
        _c(
          "a",
          {
            staticStyle: { cursor: "default" },
            attrs: { href: "javascript:;" },
          },
          [_vm._v(" 万商专区首页UV：" + _vm._s(_vm.total_tj.uv) + " ")]
        ),
        _c(
          "a",
          {
            staticStyle: { "margin-left": "40px", cursor: "default" },
            attrs: { href: "JavaScript:;" },
          },
          [_vm._v(" 万商专区首页PV：" + _vm._s(_vm.total_tj.pv) + " ")]
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: { data: _vm.tabledata, border: "" },
          on: { "sort-change": _vm.sortChange },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", align: "center", width: "90" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "mall_name",
              label: "企业名称",
              align: "center",
              width: "110",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "number_house",
              label: "展馆名称",
              align: "center",
              width: "90",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "number",
              label: "展位号",
              align: "center",
              width: "90",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "logo",
              label: "企业头像",
              align: "center",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          "preview-src-list": [row.logo],
                          src: row.logo,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "placeholder" },
                            slot: "placeholder",
                          },
                          [
                            _vm._v(" 加载中 "),
                            _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                          ]
                        ),
                        _c("div", {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        }),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "cat_name", label: "公司分类", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "yjh_id",
              label: "是否关联云交会",
              align: "center",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(" " + _vm._s(row.yjh_id != "0" ? "是" : "否") + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "uv",
              label: "访客数(UV)",
              align: "center",
              width: "90",
              sortable: "custom",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pv",
              label: "访问次数(PV)",
              align: "center",
              width: "90",
              sortable: "custom",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "poster_count",
              label: "生成海报数",
              align: "center",
              width: "90",
              sortable: "custom",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sort_order",
              label: "排序",
              align: "center",
              sortable: "custom",
              width: "90",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "状态",
              align: "center",
              width: "90",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "created", label: "创建时间", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "110px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleredit(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    row.yjh_id == "0"
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.relativeRow(row)
                              },
                            },
                            slot: "reference",
                          },
                          [_vm._v(" 关联产品 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确认删除吗？" },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteRow(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "text",
                            },
                            slot: "reference",
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", {
        ref: "edit",
        on: {
          getlist: function ($event) {
            return _vm.handlerlist()
          },
        },
      }),
      _c("relativelist", {
        ref: "product",
        on: {
          getlist: function ($event) {
            return _vm.handlerlist()
          },
        },
      }),
      _c("relativecompany", {
        ref: "company",
        on: {
          getlist: function ($event) {
            return _vm.handlerlist()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }