<template>
  <el-dialog :title="title" :visible.sync="showAdd" width="700px">
    <div>
      <el-form ref="form" label-width="120px" :model="form" :rules="rules">
        <el-form-item label="标题：" prop="name">
          <el-input
            v-model="form.name"
            clearable
            placeholder="名称"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item label="图片：" prop="pic">
          <upload-img
            ref="pic"
            :info-text="''"
            :limit="1"
            :max-size="100"
            @getImgs="getGoods_imgImg($event, 'pic')"
          />
        </el-form-item>
        <el-form-item label="内容：" prop="content">
          <wang-editor ref="content" />
        </el-form-item>
        <el-form-item label="二维码：" prop="qr_code">
          <upload-img
            ref="qr_code"
            :info-text="''"
            :limit="1"
            :max-size="100"
            @getImgs="getGoods_imgImg($event, 'qr_code')"
          />
        </el-form-item>
        <el-form-item label="排序：" prop="sort_order">
          <el-input-number v-model="form.sort_order" :min="0" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showAdd = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import WangEditor from '@/components/WangEditor'
  import UploadImg from '@/components/uploadImg'
  import { postAction } from '@/api/request'
  import { nextTick } from 'vue'

  export default {
    name: 'Add',
    components: { UploadImg, WangEditor },
    data() {
      return {
        showAdd: false,
        title: '',
        form: {
          sort_order: 0,
          name: '',
          pic: '',
          content: '',
          qr_code: '',
          id: '',
        },
        rules: {
          name: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          content: [
            {
              validator: this.verifyDuihuanshuoming,
              required: true,
              message: '请输入会议流程',
              trigger: 'blur',
            },
          ],
          pic: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        },
      }
    },
    watch: {
      showAdd(val) {
        if (!val) {
          this.form = {
            sort_order: 0,
            name: '',
            pic: '',
            content: '',
            qr_code: '',
            id: '',
          }
          this.title = ''
          nextTick(() => {
            this.closeimg('pic')
            this.closeimg('qr_code')
            this.$refs.content.clearHtml()
          })
        }
      },
    },
    methods: {
      handlerinfo(row) {
        this.form = Object.assign(this.form, row)
        nextTick(() => {
          this.setImg(row.pic, 'pic')
          this.setImg(row.qr_code, 'qr_code')
          this.$refs.content.setHtml(row.content)
        })
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      verifyDuihuanshuoming(rule, val, cb) {
        if (this.$refs.content.isKong()) {
          cb(new Error('请输入会议流程'))
        } else {
          cb()
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      handlersave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.form.content = this.$refs.content.getHtmlM()
            postAction('/api/afbc/hot/edit', this.form).then((res) => {
              this.$emit('getlist')
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.showAdd = false
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>

<style scoped></style>
